import {Component, OnInit} from "@angular/core";
import {CmsApiService} from "../../../core/cms-api.service";

@Component({
  selector: 'report-standard',
  templateUrl: './report-standard.component.html',
  styleUrls: ['./report-standard.component.scss']
})
export class ReportStandardComponent implements OnInit {

  constructor(private readonly cms: CmsApiService) {

  }

  ngOnInit(): void {
  }

}
