import { Injectable } from '@angular/core';
import {SettingsService} from "./settings.service";
import {UserData} from "./definitions/user-data";

@Injectable({
  providedIn: 'root'
})
export class ConfigServerApiService {
  private translationServerUrl = 'https://translation.primus.org';

  constructor(private settingsService: SettingsService) { }

  async getMessages(userData: UserData) {
    const restricted = userData && userData.rights_level.toLowerCase() === 'admin';
    const clientConfig = this.settingsService.getClientConfig();
    const locale = clientConfig.CLIENT_LANGUAGE;
    const serverKey = clientConfig.SERVER_NAME;
    const requestUrl = `${this.translationServerUrl}/message_api/messages/${serverKey},${locale},${restricted}`;
    const response = await fetch( requestUrl, {
      method: 'GET',
      mode: 'no-cors',
    });
    if (response.ok) {
      return response.json();
    } else {
      console.error('Error getting messages for ' + requestUrl, response.status, response.statusText);
      return [];
    }
  }
}
