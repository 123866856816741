import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentInfo} from '../../core/definitions/content-info';
import {ObjectEditService} from '../../core/object-edit.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SearchSelectorService} from '../../core/search-selector.service';
import {TemplateSelectorContainer} from '../../core/definitions/template-selector-container';
import {UiToolsService} from '../../core/ui-tools.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {OperationAndSectionsContainerParams, OperationService} from '../../operations/operation.service';
import {OperationDialogService} from '../../operations/operation-dialog.service';
import {OperationDef} from '../../core/definitions/operation-def';
import {Selector} from '../../core/definitions/reference';
import {OperationStepType} from '../../core/definitions/operation-step-type.enum';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ContentMenusService} from '../../object-content-tab/content-menus.service';
import {OperationContainer} from '../../core/definitions/operation-container';
import {ContentListSourceContainer} from '../../core/definitions/object-content-tab/content-list-source-container';
import {SelectorCreationParams} from '../../core/definitions/selector-creation-params';
import {OperationExecutorType} from '../../core/definitions/operation-executor-type.enum';
import {PrimusRouterService} from '../../core/primus-router.service';
import {PrimusRouteService} from '../../core/primus-route.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  animations: [
    trigger('showContentMenu', [
      state('collapseMenu', style({left: '-80px'})),
      state('expandMenu', style({left: 0})),
      state('pullContent', style({left: 0})),
      state('pushContent', style({left: '80px'})),

      transition('collapseMenu => expandMenu', [
        animate('800ms ease', style({left: 0}))
      ]),

      transition('expandMenu => collapseMenu', [
        animate('800ms ease', style({left: '-80px'}))
      ]),

      transition('pullContent => pushContent', [
        animate('800ms ease', style({left: '80px'}))
      ]),

      transition('pushContent => pullContent', [
        animate('800ms ease', style({left: 0}))
      ]),
    ])
  ]
})
export class InformationComponent implements OnInit, OnDestroy {

  constructor(private objectEditService: ObjectEditService,
              private primusRoute: PrimusRouteService,
              public currentObjectService: CurrentObjectService,
              private primusRouter: PrimusRouterService,
              private searchSelectorService: SearchSelectorService,
              private mediaHelper: MediaHelperService,
              private uiTools: UiToolsService,
              private operationService: OperationService,
              private contentMenusService: ContentMenusService,
              private operationDialogService: OperationDialogService) {
  }

  loadingObjectInfo: boolean;
  noObjectId = false;
  artifactId;
  templateSelectorContainer = new TemplateSelectorContainer();
  smallScreen: boolean;
  openMenu = false;
  showImageOrder = false;
  loadFailed = false;
  operationContainer: OperationContainer;

  private templateGroupId;
  private stopSizeWatch;
  private routerSubscription: Subscription;

  ngOnInit() {
    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      this.init().then();
    });
    this.init().then();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  // onSelectView(viewName: string) {
  //   const ci = this.operationContainer.contentInfo;
  //   ci.setContentListSource(ci.sources['overview']);
  // }

  onTemplateSelectorCreated(templateSelectorContainer) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  async onChangeTemplateGroup(newGroupId) {
    await this.primusRouter.navigateState(this.primusRouter.currentState(),
      {template_group_id: newGroupId},
      {location: 'replace', reload: true});
  }

  async onSelectOperation(operation: OperationDef) {
    this.operationService.setSelectedOperation(this.operationContainer, operation);
    if (!this.currentObjectService.isEditing) {
      this.operationService.setCurrentOperation(this.operationContainer, operation);
      if (operation.operation_type === 'group') {
        this.operationService.setCurrentSelectedGroupOperation(this.operationContainer, operation);
      }
    }
    if (this.smallScreen) {
      this.onToggleMenu();
    }

    this.setCurrentList(operation.content_list);
  }

  onToggleMenu() {
    this.openMenu = !this.openMenu;
  }

  onToggleChangeImageOrder(/*images?*/) {
    this.showImageOrder = !this.showImageOrder;
  }

  async setEdit() {
    this.currentObjectService.isEditing = true;
    await this.primusRouter.navigateState(this.primusRouter.currentState(),
      {edit: true, isNew: undefined},
      {location: 'replace', reload: true});
  }

  onNewObjectCreated(objectType: string) {
    let newCurrentContentListSource: ContentListSourceContainer;
    this.operationContainer.contentInfo.curContentListSource.subMenus.forEach(subMenu => {
      if (subMenu.relatedObjectType === objectType) {
        newCurrentContentListSource = subMenu;
      }
    });
    if (newCurrentContentListSource) {
      this.operationContainer.contentInfo.setContentListSource(newCurrentContentListSource);
    } else {
      console.warn('Unable to find sub menu from object type ' + objectType);
    }
  }

  async loadObject() {
    this.loadingObjectInfo = true;
    if (this.artifactId) {
      try {
        this.operationContainer = await this.operationService.createOperationAndRootSectionsContainer(
          this.getCreateParams());
        if (this.primusRoute.params.edit === 'true') {
          this.operationContainer.rootSectionsContainer.hideSectionTitle = true;
        }
        await this.postLoadOperations();
      } catch (reason) {
        this.loadFailed = true;
        console.error('Failed loading object: ' + reason.error?.message);
      }
    } else {
      this.loadingObjectInfo = false;
      this.noObjectId = true;
    }
  }

  private getCreateParams(): OperationAndSectionsContainerParams {
    const params = {
      useExistingObject: true,
      objectId: this.artifactId,
      getSourceObject: !this.currentObjectService.isEditing,
      operationTarget: OperationTarget.INFORMATION_VIEW,
      templateGroupId: this.templateGroupId
    } as OperationAndSectionsContainerParams;
    if (this.currentObjectService.isEditing) {
      params.executorType = OperationExecutorType.ARTIFACT;
      params.operationStepType = OperationStepType.EDIT_OBJECT;
    }
    return params;
  }


  selectorOverlay() {
    if (this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled) {
      this.searchSelectorService.disableSelector(this.templateSelectorContainer);
    } else {
      this.disableSelector();
    }
  }

  private async init() {
    this.currentObjectService.isEditing = this.primusRoute.params.edit === 'true';
    this.artifactId = this.primusRoute.params.artifactId;
    this.templateGroupId = this.primusRoute.params.template_group_id;
    if (!this.primusRoute.params.listName) {
      // TODO: what the heck is this supposed to do?
      // this.primusRoute.params.listName = 'overview';
    }
    const windowSize = this.uiTools.windowSize;
    this.smallScreen = windowSize.width < 800;
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.smallScreen = newVal.width < 800;
      }
    );
    await this.loadObject();
  }

  private setSelectorUsed(selector: Selector) {
    const contentListSources: ContentListSourceContainer[] = [];
    this.operationContainer.contentInfo.setSelectorUsed(selector, contentListSources);
  }

  private enableSelector(selector: Selector, mc: SelectorContainer, params?: SelectorCreationParams) {
    this.setSelectorUsed(selector);
    this.searchSelectorService.enableSelector(selector, <SelectorContainer>mc, params,
      {
        searchContainerCreated: () => {
        },
        selectorCallback: selectedObj => {
          this.searchSelectorService.disableSelector(<SelectorContainer>mc);
          mc.selectorCallback(selectedObj, selector, () => {
            this.loadObject().then();
          });
        }
      }
    );
  }

  private disableSelector() {
    this.searchSelectorService.disableSelector(<SelectorContainer>this.operationContainer);
  }

  private async postLoadOperations() {
    const sectionsContainer = this.operationContainer.rootSectionsContainer;
    let listName = this.primusRoute.params.listName;
    await this.setOperationContainer();
    this.currentObjectService.currentObject = sectionsContainer.rootObject;
    this.operationContainer.contentInfo.updateContentInfo(
      this.currentObjectService.isEditing, sectionsContainer.rootObject, listName);
    if (this.operationContainer.contentInfo.curListName) {
      listName = this.operationContainer.contentInfo.curListName;
    }
    this.operationContainer.contentInfo.templateGroupId = this.templateGroupId;
    if (!this.operationContainer.contentInfo.curListName) {
      this.operationContainer.contentInfo.curListName = listName;
    }
    await this.getMedia();

    this.operationContainer.contentInfo.setContentMenus = () => {
      this.setContentMenus();
    };
    await this.setContentMenus();
    for (let t = 0; t < this.operationContainer.operations.length; t++) {
      const operation = this.operationContainer.operations[t];
      if (operation.content_list === this.operationContainer.contentInfo.curListName) {
        await this.onSelectOperation(operation);
        break;
      }
    }
    this.loadingObjectInfo = false;
  }

  private async setOperationContainer() {
    this.operationContainer.art = this.operationContainer.rootSectionsContainer.rootObject;
    this.operationContainer.contentInfo = new ContentInfo();
    this.operationContainer.openOperationDialogFn = () => {
      this.operationDialogService.openOperationDialog(this.operationContainer).then(() => {
        this.loadObject().then();
      });
    };
    this.operationContainer.enableSelector = (selector, params) => {
      this.enableSelector(selector, this.operationContainer, params);
    };
    this.operationContainer.disableSelector = () => {
      this.disableSelector();
    };
  }

  private async setContentMenus() {
    const contentInfo = this.operationContainer.contentInfo;
    // const noListSearch = this.currentObjectService.isEditing || this.isNotComputer;
    contentInfo.setCurrentList = (listName) => {
      this.setCurrentList(listName);
    };
    if (!contentInfo.menus) {
      contentInfo.menus = await this.contentMenusService.getMenus(contentInfo);
    }
    await this.contentMenusService.setVerticalContentMenus(contentInfo.menus, contentInfo);
  }

  private setCurrentList(listName) {
    const ci = this.operationContainer.contentInfo;
    ci.curListName = listName;
    ci.curListContainer = null;
    ci.setContentListSource(null);
    if (ci.menus) {
      this.contentMenusService.setActiveVerticalMenu(ci.menus, ci);
    }
    if (ci.contentListContainers[listName]) {
      this.contentMenusService.runListSearch(ci.curListName, ci).then();
    } else if (ci.sources && ci.sources[listName]) {
      ci.setContentListSource(ci.sources[listName]);
    }
  }

  private async getMedia() {
    const object = this.operationContainer.rootSectionsContainer.rootObject;
    object.$$mediaContainer = await this.mediaHelper.getMediaContainerForAllObjectMedia(object);
  }

}
