/**
 * Defines a field used in the lists in Advanced search and Search category.
 */
import {MetaField} from '../meta-field';
import {InputOptions} from './input-options';

export class SearchableField {
  fieldId: string;
  indexName: string;
  title: { name: string, alternateName: string };
  appearsOn: Array<{ artifactId: string, name: string }>;
  meta: Partial<MetaField>;
  inputOptions: InputOptions;

  public static sortFn(a: SearchableField, b: SearchableField): number {
    const aStr = a && a.title ? a.title.name + a.title.alternateName : '';
    const bStr = b && b.title ? b.title.name + b.title.alternateName : '';
    // eslint-disable-next-line curly
    if (aStr > bStr) return 1;
    // eslint-disable-next-line curly
    else if (aStr < bStr) return -1;
    // eslint-disable-next-line curly
    else return 0;
  }
}
