import {Injectable} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {GetArtifactParams} from '../../core/definitions/get-artifact-params';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {SearchService} from "../../core/search.service";

export class Location extends SuperObjectModel {
  superobject_type_id: string;
  parent_id_value: string;
  m_path: string;
  level: number;
  is_leaf: boolean;
  valid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AdminLocationPlaceService {

  constructor(private readonly cmsApi: CmsApiService,
              private readonly searchService: SearchService) { }

  public async getLocation(locationId: string): Promise<Location> {
    return await this.cmsApi.getArtifact({
      artifact_id: locationId
    } as GetArtifactParams) as Location;
  }

  public async getDefaultLocationModel(objectType): Promise<Location> {
    return await this.cmsApi.createArtifact({
      object_type: objectType
    }) as Location;
  }

  public async saveLocation(location: Location): Promise<Location | null> {
    const res: any = await this.cmsApi.saveArtifact({
      artifact: location
    });
    if (!res) {
      return null;
    }
    location.artifact_id = res.artifact_id;
    return location;
  }

  // eslint-disable-next-line max-len
  public async getObjectNamesWhereLocationIsUsed(locationId: string): Promise<{numObjects: number, objects: Array<{id: string, name: string}>}> {
    const res = await this.searchService.search({
      query: `permanent_placement.location_id:"${locationId}"`,
      fl: ['artifact_id', 'artifact_name'],
      sort: 'artifact_name asc'
    } as SearchParameters);
    return {
      numObjects: res.search_count,
      objects: res?.artifacts?.map(a => ({
        id: a.artifact_id,
        name: a.artifact_name
      }))
    };
  }
}
