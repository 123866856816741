<div class="edit-field-search-selector-multiple-item-list">
  @if (itemsWithLimit.length > 0) {
    <mat-chip-listbox
      class="mat-chip-list-stacked edit-field-search-selector-multiple-item-list__selected-options"
      cdkDropList
      cdkDropListOrientation="mixed"
      (cdkDropListDropped)="drop($event)">
      @for (item of itemsWithLimit; track item; let i = $index) {
        @if (!crud.getDestroy(item)) {
          <mat-chip-option cdkDrag
                           [cdkDragDisabled]="reference?.search_display_limit >= 1"
                           [removable]="!reference?.ref_delete_disabled && (item.$$deletable || item.$$justAdded)"
                           (removed)="deleteItemByIndex(item.$$index)">
            @if (item) {
              <app-object-icon class="edit-field-search-selector-multiple-item-list__selected-option-icon"
                               [object]="item.$$object"></app-object-icon>
              <span class="edit-field-search-selector-multiple-item-list__selected-option-name">{{item.$$object.artifact_name}}</span>
            }
            @if ((reference?.can_edit_existing || reference?.can_edit_context) && !sectionsContainer.isCopy) {
              <a (click)="editItem(item)"
                 class="text-blue-hover edit-field-search-selector-multiple-item-list__selected-option-edit">
                <i class="icon-edit-alternative"></i></a>
            }
            @if (!reference?.ref_delete_disabled) {
              <mat-icon matChipRemove>cancel</mat-icon>
            }
          </mat-chip-option>
        }
      }
    </mat-chip-listbox>
  }
</div>
