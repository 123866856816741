<div class="object-procedure-stepper">
  <div class="line-container"><div class="line"></div></div>
  <div class="object-procedure-stepper__stepper">
    <ng-container *ngFor="let step of procedureSteps">
      <a class="object-procedure-stepper__step"
         [routerLink]="primusStateMapper.routerLinkFromState(currentState)"
         [queryParams]="primusStateMapper.queryParams({stepName: step.procedure_object_status_id_value})"
         queryParamsHandling="merge"
         [ngClass]="{'notLastStep': !step.is_end_step}"
         (click)="setCurrentStepperMenu(step)">
        <div class="object-procedure-stepper__step-box-container">
          <div *ngIf="!step.is_end_step"  class="object-procedure-stepper__vertical-line"></div>
          <div *ngIf="step.is_end_step" class="object-procedure-stepper__arrow-down"></div>
          <div class="object-procedure-stepper__step-box"
               [class.bg-blue]="currentProcedureStep.procedure_object_status_id_value === step.procedure_object_status_id_value"
               [class.bg-not-active]="currentProcedureStep.procedure_object_status_id_value !== step.procedure_object_status_id_value">
            <i class="{{step['icon']}}"></i>
          </div>
        </div>
        <span class="object-procedure-stepper__step-name">
          <span>{{step.procedure_object_status_id_value}}</span>
          <span class="object-procedure-stepper__step-count"
          >({{step.contentListSource.array.length}})</span></span>
      </a>
    </ng-container>
  </div>
</div>

