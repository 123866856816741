<div>
  @switch (field.input_type) {
    @case (FieldInputType.INLINE) {
      <div>
        @for (subField of field.sub_fields; track subField; let i = $index) {
          <app-edit-field-inline-field
            [displayRequiredIndication]="displayRequiredIndication"
            [subFieldIndex]="i"
            [sectionsContainer]="sectionsContainer"
            [grandParentObject]="myObject"
            [object]="myObject[field.name]"
            [field]="subField"
            [index]="index"
            [parentIndex]="parentIndex"
          ></app-edit-field-inline-field>
        }
      </div>
    }

    @case (FieldInputType.INLINE_ARRAY) {
      <div>
        <app-edit-field-inline-array
          [displayRequiredIndication]="displayRequiredIndication"
          [sectionsContainer]="sectionsContainer"
          [object]="myObject"
          [field]="field"
          (fieldOutsideTheScreen)="checkIfFieldOutsideTheScreen()"
          [parentIndex]="index"
        ></app-edit-field-inline-array>
      </div>
    }
    @case (FieldInputType.CONTEXT) {
      <div>
        @if (sectionsContainer.isDialog) {
          <app-edit-field-context
            [contexts]="sectionsContainer.rootObject[field.name]"
            [field]="field"
          ></app-edit-field-context>
        }
      </div>
    }
    @case (FieldInputType.ACTION_BUTTON) {
      <div>
        <app-field-action-button
          class="section-field__action-button"
          [sectionsContainer]="sectionsContainer"
          [object]="myObject"
          [field]="field"
          [edit]="true"
          [parentIndex]="index"
          (closeEdit)="onCloseEdit()"
        ></app-field-action-button>
      </div>
    }
    @case ('compare_value') {
      <app-edit-field-compare
        [displayRequiredIndication]="displayRequiredIndication"
        [sectionsContainer]="sectionsContainer"
        [field]="field"
        [parentIndex]="parentIndex"
        [index]="index"
      ></app-edit-field-compare>
    }
    @case (FieldInputType.META_OPERATION_FIELD) {
      <app-edit-field-meta-operation-field
        [displayRequiredIndication]="displayRequiredIndication"
        [sectionsContainer]="sectionsContainer"
        [field]="field"
        [setAddChoice]="sectionsContainer.rootObject['set_add_choice']"
      ></app-edit-field-meta-operation-field>
    }
    @default {
      <div>
        <app-edit-field
          [displayRequiredIndicaton]="displayRequiredIndication"
          [sectionsContainer]="sectionsContainer"
          [grandParentObject]="grandParentObject"
          [object]="myObject"
          [field]="field"
          [index]="index"
          [parentIndex]="parentIndex"
        ></app-edit-field>
      </div>
    }
  }
</div>
