<ul [formGroup]="sectionsContainer.formGroup">
  @for (field of fields; track field) {
    @if (canShow(field)) {
      <li class="list-inline-array-item-content__item"
          [ngClass]="{'userRights':field[AConst.PARENT_NAME] === 'user_rights'}">

        <app-edit-field-switch
          [sectionsContainer]="sectionsContainer"
          [field]="field"
          [grandParentObject]="grandParentObject"
          [object]="object"
          [index]="index"
          [parentIndex]="parentIndex"
        ></app-edit-field-switch>

      </li>
    }
  }
</ul>
