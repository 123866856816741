<div class="search-filter-group"
     [ngClass]="{'sideMenu': sideMenu, 'dropdownMenu': !sideMenu}">
  <button mat-button (click)="toggle()" class="search-filter-group__title-button">
    <span class="search-filter-group__title-button-text-container">
      <span class="search-filter-group__title-button-content-container">
        <span class="search-filter-group__title-button-text"
              [translate]="filterGroup.title"></span>
        @if (filterGroup.active) {
          <span class="search-filter-group__title-button-count">({{getTotalCount()}})</span>
        }
      </span>
      @if (!sideMenu) {
        <mat-icon>{{filterGroup.enabled ? 'expand_less' : 'expand_more'}}</mat-icon>
      }
    </span>
  </button>

@if (filterGroup.enabled) {
  <div class="dropdownIgnoreClicks">

    @if (hasManyFilters) {
    <div class="search-filter-group__input">
      <input type="text"
             [placeholder]="'TRANS__FILTER__SEARCH_PLACEHOLDER' | translate"
             [(ngModel)]="filterGroup.filterFilter.query"
             (ngModelChange)="queryChanged()"
             class="search-input">
      <mat-icon class="search-icon">search</mat-icon>
    </div>
  }
    <ul class="search-filter-group__items">
    @for (filter of filterGroup.visibleFilters; track filter; let i = $index) {
      <li class="search-filter-group__item filter-checkbox">
        <mat-checkbox (click)="checkFilter(filter)"
                      [(ngModel)]="filter.checked"
                      class="search-filter-group__item-checkbox">

          <span class="search-filter-group__item-text-container" [ngClass]="{'bold': filter.checked}">
          @if (filter.title) {
            <span class="search-filter-group__item-text"
                  [translate]="filter.title"></span>
          }
          @if (filter.noTransTitle) {
            <span class="search-filter-group__item-text" [innerHTML]="filter.noTransTitle"></span>
          }
          </span>
          @if (filter.count) {
            <span class="search-filter-group__item-text-count">({{filter.count}})</span>
          }
        </mat-checkbox>
      </li>
    }
    @if (filterGroup.filterFilter?.hasMore) {
      <li class="search-filter-group__item">
        <button mat-button (click)="showMore()" class="search-filter-group__showMore">
          <span>...</span>
          <span [translateParams]="{count: filterGroup.filterFilter.filterCount - filterGroup.filterFilter.showMoreCount }"
                translate="TRANS__FILTER__SHOW_MORE"></span>
        </button>
      </li>
    }
    </ul>

  </div>
}
</div>
