import {TwoWayMap} from "../TwoWayMap";
import {DefaultModelAdapter} from "../DefaultModelAdapter";

export class ReportUserTemplateAdapter<ReportUserTemplate> extends DefaultModelAdapter<ReportUserTemplate> {
  keyConversionMap: TwoWayMap = new TwoWayMap();

  constructor(singularKey: string, pluralKey: string) {
    super(singularKey, pluralKey);
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.urlPrefix = '/cms_api/v1.0/report';
    this.urlForId = null;
    this.urlForList = null;

    this.keyConversionMap.set("reportViewTypeId", "report_view_type_id");
    this.keyConversionMap.set("reportViewOptions", "report_view_options");
    this.keyConversionMap.set("fieldSections", "field_sections");
  }
}
