<div class="primus-solr-tree-view-container">
  <mat-tab-group class="tab-group">


    <mat-tab [label]="tabName | translate">
      <div class="tree-container">
        <app-tree-node [node]="rootNode"
                       [children]="children"
                       [selected]="selectedNode"
                       [loading]="loading"
                       [showReloadButton]="true"
                       [alwaysExpanded]="false"
                       (onOpen)="loadChildren($event)"
                       (onSelect)="onSelect($event)"
                       (onReload)="loadChildren($event, true)">
        </app-tree-node>
      </div>
    </mat-tab>


    <mat-tab [label]="'TRANS__SOLR_TREE_VIEW__SEARCH_TAB_NAME' | translate">
      <div class="tab-top">
        <mat-form-field class="search-input" appearance="outline">
          <mat-label>{{ searchInputLabel | translate }}</mat-label>
          <!--suppress HtmlFormInputWithoutLabel -->
          <input matInput
                 type="text"
                 [formControl]="searchControl"
                 autocomplete="off">
          <button mat-icon-button
                  matSuffix
                  type="button"
                  (click)="searchControl.setValue('')">
            <mat-icon color="warn">clear</mat-icon>
          </button>
          <button mat-icon-button
                  matSuffix
                  type="button"
                  (click)="search()">
            <mat-icon color="accent">search</mat-icon>
          </button>
        </mat-form-field>

        <mat-progress-bar color="accent" *ngIf="searching" mode="indeterminate">
        </mat-progress-bar>
      </div>

      <div class="tree-container">
        <app-tree-node [node]="rootNode"
                       [children]="searchChildren"
                       [selected]="selectedNode"
                       [showReloadButton]="false"
                       [alwaysExpanded]="true"
                       (onSelect)="onSelect($event)">
        </app-tree-node>
      </div>

      <div class="tab-bottom">
        <button mat-stroked-button
                color="accent"
                type="button"
                [disabled]="!enableSearchLoadMoreButton"
                (click)="search(true)">
          {{ 'TRANS__SOLR_TREE_VIEW__LOAD_MORE_BUTTON_LABEL' | translate }}
        </button>
      </div>

    </mat-tab>


  </mat-tab-group>
</div>
