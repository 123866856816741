import {Component, Input, OnChanges} from '@angular/core';
import {ObjectViewDataService} from '../../core/object-view-data.service';
import {ObjectView} from '../../core/definitions/object-view';
import {ObjectEditService} from '../../core/object-edit.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {RefData, RefParams, RefService} from '../../core/ref.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {ObjectTypes} from "../../core/definitions/object-types";

@Component({
  selector: 'app-object-view-tabbed-sections',
  templateUrl: './object-view-tabbed-sections.component.html',
  styleUrls: ['./object-view-tabbed-sections.component.scss']
})
export class ObjectViewTabbedSectionsComponent implements OnChanges {
  @Input() objectId: string;
  @Input() folderId: string;
  @Input() templateGroupId: string;

  objectViewData: ObjectView;
  sectionsContainer: SectionsContainer;

  constructor(private objectViewDataService: ObjectViewDataService,
              private refService: RefService,
              private readonly primusRouter: PrimusRouterService,
              private objectEditService: ObjectEditService) { }

  ngOnChanges(): void {
    this.setObjectViewData().then();
  }

  private async setObjectViewData() {
    this.sectionsContainer = await this.objectEditService.loadObjectGetSectionsContainer(this.objectId, false, this.templateGroupId);
    this.objectViewData = this.sectionsContainer.objectView;
  }

  private async createRef(searchItem): Promise<RefData> {
    const params = new RefParams(searchItem);
    params.rootObjId = this.folderId;
    params.rootObjType = ObjectTypes.FOLDER;
    return await this.refService.makeRef(params);
  }

  async goToObjectState(item) {
    const refInfo = await this.createRef(item);
    await this.primusRouter.navigateState(refInfo.stateName, refInfo.param);
  }
}
