<div class="dialog admin-concept-edit-concept {{'ignoreClicksModal-' + concept.artifact_name}}">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title" *ngIf="ready">
      <span *ngIf="concept.has_authority">{{'TRANS__ADMIN_CONCEPT__VIEW' | translate:{conceptType: data.conceptType.concept_name, name: concept.artifact_name} }}</span>
      <span *ngIf="concept.artifact_id && !concept.has_authority">{{'TRANS__ADMIN_CONCEPT__EDIT' | translate:{conceptType: data.conceptType.concept_name} }}</span>
      <span *ngIf="!concept.artifact_id">{{'TRANS__ADMIN_CONCEPT__CREATE' | translate:{conceptType: data.conceptType.concept_name} }}</span>
    </h1>

    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="null">
      <mat-icon>close</mat-icon></button>
  </div>

  <div mat-dialog-content class="modal-body modal-body-edit-fields">
    <div class="modal-main-content">

      <span *ngIf="!ready" class="admin-concept-edit-concept__loading" translate="TRANS__ADMIN_CONCEPT__EDIT__LOADING"></span>
      <div *ngIf="ready" class="admin-concept-edit-concept__edit-fields-content">
        <app-object-edit
          *ngIf="!concept.has_authority"
          [sectionsContainer]="sectionsContainer"
        ></app-object-edit>
        <app-object-view
          *ngIf="concept.has_authority"
          [sectionsContainer]="sectionsContainer"
        ></app-object-view>
        <div *ngIf="concept.artifact_id">
          <div>
            <span translate="TRANS__CONCEPT__FIELD__UPDATED_AT"></span><span> {{updatedAt}}</span><span>&nbsp;</span>
            <span translate="TRANS__CONCEPT__FIELD__UPDATED_BY"></span><span> {{concept.updated_by_id_value}}</span>
          </div>
        </div>
      </div>

    </div>

  </div>
  <div mat-dialog-actions class="modal-footer">
    <div *ngIf="displayPrevNext"  class="admin-concept-edit-concept__previous-next-button">
      <button mat-stroked-button
              [disabled]="isFirst || !ready"
              color="primary"
              (click)="previous()">
        <span translate="TRANS__ADMIN_CONCEPT__PREVIOUS"></span></button>
      <button mat-stroked-button
              [disabled]="isLast || !ready"
              color="primary"
              (click)="next()">
        <span translate="TRANS__ADMIN_CONCEPT__NEXT"></span></button>
    </div>
    <div *ngIf="errorMessage" class="error-warn-color">{{errorMessage}}</div>
    <div [matTooltip]="disabledReason"
         [matTooltipDisabled]="!disabled">
      <button mat-flat-button
              [disabled]="disabled"
              color="accent"
              class="admin-concept-edit-concept__register-button"
              (click)="store()">
        <span  class="bold" translate="TRANS__ADMIN_CONCEPT__EDIT__SAVE"></span></button>
    </div>
    <button mat-button
            color="accent"
            class="admin-concept-edit-concept__cancel-button"
            (click)="closeEdit(false)">
      <span class="bold" translate="TRANS__ADMIN_CONCEPT__EDIT__CANCEL"></span></button>
  </div>
</div>
