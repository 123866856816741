<div class="object-new-copy-view">

  <div *ngFor="let selected of selectorContainer.searchContainer.searchResult.artifacts">
    <span *ngIf="selected.artifact_id === selectorContainer.searchContainer.selections.selectedItems[0].artifact_id"
          class="object-new-copy-view__copy-information">
      <span class="object-new-copy-view__copy-information-headline">
        <span translate="TRANS__CREATE_NEW__COPY_HEADLINE"></span>
        <span>&nbsp;</span>
        <span>{{ selected.artifact_name }}</span>
      </span>
      <p class="object-new-copy-view__copy-information-text">
        <span translate="TRANS__CREATE_NEW__COPY_TEXT"></span>
        <span *ngIf="!createMultiple" translate="TRANS__CREATE_NEW__COPY_TEXT_MORE_INFO"></span>
      <span *ngIf="createMultiple" translate="TRANS__CREATE_NEW__COPY_MULTIPLE_TEXT_MORE_INFO"></span>
      </p>
      <ng-container *ngIf="object?.history_events?.length">
        <p class="object-new-copy-view__copy-information-text">
          <span [innerHTML]="'TRANS__CREATE_NEW__COPY_WITH_HISTORY_EVENTS_INFO_1' | translate"></span>
        </p>
        <p class="object-new-copy-view__copy-information-text">
          <span translate="TRANS__CREATE_NEW__COPY_WITH_HISTORY_EVENTS_INFO_2"></span>
        </p>
        <p class="object-new-copy-view__copy-information-text">
          <span translate="TRANS__CREATE_NEW__COPY_WITH_HISTORY_EVENTS_INFO_3"></span>
        </p>
      </ng-container>
    </span>
  </div>

  <div class="row object-new-copy-view__content">
    <app-object-edit
      *ngIf="object"
      [sectionsContainer]="sectionsContainer"
    ></app-object-edit>
  </div>

</div>
