import {SelectorContainer} from './selector-container';
import {SuperObjectModel} from './super-object-model';
import {ObjectMenuInterface} from './object-menu-interface';
import {SearchContainer} from './search-container';

export class TemplateSelectorContainer implements SelectorContainer {
  art: SuperObjectModel;
  searchContainer: SearchContainer;
  selectorCallback;
  selectorEnabled: boolean;
  currentMenu: ObjectMenuInterface;
}
