<div class="dialog">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span [innerHtml]="data.modalTitle | translate"></span></h1>

    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="modal-body">

    <!-- Content is a string -->
    <div class="modal-main-content" *ngIf="!isArray; else contentArray">
      <span [innerHtml]="data.modalContent | translate"></span>
    </div>

    <!-- Content is a string[] -->
    <ng-template #contentArray>
      <mat-list class="modal-main-content">
        <mat-list-item *ngFor="let content of data.modalContent">
          <span [innerHTML]="content | translate"></span>
        </mat-list-item>
      </mat-list>
    </ng-template>

  </div>

  <div mat-dialog-actions class="modal-footer">
    <button type="button"
            mat-flat-button
            color="accent"
            (click)="dialogRef.close(false)">
      <span class="bold" translate="{{(data.modalButton) ? data.modalButton : 'TRANS__ALERT_DIALOG__OK'}}"></span></button>
  </div>
</div>

