<ng-container [formGroup]="fieldParameters.sectionsContainer.formGroup">
  <mat-radio-group
    [formControlName]="formControlName">
    @for (item of radioItems; track item) {
      <div>
        <mat-radio-button [value]="item[radioFieldName]"
                          (change)="setSelected(item)">
          <span [innerHTML]="item['$$view']"></span>
        </mat-radio-button>
      </div>
    }
  </mat-radio-group>
</ng-container>
