import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {FacetItem} from '../../core/definitions/search-objects';
import {MatExpansionPanel} from '@angular/material/expansion';
import {ContentListSourceContainer} from '../../core/definitions/object-content-tab/content-list-source-container';

@Component({
  selector: 'app-object-content-list-source-array-filter',
  templateUrl: './object-content-list-source-array-filter.component.html',
  styleUrls: ['./object-content-list-source-array-filter.component.scss']
})
export class ObjectContentListSourceArrayFilterComponent implements AfterViewInit {

  dropdownMenu = false;
  currentExpansionPanel = 0;
  @Input() contentListSource: ContentListSourceContainer;
  @Output() filterSet = new EventEmitter<boolean>();
  @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList: QueryList<MatExpansionPanel>;

  ngAfterViewInit() {
    // To avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.matExpansionPanelQueryList.first.expanded = true;
    });
  }

  setStep(index: number) {
    this.currentExpansionPanel = index;
  }

  setFilter(event, facetItem: FacetItem) {
    event.preventDefault();
    facetItem.selected = !facetItem.selected;
    this.filterSet.emit(false);
  }

}
