<button (click)="toggleToolTip()" mat-icon-button
        [matMenuTriggerFor]="tooltipMenu"
        [matMenuTriggerRestoreFocus]="false"
        (menuOpened)="showToolTip = true;"
        (menuClosed)="showToolTip = false;"
        class="blue-hover-icon"
        tabindex="-1"
        type="button"
        [ngClass]="{'text-blue': showToolTip}">
  <i class="icon-help"></i></button>

<mat-menu #tooltipMenu="matMenu" class="field-tool-tip">
  <div class="field-tool-tip__inner-container">

    <div class="field-tool-tip__title">
      <mat-icon>lightbulb_outline</mat-icon>
      <span class="bold">{{fieldName}}</span></div>

    @if (loading) {
      <mat-spinner color="accent" diameter="20"></mat-spinner>
    } @else if (tooltip.loaded) {
      <div class="field-tool-tip__text">
        @if (tooltip.default) {
          <p>{{tooltip.default}}</p>
        } @else {
          <p translate="TRANS__FIELD__TOOLTIP_NO_DESC"></p>
        }
        @if (tooltip.spectrum) {
          <div class="field-tool-tip__spectrum">
            <span class="bold" translate="TRANS__FIELD__TOOLTIP_SPECTRUM"></span>
            <p>{{tooltip.spectrum}}</p></div>
        }

      </div>
    }
    </div>
</mat-menu>

