import {UserCollectionItemsBase} from './user-collection-items-base';

export class UserCollectionItems implements UserCollectionItemsBase {
  object_type = 'UserCollectionItems';
  create: boolean;
  destroy: boolean;
  collection_id: string;
  collection_id_value: string;
  collection_rights_id: string;
  collection_rights_id_value: string;
  context_id: string;
  is_main_collection: boolean;
}
