import {Injectable, isDevMode} from '@angular/core';
import {LoggerService} from './logger.service';


@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  private static readonly logID = '[AccessTokenService]';

  constructor(private logger: LoggerService) {}

  public getToken() {
    if (!window.localStorage) {
      return null;
    }

    const token = window.localStorage.getItem('kit.access_token');
    if (!token && isDevMode()) { this.logger.error(`${AccessTokenService.logID} -- fetch token failed`); }
    return token;

  }

  public getIdToken() {
    if (!window.localStorage) {
      return null;
    }

    const token = window.localStorage.getItem('kit.id_token');
    if (!token && isDevMode()) { this.logger.error(`${AccessTokenService.logID} -- fetch token failed`); }
    return token;

  }

}
