export class SimpleClientCache {
  private CACHENAME  = 'primuscache';

  private mainCache: MainCache = new MainCache();
  private itemCache: Array<CacheObject> = null;

  constructor(private name: string, private expires: number = 3600) {
    if (!window || !window.localStorage) {
      console.warn('Unable to cache data!');
      return;
    }

    const cacheData = JSON.parse(window.localStorage.getItem(this.CACHENAME));
    if (cacheData && cacheData.items && cacheData.items.length > 0) {
      this.mainCache.put(cacheData.items);
    }
    this.itemCache = this.mainCache.get();
  }

  get() {
    let cachedData = null;
    this.itemCache = <Array<CacheObject>> this.itemCache;
    if (this.itemCache) {
      const cacheObj: Array<CacheObject> = this.itemCache.filter( (item: any) => {
        return item.key === this.name;
      });
      if (cacheObj.length > 0 && cacheObj[0].data) {
        cachedData = cacheObj[0].data;
      }
    }
    return cachedData;
  }

  put(data: any) {
    const cacheData = new CacheObject(this.name, this.expires, new Date().getTime() / 1000, data);

    if (this.itemCache) {
      const ix = this.itemCache.findIndex(item => {
        return item.key === this.name;
      });

      if (ix === -1) {
        this.itemCache.push(cacheData);
      } else {
        const item = this.itemCache[ix];
        if (new Date().getTime() / 1000 - item.updated > this.expires) {
          this.itemCache[ix] = cacheData;
        }
      }
    } else {
      this.itemCache = new Array<any>();
      this.itemCache.push(cacheData);
    }

    this.saveCache();
  }

  clear() {
    this.itemCache = null;
    this.saveCache();
  }

  private saveCache() {
    this.mainCache.put(this.itemCache);
    window.localStorage.setItem(this.CACHENAME, JSON.stringify(this.mainCache));
  }
}

class MainCache {
  private items: Array<CacheObject>;

  put(items: Array<CacheObject>) {
    this.items = items;
  }

  get() {
    return this.items;
  }
}

class CacheObject {
  public key: string = null;
  public expires = 0;
  public updated = 0;
  public data: object = null;

  constructor(key: string, expires: number, updated: number, data: any) {
    this.key = key;
    this.expires = expires;
    this.updated = updated;
    this.data = data;
  }
}
