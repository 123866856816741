import {Component, Input, OnChanges} from '@angular/core';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';
import {ConceptMenu} from '../../admin-concept-lists/concept-menu';
import {ConceptService} from '../../../core/concept.service';
import {AfterCloseOperation, OperationService} from '../../../operations/operation.service';
import {OperationTarget} from '../../../core/definitions/operation-target.enum';
import {OperationContainer} from '../../../core/definitions/operation-container';
import {OperationDef} from '../../../core/definitions/operation-def';
import {SuperObjectModel} from '../../../core/definitions/super-object-model';
import {OperationDialogService} from '../../../operations/operation-dialog.service';
import {ConceptMetaOperationService} from '../../admin-concept-lists/concept-meta-operation.service';
import {FeatureFlagsService} from "../../../core/feature-flags.service";

@Component({
  selector: 'app-admin-action-list-menu',
  templateUrl: './admin-action-list-menu.component.html',
  styleUrls: ['./admin-action-list-menu.component.scss']
})
export class AdminActionListMenuComponent implements OnChanges {

  constructor(private conceptService: ConceptService,
              private conceptMetaOperationService: ConceptMetaOperationService,
              private operationService: OperationService,
              private operationDialogService: OperationDialogService,
              private featureFlagsService: FeatureFlagsService) {
  }

  @Input() conceptsContainer: ConceptsContainer;
  @Input() selectedItemLength: number; // this will cause ngOnChange when selecting items

  menus: Array<ConceptMenu> = [];
  operationContainer: OperationContainer;

  async openOperation(operation: OperationDef) {
    await this.operationService.openOperationView(this.operationContainer, operation, null,
      (operationRes: AfterCloseOperation) => {
        if (!operationRes.keepSelectedObjects) {
          this.conceptService.clearSelected(this.conceptsContainer);
        }
      }
    );
  }

  ngOnChanges(): void {
    if (!this.operationContainer) {
      this.setOperationContainer().then();
    } else {
      this.setOperations().then();
    }
  }

  private async setOperationContainer(): Promise<void> {
    this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.ADMIN_LIST_VIEW);
    this.operationContainer.refreshFn = () => {
      this.conceptService.clearSelected(this.conceptsContainer);
      this.conceptsContainer.conceptDataSource.loadConcepts(this.conceptsContainer);
    };
    this.operationContainer.openOperationDialogFn = (callback: any) => {
      this.operationDialogService.openOperationDialog(this.operationContainer).then(data => {
        if (callback) {
          callback(data);
        }
      });
    };
    if (!this.featureFlagsService.getFeatureFlags().temporary.newJoinOperation) {
      this.menus = await this.conceptMetaOperationService.getOperations(this.conceptsContainer);
    }
  }

  private async setOperations() {
    this.operationService.setOperationContextObjects(
      this.operationContainer,
      this.conceptsContainer.selected.map(conceptListItem => {
      const superObject = new SuperObjectModel();
      superObject.artifact_id = conceptListItem.artifact_id;
      superObject.artifact_name = conceptListItem.artifact_name;
      superObject.object_type = conceptListItem.object_type;
      superObject.meta_type = conceptListItem.meta_type;
      superObject.superobject_type_id = conceptListItem.superobject_type_id;
      superObject.authority_id = conceptListItem.authority_id;
      superObject.has_authority = conceptListItem.has_authority;
      return superObject;
    }));
    await this.operationService.setOperations(this.operationContainer);
  }

}
