<div class="admin-settings">
  <app-top-header [parent]="parent"
                  backRouterLink="/admin"
                  backRouterLinkLabel="TRANS__SECTION__ADMIN__TITLE"
                  class="admin-header-container"></app-top-header>
  <app-header-and-description heading="TRANS__ADMIN_SETTINGS__TITLE"
                              description="TRANS__ADMIN_SETTINGS__INGRESS">
    <div class="admin-settings__content">
      <div *ngFor="let setting of allSettings" class="admin-settings__item">
        <app-admin-settings-setting [setting]="setting"></app-admin-settings-setting>
      </div>
      <div class="admin-settings__item">
        <app-admin-settings-faq></app-admin-settings-faq>
      </div>
    </div>
  </app-header-and-description>
</div>
