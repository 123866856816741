import { Component, OnInit } from '@angular/core';
import {OperationService} from "../../../operations/operation.service";
import {OperationContainer} from "../../../core/definitions/operation-container";
import {OperationTarget} from "../../../core/definitions/operation-target.enum";
import {OperationDialogService} from "../../../operations/operation-dialog.service";
import {OperationStepType} from "../../../core/definitions/operation-step-type.enum";

@Component({
  selector: 'app-admin-service-index',
  templateUrl: './admin-service-index.component.html',
  styleUrls: ['./admin-service-index.component.scss']
})
export class AdminServiceIndexComponent implements OnInit {
  operationContainer: OperationContainer;


  constructor(private operationService: OperationService,
              private operationDialogService: OperationDialogService) { }

  ngOnInit(): void {
    this.getMenus().then();
  }

  async onOpenMenu() {
    if (this.operationContainer.currentOperation.$$currentStep.operation_step_type === OperationStepType.CREATE_OBJECT) {
      this.operationDialogService.openOperationDialog(this.operationContainer).then(afterClose => {
        console.log(afterClose);
      });
    } else {
      const res = await this.operationService.executeOperationStep(this.operationContainer);
      console.log(res);
    }
  }

  private async getMenus() {
    this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.INDEX_VIEW);
    await this.operationService.setOperations(this.operationContainer);
    if (this.operationContainer.operations?.length) {
      this.operationContainer.operations[0].$$showOperationMenu = true;
    }
  }

}
