<div class="annotation-points__top bold" [ngClass]="{'isDialog dialog': isDialog}">
  <span translate="TRANS__ANNOTATION__ANNOTATION_POINTS"></span>
</div>

<div class="annotation-points__bottom" [ngClass]="{'isDialog': isDialog}">
  <div class="annotation-points__text-container"
       *ngFor="let des of curAnn.getAnnotations(); index as i"
       (click)="curAnn.selectAnnotation(des)"
       [ngClass]="{'selectedAnnPoint bold':
       (des.$$selected === undefined && curAnn.selectedAnnotation &&
       curAnn.selectedAnnotation.description.description === des.description.description) ||  des.$$selected}">
    <span class="annotation-points__text">{{i + 1}}. {{des.description.description}}</span>
  </div>
</div>
