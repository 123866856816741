import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {CheckFilter, CheckFilterGroup} from '../../../core/definitions/search-objects';
import {SearchFilterService} from '../../search-filter.service';
import {SearchFacetService} from '../../search-facet.service';
import {SearchResultViewType} from '../../../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SearchExecutorService} from "../../search-executor.service";

@Component({
  selector: 'app-search-filter-group',
  templateUrl: './search-filter-group.component.html',
  styleUrls: ['./search-filter-group.component.scss']
})
export class SearchFilterGroupComponent implements OnChanges {

  @Input() filterGroup: CheckFilterGroup;
  @Input() sideMenu: boolean;
  @Input() searchContainer: SearchContainer;

  AConst = AConst;

  constructor(private searchFilterService: SearchFilterService,
              private searchFacetService: SearchFacetService,
              private searchExecutorService: SearchExecutorService) {
  }

  ngOnChanges() {
    if (this.searchContainer.searchResultViews.default === SearchResultViewType.SELECTOR ||
      this.searchContainer.searchResultViews.default === SearchResultViewType.CONTENT_LIST) {
      this.filterGroup.enabled = false;
    } else {
      if (!this.filterGroup.enabled) {
        this.filterGroup.enabled = !!this.filterGroup.filters.find(filter => filter.checked_value !== undefined);
        if (this.filterGroup.enabled) {
          this.filterGroup.active = true;
        }
      }
    }
  }

  get hasManyFilters() {
    let res = false;
    if (this.filterGroup?.checkFilters?.length > AConst.MAX_FILTER_LENGTH) {
      res = true;
    }
    return res;
  }

  checkFilter(filter: CheckFilter) {
    this.searchFilterService.checkCheckFilter(filter, this.searchContainer, false).then(() => {
      this.setVisibleFilters();
    });
  }

  toggle() {
    this.filterGroup.enabled = !this.filterGroup.enabled;
    if (this.filterGroup.enabled && !this.filterGroup.active) {
      this.filterGroup.active = true;
      this.setSearchSettings().then();
    }
  }

  private async setSearchSettings() {
    await this.searchExecutorService.runSearch(this.searchContainer);
    this.searchFilterService.checkMenusFromCheckedFilters(this.searchContainer);
    await this.searchFilterService.setCheckFilterGroups(this.searchContainer, false, true);
  }

  showMore() {
    this.filterGroup.filterFilter.showMoreCount += this.filterGroup.filterFilter.showMoreAdd;
    this.setVisibleFilters();
  }

  getTotalCount() {
    return this.searchFacetService.getTotalCount(this.filterGroup, this.searchContainer);
  }

  queryChanged() {
    this.filterGroup.filterFilter.showMoreCount = 10;
    this.setVisibleFilters();
  }

  private setVisibleFilters() {
    this.searchFilterService.setVisibleFilters(this.filterGroup);
  }

}
