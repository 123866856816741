<app-dropdown-menu [icon]="'settings'"  [stylingClass]="'simple-setting-button'">
  <app-dropdown-menu-item
    *ngFor="let operation of operations"
    class="operation-dropdown-menu__menu"
    [icon]="operation.menu_icon"
    (click)="openOperation(operation)"
    [text]="operation.menu_title"
    [disabled]="operation.$$disabled"
    [matTooltip]="operation.$$disabled && operation.$$toolTip"
  ></app-dropdown-menu-item>
</app-dropdown-menu>
