import {Injectable} from '@angular/core';
import {QueryMenu} from '../core/definitions/search-objects';
import {SearchContainer} from '../core/definitions/search-container';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class QueryFieldMenuService {

  constructor(private translateService: TranslateService) {
  }

  getCurrentQueryMenu(searchContainer: SearchContainer): QueryMenu {
    return searchContainer.queryContainer.selectedQueryMenu && searchContainer.currentPathView.search_view.query_menus.menus.find(
      menu => menu.query_type === searchContainer.queryContainer.selectedQueryMenu.query_type);
  }

  setSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    if (searchContainer.currentPathView.search_view.query_menus) {
      for (const menu of searchContainer.currentPathView.search_view.query_menus.menus) {
        menu.selected = false;
      }
    }
    const currentQueryMenu = this.getCurrentQueryMenu(searchContainer);
    if (currentQueryMenu) {
      currentQueryMenu.selected = true;
      searchContainer.queryContainer.selectedQueryMenu = currentQueryMenu;
      searchContainer.queryContainer.queryPlaceholder = searchContainer.queryContainer.selectedQueryMenu.description;
      this.setCurrentQueryPlaceholder(searchContainer, searchContainer.queryContainer.queryPlaceholder);
    } else {
      this.setDefaultSelectedQueryFieldMenu(searchContainer);
    }
  }

  private setDefaultSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    const qm = searchContainer.currentPathView.search_view.query_menus;
    if (qm) {
      const selectedQueryMenuExists = searchContainer.queryContainer.selectedQueryMenu
        && qm.menus.some(menu => menu.query_type === searchContainer.queryContainer.selectedQueryMenu.query_type);
      if (!selectedQueryMenuExists) {
        searchContainer.queryContainer.selectedQueryMenu = qm.menus[0];
      }
      if (!searchContainer?.searchCategory) {
        searchContainer.queryContainer.queryPlaceholder = searchContainer.queryContainer.selectedQueryMenu.description;
        this.setCurrentQueryPlaceholder(searchContainer, searchContainer.queryContainer.queryPlaceholder);
      }
    }
  }

  private setCurrentQueryPlaceholder(searchContainer: SearchContainer, placeholder: string) {
    this.translateService.get(placeholder).subscribe(value => {
      searchContainer.queryContainer.currentQueryPlaceholder = value;
    });
  }

}
