import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConceptDialogData} from '../../core/concept.service';
import {CultureHubParams} from '../../core/definitions/culture-hub-params';

@Component({
  selector: 'app-culture-hub-importer-dialog',
  templateUrl: './culture-hub-importer-dialog.component.html',
  styleUrls: ['./culture-hub-importer-dialog.component.scss']
})
export class CultureHubImporterDialogComponent implements OnInit {
  cultureHubData: CultureHubParams;

  constructor(public dialogRef: MatDialogRef<CultureHubImporterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConceptDialogData) {
  }

  ngOnInit(): void {
    this.cultureHubData = {
      entity_type: this.data.conceptType.kulturnav_params.entity_type,
      concept_type_id: this.data.conceptType.concept_type_id,
      sub_type_headline: this.data.conceptType.kulturnav_params.sub_type_headline,
      sub_type: this.data.conceptType.kulturnav_params.sub_type,
      phrase: '',
      multi_import: true
    } as CultureHubParams;
  }

}
