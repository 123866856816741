<div class="operation-view adminEventCreate">
  <div [@slideContent]="operationContainer.inModal">
    <div *ngIf="!operation || (sectionsContainers && !sectionsContainers.length)"><span translate="TRANS__CONTENT__PREPARING"></span></div>
    <div *ngIf="operation && sectionsContainers?.length">
      <div *ngIf="operationStep.selectors">
        <div *ngFor="let selector of operationStep.selectors">
          <a class="operation-view__copy-button"
             (click)="enableSelector(selector)">
            <i class="icon-copy"></i>
            <span class="underline" [translate]="selector.select_button_text"></span>
          </a>
        </div>
      </div>

      <ng-container *ngFor="let sectionsContainer of sectionsContainers">

        <app-edit-prime-fields
          *ngIf="operationStep.model_prime_fields"
          [sectionsContainer]="sectionsContainer"
        ></app-edit-prime-fields>

        <app-object-edit
          *ngIf="!operationStep.model_prime_fields"
          [sectionsContainer]="sectionsContainer"
          [sectionsToShow]="operationStep.model_sections"
        ></app-object-edit>

        <div *ngIf="operationStep.can_add_media" class="row">
          <app-media-selectors
            [object]="sectionsContainer.rootObject"
            [isDialog]="isDialog"
            [operationUploadContainer]="operation.$$operationUploadContainer"
            [annotateDashboardClass]="'annotate-dashboard-admin-event-create'"
          ></app-media-selectors>
        </div>

      </ng-container>

    </div>

  </div>
</div>
