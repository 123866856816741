<div class="report-preview-area">
  @if (reportHeaderColumns.length <= 3) {
    <div class="report-list-artifact-header">
      <div class="report-list-artifact-column"></div>
      <div class="report-list-artifact-column">ID</div>
      @for (header of reportHeaderColumns; track header) {
        <div class="report-list-artifact-column">{{ header }}</div>
      }
    </div>
  }
  @for (artifact of extractedArtifacts; track artifact.artifactId; let idx = $index, isEven = $even) {
    <div class="report-list-artifact-object">
      <div class="report-list-artifact-image">
        <div class="report-artifact-field-image-box">
          @if (artifact.thumbnailImage) {
            <app-thumbnail
              [item]="artifact.thumbnailImage"
              [stylingClass]="'report-artifact-field-image-img'"></app-thumbnail>
          } @else {
            <div class="default-image">
              No Image
            </div>
          }
        </div>
      </div>
      <div class="report-list-fields-grid">
        <div class="report-list-artifact-column">
          @if (reportHeaderColumns.length > 3) {
            <div class="report-list-artifact-column-header">ID</div>
          }

          {{ artifact.artifactName }}
        </div>

        @for (section of artifact.sections; track section.id) {
          @for (field of section.fields; track $index; let isEven = $even; let isFirst = $first) {
            <div class="report-list-artifact-column">
              @for (value of field.value; track $index; let isEven2 = $even, index = $index) {
                @if (reportHeaderColumns.length > 3 && index === 0) {
                  <div class="report-list-artifact-column-header">{{ field.key }}</div>
                }
                {{ value.values }}
              }
            </div>
          }
        }
      </div>
    </div>
  }
</div>
