
export const searchSettingsParams = {
  all_selected: { type: 'string', raw: true },
  cur_focus_id: { type: 'string', raw: true },
  filter: { type: 'string', raw: true},
  objectCount: { type: 'int', raw: true },
  order: { type: 'string', raw: true },
  path: { type: 'string', raw: true },
  searchItemIndex: { type: 'int', raw: true },
  search_engine: { type: 'string', raw: true },
  search_page: { type: 'int', raw: true },
  search_result_view: { type: 'string', raw: true },
  query: { type: 'string', raw: true },
  selected: { type: 'string', raw: true },
  targetId: { type: 'string', raw: true },
  // template_group_id: { type: 'string', raw: true }
};

export const searchStateParamMapper = {
  cur_focus_id: 'focus.curFocusId',
  filter: 'filtersFacets.checkedFilters',
  objectCount: 'objectCount',
  order: 'order',
  path: 'path',
  searchItemIndex: 'searchItemIndex',
  search_engine: 'searchEngine',
  search_page: 'searchPage',
  search_result_view: 'searchResultViewName',
  query: 'queryContainer.query',
  targetId: 'targetId',
  template_group_id: 'templateGroupId',
  list_field_template_id: 'listFieldTemplateId'
};
