<div class="dialog annotation-dialog">

  <div class="annotation-dialog__top">
    <app-annotation-parents
      [curAnn]="data.curAnn"
      [isDialog]="true"
      class="annotation-dialog__top-parent"></app-annotation-parents>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="null">
      <mat-icon>close</mat-icon></button>
  </div>

  <div mat-dialog-content class="annotation-dialog__content">
    <div class="annotation-dialog__image-container"
         id="annotate-dialog-container">
      <div class="annotation-dialog__image-content"
           id="annotate-dialog-parent">
        <app-annotation-canvas
          class="annotation-dialog__canvas-container"
          [curAnn]="data.curAnn"
          [parentContainerId]="'annotate-dialog-parent'"
          [isDialog]="true"
          [edit]="true"
          [dashboardId]="'annotate-dialog-dashboard'"
        ></app-annotation-canvas>

        <div id="annotate-dialog-dashboard" class="annotation-dialog__dashboard">
          <app-annotation-description
            class="annotation-dialog__dashboard-description"
            [curAnn]="data.curAnn"
            [isDialog]="true"></app-annotation-description>
          <app-annotation-points
            class="annotation-dialog__dashboard-points"
            [isDialog]="true"
            [curAnn]="data.curAnn"></app-annotation-points>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="annotation-dialog__footer">
    <div class="annotation-dialog__footer-shape-and-color">
      <app-annotation-shape-menu [curAnn]="data.curAnn" [isDialog]="true"></app-annotation-shape-menu>
      <app-annotation-color-menu [curAnn]="data.curAnn" [isDialog]="true"></app-annotation-color-menu></div>

    <app-annotation-dashboard-buttons [curAnn]="data.curAnn"></app-annotation-dashboard-buttons>
  </div>

</div>
