import {Component, Input, OnInit} from '@angular/core';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-menu-select-chosen',
  templateUrl: './search-menu-select-chosen.component.html',
  styleUrls: ['./search-menu-select-chosen.component.scss']
})
export class SearchMenuSelectChosenComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  isTemplateSelector = false;

  constructor(private readonly searchResultSelectionsService: SearchResultSelectionsService) {
  }

  ngOnInit() {
    this.isTemplateSelector = this.searchContainer.searchView.view_name === 'selector_template';
  }

  select() {
    if (this.searchContainer.selections.selectCallback) {
      this.searchContainer.selections.selectCallback(this.searchContainer.selections.selectedItems);
    } else {
      console.warn('Missing select callback');
    }
  }

  selectDisabled() {
    return !this.searchResultSelectionsService.selectionsChanged(this.searchContainer);
  }

}
