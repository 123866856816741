import {Component, Input} from '@angular/core';
import {BaseModel} from '../../core/definitions/base-model';

@Component({
  selector: 'app-overview-field-link',
  templateUrl: './overview-field-link.component.html',
  styleUrls: ['./overview-field-link.component.scss']
})
export class OverviewFieldLinkComponent {
  @Input() items: BaseModel[];
  @Input() disableLinks;
  @Input() toneDownTextColor: boolean;
  @Input() stylingClass: string;

  showMore = false;

  constructor() {
  }

}
