import {Component, OnInit} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {Setting} from '../../core/definitions/setting';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {

  allSettings: Array<Setting> = [];
  parent;

  constructor(private cmsApiService: CmsApiService) {
  }

  ngOnInit() {
    this.cmsApiService.getSettings().then(res => {
      this.allSettings = res;
    });
    this.setParent();
  }

  private setParent() {
    this.parent = {
      path: 'admin',
      adminType: 'settings',
      hasSubMenus: true,
      target_params: {admin_type: 'settings'},
      currentState: 'settings'
    };
  }
}
