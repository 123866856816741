import {Component, ViewEncapsulation} from '@angular/core';

/**
 * Provides the global primus-styles for material-3.
 * Note: Encapsulation is set to None to give style access to all children
 */
@Component({
  selector: 'app-m3-global-styles',
  standalone: true,
  imports: [],
  template: `<ng-content></ng-content>`,
  styleUrl: '../../material-3-styles.scss',
  encapsulation: ViewEncapsulation.None,
})
export class M3GlobalStylesComponent {

}
