import {Injectable} from '@angular/core';
import {UndoHandlerService} from './undo-handler.service';
import {DateToolsService} from './date-tools.service';
import {FieldMetaHandlerService, GetMetaFieldParams} from './field-meta-handler.service';
import {AConst} from './a-const.enum';
import {ModelsService} from './models.service';
import {CommonsService} from './commons.service';
import {MetaField} from './definitions/meta-field';
import {UserData} from './definitions/user-data';
import {BaseModel} from './definitions/base-model';
import {UserCacheService} from './user-cache.service';
import {LoggerService} from './logger.service';
import {FieldDateInfoService} from "./field-date-info.service";
import {CrudService} from "./crud.service";

@Injectable({
  providedIn: 'root'
})
export class ModelFactoryService {

  constructor(
    private logger: LoggerService,
    private undoHandler: UndoHandlerService,
    private dateTools: DateToolsService,
    private fieldMetaHandler: FieldMetaHandlerService,
    private models: ModelsService,
    private commons: CommonsService,
    private userCacheService: UserCacheService,
    private fieldDateInfoService: FieldDateInfoService,
    private crud: CrudService) {
  }

  userData: UserData;

  private isArrayItemDeleted(item: any) {
    return this.crud.getDestroy(item);
  }

  // TODO: Set to receive fieldContainer
  public getOrigVal(object: any, fieldName: string) {
    return object.$$orig && object.$$orig[fieldName];
  }

  public getInlineModel(metaField: MetaField) {
    if (!metaField) {
      throw new Error('Meta data not set!');
    }
    return metaField.inline ? metaField.inline.model : null;
  }

  public async setModelItemAsync(modelName: string, data?: BaseModel): Promise<BaseModel> {
    const models = await this.models.getModelsAsync();
    await this.checkSetUserData();
    return this.setModelItem(modelName, data, models);
  }

  public createModelItem(modelName: string, data?: any): BaseModel {
    this.checkSetUserData().then();
    const item = this.setModelItem(modelName, data);
    this.crud.setCreate(item);
    return item;
  }

  public async createModelItemAsync(modelName: string, data?: BaseModel): Promise<BaseModel> {
    const item = await this.setModelItemAsync(modelName, data);
    this.crud.setCreate(item);
    return item;
  }

  public createAddArrayItem(arr: any[], modelName: string,
                            data?: BaseModel) {
    const item = this.createModelItem(modelName, data);
    this.addArrayItem(arr, item);
    return item;
  }

  public async createAddArrayItemAsync(arr: any[], modelName: string, data: any): Promise<BaseModel> {
    const item = await this.createModelItemAsync(modelName, data);
    this.addArrayItem(arr, item);
    return item;
  }

  public deleteArrayItem(arr: BaseModel[], index: number, rootModel: BaseModel) {
    const item = arr[index];
    const modelIdField = this.getModelIdField(rootModel);
    if (this.crud.getCreate(item) || (modelIdField && !rootModel[modelIdField])) {
      arr.splice(index, 1);
      this.undoHandler.addUndo(arr, item, index);
    } else {
      this.crud.setDestroy(item, true);
      this.undoHandler.addUndo(arr, item);
    }
    return item;
  }

  public undoDeleteArrayItem(arr: any[]) {
    this.undoHandler.undo(arr);
  }

  // For-each loop that ignores destroyed array elements
  public forEach(arr: any[], fn: any) {
    arr.forEach((item, index) => {
      if (!this.isArrayItemDeleted(item)) {
        fn(item, index);
      }
    });
  }

  // Count array elements that have not been deleted
  public countArrayElements(arr: any[]) {
    let res = 0;
    arr.forEach(item => {
      if (!this.isArrayItemDeleted(item)) {
        res++;
      }
    });
    return res;
  }

  public traverseModelField(fn: any, model: BaseModel, fieldName: string) {
    const getMetaFieldParams = new GetMetaFieldParams();
    getMetaFieldParams.parentModel = model;
    getMetaFieldParams.propName = fieldName;
    getMetaFieldParams.noThrow = true;
    const metaField: MetaField = this.fieldMetaHandler.getMetaField(getMetaFieldParams);
    const subMod = model[fieldName];
    let show: string;
    if (metaField) {
      show = metaField.display;
    }
    if (metaField && (metaField.edit || show)) {
      fn(model, fieldName);
      if (this.getInlineModel(metaField)) {
        if (Array.isArray(subMod)) {
          subMod.forEach(
            (item, index) => {
              fn(model, fieldName, index);
              this.traverseModel(fn, item);
            });
        } else {
          this.traverseModel(fn, subMod);
        }
      }
    }
  }

  private setInlineDefaultValues(defValue: any, modelData: any) {
    for (const key in defValue) {
      if (!defValue.hasOwnProperty(key)) {
        continue;
      }
      const propValInfo = this.getPropVal(defValue, key);
      modelData[key] = propValInfo.value;
    }
  }

  // Set properties defined in model data but missing in model.
  // This is usually properties not to be stored, names starting with $$
  private setMissingDataProps(modelName: string, item: BaseModel, data: object,
                                     setProps: any) {
    if (data && typeof data !== 'object') {
      throw new Error('Data: ' + data + ' is not an object of model ' +
        'type ' + modelName);
    }
    for (const propName in data) {
      if (!data.hasOwnProperty(propName)) {
        continue;
      }
      const val = data[propName];
      if (val !== undefined && !setProps[propName]) {
        if (propName !== 'meta_type' &&
          propName.indexOf('$$') !== 0 &&
          !item.$$meta[propName]) {
          this.logger.warn(`Property "${modelName}.${propName}" of type "${typeof val}" is not defined in Models!`);
        }
        item[propName] = val;
      }
    }
  }

  private getModelIdField(model: BaseModel) {
    const meta = model.$$meta;
    let res: string;
    if (meta) {
      if (meta[AConst.ARTIFACT_ID]) {
        res = AConst.ARTIFACT_ID;
      } else {
        this.logger.info('No model id field found for object type ' + model.object_type);
      }
    }
    return res;
  }

  private setModelItemProps(modelItem: BaseModel, data: any, models: any) {
    const propsSet = {};
    for (const propName in modelItem) {
      if (!modelItem.hasOwnProperty(propName)) {
        continue;
      }
      this.setModelItemProp(modelItem, data, models, propName, propsSet);
    }
    return propsSet;
  }

  private setModelItemProp(modelItem: BaseModel, data: any, models: any, propName: string, propsSet: any) {
    const propValInfo = this.getPropVal(modelItem, propName, data);
    let propVal = propValInfo.value;
    const hadData = propValInfo.hadData;
    let inlineMod: any, origVal: any;
    if (propName.indexOf('$$') === 0) {
      return;
    }
    const getMetaFieldParams = new GetMetaFieldParams();
    getMetaFieldParams.parentModel = modelItem;
    getMetaFieldParams.propName = propName;
    getMetaFieldParams.noThrow = true;
    const metaData = this.fieldMetaHandler.getMetaField(getMetaFieldParams);
    if (metaData) {
      inlineMod = this.getInlineModel(metaData);
      if (inlineMod) {
        propVal = this.createSubModelItem(propVal, metaData,
            hadData, models);
      }
      const dateInfo = this.fieldDateInfoService.getFieldDateInfo(metaData);
      if (dateInfo?.today_date && !propVal) {
        propVal = this.dateTools.getTodayUtcTime();
      }
      origVal = this.findOrigVal(metaData, propVal);
      if (origVal !== undefined) {
        modelItem['$$orig'] = modelItem['$$orig'] || {};
        modelItem['$$orig'][propName] = origVal;
      }
    }
    modelItem[propName] = propVal;
    propsSet[propName] = true;
  }

  private getPropVal(item: any, propName: string, data?: any) {
    const res = {value: null, hadData: false};
    if (!propName.indexOf('$$') && propName !== '$$meta') {
      return res;
    }
    if (data) {
      res.value = data[propName];
      res.hadData = res.value !== null && res.value !== undefined;
    }
    res.value = !res.hadData ? item[propName] : res.value;
    if (typeof res.value !== 'string' || res.value.indexOf('user.') !== 0) {
      return res;
    }
    if (res.value === 'user.main_collection_id') {
      this.setMainCollectionId(res, propName);
    }
    if (res.value === 'user.artifact_id') {
      this.setUserId(res, propName);
    }
    return res;
  }

  private setMainCollectionId(res: any, propName: string) {
    if (this.userData) {
      if (propName.indexOf('_value') === -1) {
        res.value = this.userData.main_collection_id;
      } else {
        res.value = this.userData.main_collection_id_value;
      }
    } else {
      this.logger.warn('User data not obtained yet!');
    }
  }

  private setUserId(res: any, propName: string) {
    if (this.userData) {
      if (propName.indexOf('_value') === -1) {
        res.value = this.userData.artifact_id;
      } else {
        res.value = this.userData.name;
      }
    } else {
      this.logger.warn('User data not obtained yet!');
    }
  }

  private async checkSetUserData() {
    this.userData = await this.userCacheService.getUserData();
  }

  // Set sub model items belonging to array or inline objects
  // of a parent model item
  private createSubModelItem(origVal: any, metaField: MetaField, hadData: boolean, models: any) {
    let arr: any[], propVal = origVal;
    const primitives = ['string', 'numeric', 'decimal',
      'boolean', 'text'];
    const inlineMod = this.getInlineModel(metaField);
    models = models || this.models.getModels(false);
    if (metaField.field_type === 'inline') {
      if (!hadData) { // Get default value from sub model
        propVal = this.commons.copy(models[inlineMod]);
        this.setInlineDefaultValues(origVal, propVal);
      }
      propVal = this.setModelItem(inlineMod, propVal, models);
    } else if (metaField.field_type === 'array') {
      arr = [];
      if (propVal) {
        propVal.forEach((arrItem: any) => {
          if (primitives.indexOf(inlineMod) !== -1) {
            arr.push(arrItem);
          } else {
            arr.push(this.setModelItem(inlineMod,
              arrItem, models));
          }
        });
      }
      propVal = arr;
    }
    return propVal;
  }

  // Recursively populate a model with data. "Data" can either be
  // data from server or default values defined in model
  private setModelItem(modelName: string, data?: BaseModel, models?: { [name: string]: BaseModel }): BaseModel {
    let propsSet = {};
    let modelItem = new BaseModel();
    this.crud.setCreate(modelItem, false);
    models = models || this.models.getModels(false);
    if (models !== null && modelName in models) {
      modelItem = this.commons.copy(models[modelName]);
      propsSet = this.setModelItemProps(modelItem, data, models);
      this.setMissingDataProps(modelName, modelItem, data,
        propsSet);
    } else {
      this.logger.warn('Unknown model \'' + modelName + '\'');
    }
    return modelItem;
  }

  private addArrayItem(arr: any[], item: BaseModel) {
    arr.push(item);
    this.undoHandler.resetUndo(arr);
    this.checkSetOrderNumber(arr, item);
  }

  private checkSetOrderNumber(arr: any[], item: any) {
    let lastOrder = -1;
    const orderNumbers = {};
    if (!item.order_number) {
      arr.forEach((i) => {
        if (i.order_number !== undefined && i.order_number !== null) {
          if (orderNumbers[i.order_number]) {
            this.logger.warn('Order number already existed: ' + i.order_number);
            i.order_number++;
          }
          lastOrder =
            Math.max(i.order_number, lastOrder);
          orderNumbers[i.order_number] = true;
        }
      });
      item.order_number = lastOrder + 1;
    }
  }

  private findOrigVal(metaData: MetaField, propVal: any) {
    let val: any;
    const arr = [];
    const inlineMod = this.getInlineModel(metaData);
    const show = metaData.display;
    if ((metaData.edit && metaData.edit.indexOf('edit') === 0) ||
      show) {
      val = propVal;
      if (inlineMod) {
        if (Array.isArray(propVal)) {
          for (let t = 0; t < propVal.length; t++) {
            const arrVal = propVal[t].order_number;
            arr.push(arrVal);
          }
          val = arr;
        }
      }
    }
    return this.commons.copy(val);
  }

  /**
   * Traversing an object and executing a callback every time
   * an editable or displayable field is reached
   * @param fn the callback receives the parameters model, field
   * name and, if the model is an array, an index for each item
   * in the array
   *
   * @param model the model to traverse
   */
  private traverseModel(fn: any, model: BaseModel) {
    for (const fieldName in model) {
      if (!model.hasOwnProperty(fieldName)) {
        continue;
      }
      if (fieldName.indexOf('$$') !== 0) {
        this.traverseModelField(fn, model, fieldName);
      }

    }
  }
}
