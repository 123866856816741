import {Component, Input, OnInit} from '@angular/core';
import {ViewField} from '../../core/definitions/object-view';

@Component({
  selector: 'app-display-field-title',
  templateUrl: './display-field-title.component.html',
  styleUrls: ['./display-field-title.component.scss']
})
export class DisplayFieldTitleComponent implements OnInit {

  @Input() field: ViewField;
  @Input() titleClass: string;

  constructor() {
  }

  ngOnInit() {
  }

}
