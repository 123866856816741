<div class="procedure" [ngClass]="{'edit': currentObjectService.isEditing}">
  <div class="procedure__search-menu">
    <app-object-search-menu
      class="procedure__search-menu-content"
      [object]="operationContainer?.rootSectionsContainer?.rootObject"
      [isEditing]="currentObjectService.isEditing"
      [stylingClass]="'sp-procedure-page'"
    ></app-object-search-menu>
    <div class="procedure__template">
      <div class="procedure__template-button">
        <app-template-group-select-button
          *ngIf="operationContainer?.contentInfo?.artifact"
          [object]="operationContainer.contentInfo.artifact"
          [collapseOnSmallScreen]="false"
          (templateSelectorCreated)="onTemplateSelectorCreated($event)"
          (changeTemplateGroup)="onChangeTemplateGroup($event)"
          [templateGroupId]="operationContainer.contentInfo.templateGroupId"
          [disabled]="currentObjectService.isEditing"
        ></app-template-group-select-button>
      </div>
    </div>
  </div>

  <app-search-view-selector
    *ngIf="operationContainer?.selectorEnabled"
    class="procedure__selector"
    [ngClass]="{'dialogOpen': operationContainer.inModal}"
    [modalIsOpen]="operationContainer.inModal"
    [selectorContainer]="operationContainer"
  ></app-search-view-selector>
  <app-search-view-selector
    class="procedure__selector"
    *ngIf="templateSelectorContainer.selectorEnabled"
    [selectorContainer]="templateSelectorContainer"
  ></app-search-view-selector>
  <div *ngIf="showImageOrder"
       class="side-selector change-img-order-selector procedure__change-image-order-selector">
    <app-change-media-order
      *ngIf="operationContainer.rootSectionsContainer.rootObject && operationContainer.rootSectionsContainer.rootObject.$$mediaContainer?.mediaTypes.length"
      [mediaContainer]="operationContainer.rootSectionsContainer.rootObject.$$mediaContainer"
      [sectionsContainer]="operationContainer.rootSectionsContainer"
      (toggleChangeMediaOrder)="onToggleChangeImageOrder()"></app-change-media-order>
  </div>
  <div class="procedure__content">
    <div class="procedure__top">
      <div class="procedure__top-fields"
           [@showAllFields]="showAllFieldInformation || currentObjectService.isEditing ? 'openTop' : 'closedTop'">
        <app-procedure-view-and-edit
          *ngIf="operationContainer?.rootSectionsContainer?.rootObject"
          [isEditing]="currentObjectService.isEditing"
          [showALLFields]="showAllFieldInformation"
          [operationContainer]="operationContainer"
          class="procedure__top-content"></app-procedure-view-and-edit>
        <span *ngIf="noProcedureId" translate="TRANS__PROCEDURE__UNABLE_TO_FIND_PROCEDURE"></span>
      </div>

      <a class="toggleProcedureFields"
         (click)="toggleFieldInformation()"
         [ngClass]="{'disable-button': currentObjectService.isEditing}">
        <div class="toggleProcedureFields__container">
          <div class="toggleProcedureFields__content text-blue-hover">
            <mat-icon>{{showAllFieldInformation ? 'expand_less' : 'expand_more'}}</mat-icon>
          </div>
        </div>
      </a>
    </div>
    <div class="procedure__lower"
         [ngClass]="{'edit': currentObjectService.isEditing,
         'smallScreen': smallScreen}"
         [@showAllFields]="showAllFieldInformation || currentObjectService.isEditing ? 'openBottom' : 'closedBottom'">
      <div class="procedure__vertical-menu"
           [@showContentMenu]="smallScreen && openMenu ? 'expandMenu' : 'collapseMenu'">
        <div *ngIf="loadingProcedureInfo" class="loading-operation-selector-vertical">
          <div class="loading-operation-selector-vertical__selected-first-menu"
               [ngClass]="{'isEditing': currentObjectService.isEditing}"></div></div>
        <app-operation-selector-vertical
          *ngIf="!loadingProcedureInfo && operationContainer"
          [operationContainer]="operationContainer"
          [editContent]="currentObjectService.isEditing"
          [contentList]="operationContainer.contentInfo.curListName"
          [smallScreen]="smallScreen"
          (selectOperation)="onSelectOperation($event)"
        ></app-operation-selector-vertical>
      </div>
      <div class="procedure__lower-content"
           [@showContentMenu]="smallScreen && openMenu ? 'pushContent' : 'pullContent'">

        <div *ngIf="loadingProcedureInfo" class="procedure__loading-content">
          <div class="procedure__loading-content-text-container">
            <mat-spinner color="accent" diameter="40"></mat-spinner>
            <span translate="TRANS__EDIT_FIELD_SELECT__HIERARCHY__LOADING"></span></div></div>

        <ng-container
          *ngIf="!loadingProcedureInfo && operationContainer?.contentInfo && operationContainer?.currentOperation">
          <ng-container *ngIf="operationContainer?.contentInfo.curListName === 'spectrum_procedure_object'">

            <div class="procedure__content-headline">
              <div class="procedure__title">
                <a *ngIf="smallScreen"
                   (click)="onToggleMenu()"
                   class="procedure__toggle-button">
                  <i class="icon-hamburger"></i>
                </a>
                <h3 *ngIf="operationContainer.contentInfo.selectedMainMenu"><span class="bold" [translate]="operationContainer.contentInfo.selectedMainMenu['caption']"></span>
                </h3>
              </div>
              <app-operation-menus-two-levels
                class="procedure__operation-buttons"
                [operationContainer]="operationContainer"
                [parentOperation]="operationContainer.currentOperation"
              ></app-operation-menus-two-levels>
              <app-procedure-content-view-selector
                class="procedure__operation-selector"
                [currentProcedureContentViewName]="currentProcedureContentViewName"
                (viewSelected)="onSelectView($event)"
              ></app-procedure-content-view-selector>
            </div>

            <div *ngIf="currentProcedureStep && currentProcedureContentViewName === 'steps'" class="procedure__stepper">
              <app-procedure-stepper
                [procedureSteps]="procedureSteps"
                [currentProcedureStep]="currentProcedureStep"
                [operationContainer]="operationContainer"
                [isEditing]="currentObjectService.isEditing"
                (stepSelected)="onStepSelected($event)"
              ></app-procedure-stepper>
            </div>
            <hr class="procedure__line">
            <div class="procedure__second-title" *ngIf="currentProcedureContentViewName === 'steps'">
              <h2 class="bold" *ngIf="operationContainer.contentInfo.selectedMainMenu">
                <span>{{operationContainer.contentInfo.curContentListSource.array.length}} &nbsp;</span>
                <span>{{contentListHeadline}}</span>
              </h2>
            </div>

          </ng-container>

          <app-procedure-content-list-source
            *ngIf="operationContainer.contentInfo.curContentListSource"
            [isEditing]="currentObjectService.isEditing"
            [smallScreen]="smallScreen"
            [operationContainer]="operationContainer"
            (toggleChangeImageOrder)="onToggleChangeImageOrder()"
            (toggleMenu)="onToggleMenu()"
            (procedureLoad)="loadProcedure()">
          </app-procedure-content-list-source>

          <app-procedure-content-list
            *ngIf="!operationContainer.contentInfo.curContentListSource"
            [isEditing]="currentObjectService.isEditing"
            [smallScreen]="smallScreen"
            [operationContainer]="operationContainer"
            (toggleMenu)="onToggleMenu()"
            (procedureLoad)="loadProcedure()">
          </app-procedure-content-list>

        </ng-container>

      </div>
    </div>
  </div>

  <div class="object-page__action-toolbar"
       *ngIf="currentObjectService.isEditing">
    <app-operation-toolbar
      *ngIf="operationContainer?.currentOperation?.$$currentStep"
      [operationContainer]="operationContainer"
      [operationStep]="operationContainer.currentOperation.$$currentStep"
    ></app-operation-toolbar>
  </div>

  <div class="overlay"
       *ngIf="operationContainer?.selectorEnabled || templateSelectorContainer.selectorEnabled"
       (click)="selectorOverlay()"
       [ngClass]="{'dialogOpen': operationContainer.inModal}"></div>
  <div class="overlay"
       *ngIf="operationContainer?.rootSectionsContainer?.rootObject?.$$mediaContainer && showImageOrder"
       (click)="onToggleChangeImageOrder()"></div>
</div>

