import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {ContentInfo} from '../../core/definitions/content-info';
import {SectionMenuContainer} from '../../object-view/section-menu/section-menu.component';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationAndSectionsContainerParams, OperationService} from '../../operations/operation.service';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {OperationStepType} from '../../core/definitions/operation-step-type.enum';
import {ObjectMediaContainer} from '../../core/definitions/object-media-container';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationExecutorType} from '../../core/definitions/operation-executor-type.enum';
import {PrimusRouterService} from '../../core/primus-router.service';
import {Subscription} from 'rxjs';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SearchSelectorService} from '../../core/search-selector.service';

@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss']
})
export class MediaPageComponent implements OnInit, OnDestroy {

  operationContainer: OperationContainer;
  contentInfo = new ContentInfo();
  art: SuperObjectModel = null;
  contextId;
  parentId;
  galleryParentId;
  mediaId;
  imageFullScreen = false;
  sectionMenuContainer = new SectionMenuContainer();
  artTarget;
  mediaContainer: ObjectMediaContainer;
  toggleAnnotations: boolean;
  mediumScreen: boolean;
  smallObjectMenus: boolean;
  smallScreen: boolean;
  scrollContent = false;
  finishedLoading = false;

  private mediaObjectType;
  private artifactId;
  private stopSizeWatch;
  private routerSubscription: Subscription;

  constructor(private primusRouter: PrimusRouterService,
              private primusRoute: PrimusRouteService,
              private mediaHelper: MediaHelperService,
              private objectEditService: ObjectEditService,
              private uiTools: UiToolsService,
              private searchSelectorService: SearchSelectorService,
              public currentObjectService: CurrentObjectService,
              private operationService: OperationService) {
  }

  ngOnInit() {
    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      this.init().then();
    });
    this.init().then();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
    window.removeEventListener('scroll', () => {
      this.checkScroll();
    });
  }

  objectRefresh(event) {
    if (event.store) {
      this.storeObject().then();
    } else {
      this.setSelectedMediaItem().then();
    }
  }

  isFinishedLoading() {
    this.finishedLoading = true;
  }
  selectorOverlay() {
    this.searchSelectorService.disableSelector(<SelectorContainer>this.operationContainer);
  }

  async selectMedia(mediaId) {
    await this.primusRouter.navigateState(this.primusRouter.currentState(),
      {
        mediaId: mediaId
      });
    this.mediaId = mediaId;
    this.setSelectedMediaItem().then();
  }

  private async init() {
    this.currentObjectService.isEditing = this.primusRoute.params.edit === 'true';
    if (this.primusRoute.params.contextIds && typeof this.primusRoute.params.contextIds === 'string') {
      this.contextId = this.primusRoute.params.contextIds;
      this.parentId = this.primusRoute.params.parentId !== this.contextId ? this.primusRoute.params.parentId : undefined;
    } else if (this.primusRoute.params.parentId) {
      this.contextId = this.primusRoute.params.parentId;
      this.parentId = null;
    }
    this.galleryParentId = this.primusRoute.params.galleryParentId;
    this.mediaId = this.primusRoute.params.mediaId;
    this.mediaObjectType = this.primusRoute.params.objectType;


    if (this.parentId) {
      this.artifactId = this.parentId;
    } else if (this.contextId) {
      this.artifactId = this.contextId;
    } else if (this.mediaId) {
      this.artifactId = this.mediaId;
    } else {
      console.warn('Could not get artifact id for retrieving media');
    }

    await this.loadMedia();

    const windowSize = this.uiTools.windowSize;
    this.mediumScreen = windowSize.width < 1025;
    this.smallObjectMenus = windowSize.width < 870;
    this.smallScreen = windowSize.width < 642;
    window.addEventListener('scroll', () => {
      this.checkScroll();
    });

    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.smallObjectMenus = newVal.width < 1025;
        this.mediumScreen = newVal.width < 1025;
        this.smallScreen = newVal.width < 642;
      }
    );
  }

  private async loadMedia() {
    let object: SuperObjectModel;
    try {
      object = await this.objectEditService.loadObject(this.artifactId);
    } catch (reason) {
      console.warn(`Unable to load object: ${reason.error.message}`);
      return;
    }
    if (this.mediaHelper.objectIsMedia(object)) {
      // This happens if you click on a media based search item
      this.mediaContainer = await this.mediaHelper.getMediaContainerForMediaObject(object);
    } else {
      // This happens if you open an artifact containing images or videos
      this.artTarget = object;
      this.mediaContainer = await this.mediaHelper.getMediaContainerForObjectAndObjectType(
        this.artTarget, this.mediaObjectType);
    }
    await this.setSelectedMediaItem();
  }

  private getCreateParams(): OperationAndSectionsContainerParams {
    const params = {
      useExistingObject: true,
      objectId: this.mediaId,
      getSourceObject: !this.currentObjectService.isEditing,
      operationTarget: OperationTarget.OBJECT_VIEW,
      templateGroupId: this.primusRoute.params.template_group_id
    } as OperationAndSectionsContainerParams;
    if (this.currentObjectService.isEditing) {
      params.executorType = OperationExecutorType.ARTIFACT;
      params.operationStepType = OperationStepType.EDIT_OBJECT;
    }
    return params;
  }

  private async setSelectedMediaItem() {
    this.operationContainer = await this.operationService.createOperationAndRootSectionsContainer(this.getCreateParams());
    this.postLoadOperations();
  }

  private postLoadOperations() {
    this.sectionMenuContainer.sections = this.operationContainer.rootSectionsContainer.sections;
    this.art = this.operationContainer.rootSectionsContainer.rootObject;

    this.art['$$context_id'] = this.contextId;
    this.art['$$parent_id'] = this.parentId;
    this.contentInfo.updateContentInfo(
      this.currentObjectService.isEditing, this.art, this.primusRoute.params.listName);
    this.sectionMenuContainer.artifact = this.art;
    this.sectionMenuContainer.contentInfo = this.contentInfo;
    this.currentObjectService.currentObject = this.art;
    this.operationContainer.contentInfo = this.contentInfo;
  }

  private async storeObject() {
    const value = await this.objectEditService.setObjectValuesStoreObject(this.operationContainer.rootSectionsContainer);
    this.art.artifact_id = value.artifact_id;
    this.currentObjectService.isEditing = false;
    await this.primusRouter.navigateState(this.primusRouter.currentState(), {edit: null, isNew: null}, {
      location: 'replace',
      reload: true,
    });
    this.objectRefresh({store: false});
  }

  private checkScroll() {
    if (!this.mediumScreen) {
      if (this.contentInfo && this.contentInfo.curListName === 'overview') {
        this.scrollContent = window.pageYOffset > 30 && window.pageYOffset < 240;
      } else {
        this.scrollContent = window.pageYOffset > 30;
      }
    }
  }

  openImageFullScreen(activeImage) {
    this.toggleAnnotations = activeImage.toggleAnnotations;
    this.imageFullScreen = true;
  }

  closeImageFullScreen() {
    this.imageFullScreen = false;
  }

}
