<div class="dialog search-focus-edit-dialog">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span translate="TRANS__FOCUS__EDIT__TITLE_EDIT"></span>
    </h1>
  </div>
  <div mat-dialog-content class="modal-body modal-body-edit-fields">
    <div class="modal-main-content">
      <div class="search-focus-edit-dialog__field">
        <span class="search-focus-edit-dialog__field-title">
          <span class="search-focus-edit-dialog__field-title-text"
                translate="TRANS__FOCUS__INPUT_FOCUS_NAME"></span>
          <span class="search-focus-edit-dialog__field-title-required"> <span>*</span></span>
        </span>
      <div class="search-focus-edit-dialog__field-content">
        <div class="clearInputContainer">
          <label><input type="text"
                        [(ngModel)]="data.focusNameQuery"
                        [ngClass]="{'errorInputFrame': validateCurrentAndAddNewName() && validateError}"
                        autocomplete="off"></label>
          <a *ngIf="data.focusNameQuery"
             class="clearInputField"
             (click)="clearNameInput()">
            <mat-icon>close</mat-icon>
          </a>
        </div>
      </div>
      <span *ngIf="validateCurrentAndAddNewName() && validateError"
            class="search-focus-edit-dialog__error">
          <span class="search-focus-edit-dialog__error-text">
            <span [translate]="validateError"></span>
          </span>
        </span>
      </div>
      <div class="search-focus-edit-dialog__field large-checkbox">
        <mat-checkbox
          [(ngModel)]="data.focusInEdit.focus.isDefault"
          (ngModelChange)="toggleIsDefault(data.focusInEdit)"
          [checked]="data.focusInEdit.focus.isDefault">
           <span translate="TRANS__FOCUS__IS_DEFAULT_TITLE"></span>
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="modal-footer">
    <button type="submit"
            mat-flat-button
            color="accent"
            [disabled]="validateCurrentAndAddNewName()"
            (click)="saveFocus()">
      <span class="bold" translate="TRANS__FOCUS__EDIT__SAVE"></span>
    </button>
    <button type="button"
            mat-button
            color="accent"
            (click)="dialogRef.close(false)">
      <span class="bold" translate="TRANS__FOCUS__EDIT__CANCEL"></span>
    </button>
  </div>
</div>
