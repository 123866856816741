import {Component, Input, OnChanges} from '@angular/core';
import {CopyKeepService, CopyKeepServiceParams} from './copy-keep.service';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {MetaField} from '../../core/definitions/meta-field';
import {BaseModel} from '../../core/definitions/base-model';
import {KeepObject} from '../../core/definitions/keep-object';

@Component({
  selector: 'app-copy-keep-checkbox',
  templateUrl: './copy-keep-checkbox.component.html',
  styleUrls: ['./copy-keep-checkbox.component.scss']
})
export class CopyKeepCheckboxComponent implements OnChanges {
  @Input() sectionsContainer: SectionsContainer;
  @Input() object: BaseModel;
  @Input() field: MetaField;
  @Input() index: number;
  @Input() parentIndex: number;
  @Input() item: BaseModel;

  hasKeep = false;
  keepObj: KeepObject;
  fieldId: string;
  private params;
  private fieldParameters: FieldParameters;

  constructor(private readonly copyKeepService: CopyKeepService,
              private readonly inlineArrayItemSvc: InlineArrayItemService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.object = this.object;
    this.fieldParameters.field = this.field;
    this.fieldParameters.index = this.index;
    this.fieldParameters.parentIndex = this.index;
    // WARNING: The change callback will only work as long as the change tracker is running in the background
    this.params = new CopyKeepServiceParams(this.object, this.field, this.index, (changed) => {
      this.setKeep(changed);
    });
    this.setKeep();
  }

  checkIndex() {
    if (this.index !== undefined) {
      const itemIndex = this.inlineArrayItemSvc.getOpenArrayItemIndex(this.fieldParameters);
      if ((this.keepObj.canKeep && itemIndex !== this.index) ||
        (!this.keepObj.canKeep && itemIndex === this.index)) {
        this.inlineArrayItemSvc.toggleInlineArrayItemOpen(this.fieldParameters);
      }
    }
  }

  toggleKeep() {
    this.keepObj.canKeep = !this.keepObj.canKeep;
    if (this.item) {
      this.item.$$disabled = !this.keepObj.canKeep;
    }
    this.checkIndex();
  }

  private setKeep(changed?) {
    let keep = true;
    const keepProps = this.copyKeepService.getKeepProps(this.params);
    if (keepProps) {
      keep = keepProps.canKeep;
      if (changed !== undefined) {
        keep = keep && !changed;
      }
    }
    this.hasKeep = this.copyKeepService.setKeep(keep, this.params);
    if (this.hasKeep && !this.keepObj) {
      this.setKeepProps();
    }
  }

  private setKeepProps() {
    const keepRoot = this.object.$$keep;
    if (keepRoot) {
      if (this.index === undefined) {
        this.keepObj = keepRoot.fields[this.field.name];
        this.fieldId = this.object.object_type + '-' + this.field.name;
      } else {
        this.keepObj = keepRoot.fields[this.field.name].indexes[this.index];
        this.fieldId = this.object.object_type + '-' + this.field.name + '-' + this.index;
      }
    } else {
      console.warn('$$keep not set: ' + this.field.name);
    }
  }
}
