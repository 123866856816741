import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {MatDialogModule} from '@angular/material/dialog';
import {SharedModule} from '../shared/shared.module';
import {ObjectViewModule} from '../object-view/object-view.module';
import {JobStatusComponent} from './jobstatus/job-status.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MessageAndNotificationsComponent} from './message-and-notifications/message-and-notifications.component';
import {MainMenuUserComponent} from './main-menu-user/main-menu-user.component';
import {MatTabsModule} from '@angular/material/tabs';
import {JobStatusMessageListComponent} from './jobstatus/jobstatus-message-list/job-status-message-list.component';
import * as Sentry from "@sentry/angular";
import {MatDrawer, MatDrawerContainer} from "@angular/material/sidenav";
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
    ObjectViewModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    RouterModule,
    Sentry.TraceModule,
    MatDrawer,
    MatDrawerContainer
  ],
  exports: [
    MainMenuComponent
  ],
  declarations: [
    MainMenuComponent,
    JobStatusComponent,
    MessageAndNotificationsComponent,
    MainMenuUserComponent,
    JobStatusMessageListComponent
  ]
})
export class MainMenuModule {
}
