<div class="container">

  <div class="container__header">
    <h2>
      {{ headerPrimaryText | translate }}
      <span class="secondary-text" *ngIf="!!headerSecondaryText">
        {{ headerSecondaryText | translate }}
      </span>
    </h2>
    <h2 class="secondary-text" *ngIf="!!headerHintText">{{ headerHintText | translate }}</h2>
    <ng-content select="[action]"></ng-content>
  </div>

  <div class="container__content">
    <ng-content></ng-content>
  </div>

  <div class="container__footer">
    <ng-content select="[footer]"></ng-content>
  </div>

</div>
