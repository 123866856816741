<mat-form-field class="number-input-wrapper" *ngIf="field">
  <mat-label>{{ label | translate }}</mat-label>
  <!--suppress HtmlFormInputWithoutLabel -->
  <input matInput
         type="number"
         [formControl]="control"
         [placeholder]="placeholder | translate"
         autocomplete="off"/>
  <mat-hint>{{ placeholder | translate }}</mat-hint>
</mat-form-field>
