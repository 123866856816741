import {Injectable} from '@angular/core';
import {SearchContainer} from '../core/definitions/search-container';

@Injectable({
  providedIn: 'root'
})
export class SearchScrollService {

  timerID = null;

  constructor() {
  }

  scrollToYPos(searchContainer: SearchContainer) {
    if (searchContainer.scrollInfo.scrollElement && searchContainer.scrollToY !== undefined) {
      if (this.timerID !== null) {
        clearInterval(this.timerID);
      }
      this.timerID = setTimeout(() => {
        searchContainer.scrollInfo.scrollElement.focus();
        searchContainer.scrollInfo.scrollElement.scrollTo(0, searchContainer.scrollToY);
      }, 2000);
    }
  }

}
