import {Component, Input, OnChanges} from '@angular/core';
import {OverviewField} from '../../core/definitions/object-view';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationService} from '../../operations/operation.service';

@Component({
  selector: 'app-procedure-view-and-edit',
  templateUrl: './procedure-view-and-edit.component.html',
  styleUrls: ['./procedure-view-and-edit.component.scss']
})
export class ProcedureViewAndEditComponent implements OnChanges {

  @Input() isEditing: boolean;
  @Input() showALLFields: boolean;
  @Input() operationContainer: OperationContainer;
  fields: Array<OverviewField> = [];

  constructor(private operationService: OperationService) { }

  ngOnChanges() {
    if (!this.isEditing) {
      this.getFields().then(data => {
        this.fields = data;
      });
    }
  }
  getFields(): Promise<any> {
    return Promise.resolve((() => {
      return this.operationContainer.rootSectionsContainer.objectView.overview;
    })());
  }

  async openOperation(operation: OperationDef) {
    await this.operationService.openOperationView(this.operationContainer, operation);
  }

}
