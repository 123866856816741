@if (canShow) {
  <div class="field-action-button">
    @if (inlineView?.css_class_edit === 'set-button' || inlineView?.css_class_edit === 'edit-button') {
      <button mat-stroked-button
              (click)="runAction()"
              [disabled]="isDisabled">
        @if (inlineView?.icon) {
          <mat-icon>{{ inlineView?.icon }}</mat-icon>
        }
        @if (fieldTitle) {
          <span [translate]="fieldTitle"></span>
        } @else {
          <span [translate]="field.title"></span>
        }
      </button>
    } @else if (inlineView?.css_class_edit === 'cancel-button') {
      <button mat-button
              (click)="runAction()"
              [disabled]="isDisabled">
        @if (fieldTitle) {
          <span [translate]="fieldTitle"></span>
        } @else {
          <span [translate]="field.title"></span>
        }
      </button>
    } @else if (inlineView?.css_class_edit === 'store-button') {
      <button mat-flat-button
              (click)="runAction()"
              [disabled]="isDisabled"
              color="accent">
        @if (fieldTitle) {
          <span [translate]="fieldTitle"></span>
        } @else {
          <span [translate]="field.title"></span>
        }
      </button>
    } @else if (inlineView?.css_class_edit === 'add-coordinate') {
      <button mat-stroked-button
              type="button"
              (click)="runAction()"
              class="field-action-button__coordination-button"
              [disabled]="isDisabled">
        @if (inlineView?.icon) {
          <mat-icon>{{ inlineView?.icon }}</mat-icon>
        }
        @if (fieldTitle) {
          <span [translate]="fieldTitle"></span>
        } @else {
          <span [translate]="field.title"></span>
        }
      </button>
    }
  </div>
}
