<div class="annotation-dashboard-buttons">
  <div class="annotation-dashboard-buttons__save-container"
       [matTooltip]="curAnn.disabledSaveReason | translate"
       [matTooltipDisabled]="!curAnn.getDisabledSave()">
    <button mat-flat-button
            color="accent"
            type="submit"
            class="annotation-dashboard-buttons__save"
            [disabled]="curAnn.getDisabledSave()"
            (click)="curAnn.closeAnnotation()">
      <span class="bold" translate="TRANS__ANNOTATION__REGISTER_ANNOTATION"></span>
    </button></div>
  <button mat-button
          class="annotation-dashboard-buttons__cancel"
          type="button"
          (click)="curAnn.cancelAnnotation()">
    <span class="bold" translate="TRANS__ANNOTATION__CANCEL_ANNOTATION"></span></button>
</div>
