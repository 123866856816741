<ul class="annotation-parents" [ngClass]="{'dialog': isDialog}">
  <li *ngIf="curAnn.parentObject">
    <app-object-url
      [object]="curAnn.parentObject"
      [listName]="'overview'">
      <span class="object-url-content">
        <app-object-icon
          class="annotation-parents__icon"
          [object]="curAnn.parentObject">
        </app-object-icon>
        <span class="annotation-parents__text">{{curAnn.parentObject.artifact_name}}</span>
      </span>
    </app-object-url>
  </li>

</ul>
