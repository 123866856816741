import {Component, Input, OnInit} from '@angular/core';
import {AnnotationHandler} from '../annotation-handler';

@Component({
  selector: 'app-annotation-dashboard',
  templateUrl: './annotation-dashboard.component.html',
  styleUrls: ['./annotation-dashboard.component.scss']
})
export class AnnotationDashboardComponent implements OnInit {

  @Input() curAnn: AnnotationHandler;
  @Input() target;
  @Input() mediaList;

  constructor() {
  }

  ngOnInit() {
  }

  hideParent() {
    return !this.curAnn.parentObject?.artifact_id;
  }

}
