import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {FieldValueService} from '../../core/field-value.service';
import {FieldMetaService} from '../../core/field-meta.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {MetaField} from '../../core/definitions/meta-field';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationService} from '../../operations/operation.service';
import {BaseModel} from '../../core/definitions/base-model';
import {Reference, Selector} from '../../core/definitions/reference';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SelectorCreationParams} from '../../core/definitions/selector-creation-params';
import {SearchObject} from '../../core/definitions/search-object';
import {ObjectDeletionService} from '../../core/object-deletion.service';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {SolrFilterService} from '../../core/solr-filter.service';
import {FieldConditionService} from '../../core/field-condition.service';
import {SearchService} from "../../core/search.service";
import {SearchReferenceService} from "../../core/search-reference.service";
import {OptionsDialogService} from "../options-dialog.service";
import {LoggerService} from "../../core/logger.service";
import {CrudService} from "../../core/crud.service";
import {TranslateService} from "@ngx-translate/core";

export interface SearchSelectorItem extends BaseModel {
  $$object: BaseModel;
  $$index: number;
  destroy: boolean;
}

@Component({
  selector: 'app-edit-field-search-selector-multiple',
  templateUrl: './edit-field-search-selector-multiple.component.html',
  styleUrls: ['./edit-field-search-selector-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSearchSelectorMultipleComponent)
    }
  ]
})
export class EditFieldSearchSelectorMultipleComponent implements OnChanges, ControlValueAccessor {

  constructor(private logger: LoggerService,
              private fieldValueSvc: FieldValueService,
              private fieldMetaSvc: FieldMetaService,
              private operationService: OperationService,
              private searchSelector: SearchSelectorService,
              private optionsDialogService: OptionsDialogService,
              private inlineArrayItemService: InlineArrayItemService,
              private objectDeletionService: ObjectDeletionService,
              private searchService: SearchService,
              private solrFilterService: SolrFilterService,
              private fieldConditionService: FieldConditionService,
              private searchReferenceService: SearchReferenceService,
              private crud: CrudService,
              private translate: TranslateService) {
  }

  @Input() fieldParameters: FieldParameters;
  @Input() formControlName: string;

  sectionsContainer: SectionsContainer;
  object: SuperObjectModel;
  field: MetaField;
  parentIndex: number;
  items: SearchSelectorItem[] = [];
  itemsSet = false;
  disabledReason = '';
  selectorIsDisabled = false;

  reference: Reference;

  ngOnChanges() {
    this.sectionsContainer = this.fieldParameters.sectionsContainer;
    this.checkIsCopy();
    this.object = <SuperObjectModel>this.fieldParameters.object;
    this.field = this.fieldParameters.field;
    this.parentIndex = this.fieldParameters.parentIndex;
    this.fieldParameters.useMultipleSetter = true;
    this.fieldParameters.canDelete = false;

    const meta = this.fieldMetaSvc.getFieldMetaData(this.object, this.field);
    if (meta) {
      this.reference = this.searchReferenceService.getSearchReferenceFromField(meta);
      this.setItemsAndDeletable().then();
    }
  }

  async deleteItemByIndex(index: number) {
    if (!this.reference.ref_delete_disabled) {
      this.fieldValueSvc.deleteItem(this.sectionsContainer.rootObject, this.object, this.arrayField, index,
        this.sectionsContainer.formGroup, this.formControlName);
    }
    await this.setItems();
  }

  onItemDrop(event: CdkDragDrop<SearchSelectorItem[]>) {
    const arrayName = this.field.name;
    moveItemInArray(this.object[arrayName], event.previousIndex, event.currentIndex);
    this.object[arrayName].forEach((item: any, index: number) => {
      item['order_number'] = index;
    });
    this.setItems().then();
    this.sectionsContainer.formGroup.controls[this.formControlName].markAsDirty();
  }

  onFieldOutsideTheScreen() {
    // TODO: implement code here
  }

  async clickEnableSelector() {
    let selector: Selector, container: SelectorContainer, selected: SearchObject[];
    selector = this.reference.selector;
    container = <SelectorContainer>this.operationService.currentOperationContainer;
    selected = this.getSelectedValues();
    const params = {selected: selected} as SelectorCreationParams;
    // TODO: Add template group id to enableSelector call
    this.searchSelector.enableSelector(selector, container, params, {
      searchContainerCreated: () => {
      },
      selectorCallback: (selectedObj: BaseModel[]) => {
        this.searchSelector.disableSelector(container);
        this.checkDeleted(selectedObj);
        this.checkAddNewItems(selectedObj).then();
      }
    });
  }

  openEdit(item: SearchSelectorItem) {
    this.editItem(item).then(art => {
      if (art) {
        this.fieldValueSvc.markAsDirty(this.sectionsContainer.formGroup, this.formControlName);
        this.setItems().then();
      }
    });
  }

  openCreateNew() {
    const fieldParameters = new FieldParameters();
    fieldParameters.field = this.field;
    this.editItem().then(art => {
      if (art) {
        this.checkAddNewItems([art]).then();
      }
    }, reason => {
      if (reason) {
        this.logger.warn('Error storing new artifact: ' + reason.toLocaleString());
      }
    });
  }

  get isSelectorDisabled() {
    if (this.checkIsCopy()) {
      return true;
    }
    const disabledRes = this.fieldConditionService.runIfDisabled(this.fieldParameters);
    this.selectorIsDisabled = disabledRes.disabled;
    this.disabledReason = disabledRes.reason;
    return disabledRes.disabled;
  }

  private checkIsCopy() {
    let res = false;
    if (this.sectionsContainer.isCopy) {
      this.selectorIsDisabled = true;
      this.disabledReason = this.translate.instant('TRANS__SEARCH_SELECTOR_MULTIPLE__COPY_DISABLED');
      res = true;
    }
    return res;
  }

  private editItem(item?: SearchSelectorItem): Promise<BaseModel> {
    return new Promise<any>(resolve => {
      if ((!item || this.reference.can_edit_existing || this.reference.can_edit_context) &&
        !this.sectionsContainer.isCopy) {
        const fieldParameters: FieldParameters = new FieldParameters();
        fieldParameters.field = this.field;
        fieldParameters.sectionsContainer = this.sectionsContainer;
        if (item) {
          if (this.reference.can_edit_context || item.artifact_id === item.$$object.artifact_id) {
            // The last part of the "if" will make sure editing existing publish events works
            fieldParameters.object = item;
          } else {
            fieldParameters.object = item.$$object;
          }
        }
        fieldParameters.sectionsContainer.isSecondDialog = fieldParameters.sectionsContainer.isDialog;
        this.optionsDialogService.createOption(fieldParameters).then(
          option => {
            resolve(option)
          },
          reason => {
            if (reason) {
              this.logger.error(`Create option failed: ${reason}`);
            }
          });
      }
    });
  }

  private async setItemsAndDeletable() {
    await this.setItems();
    await this.objectDeletionService.setObjectsDeletable(this.sectionsContainer.rootObject, this.items);
  }

  private async setItems() {
    const arrayName = this.field.name;
    let array = this.object[arrayName];
    if (this.reference.get_existing_items_from_search) {
      array = await this.getItemsFromSearch();
    }
    this.items = [];
    if (!this.reference.ref_prop) {
      this.logger.error('An reference prop must be defined for field ' + this.field.name);
    } else {
      if (array.length) {
        this.items = array.map((item: any, index: number) => this.getItem(item, this.reference.ref_prop, index));
      }
    }
    this.itemsSet = true;
  }

  private async getItemsFromSearch(): Promise<any[]> {
    let res = [];
    const parentField = this.reference.parent_field || 'artifact_id';
    const parentTargetField = this.reference.parent_target_field;
    if (parentTargetField) {
      const params = {} as SearchParameters;
      const parentId = this.object[parentField];
      this.solrFilterService.addFq(params, parentTargetField, parentId);
      this.solrFilterService.addFq(params, 'object_type', this.reference.object_type);
      if (this.reference.sort) {
        params.sort = this.reference.sort;
      }
      const searchResult = await this.searchService.search(params);
      // Result is reversed as this would be the expected order when obtaining elements from array
      res = [...searchResult.artifacts].reverse();
    } else {
      this.logger.warn('A parent target field has not been specified in reference');
    }
    return res;
  }

  private getItem(item: BaseModel, refProp: string, index: number) {
    const itemObject = new BaseModel(item[refProp]);
    itemObject.artifact_name = item[refProp + '_value'] || item.artifact_name || item[refProp];
    itemObject.object_type = this.reference.object_type;
    item.$$object = itemObject;
    item.$$index = index;
    return item;
  }

  private getSelectedValues(): SearchObject[] {
    const res: SearchObject[] = [];
    const values = this.fieldValueSvc.getFieldValue(this.sectionsContainer.rootObject, this.formControlName);
    if (values) {
      for (let value of values) {
        if (!this.crud.getDestroy(value)) {
          res.push({
            artifact_id: value[this.reference.ref_prop]
          } as SearchObject);

        }
      }
    }
    return res;
  }

  private async checkAddNewItems(objects: Array<BaseModel>) {
    const refProp = this.reference.ref_prop;
    for (const obj of objects) {
      let targetData: any;
      const existed = this.checkIdExistedRemoveDestroyFlag(this.items, obj.artifact_id, refProp);
      if (existed) {
        continue;
      }
      if (obj.meta_type === 'adm_event') {
        targetData = {
          context_id: this.object.artifact_id,
          artifact_id: obj.artifact_id,
          artifact_id_value: obj.artifact_name,
          superobject_type_id: obj.superobject_type_id,
          object_type: 'SuperobjectAdmEventItem'
        };
      } else {
        targetData = {
          context_object_type: obj.object_type,
          context_id: this.sectionsContainer.rootObject.artifact_id || this.sectionsContainer.rootObject.context_id
        };
        targetData[refProp] = obj.artifact_id;
      }
      targetData[refProp + '_value'] = obj.artifact_name;
      // Unfortunately, adding admin events causes the server to create an extra admin event seemingly without any
      // connection to the one added here so marking form as dirty instead
      if (obj.meta_type !== 'adm_event') {
        await this.inlineArrayItemService.addInlineArrayItemToForm(this.fieldParameters, false, targetData);
      } else {
        this.fieldValueSvc.markAsDirty(this.sectionsContainer.formGroup, this.formControlName);
      }
    }
    await this.setItems();
  }

  private checkIdExistedRemoveDestroyFlag(items: SearchSelectorItem[], artifactId: string, refProp: string) {
    let existed = false
    for (const existing of items) {
      if (artifactId === existing[refProp]) {
        existed = true;
        this.crud.deleteDestroy(existing);
      }
    }
    return existed;
  }

  private get arrayField(): MetaField {
    return this.field;
  }

  private async checkDeleted(objects: Array<BaseModel>) {
    const deletedList = [];
    this.getSelectedValues().forEach((existing, i) => {
      let existed = false;
      objects.forEach((obj) => {
        if (obj.artifact_id === existing.artifact_id) {
          existed = true;
        }
      });
      if (!existed) {
        deletedList.push(i);
      }
    });
    while (deletedList.length > 0) {
      await this.deleteItemByIndex(deletedList.pop());
    }
  }

  registerOnChange(/*fn: any*/): void {
    // N/A
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  setDisabledState(/*isDisabled: boolean*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }

}
