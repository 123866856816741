<div class="media-upload-camera"
     [ngClass]="{'vertical-view': verticalView}">
  <div class="media-upload-camera__content">
    <h5 class="bold media-upload-camera__cameraText"
          translate="TRANS__IMAGE_SELECTOR__NEW_IMAGE_ALLOW_CAMERA"></h5>
    <video #video class="media-upload-camera__camera-live-video"></video>
  </div>
  <div class="media-upload-camera__button-container">
    <button mat-flat-button
            class="media-upload-camera__button"
            color="accent"
            (click)="takePicture($event)">
      <span class="bold" translate="TRANS__IMAGE_SELECTOR__NEW_IMAGE_TAKE_PHOTO"></span>
    </button></div>

  <canvas class="displayNone" #canvas></canvas>
  <img class="displayNone" src="" #photo alt="photo">

</div>
