import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageAnnotationComponent} from './image-annotation/image-annotation.component';
import {AnnotationCanvasComponent} from './annotation-canvas/annotation-canvas.component';
import {AnnotationDashboardComponent} from './annotation-dashboard/annotation-dashboard.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {AnnotationParentsComponent} from './annotation-dashboard/annotation-parents/annotation-parents.component';
import {AnnotationShapeMenuComponent} from './annotation-dashboard/annotation-shape-menu/annotation-shape-menu.component';
import {AnnotationColorMenuComponent} from './annotation-dashboard/annotation-color-menu/annotation-color-menu.component';
import {AnnotationDashboardButtonsComponent} from './annotation-dashboard/annotation-dashboard-buttons/annotation-dashboard-buttons.component';
import {AnnotationDescriptionComponent} from './annotation-dashboard/annotation-description/annotation-description.component';
import {AnnotationPointsComponent} from './annotation-dashboard/annotation-points/annotation-points.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {AnnotationDialogComponent} from './annotation-dialog/annotation-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [ImageAnnotationComponent, AnnotationCanvasComponent, AnnotationDashboardComponent,
    AnnotationParentsComponent, AnnotationShapeMenuComponent, AnnotationColorMenuComponent,
    AnnotationDashboardButtonsComponent, AnnotationDescriptionComponent, AnnotationPointsComponent,
    AnnotationDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDialogModule
  ],
  exports: [
    AnnotationCanvasComponent,
    AnnotationDashboardComponent,
    AnnotationDialogComponent
  ]
})
export class ImageAnnotationModule {
}
