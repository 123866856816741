@if (loading) {
  <mat-spinner class="loading-spinner" diameter="20"></mat-spinner>
} @else {
  <mat-action-list>

    @for (registration of visibleCards(); track registration) {

      <mat-card tabindex="0" class="registration-item card-button" matRipple (click)="onRegistrationClicked($event, registration)">
        <mat-card-header>
          @if (registration.img) {
            <img matCardImage [src]="registration.img">
          } @else {
            <mat-icon matListItemAvatar class="material-symbols-outlined image-placeholder">image</mat-icon>
          }
        </mat-card-header>
        <mat-card-content>
          <mat-card-title>{{ registration.type }}</mat-card-title>
          <mat-card-subtitle>{{ registration.label }}</mat-card-subtitle>
        </mat-card-content>
        <span class="hover-overlay"></span>
      </mat-card>

    } @empty {
      <div class="flex items-center empty-state">
        <mat-icon class="material-symbols-outlined">info</mat-icon>
        <span>
        {{ 'TRANS__WELCOME_SCREEN__RECENT_EDITS__NO_HITS' | translate }}
      </span>
      </div>
    }

    @if (registrations.length > 0) {
      @for (_ of placeholderItems(); track $index) {
        <mat-card class="registration-item placeholder">
          <mat-icon matListItemAvatar class="material-symbols-outlined">category</mat-icon>
        </mat-card>
      }
    }

  </mat-action-list>

}
