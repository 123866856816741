export class MetaOperationParam {
  param_name: string;
  param_type: string;
  source_type: string;
  title: string;
  value: any;
}

export class MetaOperationDef {
  operation_id: string;
  operation_name: string;
  operation_title: string;
  operation_params: Array<MetaOperationParam>;
  operation_items: Array<string>;
  operation_item_count: number;
  created_at: Date;
  created_by_id: string;
  updated_at: Date;
  icon: string;
  requires_items: boolean;
  minimum_items: number;
  keep_current_operation_at_close: boolean;
}

export class MetaOperationList {
  operations: Array<MetaOperationDef>;
}

export class MetaOperationValidation {
  status: string;
  message: string;
  invalid_items: Array<string>;
}

export class MetaOperationExecuteStatus {
  operation_id: string;
  status: string;
  progress = 0;
}

export class MetaOperationContainer {
  metaOperation: MetaOperationDef;
  metaOperationValidation: MetaOperationValidation;
  metaOperationExecuteStatus: MetaOperationExecuteStatus;
}
