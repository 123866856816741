import {Injectable} from '@angular/core';
import {SearchHandlerService} from '../object-search/search-handler.service';
import {ObjectStorageService} from './object-storage.service';
import {SelectorContainer} from './definitions/selector-container';
import {AConst} from './a-const.enum';
import {Selector} from './definitions/reference';
import {SuperObjectModel} from './definitions/super-object-model';
import {SearchContainerParams} from './definitions/search-container';
import {SelectorCreationParams} from './definitions/selector-creation-params';
import {SearchObject} from "./definitions/search-object";
import {SearchResultSelectionsService} from "../object-search/search-result-selections.service";
import {LoggerService} from "./logger.service";
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchSelectorService {

  constructor(private logger: LoggerService,
              private readonly searchHandler: SearchHandlerService,
              private readonly objectStorage: ObjectStorageService,
              private searchResultSelectionsService: SearchResultSelectionsService) {
  }

  private _searchSelectorChanged$ = new Subject<any>();
  get searchSelectorChanged$() {
    return this._searchSelectorChanged$.asObservable();
  }

  createSelectorContainer(art: SuperObjectModel): SelectorContainer {
    return {
      art: art,
      selectorEnabled: false,
      searchContainer: null,
      selectorCallback: null,
      currentMenu: null
    };
  }

  disableSelector(container: SelectorContainer) {
    document.getElementsByTagName('body')[0].classList.remove('selector-open');
    document.getElementsByTagName('html')[0].classList.remove('selector-open');
    container.selectorEnabled = false;
    this._searchSelectorChanged$.next(null);
  }

  enableSelector(selector: Selector, container: SelectorContainer, params?: SelectorCreationParams, fns?) {
    const filters = this.getFilters(selector.filters, container.art);
    const singleSelect = selector.selector_type.indexOf('single') !== -1;
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          selector[key] = params[key];
        }
      }
    }

    if (container.selectorEnabled) {
      this.disableSelector(container);
      return;
    }
    // This probably had some purpose, but I'll be damned if I remember what it was. Anyway, the 'context_object_type'
    // search filter does no longer exist, there exists a motif_objects.context_object_type but unsure whether this
    // is the correct one. So commenting out the following lines:
    // if (container.art) {
    //   filters[AConst.CONTEXT_OBJECT_TYPE] = container.art[AConst.CONTEXT_OBJECT_TYPE];
    // }
    const searchContainerParams = new SearchContainerParams();
    searchContainerParams.searchViewName = selector.view;
    searchContainerParams.filters = filters;
    searchContainerParams.defaultCheckedFilters = this.getFilters(selector.checked_filters, container.art);
    searchContainerParams.runSearch = false;
    searchContainerParams.selectedItems = selector.selected;
    searchContainerParams.used = selector.used || [];
    searchContainerParams.restrictions = selector.restrictions;
    searchContainerParams.keepSelected = true;
    this.searchHandler.createSearchContainer(searchContainerParams).then(
      (sc) => {
        container.searchContainer = sc;
        sc.queryContainer.query = selector.query;
        sc.selections.singleSelect = singleSelect;
        sc.selections.selectCallback = (selectedObjects: SearchObject[]) => {
          this.selectCallback(selector, fns, selectedObjects);
        };
        sc.selectButtonText = selector.select_button_text;
        fns.searchContainerCreated(sc);
        document.getElementsByTagName('body')[0]
          .classList.add('selector-open');
        document.getElementsByTagName('html')[0]
          .classList.add('selector-open');
        container.selectorEnabled = true;

        this._searchSelectorChanged$.next(container);
      }
    );
  }

  private getFilters(filterInfo, art) {
    const res = {};

    if (filterInfo) {
      filterInfo.forEach((filter) => {
        let filterVal;
        const sourceField = filter[AConst.SOURCE_FIELD];
        res[filter.field] = res[filter.field] || [];
        if (sourceField) {
          if (art) {
            filterVal = art[sourceField];
          } else {
            console.warn('Missing artifact for retrieving source field value: ' + sourceField);
          }
        } else if (filter.value) {
          filterVal = filter.value;
        } else {
          throw new Error('Missing filter value!');
        }
        res[filter.field].push(filterVal);
      });
    }
    return res;
  }

  private selectCallback(selector: Selector, fns, searchObjects: SearchObject[]) {
    if (!searchObjects) {
      fns.selectorCallback([]);
    } else {
      const getType = selector.selector_type.split(':')[0];
      switch (getType) {
        case 'select':
          fns.selectorCallback(searchObjects);
          break;
        case 'load':
          this.loadCallback(fns, this.getItemIds(searchObjects));
          break;
        case 'copy':
          this.copyCallback(fns, this.getItemIds(searchObjects));
          break;
        default:
          this.logger.warn(`Unknown selector type ${selector.selector_type}`);
      }
    }
  }

  private getItemIds(searchObjects: SearchObject[]) {
    const res = [];
    for (const selectedItem of searchObjects) {
      const itemId = this.searchResultSelectionsService.getItemId(selectedItem);
      if (itemId) {
        res.push(itemId);
      }
    }
    return res;
  }

  private loadCallback(fns, objIds) {
    this.objectStorage.loadObjects(objIds).then(
      arts => {
        fns.selectorCallback(arts);
      },
      reason => {
        console.error(`Failed loading selected artifacts: ${reason.error?.message || reason.message}`);
      }
    );
  }

  private copyCallback(fns, objIds) {
    this.objectStorage.copyObjects(objIds).then(
      copies => {
        const artifactContainer = <HTMLElement>document.querySelector('#scrollToThisFromCopyCallback');
        if (artifactContainer) {
          window.scrollTo({
            top: artifactContainer.offsetTop - 50,
            behavior: 'smooth'
          });
        }
        fns.selectorCallback(copies);
      },
      reason => {
        console.error('Failed copying selected artifacts: ' + reason.error.message);
      }
    );
  }


}
