import {AbstractControl, AsyncValidatorFn, UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DateToolsService} from './date-tools.service';
import {CmsApiService} from './cms-api.service';
import {CommonsService} from './commons.service';
import {MetaField} from './definitions/meta-field';
import {FieldValidation} from './definitions/field-validation';
import {FieldInputType} from './definitions/field-input-type.enum';
import {IsoDateWithPrecision} from './definitions/iso-date-with-precision';
import {FieldDateInfoService} from "./field-date-info.service";

export class FieldValidators {

  constructor() {
  }

  static validatePrecisionDate(dateTools: DateToolsService): ValidatorFn {
    return (field: UntypedFormControl): ValidationErrors | null => {
      if (field.value) {
        const validDate: IsoDateWithPrecision = dateTools.validateDate(field.value);
        return !validDate.dd_error_message ? null : {
          validatePrecisionDate: {
            valid: false,
            message: validDate.dd_error_message
          }
        };
      } else {
        return null;
      }
    };
  }

  static validateIdentifier(cms: CmsApiService, originalValue: string): AsyncValidatorFn {
    return (field: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise(resolve => {
        if (field.value && field.value !== originalValue) {
          cms.identifierExists({identifier: field.value}).then(
            () => {
              // Identifier did not exist
              resolve(null);
            },
            () => {
              // Identifier already exists
              resolve({
                validateIdentifier: {
                  valid: false
                }
              });
            }
          );
        } else {
          resolve(null);
        }
      });
    };
  }

  static validateCompare(
    dateTools: DateToolsService,
    commons: CommonsService,
    fieldDateInfoService: FieldDateInfoService,
    object,
    metaProps: MetaField): ValidatorFn {
    return (field: UntypedFormControl): ValidationErrors | null => {
      const res = {
        valid: true,
        type: 'validCompare',
        message: ''
      };
      let val1, val2, errorMsg, compProp, op;
      let compareField;
      if (metaProps.validation) {
        const validProps: FieldValidation = metaProps.validation;
        compareField = validProps.compare.field;
        op = validProps.compare.comparator;
      } else {
        const dateInfo = fieldDateInfoService.getFieldDateInfo(metaProps)
        compareField = dateInfo?.validation?.compare_field;
        op = dateInfo?.validation?.comparator;
      }
      if (compareField) {
        let parentObject = object;
        if (metaProps.parent_name) {
          parentObject = object[metaProps.parent_name] || object;
        }
        compProp = parentObject[compareField];
        if (compProp === undefined || compProp === null) {
          return null; // Field not set
        }
        if (metaProps.input_type === FieldInputType.DATE_ISO || metaProps.input_type === FieldInputType.DATE_TIME_ISO) {
          const fieldVal = parentObject[metaProps.name];
          if (fieldVal && compProp && field.value) {
            errorMsg = 'TRANS__VALIDATE__INVALID_DATE';
            if (op === '<' || op === '<=') {
              errorMsg = 'TRANS__VALIDATE__FROM_DATE_EXCEEDS_TO_DATE';
            } else if (op === '>' || op === '>=') {
              errorMsg = 'TRANS__VALIDATE__TO_DATE_LESS_THAN_FROM_DATE';
            }
            // Making sure only the date part is compared, leaving out the time part
            val1 = fieldVal.substring(0, 10);
            val2 = compProp.substring(0, 10);
          }
        } else {
          errorMsg = 'TRANS__VALIDATE__COMPARE_FAILED';
          val1 = field.value;
          val2 = compProp;
        }
        if (val1 !== undefined && val2 !== undefined) {
          res.valid = commons.compareValues(val1, op, val2);
          if (!res.valid) {
            res.message = errorMsg;
          }
        }
      }
      return res.valid ? null : {
        validateCompare: res
      };
    };
  }
}

