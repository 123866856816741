<div class="object-new-object-view">

  <div class="row object-new-object-view__content">
    <div class="overlay"
         *ngIf="selectorContainer && selectorContainer.selectorEnabled"
         (click)="selectorOverlay()"></div>

    <app-edit-prime-fields
      [sectionsContainer]="sectionsContainer"
    ></app-edit-prime-fields>

  </div>

</div>
