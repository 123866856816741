<div class="new_report_sidemenu">
  <span class="new_report_sidemenu_close" (click)="cancelNewReport()"><i class="material-icons">close</i></span>
  <h1>Lag rapport fra utvalg</h1>

  <div>Generer rapport for {{ searchContainer.selections.selectedItems.length }} gjenstander fra utvalget.</div>

  @if (newReport) {
    <form class="new_report_sidemenu_single_column_form">
      <div class="form-field">Tittel for repporten</div>
      <input [ngClass]="{'new_report_form_field_error': newReportNameError}" class="form-field" type="text" name="title" placeholder="Gi rapportmalen en tittel."
             [(ngModel)]="newReport.name"/>
      @if (newReportNameError) {
        <div class="form-field-error">{{ newReportNameError }}</div>
      }


      <div class="form-field">Kort beskrivelse</div>
      <textarea [ngClass]="{'new_report_form_field_error': newReportShortDescriptionError}" class="form-field" name="shortDescription" placeholder="Gi rapportmalen en kort beskrivelse."
                [(ngModel)]="newReport.shortDescription"></textarea>
      @if (newReportShortDescriptionError) {
        <div class="form-field-error">{{ newReportShortDescriptionError }}</div>
      }

      <div class="form-field">Velg et utseende for rapporten. (Dette kan endres senere før rapporten genereres)</div>

      <mat-form-field>
        <mat-label>Velg et utseende for rapporten</mat-label>
        <mat-select [ngClass]="{'new_report_form_field_error': newReportViewTypeError}" [(value)]="newReport.reportViewTypeId" (selectionChange)="reportViewTypeChanged()">
          @for (reportView of this.reportViewTypes; track reportView.id) {
            <mat-option [value]="reportView.id">{{ reportView.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (newReportViewTypeError) {
        <div class="form-field-error">{{ newReportViewTypeError }}</div>
      }
    </form>
  }

  <div class="new_report_sidemenu_button-area">
    <button mat-flat-button (click)="cancelNewReport()">Avbryt</button>
    <button mat-flat-button color="accent" (click)="validateForm()">Neste</button>
  </div>
</div>

