export class ToolbarAction {
  action_name: string;
  action_type: string;
  submit_required: boolean;
  button_class: string;
  button_text: string;
  container_class: string;
  helper_box_text_open_selector: string;
  helper_box_text: string;

  $$disabledReason: string;
}
