import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatMenu} from '@angular/material/menu';
import {SelectionModel} from '@angular/cdk/collections';

/**
 * A representation of a menuitem
 */
export interface PrimusMenuItem {
  /**
   * An ID can be used to uniquely identify the clicked item
   */
  id?: string;
  /**
   * A value to be passed along to each menuitem
   */
  value?: any;
  /**
   * The label to display to the user.
   * Can be a translation-key
   */
  label: string;
  /**
   * The name of the material-icon to preprend to the label
   */
  icon?: string;
  /**
   * Sub-items, if any.
   * Providing this array will recursively create sub-menus.
   */
  items?: Array<PrimusMenuItem>;
  /**
   * The material-theme-color to use, if any
   */
  color?: 'primary' | 'accent' | 'warn';
  /**
   * Defines how the menu-item should be displayed:
   * 'default' -> The item is displayed as a normal menu-item/button
   * 'checkbox' -> The item is displayed as a checkbox.
   * 'radio' -> The item is displayed as a radio-button.
   *
   * If not using mode 'default', then groupName must be provided as well
   */
  mode?: 'radio' | 'checkbox' | 'default';
  /**
   * The name of the selection-group this radio-group or checkbox belongs to.
   * Only required when mode is 'radio' or 'checkbox'.
   * All items with this property MUST be a leaf-node,
   * and all items that should be in the same group MUST have this property
   * set to the same value.
   */
  groupName?: string;
  /**
   * Whether or not this item should be selected by default.
   * Only relevant if the item is part of a group (groupName is set)
   * and mode is 'radio' or 'checkbox'
   */
  default?: boolean;
}

export interface SelectionState {
  [groupName: string]: SelectionModel<PrimusMenuItem>;
}

/**
 * This component is intended to ONLY work with PrimusMenuButtonComponent.
 * This is a recursive implementation of the MatMenu-component.
 */
@Component({
  selector: 'app-primus-menu-button-item',
  templateUrl: './primus-menu-button-item.component.html',
  styleUrls: ['./primus-menu-button-item.component.scss']
})
export class PrimusMenuButtonItemComponent {

  @ViewChild('menu', {static: true}) public menu: MatMenu;

  @Input() color: 'primary' | 'accent' | 'warn';
  @Input() items: Array<PrimusMenuItem>;
  @Input() selectionState: SelectionState;

  @Output() public readonly menuItemClicked: EventEmitter<PrimusMenuItem>;

  constructor() {
    this.menuItemClicked = new EventEmitter<PrimusMenuItem>();
  }

  handleMenuItemClicked(item: PrimusMenuItem, event?: MouseEvent) {
    if (event && this.selectionState && item && item.groupName) {
      const state = this.selectionState[item.groupName];
      if (item.mode === 'checkbox') {
        // StopProp to prevent menu from closing when in multiselect-mode
        event.stopPropagation();
        state.toggle(item);
      } else if (item.mode === 'radio') {
        state.select(item);
      }
    }

    this.menuItemClicked.emit(item);
  }

  getColor(item?: PrimusMenuItem): 'primary' | 'accent' | 'warn' {
    return item?.color || this.color || 'accent';
  }

  getValue(item?: PrimusMenuItem): any {
    return this.selectionState[item.groupName].selected;
  }

  isActive(item: PrimusMenuItem): boolean {
    return item.groupName &&
      this.selectionState &&
      this.selectionState.hasOwnProperty(item.groupName) &&
      this.selectionState[item.groupName].isSelected(item);
  }
}
