export default class TreeSelectionState<Group, Option> {

  private groups: Array<Group>;
  private options: Array<Option>;

  private state: {[groupId: string]: {[optionId: string]: boolean}};

  constructor(private readonly getGroupId: (group: Group) => string,
              private readonly getGroupOptions: (group: Group) => Array<Option>,
              private readonly getOptionId: (option: Option) => string) {
    this.groups = [];
    this.options = [];
    this.state = {};
  }

  public setGroups(groups: Array<Group>): void {
    this.groups = groups || [];
    this.options = [];
    this.state = {};

    this.groups.forEach(group => {
      const groupOptions = this.getGroupOptions(group);
      const groupState = {};
      this.state[this.getGroupId(group)] = groupState;
      groupOptions.forEach(opt => {
        groupState[this.getOptionId(opt)] = false;
      });
      this.options.push(...groupOptions);
    });
  }

  public getSelectedOptions(): Array<Option> {
    const selectedIds = Object.keys(this.state)
      .map(groupId =>
        Object.keys(this.state[groupId]).filter(optionId => this.state[groupId][optionId])
      ).reduce((acc, val) => [...acc, ...val], []);
    return this.options.filter(opt => selectedIds.includes(this.getOptionId(opt)));
  }

  public clear(): void {
    Object.keys(this.state).forEach(groupId => {
      Object.keys(this.state[groupId]).forEach(optionKey => {
        this.state[groupId][optionKey] = false;
      });
    });
  }

  public toggleGroupSelection(groupId: string): void {
    const newState = !this.isGroupSelected(groupId);
    Object.keys(this.state[groupId]).forEach(optId => this.setState(groupId, optId, newState));
  }

  public toggleOptionSelection(groupId: string, optionId: string): void {
    this.setState(groupId, optionId, !this.isOptionSelected(groupId, optionId));
  }

  public isGroupSelected(groupId: string): boolean {
    try {
      const state = this.state[groupId];
      return Object.keys(state).every(optId => state[optId]);
    } catch (e) {
      return false;
    }
  }

  public isGroupPartiallySelected(groupId: string): boolean {
    if (this.isGroupSelected(groupId)) {
      return false;
    }
    try {
      const state = this.state[groupId];
      return Object.keys(state).some(optId => state[optId]);
    } catch (e) {
      return false;
    }
  }

  public isOptionSelected(groupId: string, optionId: string): boolean {
    try {
      return this.state[groupId][optionId];
    } catch (e) {
      return false;
    }
  }


  private setState(groupId: string, optionId: string, value: boolean): void {
    try {
      this.state[groupId][optionId] = value;
    } catch (e) {
      return;
    }
  }

}
