import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {CmsApiService} from "../../../../core/cms-api.service";
import {ModelStore} from "../../../../core/ModelStore/ModelStore";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {FieldSection, ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";
import {DragulaService} from "ng2-dragula";
import {ReportViewType} from "../../../../core/ModelStore/models/ReportViewType";

@Component({
  selector: 'edit-report-template',
  templateUrl: './edit-report-template.component.html',
  styleUrls: ['./edit-report-template.component.scss']
})
export class EditReportTemplateComponent implements OnInit, OnDestroy {
  reportId: string;
  reportTemplate: ReportUserGenerated;
  reportViewTypes: ReportViewType[];
  selectedReportViewType: ReportViewType;
  showEditReportTemplateTitleArea: boolean = false;
  dragulaId: string = 'HANDLES';
  subs = new Subscription();

  viewOptionsCheckboxValues = [
    {key: 'vis_ledetekster', label: 'Vis ledetekster', selected: false},
    {key: 'med_helbilde', label: 'Med helbilde', selected: false},
    {key: 'høyrejuster_bilder', label: 'Høyrejuster bilder', selected: false},
    {key: 'ledetekst_og_verdi_på_en_linje', label: 'Ledetekst og verdi på én linje', selected: false},
    {key: 'med_frimerkebilder', label: 'Med frimerkebilder', selected: false},
    {key: 'vise_fotograf_for_bilder', label: 'Vise fotograf for bilder', selected: false},
    {key: 'vis_plasseringsdetaljer', label: 'Vis plasseringsdetaljer', selected: false},
    {key: 'vis_søkekriterium', label: 'Vis søkekriterium', selected: false},
    {key: 'vis_sidetall', label: 'Vis sidetall', selected: false}
  ]

  private paramMapSubscription: Subscription;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore,
              private route: ActivatedRoute,
              private cdRef:ChangeDetectorRef,
              private dragulaService: DragulaService) {

    dragulaService.createGroup(this.dragulaId, {
      invalid: (el) => el.classList.contains('report-collapsible-list-child-item')
    });

    this.subs.add(dragulaService.dropModel(this.dragulaId)
      .subscribe(({ name, el, target, source, sourceModel, targetModel, item }) => {
        const targetPosition = targetModel.findIndex((field: any) => field.name === item.name);
        const sourcePosition = this.reportTemplate.fieldSections.findIndex((field: any) => field.name === item.name);

        //When item is dropped, move the source item to the target position in this.availableFieldsKeyList
        this.reportTemplate.fieldSections.splice(targetPosition, 0, this.reportTemplate.fieldSections.splice(sourcePosition, 1)[0]);

        console.log(this.reportTemplate);

        this.triggerUIUpdate();
    }));
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.dragulaId);
    this.subs.unsubscribe();
    this.paramMapSubscription.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.reportViewTypes = this.modelStore.findAllModels('report_view_type');
    // @ts-ignore
    await this.reportViewTypes.loading;

    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      this.reportId = params.get('report_id');
    });

    if (this.reportId)  {
      this.reportTemplate = this.modelStore.findModel('report_user_generated', this.reportId);
      await this.reportTemplate.loading;

      if (this.reportTemplate.reportViewTypeId) {
        this.selectedReportViewType = this.reportViewTypes.find(viewType => viewType.id === this.reportTemplate.reportViewTypeId);
      }

      if (this.reportTemplate.reportViewOptions) {
        this.viewOptionsCheckboxValues.forEach(option => {
          option.selected = this.reportTemplate.reportViewOptions.split(',').includes(option.key);
        });
      }
    }

    await this.fetchFieldSections();
  }

  private async fetchFieldSections() {
    let objectTypes = this.reportTemplate.getObjectTypes();
    let fieldSections: FieldSection[] = [];
    if (objectTypes.length > 0) {
      //For each object type fetch fieldSearchResult and join the array returned via the name property
      for (const objectType of objectTypes) {
        let fieldSearchResult = await this.cms.getModelSections({object_type: objectType});
        fieldSearchResult.forEach(section => {
          //If fieldSearchResult does not contain a section with the same name-property, push it to the fieldSections array
          if (!fieldSections.find(fieldSection => fieldSection['name'] === section['name'])) {
            // @ts-ignore
            fieldSections.push(section);
          }
        });
      }

      this.reportTemplate.applyFieldSectionsFromBackend(fieldSections);
    }
  }

  async persistReportTemplate() {
    let newReportTemplate = this.modelStore.updateModel({modelSingularKey: 'report_user_generated', record: this.reportTemplate});
    this.reportTemplate = null;
    await newReportTemplate.loading;
    this.reportTemplate = this.modelStore.findModel('report_user_generated', newReportTemplate.id);
  }

  toggleFieldExpanded(field: any) {
    field.isExpanded = !field.isExpanded;
  }

  toggleTopLevelField(section: any) {
    section.fields.forEach((field: any) => {
      field.isSelected = section.isSelected;
    });

    this.triggerUIUpdate();
  }

  private triggerUIUpdate() {
    // @ts-ignore
    this.reportTemplate = {...this.reportTemplate};

    // Manually trigger change detection
    this.cdRef.detectChanges();
  }

  toggleSubField() {
    this.triggerUIUpdate();
  }

  markAllTopLevelFields(checked: boolean) {
    this.reportTemplate.fieldSections.forEach((value) => {
      // @ts-ignore
      value.isSelected = checked;
      // @ts-ignore
      value.fields.forEach((field: any) => {
        field.isSelected = checked;
      });
    });

    this.triggerUIUpdate();
  }

  reportViewTypeChanged() {
    this.reportTemplate.reportViewTypeId = this.selectedReportViewType.id;
    this.viewOptionsCheckboxValues.forEach(option => {
      if (option.selected && !this.selectedReportViewType.viewChoices.includes(option.key)) {
        option.selected = false;
      }
    });

    this.reportTemplate.reportViewOptions = this.viewOptionsCheckboxValues.filter(option =>
      option.selected).map(option =>
      option.key).join(',');

    this.triggerUIUpdate();
  }

  toggleViewChoice(viewChoice: any) {
    viewChoice.selected = !viewChoice.selected
    //find all viewChoices that are selected in a comma separated string
    this.reportTemplate.reportViewOptions = this.viewOptionsCheckboxValues.filter(option =>
      option.selected).map(option =>
      option.key).join(',');

    this.triggerUIUpdate();
  }

  createTree(objects: any[]) {
    // Sort the objects alphabetically based on the artifact_name property
    objects.sort((a, b) => a.artifact_name.localeCompare(b.artifact_name));

    // Create a map to store the hierarchy
    const hierarchy = new Map();

    objects.forEach(obj => {
      // Split the name property to get the hierarchy levels
      const levels = obj.name.split(' / ');
      // Start at the top level of the hierarchy
      let currentLevel = hierarchy;

      levels.forEach((level, i) => {
        // If the current level doesn't exist yet, create it
        if (!currentLevel.has(level)) {
          if (i === levels.length - 1) {
            // If this is the last level, add the complete JSON structure
            currentLevel.set(level, { ...obj, children: new Map() });
          } else {
            // Otherwise, create a new object with a children property
            // and keep the original name
            currentLevel.set(level, { name: obj.name, isSelected: false, isExpanded: false, children: new Map() });
          }
        }

        // Move to the next level
        currentLevel = currentLevel.get(level).children;
      });
    });

    //Iterate over keys and delete all keys that have no children
    hierarchy.forEach((value, key) => {
      if (value.children.size === 0) {
        hierarchy.delete(key);
      }
    });

    return hierarchy;
  }

  updateReportPreview() {
    console.log('UPDATING REPORT PREVIEW');
    this.cdRef.detectChanges();
  }
}
