import { Injectable } from '@angular/core';
import {CrudService} from "./crud.service";

@Injectable({
  providedIn: 'root'
})
export class UndoHandlerService {

  constructor(private crud: CrudService) { }

  private static getUndoList(arr: any) {
    if (!arr.$$undo) {
      arr.$$undo = [];
    }
    return arr.$$undo;
  }
  public resetUndo(arr: any) {
    if (arr.$$undo) {
      arr.$$undo = [];
    }
  }
  public addUndo(arr: any[], item: any, index?: number) {
    const undoList = UndoHandlerService.getUndoList(arr);
    undoList.push({
      item: item,
      index: index
    });
    if (undoList.length > 50) {
      undoList.shift();
    }
  }
  public undo(arr: any[]) {
    const undoList = UndoHandlerService.getUndoList(arr);
    if (undoList.length > 0) {
      const undoItem = undoList.pop();
      if (undoItem.index !== undefined) {
        arr.splice(undoItem.index, 0, undoItem.item);
      } else {
        this.crud.deleteDestroy(undoItem);
      }
    }
  }
}
