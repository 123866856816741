import {BaseModel} from './base-model';

export class MediaItem extends BaseModel {
  $$selected: boolean;
  $$mediaId: string;
  $$mediaName: string;

  context_id: string;
  // image only
  code_type_id: string;
  code_type_id_value: string;
}
