import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {
  MetaOperationContainer,
  MetaOperationDef, MetaOperationExecuteStatus,
  MetaOperationList,
  MetaOperationValidation
} from './definitions/meta-operation-def';
import {CommonsService} from './commons.service';

@Injectable({
  providedIn: 'root'
})
export class MetaOperationService {

  constructor(private cms: CmsApiService,
              private commons: CommonsService) {
  }

  async getOperationsByObjectType(objectType): Promise<MetaOperationList> {
    return await this.cms.getOperationsByObjectType(objectType);
  }

  createMetaOperationContainer(metaOperationDef: MetaOperationDef): MetaOperationContainer {
    const metaOperationContainer = new MetaOperationContainer();
    metaOperationContainer.metaOperation = this.commons.copy(metaOperationDef);
    return metaOperationContainer;
  }

  async validateAndExecuteOperation(operationContainer: MetaOperationContainer): Promise<MetaOperationExecuteStatus> {
    let status = new MetaOperationExecuteStatus();
    await this.validateOperation(operationContainer);
    if (operationContainer.metaOperationValidation.status === 'ok') {
      status = await this.executeOperation(operationContainer);
    } else {
      status.status = operationContainer.metaOperationValidation.status;
    }
    return status;
  }

  async validateOperation(operationContainer: MetaOperationContainer): Promise<MetaOperationValidation> {
    operationContainer.metaOperationValidation = await this.cms.validateOperation(operationContainer.metaOperation);
    return operationContainer.metaOperationValidation;
  }

  async executeOperation(operationContainer: MetaOperationContainer): Promise<MetaOperationExecuteStatus> {
    const status = await this.cms.executeOperation(operationContainer.metaOperation);
    operationContainer.metaOperationExecuteStatus = status;
    return status;
  }

  async getOperationStatus(operationContainer: MetaOperationContainer): Promise<MetaOperationExecuteStatus> {
    const status = await this.cms.getOperationStatus(operationContainer.metaOperation.operation_id);
    operationContainer.metaOperationExecuteStatus = status;
    return status;
  }
}
