import {TwoWayMap} from "../TwoWayMap";
import {DefaultModelAdapter} from "../DefaultModelAdapter";

export class ReportUserGeneratedAdapter<ReportUserGenerated> extends DefaultModelAdapter<ReportUserGenerated> {
  keyConversionMap: TwoWayMap = new TwoWayMap();

  constructor(singularKey: string, pluralKey: string) {
    super(singularKey, pluralKey);
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.urlPrefix = '/cms_api/v1.0/report';
    this.urlForId = null;
    this.urlForList = null;

    this.keyConversionMap.set("shortDescription", "short_description");
    this.keyConversionMap.set("reportType", "report_type");
    this.keyConversionMap.set("artifactList", "artifact_list");
    this.keyConversionMap.set("reportViewTypeId", "report_view_type_id");
    this.keyConversionMap.set("reportViewOptions", "report_view_options");
    this.keyConversionMap.set("fieldSections", "field_sections");
  }

  /*
    Override the fromJsonObject method to parse the report_data_json field and set the artifactList property
  */
  fromJsonObject(jsonString: any): ReportUserGenerated {
    let standardAdapterObject: ReportUserGenerated = super.fromJsonObject(jsonString);

    this.parseReportUserTemplate(jsonString[this.singularKey], standardAdapterObject);

    return standardAdapterObject;
  }

  /*
    Parse the report_data_json field and set the artifactList property
   */
  private parseReportUserTemplate(jsonString: any, standardAdapterObject: ReportUserGenerated) {
    if (jsonString.report_data_json) {
      let reportDataJson = JSON.parse(jsonString.report_data_json);
      // @ts-ignore
      standardAdapterObject.artifactList = reportDataJson.artifacts;
    }
  }

  /*
      Override the toJsonList method to convert the artifactList property to a JSON string and set the report_data_json field
     */
  fromJsonList(jsonString: string): ReportUserGenerated[] {
    let returnList: ReportUserGenerated[] = [];
    // @ts-ignore
    let models = jsonString[this.pluralKey];
    if (models) {
      models.forEach((jsonModel: any) => {
        let modelObject: ReportUserGenerated = this.parseJSON(jsonModel);
        this.parseReportUserTemplate(jsonModel, modelObject);
        returnList.push(modelObject);
      });
    }

    return returnList;
  }
}
