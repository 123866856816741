<div class="admin-concept-concepts">
  <div *ngIf="conceptsContainer.loadingConcepts" class="admin-concept-concepts__progress-spinner">
    <mat-spinner color="accent" diameter="22"></mat-spinner>
    <span class="admin-concept-concepts__progress-spinner-text"
          translate="TRANS__LOGIN__PROCESSING"></span></div>
  <div *ngIf="!conceptsContainer.loadingConcepts && conceptsContainer.conceptDataSource.data"
       class="admin-concept-concepts__content">
    <div class="admin-content-top admin-concept-concepts__title-container">
      <h5 *ngIf="conceptsContainer.concepts"><span [translate]="menuTitle"></span> ({{selected}}{{conceptsContainer.concepts.hits}})</h5>

      <app-admin-action-list-menu
        [conceptsContainer]="conceptsContainer"
        [selectedItemLength]="conceptsContainer.selected.length"
      ></app-admin-action-list-menu>
      <app-admin-list-filter-menu
        [conceptsContainer]="conceptsContainer"
        (filterChange)="onSearchParamChange()"
      ></app-admin-list-filter-menu>
      <app-admin-list-query
        class="admin-concept-concepts__search-query"
        [conceptsContainer]="conceptsContainer"
        [hideIcon]="true"
        [setFocus]="false"
        (queryChange)="onSearchParamChange()"
      ></app-admin-list-query>
      <app-admin-list-settings [conceptsContainer]="conceptsContainer"></app-admin-list-settings>
    </div>
    <app-admin-list-table
      *ngIf="conceptsContainer"
      [conceptsContainer]="conceptsContainer"
      [reloadConcepts]="conceptsContainer.reload"
    ></app-admin-list-table>

  </div>


</div>

