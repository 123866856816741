import {Component, ViewEncapsulation} from '@angular/core';
import {M3GlobalStylesComponent} from '../m3-global-styles/m3-global-styles.component';

/**
 * Wrapper-component to incrementally adopt material-3 to components.
 * Includes the global
 */
@Component({
  selector: 'app-m3-wrapper',
  standalone: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  template: `
    <app-m3-global-styles>
        <ng-content></ng-content>
    </app-m3-global-styles>
  `,
  imports: [
    M3GlobalStylesComponent
  ]
})
export class M3WrapperComponent {}
