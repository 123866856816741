<div class="hierarchic-list-panel"
     [ngClass]="{'create': fieldParameters.canAddNew}">
  @if (searching) {
    <div class="hierarchic-list-panel__searching">
      <mat-progress-spinner mode="indeterminate"
                            color="accent"
                            [diameter]="16"></mat-progress-spinner>
      <span translate="TRANS__HIERARCHIC_LIST__LOADING"></span>
    </div>
  } @else {
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="hierarchic-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding="">
        @if (isArray) {
          <mat-checkbox
            [checked]="node['hierarchicNode']['$$isSelected']"
            (change)="onNodeSelected(node, true)"
            tabindex="-1"></mat-checkbox>
        }
        <button class="hierarchic-list-panel__node-text"
                mat-button
                type="button"
                [title]="node['$$name']"
                (click)="onNodeSelected(node, true)">
          <span>{{node['$$name']}}</span>
          @if (node.authority === 'KulturNav') {
            <span class="concept-authority"></span>
          }
        </button>
        @if (node['$$description']) {
          <button (click)="openDescription(node)"
                  type="button"
                  mat-icon-button
                  class="hierarchic-list-panel__node-description">
            <mat-icon>info</mat-icon></button>
        }
      </mat-tree-node>
      <ul>
      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding="">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle
                    type="button"
                    (click)="onNodeOpened(node)"
                    [attr.aria-label]="'Toggle ' + node['$$name']">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            @if (isArray) {
              <mat-checkbox [checked]="node['hierarchicNode']['$$isSelected']"
                            (change)="onNodeSelected(node, false)"
              ></mat-checkbox>
            }
            <button class="hierarchic-list-panel__node-text"
                    mat-button type="button"
                    [title]="node['$$name']"
                    (click)="onNodeSelected(node, false)">
              <span>{{node['$$name']}}</span>
              @if (node.authority === 'KulturNav') {
                <span class="concept-authority"></span>
              }
            </button>
            @if (node['$$description']) {
              <button (click)="openDescription(node)"
                      type="button"
                      mat-icon-button
                      class="hierarchic-list-panel__node-description">
                <mat-icon>info</mat-icon></button>
            }
          </div>
          <ul [class.hierarchic-tree-invisible]="!treeControl.isExpanded(node)"
              class="hierarchic-list-panel__node-children">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-tree-node>
      </ul>
      <mat-tree-node *matTreeNodeDef="let node; when: isLoadMore" matTreeNodePadding="">
        @if (!node['isLoading']) {
          <button mat-stroked-button
                  class="hierarchic-list-panel__loading-button"
                  color="primary"
                  type="button"
                  (click)="onNodeLoadMore(node); $event.stopPropagation();">
            {{'TRANS__HIERARCHIC_LIST__SHOW_MORE' | translate}}</button>
        } @else {
          <div class="hierarchic-list-panel__loading-more-spinner">
            <mat-progress-spinner mode="indeterminate"
                                  color="accent"
                                  [diameter]="16"></mat-progress-spinner>
            <span translate="TRANS__HIERARCHIC_LIST__LOADING"></span>
          </div>
        }
      </mat-tree-node>
    </mat-tree>
  }

</div>
