<div class="progress-dialog">
  <div class="progress-dialog__content">
    <div class="progress-dialog__spinner">
      <div class="progress-dialog__progress"></div>
    </div>
    @if (!!data?.descriptorText) {
        @if (!!data?.count) {
          <div class="progress-dialog__text bold" [translate]="data.descriptorText" [translateParams]="{count: data.count}"></div>
        }
        @else {
          <div class="progress-dialog__text bold" [translate]="data.descriptorText"></div>
        }
    }
  </div>
</div>
