<div class="search-view content-list-source-array" id="searchMainContent">

  <div class="object-content-list__top">
    <div class="object-content-list__search-query"
         [ngClass]="{'noLeftMargin': !contentListSource.operationContainer}">
      <app-search-query
        *ngIf="contentListSource.searchContainer.searchView"
        [searchContainer]="contentListSource.searchContainer"
        [newLayout]="false"
        [useQueryChange]="true"
        (queryChange)="onContentChange(false, true)"
      ></app-search-query>
    </div>

    <div class="object-content-list__top-button-actions"
         [ngClass]="{'active': contentListSource.operationContainer?.searchContainer?.toggleActionMenu}">
      <div class="object-content-list__top-button"
           [ngClass]="{'disable': disabled}"
           *ngIf="contentListSource.operationContainer">
        <app-search-menu-select-all
          class="object-content-list__select-all"
          [searchContainer]="contentListSource.searchContainer"
          [contentListSource]="contentListSource"
        ></app-search-menu-select-all>

        <app-operation-menus-content-list
          class="object-content-list__menu-actions"
          [contentListContainer]="contentListSource"
          [disabled]="disabled"
          (operationExecuted)="onContentChange(false, true, $event)"
        ></app-operation-menus-content-list>
      </div>
    </div>

    <div class="object-content-list__top-button-filter">
      <app-object-content-list-source-array-filter
        [contentListSource]="contentListSource"
        (filterSet)="onContentChange($event, false)">
      </app-object-content-list-source-array-filter>
    </div>

    <div class="object-content-list__top-button-sort">
      <app-dropdown-menu-animated
        [menuContainer]="{
        view: { description: 'TRANS__SEARCH__ORDER_ON', icon: 'sort'},
        contentList: true,
        disableContainer: null,
        disableProp: null}"
        class="object-content-list__top-button">
        <app-object-content-list-source-array-sort
          [contentListSource]="contentListSource"
          (setOrder)="onContentChange($event, true)"
        ></app-object-content-list-source-array-sort>
      </app-dropdown-menu-animated>
    </div>
  </div>

  <div id="innerCon" class="content-list-source-array__container">
    <app-object-content-list-source-array-items
      [contentListSource]="contentListSource"
      [hasOperationMenu]="!!contentListSource.operationContainer"
      [triggerAnimation]="triggerAnimation"
      (afterDeleted)="onAfterDeleted()"
    ></app-object-content-list-source-array-items>

    <app-object-content-list-source-array-pager
      [contentListSource]="contentListSource"
      (pageChange)="onContentChange(true, false)"
    ></app-object-content-list-source-array-pager>
  </div>

</div>

