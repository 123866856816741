import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateToolsService} from '../../core/date-tools.service';
import {Facet} from '../../core/definitions/search-objects';

@Component({
  selector: 'app-date-search-field',
  templateUrl: './date-search-field.component.html',
  styleUrls: ['./date-search-field.component.scss']
})
export class DateSearchFieldComponent implements OnInit {
  @Input() parentModel: Facet;
  @Input() propName: string;
  @Input() greaterThan: string;
  @Input() lessThan: string;
  @Input() dateChangeArgs;
  @Output() dateChange = new EventEmitter<object>();
  @Output() dateUpdate = new EventEmitter<string>();

  valid = true;
  localDate;

  constructor(private readonly dateTools: DateToolsService) {
  }

  ngOnInit() {
    if (this.parentModel) {
      this.localDate = this.dateTools.solrDateToLocalStringDate(this.parentModel[this.propName]);
      this.onChange(this.localDate);
    }
  }

  setSolrDate() {
    let dateRes;
    if (this.parentModel) {
      if (this.localDate) {
        dateRes = this.dateTools.localStringDateToSolrDate(this.localDate);
        this.valid = this.checkValidDate(dateRes);
        if (dateRes.valid && this.parentModel[this.propName] !== dateRes.solrDate) {
          this.parentModel[this.propName] = dateRes.solrDate;
          this.runDateChangeFn(dateRes.solrDate);
        }
      } else {
        this.valid = false;
      }
    }
  }

  private runDateChangeFn(solrDate) {
    if (this.dateChangeArgs) {
      this.dateChange.emit(this.dateChangeArgs);
    } else {
      this.dateChange.emit(solrDate);
    }
  }

  private checkValidDate(dateRes) {
    if (dateRes.valid) {
      if (this.lessThan && dateRes.solrDate > this.parentModel[this.lessThan]) {
        dateRes.valid = false;
      }
      if (this.greaterThan && dateRes.solrDate < this.parentModel[this.greaterThan]) {
        dateRes.valid = false;
      }
    }
    return dateRes.valid;
  }

  /**
   * Reacts to onChange in localDate, returns value if valid
   * @return a date string value formatted as YYYY-MM-DDTHH:mm:ss
   */
  onChange(date: any) {
    const dateRes = this.dateTools.localStringDateToSolrDate(date, this.propName);
    const valid = this.checkValidDate(dateRes);
    if(dateRes.valid && valid) {
      this.dateUpdate.emit(dateRes.solrDate);
    }
  }
}
