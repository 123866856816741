<div class="search-menu-folder-settings">
  <button class="search-menu-folder-settings__button"
          [matMenuTriggerFor]="folderMenu"
          [matMenuTriggerRestoreFocus]="false"
          (menuOpened)="toggleMenu = true;"
          (menuClosed)="toggleMenu = false;"
          [ngClass]="{'active': toggleMenu}"
          (click)="toggleFolderSettingMenu()">
    <mat-icon class="search-menu-folder-settings__button-setting-icon">settings</mat-icon>
    <mat-icon class="search-menu-folder-settings__button-arrow">{{toggleMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>

  <mat-menu #folderMenu="matMenu"
            [overlapTrigger]="false"
            class="removePaddingInMatMenu"
            (closed)="toggleMenu = false;">
    <mat-list *ngIf="searchContainer.operationContainerDropDown?.operations" class="common-search-menu">
      <ng-container *ngFor="let operation of searchContainer.operationContainerDropDown.operations">
        <button mat-menu-item
                class="search-menu-folder-settings__menu-button"
                (click)="openOperation(operation)">
          <i class="{{operation.menu_icon}}"></i>
          <span [translate]="operation.menu_title"></span></button>
      </ng-container>
    </mat-list>
  </mat-menu>

</div>

