import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CmsApiService} from '../../core/cms-api.service';
import {AConst} from '../../core/a-const.enum';
import {SelectionModel} from "@angular/cdk/collections";
import {CultureHubOption, CultureHubParams} from '../../core/definitions/culture-hub-params';

export interface CultureHubConceptElement {
  selected: boolean;
  conceptName: string;
  dataSet: string;
  uuid: string;
}

@Component({
  selector: 'app-culture-hub-importer-items',
  templateUrl: './culture-hub-importer-items.component.html',
  styleUrls: ['./culture-hub-importer-items.component.scss']
})
export class CultureHubImporterItemsComponent implements OnInit {

  @Input() dialogRef: MatDialogRef<any>;
  @Input() cultureHubData: CultureHubParams;


  hasSelections = false;
  cultureHubConceptDataSource: CultureHubConceptElement[] = [];
  conceptColumns: string[] = ['selected', 'conceptName', 'dataSet'];
  searching = false;
  importing = false;
  noResults = false;
  errors = [];
  importedItems: any[] = [];
  selectedItems: any[] = [];
  initQuery = '';
  selectedOption: CultureHubOption;
  options: CultureHubOption[];
  selection: SelectionModel<CultureHubConceptElement>;

  constructor(
    private cms: CmsApiService) {
  }

  ngOnInit(): void {
    // This will enable the possibility to choose between single and multiple selection mode
    // Must add a variable to differentiate when to use when (backend)
    this.selection = new SelectionModel(this.cultureHubData.multi_import, [])
    this.initQuery = this.cultureHubData.phrase || '';
    if (this.cultureHubData.sub_type && this.cultureHubData.sub_type.options) {
      this.options = this.cultureHubData.sub_type.options;
      for (const option of this.options) {
        if (option.selected) {
          this.selectedOption = JSON.parse(JSON.stringify(option));
        }
      }
    }
    if (this.initQuery) {
      if (this.initQuery.indexOf('*') === -1) {
        this.initQuery += '*';
      }
      this.runSearch(this.initQuery).then();
    }
  }

  async runImport() {
    this.importing = true;
    this.errors = [];
    for (const element of this.selection.selected) {
      try {
        const importItem = await this.cms.importFromCultureHub({
          uuid: element.uuid,
          concept_type_id: this.cultureHubData.concept_type_id,
          suppressErrHandler: true
        });
        if (importItem[AConst.ARTIFACT_ID]) {
          this.importedItems.push(importItem);
        } else {
          if (importItem && importItem.message) {
            this.errors.push(importItem.message);
          } else {
            this.errors.push('TRANS__CULTURE_HUB_SEARCH__IMPORT_FAILED');
          }
          break;
        }
        this.selection.deselect(element);
      } catch (e) {
        this.errors.push('TRANS__CULTURE_HUB_SEARCH__IMPORT_FAILED');
        this.errors.push(e.error?.message);
        break;
      }
    }
    if (!this.errors.length) {
      this.dialogRef.close(this.importedItems);
    }
    this.importing = false;
  }

  async runSearch(query) {
    if (query && this.validPhrase(query)) {
      this.hasSelections = false;
      this.noResults = false;
      this.searching = true;
      this.cultureHubConceptDataSource = [];
      const res = await this.cms.searchCultureHub({
        entity_type: this.cultureHubData.entity_type,
        concept_type_id: this.cultureHubData.concept_type_id,
        sub_type: this.selectedOption?.value,
        query: query,
        suppressErrHandler: true
      });
      this.searching = false;
      if (res.length) {
        this.cultureHubConceptDataSource = res.map(searchElement => {
          return {
            selected: false,
            conceptName: searchElement['artifact_name'],
            dataSet: searchElement['dataset'],
            uuid: searchElement['uuid']
          };
        });
      } else {
        this.cultureHubConceptDataSource = [];
        this.noResults = true;
      }
    }
  }

  closeImport() {
    this.dialogRef.close(this.importedItems);
  }

  private validPhrase(searchText) {
    const illegalCharacterErr = 'TRANS__CULTURE_HUB_SEARCH__ILLEGAL_CHARACTER_IN_QUERY';
    let res = true;
    const illegalCharacters = ['#', '%', '/', '\\', '?'];
    for (const illegalCharacter of illegalCharacters) {
      if (searchText.indexOf(illegalCharacter) !== -1) {
        res = false;
        break;
      }
    }
    const errExistPos = this.errors.indexOf(illegalCharacterErr);
    if (!res) {
      if (errExistPos === -1) {
        this.errors.push(illegalCharacterErr);
      }
    } else if (errExistPos !== -1) {
      this.errors.splice(errExistPos, 1);
    }
    return res;
  }

  findSelected(element): CultureHubConceptElement {
    return this.selection.selected.find(selected => selected?.uuid === element?.uuid);
  }

  /**
   * Custom deselect method.
   * If multiple selection mode, we have to find element from selection and use that to deselect.
   * This is to prevent cases where we refresh DOM and element is treated as element !== old.element.
   *
   * @param element
   */
  deselect(element) {
    if (!this.selection.isMultipleSelection()) {
      this.selection.clear();
    } else {
      this.selection.deselect(this.findSelected(element))
    }
  }
}
