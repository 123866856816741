export class ModelType {
  singularKey: string = "";
  pluralKey: string = "";
  classInstance: any;

  constructor(singularKey: string, pluralKey: string, classInstance: any) {
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.classInstance = classInstance;
  }
}
