import {Component, Input, OnInit} from '@angular/core';
import {Setting} from '../../../core/definitions/setting';
import {CommonsService} from '../../../core/commons.service';
import {CmsApiService} from '../../../core/cms-api.service';

@Component({
  selector: 'app-admin-settings-setting',
  templateUrl: './admin-settings-setting.component.html',
  styleUrls: ['./admin-settings-setting.component.scss']
})
export class AdminSettingsSettingComponent implements OnInit {

  @Input() setting: Setting;
  private origSetting: Setting;
  editing = false;
  error = '';
  storing = false;

  constructor(private cms: CmsApiService,
              private commons: CommonsService) {
  }

  ngOnInit() {
    this.origSetting = this.commons.copy(this.setting);
  }

  editSetting() {
    this.editing = true;
  }

  storeSetting() {
    this.error = '';
    this.storing = true;
    this.cms.putSetting(this.setting).then(
      () => {
        this.editing = false;
        this.storing = false;
      },
      (reason) => {
        this.error = reason.error.message;
        this.storing = false;
      });
  }

  cancelSetting() {
    this.error = '';
    this.editing = false;
    this.setting = this.origSetting;
    this.origSetting = this.commons.copy(this.setting);
  }
}
