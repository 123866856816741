import {Component, Input, OnInit} from '@angular/core';
import {FieldValue, FieldValues} from '../../core/definitions/object-view';

@Component({
  selector: 'app-object-view-field-values',
  templateUrl: './object-view-field-values.component.html',
  styleUrls: ['./object-view-field-values.component.scss']
})
export class ObjectViewFieldValuesComponent implements OnInit {

  @Input() fieldValues: FieldValues;
  @Input() stylingClass: string;
  @Input() edit: boolean;

  fieldValuesValues: FieldValue[] = [];
  fieldValueClass = '';

  constructor() { }

  ngOnInit() {
    let fieldValuesValues = this.fieldValues.values;
    if (fieldValuesValues.length > 1 && fieldValuesValues.some(value => value.is_text)) {
      const fieldValue = new FieldValue();
      fieldValue.is_text = true;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      fieldValue.value = fieldValuesValues.map(fieldValue => fieldValue.value).reduce((val1, val2) => val1 + val2);
      const fieldValuesWithRefId = fieldValuesValues.filter(value => !!value.ref_id);
      if (fieldValuesWithRefId.length) {
        fieldValue.ref_id = fieldValuesWithRefId[0].ref_id;
        fieldValue.ref_object_type = fieldValuesWithRefId[0].ref_object_type;
        fieldValue.ref_meta_type = fieldValuesWithRefId[0].ref_meta_type;
        if (fieldValuesWithRefId.length > 1) {
          console.warn('There were more than 1 ref id in field values!');
        }
      }
      const delTagPos = fieldValue.value.indexOf('<del>');
      if (delTagPos !== -1) {
        this.fieldValueClass = 'contains-deleted';
        fieldValue.value = fieldValue.value.replaceAll('<del>', '').replaceAll('</del>', '');
      }
      fieldValuesValues = [fieldValue];
    }
    this.fieldValuesValues = fieldValuesValues;
  }

}
