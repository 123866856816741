import {Injectable} from '@angular/core';
import {PrimusRouteService} from '../core/primus-route.service';
import {SearchHandlerService} from './search-handler.service';
import {UiToolsService} from '../core/ui-tools.service';
import {SearchContainer, SearchContainerParams} from '../core/definitions/search-container';
import {SearchTemplateGroupService} from './search-template-group.service';
import {PrimusRouterService} from '../core/primus-router.service';
import {LoggerService} from "../core/logger.service";

@Injectable({
  providedIn: 'root'
})
export class SearchPageService {

  private searchContainer: SearchContainer;
  private creatingSearchContainer = false;
  private callbacks = [];

  constructor(private logger:LoggerService,
              private searchHandler: SearchHandlerService,
              private uiTools: UiToolsService,
              private primusRoute: PrimusRouteService,
              private primusRouter: PrimusRouterService,
              private searchTemplateGroupService: SearchTemplateGroupService) {
  }

  async checkCreateSearchContainer(): Promise<SearchContainer> {
    const toStateName = this.primusRouter.currentState();
    if (this.creatingSearchContainer) {
      return;
    }
    if (!this.searchContainer) {
      this.creatingSearchContainer = true;
      const stateParams = this.primusRoute.params;
      const searchContainerParams = new SearchContainerParams();
      searchContainerParams.searchViewName = 'root';
      searchContainerParams.toState = toStateName;
      searchContainerParams.targetState = 'home.primus.search';
      searchContainerParams.getFocus = true;
      searchContainerParams.useFocus = !stateParams.path;
      searchContainerParams.runSearch = true;
      searchContainerParams.stateParams = stateParams;
      const sc = await this.searchHandler.createSearchContainer(searchContainerParams);
      this.logger.info('Search container for search page created');
      this.searchContainer = sc;
      this.runCallbacks(sc);
      this.creatingSearchContainer = false;
    } else {
      this.searchContainer.toState = toStateName;
      if (this.searchContainer.templateGroupId !== this.primusRoute.params.template_group_id) {
        this.searchTemplateGroupService.setTemplateGroup(this.searchContainer, this.primusRoute.params.template_group_id);
      }
    }
    return this.searchContainer;
  }

  clearSearchContainer() {
    this.searchContainer = undefined;
  }

  addSearchContainerCreationListener(callback) {
    if (!this.searchContainer) {
      this.callbacks.push(callback);
    } else {
      callback(this.searchContainer);
    }
  }

  private runCallbacks(searchContainer: SearchContainer) {
    this.callbacks.forEach(callback => {
      callback(searchContainer);
    });
    this.callbacks = [];
  }
}
