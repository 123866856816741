import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {UserSettingsService} from '../../../core/user-settings.service';
import {CopyKeepService} from '../../copy-keep-checkbox/copy-keep.service';
import {Section, SectionsContainer} from '../../../core/definitions/sections-container';

export interface SectionInfo {
  keep: boolean;
  showKeepCheckbox: boolean;
}

@Component({
  selector: 'app-section-view-title-edit',
  templateUrl: './section-view-title-edit.component.html',
  styleUrls: ['./section-view-title-edit.component.scss']
})
export class SectionViewTitleEditComponent implements OnChanges {
  hideSectionWarning = '';

  @Input() section: Section;
  @Input() sectionsContainer: SectionsContainer;
  @Output() updateKeepSection = new EventEmitter<Section>();

  hideHideSectionWarning = false;
  sectionInfo: SectionInfo = {} as SectionInfo;

  constructor(private copyKeepService: CopyKeepService, private userSettings: UserSettingsService) {
  }

  ngOnChanges() {
    if (this.sectionsContainer.isCopy) {
      if (this.sectionsContainer.copyOptions) {
        const objType = this.sectionsContainer.rootObject.object_type;
        const objCopyOptions = this.sectionsContainer.copyOptions[objType];
        this.sectionInfo = objCopyOptions.sections[this.section.name];
        if (!this.sectionInfo) {
          this.sectionInfo = {showKeepCheckbox: true, keep: true};
          this.sectionsContainer.copyOptions[objType].sections[this.section.name] = this.sectionInfo;
        }
        if (this.section.order > 1) {
          if (objCopyOptions.allSections.keep) {
            this.sectionInfo.keep = true;
          }
          this.setKeepSection(this.sectionInfo.keep);
          this.section.keep = this.sectionInfo.keep;
          this.updateKeepSection.emit(this.section);
        } else {
          this.section.keep = true;
        }
      } else {
        console.warn('Copy options missing!');
      }
    }
  }

  setSectionKeep() {
    this.sectionInfo.keep = !this.sectionInfo.keep;
    const keep = this.sectionInfo.keep;
    if (this.sectionInfo) {
      this.section.keep = keep;
      if (!keep) {
        const objType = this.sectionsContainer.rootObject.object_type;
        const objCopyOptions = this.sectionsContainer.copyOptions[objType];
        objCopyOptions.allSections.keep = false;
      }
      this.setKeepSection(keep);
      this.userSettings.storeCopyOptions(this.sectionsContainer.copyOptions);
      this.updateKeepSection.emit(this.section);
    }
  }

  onSetSectionKeepWithKey(evt: any) {
    if (evt.key === 'Enter') {
      this.setSectionKeep();
      evt.preventDefault();
    }
  }

  toggleSectionVisible() {
    this.section.section_visible = !this.section.section_visible;
    if (!this.section.section_visible && !this.hideHideSectionWarning) {
      this.hideSectionWarning = 'TRANS__HIDE_SECTION_WARNING';
    } else {
      this.hideSectionWarning = '';
    }
  }

  setHideHideSectionWarning() {
    this.hideHideSectionWarning = true;
    this.hideSectionWarning = '';
  }

  private setKeepSection(keep: boolean) {
    this.copyKeepService.setKeepSection(this.sectionsContainer.rootObject, this.section, keep);
  }

}
