import {CmsApiService} from '../../core/cms-api.service';
import {SimpleClientCache} from '../../core/simple-client-cache';
import {OperationDef} from '../../core/definitions/operation-def';

/**
 * Class used when loading FAQ-data for each of the FAQ-categories.
 */
export class FaqLoader {

  private readonly cache: SimpleClientCache;
  private categories = [];
  private faqData = [];
  private cachedFaqData = [];

  /**
   * Object constructor
   * @param operations
   * @param cms
   */
  constructor(private operations: OperationDef[],
              private cms: CmsApiService) {
    this.cache = new SimpleClientCache('faqCategories');
    this.getCategories();
  }

  /**
   * A promise returning the FAQ categories data either from the client cache or from the server.
   */
  async getFAQData(): Promise<any[]> {
    this.loadFAQDataFromCache();
    if (!this.cachedFaqData || this.cachedFaqData.length === 0) {
      await this.loadFAQData();
      this.buildCache();
      this.loadFAQDataFromCache();
      return this.cachedFaqData;
    } else {
      return this.cachedFaqData;
    }
  }

  /**
   * Creates a list of all FAQ categories, based on information
   * found in each of the sub-menus.
   */
  private getCategories() {
    for (let i = 0, len = this.operations.length; i < len; i++) {
      const operation = this.operations[i];
      if (operation.sub_operations) {
        const categories = operation.sub_operations.map(m => m.menu_category);
        this.categories = this.categories.concat(categories);
      }
    }
  }

  /**
   * Fetches the data for the specified FAQ-category.
   * @param category
   */
  private loadFaqCategory(category) {
    return new Promise(resolve => {
      this.cms.getFaq({
        category: category
      }).then(
        (res: any) => {
          resolve(res);
        },
        function (response) {
          console.warn('Unable to get FAQ in category \'' + category + '\': ' + response.status + ': ' + response.data);
        }
      );

    });
  }

  /**
   * Fetches data for all FAQ-categories.
   */
  private async loadFAQData(): Promise<void> {
    return new Promise(resolve => {
      const categoryData: Array<Promise<any>> = [];

      for (let n = 0, len = this.categories.length; n < len; n++) {
        const category = this.categories[n];
        categoryData.push(
          this.loadFaqCategory(category).then((data: any) => {
            if (Object.keys(data).length > 0) {
              this.faqData.push(data);
            }
          })
        );
      }

      Promise.all(categoryData).then(() => {
        // Data is loaded for all FAQ-categories, resolve!
        resolve();
      });
    });
  }

  /**
   * Builds the client cache, by adding the FAQ categories data.
   */
  private buildCache() {
    this.cache.put(this.faqData);
  }

  /**
   * Loads the FAQ categories data from the client cache.
   */
  private loadFAQDataFromCache() {
    if (!this.cache) {
      return;
    }
    this.cachedFaqData = this.cache.get();
  }
}
