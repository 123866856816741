<section class="header-and-description-wrapper">
  <header>
    <div class="heading">
      <h3>{{ (heading || '') | translate }}
        @if (headingName) {
          <span>: {{headingName}}</span>
        }
      </h3>
    </div>
    <div class="description">
      <div class="description__vertical-line bg-blue"></div>
      <h5>{{ (description || '') | translate }}
        @if (count) {
          <span>({{count}})</span>
        }
        @if (href) {
          <a class="link text-primary"
             href="mailto:{{href}}">{{href}}</a>
        }
      </h5>
    </div>
  </header>
  <article>
    <ng-content></ng-content>
  </article>
</section>
