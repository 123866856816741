export enum OperationActionType {
  CANCEL_REGISTER = 'cancelRegister',
  CHANGE_STATE = 'changeState',
  CLOSE_NO_SUBMIT = 'closeNoSubmit',
  DELETE = 'delete',
  NEXT_STEP = 'nextStep',
  PREVIOUS_STEP = 'previousStep',
  REGISTER = 'register',
  REGISTER_STAY_ON_STEP = 'registerStayOnStep'
}
