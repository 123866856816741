import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PrimusMenuItem} from '../../primus-menu-button/primus-menu-button-item/primus-menu-button-item.component';
import {PrimusMenuButtonEvent} from '../../primus-menu-button/primus-menu-button.component';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

@Component({
  selector: 'app-primus-solr-data-table-filter',
  templateUrl: './primus-solr-data-table-filter.component.html',
  styleUrls: ['./primus-solr-data-table-filter.component.scss']
})
export class PrimusSolrDataTableFilterComponent {

  @Input() public filterHeading = '';
  @Input() public searchBarPlaceholder = '';

  @Input() public settings: Array<PrimusMenuItem>;

  @Output() public readonly settingClicked: EventEmitter<PrimusMenuButtonEvent> = new EventEmitter<PrimusMenuButtonEvent>();
  @Output() public readonly filterStringChange: Observable<string>;

  readonly filterControl: UntypedFormControl = new UntypedFormControl();


  constructor() {
    this.filterStringChange = this.filterControl.valueChanges.pipe(
      debounceTime(300),
      map(val => val || ''),
    );
  }
}
