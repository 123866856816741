<div class="annotate-dashboard">
  <div class="row annotate-dashboard__inner-container">
    <div class="small-12 medium-12 large-4 columns annotate-dashboard__first-column"
         [ngClass]="{'hasParent': !hideParent()}">

      <div class="annotate-dashboard__top bold">
        <span translate="TRANS__ANNOTATE_MENU__ANNOTATION"></span></div>

      <div class="annotate-dashboard__bottom row">

        <div class="small-3 medium-3 large-3 columns annotate-dashboard__inner-columns">
          <app-annotation-shape-menu [curAnn]="curAnn"></app-annotation-shape-menu>
        </div>

        <div class="small-3 medium-3 large-3 columns annotate-dashboard__inner-columns">
          <app-annotation-color-menu [curAnn]="curAnn"></app-annotation-color-menu>
        </div>

        <div class="small-6 medium-6 large-6 columns annotate-dashboard__inner-columns">
          <app-annotation-dashboard-buttons [curAnn]="curAnn"></app-annotation-dashboard-buttons>
        </div>
      </div>

    </div>

    <div class="annotate-dashboard__column-helper-container"
         [ngClass]="{'hasParent': !hideParent()}">

      <div *ngIf="!hideParent()"
           class="small-12 medium-12 large-12 columns annotate-dashboard__context-and-parent">
        <app-annotation-parents [curAnn]="curAnn" ></app-annotation-parents>
      </div>

      <div class="annotate-dashboard__second-and-third-column-container">

        <div class="small-12 medium-12 large-6 columns annotate-dashboard__second-column">
          <app-annotation-description [curAnn]="curAnn"></app-annotation-description>
        </div>

        <div class="small-12 medium-12 large-6 columns annotate-dashboard__third-column">
          <app-annotation-points
            [curAnn]="curAnn"
            class="annotate-dashboard__annotation-points"></app-annotation-points>
        </div>

      </div>
    </div>
  </div>
</div>
