import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {PrimusMenuItem} from '../../../../shared/primus-menu-button/primus-menu-button-item/primus-menu-button-item.component';
import {PrimusMenuButtonEvent} from '../../../../shared/primus-menu-button/primus-menu-button.component';
import {CmsApiService} from '../../../../core/cms-api.service';
import {SearchObject} from '../../../../core/definitions/search-object';
import {SearchParameters} from '../../../../core/definitions/search-parameters';
import {SolrFilterService} from '../../../../core/solr-filter.service';
import {SuperObjectModel} from '../../../../core/definitions/super-object-model';
import {SearchService} from "../../../../core/search.service";
import {SettingsService} from "../../../../core/settings.service";


enum CreatedTime {
  ANY_TIME = 0,
  ONE_DAY = 24 * 60 * 60 * 1000,
  ONE_WEEK = 7 * ONE_DAY,
  ONE_MONTH = 30 * ONE_DAY,
  ONE_YEAR = 365 * ONE_DAY
}

@Component({
  selector: 'app-admin-location-filter',
  templateUrl: './admin-location-filter.component.html',
  styleUrls: ['./admin-location-filter.component.scss']
})
export class AdminLocationFilterComponent implements OnChanges {

  @Input() public readonly parentName: string;
  @Input() public readonly selectedLocations: string[];
  @Output() public readonly filterStringChanged: EventEmitter<string>;
  @Output() public readonly filterChanged: EventEmitter<string>;
  @Output() public readonly  actionPerformed = new EventEmitter<void>();

  filterItems: Array<PrimusMenuItem> = [];

  filterValid: boolean | null = true;
  filterLocationTypes: Array<string> = [];
  filterCreatedAfter: CreatedTime = CreatedTime.ANY_TIME;
  operationContextObjects: SuperObjectModel[];

  constructor(private readonly searchService: SearchService,
              private readonly solrFilter: SolrFilterService,
              private settings: SettingsService) {
    this.filterStringChanged = new EventEmitter<string>();
    this.filterChanged = new EventEmitter<string>();
    this.settings = settings;
  }

  ngOnChanges(): void {
    // Need to do this COMPLETELY UNNECESSARY conversion of locations due to "PrimusSolrDataTableComponent" only
    // retuning artifact ids and not useful things like object type, meta type necessary to obtain relevant meta
    // operations
    this.setOperationContextObjects(this.selectedLocations).then();
    this.buildAndEmitFilter();
    const searchParams = {
      query: '*:*',
      fl: ['artifact_name', 'artifact_id'],
      sort: 'artifact_name asc',
    } as SearchParameters;
    this.solrFilter.addFq(searchParams, 'object_type', this.settings.getClientConfig().CONCEPT_TYPE_LOCATION_TYPE);
    this.searchService.search(searchParams).then(res => this.setFilters(res?.artifacts || []))
      .catch(err => {
        console.error(err);
        this.setFilters([]);
      });
  }

  onActionPerformed(event) {
    if (event.refreshView) {
      this.actionPerformed.emit();
    }
  }

  private async setOperationContextObjects(artifactIds) {
    if (artifactIds.length) {
      const searchParams = {} as SearchParameters;
      this.solrFilter.addFq(searchParams, 'artifact_id', artifactIds);
      const searchRes = await this.searchService.search(searchParams);
      this.operationContextObjects = searchRes.artifacts;
    } else {
      this.operationContextObjects = [];
    }
  }

  private setFilters(locationTypes: Array<SearchObject>): void {
    this.filterItems = [
      {
        label: 'TRANS__ADMIN__LOCATION__FILTER__VALIDITY',
        items: [
          {
            label: 'TRANS__ADMIN__LOCATION__FILTER__VALIDITY__ALL',
            mode: 'radio',
            groupName: 'valid',
            value: null,
          }, {
            label: 'TRANS__ADMIN__LOCATION__FILTER__VALIDITY__VALID',
            mode: 'radio',
            groupName: 'valid',
            value: true,
            default: true
          }, {
            label: 'TRANS__ADMIN__LOCATION__FILTER__VALIDITY__INVALID',
            mode: 'radio',
            groupName: 'valid',
            value: false
          }
        ],
      },
      {
        label: 'TRANS__ADMIN__LOCATION__FILTER__TYPE',
        items: locationTypes.map(type => ({
          id: type.artifact_id,
          mode: 'checkbox',
          groupName: 'location_type_id',
          label: type.artifact_name,
          value: type.artifact_id
        } as PrimusMenuItem))
      },
      {
        label: 'TRANS__ADMIN__LOCATION__FILTER__CREATED_AT',
        items: [
          {
            label: 'TRANS__ADMIN__LOCATION__FILTER__CREATED_AT__ANY_TIME',
            mode: 'radio',
            groupName: 'created_at',
            value: CreatedTime.ANY_TIME,
            default: true
          },
          {
            label: 'TRANS__ADMIN__LOCATION__FILTER__CREATED_AT__LAST_WEEK',
            mode: 'radio',
            groupName: 'created_at',
            value: CreatedTime.ONE_WEEK
          },
          {
            label: 'TRANS__ADMIN__LOCATION__FILTER__CREATED_AT__LAST_MONTH',
            mode: 'radio',
            groupName: 'created_at',
            value: CreatedTime.ONE_MONTH
          },
          {
            label: 'TRANS__ADMIN__LOCATION__FILTER__CREATED_AT__LAST_YEAR',
            mode: 'radio',
            groupName: 'created_at',
            value: CreatedTime.ONE_YEAR
          }
        ]
      }
    ];
  }

  handleFilterClicked(event: PrimusMenuButtonEvent): void {
    switch (event?.selectionGroup) {
      case 'valid':
        this.filterValid = event.item.value;
        break;
      case 'location_type_id':
        this.filterLocationTypes = (event.allSelected || []).map(item => item.value);
        break;
      case 'created_at':
        this.filterCreatedAfter = event.item.value ?? CreatedTime.ANY_TIME;
        break;
    }
    this.buildAndEmitFilter();
  }

  private buildAndEmitFilter(): void {
    const fq = [];
    if (this.filterValid !== null) {
      fq.push(`valid:${!!this.filterValid ? 'true' : 'false'}`);
    }
    if (this.filterLocationTypes?.length) {
      fq.push(`location_type_id:("${this.filterLocationTypes.join('","')}")`);
    }
    if (this.filterCreatedAfter !== CreatedTime.ANY_TIME) {
      const fromTime = new Date(Date.now() - this.filterCreatedAfter).toISOString();
      fq.push(`created_at:[${fromTime} TO *]`);
    }
    this.filterChanged.emit(fq.join(' AND '));
  }
}
