<div class="row operation-menus-object obj-menus-commons"
     [ngClass]="{'edit': isEditing, 'on-media-page': mediaPage}"
     id="actionMenuContainer">
  <div class="obj-menus-commons__content">
    <div *ngFor="let operation of operationContainer.operations"
         class="obj-menus-commons__action-menu-container dropdownIgnoreClicks"
         [matTooltip]="operation.$$toolTip"
         [matTooltipDisabled]="!operation.$$disabled"
         [@fadeIn]="operationContainer.operations">
      <button class="obj-menus-commons__action-menu"
              (click)="toggleOperationMenu(operation)"
              [class.text-dark-primary-hover]="!operation.$$showOperationMenu"
              [class.active]="operation.$$showOperationMenu &&
              operation.sub_operations && operation.sub_operations.length > 0"
              [disabled]="isEditing || (rootObject.read_only && !operation.read_access) ||
              operation.$$disabled">
        <span class="obj-menus-commons__action-menu-text-container">
          <i class="{{operation.menu_icon}}"></i>
          <span class="obj-menus-commons__action-menu-text removeTextOnSmallScreens"
                [translate]="operation.menu_title"></span></span>
      </button>
      <div *ngIf="operation.$$showOperationMenu && !isEditing && operation.sub_operations && operation.sub_operations.length > 0"
           class="obj-menus-commons-ddl operation-menus-object__dropdown-menu">
        <div [ngClass]="{'activeMenu': operationContainer.currentOperation}">
          <ul class="operation-menus-object__dropdown-menu__first-level"
              [ngClass]="{'verticalView': operation.$$noChildren}">
            <li class="operation-menus-object__dropdown-menu__first-level-content"
                *ngFor="let subOperation of operation.sub_operations"
                [ngClass]="{
                'hasSubMenu': subOperation.sub_operations,
                'four': subOperation.sub_operations && operation.sub_operations.length === 4,
                'three': subOperation.sub_operations && operation.sub_operations.length === 3,
                'two': subOperation.sub_operations && operation.sub_operations.length === 2}">
              <a *ngIf="!subOperation.sub_operations"
                 (click)="openOperation(subOperation, operation)"
                 class="obj-menus-commons-menuGroup-btn"
                 [ngClass]="{'disabled': subOperation.$$disabled}"
                 [title]="subOperation.$$toolTip">
                <span class="obj-menus-commons-btn-text">
                  <i class="{{subOperation.menu_icon}} menuItem-icon"></i>
                  <span [translate]="subOperation.menu_title"></span>
                </span>
              </a>
              <span *ngIf="subOperation.sub_operations"
                    class="obj-menus-commons-btn-lyt operation-menus-object__button-title">
                  <span class="obj-menus-commons-btn-text">
                      <span [translate]="subOperation.menu_title"></span>
                  </span>
              </span>

              <div *ngIf="subOperation.sub_operations"
                   class="operation-menus-object__sub-menu-dropdown">
                <ul class="operation-menus-object__sub-menu-dropdown-content">
                  <li *ngFor="let subSubOperation of subOperation.sub_operations">
                    <a (click)="openOperation(subSubOperation, operation)"
                       class="obj-menus-commons-sub-menu-btn"
                       [ngClass]="{'disabled': subSubOperation.$$disabled}"
                       [title]="subSubOperation.$$toolTip">
                      <i class="{{subSubOperation.menu_icon}}"></i>
                      <span [translate]="subSubOperation.menu_title"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
