<div class="dialog admin-concept-join-concepts">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span translate="TRANS__ADMIN_CONCEPT__JOIN_CONCEPTS__TITLE"></span>
    </h1>

    <button  type="button"
             mat-icon-button
             class="close"
             [tabindex]="-1"
             aria-label="close dialog"
             mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <div mat-dialog-content class="modal-body modal-body-edit-fields">
    <div class="modal-main-content">
      <div class="admin-concept-join-concepts__join-concepts-content">
        <div *ngIf="concepts.length">
          <label id="select-join-concept-radio-group" translate="TRANS__ADMIN_CONCEPT__JOIN_CONCEPTS__SELECT_JOIN_CONCEPT"></label>
          <mat-radio-group
            class="admin-concept-join-concepts__radio_group"
            aria-labelledby="select-join-concept-radio-group"
            [(ngModel)]="joinToConcept">
            <mat-radio-button
              *ngFor="let concept of concepts"
              class="admin-concept-join-concepts__radio_button"
              [disabled]="data.conceptsContainer.conceptType.is_hierarchic && !concept.is_leaf"
              [value]="concept">
              <span>{{concept.artifact_name}}</span>
              (<span *ngIf="concept.authority_id">{{concept.authority_id_value}}.
          </span><span>{{concept.$$updatedAt}}: {{concept.updated_by_id_value}}). </span>
              <span translate="TRANS__ADMIN_CONCEPT__CONCEPT_USAGE"></span>:
              <span>{{concept.$$usage.length}}.&nbsp;</span>
              <span *ngIf="data.conceptsContainer.conceptType.is_hierarchic && !concept.is_leaf"
                    class="joinResultWarn" translate="TRANS__ADMIN_CONCEPT__CONCEPT_USAGE"></span>
              <span (click)="dropConcept(concept)" [title]="'TRANS__ADMIN_CONCEPT__REMOVE_JOIN_CONCEPT' | translate"
              >&nbsp;<i class="icon-trash"></i></span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="!conceptJoinResult.message" translate="TRANS__ADMIN_CONCEPT__JOIN_CONCEPTS__WARNING"></div>
        <div *ngIf="conceptJoinResult.message"
             [ngClass]="{'joinResultWarn': conceptJoinResult.status !== 'ok'}"
        >{{conceptJoinResult.message | translate}}</div>
      </div>
    </div>


  </div>
  <div mat-dialog-actions class="modal-footer">
    <button mat-flat-button
            color="accent"
            [disabled]="getInvalidJoinConcepts()"
            (click)="joinConcepts()">
      <span class="bold" translate="TRANS__ADMIN_CONCEPT__JOIN_CONCEPTS__JOIN"></span></button>
    <button mat-button
            color="accent"
            (click)="closeDialog()">
      <span class="bold" translate="TRANS__BUTTON__CLOSE"></span></button>
  </div>
</div>
