import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObjectViewModule} from '../object-view/object-view.module';
import {InformationComponent} from './information/information.component';
import {InformationViewAndEditComponent} from './information-view-and-edit/information-view-and-edit.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {SharedModule} from '../shared/shared.module';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {MediaCarouselModule} from '../media-carousel/media-carousel.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {OperationsModule} from '../operations/operations.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import { InformationHeadlineComponent } from './information-headline/information-headline.component';
import {ObjectContentTabModule} from '../object-content-tab/object-content-tab.module';



@NgModule({
  declarations: [
    InformationComponent,
    InformationViewAndEditComponent,
    InformationHeadlineComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    SharedModule,
    ObjectViewModule,
    ObjectSearchModule,
    ObjectEditModule,
    MediaCarouselModule,
    OperationsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    ObjectContentTabModule
  ],
  exports: [
    InformationComponent
  ]
})
export class InformationModule { }
