<div class="object-view-and-edit-procedure">
  <div class="object-view-and-edit-procedure__top-headline">
    <h1>
      <i class="object-view-and-edit-procedure__headline-icon icon-procedure2"></i>
      <span *ngIf="operationContainer.rootSectionsContainer.objectView"
            class="object-view-and-edit-procedure__headline">{{operationContainer.rootSectionsContainer.objectView.artifact_name}}</span>
    </h1>

    <div class="object-view-and-edit-procedure__button-container">
      <ng-container *ngFor="let operation of operationContainer?.operations">
        <button *ngIf="operation.hide_menu_bar"
                (click)="openOperation(operation)"
                class="object-view-and-edit-procedure__button"
                mat-stroked-button
                color="primary"
                type="button"
                [disabled]="isEditing">
          <i class="{{operation.menu_icon}}"></i>
          <span>&nbsp;</span><span class="bold uppercase" [translate]="operation.menu_title"></span>
        </button>
      </ng-container>

    </div>

<!--    <a (click)="openSettings()"><span><mat-icon>settings</mat-icon></span></a>-->
  </div>

  <div *ngIf="!isEditing && !showALLFields"
       class="object-view-and-edit-procedure__overview">
    <div *ngFor="let field of fields; index as $index"
         class="object-view-and-edit-procedure__field">

      <div class="object-view-and-edit-procedure__field-title bold">
        <span>{{field.title}}:</span></div>
      <div class="object-view-and-edit-procedure__field-content">
        <div *ngIf="field.items">
          <div *ngFor="let item of field.items"
               class="object-view-and-edit-procedure__field-items"
               [ngClass]="{'level-1': item.header_values && item.header_values.level === 1,
                      'level-2': item.header_values && item.header_values.level === 2,
                      'new-array-item': item.is_new_array_item}">
            <div *ngIf="item.header_values"
                 class="item-header"
                 [ngClass]="{'bold': item.header_values.level === 1}">
              <app-object-view-field-values
                [fieldValues]="item.header_values"></app-object-view-field-values>
            </div>

              <!--              <app-object-view-field-checkbox-->
              <!--                [sectionsContainer]="sectionsContainer"-->
              <!--                [checkboxField]="item.checkbox_field">-->

            <app-object-view-field-values
              class="object-view-and-edit-procedure__field-items-value"
              *ngIf="item.field_values"
              [stylingClass]="'display-title-and-content-inline'"
              [fieldValues]="item.field_values">
            </app-object-view-field-values>

              <!--              </app-object-view-field-checkbox>-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-object-view
    class="object-view-and-edit-procedure__view"
    *ngIf="!isEditing && showALLFields"
    [objectId]="operationContainer?.rootSectionsContainer.rootObject.artifact_id"
    [sectionsContainer]="operationContainer?.rootSectionsContainer"
    [templateGroupId]="operationContainer?.rootSectionsContainer.templateGroupId"
  ></app-object-view>

  <div *ngIf="isEditing"
       class="object-view-and-edit-procedure__edit">
    <app-object-edit
      *ngIf="isEditing && operationContainer?.rootSectionsContainer"
      [sectionsContainer]="operationContainer.rootSectionsContainer"
    ></app-object-edit>
  </div>

</div>
