<div class="section-view-edit">
  @if (sectionsContainer.isCopy) {
    <mat-checkbox
      class="section-view-edit__checkbox"
      [id]="sectionsContainer.rootObject.object_type"
      [checked]="allSections.keep"
      (change)="setAllSectionKeep()">
      <span translate="TRANS__COPY_FIELDS__SELECT_ALL_SECTIONS"></span></mat-checkbox>
  }

  @for (section of sections; track section) {
    <div class="row section-view-edit__section"
         [id]="'section-' + section.name"
         [ngClass]="{'no-padding': hideSectionTitle || section.hide_title}" >

      <div class="section-view-edit__section-content">

        @if (!hideSectionTitle && !section.hide_title) {
          <app-section-view-title-edit
            [section]="section"
            (updateKeepSection)="onUpdateKeepSection($event)"
            [sectionsContainer]="sectionsContainer"
          ></app-section-view-title-edit>
          <hr>
        }

        @if (sectionsContainer.isCopy) {
          @if (section.keep) {
            <div class="subSec">
              <app-edit-fields
                [sectionsContainer]="sectionsContainer"
                [object]="sectionsContainer.rootObject"
                [fields]="section.editFields"
                (closeEdit)="onCloseEdit()"
              ></app-edit-fields>
            </div>
          }
        } @else {
          <div class="subSec"
               [ngClass]="{'disable-button': (section.section_visible !== undefined && !section.section_visible) && sectionsContainer.isTemplate}">
            <app-edit-fields
              [sectionsContainer]="sectionsContainer"
              [object]="sectionsContainer.rootObject"
              [fields]="section.editFields"
              (closeEdit)="onCloseEdit()"
            ></app-edit-fields>
          </div>
        }
      </div>

    </div>
  }

</div>
