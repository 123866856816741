<div class="annotation-description__top bold" [ngClass]="{'isDialog dialog': isDialog}">
  <span translate="TRANS__ANNOTATE_MENU__DESCRIPTION"></span>
</div>
<div class="annotation-description__bottom">
  <div class="annotation-description__textarea-container"
       matTooltip="{{'TRANS__ANNOTATE_MENU__DISABLED_TOOLTIP' | translate}}"
       [matTooltipDisabled]="curAnn.state.edit"
       matTooltipPosition="left"
       [ngClass]="{'isDialog': isDialog}">
      <textarea class="annotation-description__textarea-content"
                rows="3"
                autofocus
                [disabled]="!curAnn.state.edit || !curAnn.selectedAnnotation"
                [placeholder]="'TRANS__ANNOTATE_MENU__DESCRIPTION' | translate"
                [(ngModel)]="curAnn.selectedAnnotation?.description.description"
                (ngModelChange)="textChange()">
      </textarea>
  </div>
</div>
