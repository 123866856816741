import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface TreeNode {
  id: string;
  label: string;
  is_leaf?: boolean;
  secondaryLabel?: string;
}

export interface TreeNodeChildren {
  [parentId: string]: Array<TreeNode>;
}

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {

  // Config options
  @Input() public readonly showReloadButton: boolean = true;
  @Input() public readonly alwaysExpanded: boolean = false;

  // GUI-controls
  @Input() public readonly loading: TreeNode | null;

  // Data
  @Input() public readonly node: TreeNode;
  @Input() public readonly children: TreeNodeChildren = {};
  @Input() public readonly selected: TreeNode | null = null;
  @Input() public readonly displayIds: Array<string> | null = null;

  // Outputs
  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output() public readonly onReload: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Output() public readonly onSelect: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Output() public readonly onOpen: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  @Output() public readonly onClose: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
  /* eslint-enable @angular-eslint/no-output-on-prefix */

  // Internal state
  open = false;

  constructor() { }

  ngOnInit(): void {
    if (this.selected === this.node) {
      this.toggleOpen();
    }
  }

  getChildren(): Array<TreeNode> {
    if (!this.node || !this.children || this.displayIds?.length === 0) {
      return [];
    }
    const children = (this.children[this.node.id] || []);
    return this.displayIds === null ? children : children.filter(child => this.displayIds.includes(child.id));
  }

  toggleOpen(): void {
    this.open = !this.open;
    this.open ? this.onOpen.emit(this.node) : this.onClose.emit(this.node);
  }

  selectNode(): void {
    if (!this.open) {
      this.toggleOpen();
    }
    this.onSelect.emit(this.node);
  }

  isActive(): boolean {
    return this.selected && this.selected.id === this.node?.id;
  }

  isLeafNode(): boolean {
    if (!this.node) { return false; }
    if (!this.children) { return true; }
    return this.children[this.node.id]?.length === 0;
  }

  isLoading(): boolean {
    return this.loading?.id === this.node.id;
  }
}
