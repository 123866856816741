import {UntypedFormGroup, NgForm} from '@angular/forms';
import {ObjectView} from './object-view';
import {MetaField} from './meta-field';
import {SuperObjectModel} from './super-object-model';
import {OperationDef} from './operation-def';

export class Section {
  edit: boolean;
  editFields: MetaField[];
  fields: MetaField[];
  keep: boolean;
  name: string;
  order: number;
  title: string;
  hide_title: boolean;
  section_visible: boolean;
}

export class SectionsContainer {
  copyOptions: object;
  debug: boolean;
  errorPointer = 0;
  formGroup: UntypedFormGroup;
  form: NgForm;
  isCopy = false;
  isDialog = false;
  isSecondDialog = false;
  isTemplate = false;
  hideSectionTitle = false;
  objectView: ObjectView;
  operationContextObjects: SuperObjectModel[];
  useContextCreatingIdentifier = false;
  parentObject: SuperObjectModel;
  primeFields: any[];
  rootObject: SuperObjectModel;
  sections: Section[];
  submitted = false;
  templateGroupId: string;
  viewMode: string;
  usage: string[];
  operations: OperationDef[];
}
