import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {FieldConditionService} from '../../../core/field-condition.service';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {IfType} from '../../../core/definitions/field-if';
import {MetaField} from '../../../core/definitions/meta-field';

@Component({
  selector: 'app-edit-field-inline-array-fields',
  templateUrl: './edit-field-inline-array-fields.component.html',
  styleUrls: ['./edit-field-inline-array-fields.component.scss']
})
export class EditFieldInlineArrayFieldsComponent implements OnChanges {

  AConst = AConst;

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() grandParentObject;
  @Input() fields;
  @Input() index: number;
  @Input() parentIndex: number;

  fieldParameters: FieldParameters;

  constructor(private readonly fieldCondition: FieldConditionService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.rootObject = this.sectionsContainer.rootObject || this.object;
    this.fieldParameters.grandParentObject = this.grandParentObject;
    this.fieldParameters.object = this.object;
    this.fieldParameters.edit = true;
    this.fieldParameters.index = this.index;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  canShow(field: MetaField) {
    const show = field.display && field.edit !== 'no' ? field.display : null;
    let res = show === 'yes';
    if (res) {
      this.fieldParameters.field = field;
      res = this.fieldCondition.runIf(IfType.SHOW, this.fieldParameters).result;
    }
    return res;
  }
}
