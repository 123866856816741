import {SearchObject} from './search-object';
import {FieldIf} from './field-if';

export class OptionInfo {
  $$hasNoConditions: boolean;
  $$filteredOptions: Option[];

  options: Option[];
  inline_value_field: string;
  inline_check_field: string;
  context_object_relation_filter: boolean;
}

export class Option extends SearchObject {
  $$isSelected: boolean;
  $$label: string;
  $$description: string;
  $$showDescription: boolean;
  $$name: string;

  icon: string;
  icon_frame: string;

  is_leaf: boolean;
  label: string;
  m_path: string;
  full_code_path: string;
  value: any;
  field_ifs: FieldIf[];
  relation_object_type: string;
}
