import {Component, Input, OnChanges} from '@angular/core';
import {CommonsService} from '../../core/commons.service';
import {DateToolsService} from '../../core/date-tools.service';
import {FieldIcon, FieldItem, FieldValue, OverviewField} from '../../core/definitions/object-view';
import {MetaField} from '../../core/definitions/meta-field';
import {FieldValueService} from '../../core/field-value.service';
import {SearchObject} from '../../core/definitions/search-object';
import {OverviewFieldsService} from '../overview-fields.service';
import {BaseModel} from '../../core/definitions/base-model';
import {SettingsService} from '../../core/settings.service';

@Component({
  selector: 'app-overview-field-value',
  templateUrl: './overview-field-value.component.html',
  styleUrls: ['./overview-field-value.component.scss']
})
export class OverviewFieldValueComponent implements OnChanges {

  @Input() overviewField: OverviewField;
  @Input() metaField: MetaField;
  @Input() object: SearchObject;
  @Input() viewName: string;
  @Input() disableLinks: boolean;

  isArrayValue: boolean;
  showMore: boolean;
  expired: boolean;
  fieldValues: Array<FieldValue> = [];
  fieldIcon: FieldIcon = null;
  linkItems: BaseModel[] = [];
  linkDisabled: boolean;

  constructor(private commons: CommonsService,
              private dateTools: DateToolsService,
              private fieldValueService: FieldValueService,
              private overviewFieldsService: OverviewFieldsService,
              private settingsService: SettingsService) {
  }

  ngOnChanges() {
    this.linkDisabled = this.disableLinks;
    this.overviewField.items.forEach((fieldItem: FieldItem) => {
      this.fieldIcon = fieldItem.field_values.field_icon;
      const singleFieldValue = new FieldValue();
      singleFieldValue.value = '';
      fieldItem.field_values.values.forEach((fieldValue: FieldValue) => {
        singleFieldValue.value += fieldValue.value.toString();
        if (fieldValue.ref_id) {
          singleFieldValue.ref_id = fieldValue.ref_id;
          singleFieldValue.ref_object_type = fieldValue.ref_object_type;
          singleFieldValue.ref_meta_type = fieldValue.ref_meta_type;
          if (this.settingsService.getClientConfig().DEFAULT_COLLECTION_ID === fieldValue.ref_id) {
            this.linkDisabled = true;
          }
        }
      });
      singleFieldValue.value = singleFieldValue.value || '-';
      this.fieldValues.push(singleFieldValue);
    });
    this.isArrayValue = this.fieldValues.length > 1;
    this.checkExpiredDate();
    if (this.overviewField.is_link) {
      this.overviewFieldsService.getLinkItems(this.fieldValues).then(linkItems => {
        this.linkItems = linkItems;
      });
    }
  }

  private checkExpiredDate() {
    if (this.overviewField.field_class === 'precision-date-field') {
      const statusType = this.commons.getStatusFromArtifact(this.object);
      if (statusType) {
        const precisionDateMetaField: MetaField = this.metaField;
        const fieldValue = this.fieldValueService.getFieldValue(this.object, this.metaField.field_name);
        if (fieldValue) {
          this.expired = this.dateTools.checkExpiredIsoDate(precisionDateMetaField, fieldValue, statusType);
        }
      }
    }
  }
}
