<mat-tab-group [selectedIndex]="tabIndex.value"
               class="object-information-tabs"
               (selectedIndexChange)="tabIndex.setValue($event)"
               [ngClass]="{'noSelectedTab': tabIndex.value === 0}">
  <mat-tab>
    <ng-template mat-tab-label class="bold">
      <span (click)="toggleTab(0)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__FOLDERS' | translate}}
       <mat-icon>expand_more</mat-icon></span>
    </ng-template>
    <app-object-folders [object]="object"></app-object-folders>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="toggleTab(1)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__CHANGES' | translate}}
        <mat-icon>expand_more</mat-icon></span>
    </ng-template>
    <app-object-update-log [contextId]="object.artifact_id" [getLogItems]="tabIndex.value === 1"></app-object-update-log>
  </mat-tab>
  <ng-container *ngIf="logMetaTypes.includes(object.meta_type)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="toggleTab(2)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__IDENTIFICATION_NUMBER_LOG' | translate}}
        <mat-icon>expand_more</mat-icon></span>
    </ng-template>
    <app-identification-number-log [object]="object"></app-identification-number-log>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="toggleTab(3)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__COLLECTION_LOG' | translate}}
        <mat-icon>expand_more</mat-icon></span>
    </ng-template>
    <app-collection-log [object]="object"></app-collection-log>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="toggleTab(4)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__COLLECTION_STATUS_LOG' | translate}}
        <mat-icon>expand_more</mat-icon></span>
    </ng-template>
    <app-collection-status-log [object]="object"></app-collection-status-log>
  </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
      <span (click)="toggleTab(5)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__QUANTITY_LOG' | translate}}
        <mat-icon>expand_more</mat-icon></span>
      </ng-template>
      <app-quantity-log [object]="object"></app-quantity-log>
    </mat-tab>
  </ng-container>
</mat-tab-group>
