<div class="search-filter-hierarchy"
     [ngClass]="{'sideMenu': sideMenu, 'dropdownMenu': !sideMenu}">
  <ul class="search-filter-hierarchy__items">
    <li *ngFor="let child of getChildNodes()"
        class="search-filter-hierarchy__item">
      <div class="search-filter-hierarchy__item-content filter-checkbox">

        <ng-container *ngIf="child.childrenArray.length">
          <a class="node-width-children"
             (click)="toggleOpen(child)"
             [ngClass]="{'open': child.open}">
            <mat-icon class="hierarchic-arrow-white"
                      [ngClass]="{'selected': child.facet.selected}">arrow_right</mat-icon></a>
        </ng-container>

        <span (click)="select(child)">
          <span class="search-filter-hierarchy__item-text"
                [ngClass]="{'bold selected': child.facet.selected}">
            <span class="search-filter-hierarchy__item-text-content" [innerHTML]="child.facet.shortName"></span>
            <span class="search-filter-hierarchy__countText">({{child.facet.count}})</span>
          </span>
        </span>
      </div>

      <app-search-filter-hierarchy
        *ngIf="child.open"
        [node]="child"
        [searchContainer]="searchContainer"
      ></app-search-filter-hierarchy>
    </li>
  </ul>
</div>
