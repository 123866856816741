import {Component, Input} from '@angular/core';
import {RefParams, RefService} from '../../core/ref.service';
import {BaseModel} from '../../core/definitions/base-model';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OrderDownloadUrlResult} from '../../core/definitions/order-download-url-result';

@Component({
  selector: 'app-download-url',
  templateUrl: './download-url.component.html',
  styleUrls: ['./download-url.component.scss']
})
export class DownloadUrlComponent {
  filename: String = null;
  url: String = null;
  @Input() object: BaseModel;

  constructor(private readonly ref: RefService) {
  }

  async handleClick(e) {
    e.preventDefault();
    const ref = await this.ref.makeDmsDownloadUrl(new RefParams(<SuperObjectModel>this.object));
    if (ref) {
      await this.downloadMedia(ref);
    }
  }

  private async downloadMedia(orderDownloadUrlResult: OrderDownloadUrlResult) {
    const response = await fetch(orderDownloadUrlResult.url, {
      method: 'GET',
      mode: 'no-cors'
      } as RequestInit);
    const blob = await response.blob();
    const a = document.createElement('a');
    a.style['display'] = 'none';
    document.body.appendChild(a);
    const dUrl = window.URL.createObjectURL(blob);
    a.href = orderDownloadUrlResult.url;
    a.download = orderDownloadUrlResult.file_name;
    a.click();

    // cleanup
    window.URL.revokeObjectURL(dUrl);
    document.body.removeChild(a);
  }
}
