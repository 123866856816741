<div class="search-focus-menu-panel dropdownIgnoreClicks"
     *ngIf="searchContainer.focus.curFocus"
     [ngClass]="{checked: searchContainer.focus.curFocus}">
  <div class="search-focus-menu-panel__container">
    <a class="search-focus-menu-panel__close-focus-button"
       (click)="closeMenu()">
      <mat-icon>navigate_before</mat-icon>
      <span translate="TRANS__FOCUS__GO_BACK"></span></a>
    <div class="search-focus-menu-panel__top-section">
      <div class="search-focus-menu-panel__title">
        <span class="search-focus-menu-panel__title-text">
          <i class="icon-overview text-blue"></i>
          <span *ngIf="!searchContainer.focus.curFocusId && !searchContainer.focus.curFocus.focus.name"
                class="uppercase"
                translate="TRANS__FOCUS__INFO_SET_FOCUS_AS_NEW_SEARCH"></span>
          <span *ngIf="searchContainer.focus.curFocus.focus.name"
                class="uppercase" translate="TRANS__FOCUS__INFO_FOCUS_UNCHANGED"
                [translateParams]="{focusName: searchContainer.focus.curFocus.focus.name}"></span>
        </span>
        <span *ngIf="searchContainer.focus.curFocusId"
              class="search-focus-menu-panel__title-edit">
          <i class="icon-edit-alternative"
             (click)="editFocus(searchContainer.focus.curFocus)"></i></span>
      </div>

      <div class="search-focus-menu-panel__info">
        <span *ngIf="!searchContainer.focus.curFocusId"
              translate="TRANS__FOCUS__INFO_SET_FOCUS"></span>
        <span *ngIf="searchContainer.focus.curFocus.focus.stored && (hasChanges() && !dialogOpen)">
          <span translate="TRANS__FOCUS__INFO_FOCUS_CHANGED_TO" [translateParams]="{focusName: searchContainer.focus.curFocus.focus.name}"></span>
        </span>
        <span *ngIf="storeMessage">
          <span [translate]="storeMessage"
                [translateParams]="{focusName: searchContainer.focus.curFocus.focus.name}"></span>
        </span>
      </div>

      @if (searchContainer.focus.curFocusId) {
        <div class="search-focus-menu-panel__top-section-button-group">
          <a class="search-focus-menu-panel__top-section-button bg-primary"
             (click)="deselectFocus()"
          >
            <span>{{'TRANS__FOCUS__BUTTON_LEAVE_FOCUS' | translate}}</span>
          </a>

          <div class="search-focus-menu-panel__top-section-button-group-separated">
            <a class="search-focus-menu-panel__top-section-button bg-primary"
               [ngClass]="{'disable-button bg-gray': !hasChanges() || dialogOpen ? 'disabled': undefined}"
               [attr.disabled]="!hasChanges() || dialogOpen ? 'disabled': undefined"
               (click)="storeFocusChanges()">
              <span translate="TRANS__FOCUS__BUTTON_STORE_CHANGES"></span>
            </a>

            <a class="search-focus-menu-panel__top-section-button bg-gray"
               (click)="setFocusAsNewSearch()">
              <span translate="TRANS__FOCUS__BUTTON_STORE_AS_NEW_SEARCH"></span>
            </a>
          </div>
        </div>
      }

      @if (!searchContainer.focus.curFocusId) {
        <div class="search-focus-menu-panel__top-section-button-no-focus">
          <a class="bg-primary"
             [ngClass]="{'no-stored-focuses': displayFocus.length === 0}"
             (click)="setFocusAsNewSearch()">
            <span translate="TRANS__FOCUS__BUTTON_STORE_AS_NEW_SEARCH"></span></a>
        </div>
      }

      <!-- TODO: remove me if allest ist gut-->
      <!--<div class="search-focus-menu-panel__top-section-buttons">
        <div *ngIf="!searchContainer.focus.curFocusId"
             class="search-focus-menu-panel__top-section-button">
          <a class="bg-primary"
             [ngClass]="{'no-stored-focuses': displayFocus.length === 0}"
             (click)="setFocusAsNewSearch()">
            <span translate="TRANS__FOCUS__BUTTON_STORE_AS_NEW_SEARCH"></span></a>
        </div>
        @if (searchContainer.focus.curFocusId) {
          <div class="search-focus-menu-panel__top-section-button">
            <a class="bg-primary">
              <span>{{'TRANS__FOCUS__BUTTON_LEAVE_FOCUS' | translate}}</span>
            </a>
          </div>
        }
        <div *ngIf="searchContainer.focus.curFocusId"
             class="search-focus-menu-panel__top-section-button">
            <a class="search-focus-menu-panel__store-button bg-primary"
               [ngClass]="{'disable-button bg-gray': !hasChanges() || dialogOpen ? 'disabled': undefined}"
               [attr.disabled]="!hasChanges() || dialogOpen ? 'disabled': undefined"
               (click)="storeFocusChanges()">
              <span translate="TRANS__FOCUS__BUTTON_STORE_CHANGES"></span></a>
            <a class="bg-gray"
               (click)="setFocusAsNewSearch()">
              <span translate="TRANS__FOCUS__BUTTON_STORE_AS_NEW_SEARCH"></span></a>
        </div>
      </div>-->
    </div>

    <div class="search-focus-menu-panel__get-focus">
      <a class="search-focus-menu-panel__get-focus-button"
         (click)="toggleSelectFocusPanel()">
        <span translate="TRANS__FOCUS__SEARCH_SELECT_FOCUSES"></span>
        <mat-icon>{{showSelectFocusPanel ? 'expand_less' : 'expand_more'}}</mat-icon>
      </a>
      <div *ngIf="showSelectFocusPanel"
           class="search-focus-menu-panel__search">

        <label for="selectFocus">
          <span translate="TRANS__FOCUS__SEARCH_AFTER_FOCUSES"></span></label>

        <div class="clearInputContainer">
          <input type="text"
                 id="selectFocus"
                 placeholder="&#xe62c;&nbsp;{{'TRANS__FOCUS__SEARCH_AFTER_FOCUSES__PLACEHOLDER' | translate}}"
                 [(ngModel)]="searchQuery"
                 (ngModelChange)="inputChange()"
                 autocomplete="off">
          <a *ngIf="searchQuery"
             class="clearInputField"
             (click)="clearSearchInput()">
            <mat-icon>close</mat-icon>
          </a>
        </div>
        <span translate="TRANS__FOCUS__SELECT_FOCUSES"></span>

        <div class="search-focus-menu-panel__search-options-container">
          <ul class="search-focus-menu-panel__search-options">
            <li *ngFor="let fo of displayFocus; index as $index"
                [ngClass]="{'active': fo.focus.settingMenu}"
                class="search-focus-menu-panel__search-option">
                <span class="search-focus-menu-panel__search-option-content"
                      [ngClass]="{'bold': fo.focus.settingMenu}">
                  <mat-radio-button (click)="selectFocus(fo)"
                                    [checked]="fo.focus.marked"
                                    class="gray-radio-button search-focus-menu-panel__radio-button">
                    <span [innerHTML]="getFocusNameWeighedWordBreak(fo)" class="search-focus-menu-panel__search-option-content-text"></span>
                    <span *ngIf="fo.focus.isDefault" translate="TRANS__FOCUS__SELECT_FOCUSES__START_FOCUS"></span>
                  </mat-radio-button>
                  <span class="search-focus-menu-panel__search-option-setting-button"
                        (click)="toggleSettingMenu(fo)">
                    <mat-icon>settings</mat-icon></span></span>
              <span *ngIf="fo.focus.settingMenu"
                    class="search-focus-menu-panel__search-option-setting-menu"
                    [ngClass]="{'bold': fo.focus.settingMenu}">
                    <span (click)="editFocus(fo)"
                          translate="TRANS__FOCUS__SELECT_FOCUSES__EDIT"></span>
                    <span class="search-focus-menu-panel__search-option-setting-menu-line"></span>
                    <span (click)="delete(fo)"
                          translate="TRANS__FOCUS__SELECT_FOCUSES__DELETE"></span>
                </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="transparent-overlay" (click)="closeMenu()"
     *ngIf="searchContainer.focus.curFocus"></div>
