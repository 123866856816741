<mat-form-field [formGroup]="formGroup">
  <mat-label>Importfelt: {{mapField.target_field.field_title}}</mat-label>
  <input type="text"
         placeholder="Sett verdi"
         aria-label="verdi"
         matInput
         [formControl]="formControl"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectColumn($event)" [displayWith]="displayFn">
    <mat-option
      *ngFor="let column of mapField.filtered_columns"
      [value]="column" >{{column}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
