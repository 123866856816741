export enum ObjectTypes {
  ANNOTATION = 'Annotation',
  ATTACHMENT = 'Attachment',
  AUDIO = 'Audio',
  AUTHORITY = 'authority',
  COLLECTION = 'Collection',
  FOLDER = 'folder',
  GENERIC_EVENT = 'GenericEvent',
  IMAGE = 'Image',
  KULTURNAV_AUTHORITY = 'KulturNavAuthority',
  KULTURNAV_FOLDER = 'kulturnav_folder',
  MEDIA_FOLDER = 'MediaFolder',
  LOCATION = 'Location',
  PLACE = 'place',
  SETTINGS = 'Settings',
  USER = 'user',
  VIDEO = 'Video',
}
