<mat-menu #menu [overlapTrigger]="false">
  @for (item of items; track item) {
    @if (item.items && item.items.length > 0) {
      <button mat-menu-item
              type="button"
              [matMenuTriggerFor]="subMenu.menu">
        @if (item.color) {
          <mat-icon [color]="getColor(item)">{{ item.icon }}</mat-icon>
        }
        {{ item.label | translate }}
      </button>
      <app-primus-menu-button-item #subMenu
                                   [items]="item.items"
                                   [selectionState]="selectionState"
                                   [color]="color"
                                   (menuItemClicked)="handleMenuItemClicked($event)">
      </app-primus-menu-button-item>
    }

    @if (!item.items || item.items.length === 0) {
      <button mat-menu-item
              type="button"
              [ngClass]="{'active': isActive(item)}"
              (click)="handleMenuItemClicked(item, $event)">
        @if (item.mode === 'radio') {
          <mat-radio-group [selected]="getValue(item)">
            <mat-radio-button [disabled]="true" [checked]="isActive(item)"></mat-radio-button>
          </mat-radio-group>
        }
        @if (item.mode === 'checkbox') {
          <mat-checkbox [disabled]="true" [checked]="isActive(item)" [value]="item.id"></mat-checkbox>
        }
        @if (item.icon) {
          <mat-icon [color]="getColor(item)">{{ item.icon }}</mat-icon>
        }
        <span [innerHTML]="item.label | translate"></span>
      </button>
    }
  }
</mat-menu>
