import {Component, effect, EventEmitter, Input, Output, WritableSignal} from '@angular/core';

import {AdvFieldQuery, AdvFieldQueryGroup} from "../../../core/definitions/advanced-search-params";
import {SearchObject} from "../../../core/definitions/search-object";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {AdvancedSearchField} from "../../../core/definitions/advanced-search-field";
import {TranslateService} from "@ngx-translate/core";
import {AdvancedSearchToolsService} from "../../../core/advanced-search-tools.service";
import {SearchContainer} from "../../../core/definitions/search-container";

@Component({
  selector: 'app-advanced-search-query-builder-options-view',
  templateUrl: './advanced-search-query-builder-options-view.component.html',
  styleUrl: './advanced-search-query-builder-options-view.component.scss'
})
export class AdvancedSearchQueryBuilderOptionsViewComponent {
  @Input() filterMode: 'self' | 'parent';
  @Input() filterQuery: WritableSignal<string>;
  @Input() multiselect: boolean = false;
  @Input() options: WritableSignal<SearchObject[] | AdvancedSearchField[]> = null;
  @Input() parentGroup: AdvFieldQueryGroup;
  @Input() rootGroup: AdvFieldQueryGroup;
  @Input() searchContainer: SearchContainer;
  @Input() selectedField: AdvFieldQuery | undefined;
  @Input() selectedOption: AdvFieldQuery;
  @Input() selectedValue = null;
  @Input() showLimitDescription = false;

  @Output() arrayOptionDeselected: EventEmitter<SearchObject | AdvancedSearchField> = new EventEmitter();
  @Output() arrayOptionSelected: EventEmitter<SearchObject | AdvancedSearchField> = new EventEmitter();
  @Output() optionSelected: EventEmitter<SearchObject | AdvancedSearchField> = new EventEmitter();

  optionsFiltered: SearchObject[] | AdvancedSearchField[] = [];

  treeController = new FlatTreeControl(
    this.getNodeLevel,
    this.isExpandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    this.getNodeLevel,
    this.isExpandable,
    this.getNodeChildren
  );

  treeDataSource = new MatTreeFlatDataSource(
    this.treeController,
    this.treeFlattener
  );

  constructor(
    private translate: TranslateService,
    private advancedSearchTools: AdvancedSearchToolsService
  ) {
    effect(() => {
      if (this.options !== null) {
        this.optionsFiltered = this.options();
      }

      if (this.filterQuery() !== undefined && this.filterMode === 'self') {
        for (const node of this.optionsFiltered) {
          this.setVisibilityOnQuery(node);
        }
      }

      if (this.selectedValue === null || this.selectedValue === '' || this.filterQuery() !== '') {
        this.treeDataSource.data = this.optionsFiltered;

        if (this.filterQuery() !== '') {
          this.treeController.expandAll();
        }
      }
    });
  }

  getHighlightedName(node) {
    let name = node.hasOwnProperty('field_title') ? node.field_title.split(' / ')[node.field_title.split(' / ').length -1] : node.artifact_name ||node['name.name'];

    if (this.filterQuery() !== '') {
      name = name.replaceAll(new RegExp(this.filterQuery().toLowerCase(), 'ig'), (matchingText) => '<span class="highlighted-advanced-search-tree-view">' + matchingText + '</span>');
    }

    if (this.requireNewGroup(node) && !this.nodeHasChildren(0, node)) {
      name = `${name} <span class="helper-text">${this.translate.instant('TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__REQUIRES_NEW_GROUP')}</span>`
    }

    if (name.includes('<del>')) {
      name = `${name} <span class="helper-text">${this.translate.instant('TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__IS_DELETED')}</span>`
    }

    return name;
  }

  nodeExpanded($event: MouseEvent, node = null): void {
    if (node !== null && !node.hasOwnProperty('field_title')) {
      // If the field is NOT a field type, but a value type it should allow user to select a parent node
      this.nodeSelected(node);
    }
    $event.stopPropagation();
    $event.preventDefault();
  }

  nodeHasChildren(_: number, node: SearchObject | AdvancedSearchField): boolean {
    return !!node.children && node.children.length > 0;
  }

  nodeArraySelected(node: SearchObject | AdvancedSearchField): void {
    if (!this.isChecked(node)) {
      this.arrayOptionSelected.emit(node);
    }
    else {
      this.arrayOptionDeselected.emit(node);
    }
  }

  nodeSelected(node: SearchObject | AdvancedSearchField): void {
    if (this.multiselect) {
      this.nodeArraySelected(node);
    }
    else {
      this.optionSelected.emit(node);
    }
  }

  isChecked(node) {
    if (Array.isArray(this.selectedValue)) {
      return this.selectedValue.findIndex(n => n === node.artifact_id) >= 0;
    }

    return false;
  }

  private getNodeChildren(node: SearchObject): SearchObject[] | AdvancedSearchField[] {
    return node.children;
  }

  private getNodeLevel(node: SearchObject | AdvancedSearchField): number {
    return node.level || 0;
  }

  private isExpandable(node: SearchObject | AdvancedSearchField): boolean {
    return !!node.children && node.children.length > 0;
  }

  private requireNewGroup(node) {
    const relationInfo = this.advancedSearchTools.getRelationInfoFromGroup(
      this.parentGroup, this.rootGroup);
    const relationId = relationInfo?.superobjectTypeId;
    return (node.context_field || node.child_document_type) &&
      (relationInfo?.childDocumentType === null || relationInfo?.childDocumentType === undefined) &&
      (relationId === undefined || relationId === null || relationId === '') &&
      (this.parentGroup.field_queries.length > 1 && this.advancedSearchTools.hasSelectedFields(this.parentGroup));
  }

  private setVisibilityOnQuery(node) {
    let nodeHasVisibleChildren = false;

    if (node.children) {
      for (const child of node.children) {
        this.setVisibilityOnQuery(child);

        if (child.visible) {
          nodeHasVisibleChildren = true;
        }
      }
    }

    node.visible = (nodeHasVisibleChildren || node.field_title?.toLowerCase().indexOf(this.filterQuery().toLowerCase()) !== -1);
  }

  private transformer(node, level: number)  {
    let name = node.hasOwnProperty('name') ? node.name : node.field_name;

    const supplemental = {
      expandable: !!node.children && node.children.length > 0,
      name: name,
      level
    }

    return { ...node, ...supplemental};
  }
}
