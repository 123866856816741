import { kitFetch } from '@ekultur/fetch';
import videojs from 'video.js';

import './css/DMSVideo.css';

(() => {
    const containers = document.getElementsByClassName('dms-video');

    if (!containers.length) {
        return;
    }

    for (let container of containers) {
        // Fetch all the elements that are not yet defined
        const placeholder = container.querySelector('.video-placeholder');
        const undefinedElements = container.querySelectorAll(':not(:defined)');

        async function removePlaceholder() {
            const promises = [...undefinedElements].map(
                (viewer) => customElements.whenDefined(viewer.localName)
            );
            
            // Wait for all the elements to be upgraded
            await Promise.all(promises);
            
            // then remove the placeholder
            container.removeChild(placeholder);
        }
        
        removePlaceholder();
    };
})();

customElements.define('kit-dms-video', class DMSVideo extends HTMLElement {
    static observedAttributes = ['dms_api', 'dms_ids'];

    constructor() {
        super();
        this.dmsApi = null;
        this.dmsIds = null;
    }

    attributeChangedCallback(name, oldValue, newValue) {
        location.hostname === 'localhost' && console.log(`DMSVideo attribute ${name} has changed from «${oldValue}» to «${newValue}»`);

        if (name === 'dms_api') {
            this.dmsApi = newValue;
        } else if (name === 'dms_ids') {
            this.dmsIds = newValue;
        }

        this.ready();
    }

    get stream() {
        return this.getAttribute('stream') !== 'false';
    }

    get authorization() {
        return this.getAttribute('authorization') !== 'false';
    }

    renderViewer = data => {
        if (data.urls) {
            const video = document.createElement('video'),
                options = {
                    controls: true,
                    responsive: true,
                    fluid: true,
                    sources: []
                };

            if (this.stream) {
                options.sources.push({
                    src: data.urls.hls,
                    type: 'application/x-mpegURL'
                });
            }
        
            options.sources.push({
                src: data.urls.mp4,
                type: 'video/mp4'
            });

            video.id = `vid-${data.multimediaId}`;
            video.controls = true;
            video.setAttribute('class', 'video-js vjs-big-play-centered');
            video.setAttribute('width', '100%');
            video.setAttribute('height', '100%');
            video.setAttribute('data-setup', '{}');
            this.appendChild(video);

            videojs(`vid-${data.multimediaId}`, options, () => {
                // videojs.log('player is ready');
                // onReady && onReady(player);
            });
        }
    };

    static async dmsVideoPlayback(api, ids, useAuthorization) {
        const fetcher = useAuthorization ? kitFetch : fetch;

        return Promise.allSettled(ids.map(id => fetcher(`${api}/playback/${id.trim()}`)
            .then(res => useAuthorization ? res : res.json())))
            .then(responses => {
                return responses.map(x => {
                    if (x.status === 'fulfilled' && x.value) {
                        return x.value;
                    }

                    return false;
                }).filter(x => !!x);
            })
            .catch(error => console.log(error));
    }

    static async loadVideos(api, ids, renderFn, useAuthorization) {
        const videos = await this.dmsVideoPlayback(api, ids, useAuthorization);
        
        if (Array.isArray(videos) && videos.length) {
            videos.forEach(vid => renderFn(vid));
        }
    }

    connectedCallback() {
        location.hostname === 'localhost' && console.log('KIT-DMS-Video @connectedCallback');
    }

    /**
     * Ensure DOM elements are ready before proceeding
     */
    ready = () => {
        if (this.dmsIds && this.dmsApi) {
            DMSVideo.loadVideos(this.dmsApi, this.dmsIds.split(','), this.renderViewer, this.authorization);
        }
    };
});
