import {animate, animation, style} from '@angular/animations';

export const slideDownMenu = animation([
  style({height: 0}),
  animate('{{time}}', style({height: '*'}))
]);

export const slideUpMenu = animation([
  style({height: '*'}),
  animate('{{time}}', style({height: 0}))
]);

export const fadeIn = animation([
  animate('{{time}}', style({opacity: 1}))
]);

export const fadeOut = animation([
  animate('{{time}}', style({opacity: 0}))
]);

export const slideInFromLeft = animation([
  style({width: 0, overflow: 'hidden'}),
  animate('{{time}}', style({width: '*'}))
]);

export const slideInToLeft = animation([
  style({width: '*', overflow: 'hidden'}),
  animate('{{time}}', style({width: 0}))
]);
