import {Component, Input, OnInit} from '@angular/core';
import {AnnotationHandler} from '../../annotation-handler';

@Component({
  selector: 'app-annotation-dashboard-buttons',
  templateUrl: './annotation-dashboard-buttons.component.html',
  styleUrls: ['./annotation-dashboard-buttons.component.scss']
})
export class AnnotationDashboardButtonsComponent implements OnInit {

  @Input() curAnn: AnnotationHandler;

  constructor() {
  }

  ngOnInit() {
  }

}
