import {Component, Inject, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmDialogData {
  modalTitle: string;
  modalContent: string | string[];
  modalButton?: string;
  modalCancel?: string;
  modalConfirm?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  AConst = AConst;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
  }

  ngOnInit() {
  }

}
