import {MetaField} from './meta-field';
import {SearchFilters} from './search-filters';

export class Facet {
  checked: boolean;
  editable: boolean;
  end: string;
  f_name: string;
  field_type: string;
  group_title: string;
  items: Array<FacetItem>;
  oldRangeFilter: string;
  start: string;
  title: string;
  total_count: number;

  $$origEnd: string
  $$origStart: string;
}

export class FacetItem {
  name = '???';
  count = 0;
  selected: boolean;
  id: string;

  shortName = '???';
}

export class FacetCount {
  totalCount: number;
  trueCount: number;
  itemCounts: {[name: string]: number};
}

export class FilterGroupHierarchyNode {
  facet: FacetItem = new FacetItem();
  children: { [name: string]: FilterGroupHierarchyNode } = {};
  childrenArray: FilterGroupHierarchyNode[];
  open = false;
}

export class HierarchicFilterGroup extends FilterGroupHierarchyNode {
  title: string;
  single_filter_field: string;
  list_filter_field: string;

  lastSelectedNode: FilterGroupHierarchyNode;
}

export class FilterFilter {
  query = '';
  hasMore = false;
  showMoreCount = 10;
  showMoreAdd = 20;
  filterCount = 0;
}

export class CheckFilterGroup {
  // From backend
  title: string;
  filters: CheckFilter[];

  // Not from backend
  enabled = true;
  active = false;
  filterNames: string[];
  checkFilters: CheckFilter[];
  open: boolean;
  totalCount: number;
  filterFilter = new FilterFilter();
  visibleFilters: CheckFilter[] = [];
}

export class RangeGroup {
  title: string;
  facet_ranges: Array<Facet>;
  enabled: boolean;
}

export class CheckFilter {
  constructor(name: string, value: any) {
    this.name = name;
    this.value = value;
  }

  checked: boolean;
  checked_value: any;
  count: number;
  items: Array<FacetItem>;
  join_prefix: string;
  name: string;
  noTransTitle: string = null;
  preChecked: boolean;
  title: string = null;
  translate: boolean;
  value: any;
}

export class QueryMenu {
  description: string;
  selected: boolean;
  query_type: string;
  query_field: string;
  menus: QueryMenu[] = [];
  icon_class: string;
  icon: string;
}

export class SearchViewMenu {
  title: string;
  path: string;
  order: number;
  facet: string;
  facet_values: string[];
  facet_as_filter: boolean;
  menus: Array<SearchViewMenu>;
  // Client specific fields, does not exist server side
  openItem: boolean;
  checked: boolean;
  count: number;
}

export class SortOrderMenu {
  description: string;
  order: string;
}

export class SearchResultView {
  name: string;
  icon: string;
  description: string;
}

export class SearchResultViews {
  views: Array<SearchResultView>;
  default: string;
  force_set_default: boolean;
}

export class SearchViewPath {
  path_name: string;
  search_view: SearchView;
  reset_query: boolean;
}

export class RootSearchView {
  search_result_views: SearchResultViews;
  paths: Array<SearchViewPath>;
  view_name: string;
}

export class SearchView {
  // Client side only
  $$origFilters: SearchFilters;
  $$origOverviewFields: MetaField[];

  title: string;
  filters: SearchFilters;
  search_view_type: string;
  facets: string[];
  facet_range_groups: RangeGroup[];
  fq: string;
  menus: SearchViewMenu[];
  hierarchic_filter_group: HierarchicFilterGroup;
  check_filter_groups: CheckFilterGroup[];
  sort_order_menus: SortOrderMenu[];
  query_menus: QueryMenu;
  overview_fields: MetaField[];
  always_use_overview_fields_from_search_view: boolean;
  search_result_views: SearchResultViews;
  override_target;
  hide_select_all: boolean;
  write_collections_only: boolean;
  superobject_types: string[];
}
