import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnChanges {

  @Input() conceptsContainer: ConceptsContainer;
  @Input() menuTitle: string;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {}

  onSearchParamChange() {
    this.conceptsContainer.reload = Math.random();
  }

  get selected() {
    let res = '';
    if (this.conceptsContainer.selected.length) {
      res = this.conceptsContainer.selected.length + ' / ';
    }
    return res;
  }

}
