import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {MetaField} from '../../core/definitions/meta-field';
import {CmsApiService} from '../../core/cms-api.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {FieldType} from 'src/app/core/definitions/field-type.enum';
import {FieldInputType} from '../../core/definitions/field-input-type.enum';
import {BaseModel} from '../../core/definitions/base-model';
import {ModelsService} from '../../core/models.service';
import {SearchReferenceService} from "../../core/search-reference.service";

@Component({
  selector: 'app-edit-field-meta-operation-field',
  templateUrl: './edit-field-meta-operation-field.component.html',
  styleUrls: ['./edit-field-meta-operation-field.component.scss']
})
export class EditFieldMetaOperationFieldComponent implements OnChanges, OnInit {
  @Input() displayRequiredIndication: boolean;
  @Input() sectionsContainer: SectionsContainer;
  @Input() field: MetaField;
  @Input() setAddChoice;

  operationField: MetaField;
  operationObject: BaseModel;
  FieldInputType = FieldInputType;
  textFieldTypes = [FieldType.TEXT.toString(), FieldType.STRING.toString()];

  constructor(private cms: CmsApiService,
              private objectEditService: ObjectEditService,
              private modelsService: ModelsService,
              private searchReferenceService: SearchReferenceService) {
  }

  ngOnChanges(): void {
    if (this.setAddChoice) {
      for (const subField of this.operationField.sub_fields) {
        subField.$$hide = ['add_before_existing_text', 'add_after_existing_text'].includes(
          this.setAddChoice) && this.operationField.inline.prop !== subField.name;
      }
    }
  }

  ngOnInit() {
    this.setMetaOperationField().then();
  }

  private async setMetaOperationField() {
    const reference = this.searchReferenceService.getSearchReferenceFromField(this.field);
    const selectedFieldId = this.sectionsContainer.rootObject[reference.operation_field];
    const fieldOperationType = reference.field_operation_type;
    if (!selectedFieldId) {
      console.error('Unable to get selected field id!');
      return;
    }
    const selectedField = await this.cms.getMetaFieldById(selectedFieldId);
    if (!selectedField) {
      console.error('Unable to get selected field from id ' + selectedFieldId);
      return;
    }
    const inline = selectedField.inline;
    let modelName;
    let fieldRequired = false;
    if (selectedField.field_type !== FieldType.ARRAY) {
      fieldRequired = selectedField.is_required === 'required';
    }
    this.sectionsContainer.rootObject['operation_field_required'] = fieldRequired;
    if (inline) {
      modelName = inline.model;
      this.sectionsContainer.rootObject['operation_inline_prop'] = inline.prop;
      this.sectionsContainer.rootObject['operation_inline_unique_props'] = inline.unique_props;
    } else {
      modelName = this.sectionsContainer.rootObject.object_type;
    }
    this.sectionsContainer.rootObject['operation_value_is_array'] = selectedField.field_type === FieldType.ARRAY;
    this.sectionsContainer.rootObject['operation_value_is_text'] = false;
    this.sectionsContainer.rootObject['select_field_name'] = selectedField.field_name;
    // Replace some of the selected field's attributes with the operation field's attributes in order for
    // the operation field value to be set in the correct place within the operation object
    selectedField.name = this.field.name;
    selectedField.key = this.field.key;
    selectedField.edit = 'edit';
    if (fieldOperationType !== 'set_value') {
      // When user selects 'replace' or 'delete', the field title needs to be 'replace from value' or 'replace to value'
      selectedField.title = this.field.title;
      // When user selects 'replace' the field cannot be an array so setting field type to "inline"
      if (inline) {
        selectedField.field_type = FieldType.INLINE;
        selectedField.input_type = FieldInputType.INLINE;
      }
    }
    if (selectedField.field_type === FieldType.INLINE) {
      selectedField.sub_fields = this.getSubFields(selectedField, fieldOperationType);
      this.sectionsContainer.rootObject['operation_value_is_text'] = selectedField.sub_fields.filter(
        subField => subField.name === inline.prop).some(
          subField => this.textFieldTypes.includes(subField.field_type));
      // If field type is not array need to add inline fields into form control
      this.operationObject = await this.objectEditService.addInlineItem(
        modelName, selectedField, this.sectionsContainer);
      this.sectionsContainer.rootObject[this.field.name] = this.operationObject;
    } else if (selectedField.field_type === FieldType.ARRAY) {
      this.sectionsContainer.rootObject[selectedField.name] = [];
      this.sectionsContainer.parentObject = this.sectionsContainer.rootObject;
      if (selectedField.input_type === FieldInputType.INLINE_ARRAY) {
        for (const inlineField of selectedField.inline_fields) {
          inlineField.parent_name = this.field.name;
          inlineField.path = this.field.name;
        }
      }
    }
    this.operationField = selectedField;
  }

  private getSubFields(selectedField: MetaField, fieldOperationType: string) {
    const useReplaceFieldTitle = fieldOperationType === 'replace' ? !!selectedField.inline.prop : false;
    const subFields = [];
    const meta = this.modelsService.getModelMeta(selectedField.inline.model) || {};
    for (const [subFieldName, subFieldOrig] of Object.entries(meta)) {
      const subField: MetaField = {...subFieldOrig};
      let addField = selectedField.inline.prop === subFieldName ||
        (fieldOperationType !== 'replace_from_value' && subField.edit && subField.title &&
          subField.display === 'yes');
      if (addField) {
        addField = !(fieldOperationType === 'delete_value' && selectedField.inline?.unique_props.indexOf(subField.name) === -1);
      }
      if (!addField) {
        continue;
      }
      subFields.push(subField);
      // Must remove required prop to avoid client validation issues
      delete subField.is_required;
      // When user selects 'replace' the field title needs to be 'replace from value' or 'replace to value'
      if (useReplaceFieldTitle && selectedField.inline.prop === subFieldName) {
        subField.title = this.field.title;
      }
    }
    return subFields;
  }
}
