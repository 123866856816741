import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {Option} from '../../core/definitions/option-info';
import {FieldOptionsService} from '../../core/field-options.service';
import {FieldValueService} from '../../core/field-value.service';

@Component({
  selector: 'app-edit-field-radio-options',
  templateUrl: './edit-field-radio-options.component.html',
  styleUrls: ['./edit-field-radio-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldRadioOptionsComponent)
    }]
})
export class EditFieldRadioOptionsComponent implements OnInit, ControlValueAccessor {

  @Input() formControlName;
  @Input() fieldParameters: FieldParameters;

  constructor(private fieldOptionsService: FieldOptionsService,
              private fieldValue: FieldValueService) {
  }

  ngOnInit(): void {
    this.fieldOptionsService.initOptions(this.fieldParameters).then();
  }

  getFieldOptions(): Option[] {
    return this.fieldOptionsService.getOptions(this.fieldParameters);
  }

  onChange(event) {
    this.fieldValue.setFieldValueAndControlValue(
      this.fieldParameters, this.fieldParameters.object, this.fieldParameters.field, event.value).then();
  }

  registerOnChange(/*fn: any*/): void {
    // N/A
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }

}
