@if (fieldParameters.canAddNew) {
  <div class="edit-field-create-option-menu">
    <button mat-stroked-button
            (keyup)="createOptionWithKey($event)"
            (blur)="createOptionBlur()"
            type="button"
            (click)="createOption(); $event.stopPropagation();">
      <mat-icon>add</mat-icon>
      <span translate="TRANS__EDIT_FIELD_CREATE_OPTION_MENU__CREATE_NEW"></span>
    </button>
    @if (reference?.search_kulturnav) {
      <button mat-stroked-button
              (click)="searchCultureHub(); $event.stopPropagation();"
              (keyup)="searchCultureHubWithKey($event)"
              (blur)="searchCultureHubBlur()"
              type="button">
        <mat-icon>get_app</mat-icon>
        <span translate="TRANS__EDIT_FIELD_CREATE_OPTION_MENU__GET_FROM_AUTHORITY"></span>
      </button>
    }
  </div>
}
