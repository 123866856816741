<div class="media-carousel"
     [ngClass]="{'smallCarousel': mediumScreen, 'largeCarousel': !mediumScreen}">
  @if (mediaContainer.currentMediaType) {
    <div class="media-carousel__container"
         [ngClass]="{'can-annotate': canAnnotate()}">
      <div class="media-carousel__top">
        <app-media-carousel-top-menu
          [zoomValue]="zoomValue"
          [isEditing]="isEditing"
          [mediaElements]="mediaContainer.currentMediaType.mediaElements"
          [imageGallery]="imageGallery"
          [mediaIndex]="mediaContainer.currentMediaType.currentMediaIndex"
          [toggleAnnotations]="toggleAnnotations"
          [collapseZoomIn]="collapseZoomInButton"
          [mediumScreen]="mediumScreen"
          (changedZoomValue)="onChangeZoomValue($event)"
          (openImageFullScreen)="onOpenImageFullScreen($event)"
          (openModelViewer)="onOpenModelFullscreen($event)">
        </app-media-carousel-top-menu>
      </div>
      @if (mediaContainer.currentMediaType.currentMediaIndex >= 0) {
        <div class="media-carousel__active-image" #container>
          @if (!toggleAnnotations) {
            @if (container) {
              <app-active-media
                [activeMedia]="activeMedia"
                [zoomValue]="zoomValue"
                [container]="container"
                [fullscreen]="false"></app-active-media>
            }
          } @else {
            @if (curAnn && curAnn.annotateImage) {
              <app-active-annotate-image
                [curAnn]="curAnn"
                [imgIndex]="mediaContainer.currentMediaType.currentMediaIndex"
                [zoomValue]="zoomValue"
                [lockHeight]="false"
                [container]="container"
                [edit]="false"></app-active-annotate-image>
            }
          }
        </div>
      }
    </div>
  }
  @if (canAnnotate()) {
    <div class="media-carousel__annotation">
      <mat-slide-toggle (change)="toggleAnnotation()"
                        [disabled]="isEditing"
                        class="media-carousel__annotation-toggle-button">
        <span translate="TRANS__IMAGE_CAROUSEL__SHOW_ANNOTATION"></span></mat-slide-toggle>
      @if (toggleAnnotations && curAnn && curAnn.annotateImage) {
        <app-image-carousel-annotation-points
          [mediaType]="mediaContainer.currentMediaType"
          [curAnn]="curAnn"
          [mediumScreen]="mediumScreen"
          [readonly]="sectionsContainer?.rootObject.read_only">
        </app-image-carousel-annotation-points>
      }
    </div>
  }

  <div class="media-carousel__thumbnails-container">
    @if (!mediumScreen) {
      <div class="media-carousel__buttons-container">
        <button [disabled]="isEditing || imageGallery"
                type="button"
                class="media-carousel__change-media-order-button"
                mat-stroked-button color="primary"
                (click)="onToggleChangeMediaOrder()">
          <mat-icon>unfold_more</mat-icon>
          <span class="bold" translate="TRANS__MEDIA_CAROUSEL__CHANGE_ORDER"></span></button>
        @for (key of thumbWidthKeys; track key) {
          <button mat-stroked-button
                  class="media-carousel__size-buttons"
                  type="button"
                  (click)="setThumbWidth(key)"
                  [ngClass]="{'active': mediaContainer.thumbWidth === key}">
            <span class="bold" [translate]="thumbSize[key]"></span></button>
        }
      </div>
      <div class="media-carousel__thumbnail-stack">
        @for (mediaType of mediaContainer.mediaTypes; track mediaType) {
          <app-thumbnail-stack
            [mediaContainer]="mediaContainer"
            [mediaType]="mediaType"
            [isEditing]="isEditing"
            [imageGallery]="imageGallery"
            (setActiveImg)="onSetActiveMedia(mediaType, $event)"
          ></app-thumbnail-stack>
        }
      </div>
    } @else {
      <div class="media-carousel__thumbnail-carousel-container">
        <app-thumbnail-carousel-nav-button
          class="media-carousel__carousel-nav-button-left"
          [direction]="'prev'"
          [disable]="mediaContainer.mediaTypes[0].mediaType === mediaContainer.currentMediaType.mediaType
        && mediaContainer.currentMediaType.currentMediaIndex < 1"
          [mediaType]="mediaContainer.currentMediaType"
          (setActiveMediaIndex)="onSetActiveMedia( mediaContainer.currentMediaType, $event, 'prev')"
        ></app-thumbnail-carousel-nav-button>

        <div class="media-carousel__thumbnail-carousel" #mediaElementContainer>
          @for (mediaType of mediaContainer.mediaTypes; track mediaType) {
            <app-thumbnail-carousel
              [ngStyle]="{'width': (mediumScreenThumbnailWidth * mediaType.mediaElements.length) + 'px'}"
              class="media-carousel__thumbnail-carousel-media-type"
              [media]="mediaType.mediaElements"
              [mediaIndex]="mediaContainer.currentMediaType.currentMediaIndex"
              [currentMediaType]="mediaContainer.currentMediaType.mediaType"
              [thumbnailWidth]="mediumScreenThumbnailWidth"
              (setActiveImg)="onSetActiveMedia(mediaType, $event)"></app-thumbnail-carousel>
          }
        </div>

        <app-thumbnail-carousel-nav-button
          class="media-carousel__carousel-nav-button-right"
          [direction]="'next'"
          [disable]="disableNextButton"
          [mediaType]="mediaContainer.currentMediaType"
          (setActiveMediaIndex)="onSetActiveMedia(mediaContainer.currentMediaType, $event, 'next')"
        ></app-thumbnail-carousel-nav-button>
      </div>
    }

  </div>

  @if (showMediaOrder) {
    <div class="overlay"></div>

    <div class="side-selector change-img-order-selector media-carousel__selector">
      <app-change-media-order
        [mediaContainer]="mediaContainer"
        [sectionsContainer]="sectionsContainer"
        (toggleChangeMediaOrder)="onToggleChangeMediaOrder($event)"></app-change-media-order>
    </div>
  }

</div>
