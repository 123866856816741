<div class="dialog profile-image-uploader-component-wrapper">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title"> <span translate="TRANS__PROFILE_IMAGE_UPLOADER__HEADLINE"></span></h1>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <mat-dialog-content class="modal-body modal-body-edit-fields">
    <div class="modal-main-content">
    <app-media-selector-general
      *ngIf="user"
      [uploadInfo]="uploadInfo"
      [files]="newFiles"
      [parentObject]="user"
      (updateImage)="updateDialog($event)">
    </app-media-selector-general>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions class="modal-footer">
      <button mat-flat-button type="button" color="accent"  class="bold" (click)="save()">
        <span translate="TRANS__EDIT_OBJECT_DIALOG__SUBMIT"></span></button>
      <button mat-button
              class="bold"
              color="accent"
              (click)="closeDialog(false)">
        <span translate="TRANS__ADMIN_CONCEPT__JOIN_CONCEPTS__CANCEL"></span></button>
  </div>
</div>

