<div class="tree-node-container">
  <div class="header" [ngClass]="{active: isActive()}">
    <button mat-icon-button
            type="button"
            (click)="toggleOpen();"
            [hidden]="alwaysExpanded || isLoading() || isLeafNode() || node.is_leaf">
      <mat-icon *ngIf="!node.is_leaf">
        {{ (open || alwaysExpanded) && !isLoading() && !isLeafNode() ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>

    <div class="label" (click)="selectNode()">
      <span>
        {{ (node?.label || '') | translate }}
      </span>

      <span class="secondary">
        {{ (node?.secondaryLabel || '') | translate }}
      </span>
    </div>

    <button mat-icon-button
            class="reload-button"
            type="button"
            color="accent"
            [disabled]="isLoading()"
            *ngIf="showReloadButton || isLoading()"
            (click)="onReload.emit(node)">
      <mat-icon [ngClass]="{loading: isLoading()}">refresh</mat-icon>
    </button>

  </div>
  <div class="children-container" [ngClass]="{closed: !(alwaysExpanded || open) || isLeafNode()}">
    <app-tree-node *ngFor="let child of getChildren()"
                   [showReloadButton]="showReloadButton"
                   [alwaysExpanded]="alwaysExpanded"
                   [loading]="loading"
                   [node]="child"
                   [children]="children"
                   [selected]="selected"
                   (onReload)="onReload.emit($event)"
                   (onSelect)="onSelect.emit($event)"
                   (onOpen)="onOpen.emit($event)"
                   (onClose)="onClose.emit($event)">
    </app-tree-node>
  </div>
</div>
