import {Injectable} from '@angular/core';

import {CmsApiService} from './cms-api.service';
import {ObjectView} from './definitions/object-view';
import {SuperObjectModel} from './definitions/super-object-model';

export class ObjectViewDataParams {
  objectId: string;
  object: SuperObjectModel;
  templateGroupId: string;

  constructor() {
  }
}

@Injectable({
  providedIn: 'root'
})
export class ObjectViewDataService {

  constructor(private cms: CmsApiService) {
  }

  async createViewData(params: ObjectViewDataParams): Promise<ObjectView> {
    const cmsParams = {
      artifact_id: null,
      template_group_id: params.templateGroupId,
      timezone_offset: new Date().getTimezoneOffset()
    };
    let res;
    if (params.object) {
      cmsParams.artifact_id = params.object.artifact_id;
    } else {
      cmsParams.artifact_id = params.objectId;
    }
    if (cmsParams.artifact_id && cmsParams.artifact_id.indexOf('template') !== 0) {
      res = await this.cms.getArtifactView(cmsParams);
    } else {
      res = await this.cms.getMetaView(cmsParams);
    }
    return res;
  }

}
