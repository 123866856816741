import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
// eslint-disable-next-line max-len
import {SharedModule} from '../shared/shared.module';
import {SectionMenuScrollComponent} from './section-menu-scroll/section-menu-scroll.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {SectionMenuComponent} from './section-menu/section-menu.component';
import {OrderByTranslatedPipe} from './order-by-translated.pipe';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {ImageAnnotationModule} from '../image-annotation/image-annotation.module';
import { ObjectViewAndEditComponent } from './object-view-and-edit/object-view-and-edit.component';
import { ObjectUpdateLogComponent } from './object-information-tabs/object-update-log/object-update-log.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import { ObjectInformationTabsComponent } from './object-information-tabs/object-information-tabs.component';
import { ObjectFoldersComponent } from './object-information-tabs/object-folders/object-folders.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SectionPanelComponent } from './section-panel/section-panel.component';
import {RouterModule} from '@angular/router';
import { IdentificationNumberLogComponent } from './object-information-tabs/identification-number-log/identification-number-log.component';
import { CollectionLogComponent } from './object-information-tabs/collection-log/collection-log.component';
import { CollectionStatusLogComponent } from './object-information-tabs/collection-status-log/collection-status-log.component';
import { QuantityLogComponent } from './object-information-tabs/quantity-log/quantity-log.component';

@NgModule({
    exports: [
        SectionMenuComponent,
        SectionMenuScrollComponent,
        ObjectViewAndEditComponent,
        ObjectUpdateLogComponent,
        ObjectInformationTabsComponent,
        SectionPanelComponent,
      ObjectFoldersComponent,
      IdentificationNumberLogComponent,
      CollectionLogComponent,
      CollectionStatusLogComponent,
      QuantityLogComponent,
    ],
  imports: [
    FormsModule,
    MatDialogModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    ObjectSearchModule,
    OverviewFieldsModule,
    MediaSelectorModule,
    ObjectEditModule,
    ImageAnnotationModule,
    MatTabsModule,
    MatIconModule,
    MatTableModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  declarations: [
    SectionMenuComponent,
    SectionMenuScrollComponent,
    OrderByTranslatedPipe,
    ObjectViewAndEditComponent,
    ObjectUpdateLogComponent,
    ObjectInformationTabsComponent,
    ObjectFoldersComponent,
    SectionPanelComponent,
    IdentificationNumberLogComponent,
    CollectionLogComponent,
    CollectionStatusLogComponent,
    QuantityLogComponent
  ]
})
export class ObjectViewModule {
}
