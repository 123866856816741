import { Injectable } from '@angular/core';
import {SearchParameters, SearchParametersForOverview} from "./definitions/search-parameters";
import {CmsApiService} from "./cms-api.service";
import {SearchResult} from "./definitions/search-result";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private cms: CmsApiService) { }

  async search(searchParams: SearchParameters) {
    searchParams['q.op'] = searchParams['q.op'] || 'AND';
    return this.cms.search(searchParams);
  }

  async searchWithOverview(params: SearchParametersForOverview): Promise<SearchResult> {
    params['q.op'] = params['q.op'] || 'AND';
    let res: SearchResult;
    try {
      if (params.search_engine === 'advanced' && params.advanced_search_params?.db_search) {
        res = await this.cms.searchDbWithOverview(params);
      } else {
        res = await this.cms.searchWithOverview(params);
      }
    } catch (e) {
      if (e.status === 408) {
        res = await this.checkIfGettingCachedOverviewResult(e);
      } else {
        throw e;
      }
    }
    return res;
  }

  private async checkIfGettingCachedOverviewResult(e: any): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      const correlationId = e['Correlation-Id'];
      if (!correlationId) {
        console.log('Correlation-Id not found: ' + correlationId);
        throw e;
      }
      console.log('Request timeout with correlationId: ' + correlationId);
      this.getCachedOverviewResultWithRetry(correlationId, 0).then(
        res => {
          if (res) {
            console.log('Got cached search result with correlationId: ' + correlationId);
            this.cms.deleteCachedOverviewResult(correlationId).then(() => {
              console.log('Deleted cached search result with correlationId: ' + correlationId);
            });
          }
          resolve(res)
        }
      ).catch(reason => reject(reason));
    });
  }

  private async getCachedOverviewResultWithRetry(correlationId: string, retries = 0): Promise<SearchResult> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.cms.getCachedOverviewResult(correlationId).then(res => {
          if (res && !res['error']) {
            console.log('Got cached search result');
            resolve(res);
          } else {
            if (retries < 30) {
              console.log('Retrying getting cached search result for id ' + correlationId);
              this.getCachedOverviewResultWithRetry(correlationId, retries + 1)
            } else {
              reject('Too many retries')
            }
          }
        });
      }, 4000);
    })
  }


}
