import {Injectable} from '@angular/core';
import {ContentInfo} from '../core/definitions/content-info';
import {SearchParameters} from '../core/definitions/search-parameters';
import {SearchResult} from '../core/definitions/search-result';
import {SearchHandlerService} from '../object-search/search-handler.service';
import {SolrFilterService} from '../core/solr-filter.service';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {OperationTarget} from '../core/definitions/operation-target.enum';
import {OperationDialogService} from '../operations/operation-dialog.service';
import {SearchResultSelectionsService} from '../object-search/search-result-selections.service';
import {OperationService} from '../operations/operation.service';
import {
  ContentMenuSearchView,
  FilterProp,
} from '../core/definitions/object-content-tab/content-menus';
import {SearchContainer, SearchContainerParams} from '../core/definitions/search-container';
import {OperationContainer} from '../core/definitions/operation-container';
import {ContentListContainer} from '../core/definitions/object-content-tab/content-list-container';
import {SearchFilters} from '../core/definitions/search-filters';
import {SearchService} from "../core/search.service";
import {SearchObject} from "../core/definitions/search-object";

@Injectable({
  providedIn: 'root'
})
export class ObjectContentListService {

  constructor(private searchHandler: SearchHandlerService,
              private solrFilter: SolrFilterService,
              private searchService: SearchService,
              private operationDialogService: OperationDialogService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private operationService: OperationService) {
  }

  async getListSearchContainer(menu: ContentMenuSearchView, contentInfo: ContentInfo, $stateParams: any): Promise<SearchContainer> {
    const filters = await this.getSearchFilters(menu, contentInfo.artifact);
    const searchView = menu.search_view;
    menu.count = 0;
    this.setMenuCountFromPreSearchFilters(menu, filters);
    const searchContainerParams = new SearchContainerParams();
    searchContainerParams.filters = filters;
    searchContainerParams.rows = {'content-list': 5};
    searchContainerParams.searchViewName = searchView;
    searchContainerParams.searchViewData = menu.search_view_data;
    searchContainerParams.stateParams = $stateParams;
    searchContainerParams.targetObject = contentInfo.artifact;
    searchContainerParams.templateGroupId = $stateParams.template_group_id;
    searchContainerParams.listFieldTemplateId = $stateParams.list_field_template_id;
    searchContainerParams.qOp = menu.filter_props[0].q_op;
    return await this.searchHandler.createSearchContainer(searchContainerParams);
  }

  getMenuObjectsFromArray(rootObject: SuperObjectModel, superobjectTypeId: string) {
    const res = [];
    if (rootObject.adm_events) {
      for (const admEventItem of rootObject.adm_events) {
        if (admEventItem.superobject_type_id === superobjectTypeId) {
          res.push(admEventItem);
        }
      }
    }
    if (rootObject.artifacts) {
      for (const artifact of rootObject.artifacts) {
        if (artifact.superobject_type_id === superobjectTypeId) {
          res.push(artifact);
        }
      }
    }
    return res;
  }

  setListOperationsContainer(menu: ContentMenuSearchView, contentInfo: ContentInfo, listName: string) {
    if (menu.get_operations) {
      // Dynamically get operations based on selected objects, like in 'regular' search views
      const contentListContainer = contentInfo.contentListContainers[listName];
      const searchContainer = contentListContainer.searchContainer;
      if (searchContainer) {
        const operationContainer = new OperationContainer(OperationTarget.CONTENT_LIST_VIEW);
        contentListContainer.operationContainer = operationContainer;
        searchContainer.operationContainer = operationContainer;
        // operationContainer.art = searchContainer.
        searchContainer.selections.selectItemCallback = () => {
          this.setOperations(contentListContainer).then();
        };
        operationContainer.getTargetId = () => {
          return contentInfo.artifact?.artifact_id;
        };
        operationContainer.art = contentInfo.artifact;
        operationContainer.openOperationDialogFn = () => {
          this.operationDialogService.openOperationDialog(operationContainer).then(data => {
            if (data.refreshView && contentListContainer.refreshCallback) {
              contentListContainer.refreshCallback();
            }
          });
        };

      }
    }
    // TODO: The two types of operation menus above should be combined into one (the dynamic one)
  }

  private async setOperations(contentListContainer: ContentListContainer) {
    const searchContainer = contentListContainer.searchContainer;
    this.operationService.setOperationContextObjects(
      searchContainer.operationContainer,
      this.searchResultSelectionsService.getCleanItems(searchContainer));
    await this.operationService.setOperations(contentListContainer.operationContainer, searchContainer);
  }


  // In some cases, need to run a "pre search" in order to set search filters, due to the relevant information being
  // indexed within separate search documents instead of within the "parent" document, e.g. for attachments usage.
  private async getFiltersFromPreSearch(menu: ContentMenuSearchView, artifact: SuperObjectModel): Promise<any> {
    const params = {} as SearchParameters;
    const preFilters = this.getPreSearchFilters(menu, artifact)
    this.solrFilter.setFqFromObject(params, preFilters);
    const preSearchRes: SearchResult = await this.searchService.search(params);
    if (!(preSearchRes.artifacts && preSearchRes.artifacts.length)) {
      console.warn('Pre search did not return a result');
      return;
    }
    const preSearchObjects = preSearchRes.artifacts || [];
    return this.setPreSearchFilters(menu, preSearchObjects);
  }

  private getPreSearchFilters(menu: ContentMenuSearchView, artifact: SuperObjectModel) {
    const preFilters = {} as SearchFilters;
    for (const preSearchFilter of menu.pre_search) {
      const filterPropName = preSearchFilter.filter_prop_name;
      const filterPropValueSourceProp = preSearchFilter.filter_prop_value_source_prop;
      const filterPropValue = preSearchFilter.filter_prop_value;
      if (filterPropValueSourceProp) {
        preFilters[filterPropName] = artifact[filterPropValueSourceProp];
      } else if (filterPropValue) {
        preFilters[filterPropName] = filterPropValue;
      }
    }
    return preFilters;
  }

  private setPreSearchFilters(menu: ContentMenuSearchView, preSearchObjects: SearchObject[]) {
    const filters = {};
    for (const preSearchObj of preSearchObjects) {
      const filter = this.getSearchFiltersFromObject(menu, preSearchObj);
      for (const [key, value] of Object.entries(filter)) {
        this.setPreSearchFilter(filters, key, value);
      }
    }
    return filters;
  }

  private setPreSearchFilter(filters: any, key: string, value: any) {
    if (!filters[key]) {
      filters[key] = Array.isArray(value) ? value : [value];
    } else {
      if (Array.isArray(value)) {
        filters[key] = filters[key].concat(value);
      } else {
        filters[key].push(value);
      }
    }
  }

  private setMenuCountFromPreSearchFilters(menu: ContentMenuSearchView, filters: any) {
    if (menu.pre_search && menu.pre_search.length) {
      const preSearchSourceProp = menu.pre_search[0].filter_prop_value_source_prop;
      if (preSearchSourceProp) {
        const sourceFilters = filters[preSearchSourceProp];
        if (sourceFilters) {
          menu.count = sourceFilters.length;
        }
      }
    }
  }

  private async getSearchFilters(menu: ContentMenuSearchView, artifact: SuperObjectModel): Promise<any> {
    if (menu.pre_search) {
      return await this.getFiltersFromPreSearch(menu, artifact);
    } else {
      return this.getSearchFiltersFromObject(menu, artifact);
    }
  }

  private getSearchFiltersFromObject(menu: ContentMenuSearchView, object: any) {
    const filterProps = menu.filter_props || [];
    let res = null, requiredMissing = false;
    for (const filterProp of filterProps) {
      const propVal = this.getFilterPropValue(filterProp, object);
      if (propVal && propVal.length > 0) {
        res = res || {};
        let filterPropName = this.getFilterPropName(filterProp, object);
        if (filterProp.filter_type) {
          filterPropName = filterProp.filter_type + ':' + filterPropName;
        }
        res[filterPropName] = propVal;
      } else {
        if (filterProp.artifact_prop_required) {
          requiredMissing = true;
        }
      }
    }
    if (requiredMissing) {
      res = null;
    }
    return res;
  }

  private getFilterPropValue(filterProp: FilterProp, artifact: any) {
    const artPropName = filterProp.artifact_prop_name;
    let res = filterProp.filter_prop_value;
    let mod: any;
    if (!artPropName) {
      return res;
    }
    res = artifact[artPropName];
    if (res !== undefined) {
      return res;
    }
    const propSplit = artPropName.split('.');
    mod = artifact;
    for (const propName of propSplit) {
      if (mod) {
        if (Array.isArray(mod)) {
          res = mod.map(arrItem => arrItem[propName]);
        } else {
          res = mod[propName];
          if (!res) {
            console.warn('Object does not contain property "' + propName + '"');
          }
        }
        mod = res;
      }
    }
    return res;
  }

  private getFilterPropName(filterPropInfo: FilterProp, artifact: any) {
    const propNameInfo = filterPropInfo.filter_prop_name_info;
    let elseProp: any;
    let res = propNameInfo.filter_prop_name;
    if (Object.keys(propNameInfo).length > 1) {
      let test: boolean;
      for (const [key, value] of Object.entries(propNameInfo)) {
        if (key === 'if_clause') {
          test = this.checkFilterPropNameEquals(value, artifact);
        } else if (key === 'else_value') {
          elseProp = value;
        }
      }
      if (!test) {
        if (elseProp) {
          res = elseProp;
        } else {
          throw new Error('\'else_value\' attribute missing');
        }
      }
    }
    if (!res) {
      console.warn('No valid filter prop name found');
    }
    return res;
  }

  private checkFilterPropNameEquals(value: string, artifact: any) {
    let res = false;
    const values = value.split(' or ');
    for (const valuePair of values) {
      const split = valuePair.split('==');
      if (!res) {
        const testProp = split[0];
        const testValue = split[1];
        res = artifact[testProp] === testValue;
      }
    }
    return res;
  }

}
