import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageItem} from '../../../../core/definitions/image-item';
import {CmsApiService} from '../../../../core/cms-api.service';
import {UploadInfo} from '../../../../core/definitions/upload-info';
import User from '../../User';
import {ContextList} from '../../../../core/definitions/context-list';
import {BaseModel} from '../../../../core/definitions/base-model';

@Component({
  selector: 'app-profile-image-uploader',
  templateUrl: './profile-image-uploader.component.html',
  styleUrls: ['./profile-image-uploader.component.scss']
})
export class ProfileImageUploaderComponent {

  user!: User;
  readonly uploadInfo: UploadInfo;
  readonly newFiles: Array<ImageItem>;

  constructor(private readonly dialogRef: MatDialogRef<ProfileImageUploaderComponent>,
              private readonly cmsApi: CmsApiService,
              @Inject(MAT_DIALOG_DATA) private readonly data: User) {
    this.user = this.data ? this.data : new User();
    this.newFiles = [];
    this.user.images.forEach((image, index) => {
      if (index === 0) {
        this.newFiles.push(image);
      }
    });
    this.uploadInfo = {
      file_accept: 'image/*',
      file_type: 'image',
      from_camera: true,
      item_model: 'ImageItem',
      multiple_files: false,
      object_type: 'Image',
      parent_to_item_fields: ['code'],
      select_from_parent: true,
      target_array: 'images',
      text_from_camera: 'TRANS__IMAGE_SELECTOR__NEW_IMAGE_CAMERA',
      text_select_from_parent: 'TRANS__IMAGE_SELECTOR__CHOOSE_OBJECT_IMAGE',
      text_upload: 'TRANS__IMAGE_SELECTOR__UPLOAD_IMAGES',
      text_upload_single: 'TRANS__IMAGE_SELECTOR__CHANGE_IMAGE',
      text_upload_single_tablet: 'TRANS__IMAGE_SELECTOR__CHANGE_IMAGE_AND_CAMERA',
      text_upload_tablet: 'TRANS__IMAGE_SELECTOR__UPLOAD_IMAGE_AND_CAMERA',
    } as UploadInfo;
  }

  closeDialog(success: boolean): void {
    this.dialogRef.close(success);
  }
  updateDialog(event) {
     this.newFiles[0] = event;
  }

  async save(): Promise<void> {
    const res = await this.cmsApi.saveSubArtifacts({
      contexts: this.user.images as Array<BaseModel>
    } as ContextList);

    if (!!res) {
      this.closeDialog(true);
    }
  }
}
