export class SearchFilters {
  constructor(filters?: object) {
    if (filters) {
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          this[key] = filters[key];
        }
      }
    }
  }

  artifact_id: Array<string>;
  image_id: string;
  meta_type: Array<string>;
  object_type: Array<string>; // Could be both strings and array of strings
  super_artifact_id: string;
  parent_id: string;
  level: number;
  fq: string[];
}
