<div class="report-preview-area">
  @if(reportTemplate.reportViewTypeId === katalograpportMedOversiktsfelterUuid) {
    <report-preview-catalogue
      [reportTemplate]="reportTemplate"
      [extractedArtifacts]="extractedArtifacts"
      [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
      [preview]="preview"></report-preview-catalogue>
  } @else if (reportTemplate.reportViewTypeId === bilderapportMedOversiktsfelterUuid) {
    <report-picture-with-overview
      [reportTemplate]="reportTemplate"
      [extractedArtifacts]="extractedArtifacts"
      [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
      [preview]="preview">
    </report-picture-with-overview>
  } @else if (reportTemplate.reportViewTypeId === bilderapportId) {
    <report-image
      [reportTemplate]="reportTemplate"
      [extractedArtifacts]="extractedArtifacts"
      [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
      [preview]="preview">
    </report-image>
  } @else if (reportTemplate.reportViewTypeId === rapportlisteMedBildeUuid) {
    <report-list
      [reportTemplate]="reportTemplate"
      [extractedArtifacts]="extractedArtifacts"
      [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
      [preview]="preview">
    </report-list>
  } @else if (reportTemplate.reportViewTypeId) {
    <div>Denne rapporttypen er ikke implementert enda</div>
  } @else {
    <div>Velg en rapportvisning og klikk på lagre. Foreløpig må du deretter klikke Oppdater i nettleseren for å se rapporten. </div>
  }


</div>
