import {Component, Input, OnInit} from '@angular/core';
import {CommonsService} from '../../core/commons.service';
import {CmsApiService} from '../../core/cms-api.service';
import {UploadMediaContainer} from '../../core/definitions/upload-media-container';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationUploadContainer} from '../../core/definitions/operation-upload-container';

@Component({
  selector: 'app-media-selectors',
  templateUrl: './media-selectors.component.html',
  styleUrls: ['./media-selectors.component.scss']
})
export class MediaSelectorsComponent implements OnInit {

  @Input() object: SuperObjectModel;
  @Input() operationUploadContainer: OperationUploadContainer;
  @Input() annotateDashboardClass: string;
  @Input() isDialog: boolean;

  constructor(private commons: CommonsService,
              private cms: CmsApiService) {
  }

  ngOnInit() {
    if (this.object) {
      this.operationUploadContainer.uploadMediaContainers = [];
      this.cms.getModelUploadInfo({modelName: this.object.object_type}).then(res => {
        res.upload_info_list.forEach(uploadInfo => {
          const mediaContainer = new UploadMediaContainer();
          mediaContainer.uploadInfo = uploadInfo;
          mediaContainer.parentObject = this.object;
          mediaContainer.name = 'TRANS__HEADER__' + uploadInfo.target_array.toUpperCase();
          this.operationUploadContainer.uploadMediaContainers.push(mediaContainer);
        });
      });
    }
  }

}
