<table mat-table [dataSource]="folderItems" class="object-folders information-tabs-table">
    <!-- Position Column -->
  <ng-container matColumnDef="folder_name">
    <th mat-header-cell *matHeaderCellDef>{{'TRANS__OBJECT_FOLDERS__FOLDER' | translate}}</th>
    <td mat-cell *matCellDef="let element">
      <a *ngIf="element['$$routerLink']"
         [routerLink]="element['$$routerLink']"
         [queryParams]="element['$$queryParams']"
         class="text-blue-hover">{{element['folder_id_value']}}
      </a>
      <span *ngIf="!element['$$routerLink']">{{element['folder_id_value']}}</span>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="folder_type_id">
    <th mat-header-cell *matHeaderCellDef>{{'TRANS__OBJECT_FOLDERS__FOLDER_TYPE' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{element['folder_type_id_value']}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef>{{'TRANS__OBJECT_FOLDERS__CREATED_AT' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{fromSolrDate(element['created_at'])}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="created_by">
    <th mat-header-cell *matHeaderCellDef>{{'TRANS__OBJECT_FOLDERS__CREATED_BY' | translate}}</th>
    <td mat-cell *matCellDef="let element"> {{element['created_by_id_value']}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
