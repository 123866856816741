@if (fromDateControlName) {
  <div class="edit-field-precision-date-range"
       [formGroup]="fieldParameters.sectionsContainer.formGroup">

    <div class="edit-field-precision-date-range__first-field">
      @if (fromDateField.input_type === 'date_iso') {
        <app-edit-field-date-historic
          [fieldParameters]="fieldParameters"
          [field]="fromDateField"
          [formControlName]="fromDateControlName"
          [otherFormControlName]="toDateControlName"
          [placeholder]="placeholderFromDate | translate"
        ></app-edit-field-date-historic>
      } @else if (fromDateField.input_type === 'date_time_iso') {
        <app-edit-field-date-picker
          [fieldParameters]="fieldParameters"
          [field]="fromDateField"
          [otherField]="toDateField"
          [formControlName]="fromDateControlName"
          [otherFormControlName]="toDateControlName"
          [placeholder]="placeholderFromDate | translate"
        ></app-edit-field-date-picker>
      }
    </div>
    <div class="edit-field-precision-date-range__second-field">
      @if (fromDateField.input_type === 'date_iso') {
        <app-edit-field-date-historic
          [fieldParameters]="fieldParameters"
          [field]="toDateField"
          [formControlName]="toDateControlName"
          [otherFormControlName]="fromDateControlName"
          [placeholder]="placeholderToDate | translate"
        ></app-edit-field-date-historic>
      } @else {
        <app-edit-field-date-picker
          [fieldParameters]="fieldParameters"
          [field]="toDateField"
          [otherField]="fromDateField"
          [formControlName]="toDateControlName"
          [otherFormControlName]="fromDateControlName"
          [isToDate]="true"
          [placeholder]="placeholderToDate | translate"
        ></app-edit-field-date-picker>
      }
    </div>
  </div>
}
