import {Component, Input} from '@angular/core';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {UntypedFormControl} from '@angular/forms';
import {MetaTypes} from "../../core/definitions/meta-types";

@Component({
  selector: 'app-object-information-tabs',
  templateUrl: './object-information-tabs.component.html',
  styleUrls: ['./object-information-tabs.component.scss']
})
export class ObjectInformationTabsComponent {

  @Input() object: SuperObjectModel;

  tabIndex = new UntypedFormControl(0);
  logMetaTypes = [
    MetaTypes.ARTIFACT.toString(),
    MetaTypes.ARTIFACT_COPY.toString(),
    MetaTypes.CONSTRUCTION.toString(),
    MetaTypes.LIVING_COLLECTION.toString(),
    MetaTypes.TRANSPORTATION.toString(),
  ]

  toggleTab(index) {
    if (index === this.tabIndex.value) {
      setTimeout(() => {
        this.tabIndex.setValue(0);
        }, 100);
    }
  }

}
