<div class="dialog culture-hub-importer-dialog">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span translate="TRANS__CULTURE_HUB_IMPORTER__TITLE"></span>
    </h1>

    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <mat-tab-group color="accent">
    <mat-tab label="{{'TRANS__CULTURE_HUB_IMPORTER__SINGLE_CONCEPTS' | translate}}">
      <app-culture-hub-importer-items
        *ngIf="cultureHubData"
        [dialogRef]="dialogRef"
        [cultureHubData]="cultureHubData">
      </app-culture-hub-importer-items>
    </mat-tab>
    <mat-tab label="{{'TRANS__CULTURE_HUB_IMPORTER__FOLDERS' | translate}}">
        <app-culture-hub-importer-folders
          *ngIf="cultureHubData"
          [dialogRef]="dialogRef"
          [cultureHubData]="cultureHubData">
        </app-culture-hub-importer-folders>
    </mat-tab>
  </mat-tab-group>
</div>
