<div class="panel-layout-container">

  <span class="header" (click)="onClose.emit()">
    <span class="page-handle"></span>
    <span class="close-text">{{ closePanelText | translate }}</span>
  </span>

  <h1 class="headline">{{ headlineText | translate }}</h1>

  <p class="description">{{ description | translate }}</p>

  <ng-container *ngIf="loading">
    <div class="spinner-wrapper">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <div class="filter-section">
      <ng-content select="[filter]"></ng-content>
    </div>

    <div class="container">
      <ng-content></ng-content>
    </div>
  </ng-container>

</div>
