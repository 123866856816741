import { Injectable } from '@angular/core';
import {ModelsService} from './models.service';
import {MetaField} from './definitions/meta-field';
import {SuperObjectModel} from './definitions/super-object-model';
import {BaseModel} from './definitions/base-model';
import {System} from "./definitions/system";
import {LoggerService} from "./logger.service";

export class TraverseObjectResult {
  parentFieldName: string;
  fieldName: string;
  subObject: BaseModel | BaseModel[];
  parentObject: BaseModel | BaseModel[];
  parentIndex: number;
  parentKey: string;
}

export class FieldArrayIndexData {
  fieldName: string;
  arrIndex: number;

}

export class MetaFieldAndSubObject {
  fieldMeta: MetaField;
  subObject: BaseModel | BaseModel[];
}

@Injectable({
  providedIn: 'root'
})
export class ObjectFieldTraverseService {

  constructor(private logger: LoggerService,
              private modelsSvc: ModelsService) { }

  traverseObjectByPath(object: BaseModel, fieldPath: string, pathIndexOffset: number, createArrayElement?: boolean): TraverseObjectResult {
    let keySep = '';
    const res = new TraverseObjectResult();
    res.subObject = object;
    res.parentObject = object
    res.parentIndex = null;
    res.parentKey = '';
    if (!fieldPath) {
      return res;
    }
    const fieldNameSplits = fieldPath.split(System.keySep);
    for (const [index, fieldNameSplit] of fieldNameSplits.entries()) {
      const arrIndexRes = this.getFieldArrayIndex(fieldNameSplit);
      res.fieldName = arrIndexRes.fieldName;
      res.parentKey += keySep + fieldNameSplit;
      keySep = System.keySep;
      if (res.subObject && index < fieldNameSplits.length - pathIndexOffset) {
        res.parentObject = res.subObject;
        res.subObject = res.subObject[res.fieldName];
        this.setArrayBasedSubObjects(object, res, arrIndexRes, createArrayElement);
      }
      if (index < fieldNameSplits.length - pathIndexOffset - 1) {
        res.parentFieldName = res.fieldName;
      }
    }
    return res;
  }

  getSubObjectFromField(object: BaseModel, field: MetaField): BaseModel | BaseModel[] {
    let subObject: BaseModel | BaseModel[] = object;
    if (field.path) {
      const traverseRes = this.traverseObjectByPath(object, field.path, 0);
      subObject = traverseRes.subObject;
    }
    return subObject;
  }

  getFieldMetaAndSubObjectFromPath(object: SuperObjectModel, fieldPath: string) {
    const traverseRes = this.traverseObjectByPath(object, fieldPath, 1);
    const metaField = {} as MetaField;
    const res: MetaFieldAndSubObject = {
      fieldMeta: metaField,
      subObject: traverseRes.subObject
    }
    if (!res.subObject) {
      this.logger.warn('Could not find sub object for ' + fieldPath);
      return res;
    }
    let meta = res.subObject['$$meta'];
    if (!meta && traverseRes.parentFieldName) {
      const parentFieldMeta = object.$$meta[traverseRes.parentFieldName];
      meta = this.modelsSvc.getModelMeta(parentFieldMeta.inline.model);
    }
    if (!meta) {
      this.logger.warn('Sub object missing meta');
      return res;
    }
    res.fieldMeta = meta[traverseRes.fieldName];
    if (!res.fieldMeta) {
      this.logger.warn('Could not find meta field ' + traverseRes.fieldName);
      return res;
    }
    if (res.fieldMeta.inline) {
      res.subObject = this.modelsSvc.getModelCopy(res.fieldMeta.inline.model);
    }
    return res;
  }

  private setArrayBasedSubObjects(object: BaseModel,
                                  res: TraverseObjectResult,
                                  arrIndexRes: FieldArrayIndexData,
                                  createArrayElement: boolean) {
    // Field path either contains array indexes, in which case the correct array items must be traversed,
    // or not, in which case the first element in the arrays are traversed because the indexes don't matter
    if (!res.subObject || !Array.isArray(res.subObject)) {
      return;
    }
    if (arrIndexRes.arrIndex !== -1) {
      res.subObject = res.subObject[arrIndexRes.arrIndex];
    } else {
      res.parentKey += '[{index1}]';
      if (res.subObject.length > 0) {
        res.subObject = res.subObject[0];
        res.parentIndex = 0;
      } else if (createArrayElement && object.$$meta[res.fieldName].input_type !== 'ref_array') {
        const arrayMeta = object.$$meta[res.fieldName];
        const model = this.modelsSvc.getModelCopy(arrayMeta.inline.model);
        res.subObject.push(model);
        res.subObject = res.subObject[0];
        // This makes sure array based prime fields work
        res.parentIndex = 0;
      } else {
        this.logger.warn('No items in array ' + res.fieldName);
      }
    }
  }

  private getFieldArrayIndex(fieldNameIn: string): FieldArrayIndexData {
    const bracketPos = fieldNameIn.indexOf('[');
    let arrIndex = -1;
    let fieldName = fieldNameIn;
    if (bracketPos !== -1) {
      fieldName = fieldNameIn.substring(0, bracketPos);
      arrIndex = Number(fieldNameIn.substring(bracketPos + 1, fieldNameIn.indexOf(']')));
    }
    return {
      fieldName: fieldName,
      arrIndex: arrIndex
    };
  }

}
