<ng-container *ngIf="conceptSetting">
  <button mat-button
          [matMenuTriggerFor]="settingMenu"
          class="admin-concept-concepts__setting-button">
    <mat-icon>settings</mat-icon></button>
  <mat-menu #settingMenu="matMenu" class="removePaddingInMatMenu">

    <button mat-menu-item>
      <label>
        <span translate="TRANS__ADMIN_CONCEPT__ALLOW_NEW"></span> <span> </span>
        <span> {{conceptsContainer.conceptType.concept_name.toLocaleLowerCase()}}</span><span>:  </span>
        <mat-checkbox [(ngModel)]="conceptSetting.editable"
                      (ngModelChange)="store()"
        ></mat-checkbox>
      </label>
    </button>

    <button mat-menu-item *ngFor="let subSetting of conceptSetting.sub_settings"
            class="admin-settings-setting__content-item">
      <app-admin-settings-field-value
        [subSetting]="subSetting"
        [editing]="true"
        (changed)="store()"
      ></app-admin-settings-field-value>
    </button>

  </mat-menu>

</ng-container>
