export enum MetaTypes {
  ACTIVITY = 'activity',
  ADM_EVENT = 'adm_event',
  ARTIFACT = 'artifact',
  ARTIFACT_COPY = 'artifact_copy',
  CONCEPT = 'concept',
  CONSTRUCTION = 'construction',
  LIVING_COLLECTION = 'living_collection',
  LOCATION = 'location',
  NAMED = 'named',
  PLACE = 'place',
  SPECTRUM_PROCEDURE = 'spectrum_procedure',
  SUB_MODEL = 'sub_model',
  TRANSPORTATION = 'transportation',
}
