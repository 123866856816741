import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';

import {SharedModule} from '../shared/shared.module';
import {MediaCarouselModule} from '../media-carousel/media-carousel.module';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {AdminImportAuthoritiesComponent} from './admin-import-authorities/admin-import-authorities.component';
import {ObjectViewModule} from '../object-view/object-view.module';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {ImportSitulaComponent} from './import-situla/import-situla.component';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {OperationsModule} from '../operations/operations.module';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ObjectContentTabModule} from '../object-content-tab/object-content-tab.module';
// eslint-disable-next-line max-len

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatDialogModule,
        MatTableModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSliderModule,
        MatIconModule,
        SharedModule,
        MediaCarouselModule,
        ObjectEditModule,
        ObjectViewModule,
        ObjectSearchModule,
        OverviewFieldsModule,
        MediaSelectorModule,
        OperationsModule,
        MatFormFieldModule,
        MatInputModule,
        ObjectContentTabModule,
    ],
  declarations: [
    AdminImportAuthoritiesComponent,
    ImportSitulaComponent,
  ]
})
export class LandingPagesModule {
}
