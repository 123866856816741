import {SuperObjectModel} from './super-object-model';
import {Selector} from './reference';
import {ContentListContainer} from './object-content-tab/content-list-container';
import {ContentListSourceContainer} from './object-content-tab/content-list-source-container';
import {ContentMenu} from './object-content-tab/content-menus';

export class ContentInfo {
  artifact: SuperObjectModel;
  curListName: string;
  curListContainer: ContentListContainer;
  curContentListSource: ContentListSourceContainer;
  contentListContainers: {[name: string]: ContentListContainer} = {};
  menus: Array<ContentMenu>;
  selectedMainMenu: any;
  setContentMenus: any;
  setCurrentList: any;
  sources: any;
  templateGroupId: string;
  visibleDropDowns: Array<any>;

  updateContentInfo(isEditing, art: SuperObjectModel, listName) {
    let useListName = listName;
    if (isEditing || !listName) {
      useListName = 'overview';
    }
    this.curListName = useListName;
    this.artifact = art;
    if (this.setContentMenus) {
      this.setContentMenus(this);
    }
  }
  setSelectorUsed(selector: Selector,
                  sources?: ContentListSourceContainer[],
                  currentListContainer?: ContentListSourceContainer) {
    const used = [];
    if (!currentListContainer && this.curContentListSource) {
      currentListContainer = this.curContentListSource;
    }
    if (currentListContainer) {
      let usedItems = currentListContainer.array;
      if (sources && sources.length) {
        // This will make sure that 'used' objects in procedure steps are added to the 'used' array
        sources.forEach(contentListSource => {
          usedItems = usedItems.concat(contentListSource.array);
        });
      }
      usedItems.forEach(item => {
        used.push(item[currentListContainer.relatedObjectIdField]);
      });
    } else if (this.curListContainer) {
      this.curListContainer.searchContainer.searchResult.artifacts.forEach(artifact => {
        used.push(artifact.artifact_id);
      });
    }
    selector.used = used;
  }

  setContentListSource(contentListSource: ContentListSourceContainer) {
    this.curContentListSource = contentListSource;
  }
}
