<!-- Styling classes stolen from the 'object new page' component just to have something up and running... -->
<div class="admin-mass-media-import">
  <app-top-header [parent]="parent"
                  backRouterLink="/admin"
                  backRouterLinkLabel="TRANS__SECTION__ADMIN__TITLE"
                  class="admin-header-container"></app-top-header>

  <app-header-and-description heading="TRANS__MASS_MEDIA_IMPORT__HEADING"
                              description="TRANS__MASS_MEDIA_IMPORT__MEDIA_FOLDER_DESCRIPTION">

  <div class="admin-mass-media-import__container">
    <div class="admin-mass-media-import__top">
      <div class="admin-mass-media-import__pre-ops">
        <div class="admin-mass-media-import__create-folder">
          <ng-container *ngIf="preOperationContainer?.operations?.length">
            <button mat-stroked-button
                    class="admin-mass-media-import__create-folder-button"
                    color="primary"
                    type="button"
                    [disabled]="preOpButtonsDisabled"
                    *ngFor="let operation of preOperationContainer.operations"
                    (click)="openPreProdOperation(operation)">
              <i class="{{operation.menu_icon}}"></i>
              <span class="bold uppercase"
                    [translate]="operation.menu_title"></span>
            </button>
          </ng-container>
          <span *ngIf="mediaFolder" class="bold admin-mass-media-import__created-folder">
            <app-object-url [object]="mediaFolder">
              <span class="object-url-content text-blue"><span translate="TRANS__MASS_IMPORT__MEDIA_FOLDER_NAME"></span>: {{mediaFolder.artifact_name}}</span>
            </app-object-url>
          </span>
        </div>
        <div class="admin-mass-media-import__buttons">
          <button mat-stroked-button
                  type="button"
                  [disabled]="importButtonDisabled || !mediaFolder"
                  color="primary">
            <label for="uploadFiles">
              <i class="icon-upload"></i>
              <span *ngIf="computer"
                    class="bold uppercase"
                    translate="TRANS__IMAGE_SELECTOR__UPLOAD_IMAGES"></span>
              <span *ngIf="!computer"
                    class="bold uppercase"
                    translate="TRANS__IMAGE_SELECTOR__UPLOAD_IMAGE_AND_CAMERA"></span>
            </label>
            <input type="file"
                   (change)="onFileSelect($event)"
                   id="uploadFiles"
                   multiple="multiple"
                   accept="image/*">
          </button>
        </div>

      </div>
      <div>
        <br>
      </div>
      <div *ngIf="lastUploadWorkerResult?.uploadStatus === 'uploading'">
        <h2 translate="TRANS__MASS_MEDIA_IMPORT__UPLOADING"></h2>
      </div>
      <div *ngIf="lastUploadWorkerResult?.uploadStatus === 'uploadFinished'">
        <h2 translate="TRANS__MASS_MEDIA_IMPORT__SUCCESS"></h2>
      </div>
      <div *ngIf="lastUploadWorkerResult?.uploadStatus === 'failed'">
        <h2 translate="TRANS__MASS_MEDIA_IMPORT__FAILED"></h2>: <span>{{lastUploadWorkerResult.statusText}}</span>
      </div>
      <div *ngIf="uploadProgress">
        <mat-progress-bar mode="determinate" value="{{uploadProgress}}"></mat-progress-bar>
      </div>
      <table *ngIf="lastUploadWorkerResult?.images" mat-table [dataSource]="lastUploadWorkerResult.images" aria-label="Import results">
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef translate="TRANS__MASS_MEDIA_IMPORT__FILE_NAME"></th>
          <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
        </ng-container>
        <ng-container matColumnDef="ok">
          <th mat-header-cell *matHeaderCellDef translate="TRANS__MASS_MEDIA_IMPORT__IMPORT_OK"></th>
          <td mat-cell *matCellDef="let element"> {{element.ok ? '&#10003;' : '&#10005;'}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef translate="TRANS__MASS_MEDIA_IMPORT__IMPORT_STATUS"></th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="statusText">
          <th mat-header-cell *matHeaderCellDef translate="TRANS__MASS_MEDIA_IMPORT__IMPORT_STATUS_TEXT"></th>
          <td mat-cell *matCellDef="let element"> {{element.statusText}} </td>
        </ng-container>
        <ng-container matColumnDef="uploadStatus">
          <th mat-header-cell *matHeaderCellDef translate="TRANS__MASS_MEDIA_IMPORT__UPLOAD_STATUS"></th>
          <td mat-cell *matCellDef="let element"> {{element.uploadStatusText}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  </app-header-and-description>
</div>
