import {Component, Input, OnChanges} from '@angular/core';
import {OperationStepInformation} from '../../core/definitions/operation-step-information';
import {OperationService} from '../operation.service';
import {OperationContainer} from '../../core/definitions/operation-container';

@Component({
  selector: 'app-operation-view-information',
  templateUrl: './operation-view-information.component.html',
  styleUrls: ['./operation-view-information.component.scss']
})
export class OperationViewInformationComponent implements OnChanges {

  @Input() operationContainer: OperationContainer;
  @Input() operationStepIndex: number;

  information: OperationStepInformation;

  constructor(private operationService: OperationService) { }

  ngOnChanges(): void {
    const stepIndex = this.operationContainer.currentOperation.$$currentStepIndex;
    const currentStep = this.operationContainer.currentOperation.operation_steps[stepIndex];
    if (currentStep.information) {
      this.information = currentStep.information;
      if (!this.operationContainer.currentOperation.$$currentStep) {
        this.operationService.setOperationStep(this.operationContainer).then();
      }
    }
  }

}
