import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IdentifierFormat} from '../identifier-format';
import {CollectionChangedEvent} from '../admin-id-format-collection-list/admin-id-format-collection-list.component';
import {AdminIdFormatService} from '../admin-id-format.service';
import {Collection} from '../collection';
import {SettingsService} from "../../../core/settings.service";

@Component({
  selector: 'app-admin-id-format-collection-view',
  templateUrl: './admin-id-format-collection-view.component.html',
  styleUrls: ['./admin-id-format-collection-view.component.scss']
})
export class AdminIdFormatCollectionViewComponent implements OnChanges {

  @Input() formats: Array<IdentifierFormat>;
  @Output() public readonly formatAdded: EventEmitter<IdentifierFormat>;
  @Output() public readonly formatDeleted: EventEmitter<IdentifierFormat>;

  selectedCollection: Collection;
  formatsToDisplay: Array<IdentifierFormat>;

  constructor(private readonly idFormatService: AdminIdFormatService, private settings: SettingsService) {
    this.formatsToDisplay = [];
    this.formatAdded = new EventEmitter<IdentifierFormat>();
    this.formatDeleted = new EventEmitter<IdentifierFormat>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('formats')) {
      this.formats = changes.formats.currentValue;
      if (this.selectedCollection) {
        this.handleSelectionChange({
          collectionId: this.selectedCollection.id,
          collectionName: this.selectedCollection.name
        });
      }
    }
  }

  handleSelectionChange(collection: CollectionChangedEvent) {
    if (this.formats && collection) {
      this.selectedCollection = {
        id: collection.collectionId,
        name: collection.collectionName,
        count: 0
      };
      this.formatsToDisplay = this.formats.filter(format =>
        format.identifier_format_collection_id === collection.collectionId);
    } else {
      this.formatsToDisplay = [];
    }
  }

  async handleAddOrEditPrefixClicked(format?: IdentifierFormat): Promise<void> {
    const newFormat = await this.idFormatService.addOrEditIdentifierFormat(
      format ? format : new IdentifierFormat(null, this.settings.getClientConfig().IDENTIFIER_FORMAT_SUPEROBJECT_TYPE_ID),
      this.selectedCollection
    );
    if (newFormat) {
      this.formatAdded.emit(newFormat);
    }
  }
}
