import {Component, Input} from '@angular/core';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationStepType} from "../../core/definitions/operation-step-type.enum";

@Component({
  selector: 'app-operation-step-indicator',
  templateUrl: './operation-step-indicator.component.html',
  styleUrls: ['./operation-step-indicator.component.scss']
})
export class OperationStepIndicatorComponent {
  operationStepType = OperationStepType;

  @Input() currentOperation: OperationDef;

}
