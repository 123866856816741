import {Component, Input, OnInit} from '@angular/core';
import {transition, trigger, useAnimation} from '@angular/animations';
import {slideDownMenu, slideUpMenu} from '../../shared/animations';
import {OperationContainer} from '../../core/definitions/operation-container';

@Component({
  selector: 'app-operation-view-object',
  templateUrl: './operation-view-object.component.html',
  styleUrls: ['./operation-view-object.component.scss'],
  animations: [
    trigger('slideObjectMenuCurrent', [
      transition(':leave', [
        useAnimation(slideUpMenu, { params: {time: '750ms ease-in'}}),
      ]),

      transition(':enter', [
        useAnimation(slideDownMenu, { params: {time: '750ms ease-out'}}),
      ])
    ])
  ]
})
export class OperationViewObjectComponent implements OnInit {
  @Input() operationContainer: OperationContainer;

  constructor() { }

  ngOnInit(): void {
  }

}
