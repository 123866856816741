import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {AConst} from '../../core/a-const.enum';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {CommonsService} from '../../core/commons.service';
import {ObjectDeletionService} from '../../core/object-deletion.service';
import {BaseModel} from '../../core/definitions/base-model';
import {MetaField} from '../../core/definitions/meta-field';
import {UiToolsService} from '../../core/ui-tools.service';
import {FieldConditionService} from '../../core/field-condition.service';
import {IfType} from '../../core/definitions/field-if';

@Component({
  selector: 'app-edit-field-inline-array',
  templateUrl: './edit-field-inline-array.component.html',
  styleUrls: ['./edit-field-inline-array.component.scss']
})
export class EditFieldInlineArrayComponent implements OnChanges {

  @Input() displayRequiredIndication: boolean;
  @Input() sectionsContainer: SectionsContainer;
  @Input() field: MetaField;
  @Input() object;
  @Input() parentIndex;
  @Output() fieldOutsideTheScreen = new EventEmitter<object>();

  AConst = AConst;
  fieldParameters: FieldParameters;

  public items: BaseModel[];
  private unsorted;

  constructor(private inlineArrayItemSvc: InlineArrayItemService,
              private commons: CommonsService,
              private objectDeletionService: ObjectDeletionService,
              private uiTools: UiToolsService,
              private fieldCondition: FieldConditionService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.field = this.field;
    this.fieldParameters.object = this.object;
    this.fieldParameters.parentIndex = this.parentIndex;
    this.unsorted = this.inlineArrayItemSvc.getArrayItems(this.fieldParameters);
    this.items = this.commons.sortArray(this.unsorted, 'order_number');
    this.objectDeletionService.setObjectsDeletable(this.sectionsContainer.rootObject, this.items).then();
  }

  checkOutsideClick(item: BaseModel, evt: Event) {
    const found = this.uiTools.findClassNameRecursively(evt.target,
      'dropdownIgnoreClicksSettingButton');
    if (!found) {
      setTimeout(() => {
        item.$$activeDropdown = false;
        window.removeEventListener('mouseup', event => {
          this.checkOutsideClick(item, event);
        }, true);
      }, 100);
    }
  }

  onFieldOutsideTheScreen() {
    this.fieldOutsideTheScreen.emit();
  }

  get isDisabled(): boolean {
    return this.fieldCondition.runIf(IfType.DISABLE, this.fieldParameters).result;
  }

}
