import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SearchObjectRow} from '../core/definitions/search-object';
import {SearchExecutorService} from './search-executor.service';
import {SearchContainer} from '../core/definitions/search-container';

export class VirtualScrollSearchDataSourceColumns implements DataSource<SearchObjectRow> {
  private dataStream = new BehaviorSubject<SearchObjectRow[]>([]);
  private subscription = new Subscription();

  constructor(private searchExecutorService: SearchExecutorService, private searchContainer: SearchContainer) {

  }

  connect(collectionViewer: CollectionViewer): Observable<SearchObjectRow[] | ReadonlyArray<SearchObjectRow>> {
    this.dataStream.next(this.searchContainer.cachedScrollDataColumns);
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      this.searchExecutorService.runVirtualScrollSearch(this.searchContainer, range, true).then(hasSearchResult => {
        if (hasSearchResult) {
          this.dataStream.next(this.searchContainer.cachedScrollDataColumns);
        }
      });
    }));
    return this.dataStream;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }

}
