<div *ngIf="objectViewData" class="object-view-tabbed-sections">
  <mat-tab-group mat-align-tabs="center" color="accent">
    <mat-tab *ngFor="let section of objectViewData.sections"
             label="{{section.title}}"
             class="object-view-tabbed-sections__tab"
             id="section-{{section.name}}">
      <div class="object-view-tabbed-sections__tab-content">

        <div class="object-view-tabbed-sections__fields"
             id="field-{{section.section_fields.length}}"
             [ngClass]="{'center-fields' : section.section_fields.length <= 3}">

          <div *ngIf="section.section_fields.length === 0"
               class="object-view-tabbed-sections__no-fields">
            <span translate="TRANS__OBJECT_VIEW_TABBED_SECTIONS__NO_INFO"></span></div>

          <div *ngFor="let sectionField of section.section_fields"
               class="object-view-tabbed-sections__field-container"
               [ngClass]="{
               'object-view__action-buttons': sectionField.action_button,
               'checklist': section['name'] === 'checklist'}">

            <div class="object-view-tabbed-sections__field-inner-container">
            <app-display-field-title
              *ngIf="!sectionField.action_button"
              [titleClass]="'object-view-tabbed-sections'"
              [field]="sectionField"></app-display-field-title>
            <div *ngIf="sectionField.items" class="object-view-tabbed-sections__field-content"
                 [ngClass]="{'inline-fields': sectionField['field_type'] === 'inline'}">
              <div *ngFor="let item of sectionField.items"
                   class="object-view-tabbed-sections__field">
                <div *ngIf="item.header_values"
                     class="item-header"
                     [ngClass]="{'bold': item.header_values.level === 1}">
                  <app-object-view-field-values
                    [fieldValues]="item.header_values"></app-object-view-field-values></div>

                <app-object-view-field-checkbox
                  [sectionsContainer]="sectionsContainer"
                  [checkboxField]="item.checkbox_field">
                  <app-object-view-field-values
                    *ngIf="item.field_values"
                    [fieldValues]="item.field_values">
                  </app-object-view-field-values>
                </app-object-view-field-checkbox>
              </div>
            </div>

            <ng-container *ngIf="sectionField.action_button">
              <app-field-action-button
                class="section-field__action-button"
                [sectionsContainer]="sectionsContainer"
                [fieldTitle]="sectionField.title"
                [field]="sectionField.action_button"
              ></app-field-action-button>
            </ng-container>
            </div>
          </div>
        </div>

        <div class="object-view-tabbed-sections__go-to-button"
             *ngIf="objectViewData.object_type !== 'folder'">
            <a mat-stroked-button color="primary"
               (click)="goToObjectState(this.sectionsContainer.rootObject)"
               [title]="'TRANS__OBJECT_VIEW_TABBED_SECTIONS__MORE_INFO' | translate">
              <span translate="TRANS__OBJECT_VIEW_TABBED_SECTIONS__MORE_INFO"></span>
              <mat-icon>chevron_right</mat-icon>
            </a>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>
