import {Injectable} from '@angular/core';
import {AnnotationHandler} from './annotation-handler';
import {ObjectEditService} from '../core/object-edit.service';
import {Annotation} from './annotation';
import {CommonsService} from '../core/commons.service';
import {ChangeTrackerService} from '../core/change-tracker.service';
import {SearchParameters} from '../core/definitions/search-parameters';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {UserCacheService} from '../core/user-cache.service';
import {ImageItem} from '../core/definitions/image-item';
import {SolrFilterService} from '../core/solr-filter.service';
import {ModelRelationsService} from '../core/model-relations.service';
import {ObjectTypes} from '../core/definitions/object-types';
import {SearchService} from "../core/search.service";
import {CrudService} from "../core/crud.service";

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  constructor(private objectEditService: ObjectEditService,
              private searchService: SearchService,
              private commons: CommonsService,
              private changeTrackerService: ChangeTrackerService,
              private userCacheService: UserCacheService,
              private solrFilter: SolrFilterService,
              private modelRelationsService: ModelRelationsService,
              private crud: CrudService) {
  }

  async setCurAnnotation(curAnn: AnnotationHandler,
                         target: SuperObjectModel,
                         image: ImageItem,
                         parentObject: SuperObjectModel,
                         selectedAnnotationId: string,
                         findAnnotation: boolean) {
    let res: Annotation[];
    curAnn.annotateImage = null;
    if (findAnnotation) {
      const annotations = await this.findAnnotations(image.image_id, parentObject.artifact_id);
      if (annotations.length > 0) {
        const annotationIds = annotations.map(annotation => {
          return annotation.artifact_id;
        });
        res = await this.loadAnnotations(curAnn, target, annotationIds, selectedAnnotationId, parentObject);
      } else {
        this.initAnnotations(curAnn, target, image, parentObject, selectedAnnotationId);
      }
    } else {
      this.initAnnotations(curAnn, target, image, parentObject, selectedAnnotationId);
    }
    return res;
  }

  async saveAnnotations(curAnn: AnnotationHandler) {
    for (const annotation of curAnn.annotations) {
      if (!this.crud.getDestroy(annotation)) {
        if (this.crud.getCreate(annotation) || this.changeTrackerService.objectHasChanges(annotation)) {
          await this.objectEditService.storeObjectShowProgressModal(<SuperObjectModel>annotation);
        }
      } else {
        await this.objectEditService.deleteObjectShowDialog(annotation);
      }
    }
  }

  public async canAnnotate(object: SuperObjectModel): Promise<boolean> {
    let res = false;
    const hasAnnotationRights = await this.hasAnnotationRights();
    if (object && object.artifact_id && hasAnnotationRights) {
      res = await this.modelRelationsService.objectCanHaveObjectTypes(object, [ObjectTypes.ANNOTATION]);
    }
    return res;
  }

  public async hasAnnotationRights(): Promise<boolean> {
    const userData = await this.userCacheService.getUserData();
    return userData.edition === 'Large';
  }

  // store.loadArtifact puts annotation event in
  // scope.AnnotationEventTarget
  private async loadAnnotations(curAnn: AnnotationHandler,
                                target: SuperObjectModel,
                                annotationIds: Array<string>,
                                selectedAnnotationId: string,
                                parentObject?: SuperObjectModel) {
    const annotations = await this.objectEditService.loadObjects(annotationIds);
    const annotation = <Annotation>annotations[0];
    const image = new ImageItem();
    image.image_id = annotation.image_id;
    if (!parentObject) {
      // This will probably never happen
      parentObject = {
        artifact_id: annotation.super_artifact_id,
        artifact_name: annotation.superobject_type_id_value,
        object_type: null
      } as SuperObjectModel;
    }
    this.addSetCurAnn(
      curAnn,
      target,
      image,
      parentObject,
      annotations,
      selectedAnnotationId);
    return this.commons.sortArray(annotations, 'created_at');
  }

  private async findAnnotations(imageId: string, parentId: string): Promise<Array<Annotation>> {
    let res = [];
    const params = {sort: 'created_at'} as SearchParameters;
    this.solrFilter.addFq(params, 'object_type', 'Annotation');
    this.solrFilter.addFq(params, 'super_artifact_id', parentId);
    this.solrFilter.addFq(params, 'image_id', imageId);
    const data = await this.searchService.search(params);
    if (data.artifacts) {
      const convert = <unknown>data.artifacts;
      res = <Array<Annotation>>convert;
    } else {
      console.error('Retrieving annotations failed: ' + JSON.stringify(data));
      window.alert('An error occurred obtaining annotations');
    }
    return res;
  }

  private addSetCurAnn(curAnn: AnnotationHandler,
                       target: SuperObjectModel,
                       image: ImageItem,
                       parentObject: SuperObjectModel,
                       annotations?: Array<Annotation>,
                       selectedAnnotationId?: string) {
    curAnn.annotations = annotations ? annotations : [];
    this.setCurAnn(
      curAnn,
      curAnn.annotations,
      target,
      image,
      parentObject,
      selectedAnnotationId);
  }

  private setCurAnn(curAnn: AnnotationHandler,
                    annotationsIn: Array<Annotation>,
                    target: SuperObjectModel,
                    image: ImageItem,
                    parentObject: SuperObjectModel,
                    selectedAnnotationId: string) {
    const annotations = curAnn.getAnnotations(annotationsIn);
    curAnn.annotateImage = image;
    curAnn.target = target;
    curAnn.parentObject = parentObject;
    this.setSelectedAnnotation(curAnn, annotations, selectedAnnotationId);

    curAnn.annotationsSet = true;
    if (curAnn.canvasCallback) {
      curAnn.canvasCallback();
    }
  }

  private setSelectedAnnotation(curAnn: AnnotationHandler, annotations: Array<Annotation>, selectedAnnotationId: string) {
    if (annotations && annotations.length > 0) {
      let annotationSelected = false;
      if (selectedAnnotationId) {
        annotations.forEach(annotation => {
          if (selectedAnnotationId === annotation.artifact_id) {
            curAnn.selectedAnnotation = annotation;
            annotationSelected = true;
          }
        });
      }
      if (!annotationSelected) {
        curAnn.selectedAnnotation = annotations[0];
      }
    } else {
      curAnn.selectedAnnotation = null;
    }
  }

  private initAnnotations(curAnn: AnnotationHandler,
                          target: SuperObjectModel,
                          image: ImageItem,
                          parentObject: SuperObjectModel,
                          selectedAnnotationId: string) {
    this.addSetCurAnn(
      curAnn,
      target,
      image,
      parentObject,
      null,
      selectedAnnotationId);
  }

}
