import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {SearchObject} from '../../../core/definitions/search-object';
import {MatSort, SortDirection} from '@angular/material/sort';
import {SolrFilterService} from '../../../core/solr-filter.service';
import {merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {SearchResult} from '../../../core/definitions/search-result';
import {SearchParameters} from '../../../core/definitions/search-parameters';
import {SearchService} from "../../../core/search.service";

@Component({
  selector: 'app-admin-service-fields',
  templateUrl: './admin-service-fields.component.html',
  styleUrls: ['./admin-service-fields.component.scss']
})
export class AdminServiceFieldsComponent implements AfterViewInit {
  fieldNames: string[] = ['field_uuid', 'field_title', 'index_query_field', 'superobject_type_ids_values'];
  dataSource: SearchObject[] = [];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private searchService: SearchService,
              private solrFilter: SolrFilterService) {
  }

  ngAfterViewInit(): void {
    merge(this.sort.sortChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.getFields(this.sort.active, this.sort.direction);
        }),
        map(searchResult => {
          return searchResult.artifacts
        }),
      )
      .subscribe(fields => (this.dataSource = fields));

  }

  private async getFields(sortActive, sortDirection: SortDirection): Promise<SearchResult> {
    const searchParams = {} as SearchParameters;
    searchParams.fl = this.fieldNames;
    searchParams.rows = 10000;
    searchParams.sort = `${sortActive} ${sortDirection}`;
    this.solrFilter.addFq(searchParams, 'object_type', 'MetaField');
    return await this.searchService.search(searchParams);
  }
}
