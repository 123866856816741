import {NgModule} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {CmsApiService} from './cms-api.service';
import {AccessTokenService} from './access-token.service';
import {CmsQueueService} from './cms-queue.service';
import {ModelsService} from './models.service';
import {CommonsService} from './commons.service';
import {RefService} from './ref.service';
import {DateToolsService} from './date-tools.service';
import {FieldMetaHandlerService} from './field-meta-handler.service';
import {SettingsService} from './settings.service';
import {UserSettingsService} from './user-settings.service';
import {ModelFactoryService} from './model-factory.service';
import {UndoHandlerService} from './undo-handler.service';
import {OptionsService} from './options.service';
import {UiToolsService} from './ui-tools.service';
import {MediaHelperService} from './media-helper.service';
import {ObjectNameService} from './object-name.service';
import {ObjectViewDataService} from './object-view-data.service';
import {DragulaService} from 'ng2-dragula';
import {FileSaverModule} from 'ngx-filesaver';

@NgModule({
  exports: [],
  imports: [
    MatDialogModule,
    CommonModule,
    FileSaverModule
  ],
  providers: [
    CmsApiService,
    AccessTokenService,
    CmsQueueService,
    CommonsService,
    RefService,
    DateToolsService,
    FieldMetaHandlerService,
    OptionsService,
    ModelFactoryService,
    ModelsService,
    SettingsService,
    UndoHandlerService,
    UserSettingsService,
    UiToolsService,
    MediaHelperService,
    ObjectNameService,
    ObjectViewDataService,
    DragulaService
  ]
})
export class CoreModule {
}
