import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {CmsApiService} from './cms-api.service';
import {CommonsService} from './commons.service';
import {PrimusRouteService} from './primus-route.service';
import {Annotation} from '../image-annotation/annotation';
import {SuperObjectModel} from './definitions/super-object-model';
import {UserCacheService} from './user-cache.service';
import {SettingsService} from './settings.service';
import {PrimusRouterService} from './primus-router.service';
import {PrimusStateMapperService} from './primus-state-mapper.service';
import {OrderDownloadUrlResult} from './definitions/order-download-url-result';
import {ObjectTypes} from "./definitions/object-types";
import {MetaTypes} from "./definitions/meta-types";
import {BaseModel} from "./definitions/base-model";

export interface StateParams {
  artifactId: string;
  annotationId: string;
  contextIds: string[];
  edit: boolean;
  externalLink: string;
  imageId: string;
  isNew: boolean;
  listName: string;
  mediaId: string;
  objectCount: number;
  objectType: string;
  parentId: string;
  parentObjectType: string;
  path: string;
  quality: number,
  rootObjId: string;
  rootObjType: string;
  searchItemIndex: number;
  search_result_view: string;
  size: string;
  targetId: string;
  userId: string;
}
export class RefParams {
  object: SuperObjectModel;
  stateParams: StateParams;
  rootObjId: string;
  rootObjType: string;
  searchItemIndex: number;
  edit: boolean;
  isNew: boolean;

  constructor(object: SuperObjectModel, stateParams?: StateParams) {
    this.object = object;
    this.stateParams = stateParams;
  }
}

export class RefData {
  param: any;
  stateName: string;
  disabled: boolean;
  routerLink: any[];
  queryParams: any;

  constructor(stateName: string, param: any, disabled: boolean) {
    this.stateName = stateName;
    this.param = param;
    this.disabled = disabled;
  }
}

interface RefStuff {
  parentId: string;
  contextIds: string[];
  objectType: string;
  metaType: string;
}


@Injectable({
  providedIn: 'root'
})
export class RefService {

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private primusRoute: PrimusRouteService,
              private primusRouter: PrimusRouterService,
              private primusStateMapper: PrimusStateMapperService,
              private userCacheService: UserCacheService,
              private settingsService: SettingsService) {
  }

  public async makeDmsDownloadUrl(params: RefParams): Promise<OrderDownloadUrlResult> {
    return this.cms.orderDownloadUrl(params.object.artifact_id);
  }

  public async makeRef(params: RefParams): Promise<RefData> {
    const obj: SuperObjectModel = params.object;
    params.stateParams = params.stateParams || {} as StateParams;
    // The below statement necessary in order to correctly open
    // gallery from media content lists within artifact page

    const $stateParams = this.primusRoute.params;
    if ($stateParams.artifactId) {
      params.stateParams.parentId = $stateParams.artifactId;
    }
    if (!params.rootObjId && this.primusRoute.params.rootObjId) {
      params.rootObjId = this.primusRoute.params.rootObjId;
      params.rootObjType = this.primusRoute.params.rootObjType;
    }

    if (!obj) {
      return null;
    }
    const refStuff = await this.getRefStuff(params);
    if (!refStuff.objectType && !refStuff.metaType) {
      return null;
    }
    let stateName = this.getObjectState(refStuff.objectType, refStuff.metaType);
    const stateParams = this.getStateParamsFromRefStuff(refStuff, obj);
    if (refStuff.metaType === MetaTypes.SUB_MODEL) {
      params.rootObjId = obj.context_id;
    }
    this.setStateParamsFromRefParams(params, stateParams);
    const disabled = await this.getDisabled(refStuff, obj, stateName);
    const refData = new RefData(stateName, stateParams, disabled);
    refData.routerLink = this.primusStateMapper.routerLinkFromRefData(refData);
    refData.queryParams = this.primusStateMapper.queryParamsFromRefData(refData);
    return refData;
  }

  public getObjectState(objectType: string, metaType: string) {
    let states: any, res: any, stateType: string;
    const artifactStates = {
      regular: 'home.primus.artifact',
      search: 'home.primus.search.artifact'
    };
    const objectStates = {
      Annotation: {
        regular: 'home.primus.annotate',
        search: 'home.primus.search.annotate'
      },
      Attachment: {
        regular: 'home.primus.gallery',
        search: 'home.primus.search.gallery'
      },
      Audio: {
        regular: 'home.primus.gallery',
        search: 'home.primus.search.gallery'
      },
      Collection: {
        regular: 'home.primus.information',
        search: 'home.primus.search.information'
      },
      conceptType: {
        regular: 'home.primus.search',
        search: 'home.primus.search'
      },
      folder: {
        regular: 'home.primus.search',
        search: 'home.primus.search'
      },
      GenericEvent: {
        regular: 'home.primus.information',
        search: 'home.primus.search.information'
      },
      Image: {
        regular: 'home.primus.gallery',
        search: 'home.primus.search.gallery'
      },
      Location: {
        regular: 'home.primus.information',
        search: 'home.primus.search.information'
      },
      TextBlock: {
        regular: 'home.primus.information',
        search: 'home.primus.search.information'
      },
      user: {
        regular: 'user-profile',
        search: 'user-profile', // not really relevant, users should never be displayed in search
      },
      Video: {
        regular: 'home.primus.videoGallery',
        search: 'home.primus.search.videoGallery'
      },
      Settings: {
        regular: 'settings',
        search: 'settings'
      },
      AdmEventContextItem: artifactStates
    };
    objectStates[AConst.ARTIFACT] = artifactStates;
    states = objectStates[objectType];
    if (!states) {
      if (metaType === MetaTypes.SPECTRUM_PROCEDURE) {
        states = {
          regular: 'home.primus.procedure',
          search: 'home.primus.search.procedure'
        };
      } else {
        states = artifactStates;
      }
    }

    if (this.primusRouter.currentState().indexOf('search') !== -1) {
      stateType = 'search';
    } else {
      stateType = 'regular';
    }
    res = states[stateType];
    if (!res) {
      throw new Error('No search state defined for \'' + stateType + '\'');
    }
    return res;
  }

  private async getRefStuff(params: RefParams): Promise<RefStuff> {
    let contextIds: string[], parentId: string;
    const obj = params.object;
    const objectType = await this.commons.getObjectType(obj);
    if (obj.contexts) {
      contextIds = this.commons.getContextIds(obj.contexts);
    } else if (obj.context_id) {
      contextIds = [obj.context_id];
    } else if (params.stateParams?.contextIds) {
      contextIds = params.stateParams.contextIds;
    }
    if (obj.parent_id) {
      parentId = obj.parent_id;
    } else if (params.stateParams?.parentId) {
      parentId = params.stateParams.parentId;
    }
    if (!obj.meta_type) {
      if (objectType.startsWith('SpectrumProcedure')) {
        obj.meta_type = MetaTypes.SPECTRUM_PROCEDURE;
      }
    }
    return {
      parentId: parentId,
      contextIds: contextIds,
      objectType: objectType,
      metaType: obj.meta_type
    };
  }

  private getStateParamsFromRefStuff(refStuff: RefStuff, obj: BaseModel) {
    let stateParams: StateParams;
    if (refStuff.metaType === MetaTypes.SUB_MODEL) {
      stateParams = {
        artifactId: obj.context_id
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.IMAGE) {
      stateParams = {
        mediaId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        size: 'large',
        quality: 90,
        objectType: ObjectTypes.IMAGE
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.VIDEO) {
      stateParams = {
        mediaId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        objectType: ObjectTypes.VIDEO
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.AUDIO) {
      stateParams = {
        mediaId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        objectType: ObjectTypes.AUDIO
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.ATTACHMENT) {
      stateParams = {
        mediaId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        objectType: ObjectTypes.ATTACHMENT
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.ANNOTATION) {
      const conversion = <unknown>obj;
      const annotation = <Annotation>conversion;
      stateParams = {
        annotationId: annotation.artifact_id,
        imageId: annotation.image_id,
        parentId: annotation.super_artifact_id,
        edit: true
        // edit: !annotation.artifact_id
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.FOLDER) {
      stateParams = this.getFolderStateParams(obj);
    } else if (refStuff.metaType === MetaTypes.SPECTRUM_PROCEDURE) {
      stateParams = {
        artifactId: obj.artifact_id,
        listName: 'spectrum_procedure_object'
      } as StateParams;
    } else if (refStuff.metaType === 'information' ||
      refStuff.objectType === ObjectTypes.LOCATION ||
      refStuff.objectType === ObjectTypes.GENERIC_EVENT) {
      stateParams = {
        artifactId: obj.artifact_id,
        listName: 'overview'
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.USER) {
      stateParams = {
        userId: obj.artifact_id
      } as StateParams;
    } else if (refStuff.objectType === ObjectTypes.COLLECTION) {
      stateParams = {
        artifactId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds
      } as StateParams;
    } else if ([
      ObjectTypes.AUTHORITY.toString(),
      ObjectTypes.KULTURNAV_FOLDER.toString()].includes(refStuff.objectType)) {
      stateParams = {
        externalLink: `https://kulturnav.org/${obj.artifact_id}`
      } as StateParams;
    } else {
      stateParams = {
        artifactId: obj.artifact_id,
        parentId: refStuff.parentId,
        contextIds: refStuff.contextIds,
        listName: 'overview'
      } as StateParams;
    }
    return stateParams;
  }

  async getDisabled(refStuff: RefStuff, obj: BaseModel, stateName: string): Promise<boolean> {
    let disabled = false;
    if (refStuff.objectType === ObjectTypes.COLLECTION) {
      const clientConfig = this.settingsService.getClientConfig();
      if (obj.artifact_id === clientConfig.DEFAULT_COLLECTION_ID) {
        disabled = true;
      }
    }
    if (stateName.indexOf('.admin.') !== -1) {
      const user = await this.userCacheService.getUserData();
      disabled = user.rights_level !== 'ADMIN';
    }
    return disabled;
  }

  setStateParamsFromRefParams(params: RefParams, stateParams: StateParams) {
    if (params.searchItemIndex !== undefined) {
      stateParams.searchItemIndex = params.searchItemIndex;
    }
    if (params.rootObjId) {
      stateParams.rootObjId = params.rootObjId;
      stateParams.rootObjType = params.rootObjType;
    }

    if (params.edit) {
      stateParams.edit = true;
    } else {
      stateParams.edit = null;
    }
    if (params.isNew) {
      stateParams.isNew = true;
    }
    // Used for prev/next search object functionality
    if (params.stateParams) {
      Object.keys(params.stateParams).forEach(key => {
        if (!stateParams[key]) {
          stateParams[key] = params.stateParams[key];
        }
      });
    }
  }

  private getFolderStateParams(obj: BaseModel): StateParams {
    const stateParams = {
      targetId: obj.artifact_id,
      path: 'home/folders/folder',
      objectCount: 0,
      search_result_view: 'thumbnail'
    } as StateParams;
    // Special handling of media folders
    const mediaFolderTypeId = this.settingsService.getClientConfig().FOLDER_TYPE_MEDIA_ID;
    const folderTypeId = obj['folder_type_id'];
    if (mediaFolderTypeId && folderTypeId && folderTypeId.indexOf(mediaFolderTypeId) !== -1) {
      stateParams.path = 'home/media_folders/folder';
    }
    return stateParams;
  }
}
