<div class="query-builder-container">

  <mat-accordion style="position: sticky; top: 0;">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <b>{{ 'TRANS__ADVANCED_SEARCH__FILTER_SECTION_HEADING' | translate }}</b>
        <span>:</span>
        <span *ngIf="deletedStateValue === 'NOT_DELETED'">{{ 'TRANS__ADVANCED_SEARCH__FILTER__DELETED_STATE__NOT_DELETED' | translate }}</span>
        <span *ngIf="deletedStateValue === 'DELETED'">{{ 'TRANS__ADVANCED_SEARCH__FILTER__DELETED_STATE__DELETED_ONLY' | translate }}</span>
        <span *ngIf="deletedStateValue === 'ALL'">{{ 'TRANS__ADVANCED_SEARCH__FILTER__DELETED_STATE__ALL' | translate }}</span>
        <span *ngIf="selectedArtifactIdsControl.value">:</span>
        <span>{{ selectedArtifactNames$ | async }}</span>
      </mat-expansion-panel-header>

      <mat-divider></mat-divider>

      <div class="filter-label">
        {{ 'TRANS__ADVANCED_SEARCH__FILTER__DELETED_STATE_LABEL' | translate }}
      </div>

      <mat-button-toggle-group [(value)]="deletedStateValue"
                               (valueChange)="handleDeletedStateChanged()">
        <mat-button-toggle value="NOT_DELETED">
          {{ 'TRANS__ADVANCED_SEARCH__FILTER__DELETED_STATE__NOT_DELETED' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="DELETED">
          {{ 'TRANS__ADVANCED_SEARCH__FILTER__DELETED_STATE__DELETED_ONLY' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="ALL">
          {{ 'TRANS__ADVANCED_SEARCH__FILTER__DELETED_STATE__ALL' | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="filter-label">{{ 'TRANS__ADVANCED_SEARCH__FILTER__SELECT_OBJECT_TYPE_HINT' | translate }}</div>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{ 'TRANS__ADVANCED_SEARCH__FILTER__SELECT_OBJECT_TYPE_LABEL' | translate }}</mat-label>
        <mat-select [formControl]="selectedArtifactIdsControl" multiple>
          <mat-optgroup *ngFor="let group of selectableArtifactIds"
                        [label]="group.groupName">
            <mat-option *ngFor="let option of group.options" [value]="option">{{ option.name }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

    </mat-expansion-panel>
  </mat-accordion>

  <div class="predicate-group-wrapper"
       *ngFor="let group of queryService.getCurrentQuery()"
       [cdkDropListData]="group"
       (cdkDropListDropped)="onDrop($event)"
       cdkDropList>

    <div class="operand-selector-wrapper">
      <button class="operand-selector"
              mat-stroked-button
              color="accent"
              *ngFor="let p of queryService.getAvailableOperands()"
              [ngClass]="{'active': p.operand === group.operand}"
              (click)="changeOperand(group, p.operand)">
        {{ p.label | translate }}
      </button>
    </div>

    <div class="predicate-group predicate-group-color" (click)="selectGroup($event, group)">

      <button mat-icon-button
              type="button"
              [disabled]="!enableRemoveGroupButton()"
              class="close-group-button"
              [matTooltip]="'TRANS__ADVANCED_SEARCH__REMOVE_GROUP_TOOLTIP' | translate"
              (click)="removeGroup($event, group)">
        <mat-icon>close</mat-icon>
      </button>

      <div class="predicate-field-wrapper"
           *ngFor="let field of group.queryFields"
           [cdkDragData]="field"
           [cdkDragDisabled]="deviceType === 'mobile'"
           [cdkDragStartDelay]="deviceType === 'tablet' ? 300 : 0"
           (click)="$event.stopPropagation()"
           cdkDrag>

        <div class="operand-selector-wrapper">
          <button mat-button
                  *ngFor="let p of queryService.getAvailableOperands()"
                  [ngClass]="{'active': p.operand === field.operand}"
                  (click)="changeOperand(field, p.operand)">
            {{ p.label | translate }}
          </button>
        </div>

        <div class="predicate-field">
          <i class="icon-expand drag-icon"></i>

          <span class="field-title" [matTooltip]="field.label + ' ' + field.secondaryLabel">
            <span>{{field.label}}</span>
            <span class="alternate-name" *ngIf="field.secondaryLabel">
              ({{ field.secondaryLabel }})</span>
          </span>

          <span class="input-container">
            <app-input [field]="field"
                       [useFreeTextSearch]="queryService.isFreeTextMode(field)"
                       (searchValueChanged)="handleSearchValueChanged(field, $event)">
            </app-input>
          </span>

          <span class="field-config-container">

            <mat-slide-toggle [checked]="queryService.isFreeTextMode(field)"
                              *ngIf="queryService.canUseFreeTextSearch(field)"
                              (toggleChange)="queryService.toggleFreeTextMode(field)">
              {{ 'TRANS__ADVANCED_SEARCH__FREE_TEXT_SEARCH_PLACEHOLDER' | translate }}
            </mat-slide-toggle>

          </span>

          <span class="remove-field-button-container">
            <button mat-icon-button
                    type="button"
                    color="warn"
                    [matTooltip]="'TRANS__ADVANCED_SEARCH__REMOVE_FIELD_TOOLTIP' | translate"
                    (click)="removeField($event, group, field)">
              <mat-icon>delete</mat-icon>
            </button>
          </span>
        </div>


        <div class="advanced-search-drag-preview" *cdkDragPreview>
          <span class="field-title">{{ field.label }}</span>
        </div>

        <div class="predicate-field-wrapper" *cdkDragPlaceholder>
          <div class="predicate-field">
            <span class="field-title">{{ field.label }}</span>
          </div>
        </div>

      </div>

      <span class="no-content-message" *ngIf="!group.queryFields.length && deviceType !== 'mobile'">
        {{ 'TRANS__ADVANCED_SEARCH__NO_FIELDS_SELECTED' | translate }}
      </span>

      <span class="no-content-message" *ngIf="deviceType === 'mobile'">
        {{ 'TRANS__ADVANCED_SEARCH__ADD_FIELDS_LABEL' | translate }}
      </span>

    </div>

  </div>

</div>
