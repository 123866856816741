import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {AConst} from './a-const.enum';
import {CmsQueueService} from './cms-queue.service';
import {UserData} from './definitions/user-data';
import {ClientConfig} from "./definitions/client-config";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private cfg: ClientConfig = null;
  private modelIcons = null;

  constructor(private cms: CmsApiService,
              private cmsQueue: CmsQueueService) {
  }

  public getClientConfig(): ClientConfig {
    if (!this.cfg) {
      console.warn('Client config has not been retrieved yet!');
    }
    return this.cfg;
  }

  public async initSettings() {
    return new Promise((resolve, reject) => {
      let user: UserData = null;
      this.cms.init(console.error);
      this.cmsQueue.runCmsFnWithQueue(this.cms.getClientConfig, undefined, false,
        (data: ClientConfig) => {
          this.cfg = data;
          if (user) {
            this.cfg.user = user;
          }
          resolve(data);
        },
        e => reject(e)).then();
    });
  }

  private async getModelIcons() {
    if (!this.modelIcons) {
      this.modelIcons = await this.cms.getModelIcons();
    }
    return this.modelIcons || {};
  }

  public async objectIcon(objectType, size?, object?, noDefault?) {
    const contextType = this.getContextType(objectType)
    const objIcons = await this.getModelIcons();
    let icon;
    objectType = objectType || AConst.THING;
    if (objectType === 'Attachment' && object) {
      const attachmentType = object[AConst.ATTACHMENT_TYPE];
      icon = objIcons[attachmentType];
      icon = icon ? icon : objIcons[objectType];
    } else {
      icon = objIcons[objectType];
    }
    icon = !icon && contextType ? objIcons[contextType] : icon;
    icon = !icon && !noDefault ? objIcons[AConst.THING] : icon;
    if (icon && size) {
      icon += ' fa-' + size + 'x';
    }
    return icon;
  }

  private getContextType(object) {
    let contextType;
    if (object) {
      contextType = object[AConst.CONTEXT_OBJECT_TYPE];
      contextType = Array.isArray(contextType) ? contextType[0] : contextType;
    }
    return contextType;
  }

}
