<div class="advanced-search-query" id="search-query">
  <span class="advanced-search-query__title">
    {{getHeaderText()}}
  </span>

  <button class="advanced-search-query__help-button" mat-icon-button [matMenuTriggerFor]="advancedSearchTooltip">
    <i class="icon-help"></i>
  </button>

  <button class="advanced-search-query__fab-button" mat-fab extended="true" (click)="scrollToLocation()">
    @if(scrollTop === 0) {
      <mat-icon>arrow_downward</mat-icon>
    }
    @else {
      <mat-icon>arrow_upward</mat-icon>
    }
    <span [translate]="scrollTop === 0 ? 'TRANS__ADVANCED_SEARCH__TO_RESULT' : 'TRANS__ADVANCED_SEARCH__TO_TOP'"></span>
  </button>

  <div class="advanced-search-query__searchsuggestions">
    <button mat-button [matMenuTriggerFor]="searchSuggestionsMenu" class="advanced-search-query__searchsuggestion-button">
      <span translate="TRANS__ADVANCED_SEARCH__SEARCH_SUGGESTIONS"></span>
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
  </div>

  <mat-menu #advancedSearchTooltip="matMenu" xPosition="after" yPosition="below" class="advanced-search-tooltip">
    <div class="advanced-search-tooltip__inner-container">
      <div class="advanced-search-tooltip__title">
        <span class="bold" translate="TRANS__ADVANCED_SEARCH__HELP__TITLE"></span>
      </div>

      <div class="advanced-search-tooltip__text" translate="TRANS__ADVANCED_SEARCH__HELP__CONTENT">
      </div>
    </div>
  </mat-menu>

  <mat-menu #searchSuggestionsMenu="matMenu" xPosition="after" yPosition="below">
    @for (suggestion of searchSuggestions; track $index) {
      <button mat-menu-item (click)="setAdvancedSearch(suggestion.advancedSearchParams)">
        {{suggestion.label}}
      </button>
    }
  </mat-menu>
</div>
