export enum IfType {
  DISABLE = 'disable',
  EDIT = 'edit',
  FIELD_WARNING = 'field_warning',
  SET_SELECTED = 'set_selected',
  SHOW = 'show'
}

export class FieldIf {
  if_type: IfType;
  conditions: Array<IfCondition>;
  operator: string;
  if_then: Array<IfThen>;
  if_else: Array<IfElse>;
  reason_field: string;
  field_warning_text: string;
}

export class IfCondition {
  cond_type: string;
  field: string;
  value: any;
  values: Array<any>;
  comparator: string;
  compare_field: string;
  backend_api: string;
}

export class IfThen {
  if_then_type: string;
  value: any;
}

export class IfElse {
  if_else_type: string;
  value: any;
}

export enum IfThenType {
  SET_DEFAULT_VAL = 'set_default_val'
}
