import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatMenuTrigger} from "@angular/material/menu";
import {MatDialog} from "@angular/material/dialog";
import {SelectFieldDialogComponent} from "./select-field-dialog/select-field-dialog.component";
import {ImportField, ImportService} from "./import.service";
import {EditImportFieldDialogComponent} from "./edit-import-field-dialog/edit-import-field-dialog.component";
import {FormGroup} from "@angular/forms";

export interface SelectFieldTypeDialogData {
  column: string;
  field: string;
}

export interface ImportFieldDialogData {
  columns: string[];
  importField: ImportField;
}

@Component({
  selector: 'app-admin-service-import',
  templateUrl: './admin-service-import.component.html',
  styleUrls: ['./admin-service-import.component.scss']
})
export class AdminServiceImportComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  mainOptions: ImportField;
  formGroup: FormGroup;
  importFields: ImportField[] = [];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  constructor(private dialog: MatDialog,
              private importService: ImportService) {
  }

  ngOnInit() {
    this.importService.getMainOptions().then(mainOptions => {
      this.mainOptions = mainOptions;
      this.formGroup = this.importService.createFormGroup(this.mainOptions);
    })
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  // Add menu properties
  columnFieldData: {[name: string]: ImportField} = {};
  handleFileInput(files: FileList): void {
    const file = files.item(0);
    if (file) {
      const reader = new FileReader();
      reader.onload = (/*e*/) => {
        let csvData: string;
        if (typeof reader.result !== 'string') {
          return;
        }
        csvData = reader.result.toString();
        const rows = csvData.split('\n');
        const headers = rows[0].split(';');
        this.displayedColumns = headers;
        const data = [];

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i].split(';');
          const rowData: any = {};
          for (let j = 0; j < headers.length; j++) {
            rowData[headers[j]] = row[j];
          }
          data.push(rowData);
        }

        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      };

      reader.readAsText(file);
    }
  }

  addField() {
    const dialogRef = this.dialog.open(SelectFieldDialogComponent, {
      data: {
        field: ''
      } as SelectFieldTypeDialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const importField = JSON.parse(JSON.stringify(result));
        this.openEditImportFieldData(importField);
      }
    });
  }

  openEditImportFieldData(importField: ImportField) {
    for (const mapField of importField.field_map) {
      mapField.columns = this.displayedColumns;
      mapField.filtered_columns = this.displayedColumns;
    }
    const dialogRef = this.dialog.open(EditImportFieldDialogComponent, {
      data: {
        columns: this.displayedColumns,
        importField: importField,
      } as ImportFieldDialogData
    });
    dialogRef.afterClosed().subscribe((result: ImportField) => {
      if (result) {
        if (!this.importFields.find(existing => this.compareExisting(existing, result))) {
          this.importFields.push(importField);
        }
        this.setColumnsSet();
      }
    });
  }

  getImportFieldTitle(importField: ImportField) {
    return `${importField.field_title} (${importField.field_map.filter(
      mapField => !!mapField.import_file_field_name).map(
        mapField => mapField.import_file_field_name).join(', ')})`
  }

  private compareExisting(importField: ImportField, existing: ImportField) {
    let res = false;
    if (importField.field_id === existing.field_id) {
      let compareCount = 0;
      for (const mapField of importField.field_map) {
        if (existing.field_map.find(existingMapField =>
          mapField.import_file_field_name === existingMapField.import_file_field_name)) {
          compareCount++;
        }
      }
      res = compareCount === importField.field_map.length;
    }
    return res;
  }

  private setColumnsSet() {
    for (const importField of this.importFields) {
      for (const mapField of importField.field_map) {
        if (mapField.import_file_field_name) {
          this.columnFieldData[mapField.import_file_field_name] = importField;
        }
      }
    }
  }
}
