import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {SuperObjectModel} from './definitions/super-object-model';

@Injectable({
  providedIn: 'root'
})
export class ModelRelationsService {

  modelRelations = {};

  constructor(private cms: CmsApiService) {
  }

  // Check whether one or more objects have model relations towards one or more object types and returns true if so
  async objectsCanHaveObjectTypes(objects: SuperObjectModel[], objectTypes: string[]): Promise<boolean> {
    let res = false;
    for (let t = 0 ; t < objects.length ; t++) {
      const object = objects[t];
      res = await this.objectCanHaveObjectTypes(object, objectTypes);
      if (res) {
        break;
      }
    }
    return res;
  }

  // Check whether an object has model relations towards one or more object types and returns true if so
  async objectCanHaveObjectTypes(object: SuperObjectModel, objectTypes: string[]): Promise<boolean> {
    const objectType = object.object_type;
    if (objectType.indexOf('ct_') !== 0) {
      if (!this.modelRelations[objectType]) {
        this.modelRelations[objectType] = await this.cms.getModelRelations({modelName: objectType});
      }
      return this.isObjectTypesInModelRelations(this.modelRelations[objectType], objectTypes);
    } else {
      return false;
    }
  }

  private isObjectTypesInModelRelations(modelRelations, objectTypes: string[]): boolean {
    let res = false;
    if (modelRelations) {
      for (let t = 0 ; t < objectTypes.length ; t++) {
        const objectType = objectTypes[t];
        if (modelRelations['object_types'].indexOf(objectType) !== -1) {
          res = true;
          break;
        }
      }
    }
    return res;
  }

}
