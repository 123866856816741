import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Media} from '../../core/definitions/media';

@Component({
  selector: 'app-thumbnail-carousel',
  templateUrl: './thumbnail-carousel.component.html',
  styleUrls: ['./thumbnail-carousel.component.scss']
})
export class ThumbnailCarouselComponent implements OnChanges {
  @Input() media: Media[];
  @Input() mediaIndex: number;
  @Input() currentMediaType;
  @Input() thumbnailWidth;
  @Output() setActiveImg = new EventEmitter<object>();

  constructor() {
  }

  ngOnChanges() {}


  public setMedia(index) {
    const mediaIndex = {
      index: index
    };
    this.setActiveImg.emit(mediaIndex);
  }
}
