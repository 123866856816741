import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {OptionsService} from '../core/options.service';
import {SearchParams} from '../core/search-params';
import {Option} from '../core/definitions/option-info';

export class VirtualScrollEditDataSourceList implements DataSource<Option> {
  private readonly dataStream = new BehaviorSubject([]);
  private readonly subscription = new Subscription();

  private searching = false;
  private loading = true;

  constructor(
    private optionsService: OptionsService,
    private params: SearchParams,
  ) {
    this.optionsService.searchOptions(this.params, null, this.dataStream.value)
      .then((options) => {
        this.dataStream.next(options);
        this.loading = false;
      });
  }

  /**
   * Triggers when we open the view
   * @param collectionViewer
   */
  connect(collectionViewer: CollectionViewer): Observable<Option[]> {
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      if (!this.searching) {
        this.optionsService.searchOptions(this.params, range, this.dataStream.value)
          .then((options) => {
            this.dataStream.next(options);
          });
      }
    }));
    return this.dataStream;
  }

  /**
   * Triggers when we close the view
   * @param collectionViewer
   */
  disconnect(collectionViewer: CollectionViewer): void {}

  /**
   * Method used to query, by setting the 'params.query' to the input
   * @param query string value of the desired query
   */
  searchByQuery(query: String) {
    this.searching = true;
    this.params.query = query;
    this.optionsService.searchOptions(this.params)
      .then((options: Option[]) => {
        this.dataStream.next(options);
      }).then(() => this.searching = false);
  }

  /**
   * Method return the value corresponding to the given index
   * @param selectedRow index
   */
  getIndex(selectedRow: number) {
    return this.dataStream.value[selectedRow];
  }

  /**
   * Call this method to unsubscribe all subscriptions.
   * We use this method instead of disconnect, because disconnect triggers when we click on the input, tries to
   * search , click "hide" or outside the select.
   */
  unsubscribe() {
    this.subscription.unsubscribe();
  }

  /**
   * @return dataStream as Observable()
   */
  asObservable(): Observable<any[]> {
    return this.dataStream.asObservable();
  }

  isLoading(): Boolean {
    return this.loading;
  }
}
