import {FieldParameters} from '../core/definitions/field-parameters';
import {ActionButtonField} from '../core/definitions/object-view';
import {EventEmitter} from '@angular/core';

export class FieldActionParameters extends FieldParameters {
  trigger: string;
  newData: any;
  field: ActionButtonField;
  closeEdit: EventEmitter<any>;
  edit: boolean;
}
