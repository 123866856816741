import {Injectable} from '@angular/core';

export class AppNotification {
  constructor(messages: Array<string>, type: string, values?: object, status?: number) {
    this.messages = messages;
    this.type = type;
    this.values = values;
    this.status = status;
  }

  messages: Array<string>;
  type: string;
  values: object;
  status: number;
  correlationId: string;
  _id: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private appNotifications: Array<AppNotification> = [];
  private notificationTime = 0;

  // Wait 3 seconds before auto-dismissing notification.
  private autoDismissDelay = 3 * 1000;

  // Auto-dismiss notifications of these types.
  private autoDismissTypes = ['success', 'info', 'warning'];

  constructor() {
  }

  get notifications(): Array<AppNotification> {
    return this.appNotifications;
  }

  addNotification(notification: AppNotification) {
    const mxLen = 3;
    const notTime = new Date().getTime();
    let mustDelay = false, delay = 1000;
    if (this.appNotifications.length > 0) {
      mustDelay = notTime < this.notificationTime;
      if (mustDelay) {
        delay += this.notificationTime - notTime;
      }
    }
    this.notificationTime = notTime + delay;
    setTimeout(() => {
      if (this.appNotifications.length > mxLen) {
        this.appNotifications.splice(0, this.appNotifications.length - mxLen);
        mustDelay = true;
      }
      // Referencing this ID when auto-dismissing success-notifications
      notification._id = this.appNotifications.length;

      this.appNotifications.push(notification);
    }, mustDelay ? delay : 0);

    if (notification.type in this.autoDismissTypes) {
      setTimeout(() => {
        this.appNotifications = this.autoDismissSuccess(this.appNotifications, notification._id);
      }, this.autoDismissDelay);
    }
  }

  removeNotification(index) {
    this.appNotifications.splice(index, 1);
  }

  private autoDismissSuccess(notifications, id) {
    const ix = notifications.findIndex(function (n) {
      return (n._id === id);
    });

    notifications.splice(ix, 1);
    return notifications;
  }

}
