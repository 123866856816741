<div class="action-toolbar-dialog">

  <div class="action-toolbar-dialog__content">
<!--    [ngClass]="{'errorInputFrame errorNoBorder':isInvalid}"-->

    <div class="action-toolbar-dialog__buttons-container">
      <div *ngFor="let action of toolbarActions"
           class="action-toolbar-dialog__button"
           [matTooltip]="getTooltipText(action) | translate"
           [matTooltipDisabled]="!isDisabled(action)">

        <button mat-flat-button
                color="primary"
                *ngIf="action.action_type !== 'cancelRegister'"
                (click)="runAction(action)"
                [disabled]="isDisabled(action)">
          <span class="bold" [translate]="action.button_text"></span></button>
        <button mat-button
                *ngIf="action.action_type === 'cancelRegister'"
                (click)="runAction(action)"
                color="primary">
          <span class="bold" [translate]="action.button_text"></span></button>
      </div>
    </div>

    <app-operation-step-indicator *ngIf="!isCompleted"
      [currentOperation]="operationContainer.currentOperation"></app-operation-step-indicator>

    <div class="action-toolbar-dialog__error-container" *ngIf="!isCompleted">

      <!-- If isInvalid -->
      <span class="" *ngIf="isInvalid; else valid">
        <span class="bold error-text-medium-up"
              translate="TRANS__SAVE_TOOLBAR__EXIST"></span>
        <span>&nbsp;</span>
        <span class="bold">{{errorCount}} </span>
        <span>&nbsp;</span>
        <span class="bold"
              translate="TRANS__SAVE_TOOLBAR__ERROR"></span>
        <span>&nbsp;</span>
        <span class="bold error-text-medium-up"
              translate="TRANS__SAVE_TOOLBAR__IN_FORM"></span>
        <span>&nbsp;</span>
        <span class="error-help-text"
              translate="TRANS__SAVE_TOOLBAR__HELP_TEXT"></span>
<!--        <span>&nbsp; | &nbsp;</span>-->
<!--        <span (click)="nextError()"-->
<!--              class="error-link">-->
<!--          <span translate>TRANS__SAVE_TOOLBAR__SHOW</span>-->
<!--          <span>&nbsp;</span>-->
<!--          <span class="error-link-text"-->
<!--                translate>TRANS__SAVE_TOOLBAR__NEXT_ERROR</span>-->
<!--        </span>-->
      </span>&nbsp;

      <!-- Else if valid -->
      <ng-template #valid>
        <span class="bold error-text-medium-up"
              translate="TRANS__SAVE_TOOLBAR__EXIST"></span>
        <span>&nbsp;</span>
        <span class="bold">{{errorCount}} </span>
        <span>&nbsp;</span>
        <span class="bold"
              translate="TRANS__SAVE_TOOLBAR__ERROR"></span>
        <span>&nbsp;</span>
        <span class="bold error-text-medium-up"
              translate="TRANS__SAVE_TOOLBAR__IN_FORM"></span>
        <span>&nbsp;</span>
      </ng-template>

    </div>

  </div>
</div>
