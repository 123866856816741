import {Injectable} from '@angular/core';
import {InlineViewItem} from "./definitions/inline-view-item";
import {CmsApiService} from "./cms-api.service";
import {LoggerService} from "./logger.service";
import {MetaField} from "./definitions/meta-field";
import {CmsQueueService} from "./cms-queue.service";
import {BaseModel} from "./definitions/base-model";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class InlineViewService {

  private inlineViewCache: { [object_type: string]: InlineViewItem[] } = {};
  private missingInlineViewsObjectTypes: string[] = [];

  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private cmsQueueService: CmsQueueService,
              private translateService: TranslateService) {
  }

  async getInlineViewForField(object: BaseModel, field: MetaField): Promise<InlineViewItem> {
    const objectType = object.object_type;
    if (this.missingInlineViewsObjectTypes.indexOf(objectType) !== -1) {
      return null;
    }
    let res: InlineViewItem;
    let inlineView = this.inlineViewCache[objectType];
    if (inlineView === undefined) {
      inlineView = await this.getInlineView(objectType);
      this.inlineViewCache[objectType] = inlineView;
      // Only display warning for inline array objects
      if (!inlineView && !object.is_root_model && object.order_number !== undefined) {
        this.logger.warn(`No inline view data for ${objectType}`);
        this.missingInlineViewsObjectTypes.push(objectType);
      }
    }
    if (inlineView) {
      res = inlineView.find(item => item.field === field.name);
    }
    return res;
  }

  async getInlineView(objectType: string): Promise<InlineViewItem[]> {
    return new Promise(resolve => {
      if (this.missingInlineViewsObjectTypes.indexOf(objectType) !== -1) {
        resolve(null);
        return;
      }
      let inlineView = this.inlineViewCache[objectType];
      if (inlineView) {
        resolve(inlineView);
        return;
      }
      this.cmsQueueService.runCmsFnWithQueue(this.cms.getInlineView, {objectType: objectType}, false,
        (inlineView: InlineViewItem[]) => {
          if (inlineView) {
            this.inlineViewCache[objectType] = inlineView;
          } else {
            this.missingInlineViewsObjectTypes.push(objectType);
          }
          resolve(inlineView);
        }, (e: any) => {
          if (e.status === 404) {
            this.logger.warn('Inline view API does not seem to be implemented on backend yet.');
          }
          this.logger.error(`Unable to get inline view: ${JSON.stringify(e)}`);
          resolve(null);
        });
    });
  }

  // Generates inline view texts from object data and inline view metadata.
  // Why not use this all places using inline views? Because it lacks link generation
  async generateInlineViewString(object: BaseModel): Promise<string> {
    let res = '';
    if (this.missingInlineViewsObjectTypes.indexOf(object.object_type) !== -1) {
      return res;
    }
    let inlineView = this.inlineViewCache[object.object_type];
    if (!inlineView) {
      inlineView = await this.getInlineView(object.object_type);
      this.inlineViewCache[object.object_type] = inlineView;
    }
    for (const inlineViewItem of inlineView) {
      const value = object[inlineViewItem.field];
      if (value === undefined || value === null) {
        continue;
      }
      res += this.getAppendPrefixValues(inlineViewItem.prefix);
      res += value
      res += this.getAppendPrefixValues(inlineViewItem.append);
    }
    return res;
  }

  private getAppendPrefixValues(dix: string[]) {
    let res = '';
    if (!dix) {
      return res;
    }
    for (const part of dix) {
      if (part.startsWith('TRANS__')) {
        res += this.translateService.instant(part);
      } else {
        res += part;
      }
    }
    return res;
  }
}
