import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CmsApiService} from '../../../core/cms-api.service';
import {IdentifierFormat} from '../identifier-format';
import {Collection} from '../collection';
import {AdminIdFormatService} from '../admin-id-format.service';

export interface CollectionChangedEvent {
  collectionId: string;
  collectionName: string;
}

@Component({
  selector: 'app-admin-id-format-collection-list',
  templateUrl: './admin-id-format-collection-list.component.html',
  styleUrls: ['./admin-id-format-collection-list.component.scss']
})
export class AdminIdFormatCollectionListComponent implements OnInit, OnChanges {

  @Input() formats: Array<IdentifierFormat>;

  @Output() public readonly selectionChange: EventEmitter<CollectionChangedEvent>;

  collections: Array<Collection>;

  constructor(private readonly cmsApi: CmsApiService,
              private readonly idFormatService: AdminIdFormatService) {
    this.selectionChange = new EventEmitter<CollectionChangedEvent>();
    this.formats = [];
    this.collections = [];
  }

  public async ngOnInit(): Promise<void> {
    this.collections = await this.idFormatService.getAllCollections();
    this.recountFormatsInCollection();
    this.handleSelectionChange(this.collections[0]); // Select the first, so that it corresponds to visual on list.
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('formats')) {
      this.formats = changes.formats.currentValue;
      this.recountFormatsInCollection();
    }
  }

  private recountFormatsInCollection(): void {
    this.idFormatService.updateCollectionCount(this.collections, this.formats);
  }

  handleSelectionChange(collection: Collection): void {
    this.selectionChange.emit({
      collectionId: collection.id,
      collectionName: collection.name
    });
  }
}
