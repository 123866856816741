@if (canShow()) {
  @if (field.input_type !== FieldInputType.COMPARE_VALUE) {
    <app-edit-field
      [ngClass]="{'moreThanOneSubField': subFieldIndex > 0}"
      [displayRequiredIndicaton]="displayRequiredIndication"
      [sectionsContainer]="sectionsContainer"
      [grandParentObject]="grandParentObject"
      [object]="object"
      [field]="field"
      [index]="index"
      [parentIndex]="parentIndex"
    ></app-edit-field>
  } @else {
    <app-edit-field-compare
      [ngClass]="{'moreThanOneSubField': subFieldIndex > 0}"
      [displayRequiredIndication]="displayRequiredIndication"
      [sectionsContainer]="sectionsContainer"
      [field]="field"
      [parentIndex]="parentIndex"
      [index]="index"
    ></app-edit-field-compare>
  }
}
