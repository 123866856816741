import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImportFieldDialogData} from "../admin-service-import.component";
import {ImportField, ImportService} from "../import.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-edit-import-field-dialog',
  templateUrl: './edit-import-field-dialog.component.html',
  styleUrls: ['./edit-import-field-dialog.component.scss']
})
export class EditImportFieldDialogComponent implements OnInit {
  columns: string[];
  importField: ImportField;
  importFieldSet = false;
  formGroup: FormGroup;

  onNoClick(): void {
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<EditImportFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImportFieldDialogData,
    private importService: ImportService,
  ) {
  }

  ngOnInit() {
    this.setImportField();
  }

  close() {
    this.dialogRef.close(this.importField);
  }

  isInvalid() {
    let invalid = false;
    for (const controlName in this.formGroup.controls) {
      const control = this.formGroup.controls[controlName];
      if (control.invalid) {
        invalid = true;
        break;
      }
    }
    return invalid;
  }

  private setImportField() {
    this.columns = this.data.columns;
    this.importField = this.data.importField;
    this.formGroup = this.importService.createFormGroup(this.importField);
    this.importFieldSet = true;
  }


}
