<h1 mat-dialog-title class="notification-send-email-header" translate="TRANS__NOTIFICATION_EMAIL__DIALOG_HEADER"></h1>
<div mat-dialog-content>
    @if (showEmailSuccess) {
        <div class="notification-send-email-success-message">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-160q75 0 127.5-52.5T660-340q0-75-52.5-127.5T480-520q-75 0-127.5 52.5T300-340q0 75 52.5 127.5T480-160ZM363-572q20-11 42.5-17.5T451-598L350-800H250l113 228Zm234 0 114-228H610l-85 170 19 38q14 4 27 8.5t26 11.5ZM256-208q-17-29-26.5-62.5T220-340q0-36 9.5-69.5T256-472q-42 14-69 49.5T160-340q0 47 27 82.5t69 49.5Zm448 0q42-14 69-49.5t27-82.5q0-47-27-82.5T704-472q17 29 26.5 62.5T740-340q0 36-9.5 69.5T704-208ZM480-80q-40 0-76.5-11.5T336-123q-9 2-18 2.5t-19 .5q-91 0-155-64T80-339q0-87 58-149t143-69L120-880h280l80 160 80-160h280L680-559q85 8 142.5 70T880-340q0 92-64 156t-156 64q-9 0-18.5-.5T623-123q-31 20-67 31.5T480-80Zm0-260ZM363-572 250-800l113 228Zm234 0 114-228-114 228ZM406-230l28-91-74-53h91l29-96 29 96h91l-74 53 28 91-74-56-74 56Z"/></svg>
            <p translate="TRANS__NOTIFICATION_EMAIL__DIALOOG_WELCOME_TEXT"></p>
            <p translate="TRANS__NOTIFICATION_EMAIL__EMAIL_SENT_CONFIRMATION"></p>
        </div>
    } @else {
        <p>Det har oppstått en feil i applikasjonen. Vennligst varsle KulturIt om feilen.</p>
        <p class="notification-send-email-error-message"><span translate="TRANS__NOTIFICATION_EMAIL__ERROR_MESSAGE_LABEL"></span><span>: <br /></span>
            @for (message of data.notification.messages; track message ) {
                <span [translate]="message"></span>
            }
            @if (this.data.notification.correlationId) {
                <br />
                <span>Correlation-Id: {{this.data.notification.correlationId}}</span>
            }
        </p>
        <p [class.notification-send-email-error]="actionBeforeErrorInputError">
            <span translate="TRANS__NOTIFICATION_EMAIL__ERROR_DESCRIPTION_LABEL"></span><span> (*):</span>
        </p>
        <textarea id="actionBeforeErrorInputField" class="notification-send-email-textarea" matInput [(ngModel)]="actionBeforeError"></textarea>
        <p translate="TRANS__NOTIFICATION_EMAIL__ERROR_SEARCH_LABEL"></p>
        <textarea class="notification-send-email-textarea" matInput [(ngModel)]="searchValue"></textarea>
        <p translate="TRANS__NOTIFICATION_EMAIL__ERROR_OBJECT_LABEL"></p>
        <textarea class="notification-send-email-textarea" matInput [(ngModel)]="openObject"></textarea>
    }
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" translate="TRANS__NOTIFICATION_EMAIL__CLOSE_DIALOG"></button>
    @if (!showEmailSuccess) {
        <button mat-button (click)="sendNotification()" translate="TRANS__NOTIFICATION_EMAIL__SEND_BUTTON_LABEL"></button>
    }
</div>

