<div class="dialog culture-hub-search">

  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span translate="TRANS__CULTURE_HUB_SEARCH__TITLE"></span>
    </h1>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <app-culture-hub-importer-items
    [dialogRef]="dialogRef"
    [cultureHubData]="data"
  ></app-culture-hub-importer-items>

</div>
