import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AnnotationHandler} from '../../image-annotation/annotation-handler';
import {ObjectMediaContainer} from '../../core/definitions/object-media-container';

@Component({
  selector: 'app-image-full-screen',
  templateUrl: './image-full-screen.component.html',
  styleUrls: ['./image-full-screen.component.scss']
})
export class ImageFullScreenComponent implements AfterViewInit {
  zoomValue = 1;
  container;
  toggleAnnotationPointContainer = false;
  @Input() mediaContainer: ObjectMediaContainer;
  @Input() toggleAnnotations;
  @Input() curAnn: AnnotationHandler;
  @Output() closeImageFullScreen = new EventEmitter();
  @ViewChild('imageFullScreen', { static: true }) imageFullScreen: ElementRef;

  constructor(private readonly elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.container = this.elementRef.nativeElement.querySelector('#imageFullScreenContainer');
      this.imageFullScreen.nativeElement.focus();
      this.onChangeZoomValue(1);
    });
  }

  onChangeZoomValue(zoom) {
    this.zoomValue = zoom.value;
  }

  goBack() {
    this.closeImageFullScreen.emit();
  }

  goBackWithKey(event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.goBack();
    }
  }
  toggleAnnotationPoints() {
    this.toggleAnnotationPointContainer = !this.toggleAnnotationPointContainer;
  }
}
