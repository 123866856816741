import {TwoWayMap} from "../TwoWayMap";
import {DefaultModelAdapter} from "../DefaultModelAdapter";

export class ReportViewTypeAdapter<ReportViewType> extends DefaultModelAdapter<ReportViewType> {
  keyConversionMap: TwoWayMap = new TwoWayMap();

  constructor(singularKey: string, pluralKey: string) {
    super(singularKey, pluralKey);
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.urlPrefix = '/cms_api/v1.0/report';
    this.urlForId = null;
    this.urlForList = null;

    this.keyConversionMap.set("viewChoices", "view_choices");
    this.keyConversionMap.set("reportViewType", "report_view_type");
    this.keyConversionMap.set("templateCode", "template_code");
    this.keyConversionMap.set("templateCodePerObject", "template_code_per_object");
  }
}
