import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FieldService} from '../../services/field.service';
import {SearchableField} from '../../../../core/definitions/advanced-search/searchable-field';
import {FieldGroup} from '../../../../core/definitions/advanced-search/field-group';

@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss']
})
export class FieldListComponent implements OnChanges {

  /**
   * All available fields to display in the list
   * @type {Array<SearchableField>}
   */
  @Input() fields: Array<SearchableField>;

  /**
   * The field that is currently selected.
   * Used to display the relevant field as active/selected.
   * Only relevant on mobile devices.
   * @type {SearchableField | null}
   */
  @Input() selectedField: SearchableField | null;

  /**
   * The current device-type. Used for responsive design
   * @type {"mobile" | "tablet" | "desktop"}
   */
  @Input() deviceType: 'mobile' | 'tablet' | 'desktop';

  /**
   * Event emitted when the user selects a field.
   * Only relevant on mobile devices.
   * @type {EventEmitter<SearchableField | null>}
   */
  @Output() public readonly selectedFieldChanged: EventEmitter<SearchableField | null>;

  /**
   * All fields (from input) grouped by their first letter and sorted by name.
   * @type {Array<FieldGroup>}
   */
  groups: Array<FieldGroup>;
  /**
   * The ID of the selected field
   * @type {string | null}
   */
  selectedFieldId: string | null;

  /**
   * Constructor
   */
  constructor() {
    this.selectedFieldChanged = new EventEmitter<SearchableField | null>();
    this.selectedFieldId = null;
    this.fields = [];
    this.groups = [];
  }

  /**
   * Groups all the fields by their first letter when the fields change
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('fields')) {
      this.fields = changes.fields.currentValue || [];
      this.groups = FieldService.groupFields(this.fields);
    }
  }

  /**
   * Sets the selected field.
   * Only relevant on mobile devices.
   * @param {SearchableField | null} field
   */
  setSelectedField(field: SearchableField | null = null): void {
    if (this.deviceType === 'mobile') {
      this.selectedFieldId = field?.fieldId || null;
      this.selectedFieldChanged.emit(field);
    }
  }
}
