<div *ngIf="contentInfo">
  <div>
    <h2 class="bold">
      <span>{{count}}&nbsp;</span>
      <span class="content-list-headline">{{headline}}</span>
    </h2>
  </div>
  <app-object-content-list-regular
    *ngIf="contentInfo.curListContainer"
    [contentListContainer]="contentInfo.curListContainer"
    (objectRefresh)="setObjectRefresh($event)"
  ></app-object-content-list-regular>
  <app-object-content-list-source-array
    *ngIf="contentInfo.curContentListSource"
    [contentListSource]="contentInfo.curContentListSource"
    (objectRefresh)="setObjectRefresh($event)"
  ></app-object-content-list-source-array>
</div>
<!--<div *ngIf="!contentInfo || (!contentInfo.curListSearchContainer &&-->
<!--  !contentInfo.contentListSource)">-->
<!--  <span translate>TRANS__CONTENT_LIST__NO_CONTENT</span>-->
<!--</div>-->
