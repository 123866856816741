<div class="search-query-advanced">
  <div class="search-query-advanced__chip-container">
    @for (fragment of searchContainer.queryContainer.fieldSearchQueryFragments; track $index; let first=$first) {
      <mat-chip
        (click)="selectFragment(fragment)"
        [matMenuTriggerFor]="fragmentMenu"
      >
        @if (!first) {
          <span [translate]="fragment.solrOperand ? 'TRANS__SEARCH_ADVANCED_QUERY__MENU_AND' : 'TRANS__SEARCH_ADVANCED_QUERY__MENU_OR'"></span>
        }

        @if (fragment.isExcluded) {
          <span translate="TRANS__SEARCH_ADVANCED_QUERY__MENU_NOT"></span>
        }

        {{fragment.name}}: {{fragment.value}}

        <button
          matChipRemove
          (click)="removeFragment(fragment)"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    }
  </div>

  <input
    class="search-query-advanced__input"
    type="text"
    id="searchQueryNew"
    autocomplete="off"
    (keypress)="onKeyPressed($event)"
    [(ngModel)]="query"
    [matMenuTriggerFor]="fragmentMenu"
    [ngClass]="{'search-query-advanced__error-input-frame': !isValidQuery, 'search-query-advanced__valid-input-frame': isValidQuery && searchString }"
    [placeholder]="queryPlaceholder"
  >

  <a
    *ngIf="searchString || searchContainer.queryContainer.fieldSearchQueryFragments.length > 0"
    class="search-query-advanced__clear-input-field"
    (click)="clearSearch()"
  >
    <mat-icon>close</mat-icon>
  </a>
</div>

<mat-menu #fragmentMenu="matMenu" (closed)="selectedFragment = null; searchValue = ''" overlayPanelClass="menu-panel">
  @if (selectedFragment !== null) {
    <button
      mat-icon-button
      (click)="$event.stopPropagation(); selectedFragment = null"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="search-query-advanced__suggestion-menu-container">
      <mat-slide-toggle [(ngModel)]="selectedFragment.isExcluded" (click)="$event.stopPropagation()" translate="TRANS__SEARCH_ADVANCED_QUERY__MENU_EXCLUDE"></mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="selectedFragment.solrOperand" (click)="$event.stopPropagation()" translate="TRANS__SEARCH_ADVANCED_QUERY__MENU_OPERATOR"></mat-slide-toggle>

      @switch(selectedFragment.inputType) {
        @case ('map_id') {
          <form>
            <mat-form-field>
              <input
                type="text"
                matInput
                (click)="$event.stopPropagation(); updateAutocompleteOptions('*')"
                [matAutocomplete]="autocomplete"
                [formControl]="autocompleteFormControl"
              >
              <mat-autocomplete
                #autocomplete="matAutocomplete"
                (click)="$event.stopPropagation();"
                style="height: 100%"
              >
                @for (option of autocompleteOptions; track option) {
                  <mat-option [value]="option.name" (click)="selectedFragment.value = option.name">{{option.name}}</mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </form>
        }

        @case ('input') {
          <input
            type="text"
            autocomplete="off"
            [(ngModel)]="value"
            (click)="$event.stopPropagation()"
          >
        }

        @case ('text_area') {
          <input
            type="text"
            autocomplete="off"
            [(ngModel)]="value"
            (click)="$event.stopPropagation()"
          >
        }

        @default {
          {{null}}
        }
      }

      <button
        mat-flat-button
        [color]="'primary'"
        (click)="addFragment(selectedFragment)"
        [translate]="isEditing ? 'TRANS__SEARCH_ADVANCED_QUERY__MENU_EDIT' : 'TRANS__SEARCH_ADVANCED_QUERY__MENU_ADD'"
      >

      </button>
    </div>
  }
  @else {
    @for (fieldSuggestion of fieldSuggestions; track fieldSuggestion.artifact_id) {
      <button
        mat-menu-item
        (click)="selectSuggestion($event, fieldSuggestion)"
      >
        <span>{{fieldSuggestion.name}}</span>
      </button>
    } @empty {
      <span translate="TRANS__SEARCH_ADVANCED_QUERY__MENU_PLACEHOLDER"></span>
    }
  }
</mat-menu>
