<div  class="media-carousel-top"
      [ngClass]="{'smallCarousel': mediumScreen, 'largeCarousel': !mediumScreen}">
  @if (showInformationButton && !imageGallery) {
    <div class="media-carousel-top__button-container media-carousel-top__information">
      @if (mediaElements[mediaIndex]) {
        <button mat-button class="media-carousel-top__button"
                [routerLink]="mediaElements[mediaIndex].mediaObject.$$routerLink"
                [queryParams]="mediaElements[mediaIndex].mediaObject.$$queryParams"
                queryParamsHandling="merge"
                [ngClass]="{'disable-button': isEditing}">
          <div class="media-carousel-top-menu-button">
            <mat-icon>more_horiz</mat-icon>
            <span class="media-carousel-top__button-text"
            >{{'TRANS__MEDIA_CAROUSEL__MEDIA_INFORMATION' | translate}}</span>
          </div>
        </button>
      }
    </div>
  }

  <div class="media-carousel-top__button-container  media-carousel-top__image-zoom">
    @if (showZoom) {
      <button mat-button class="media-carousel-top__button"
              [matMenuTriggerFor]="zoomIn"
              #zoomInButton="matMenuTrigger">
        <div class="media-carousel-top-menu-button">
          <mat-icon>zoom_in</mat-icon>
          <span class="media-carousel-top__button-text">{{'TRANS__MEDIA_CAROUSEL__ZOOM' | translate}}</span>
        </div>
      </button>
    }

    <mat-menu #zoomIn="matMenu" [hasBackdrop]="false">
      <div mat-menu-item>
        <mat-slider min="1" max="2" step="0.1"
                    (click)="$event.stopPropagation()" #ngSlider><input matSliderThumb [value]="zoomValue" (change)="onChangedZoomValue({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /></mat-slider></div>
    </mat-menu>
  </div>

  <div class="media-carousel-top__button-container media-carousel-top__full-screen">
    @if (showFullScreenButton) {
      <button (click)="openFullScreen()"
              class="media-carousel-top__button"
              [disabled]="isEditing" mat-button>
        <div class="media-carousel-top-menu-button">
          <mat-icon>fullscreen</mat-icon>
          <span class="media-carousel-top__button-text"
          >{{'TRANS__MEDIA_CAROUSEL__FULL_SCREEN' | translate}}</span>
        </div>
      </button>
    }
  </div>

  <div class="media-carousel-top__button-container">
    @if (showDownload) {
      <app-download-url [object]="mediaElements[mediaIndex].mediaObject">
        <div class="app-download-content">
          <button class="media-carousel-top__button" mat-button>
            <div class="media-carousel-top-menu-button">
              <mat-icon>get_app</mat-icon>
              <span class="media-carousel-top__button-text"
              >{{'TRANS__MEDIA_CAROUSEL__DOWNLOAD' | translate}}</span>
            </div>
          </button>
        </div>
      </app-download-url>
    }
  </div>
</div>

