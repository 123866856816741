import {Component, effect, EventEmitter, Input, OnInit, Output, signal, WritableSignal} from '@angular/core';
import {AdvFieldQuery, AdvFieldQueryGroup} from "../../../core/definitions/advanced-search-params";
import {AdvancedSearchField, GetAdvancedSearchFieldParams} from "../../../core/definitions/advanced-search-field";
import {SearchContainer} from "../../../core/definitions/search-container";
import {AdvancedSearchToolsService} from "../../../core/advanced-search-tools.service";
import {SearchObject} from "../../../core/definitions/search-object";
import {AdvancedSearchFieldService} from "../../../core/advanced-search-field.service";

@Component({
  selector: 'app-advanced-search-query-builder-field',
  templateUrl: './advanced-search-query-builder-field.component.html',
  styleUrl: './advanced-search-query-builder-field.component.scss'
})
export class AdvancedSearchQueryBuilderFieldComponent implements OnInit {
  @Input() field: AdvFieldQuery;
  @Input() parentGroup: AdvFieldQueryGroup;
  @Input() rootGroup: AdvFieldQueryGroup;
  @Input() searchContainer: SearchContainer;
  @Input() triggerUpdateFields: WritableSignal<boolean>;

  @Output() removeSelf = new EventEmitter<AdvFieldQuery>();
  @Output() updateSelf = new EventEmitter<AdvFieldQuery>();

  advancedFieldQuery: AdvFieldQuery;
  current: boolean;
  fieldFilterDebouncer = null;
  fieldOptions: WritableSignal<AdvancedSearchField[]> = signal([]);
  fieldQuery: WritableSignal<string> = signal('');
  showFieldSelector: boolean = false;
  showValueSelector: boolean = false;
  valueFilterDebouncer = null;
  valueOptions:WritableSignal<SearchObject[]> = signal([]);
  valueQuery: WritableSignal<string> = signal('');

  constructor(
    public advancedSearchTools: AdvancedSearchToolsService,
    private advancedSearchFieldService: AdvancedSearchFieldService
  ) {
    effect(() => {
      this.updateValues();

      if (this.triggerUpdateFields() !== this.current) {
        this.updateFieldSelectorOptions();
        this.current = this.triggerUpdateFields();
      }
    });
  }

  ngOnInit() {
    this.advancedFieldQuery = this.field;
    this.current = this.triggerUpdateFields();

    if (!this.advancedFieldQuery.reference_id) {
      this.updateFieldSelectorOptions();
    }

    if (this.advancedFieldQuery.field_title !== '') {
      this.fieldQuery.set(this.advancedFieldQuery.field_title.split(' / ')[this.advancedFieldQuery.field_title.split(' / ').length -1]);
    }

    if (this.advancedFieldQuery.operator_selected !== 'in' && this.advancedFieldQuery.operator_selected !== 'not in') {
      if ((this.advancedFieldQuery.valueDisplay !== '' && this.advancedFieldQuery.valueDisplay !== undefined)) {
        this.valueQuery.set(Array.isArray(this.advancedFieldQuery.valueDisplay) ? this.advancedFieldQuery.valueDisplay[0] : this.advancedFieldQuery.valueDisplay);
      }
      else {
        this.valueQuery.set('');
      }
    }
    else {
      this.valueQuery.set('');
    }
  }

  arraySelectionChanged(event) {
    let temp = this.field;

    let tempVal = [];
    let tempValDisp = [];

    for (const val of event) {
      tempVal.push(val.artifact_id);
      tempValDisp.push(val);
    }

    temp.value = tempVal;
    temp.valueDisplay = tempValDisp;

    this.updateSelf.emit(temp);
  }

  clearSelectedField() {
    let temp = this.field;

    temp.parent_field_ids = [];
    temp.field_title = '';
    temp.field_name = '';
    temp.path = '';
    temp.input_type = '';
    temp.reference_id = '';
    temp.operator_selected = undefined;
    temp.operators = [];
    temp.superobject_type_id = null;
    temp.context_field = null;
    temp.child_document_type = null;

    this.updateSelf.emit(temp);
    this.fieldQuery.set('');
  }

  clearFieldValue() {
    let temp = this.field;
    temp.value = null;
    temp.valueDisplay = null;

    this.updateSelf.emit(temp);

    this.valueQuery.set('');
  }

  filterFieldDropdownContent(event) {
    if (this.fieldFilterDebouncer !== null) {
      clearTimeout(this.fieldFilterDebouncer);
    }

    this.fieldFilterDebouncer = setTimeout(() => {
      this.fieldQuery.set(event.target.value);

      if (this.fieldOptions().length === 0) {
        this.updateFieldSelectorOptions();
      }
    }, 500);
  }

  filterValueDropdownContent(event) {
    this.valueQuery.set(event.target.value || event.target.innerHTML);
  }

  getOperatorDisplayLabel() {
    if (this.parentGroup.field_logical_operator === 'OR') {
      return 'TRANS__OPERATOR__OR';
    }
    else if (this.parentGroup.field_logical_operator === 'AND') {
      return 'TRANS__OPERATOR__AND';
    }
    else if (this.parentGroup.field_logical_operator === 'NOT') {
      return 'TRANS__OPERATOR__NOT';
    }

    return null;
  }

  hideDropdown(event, selector) {
    event.preventDefault();

    setTimeout(() => {
      if (!document.activeElement.closest(selector)) {
        if (selector === '#field-options-view') {
          this.showFieldSelector = false;

          if (!this.advancedFieldQuery.field_title.endsWith(this.fieldQuery())) {
            this.fieldQuery.set('');
          }
        }
        else {
          this.showValueSelector = false;

          if (this.advancedFieldQuery.valueDisplay !== this.valueQuery()) {
            this.valueQuery.set('');
          }
        }
      }
    }, 1);
  }

  hideDropdowns() {
    if (this.showFieldSelector) {
      this.showFieldSelector = false;
    }

    if (this.showValueSelector) {
      this.showValueSelector = false;
    }
  }

  removeField() {
    this.removeSelf.emit(this.field);
  }

  setIntervalValue(event) {
    let temp = this.field;

    if (!Array.isArray(temp.value)) {
      temp.value = [null, null];
    }
    if (event[0] !== null) {
      temp.value[0] = event[0].target.value === '' ? null : event[0].target.value;
    }
    if (event[1] !== null) {
      temp.value[1] = event[1].target.value === '' ? null : event[1].target.value;
    }

    this.updateSelf.emit(temp);
  }

  setSelectedField(event) {
    this.fieldQuery.set(event.field_title);

    this.showFieldSelector = false;

    let temp = this.advancedSearchTools.createAdvFieldQueryFromSearchField(event);

    if (event.context_field) {
      temp.relation_superobject_type_id = event.superobject_type_id;
    }

    this.updateSelf.emit(temp);
  }

  setSelectedValue(event) {
    let temp = this.field;

    this.valueQuery.set(event.artifact_name);
    temp.value = event.artifact_id;
    temp.valueDisplay = event.artifact_name;

    this.showValueSelector = false;

    this.updateSelf.emit(temp);
  }

  setTextValue(event) {
    if (this.valueFilterDebouncer !== null) {
      clearTimeout(this.valueFilterDebouncer);
    }

    this.valueFilterDebouncer = setTimeout(() => {
      let temp = this.field;
      // @ts-ignore
      temp.value = event.target.value;

      this.updateSelf.emit(temp);
    }, 200)
  }

  showFieldDropdown(event) {
    event.target.select();
    this.showFieldSelector = true;
  }

  showValueDropdown() {
    this.showValueSelector = true;
  }

  get valueFieldDisabled() {
    return this.advancedFieldQuery.operator_selected === undefined ? true : this.advancedFieldQuery.operator_selected.indexOf('empty') !== -1;
  }

  private updateFieldSelectorOptions() {
    const relationInfo = this.advancedSearchTools.getRelationInfoFromGroup(
      this.parentGroup, this.rootGroup);
    const relationId = relationInfo ? relationInfo.superobjectTypeId : null;

    this.advancedSearchFieldService.getAdvancedSearchFields({
      superobject_types: relationId || this.searchContainer.currentPathView.search_view.superobject_types.join(', '),
      is_relation_query: !!relationId,
      is_sub_group: this.parentGroup.level > 0,
      child_document_type: relationInfo?.childDocumentType,
      db_search: this.searchContainer.advancedSearchParams.db_search
    } as GetAdvancedSearchFieldParams, this.searchContainer.advancedSearchParams.query_groups, this.parentGroup).then((fields) => {
      this.fieldOptions.set(fields);
    });
  }

  private updateValues() {
    if (this.valueQuery() !== '') {
      this.updateValueSelectorOptions(this.valueQuery()).then();
    }
    else {
      this.updateValueSelectorOptions().then();
    }
  }

  private async updateValueSelectorOptions(query= '') {
    // By removing this check, it will be possible to obtain available field values for all field types, like text etc.
    if (!this.advancedFieldQuery.reference_id) {
      return;
    }
    const path = this.advancedFieldQuery.path || this.advancedFieldQuery.field_name;
    if (!path) {
      return
    }
    let superobjectTypeIds = [];
    const relationInfoFromGroup = this.advancedSearchTools.getRelationInfoFromGroup(
      this.parentGroup, this.rootGroup);

    if (relationInfoFromGroup?.superobjectTypeId) {
      superobjectTypeIds.push(relationInfoFromGroup.superobjectTypeId)
    } else {
      superobjectTypeIds = this.searchContainer.advancedSearchParams.superobject_type_ids;
    }
    let folderId = this.searchContainer.getTargetId();
    if (folderId === 'none') {
      folderId = ''
    }
    const searchObjects = await this.advancedSearchFieldService.getAdvancedSearchFieldValues(
      this.advancedFieldQuery, query, superobjectTypeIds, 150, folderId);
    this.valueOptions.set(searchObjects);
  }

}
