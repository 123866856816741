import {Injectable} from '@angular/core';
import {FieldMetaHandlerService, MetaPropParams} from './field-meta-handler.service';
import {ModelFactoryService} from './model-factory.service';
import {ModelsService} from './models.service';
import {BaseModel} from './definitions/base-model';
import {LoggerService} from "./logger.service";
import {MetaField} from "./definitions/meta-field";
import {CrudService} from "./crud.service";

@Injectable({
  providedIn: 'root'
})
export class ChangeTrackerService {

  constructor(private logger: LoggerService,
              private fieldMetaHandler: FieldMetaHandlerService,
              private modelFactory: ModelFactoryService,
              private modelsSvc: ModelsService,
              private crud: CrudService) {
  }

  objectHasChanges(obj: BaseModel, modelName?) {
    let res = false;
    for (const item of this.getItems(obj)) {
      const meta = this.getMetaFromObject(item, modelName);
      if (meta) {
        res = this.loopFieldsCheckChanges(item, meta)
        if (res) {
          break;
        }
      }
    }
    return res;
  }


  checkFieldValueChanged(object: BaseModel, fieldName) {
    let changed = false, isArray, origVal, inline;

    const fieldValue = object[fieldName];
    const params = new MetaPropParams();
    params.metaPropName = 'inline';
    params.fieldName = fieldName;
    params.parentModel = object;
    inline = this.fieldMetaHandler.getMetaFieldValue(params);
    isArray = Array.isArray(fieldValue);

    if (inline && !isArray) {
      if (!fieldValue) {
        return changed;
      }
      changed = this.objectHasChanges(fieldValue, inline.model);
    } else {
      origVal = this.getOriginalFieldValue(object, fieldName);
      if (origVal) {
        if (isArray) {
          changed = this.checkArrayChanges(fieldValue, origVal);
        } else {
          changed = fieldValue !== origVal;
        }
      } else {
        changed = this.checkChangesEmptyOrigValue(isArray, fieldValue, origVal);
      }
    }
    this.fieldChangeCallback(object, fieldName, changed);
    return changed;
  }

  getOriginalFieldValue(object, fieldName) {
    return this.modelFactory.getOrigVal(object, fieldName);
  }

  setFieldChangeCallback(object, fieldName, callback) {
    object['$$changeCallbackInfo-' + fieldName] = {callback: callback, changed: false};
  }

  private fieldChangeCallback(object, fieldName, changed) {
    const callbackInfo = object['$$changeCallbackInfo-' + fieldName];
    if (callbackInfo && callbackInfo.changed !== changed) {
      callbackInfo.changed = changed;
      callbackInfo.callback(changed);
    }
  }

  private getItems(items): BaseModel[] {
    return Array.isArray(items) ? items : [items];
  }

  private checkArrayChanges(arrayValues, origArrayValues) {
    let changed = origArrayValues.length !== arrayValues.length;
    if (!changed) {
      changed = this.arrayItemsDeleted(arrayValues);
    }
    if (!changed) {
      changed = this.arrayItemsMoved(arrayValues, origArrayValues);
    }
    if (!changed) {
      changed = this.objectHasChanges(arrayValues);
    }
    return changed;
  }

  private checkChangesEmptyOrigValue(isArray: boolean, fieldValue: any, origVal: any) {
    let changed;
    if (isArray) {
      changed = fieldValue.length > 0;
    } else if (origVal === 0) {
      changed = fieldValue !== 0;
    } else if (origVal === '') {
      changed = fieldValue !== '';
    } else if (origVal === false) {
      changed = fieldValue !== false;
    } else {
      changed = fieldValue !== null;
    }
    return changed;
  }

  private getMetaFromObject(object: BaseModel, modelName?): { [name: string]: MetaField } {
    let meta = object && object.$$meta;
    if (!meta) {
      if (modelName) {
        meta = this.modelsSvc.getModelMeta(modelName);
        if (!meta) {
          this.logger.warn(`No meta fond for ${modelName}`);
        }
      } else {
        this.logger.warn('No meta?');
      }
    }
    return meta;
  }

  private loopFieldsCheckChanges(object: BaseModel, meta: { [name: string]: MetaField }) {
    let res = false;
    for (const [fieldName, fieldMeta] of Object.entries(meta)) {
      const show = fieldMeta.display;
      if (!res && typeof fieldMeta === 'object' && (fieldMeta.edit || show)) {
        res = this.checkFieldValueChanged(object, fieldName);
        if (res) {
          break;
        }
      }
    }
    return res;
  }

  private arrayItemsDeleted(array: BaseModel[]) {
    return array.find(element => this.crud.getDestroy(element)) !== undefined;
  }

  private arrayItemsMoved(array: BaseModel[], origOrder) {
    let res = false;
    for (const [index, item] of array.entries()) {
      if (item.order_number !== undefined && item.order_number !== null &&
        Number(item.order_number) !== Number(origOrder[index])) {
        res = true;
        break;
      }
    }
    return res;
  }

}
