<div *ngIf="searchContainer.searchResultViewName === 'list' && listFieldTemplateId"
     class="search-view-full-header__title">
 <span translate="TRANS__SEARCH_VIEW_FULL_HEADER__SEARCH_FIELDS_TITLE"></span>
  "<app-object-name
    [object]="listFieldTemplateObject"
    [objectId]="listFieldTemplateId"
></app-object-name>"
  <ng-container *ngIf="operationContainer">
    <ng-container *ngFor="let operation of operationContainer.operations">
      <button mat-button (click)="openOperation(operation)"
              [title]="operation.menu_title | translate">
        <i class="{{operation.menu_icon}}"></i>
      </button>
    </ng-container>
  </ng-container>

</div>

