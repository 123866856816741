<div class="admin-id-format-filter-wrapper">
  <header>
    <h5>{{ heading | translate }}</h5>
  </header>
  <section>
    <article class="description">
      <p>{{ description | translate }}</p>
    </article>
    <article class="filter">

      <div class="admin-stroked-button">
        <button mat-stroked-button
                type="button"
                [mat-menu-trigger-for]="ActionMenu"
                [disabled]="!actions || actions.length === 0">
          <mat-icon class="mat-button-icon-prefix">more_horiz</mat-icon>
          <span class="bold uppercase admin-stroked-button__left-margin">{{ 'TRANS__ADMIN__ID_FORMAT__SELECT_BUTTON_LABEL' | translate }}</span>
        </button>

        <mat-menu #ActionMenu="matMenu">
          <button mat-menu-item
                  type="button"
                  *ngFor="let action of actions"
                  (click)="actionClicked.emit(action)">
            {{ (actionDisplayProperty ? action[actionDisplayProperty] : action) | translate }}
          </button>
        </mat-menu>
      </div>

      <div class="admin-stroked-button">
        <!-- TODO: Remove disabled when filters are implemented -->
        <button mat-stroked-button type="button" disabled>
          <mat-icon class="mat-button-icon-prefix">tune</mat-icon>
          <span class="bold uppercase admin-stroked-button__left-margin">{{ 'TRANS__ADMIN__ID_FORMAT__FILTER_BUTTON_LABEL' | translate }}</span>
        </button>
      </div>

      <div class="small-mat-form-outline">
        <mat-form-field appearance="outline" color="primary">
          <mat-label>{{ 'TRANS__ADMIN__ID_FORMAT__FILTER_INPUT_PLACEHOLDER' | translate }}</mat-label>
          <input matInput [formControl]="filterControl" autocomplete="off">
          <button mat-icon-button
                  matSuffix
                  aria-label="clear"
                  type="button"
                  class="clear-icon"
                  (click)="filterControl.setValue('')"
                  *ngIf="filterControl && filterControl.value">
            <mat-icon>close</mat-icon>
          </button>
          <button mat-icon-button
                  matSuffix
                  class="search-icon"
                  type="button">
            <mat-icon matSuffix color="accent">search</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="filter-button-container">
        <!-- TODO: Remove disabled when filters are implemented -->
        <button mat-icon-button type="button" disabled>
          <mat-icon>settings</mat-icon>
        </button>
      </div>

    </article>
  </section>
</div>
