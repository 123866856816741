import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PrimusMenuItem, SelectionState} from './primus-menu-button-item/primus-menu-button-item.component';
import {SelectionModel} from '@angular/cdk/collections';

export interface PrimusMenuButtonEvent {
  item: PrimusMenuItem;
  selected?: boolean;
  selectionGroup?: string;
  allSelected?: Array<PrimusMenuItem>;
}

@Component({
  selector: 'app-primus-menu-button',
  templateUrl: './primus-menu-button.component.html',
  styleUrls: ['./primus-menu-button.component.scss']
})
export class PrimusMenuButtonComponent implements OnChanges {

  @Input() menuButtonLabel: string;
  @Input() menuButtonIcon: string;
  @Input() color: 'primary' | 'accent' | 'warn';
  @Input() menuItems: Array<PrimusMenuItem>;
  @Input() disabled: boolean;

  @Output() public readonly menuItemClicked: EventEmitter<PrimusMenuButtonEvent>;

  selectionGroups: SelectionState;

  constructor() {
    this.menuItems = [];
    this.menuItemClicked = new EventEmitter<PrimusMenuButtonEvent>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('menuItems')) {
      const selectionState: SelectionState = {};
      this.flattenMenuItems(this.menuItems || [])
        .filter(i => !i.items && !!i.groupName && (i.mode === 'radio' || i.mode === 'checkbox'))
        .forEach(item => {
          if (!selectionState.hasOwnProperty(item.groupName)) {
            selectionState[item.groupName] = new SelectionModel<PrimusMenuItem>(item.mode === 'checkbox');
          }
          if (!!item.default) {
            selectionState[item.groupName].select(item);
          }
        });
      this.selectionGroups = selectionState;
    }
  }

  handleMenuItemClicked(item: PrimusMenuItem) {
    const event: PrimusMenuButtonEvent = {item};
    if (item.groupName && this.selectionGroups && this.selectionGroups.hasOwnProperty(item.groupName)) {
      event.selectionGroup = item.groupName;
      event.selected = this.selectionGroups[item.groupName].isSelected(item);
      event.allSelected = this.selectionGroups[item.groupName].selected;
    }
    this.menuItemClicked.emit(event);
  }

  private flattenMenuItems(items: Array<PrimusMenuItem>): Array<PrimusMenuItem> {
    const itemArray: Array<PrimusMenuItem> = [...items];
    items.filter(i => i.items && i.items.length > 0)
      .forEach(i => itemArray.push(...this.flattenMenuItems(i.items)));
    return itemArray;
  }
}
