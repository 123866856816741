import {Component, HostListener, Input, OnInit} from '@angular/core';
import {SectionMenuContainer} from '../section-menu/section-menu.component';

@Component({
  selector: 'app-section-menu-scroll',
  templateUrl: './section-menu-scroll.component.html',
  styleUrls: ['./section-menu-scroll.component.scss']
})
export class SectionMenuScrollComponent implements OnInit {

  @Input() sectionMenuContainer: SectionMenuContainer;

  constructor() {
  }

  ngOnInit() {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let divPosition;
    const div: HTMLDivElement = document.querySelector('#sectionMenuScroll');
    if (div !== null) {
      divPosition = div.offsetTop; // +70
      const main = document.getElementById('innerCon');
      const actionMenu = main.offsetTop;
      if (actionMenu > 200) {
        divPosition = divPosition + actionMenu;
      }
    }

    if (window.pageYOffset > divPosition) {
      if (!this.sectionMenuContainer.showSectionMenu) {
        this.sectionMenuContainer.showSectionMenu = true;
      }
    } else {
      if (this.sectionMenuContainer.showSectionMenu) {
        this.sectionMenuContainer.showSectionMenu = false;
      }
    }
    if (this.sectionMenuContainer.showSectionMenu) {
      this.sectionMenuContainer.sections.forEach(section => {
        const sectionElm = <HTMLElement>document.querySelector('#section-' + section.name);
        if (sectionElm) {
          const bottom = sectionElm.offsetHeight + sectionElm.offsetTop;
          if (sectionElm.offsetTop - 100 <= window.scrollY &&
            bottom > window.scrollY) {
            this.sectionMenuContainer.currentSectionInViewPort = section.name;
          }
        }
      });
    }
  }


}
