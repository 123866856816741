import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {ObjectView, OverviewField} from '../../core/definitions/object-view';

@Component({
  selector: 'app-object-overview-fields',
  templateUrl: './object-overview-fields.component.html',
  styleUrls: ['./object-overview-fields.component.scss']
})
export class ObjectOverviewFieldsComponent implements OnChanges {

  @Input() objectView: ObjectView;
  @Input() disableLinks: boolean;
  @Input() isEditing: boolean;
  @Input() noTitle: boolean;

  AConst = AConst;
  fields: Array<OverviewField> = [];
  errMsg = '';

  constructor() {
  }

  ngOnChanges() {
    this.fields = this.getMaxNumberFields(this.objectView.overview);
  }

  private getMaxNumberFields(fields: Array<OverviewField>): Array<OverviewField> {
    return fields && fields.length ? fields.slice(0, 5) : [];
  }

}
