<div class="search-menu-select-chosen"
     [ngClass]="{'disable': selectDisabled(),
     'copy': !isTemplateSelector,
     'select': isTemplateSelector}"
     [matTooltip]="isTemplateSelector ? ('TRANS__SELECTOR__HELP_TEXT_SELECT_TEMPLATE' | translate) :
     (('TRANS__SELECTOR__HELP_TEXT_BEFORE' | translate) + ' ' + (searchContainer.selectButtonText | translate) + ' ' + ('TRANS__SELECTOR__HELP_TEXT_AFTER' | translate))"
     [matTooltipDisabled]="!selectDisabled()">
  <button mat-flat-button
          class="search-menu-select-chosen__button"
          color="accent"
          (click)="select()"
          [disabled]="selectDisabled()">
    <mat-icon *ngIf="isTemplateSelector">check</mat-icon>
    <i *ngIf="!isTemplateSelector" class="icon-select1"></i>
    <span class="bold" [translate]="searchContainer.selectButtonText"></span>
  </button>
</div>
