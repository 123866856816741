import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-inline-array-header-sep',
  templateUrl: './inline-array-header-sep.component.html',
  styleUrls: ['./inline-array-header-sep.component.scss']
})
export class InlineArrayHeaderSepComponent implements OnInit {
  @Input() sepValue;
  labelSep = [];

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    let sepArr = this.sepValue;
    if (sepArr && !Array.isArray(sepArr)) {
      sepArr = [sepArr];
    }
    sepArr.forEach((sep) => {
      if (sep.indexOf('TRANS__') === 0) {
        this.labelSep.push(this.translate.instant(sep));
      } else {
        this.labelSep.push(sep);
      }
    });

  }

}
