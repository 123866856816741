import {Injectable} from '@angular/core';
import {ModelsService} from "../core/models.service";
import {FieldParameters} from "../core/definitions/field-parameters";
import {MetaField} from "../core/definitions/meta-field";
import {AddNewParams, CopyField, Reference, ReferenceFilter} from "../core/definitions/reference";
import {CmsApiService} from "../core/cms-api.service";
import {SearchParameters} from "../core/definitions/search-parameters";
import {SettingsService} from "../core/settings.service";
import {SectionsContainer} from "../core/definitions/sections-container";
import {CreateSectionsContainerParams, ObjectEditService} from "../core/object-edit.service";
import {EditObjectDialogData} from "./edit-object-dialog-data";
import {GetArtifactParams} from "../core/definitions/get-artifact-params";
import {FieldValueService} from "../core/field-value.service";
import {AConst} from "../core/a-const.enum";
import {SolrFilterService} from "../core/solr-filter.service";
import {SearchService} from "../core/search.service";
import {OptionsService} from "../core/options.service";

@Injectable({
  providedIn: 'root'
})
export class EditObjectDialogService {

  constructor(private models: ModelsService,
              private cms: CmsApiService,
              private settingsService: SettingsService,
              private objectEditService: ObjectEditService,
              private fieldValueHandler: FieldValueService,
              private solrFilter: SolrFilterService,
              private searchService: SearchService,
              private optionsService: OptionsService) {
  }

  async getSelectObjectTypesFromMetaTypes(metaTypes) {
    await this.models.getModelsAsync();
    metaTypes = Array.isArray(metaTypes) ? metaTypes : [metaTypes];
    const superObjectTypes = await this.models.getSuperObjectConceptTypesFromMetaTypes(metaTypes);
    const objectTypes = this.models.getObjectTypesFromSuperObjectTypeIds(
      superObjectTypes.map(superObjectType => superObjectType.artifact_id));
    return [...superObjectTypes].sort(
      (obj1, obj2) => obj1.artifact_name.localeCompare(obj2.artifact_name)
    ).map(superObjectType => {
      return {
        name: superObjectType.artifact_name,
        objectType: objectTypes[superObjectType.artifact_id]
      };
    });
  }

  async createOptionFromFieldParameters(
    fieldParameters: FieldParameters,
    editObjectDialogData: EditObjectDialogData,
    fieldReference: Reference,
    text?,
    parentId?): Promise<SectionsContainer> {
    let sectionsContainer: SectionsContainer;
    const parentMeta: MetaField = fieldParameters.field;
    const canAddNew = await this.optionsService.getCanAddNew(fieldReference);
    if (canAddNew) {
      const objType = (fieldReference.add_new_params && fieldReference.add_new_params.new_object_type) || fieldReference.object_type;
      const optionData = await this.getOptionData(fieldParameters, fieldReference, editObjectDialogData, text, parentId);
      sectionsContainer = await this.createObjectSectionsContainer(
        objType, editObjectDialogData, fieldReference, optionData, parentMeta);
      if (fieldParameters.sectionsContainer) {
        sectionsContainer.parentObject = fieldParameters.sectionsContainer.rootObject;
      }
    }
    return sectionsContainer
  }

  getUsePrimeFields(objectType, fieldReference: Reference, parentMeta?) {
    let usePrimeFields = false;
    if (objectType.indexOf('ct_') !== 0) {
      usePrimeFields = !(parentMeta && fieldReference && fieldReference.add_new_params &&
        fieldReference.add_new_params.full_object_edit);
    }
    return usePrimeFields;
  }

  async createObjectSectionsContainer(
    objectType: string,
    editObjectDialogData: EditObjectDialogData,
    fieldReference: Reference,
    objectData?,
    parentMeta?): Promise<SectionsContainer> {
    let usePrimeFields = this.getUsePrimeFields(objectType, fieldReference, parentMeta);
    const objectId = objectData?.artifact_id;
    const object = objectId ? null : objectData;
    const params = {
      useExistingObject: !!(!usePrimeFields && objectId),
      usePrimeFields: usePrimeFields,
      objectType: objectType,
      templateGroupId: editObjectDialogData.fieldParameters.sectionsContainer?.templateGroupId,
      objectId: objectId,
      object: object,
      getSourceObject: false
    } as CreateSectionsContainerParams;
    const sectionsContainer = await this.objectEditService.createSectionsContainer(params);
    sectionsContainer.isDialog = true;
    if (editObjectDialogData.secondDialogOpen) {
      sectionsContainer.isSecondDialog = editObjectDialogData.secondDialogOpen;
    }
    if (editObjectDialogData.fieldParameters.sectionsContainer) {
      const parentSectionsContainer = editObjectDialogData.fieldParameters.sectionsContainer;
      sectionsContainer.operationContextObjects = parentSectionsContainer.operationContextObjects;
      sectionsContainer.useContextCreatingIdentifier = parentSectionsContainer.useContextCreatingIdentifier;
    }
    if (fieldReference.add_new_params?.use_context_creating_identifier) {
      sectionsContainer.useContextCreatingIdentifier = true;
    }
    return sectionsContainer;
  }


  private async getOptionData(
    fieldParameters: FieldParameters,
    fieldReference: Reference,
    editObjectDialogData: EditObjectDialogData,
    text?,
    parentId?) {
    const createObjectType = fieldReference.add_new_params?.new_object_type || fieldReference.object_type;
    const meta = this.models.getModelMeta(createObjectType);
    const optionData = {
      $$icon: 'icon-plus',
      $$weight: -1,
      parent_id: parentId
    };
    if (fieldParameters.object && fieldParameters.object.object_type === createObjectType) {
      this.mergeData(optionData, fieldParameters.object);
    }
    if (text) {
      this.objectEditService.setNameField(fieldReference, optionData, text);
    }

    for (const metaFieldName in meta) {
      if (meta.hasOwnProperty(metaFieldName)) {
        if (metaFieldName === AConst.REG_LEVEL_TYPE_ID) {
          optionData[metaFieldName] = this.fieldValueHandler.getFieldValue(
            fieldParameters.object, AConst.REG_LEVEL + '.' + AConst.REG_LEVEL_TYPE_ID);
        } else if (metaFieldName === AConst.PARENT_FIELD) {
          const parentField = fieldReference.parent_field;
          const parentVal = this.fieldValueHandler.getFieldValue(fieldParameters.object, parentField);
          const parentTargetField = fieldReference.parent_target_field || AConst.PARENT_ID;
          optionData[parentTargetField] = parentVal;
        }
      }
    }
    await this.setFilterDataFromReference(fieldParameters, optionData, fieldReference);

    await this.setFieldsFromOtherObject(optionData, fieldParameters, fieldReference, editObjectDialogData);
    return optionData;
  }

  private async setFilterDataFromReference(fieldParameters: FieldParameters, optionData, reference: Reference) {
    const refFilters = reference.ref_filter;
    if (refFilters) {
      for (const refFilter of refFilters) {
        switch (refFilter.filter_field) {
          case 'filters.superobject_type_id':
            await this.setParentChildFilterValues(fieldParameters, optionData, refFilter);
            break;
          case 'artifact':
            optionData['artifact'] = refFilter.values;
            break;
          default:
            console.warn(`Unsupported filter field ${refFilter.filter_field}`);
        }
      }
    }
  }

  private async setParentChildFilterValues(fieldParameters: FieldParameters, optionData, refFilter: ReferenceFilter) {
    const pathSplit = refFilter.filter_field.split('.');
    const parentName = pathSplit[0];
    const childName = pathSplit[1];
    optionData[parentName] = [];
    const config = this.settingsService.getClientConfig();
    for (const value of refFilter.values) {
      const childObject = {};
      if (value.indexOf('{') !== -1) {
        const substStr = this.fieldValueHandler.getSubstituteString(value, '{', '}');
        const substValue = fieldParameters.rootObject[substStr];
        if (substValue !== undefined) {
          childObject[childName] = value.replace(value, substValue);
          childObject[`${childName}_value`] = fieldParameters.rootObject[`${substStr}_value`];
          optionData[parentName].push(childObject);
        }
      } else if (value.indexOf(config.SUPEROBJECT_TYPE_ID) === -1) {
        // The above check in order to prevent 'all' from being added as parent to the new concept
        childObject[childName] = value;
        const searchParams = {} as SearchParameters;
        this.solrFilter.addFq(searchParams, 'artifact_id', value);
        const searchResult = await this.searchService.search(searchParams);
        if (searchResult.artifacts.length) {
          childObject[`${childName}_value`] = searchResult.artifacts[0].artifact_name;
        }
        optionData[parentName].push(childObject);
      }
    }
  }

  private mergeData(target, data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        target[key] = data[key];
      }
    }
  }

  private async setFieldsFromOtherObject(
    object,
    fieldParameters: FieldParameters,
    fieldReference: Reference,
    editObjectDialogData: EditObjectDialogData) {
    const addNewParams: AddNewParams = fieldReference.add_new_params;
    const copyFields = addNewParams ? addNewParams.copy_fields || [] : [];
    if (copyFields?.length && copyFields[0].copy_source_type === 'prev_object' && object.artifact_id) {
      // This will make sure editing existing publish events works
      return;
    }
    for (const copyField of copyFields) {
      const sourceType = copyField.copy_source_type;
      const sourceField = copyField.copy_source_field;
      const targetField = copyField.copy_target_field;
      const copyDefaultValue = copyField.copy_default_value;
      const otherObject = await this.checkSetPrevObjectGetOtherObject(fieldParameters, editObjectDialogData, sourceType);
      const isCopyFieldException = this.checkCopyFieldException(copyField, otherObject);
      if (isCopyFieldException || !(sourceField && targetField)) {
        continue;
      }
      let sourceValue = otherObject ? this.fieldValueHandler.getFieldValue(otherObject, sourceField) : copyDefaultValue;
      sourceValue = this.checkGetTransformedValue(copyField, sourceValue);
      this.fieldValueHandler.setFieldValue(object, targetField, sourceValue,  null,'.');
    }
  }

  private checkCopyFieldException(copyField: CopyField, object) {
    let res = false;
    if (copyField.exception) {
      const exceptionCheckValue = this.fieldValueHandler.getFieldValue(object, copyField.exception.exception_field);
      if (exceptionCheckValue === copyField.exception.exception_value) {
        res = true;
      }
    }
    return res;
  }

  private checkGetTransformedValue(copyField: CopyField, value) {
    const transformType = copyField.copy_transform_type;
    if (transformType) {
      if (transformType === '!') {
        value = !value;
      }
    }
    return value;
  }

  private async checkSetPrevObjectGetOtherObject(
    fieldParameters: FieldParameters,
    editObjectDialogData: EditObjectDialogData,
    sourceType: string) {
    let otherObject = fieldParameters.sectionsContainer.rootObject;
    if (sourceType === 'prev_object') {
      if (!fieldParameters.prevObject) {
        await this.setPrevObject(editObjectDialogData);
      }
      otherObject = fieldParameters.prevObject;
    }
    return otherObject
  }

  private async setPrevObject(editObjectDialogData: EditObjectDialogData) {
    const searchParams = {
      metaField: editObjectDialogData.fieldParameters.field,
      fieldParameters: editObjectDialogData.fieldParameters
    };
    const options = await this.optionsService.searchOptions(searchParams);
    if (options && options.length) {
      const params = new GetArtifactParams();
      params.artifact_id = options[0].artifact_id;
      editObjectDialogData.fieldParameters.prevObject = await this.cms.getArtifact(params);
    }
  }

}
