// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../../node_modules/video.js/dist/video-js.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "kit-dms-video .video-js + .video-js {\n    margin-top: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/css/DMSVideo.css"],"names":[],"mappings":"AAEA;IACI,eAAe;AACnB","sourcesContent":["@import '~video.js/dist/video-js';\n\nkit-dms-video .video-js + .video-js {\n    margin-top: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
