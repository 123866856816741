<!-- Not sure if all those div tags can be removed, they used to contain the ngIfs -->
@if (operationField) {
  <div>
    @switch (operationField.input_type) {
      @case (FieldInputType.INLINE) {
        <div>
          @for (subField of operationField.sub_fields; track subField; let i = $index) {
            <app-edit-field-inline-field
              [displayRequiredIndication]="displayRequiredIndication"
              [subFieldIndex]="i"
              [sectionsContainer]="sectionsContainer"
              [object]="operationObject"
              [field]="subField"
            ></app-edit-field-inline-field>
          }
        </div>
      }
      @case (FieldInputType.INLINE_ARRAY) {
        <div>
          <app-edit-field-inline-array
            [displayRequiredIndication]="displayRequiredIndication"
            [sectionsContainer]="sectionsContainer"
            [object]="sectionsContainer.rootObject"
            [field]="operationField"
          ></app-edit-field-inline-array>
        </div>
      }
      @default {
        <div>
          <app-edit-field
            [displayRequiredIndicaton]="displayRequiredIndication"
            [sectionsContainer]="sectionsContainer"
            [object]="sectionsContainer.rootObject"
            [field]="operationField"
          ></app-edit-field>
        </div>
      }
    }
  </div>
}
