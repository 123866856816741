<div class="inline-array-header-item">
    @if (container.values[label.key] !== '' && container.values[label.key] !== null && container.values[label.key] !== undefined) {
      <div class="item"
           [ngClass]="{'labelWithIcon': label.icon === '{conceptIcon}'}">
        @if (label.icon) {
          <span><i class="{{container.arrayItem.icon}}">{{container.arrayItem['icon_frame']}}</i></span>
        }

        <span class="tableView {{label.name}} {{labelClass}}"
              [ngClass]="{'textLabel': label.type === 'text' &&
              label.prefix && label.prefix[0].indexOf('TRANS__') !== -1}">
          @if (label.prefix) {
            <span class="prefix-label"
                  [ngClass]="{'titleInEdit': label.prefix[0].indexOf('TRANS__') === 0}">
          <app-inline-array-header-sep [sepValue]="label.prefix"></app-inline-array-header-sep>
        </span>
          }
        <span class="label-content">
          <span class="header-value-container"
                [ngClass]="{'no-prefix': !label.prefix}">
            <app-inline-array-header-value
              class="header-value-content"
              [label]="label"
              [container]="container"
            ></app-inline-array-header-value>
            @if (label.append) {
              <span class="append">
              <app-inline-array-header-sep [sepValue]="label.append"></app-inline-array-header-sep>
            </span>
            }
          </span>
        </span>
      </span>
      </div>
    }
</div>
