import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {AdminUsersService} from './admin-users.service';
import {
  PrimusTableComponent,
  SelectionChangedEvent,
  TableColumn
} from '../../shared/primus-tables/primus-table/primus-table.component';
import {AdminUsersFilterComponent, ListType, UserAction} from './admin-users-filter/admin-users-filter.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {MetaField} from '../../core/definitions/meta-field';
import {AddNewParams, Reference} from '../../core/definitions/reference';
import {ModelsService} from '../../core/models.service';
import User from './User';
import AdminUsersDataSource from './admin-users-data-source';
import {ProgressDialogComponent} from 'src/app/shared/progress-dialog/progress-dialog.component';
import {OptionsDialogService} from '../../object-edit/options-dialog.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {SuperObjectModel} from '../../core/definitions/super-object-model';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements AfterViewInit {

  dataSource: AdminUsersDataSource;
  columns: Array<TableColumn<Partial<User>>>;

  parent;

  selectedUsers: SuperObjectModel[];
  private selectedUserIds: Array<string>;
  private filterSettings: any;

  private loader?: MatDialogRef<ProgressDialogComponent>;

  @ViewChild(PrimusTableComponent) dataTable: PrimusTableComponent<any>;
  @ViewChild(AdminUsersFilterComponent) userFilter: AdminUsersFilterComponent;

  constructor(private readonly usersService: AdminUsersService,
              private readonly dialog: MatDialog,
              private readonly modelsService: ModelsService,
              private readonly optionsDialogService: OptionsDialogService,
              private readonly primusRouter: PrimusRouterService) {
    this.dataSource = new AdminUsersDataSource(this.usersService);
    this.columns = [];
    this.selectedUserIds = [];
    this.selectedUsers = [];
  }

  ngAfterViewInit() {
    this.loadUsers();
  }

  get isAdmin(): boolean {
    return this.usersService.isAdmin;
  }

  handlePageChange(event) {
    this.loadUsers(event.pageSize, event.pageIndex);
  }

  handleFilterChanged(filterSettings: any) {
    this.filterSettings = filterSettings;
    this.loadUsers();
  }

  handleSelectionChanged(event: SelectionChangedEvent<Partial<User>>): void {
    let selected: Array<string> = [];
    let selectedUsers: SuperObjectModel[] = [];
    if (event && event.selected) {
      selected = event.selected.map(s => s.artifact_id);
      selectedUsers = event.selected.map(user => <SuperObjectModel>user['source']);
    }
    this.selectedUserIds = selected;
    this.selectedUsers = selectedUsers;
    this.usersService.setUsersSelected(this.selectedUserIds.length > 0);
  }

  async handleListTypeChanged(listType: ListType): Promise<void> {
    this.parent = {
      path: 'admin',
      adminType: 'users',
      hasSubMenus: true,
      target_params: {admin_type: 'users'},
      currentState: 'users'
    };
    this.columns = await this.usersService.getUserTableColumns(listType === ListType.AVATAR_LIST);
    this.loadUsers();
  }

  async handleCreateUserClicked() {
    const fp = new FieldParameters();
    fp.object = await this.modelsService.getModelAsync('user');
    fp.field = {} as MetaField;
    fp.field.reference = new Reference();
    fp.field.reference.object_type = 'user';
    fp.field.reference.is_hierarchic = false;
    fp.field.reference.add_new_params = new AddNewParams();
    fp.field.reference.add_new_params.new_object_type = 'user';

    const user = await this.optionsDialogService.createOption(fp);
    if (!!user) {
      this.loadUsers();
    }
  }

  async handleEditUserClicked(user: Partial<User>): Promise<void> {
    await this.primusRouter.navigateState('user-profile', {
      userId: user.artifact_id
    });
  }

  async handleActionOnSelectedUsers(action: UserAction): Promise<void> {
    switch (action) {
      case UserAction.DEACTIVATE_USERS:
        await this.activateOrDeactivateSelectedUsers();
        this.loadUsers();
        break;
      case UserAction.ACTIVATE_USERS:
        await this.activateOrDeactivateSelectedUsers(true);
        this.loadUsers();
        break;
      default:
        console.error('Unknown action-type');
    }
  }

  private async activateOrDeactivateSelectedUsers(activate: boolean = false): Promise<void> {
    if (this.dataSource && this.selectedUserIds) {
      const selectedUsers = await this.usersService.getUsersWithId(this.selectedUserIds);
      for (const user of selectedUsers.filter(u => u.deactivated === activate)) {
        if (activate) {
          await this.usersService.activateUser(user);
        } else {
          await this.usersService.deactivateUser(user);
        }
      }
    }
  }

  private startLoading(): void {
    if (!this.loader) {
      this.loader = this.dialog.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
    }
  }

  private stopLoading(): void {
    if (this.loader) {
      this.loader.close();
      this.loader = undefined;
    }
  }

  private loadUsers(pageSize: number = 25, pageIx: number = 0) {

    if (!this.dataTable) {
      return;
    }

    const paginator = this.dataTable.getPaginator();

    if (paginator) {
      pageSize = paginator.pageSize;
      pageIx = paginator.pageIndex;
    }

    this.startLoading();

    this.dataSource.loadUsers(pageSize, pageIx, this.filterSettings).then(() => {
      this.dataSource.numUsersSubject.subscribe(num => {
        // Make sure the number of objects in the paginator
        // matches the total number of objects fetched.
        if (paginator) {
          paginator.length = num;
        }
      });
      setTimeout(() => {
        this.stopLoading();
      }, 300);
    });
  }
}
