import {Component, Input, OnInit} from '@angular/core';
import {SearchResultViewType} from '../../../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-filter-menu-small',
  templateUrl: './search-filter-menu-small.component.html',
  styleUrls: ['./search-filter-menu-small.component.scss']
})
export class SearchFilterMenuSmallComponent implements OnInit {

  dropdownMenu = false;
  searchViewClass = 'content-list';
  @Input() searchContainer: SearchContainer;
  @Input() showFilters: boolean;

  constructor() {
  }

  ngOnInit() {
    if (this.searchContainer.searchResultViewName === SearchResultViewType.SELECTOR) {
      this.searchViewClass = 'selector';
    }
  }

}
