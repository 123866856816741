@if (fieldTitle) {
  <div class="field-title-component">

    <div class="field-title-component__text">
      <span [translate]="fieldTitle"></span>

      @if (!field.is_required && edit && !displayRequiredIndication) {
        <span class="field-title-component__required" translate="TRANS__FIELD__IS_OPTIONAL"></span>
      }
      @else if(field.is_required && edit && displayRequiredIndication) {
        <span class="field-title-component__required" translate="TRANS__FIELD__IS_REQUIRED"></span>
      }
    </div>
    @if (showToolTip && edit && editable) {
      <app-field-tool-tip
        class="field-title-component__tooltip"
        [field]="field" [rootObject]="rootObject"></app-field-tool-tip>
    }
  </div>
}
