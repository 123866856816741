import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges, OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AConst} from '../../../core/a-const.enum';
import {FieldValueService} from '../../../core/field-value.service';
import {FieldStateService} from '../../../core/field-state.service';
import {TranslateService} from '@ngx-translate/core';
import {FieldValidationService} from '../../../core/field-validation.service';
import {InFocus} from '../../in-focus';
import {CurrentQuery} from '../../../core/definitions/current-query';
import {Reference} from '../../../core/definitions/reference';
import {UiToolsService} from '../../../core/ui-tools.service';
import {IfType} from '../../../core/definitions/field-if';
import {FieldConditionService} from '../../../core/field-condition.service';
import {CrudService} from "../../../core/crud.service";
import {BaseModel} from "../../../core/definitions/base-model";

export interface ShowOptions {
  show: boolean;
  initialized: boolean;
}


@Component({
  selector: 'app-edit-field-select-query',
  templateUrl: './edit-field-select-query.component.html',
  styleUrls: ['./edit-field-select-query.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSelectQueryComponent)
    }
  ]
})
export class EditFieldSelectQueryComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  refProp: string;
  isArray = false;
  AConst = AConst;
  fieldKey: string;
  placeHolderSearchFor: string;
  placeholder: string;
  onChange: any;
  private clickListenerId: string;

  @Input() sectionsContainer: SectionsContainer;
  @Input() fieldParameters: FieldParameters;
  @Input() reference: Reference;
  @Input() query: CurrentQuery;
  @Input() inFocus: InFocus;
  @Input() showOptions: ShowOptions;
  @Input() fullSearch: any;
  @Input() temporaryFieldValueName: string;
  @Output() queryEvent = new EventEmitter<object>();

  @ViewChild('fieldSelectInput') fieldSelectInput: ElementRef;

  constructor(private readonly fieldValue: FieldValueService,
              private readonly fieldState: FieldStateService,
              private readonly translate: TranslateService,
              public readonly fieldValidation: FieldValidationService,
              private readonly fieldCondition: FieldConditionService,
              private readonly uiTools: UiToolsService,
              private readonly crud: CrudService) {
  }

  ngOnChanges() {
    this.initSelectQueryData().then();
  }

  onInputKey(event: any) {
    if (event.key !== 'Tab') {
      this.fullSearch.value = false;
      if (!this.showOptions.show) {
        this.toggleShowOptions(true);
      } else {
        if (this.reference.is_hierarchic) {
          if (event.key === 'ArrowDown') {
            this.queryEvent.emit({type: 'setNodeToFocusFromInput'});
          }
        } else if (event.key === 'Enter') {
          this.queryEvent.emit({type: 'keyPressed', data: event.key});
          document.getElementById(this.fieldKey).blur();
        }
        event.preventDefault();
      }
    }

    if (event.key === 'Escape' || event.key === 'Esc') {
      this.toggleShowOptions(false);
    }
  }

  onInputKeyDown(event: any) {
    if (event.key === 'Tab') {
      this.queryEvent.emit({type: 'setTabToNextField'});
    } else if (event.key === 'ArrowDown') {
      this.queryEvent.emit({type: 'keyPressed', data: event.key});
    } else if (event.key === 'ArrowUp') {
      this.queryEvent.emit({type: 'keyPressed', data: event.key});
    }
  }

  refreshAndShowOptions() {
    this.toggleShowOptions(true, true);
    this.setInputToFocus();
  }

  clearField() {
    this.queryEvent.emit({type: 'setClearField'});
  }

  toggleShowOptions(setValue: any, fullSearch?: boolean) {
    if (setValue === undefined) {
      setValue = !this.showOptions.show;
    }
    this.fullSearch.value = fullSearch;
    this.showOptions.show = setValue;
    this.showOptions.initialized = true;

    if (this.showOptions.show) {
      this.clickListenerId = this.uiTools.addDocumentClickListener((event: any) => {
        this.checkOutsideClick(event);
      }, 'ignoreClicksSelect-' + this.fieldParameters.field.name);
    } else {
      this.uiTools.removeDocumentClickListener(this.clickListenerId);
      this.fieldSelectBlur();
    }
  }

  onFieldSelectFocus() {
    this.queryEvent.emit({type: 'fieldFocus'});
  }

  get textValue() {
    let res: string;
    if (!this.isSingleItemArray) {
      res = this.fieldValue.getValueCompanion(this.fieldParameters.object, this.fieldParameters.field.name);
    } else {
      const item = this.firstArrayItemNotDeleted;
      if (item) {
        res = this.fieldValue.getValueCompanion(item, this.fieldParameters.field.inline.prop);
      }
    }
    return res;
  }

  setInputToFocus() {
    this.fieldSelectInput.nativeElement.focus();
  }

  get isSingleItemArray() {
    return this.fieldValue.isSingleItemArray(this.fieldParameters);
  }

  get fieldArray(): BaseModel[] {
    return this.fieldValue.getFieldValue(this.fieldParameters.rootObject, this.fieldKey);
  }

  get isDisabled(): boolean {
    return this.fieldCondition.runIf(IfType.DISABLE, this.fieldParameters).result;
  }

  private async initSelectQueryData() {
    const fieldInfo = this.fieldParameters.field;
    this.refProp = this.reference.ref_prop || AConst.ARTIFACT_ID;
    this.isArray = this.getIsArray();
    this.fieldKey = this.fieldState.getFieldKeyWhileDrawingInputs(
      fieldInfo, this.fieldParameters.index, this.fieldParameters.parentIndex);
    this.placeholder = this.fieldState.getFieldTitle(fieldInfo, this.fieldParameters.rootObject, true, true);
    this.placeHolderSearchFor = this.translate.instant('TRANS__FIELD_SELECT__SEARCH_PLACEHOLDER');

  }

  private fieldSelectBlur() {
    this.queryEvent.emit({type: 'fieldBlur'});
  }

  private getIsArray() {
    return this.fieldParameters.field.field_type === AConst.ARRAY && !this.isSingleItemArray;
  }

  private get firstArrayItemNotDeleted(): BaseModel {
    let res: BaseModel;
    for (const element of this.fieldArray) {
      const item = element;
      if (!this.crud.getDestroy(item)) {
        res = item;
        break;
      }
    }
    return res;
  }

  private checkOutsideClick(evt: Event) {
    let found: boolean;
    if (this.sectionsContainer.isSecondDialog) {
      found = this.uiTools.findClassNameRecursively(evt, 'ignoreClicksSecondModal-' + this.fieldParameters.field.name) ||
        this.uiTools.findClassNameRecursively(evt, 'ignoreClicksModal-' + this.fieldParameters.field.name);
    } else if (this.sectionsContainer.isDialog) {
      found = this.uiTools.findClassNameRecursively(evt, 'ignoreClicksModal-' + this.fieldParameters.field.name) ||
        this.uiTools.findClassNameRecursively(evt, 'ignoreClicks-' + this.fieldParameters.field.name);
    } else {
      found = this.uiTools.findClassNameRecursively(((evt.target) ? evt.target : evt), 'ignoreClicks-' + this.fieldParameters.field.name);
    }
    if (!found) {
      this.toggleShowOptions(false);
      this.uiTools.removeDocumentClickListener(this.clickListenerId);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(/*fn: any*/): void {
    // No implementation necessary
  }

  setDisabledState(/*isDisabled: boolean*/): void {
    // No implementation necessary
  }

  writeValue(/*obj: any*/): void {
    // No implementation necessary
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }
}
