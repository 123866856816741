import {Component, Input, OnInit} from '@angular/core';
import {SuperObjectModel} from "../../../core/definitions/super-object-model";
import {DateToolsService} from "../../../core/date-tools.service";

@Component({
  selector: 'app-collection-log',
  templateUrl: './collection-log.component.html',
  styleUrls: ['./collection-log.component.scss']
})
export class CollectionLogComponent  implements OnInit {
  @Input() object: SuperObjectModel;

  constructor(private dateTools: DateToolsService) {
  }

  logItems: any[] = [];
  displayedColumns = ['updated_at', 'updated_by', 'to_collection', 'from_collection'];

  ngOnInit(): void {
    const logData = this.object.collection_log || [];
    this.logItems = logData.map(item => {
      return {
        updated_at: this.dateTools.isoDateToString(item.updated_at),
        updated_by: item.updated_by_id_value,
        to_collection: item.to_collection_id_value,
        from_collection: item.from_collection_id_value
      }
    });
  }
}
