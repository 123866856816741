import {Component, Input, OnChanges} from '@angular/core';
import {SearchObject} from '../../core/definitions/search-object';

@Component({
  selector: 'app-search-tile-overview-fields',
  templateUrl: './search-tile-overview-fields.component.html',
  styleUrls: ['./search-tile-overview-fields.component.scss']
})
export class SearchTileOverviewFieldsComponent implements OnChanges {

  @Input() searchObject: SearchObject;
  @Input() viewName: string;

  constructor() {
  }

  ngOnChanges(): void {
  }

}
