import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FieldConditionService} from '../../core/field-condition.service';
import {AConst} from '../../core/a-const.enum';
import {FieldActionService} from '../field-action.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {FieldActionParameters} from '../field-action-parameters';
import {ActionButtonField} from '../../core/definitions/object-view';
import {IfType} from '../../core/definitions/field-if';
import {InlineViewService} from "../../core/inline-view.service";
import {InlineViewItem} from "../../core/definitions/inline-view-item";
import {SuperObjectModel} from "../../core/definitions/super-object-model";
import {BaseModel} from "../../core/definitions/base-model";

@Component({
  selector: 'app-field-action-button',
  templateUrl: './field-action-button.component.html',
  styleUrls: ['./field-action-button.component.scss']
})
export class FieldActionButtonComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() grandParentObject: SuperObjectModel;
  @Input() object: BaseModel;
  @Input() field: ActionButtonField;
  @Input() fieldTitle: string;
  @Input() edit: boolean;
  @Input() index: number;
  @Input() parentIndex: number;
  @Output() closeEdit = new EventEmitter<any>();
  @Output() actionRun = new EventEmitter<any>();

  AConst = AConst;
  parameters: FieldActionParameters;
  inlineView: InlineViewItem;

  constructor(private fieldCondition: FieldConditionService,
              private fieldActionService: FieldActionService,
              private inlineViewService: InlineViewService) {
  }

  ngOnChanges() {
    this.init().then();
  }

  async runAction() {
    if (this.parameters.field) {
      await this.fieldActionService.runActions(this.parameters);
      if (this.actionRun) {
        this.actionRun.emit();
        if (this.parameters.field.name === 'copy_template_group') {
          this.scrollToBottom();
        }
      }
    } else {
      console.log('Sections container missing!');
    }
  }

  get isDisabled(): boolean {
    return this.parameters.field ? this.fieldCondition.runIf(IfType.DISABLE, this.parameters).result : true;
  }

  get canShow(): boolean {
    return this.parameters.field ? this.fieldCondition.runIf(IfType.SHOW, this.parameters).result : false;
  }

  private async init() {
    this.parameters = new FieldActionParameters();
    const object = this.object || this.sectionsContainer.rootObject;
    this.inlineView = await this.inlineViewService.getInlineViewForField(object, this.field);
    if (this.sectionsContainer) {
      this.parameters.sectionsContainer = this.sectionsContainer;
      this.parameters.rootObject = this.sectionsContainer.rootObject;
      this.parameters.grandParentObject = this.grandParentObject;
      this.parameters.object = object;
      this.parameters.field = this.field;
      this.parameters.edit = this.edit;
      this.parameters.parentIndex = this.parentIndex;
      this.parameters.index = this.index;
      this.parameters.trigger = 'on_click';
      this.parameters.closeEdit = this.closeEdit;
    } else {
      console.log('Sections container missing!');
    }
  }

  private scrollToBottom() {
    const lastElement = document.getElementById('lastGroup');
    if (lastElement) {
      lastElement.scrollIntoView(false)
    }
  }
}
