<div class="advanced-search-query-field__container">
  <div class="advanced-search-query-field">
    <form class="advanced-search-query-field__form">
      <mat-form-field appearance="outline" class="form grow">
        <mat-label translate="TRANS__ADVANCED_SEARCH__QUERY_BUILDER_FIELDS"></mat-label>
        <input
          autocomplete="off"
          class="input grow"
          matInput
          type="text"
          (blur)="hideDropdown($event, '#field-options-view')"
          (input)="filterFieldDropdownContent($event)"
          (focus)="showFieldDropdown($event)"
          [value]="fieldQuery()"
        >
       @if (field.field_title !== '') {
         <mat-icon class="icon" matSuffix (click)="clearSelectedField()">close</mat-icon>
       }
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>

      <div class="advanced-search-query-field__dropdown" [ngClass]="{'show': showFieldSelector}">
        <app-advanced-search-query-builder-options-view
          (optionSelected)="setSelectedField($event)"
          [filterQuery]="fieldQuery"
          [options]="fieldOptions"
          [parentGroup]="parentGroup"
          [rootGroup]="rootGroup"
          [searchContainer]="searchContainer"
          filterMode="self"
          id="field-options-view"
        ></app-advanced-search-query-builder-options-view>
      </div>
    </form>

    <mat-form-field appearance="outline" class="form operator">
      <mat-label translate="TRANS__ADVANCED_SEARCH__QUERY_BUILDER_OPERATOR"></mat-label>
      <mat-select class="select" [(ngModel)]="advancedFieldQuery.operator_selected" [disabled]="advancedFieldQuery.operators.length === 0">
        @for (operator of advancedFieldQuery.operators; track $index) {
          <mat-option class="option" value="{{operator.operator}}" (click)="clearFieldValue()">{{operator.operatorTitle | translate}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <app-advanced-search-query-builder-value-input
      class="grow-flex value-input"
      [disabled]="valueFieldDisabled"
      [fieldType]="advancedFieldQuery.input_type"
      [fieldOperator]="advancedFieldQuery.operator_selected"
      [query]="valueQuery"
      [value]="advancedFieldQuery.value"
      [valueDisplay]="advancedFieldQuery.valueDisplay"
      [valueIsValid]="advancedFieldQuery.valid"
      [valueOptions]="valueOptions"
      (arrayValueInput)="arraySelectionChanged($event)"
      (intervalValueInput)="setIntervalValue($event)"
      (queryChanged)="filterValueDropdownContent($event)"
      (valueCleared)="clearFieldValue()"
      (valueInput)="setTextValue($event)"
      (valueSelected)="setSelectedValue($event)"
    >
    </app-advanced-search-query-builder-value-input>
  </div>

  <button mat-icon-button (click)="removeField()">
    <mat-icon>remove_circle_outline</mat-icon>
  </button>
</div>

@if (showFieldSelector || showValueSelector) {
  <!-- //NOSONAR --><div class="backdrop" (click)="hideDropdowns()"></div>
}
