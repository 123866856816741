import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {ModelFactoryService} from './model-factory.service';
import {ObjectViewAndData, ObjectAndOperations} from './definitions/object-view';
import {SuperObjectModel} from './definitions/super-object-model';
import {CopyObjectParams} from './definitions/copy-object-params';
import {
  GetArtifactParams,
  GetArtifactViewAndDataParams
} from './definitions/get-artifact-params';
import {GetArtifactsParams} from './definitions/get-artifacts-params';
import {BaseModel} from './definitions/base-model';
import {ContextList} from './definitions/context-list';
import {Concept} from './definitions/concepts';
import {GetArtifactAndOperationsParams} from './get-artifact-and-operations-params';
import {AccessTokenService} from './access-token.service';
import {CrudService} from "./crud.service";

@Injectable({
  providedIn: 'root'
})
export class ObjectStorageService {

  constructor(private cms: CmsApiService,
              private modelFactory: ModelFactoryService,
              private accessTokenService: AccessTokenService,
              private crud: CrudService) {
  }

  async storeObject(objectIn: BaseModel): Promise<any> {
    const metaType = objectIn.meta_type;
    const subArtTypes = ['sub_model', 'item_model'];
    // This conversion removes attributes that should not be included when storing
    const art = this.getCleanObject(objectIn);

    if (metaType === 'context_list') {
      return await this.cms.saveSubArtifacts(<ContextList>art);
    } else if (subArtTypes.indexOf(metaType) !== -1) {
      return await this.cms.saveSubArtifact({subArtifact: art});
    } else if (art.meta_type === 'template_model') {
      if (art.object_type !== 'TemplateGroup') {
        return await this.cms.putMetaObject({object: art});
      } else {
        return await this.cms.putTemplateGroup({templateGroup: art});
      }
    } else if (metaType === 'concept') {
      return await this.cms.putConcept(<Concept>art);
    } else if (art.object_type === 'user') {
      return await this.cms.saveUser({
        artifact: art,
        jwt: this.accessTokenService.getToken()
      });
    } else {
      return await this.cms.saveArtifact({artifact: art});
    }
  }

  async loadObject(objectId: string): Promise<SuperObjectModel> {
    let res = null;
    let object: any;
    const params = new GetArtifactParams();
    params.artifact_id = objectId;
    if (objectId.startsWith('template_group')) {
      object = await this.cms.getTemplateGroup(params);
    } else {
      object = await this.cms.getArtifact(params);
    }
    if (object) {
      res = this.setObjectProps(object);
    }
    return res;
  }

  async loadObjectWithViewData(objectId:string, getOriginalObject: boolean, templateGroupId?: string): Promise<ObjectViewAndData> {
    const params = new GetArtifactViewAndDataParams();
    params.artifact_id = objectId;
    params.get_original_artifact = getOriginalObject;
    params.template_group_id = templateGroupId;

    const objectViewAndData = await this.cms.getArtifactViewAndData(params);
    try {
      const obj = await this.setObjectProps(objectViewAndData.artifact);
      objectViewAndData.artifact = <SuperObjectModel>obj;
      return objectViewAndData;
    } catch (error) {
      throw Error(error);
    }
  }

  async loadObjectWithViewDataAndOperations(getOperationObjectsParams: GetArtifactAndOperationsParams): Promise<ObjectAndOperations> {
    const objectAndOperations = await this.cms.getObjectAndOperations(getOperationObjectsParams);
    try {
      const obj = await this.setObjectProps(objectAndOperations.artifact);
      objectAndOperations.artifact = <SuperObjectModel>obj;
      return objectAndOperations;
    } catch (error) {
      throw Error(error);
    }
  }

  async loadObjects(objIds: string[]): Promise<Array<any>> {
    const params = new GetArtifactsParams();
    let objects: Array<any> = [];
    params.artifact_ids = objIds;
    if (objIds[0].startsWith('template_group')) {
      const object = await this.cms.getTemplateGroup({artifact_id: objIds[0]});
      objects.push(object);
    } else {
      objects = await this.cms.getArtifacts(params) as Array<any>;
    }
    return await this.setObjectsProps(objects);
  }

  async deleteObject(object: SuperObjectModel): Promise<void> {
    if (object.meta_type === 'template_model') {
      await this.cms.deleteTemplateGroup({templateGroupId: object.artifact_id});
    } else if (object.meta_type === 'sub_model') {
      this.crud.setDestroy(object, true);
      await this.cms.saveSubArtifact({ subArtifact: object});
    } else {
      await this.cms.deleteArtifact({artifact_id: object.artifact_id});
    }
  }

  async copyObjects(objectIds: string[]): Promise<Array<any>> {
    return await Promise.all(
      objectIds.map(id => ({
        ...new CopyObjectParams(),
        artifact_id: id,
      } as CopyObjectParams))
        .map(params => this.cms.copyArtifact(params))
        .map(async copy => await this.setObjectProps(await copy))
    );
  }

  // Return a "clean" object without $$ attributes
  getCleanObject(object: BaseModel): BaseModel {
    const res = {} as BaseModel;
    for (const [key, valueCheck] of Object.entries(object)) {
      if (key.startsWith('$$')) {
        continue;
      }
      let value = valueCheck;
      if (!valueCheck) {
        // do nothing, else there may be convert errors
      } else if (Array.isArray(valueCheck)) {
        value = [];
        for (const item of valueCheck) {
          if (typeof item === 'object') {
            value.push(this.getCleanObject(item));
          } else {
            value.push(item);
          }
        }
      } else if (typeof valueCheck === 'object') {
        value = this.getCleanObject(valueCheck);
      }
      res[key] = value;
    }
    return res;
  }
  private async setObjectProps(object: SuperObjectModel): Promise<BaseModel> {
    return await this.modelFactory.setModelItemAsync(object.object_type, object);
  }

  private async setObjectsProps(objects: Array<any>): Promise<Array<any>> {
    if (!objects) {
      return [];
    }
    return await Promise.all(objects.map(object => this.setObjectProps(object)));
  }
}
