<div class="select-object-type-button-container mat-form-field-wrapper">

  <button mat-stroked-button
          type="button"
          class="filter-selection-button"
          [matMenuTriggerFor]="objTypeFilterMenu"
          [disabled]="objectTypes.length === 0">
    {{ buttonLabel | translate }}
  </button>


  <mat-menu #objTypeFilterMenu="matMenu">
    <ng-container *ngFor="let group of objectTypes">

      <button mat-menu-item
              type="button"
              [mat-menu-trigger-for]="subMenu"
              (click)="toggleGroupSelection(group, $event)">
        <mat-checkbox [checked]="objectTypeSelectionState.isGroupSelected(group.groupId)"
                      [indeterminate]="objectTypeSelectionState.isGroupPartiallySelected(group.groupId)">
        </mat-checkbox>
        {{ group.groupName | translate }}
      </button>

      <mat-menu #subMenu="matMenu">
        <button mat-menu-item
                type="button"
                *ngFor="let option of group.options"
                (click)="toggleOptionSelection(option, $event)">
          <mat-checkbox [checked]="objectTypeSelectionState.isOptionSelected(group.groupId, option.id)">
          </mat-checkbox>
          {{ option.name | translate }}
        </button>
      </mat-menu>

    </ng-container>
  </mat-menu>

  <mat-chip-listbox class="chip-list" [selectable]="false">
    <mat-chip-option class="chip"
              *ngFor="let opt of objectTypeSelectionState.getSelectedOptions()"
              [selectable]="false"
              (removed)="toggleOptionSelection(opt)"
              removable>
      {{ opt.name | translate }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>
  </mat-chip-listbox>

</div>
