import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ConceptTypeMainMenu, ConceptTypeMenu} from '../concept-type-menu';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';

@Component({
  selector: 'app-admin-concept-concept-list-selector',
  templateUrl: './admin-concept-concept-list-selector.component.html',
  styleUrls: ['./admin-concept-concept-list-selector.component.scss']
})
export class AdminConceptConceptListSelectorComponent implements OnChanges {

  @Input() conceptTypeMainMenus: MatTableDataSource<ConceptTypeMainMenu>;
  @Input() conceptsContainer: ConceptsContainer;
  @Output() conceptTypeMenuChange = new EventEmitter<ConceptTypeMenu>();

  currentConceptTypeMenu: ConceptTypeMenu;
  listColumns: string[] = ['letter', 'list'];

  constructor() { }

  ngOnChanges(): void {
    if (this.conceptTypeMainMenus.data.length) {
      this.setCurrentConceptTypeMenu(this.conceptTypeMainMenus.data[0].subMenus[0]);
    }
  }

  toggleConcept(conceptTypeMenu: ConceptTypeMenu) {
    if (conceptTypeMenu.subMenus.length) {
      conceptTypeMenu.open = !conceptTypeMenu.open;
      if (conceptTypeMenu.open) {
        this.setCurrentConceptTypeMenu(conceptTypeMenu.subMenus[0]);
      }
    } else {
      this.setCurrentConceptTypeMenu(conceptTypeMenu);
    }
  }

  private setCurrentConceptTypeMenu(conceptTypeMenu: ConceptTypeMenu) {
    this.currentConceptTypeMenu = conceptTypeMenu;
    this.conceptTypeMenuChange.emit(conceptTypeMenu);
  }

}
