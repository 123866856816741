// import * as angular from 'angular';
// import {setAngularJSGlobal} from '@angular/upgrade/static';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from "@sentry/angular";




// setAngularJSGlobal(angular);
if (environment.production) {
  enableProdMode();

  // Init Sentry
  Sentry.init({
    dsn: "https://2ee0eb66fedd45ff8a39b781bb7d8779@o1289125.ingest.sentry.io/6634740",
    integrations: [
      Sentry.browserTracingIntegration({

      }),
    ],
    debug: false,
    environment: (environment.production)? "production":"development",
    release: environment.version,

    tracesSampleRate: 0.2,
  });
}



platformBrowserDynamic().bootstrapModule(AppModule).catch((e) => {
  console.error(e);
  // HACK: Angular-white-screen-of-death workaround.
  setTimeout(() => {
    window.location.reload();
  }, 2000);
});
