import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../../core/primus-route.service';
import User from '../User';
import {AdminUsersService, RightsLevel} from '../admin-users.service';
import {MediaHelperService} from '../../../core/media-helper.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserDeletedDialogComponent} from './user-deleted-dialog/user-deleted-dialog.component';
import {Subscription} from 'rxjs';
import {CommonsService} from '../../../core/commons.service';
import {ProfileImageUploaderComponent} from './profile-image-uploader/profile-image-uploader.component';
import {ProgressDialogComponent} from '../../../shared/progress-dialog/progress-dialog.component';
import { LoginService } from 'src/app/core/login.service';
import {ImageItem} from '../../../core/definitions/image-item';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {ObjectEditService} from '../../../core/object-edit.service';
import {UserCollectionsAdderComponent} from './user-collections-adder/user-collections-adder.component';

@Component({
  selector: 'app-admin-users-profile-page',
  templateUrl: './admin-users-profile-page.component.html',
  styleUrls: [
    './admin-users-profile-page.component.scss',
    './admin-users-profile-page.component.tablet.scss',
    './admin-users-profile-page.component.phone.scss'
  ]
})
export class AdminUsersProfilePageComponent implements OnInit, OnDestroy {
  private loader?: MatDialogRef<ProgressDialogComponent>;
  private restoreUserSubscription?: Subscription;
  user: User;
  /**
   * The changes to be applied to the user, if any.
   * If no changes has been made, it will be an identical clone of `user`.
   * (Deep-copy)
   */
  changedUser: User;

  editUserInfo: boolean;
  editCollectionRights: boolean;
  editVirtualCollectionRights: boolean;
  editUserRights: boolean;

  headerImageUrl: string;
  profileImageUrl: string;
  rights: Array<RightsLevel>;
  sectionsContainer: SectionsContainer;

  private currentUser = null;

  constructor(private readonly router: PrimusRouteService,
              private readonly mediaHelperService: MediaHelperService,
              private readonly  objectEditService: ObjectEditService,
              private readonly usersService: AdminUsersService,
              private readonly dialog: MatDialog,
              private readonly commons: CommonsService,
              private loginService: LoginService) {
    this.editUserInfo = false;
    this.editCollectionRights = false;
    this.editVirtualCollectionRights = false;
    this.editUserRights = false;
    this.user = new User();
  }

  async ngOnInit(): Promise<void> {
    this.startLoading();
    await this.loadUser(this.router.params.userId);

    this.headerImageUrl = await this.getHeaderImageUrl();

    await this.setupDeletedUserModal();
    this.stopLoading();

    this.loginService.currentUser.subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.restoreUserSubscription && !this.restoreUserSubscription.closed) {
      this.restoreUserSubscription.unsubscribe();
    }
    if (this.loader) {
      this.stopLoading();
    }
  }

  async handleSaveClicked(): Promise<void> {
    this.startLoading();
    try {
      const userId = await this.usersService.saveUser(this.changedUser);
      await this.loadUser(userId);
    } catch (e) {
      console.error('Unable to save user', this.user, e);
    } finally {
      this.stopLoading();
    }
  }

  async handleChangeProfilePhotoClicked(): Promise<void> {
    const uploadSuccess = await this.dialog
      .open(ProfileImageUploaderComponent, {
        panelClass: 'edit-dialog', data: this.changedUser})
      .afterClosed()
      .toPromise();
    if (uploadSuccess) {
      this.startLoading();
      await this.loadUser(this.user.artifact_id);
      this.stopLoading();
    }
  }

  handleCancelClicked(): void {
    this.setSectionsContainer().then();
  }

  handleBackButtonClicked() {
    history.back();
  }

  async handleAddCollectionRightsClicked(collectionType: string) {
    const added = await this.dialog.open(UserCollectionsAdderComponent, {
      panelClass: 'edit-dialog', data: {user: this.changedUser, collectionType: collectionType}})
      .afterClosed()
      .toPromise();
    if (added) {
      await this.handleSaveClicked();
    }
  }

  isECultureUser() {
    return this.user.ekultur_user;
  }

  get isAdmin(): boolean {
    return this.usersService.isAdmin;
  }

  get allowProfileImageChange(): boolean {
    return this.user?.artifact_id === this.currentUser?.artifact_id;
  }

  private async setupDeletedUserModal(): Promise<void> {
    if (this.user.deactivated) {
      this.restoreUserSubscription = this.dialog
        .open(UserDeletedDialogComponent, {
          position: {
            top: '55px'
          },
          hasBackdrop: false,
        })
        .afterClosed()
        .subscribe(doRestoreUser => {
            if (doRestoreUser) {
              this.handleRestoreUser();
            }
          }
        );
    }
  }

  private async loadUser(userId: string): Promise<void> {
    const user = await this.usersService.getOneUser(userId);
    this.user = user ? new User(user) : null;
    this.profileImageUrl = await this.getUserProfileImageUrl();
    await this.setSectionsContainer();
  }

  private async setSectionsContainer() {
    this.sectionsContainer = await this.objectEditService.loadObjectGetSectionsContainer(
      this.user.artifact_id, true);
    this.changedUser = <User>this.sectionsContainer.rootObject;
  }

  private async handleRestoreUser(): Promise<void> {
    this.startLoading();
    try {
      await this.usersService.activateUser(this.user);
    } catch (e) {
      console.error('Unable to restore user', this.user, e);
    } finally {
      this.stopLoading();
    }
  }

  private async getHeaderImageUrl(): Promise<string> {
    // TODO: Load the image the museum has selected, if any.
    //  (When this feature is implemented)
    // Return default-image
    return 'primus-assets/img/profile-header.jpg';
  }

  private async getUserProfileImageUrl(): Promise<string> {
    const imageId = this.user?.images?.filter(i => !!i.image_id)[0]?.image_id;
    if (imageId) {
      const image = new ImageItem();
      image.image_id = imageId;
      const url = await this.mediaHelperService.getImageUrl(image, 'medium');
      if (url) {
        return url;
      }
    }
    return '';
  }

  private startLoading(): void {
    this.loader = this.dialog.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
  }

  private stopLoading(): void {
    if (this.loader) {
      this.loader.close();
      this.loader = undefined;
    }
  }
}
