import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import {FieldConditionService} from '../../core/field-condition.service';
import {FieldStateService} from '../../core/field-state.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {EditFieldSelectComponent} from '../edit-field-select/edit-field-select.component';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {InFocus} from '../in-focus';
import {MetaField} from '../../core/definitions/meta-field';
import {BaseModel} from '../../core/definitions/base-model';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {FieldInputType} from '../../core/definitions/field-input-type.enum';
import {IfType} from '../../core/definitions/field-if';
import {UserCacheService} from '../../core/user-cache.service';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnChanges {
  FieldInputType = FieldInputType;

  fieldParameters: FieldParameters;
  isGenerallyEditable: boolean;
  inFocus: InFocus = new InFocus();
  generalFieldType: string;

  @Input() displayRequiredIndicaton: boolean;
  @Input() sectionsContainer: SectionsContainer;
  @Input() object: BaseModel;
  @Input() grandParentObject: BaseModel;
  @Input() field: MetaField;
  @Input() index: number;
  @Input() parentIndex: number;
  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild(EditFieldSelectComponent) editFieldSelect: EditFieldSelectComponent;

  constructor(private fieldState: FieldStateService,
              private fieldCondition: FieldConditionService,
              private userCacheService: UserCacheService) {

  }

  ngOnChanges() {
    this.init().then();
  }

  get isEditable(): boolean {
    return this.isGenerallyEditable && this.fieldCondition.runIf(IfType.EDIT, this.fieldParameters).result;
  }

  private async init() {
    this.fieldParameters = this.makeFieldParameters();

    const userData = await this.userCacheService.getUserData();
    this.isGenerallyEditable = this.fieldState.isEditable(this.sectionsContainer.rootObject, this.object, this.field, userData);
    this.generalFieldType = this.fieldState.getFieldInputType(
      this.field, this.sectionsContainer.rootObject, this.object, userData);
  }

  private makeFieldParameters() {
    return {
      sectionsContainer: this.sectionsContainer,
      grandParentObject: this.grandParentObject,
      rootObject: this.sectionsContainer.rootObject || <SuperObjectModel>this.object,
      object: this.object,
      field: this.field,
      edit: true,
      index: this.index,
      parentIndex: this.parentIndex,
      canDelete: undefined,
      canAddNew: undefined,
      useMultipleSetter: undefined,
      prevObject: undefined
    } as FieldParameters;
  }
}
