import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';
import {ConceptService} from '../../../core/concept.service';
import {ConceptDialogService, OpenConceptParams} from '../../admin-concept-lists/concept-dialog.service';

@Component({
  selector: 'app-admin-list-action-toolbar',
  templateUrl: './admin-list-action-toolbar.component.html',
  styleUrls: ['./admin-list-action-toolbar.component.scss']
})
export class AdminListActionToolbarComponent implements OnInit {

  @Input() conceptsContainer: ConceptsContainer;
  @Output() updateConceptList = new EventEmitter<void>();

  constructor(private conceptService: ConceptService,
              private conceptDialogService: ConceptDialogService) {
  }

  ngOnInit() {
  }

  async addConcept() {
    const concept = await this.conceptService.createChildConcept(this.conceptsContainer);
    const params: OpenConceptParams = {
      conceptsContainer: this.conceptsContainer,
      concept: concept,
      concept_id: null,
      index: -1,
      callback: (stored: boolean) => {
        if (stored) {
          this.updateConceptList.emit();
        }
      }
    };
    this.conceptDialogService.openConcept(params);
  }

  getConceptsFromCultureHub() {
    this.conceptDialogService.openCultureHubImporter(this.conceptsContainer, hadImports => {
      if (hadImports) {
        this.updateConceptList.emit();
      }
    });
  }

}
