import {Component, OnInit} from "@angular/core";
import {CmsApiService} from "../../../core/cms-api.service";
import {ModelStore} from "../../../core/ModelStore/ModelStore";

@Component({
  selector: 'report-templates',
  templateUrl: './report-templates.component.html',
  styleUrls: ['./report-templates.component.scss']
})
export class ReportTemplatesComponent implements OnInit {
  reportTemplates: any;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore) {

  }

  async ngOnInit(): Promise<void> {
    this.reportTemplates = this.modelStore.findAllModels('report_user_template')
  }

}
