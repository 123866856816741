import {Component, Input, OnChanges} from '@angular/core';
import {SuperObjectModel} from '../../../core/definitions/super-object-model';
import {DateToolsService} from '../../../core/date-tools.service';
import {RefParams, RefService} from '../../../core/ref.service';
import {SuperobjectFolderItem} from '../../../core/definitions/super-object-folder-item';
import {UserCacheService} from '../../../core/user-cache.service';
import {SettingsService} from '../../../core/settings.service';
import {PrimusStateMapperService} from '../../../core/primus-state-mapper.service';

@Component({
  selector: 'app-object-folders',
  templateUrl: './object-folders.component.html',
  styleUrls: ['./object-folders.component.scss']
})
export class ObjectFoldersComponent implements OnChanges {

  @Input() object: SuperObjectModel;

  foldersSet = false;
  folderItems: SuperobjectFolderItem[] = [];
  displayedColumns = ['folder_name', 'folder_type_id', 'created_at', 'created_by'];

  constructor(public primusStateMapper: PrimusStateMapperService,
              private dateTools: DateToolsService,
              private refService: RefService,
              private userCacheService: UserCacheService,
              private settingsService: SettingsService) { }

  ngOnChanges(): void {
    if (!this.foldersSet) {
      this.setFolders().then();
    }
  }

  fromSolrDate(solrDate) {
    return this.dateTools.solrDateToLocalStringDate(solrDate);
  }

  async setFolders() {
    const privateFolderTypeId = this.settingsService.getClientConfig().FOLDER_TYPE_PRIVATE_ID;
    const userData = await this.userCacheService.getUserData();
    const folderItems = (this.object.folders || []).filter(
      folderItem => folderItem.folder_type_id.toLowerCase().indexOf(privateFolderTypeId) === -1 ||
      folderItem.created_by_id === userData['artifact_id']);
    for (const folderItem of folderItems) {
      try {
        const folder = {
          artifact_id: folderItem.folder_id,
          object_type: 'folder'
        } as SuperObjectModel;
        const ref = await this.refService.makeRef({
          object: folder
        } as RefParams);
        folderItem.$$routerLink = ref.routerLink;
        folderItem.$$queryParams = ref.queryParams;
      } catch (e) {
        console.error(e.error.message);
      }
    }
    this.folderItems = folderItems;
    this.foldersSet = true;
  }
}
