<div class="image-carousel-annotation-points"
     [ngClass]="{'smallCarousel': mediumScreen, 'largeCarousel': !mediumScreen,
     'showMoreAnn': showAllAnnotationPoints}">

  @if (activeAnnotation) {
    <ul id="annotationPoints">
      @for (annotation of curAnn.annotations; track annotation; let i = $index) {
        <li>
          @if (activeAnnotationRef[i]) {
            <a class="text-blue"
               [routerLink]="activeAnnotationRef[i].routerLink"
               [queryParams]="activeAnnotationRef[i].queryParams"
               queryParamsHandling="merge">
              <span>{{i + 1}}. {{annotation.description.description}}</span>
            </a>
          }
        </li>
      }
    </ul>
  }

  @if (activeAnnotationRefCreate) {
    <a class="image-carousel-annotation-points__create text-blue-hover"
       [routerLink]="activeAnnotationRefCreate.routerLink"
       [queryParams]="activeAnnotationRefCreate.queryParams"
       queryParamsHandling="merge">
      <i class="icon-plus"></i>
      <span>{{'TRANS__IMAGE_CAROUSEL__CREATE_NEW_ANNOTATION' | translate}}</span></a>
  }

  @if (isOverflown) {
    <a (click)="toggleAllAnnotationPoints()"
       class="image-carousel-annotation-points__showOverflow">
      <div class="image-carousel-annotation-points__showOverflow-container">
        <div class="image-carousel-annotation-points__showOverflow-content text-blue">
          <mat-icon>{{showAllAnnotationPoints ? 'expand_less' : 'expand_more'}}</mat-icon>
        </div>
      </div>
    </a>
  }

</div>
