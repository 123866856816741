import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';
import {ConceptsParams} from '../../../core/definitions/concepts';
import {CheckFilter, CheckFilterGroup} from '../../../core/definitions/search-objects';

@Component({
  selector: 'app-admin-list-filter-menu',
  templateUrl: './admin-list-filter-menu.component.html',
  styleUrls: ['./admin-list-filter-menu.component.scss']
})
export class AdminListFilterMenuComponent implements OnChanges {

  @Input() conceptsContainer: ConceptsContainer;

  @Output() filterChange = new EventEmitter<void>();

  constructor() {
  }

  ngOnChanges() {
  }

  filterChanged(filterGroup: CheckFilterGroup, filter: CheckFilter) {
    const filterName = filter.name;
    const params: ConceptsParams = this.conceptsContainer.conceptsParams;
    const conceptParam = params[filterName];
    if (Array.isArray(conceptParam)) {
      const index = conceptParam.indexOf(filter.value);
      if (index === -1) {
        conceptParam.push(filter.value);
      } else {
        conceptParam.splice(index, 1);
      }
    } else {
      if (params[filterName] !== filter.value) {
        params[filterName] = filter.value;
      } else {
        params[filterName] = undefined;
      }
      // Set other filters in field group to false
      for (const otherFilter of filterGroup.filters) {
        if (otherFilter.value !== filter.value) {
          otherFilter.checked = false;
        }
      }
    }
    this.filterChange.emit();
  }
}
