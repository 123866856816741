import { Component, OnInit } from '@angular/core';
import {ConceptsContainer} from '../../core/definitions/concepts-container';
import {ConceptService} from '../../core/concept.service';
import {PrimusRouterService} from '../../core/primus-router.service';

@Component({
  selector: 'app-admin-collection',
  templateUrl: './admin-collection.component.html',
  styleUrls: ['./admin-collection.component.scss']
})
export class AdminCollectionComponent implements OnInit {

  constructor(private conceptService: ConceptService,
              private primusRouter: PrimusRouterService) { }

  conceptsContainer: ConceptsContainer;
  parent;

  ngOnInit(): void {
    this.setCollectionConceptsContainer().then();
    this.parent = {
      path: 'admin',
      adminType: 'collection',
      hasSubMenus: true,
      target_params: {admin_type: 'collection'},
      currentState: this.primusRouter.currentState()
    };
  }

  async setCollectionConceptsContainer() {
    const conceptType = await this.conceptService.getConceptType('Collection');
    this.conceptService.setConceptsContainer(conceptType).then(conceptsContainer => {
      this.conceptsContainer = conceptsContainer;
    });

  }
}
