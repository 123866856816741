import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export class DropdownMenuContainer {
  view: any;
  disableContainer: any;
  disableProp: any;
  buttonClass?;
  contentList?;
}

@Component({
  selector: 'app-dropdown-menu-animated',
  templateUrl: './dropdown-menu-animated.component.html',
  styleUrls: ['./dropdown-menu-animated.component.scss']
})
export class DropdownMenuAnimatedComponent implements OnInit {

  @Input() menuContainer: DropdownMenuContainer;

  menuToggled = false;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.menuContainer.buttonClass =
      this.menuContainer.buttonClass ? this.menuContainer.buttonClass : 'new-button';
    if (!this.menuContainer.contentList) {
      this.menuContainer.contentList = false;
    }
  }

  get buttonLabel() {
    let res = '';
    if (this.menuContainer.view.description) {
      res = this.translateService.instant(this.menuContainer.view.description);
    }
    return res;
  }

}
