<div class="operation-menus-content-list common-operation-menu-styling"
     [ngClass]="{'active': contentListContainer.searchContainer.toggleActionMenu && !disabled,
     'tooltip1': !selectedCount}"
     matTooltip="{{'TRANS__VALIDATION__NO_SELECTED_OBJECT' | translate}}"
     [matTooltipDisabled]="!disabled">

  <button class="common-operation-menu-styling__button"
          (click)="toggleOperationMenus()"
          [disabled]="disabled"
          [matMenuTriggerFor]="operationMenusContentList">
    <span class="common-operation-menu-styling__button-text common-operation-menu-styling__text1"
          translate="TRANS__OPERATION_MENUS_CONTENT_LIST__PROCESS_SELECTIONS"></span>
    <span class="common-operation-menu-styling__button-text common-operation-menu-styling__text2"
          translate="TRANS__SEARCH__TREAT"></span>
    <span class="common-operation-menu-styling__button-count">({{selectedCount}})</span>
    <mat-icon>{{contentListContainer.searchContainer.toggleActionMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>

  <mat-menu #operationMenusContentList="matMenu"
            class="common-operation-menu-styling__menu removePaddingInMatMenu"
            (closed)="contentListContainer.searchContainer.toggleActionMenu = false;">
    <mat-accordion displayMode="flat">
      <mat-expansion-panel *ngFor="let operation of contentListContainer.operationContainer.operations"
                           (click)="toggleOperationMenu(operation)"
                           class="no-active-blue-color"
                           [hideToggle]="!operation.sub_operations"
                           #currentOperationPanel>
        <mat-expansion-panel-header (click)="onClickPanelHeader($event, operation)"
                                    [collapsedHeight]="'40px'"
                                    [expandedHeight]="'40px'">
          <mat-panel-title [ngClass]="{'bold': currentOperationPanel.expanded}"
                           [translate]="operation.menu_title"></mat-panel-title></mat-expansion-panel-header>
        <mat-list>
          <ng-container *ngFor="let subOperation of operation.sub_operations">
            <button mat-button
                    class="common-operation-menu-styling__sub-operation-button"
                    (click)="openOperation(subOperation, operation)">
<!--              <i class="{{subOperation.menu_icon}}"></i>-->
              <span [translate]="subOperation.menu_title"></span>
            </button>
          </ng-container>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-menu>
</div>
