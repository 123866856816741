import {Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {RefData, RefParams, RefService, StateParams} from '../../core/ref.service';
import {Annotation} from '../../image-annotation/annotation';
import {AnnotationHandler} from '../../image-annotation/annotation-handler';
import {Media} from '../../core/definitions/media';
import {ObjectMediaType} from '../../core/definitions/object-media-container';
import {BaseModel} from "../../core/definitions/base-model";

@Component({
  selector: 'app-image-carousel-annotation-points',
  templateUrl: './image-carousel-annotation-points.component.html',
  styleUrls: ['./image-carousel-annotation-points.component.scss']
})
export class ImageCarouselAnnotationPointsComponent implements OnDestroy, OnChanges {

  activeAnnotation: Annotation;
  activeAnnotationRefCreate: RefData;
  activeAnnotationRef: Array<RefData> = [];
  isOverflown = false;
  showAllAnnotationPoints = false;
  media: Media[];
  private images: BaseModel[];

  @Input() mediaType: ObjectMediaType;
  @Input() mediumScreen: boolean;
  @Input() curAnn: AnnotationHandler;
  @Input() readonly: boolean;

  constructor(private ref: RefService,
              private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.media = this.mediaType.mediaElements;
    this.images = this.media.map(media => media.mediaObject);
    if (changes.hasOwnProperty('imgIndex') && changes.imgIndex.previousValue !== changes.imgIndex.currentValue ||
      changes.hasOwnProperty('curAnn') && changes.curAnn.previousValue !== changes.curAnn.currentValue) {
      this.getAnnotationPoints(this.mediaType.currentMediaIndex);
      this.checkAnnotationPointsOverflow();
    }
  }

  private getAnnotationPoints(index: number) {
    const imageId = this.images[index].artifact_id;
    this.activeAnnotation = this.getAnnotation();
    this.curAnn.selectedAnnotation = this.activeAnnotation;
    let params: RefParams;
    if (this.activeAnnotation) {
      params = new RefParams(
        this.activeAnnotation,
        {
          parentId: this.activeAnnotation.super_artifact_id,
          rootObjId: this.activeAnnotation.artifact_id,
          edit: !this.readonly
        } as StateParams
      );
    } else {
      const object = new Annotation();
      object.object_type = 'Annotation';
      object.image_id = imageId;
      object.parentId = this.images[index].$$parentId;
      object.parentObjectType = this.images[index].$$parentObjectType;
      params = new RefParams(
        object,
        {
          parentId: object.parentId,
          parentObjectType: object.parentObjectType,
          rootObjId: object.parentId,
          rootObjType: object.parentObjectType,
          edit: !this.readonly
        } as StateParams
      );
    }
    this.getAnnotationPointsRef(params).then();
  }

  private async getAnnotationPointsRef(params: RefParams) {
    this.activeAnnotationRefCreate = await this.ref.makeRef(params);
    this.activeAnnotationRef = [];
    for (let t = 0; t < (this.curAnn.annotations || []).length; t++) {
      const annotation = this.curAnn.annotations[t];
      params.object.artifact_id = annotation.artifact_id;
      const ref = await this.ref.makeRef(params);
      this.activeAnnotationRef.push(ref);
    }
  }

  private getAnnotation(): Annotation {
    let activeAnnotation = null;
    if (this.curAnn.annotations && this.curAnn.annotations.length > 0) {
      for (const annotation of this.curAnn.annotations) {
        if (this.images[this.mediaType.currentMediaIndex].artifact_id === annotation.image_id) {
          activeAnnotation = annotation;
          break;
        }
      }
    }
    return activeAnnotation;

  }

  checkAnnotationPointsOverflow() {
    this.showAllAnnotationPoints = false;
    setTimeout(() => {
      const annotationElement = this.elementRef.nativeElement.querySelector('#annotationPoints');
      if (this.activeAnnotation && annotationElement) {
        this.isOverflown = this.isOverFlow(annotationElement);
      } else {
        this.isOverflown = false;
      }
    }, 400);
  }

  toggleAllAnnotationPoints() {
    this.showAllAnnotationPoints = !this.showAllAnnotationPoints;
  }

  isOverFlow(element: Element) {
    return element.scrollHeight > element.clientHeight;
  }

  ngOnDestroy() {
    this.showAllAnnotationPoints = false;
  }
}
