import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';
import {DateToolsService} from './date-tools.service';
import {ModelFactoryService} from './model-factory.service';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {FieldMetaService} from './field-meta.service';
import {ModelsService} from './models.service';
import {FieldStateService} from './field-state.service';
import {FieldParameters} from './definitions/field-parameters';
import {MetaField} from './definitions/meta-field';
import {Inline, InlineList} from './definitions/inline';
import {SuperObjectModel} from './definitions/super-object-model';
import {SearchParameters} from './definitions/search-parameters';
import {BaseModel} from './definitions/base-model';
import {FieldType} from './definitions/field-type.enum';
import {FieldInputType} from './definitions/field-input-type.enum';
import {SolrFilterService} from './solr-filter.service';
import {LoggerService} from './logger.service';
import {SearchFilters} from "./definitions/search-filters";
import {SearchService} from "./search.service";
import {SearchReferenceService} from "./search-reference.service";
import {Reference} from "./definitions/reference";
import {ValueOptionService} from "./value-option.service";
import {CrudService} from "./crud.service";
import {System} from "./definitions/system";

export interface FindMappedValueParams {
  filters: SearchFilters;
}

@Injectable({
  providedIn: 'root'
})
export class FieldValueService {
  constructor(private readonly translate: TranslateService,
              private readonly commons: CommonsService,
              private readonly logger: LoggerService,
              private readonly dateTools: DateToolsService,
              private readonly modelFactory: ModelFactoryService,
              private readonly searchService: SearchService,
              private readonly fieldMetaSvc: FieldMetaService,
              private readonly modelsSvc: ModelsService,
              private readonly fieldState: FieldStateService,
              private readonly solrFilter: SolrFilterService,
              private searchReferenceService: SearchReferenceService,
              private valueOptionService: ValueOptionService,
              private crud: CrudService) {
  }

  private fieldWarnings = {};

  private getFieldPartNames(fieldStr: string) {
    return fieldStr.split(System.keySep);
  }

  private static getPythonStyleIndexes(value: any, bracketVal: string) {
    const res = {
      index1: undefined,
      index2: undefined
    };
    const split = bracketVal.split(':');
    if (!isNaN(split[0] as any)) {
      res.index1 = Number.parseInt(split[0], 10);
    } else {
      res.index1 = 0;
    }
    if (!isNaN(split[1] as any)) {
      res.index2 = Number.parseInt(split[1], 10);
    } else {
      res.index2 = value.length;
    }
    return res;
  }

  fieldIf(model: any, fieldName: string, comparator: string, compareValue: any) {
    const fieldValue = this.getFieldValue(model, fieldName);
    return this.commons.compareValues(fieldValue, comparator, compareValue);
  }

  /**
   * Get a field value from a model
   * @param model - a complex model object
   * @param fieldsIn - a string representation of the field value path e.g. 'designations[0].designation_type_id'
   * @param textValue - used for map_id based fields, if set to true will return the text value instead of the id value of the field
   * @param booleanAsYesNo - used with textValue=true, if set to true, return boolean text values as 'Yes' or 'No' instead of title or
   * nothing
   * @returns a field value within the model, or null if field path
   * does not exist
   * The getFieldValue and getFieldValueFromContainer methods
   * should be combined into one
   */
  getFieldValue(model: BaseModel, fieldsIn: string, textValue?: boolean, booleanAsYesNo?: boolean) {
    let negative = false, fields = fieldsIn;
    if (!fields) {
      this.logger.info('No fields');
      return '';
    }
    if (fields.startsWith('!')) {
      fields = fields.substring(1);
      negative = true;
    }
    const fieldNames = this.splitField(fields);
    return this.loopGetFieldValue(model, fieldNames, negative, textValue, booleanAsYesNo);
  }

  async getMappedFieldValue(metaField: MetaField, object: any, refId?: string): Promise<string> {
    let parentTargetField = 'parent_id';
    let res = this.getValueCompanion(object, metaField.name);
    if (res) {
      return res;
    }
    if (!refId) {
      refId = this.getFieldValue(object, metaField.name);
    }

    if (refId) {
      const reference = this.searchReferenceService.getSearchReferenceFromField(metaField);
      const objectType = reference.object_type;
      const params = {
        filters: {
          artifact_id: null,
          object_type: objectType
        },
        labelProp: reference.label_prop
      };

      const parentField = reference.parent_field;
      if (parentField) {
        const parentId = object[parentField];
        if (parentId) {
          if (reference.parent_target_field) {
            parentTargetField = reference.parent_target_field;
          }
          params.filters[parentTargetField] = parentId;
        }
      }

      const refProp = this.getRefProp(reference);
      if (!res) {
        params.filters[refProp] = refId;
        res = await this.findMappedValue(params);
      }
    } else {
      res = '';
    }
    return res;
  }

  // This method should usually not be called, only when _value field values are missing
  async findMappedValue(params: any): Promise<any> {
    const searchParams = {} as SearchParameters;
    this.solrFilter.setFqFromObject(searchParams, params.filters);
    const data = await this.searchService.search(searchParams);
    let mapText: any;
    const artifacts = data.artifacts;
    const labelProp = AConst.ARTIFACT_NAME;
    if (artifacts && artifacts.length === 1) {
      mapText = artifacts[0][labelProp];
    } else if (artifacts && artifacts.length > 1) {
      mapText = [];
      for (const art of artifacts) {
        mapText.push(art[labelProp]);
      }
    } else {
      this.logger.warn('No search result for ', params.filters);
      mapText = params.filters.artifact_id;
    }
    return mapText;
  }

  getFieldTextValue(model: any, fieldsIn: string, booleanAsYesNo?: boolean) {
    return this.getFieldValue(model, fieldsIn, true, booleanAsYesNo);
  }

  isSingleItemArray(fieldParameters: FieldParameters) {
    let res = false;
    const inline = fieldParameters.field.inline;
    if (inline) {
      const inlineList = inline.inline_list;
      if (inlineList && inlineList.max_length === 1) {
        res = true;
      }
    }
    return res;
  }

  addItemUsingFieldParameters(fieldParameter: FieldParameters, itemData: any, fieldKey: string) {
    return this.createAddItem(
      fieldParameter,
      itemData,
      fieldParameter.sectionsContainer.formGroup,
      fieldKey);
  }

  // Avoid using this method for inline array elements, use methods in InlineArrayItemService
  createAddItem(fieldParameters: FieldParameters, data?: any, form?: UntypedFormGroup, fieldKey?: string) {
    const metaField = fieldParameters.field;
    const object = fieldParameters.object || fieldParameters.rootObject;
    const model = this.modelFactory.getInlineModel(metaField);
    let array = this.getArrayFromFieldParameters(fieldParameters);
    if (!array) {
      object[metaField.name] = object[metaField.name] ? object[metaField.name] : [];
      array = this.getArrayFromFieldParameters(fieldParameters);
    }
    const item = this.modelFactory.createAddArrayItem(array, model, data);
    // $$grandParentModel used for models outside regular model regime, e.g. TemplateModels
    if (object.object_type.toLowerCase().indexOf('template') !== -1) {
      item['$$grandParentModel'] = object;
    }
    item['$$parentName'] = metaField.name;
    if (form) {
      form.controls[fieldKey].setValue(array.length);
      this.markAsDirty(form, fieldKey);
    }
    return item;
  }

  itemExists(fieldParameters: FieldParameters, item: any) {
    let res = false;
    const array = this.getArrayFromFieldParameters(fieldParameters);
    const inlineFieldName = this.fieldState.getInlineFieldName(fieldParameters.field);
    if (inlineFieldName) {
      res = array.map(existingItem => existingItem[inlineFieldName]).includes(item[inlineFieldName]);
    } else {
      this.logger.warn('itemExists require inline based field with prop');
    }
    return res;
  }

  markAsDirty(form: UntypedFormGroup, fieldKey: string) {
    if (form && fieldKey) {
      form.controls[fieldKey].markAsDirty();
    }
  }


  // Avoid using this method for inline array elements, use methods in InlineArrayItemService
  deleteItem(rootObject: BaseModel, object: BaseModel, metaField: MetaField, index: number, form?: UntypedFormGroup, fieldKey?: string) {
    const array = object[metaField.name];
    this.modelFactory.deleteArrayItem(array, index, rootObject);
    form.controls[fieldKey].setValue(array.length ? array.length : '');
    this.markAsDirty(form, fieldKey);
  }

  deleteItemUsingFieldParameters(fieldParameters: FieldParameters, itemId: string, fieldKey: string) {
    const array = this.getArrayFromFieldParameters(fieldParameters);
    const compareFieldName = this.getIdFieldName(fieldParameters);
    const index = array.findIndex(arrayItem => {
      return arrayItem[compareFieldName] === itemId;
    });
    if (index !== -1) {
      const form = fieldParameters.sectionsContainer.formGroup;
      const array = this.getArrayFromFieldParameters(fieldParameters);
      this.modelFactory.deleteArrayItem(array, index, fieldParameters.rootObject);
      form.controls[fieldKey].setValue(array.length ? array.length : '');
      this.markAsDirty(form, fieldKey);
    } else {
      this.logger.warn(`Did not find this item for deletion: ${itemId}`);
    }
  }

  getIdFieldName(fieldParameters: FieldParameters) {
    const inlineFieldName = this.fieldState.getInlineFieldName(fieldParameters.field);
    return inlineFieldName ? inlineFieldName : fieldParameters.field.name;
  }


  setObjectEditFields(editFields: string[], obj: BaseModel, firstFieldNames?: string) {
    for (const [propName, metaField] of Object.entries(obj.$$meta || {})) {
      const inlineMod = metaField.inline ? metaField.inline.model : null;
      if (metaField.edit && metaField.edit.startsWith('edit')) {
        const fieldName = firstFieldNames ? `${firstFieldNames}${System.keySep}${propName}` : propName;
        if (!inlineMod) {
          editFields.push(fieldName);
        } else {
          this.checkSetInlineEditFields(editFields, obj, fieldName, propName);
        }
      }
    }
  }

  private checkSetInlineEditFields(editFields: string[], obj: BaseModel, fieldName: string, propName: string) {
    if (!obj.hasOwnProperty(propName)) {
      return;
    }
    if (obj[propName]) {
      this.setObjectEditFields(editFields, obj[propName], fieldName);
    } else {
      this.logger.warn('Unable to get edit fields from object structure for field ' + propName);
    }
  }

  getFieldValFromFieldParameters(fieldParameters: FieldParameters, fieldStr: string) {
    const fieldPartNames = this.getFieldPartNames(fieldStr);
    let modelVal: any;

    modelVal = this.loopGetFieldValFromModel(fieldParameters, fieldParameters.object, fieldPartNames);

    if ((modelVal === undefined || modelVal === null) && fieldParameters.grandParentObject) {
      modelVal = this.loopGetFieldValFromModel(fieldParameters, fieldParameters.grandParentObject, fieldPartNames);
    }

    if (modelVal === undefined || modelVal === null) {
      modelVal = this.loopGetFieldValFromModel(fieldParameters, fieldParameters.sectionsContainer.rootObject, fieldPartNames);
    }

    return modelVal;
  }

  // Get field value as array no matter whether field is array based or not. Skipping items marked as destroy
  getFieldValueFromFieldParametersAsArray(fieldParameters: FieldParameters): BaseModel[] {
    let array = this.getArrayFromFieldParameters(fieldParameters);
    return array.filter(item => {
      return !this.crud.getDestroy(item);
    });
  }

  getFieldValFromModel(fieldParams: FieldParameters, model: any, partFieldName: string) {
    let res: any;
    const fieldName = this.getRealFieldName(fieldParams, partFieldName);
    const indexVal = this.getModelIndexVal(model, fieldName);
    if (indexVal !== null) {
      res = indexVal;
    } else {
      res = model[fieldName];
    }
    return res;
  }

  getSubstituteString(str: string, startChar: string, endChar: string) {
    let res = '';
    if (str) {
      const subStart = str.indexOf(startChar);
      if (subStart !== -1) {
        const subEnd = str.indexOf(endChar);
        if (subEnd !== -1) {
          res = str.substring(subStart + 1, subEnd);
        } else {
          this.logger.warn('Missing \'' + endChar + '\' in ' + str);
        }
      }
    }
    return res;
  }

  /**
   * Set model field value in which fields are a string containing
   * dot separated field names, e.g. "user[0].info.name"
   * @param model
   * @param fields
   * @param fieldValue
   * @param textValue
   * @param fieldSep
   */
  setFieldValue(model: BaseModel, fields: string, fieldValue: any, textValue?: boolean, fieldSep?: string) {
    const fieldNames = this.splitField(fields, fieldSep);
    let mod = model;
    const lastIndex = fieldNames.length - 1;
    let changed = false;
    fieldNames.forEach((fieldName, index) => {
      if (index === lastIndex) {
        if (textValue) {
          fieldName += '_value';
        }
        fieldValue = fieldValue !== undefined ? fieldValue : null;
        changed = mod[fieldName] !== fieldValue;
        if (changed) {
          mod[fieldName] = fieldValue;
        }
      } else {
        if (mod[fieldName] === undefined) {
          mod[fieldName] = {};
        }
        mod = mod[fieldName];
      }
    });
    return changed;
  }

  /**
   * Get the value of a field within an object in a format that can be used in a form control
   * @param rootObject the root object
   * @param object the object containing the field
   * @param field the field meta-data
   * @param getInlineFieldName if set to true will use the inline field name
   */
  getControlValueFromObjectField(rootObject: BaseModel, object: BaseModel, field: MetaField, getInlineFieldName = false): any {
    let fieldName = getInlineFieldName ? this.fieldState.getInlineFieldName(field) : field.name;
    if (Array.isArray(object)) {
      fieldName = field.path;
      object = rootObject;
    }
    let res = object[fieldName];
    const metaField: MetaField = object.$$meta[fieldName];
    if (!metaField) {
      this.logger.warn('Field + "' + fieldName + '" not found in object');
      return res;
    }
    if (metaField.input_type === FieldInputType.DATE_TIME_ISO) {
      res = this.dateTools.isoDateToString(res, null);
    } else if (metaField.input_type === FieldInputType.DATE_ISO) {
      const precision = object[fieldName + '_precision'];
      res = this.dateTools.isoDateToString(res, precision);
    } else if (metaField.input_type === FieldInputType.COMPARE_VALUE) {
      const value = this.getValueCompanion(object, fieldName, true);
      res = value || res;
    } else if (metaField.field_type === FieldType.BOOLEAN) {
      res = !!res;
    } else if (metaField.field_type === FieldType.MAP_ID) {
      res = this.getValueCompanion(object, fieldName);
    } else if (metaField.field_type === FieldType.ARRAY) {
      res = this.getArrayBasedControlValue(rootObject, res, metaField)
    }
    return res;
  }

  private getArrayBasedControlValue(rootObject: BaseModel, fieldValue: any, metaField: MetaField) {
    let res = fieldValue.length ? fieldValue.length : '';
    const inline: Inline = metaField.inline;
    if (inline) {
      const inlineList: InlineList = inline.inline_list;
      if (inlineList && inlineList.max_length === 1 && fieldValue && fieldValue.length > 0) {
        res = this.getControlValueFromObjectField(rootObject, fieldValue[0], metaField, true);
      }
    }
    return res;
  }

  getValueCompanion(object: any, fieldName: string, dontSet?: boolean): string {
    if (!object) {
      return '';
    }
    let res = object[fieldName + '_value'];
    if (object[fieldName] && (res === undefined || res === null) && !dontSet) {
      this.warnFieldCompanionMissing(object, fieldName);
      res = object[fieldName];
    }
    return res;
  }

  async setFieldValueAndControlValue(fieldParameters: FieldParameters,
                                     object: BaseModel,
                                     field: MetaField,
                                     fieldValue: any,
                                     textValue?: string): Promise<boolean> {
    const fieldName = field.name;
    const changed = object[fieldName] !== fieldValue;
    if (changed) {
      object[fieldName] = fieldValue;
      const metaField: MetaField = fieldParameters.field;
      if (metaField) {
        if (metaField.field_type === FieldType.MAP_ID) {
          await this.setValueCompanion(object, fieldName, textValue);
          this.setControlValue(fieldParameters, object, field);
        } else {
          this.setControlValue(fieldParameters, object, field);
        }
      }
    }
    return changed;
  }

  setControlValue(fieldParameters: FieldParameters, object: BaseModel, field: MetaField) {
    const fieldName = field.name;
    const control = this.getControl(fieldParameters, fieldName);
    if (control) {
      const value = this.getControlValueFromObjectField(fieldParameters.rootObject, object, field);
      control.setValue(value);
      control.markAsDirty();
    }
  }

  getControl(fieldParameters: FieldParameters, fieldName: string): AbstractControl {
    let control: AbstractControl;
    const fieldKey = this.fieldState.getFieldKey(
      fieldParameters.field, fieldParameters.index, fieldParameters.parentIndex, fieldName);
    if (fieldKey) {
      control = fieldParameters.sectionsContainer.formGroup.controls[fieldKey];
    }
    return control;
  }

  hasValue(metaField: MetaField, object: any) {
    let res = false;
    if (!metaField) {
      return res;
    }
    if (metaField.field_type === 'action_button') {
      res = true;
    } else if (object) {
      const value = object[metaField.name];
      if (value !== null && value !== undefined) {
        res = true;
        if (typeof value === 'string' || Array.isArray(value)) {
          res = value.length > 0;
        } else if (typeof value === 'object') {
          res = this.hasInlineValue(metaField, value);
        }
      }
    }
    return res;
  }

  getTextValue(object: BaseModel, metaFieldIn: any, booleanAsYesNo?: boolean) {
    const [fieldName, metaField] = this.getFieldNameAndMetaField(object, metaFieldIn);
    let value = object[fieldName];
    if (!metaField || value === null) {
      return value || '';
    }
    if (metaField.input_type === FieldInputType.DATE_ISO) {
      const precision = object[fieldName + '_precision'];
      value = this.dateTools.isoDateToString(object[fieldName], precision);
    } else if (metaField.input_type === FieldInputType.DATE_TIME_ISO) {
      value = this.dateTools.isoDateToString(object[fieldName]);
    } else if (metaField.field_type === FieldType.BOOLEAN) {
      value = this.getBooleanTextValue(metaField, value, booleanAsYesNo);
    } else if (metaField.field_type === FieldType.NUMERIC || metaField.field_type === FieldType.DECIMAL) {
      value = this.getNumericTextValue(value, metaFieldIn);
    } else if (metaField.input_type === FieldInputType.MAP_ID || metaField.field_type === FieldType.MAP_ID) {
      value = this.getValueCompanion(object, fieldName);
    } else if (metaField.field_type === FieldType.OPTION_STRING) {
      const label = this.getOptionLabel(value, metaField);
      if (label) {
        value = this.translate.instant(label);
      }
    }
    return value || '';
  }

  async setValueCompanion(object: any, fieldName: string, value?: string): Promise<string> {
    let res: string;
    if (!value) {
      const fieldValue = object[fieldName];
      if (fieldValue) {
        res = await this.getMappedValue(fieldValue);
        object[fieldName + '_value'] = res;
      } else {
        object[fieldName + '_value'] = '';
        res = '';
      }
    } else {
      object[fieldName + '_value'] = value;
      res = value;
    }
    return res;
  }

  async getMappedValue(value: any): Promise<any> {
    const params = {filters: {artifact_id: value}} as FindMappedValueParams;
    return this.findMappedValue(params);
  }

  getArrayFromFieldParameters(fieldParameters: FieldParameters): any[] {
    let array: any[];
    if (fieldParameters.object) {
      array = fieldParameters.object[fieldParameters.field.name];
      if (array !== undefined && !Array.isArray(array)) {
        array = array === null ? [] : [fieldParameters.object];
      }
    }
    if (!array) {
      // This will happen to ref array fields when used as prime field
      array = fieldParameters.rootObject[fieldParameters.field.path];
      if (array && !Array.isArray(array)) {
        array = [];
      }
    }
    return array || [];
  }

  private getBooleanTextValue(metaField: MetaField, value: any, booleanAsYesNo: boolean) {
    let res = value;
    let transKey: string;
    if (booleanAsYesNo) {
      transKey = value ? 'TRANS__FIELD__BOOLEAN__YES' : 'TRANS__FIELD__BOOLEAN__NO';
    } else if (value) {
      transKey = metaField.title;
    }
    if (transKey) {
      res = this.translate.instant(transKey);
    }
    return res;
  }

  private getNumericTextValue(value: any, metaFieldIn: MetaField) {
    let res = value;
    if (value !== undefined) {
      res = value.toLocaleString();
    } else {
      this.logger.warn('Value undefined for ' + metaFieldIn.name);
    }
    return res;
  }

  private getFieldNameAndMetaField(object: BaseModel, metaFieldIn: MetaField): [string, MetaField] {
    let fieldName: string;
    let metaField: MetaField;
    if (typeof metaFieldIn === 'string') {
      fieldName = metaFieldIn;
      metaField = this.getFieldMeta(object, fieldName);
      if (!metaField) {
        this.logger.warn('Object did not contain field meta for \'' + fieldName + '\'');
      }
    } else {
      fieldName = metaFieldIn.name;
      metaField = metaFieldIn;
    }
    return [fieldName, metaField];
  }

  private getIndexVal(value: any, indexes: any) {
    let res = null;
    const index1 = indexes.index1, index2 = indexes.index2;
    if (index1 === undefined && index2 === undefined) {
      this.logger.warn('Indexes missing');
      return;
    }
    if (typeof value === 'string') {
      if (index2 === undefined) {
        res = value.substring(index1, index1 + 1);
      } else {
        res = value.substring(index1, index2);
      }
    } else if (Array.isArray(value)) {
      if (index2 === undefined) {
        res = value[index1];
      } else {
        res = value.slice(index1, index2);
      }
    } else {
      this.logger.warn('Cannot get index value from \'' + value + '\'');
    }
    return res;
  }

  private warnFieldCompanionMissing(object: SuperObjectModel, fieldName: string) {
    if (!this.fieldWarnings[fieldName]) {
      this.logger.warn('Field ' + fieldName + ', object ' + object.object_type + ' is missing _value companion');
      this.fieldWarnings[fieldName] = true;
    }
  }

  private hasInlineValue(metaField: MetaField, value: any) {
    let res = false, inlineVal: any;
    const inlineFieldName = this.fieldState.getInlineFieldName(metaField);
    if (inlineFieldName) {
      inlineVal = value[inlineFieldName];
    }
    const modName = this.modelFactory.getInlineModel(metaField);
    if (inlineVal !== undefined) {
      res = inlineVal !== null;
      if (res && typeof inlineVal === 'object') {
        const subModel = this.modelsSvc.getModelCopy(modName);
        if (subModel) {
          const subFi = subModel.$$meta[inlineFieldName];
          res = this.hasInlineValue(subFi, inlineVal);
        }
      }
    }
    return res;
  }

  private loopGetFieldValue(model: any, fieldNames: any[], negative: boolean, textValue?: boolean, booleanAsYesNo?: boolean) {
    let res = model;
    for (let index = 0; index < fieldNames.length; index++) {
      let fieldName = fieldNames[index];
      const isLastField = index === fieldNames.length - 1;
      res = this.checkIsArrayGetSubValues(res, fieldName, isLastField, textValue);
      if (res !== undefined && res !== null) {
        if (textValue && index === fieldNames.length - 1) {
          res = this.getTextValue(res, fieldName, booleanAsYesNo);
        } else {
          res = this.getFieldValueFromSubObject(res, fieldName, isLastField, textValue);
        }
      }
    }
    if (typeof res === 'boolean' && negative) {
      res = !res;
    }
    res = textValue ? res || '' : res;
    return res;
  }

  private checkIsArrayGetSubValues(value: any, fieldName: string, isLastField: boolean, textValue: boolean) {
    let res: any;
    if (Array.isArray(value) && isNaN(Number.parseInt(fieldName, 10))) {
      if (value.length > 0) {
        res = value.map(item => this.getFieldValueFromSubObject(item, fieldName, isLastField, textValue));
      } else {
        res = null;
      }
    } else {
      res = value;
    }
    return res;
  }

  private getFieldValueFromSubObject(subObject: BaseModel, fieldName: string, isLastField: boolean, textValue?: boolean) {
    if (textValue && isLastField) {
      subObject = this.getTextValue(subObject, fieldName);
    } else {
      subObject = subObject[fieldName];
    }
    return subObject;
  }

  private getOptionLabel(value: any, metaField: MetaField) {
    const valueOptions = this.valueOptionService.getValueOptionsForField(metaField);
    let res = (valueOptions?.options || []).find(option => option.value.toString() === value.toString());
    if (!res) {
      this.logger.warn('Option not found for value "' + value + '"');
    }
    return res?.label;
  }

  private getFieldMeta(object: BaseModel, fieldName: string): MetaField {
    let res: MetaField = {} as MetaField;
    const meta = this.fieldMetaSvc.checkSetMetaData(object);
    if (meta) {
      res = meta[fieldName];
      if (!res) {
        this.logger.warn('Found no field meta data for: ' + fieldName);
      }
    } else {
      this.logger.warn('Object is missing meta data: ', object);
    }
    return res;
  }

  private splitField(field: string, separator?: string) {
    if (!field) {
      this.logger.warn('No field!');
      return;
    }
    const dotSplit = field.split(separator || System.keySep);
    const res = [];
    for (const dotField of dotSplit) {
      const bracketStart = dotField.indexOf('[');
      if (bracketStart !== -1) {
        const bracketEnd = dotField.indexOf(']');
        if (bracketEnd === -1) {
          throw new Error('Field missing bracket end: ' + field);
        }
        res.push(dotField.substring(0, bracketStart));
        res.push(dotField.substring(bracketStart + 1,
          bracketEnd));
      } else {
        res.push(dotField);
      }

    }
    return res;
  }

  private loopGetFieldValFromModel(fieldParameters: FieldParameters, modelVal: any, fieldPartNames: string[]) {
    let res = modelVal;
    for (const fieldName of fieldPartNames) {
      if (res) {
        res = this.getFieldValFromModel(fieldParameters, res, fieldName);
      }
      if (!res) {
        break
      }
    }
    return res;
  }

  private getModelIndexVal(model: any, fieldNameIndex: string) {
    let res = null;
    let bracketVal = this.getSubstituteString(fieldNameIndex, '[', ']');
    let indexes = {
      index1: undefined,
    };
    if (bracketVal) {
      const fieldName = fieldNameIndex.replace('[' + bracketVal + ']', '');
      const value = model[fieldName];
      if (value === undefined) {
        return null;
      }
      const subStr = this.getSubstituteString(bracketVal, '{', '}');
      if (subStr) {
        bracketVal = subStr;
      }
      if (!isNaN(Number.parseInt(bracketVal, 10))) {
        indexes.index1 = Number.parseInt(bracketVal, 10);
      } else if (bracketVal === '$last') {
        indexes.index1 = value.length - 1;
      } else if (bracketVal === '$first') {
        indexes.index1 = 0;
      } else if (bracketVal.indexOf(':') !== -1) {
        indexes = FieldValueService.getPythonStyleIndexes(value, bracketVal);
      } else {
        this.logger.warn('Don\'t know how to handle array index \'' + subStr + '\'');
      }
      res = this.getIndexVal(value, indexes);
    }
    return res;
  }

  private getRealFieldName(fieldParameters: FieldParameters, fieldName: string) {
    let res = fieldName;
    const subStr = this.getSubstituteString(fieldName, '{', '}');
    if (subStr) {
      const subVal = this.getFieldValFromModel(fieldParameters, fieldParameters.object, subStr);
      res = fieldName.replace('{' + subStr + '}', subVal);
    }
    return res;
  }

  private getRefProp(reference: Reference) {
    return reference.ref_prop || 'artifact_id';
  }

}
