import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import SolrDataSource from '../solr-data-source';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SearchObject} from '../../../core/definitions/search-object';
import {Observable} from 'rxjs';
import {DateToolsService} from '../../../core/date-tools.service';
import {PrimusRouterService} from '../../../core/primus-router.service';
import {SearchService} from "../../../core/search.service";

export interface ColumnLabels {
  [property: string]: string;
}
export type RowTransformFn = (value: unknown, row: SearchObject, col: string) => unknown;
export type RefreshDataFn = () => void;

export interface EditRowEvent {
  row: SearchObject;
  refreshTable: RefreshDataFn;
}

export interface AddRowEvent {
  cultureHubImport: boolean;
  refreshTable: RefreshDataFn;
}

@Component({
  selector: 'app-primus-solr-data-table',
  templateUrl: './primus-solr-data-table.component.html',
  styleUrls: ['./primus-solr-data-table.component.scss']
})
export class PrimusSolrDataTableComponent implements OnChanges {

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this._sort = sort;
    if (this.dataSource) {
      this.dataSource.sort = sort;
    }
  }

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
    if (this.dataSource) {
      this.dataSource.paginator = paginator;
    }
  }

  // Outputs
  @Output() public readonly rowClicked: EventEmitter<SearchObject>;
  @Output() public readonly editRowClicked: EventEmitter<EditRowEvent>;
  @Output() public readonly addRowClicked: EventEmitter<AddRowEvent>;
  @Output() public readonly selectionChanged: Observable<Array<string>>;

  // Config-options
  @Input() public enableSortHeader = false;
  @Input() public enableAddRowButton = false;
  @Input() public enableCultureHub = false;
  @Input() public enableSelection = false;
  @Input() public enableMultiSelection = false;
  @Input() public enableEditing = false;
  @Input() public linkToObjectPage = false;
  @Input() public rowValueTransformFn: RowTransformFn;

  // Data needed to correctly display information in table
  @Input() public columnLabels: ColumnLabels;
  @Input() public imageProperty?: string;
  @Input() public addRowButtonLabel?: string;

  // Datasource inputs
  @Input() public solrQuery: string;
  @Input() public filterString?: string;
  @Input() public additionalFilters: Array<{field: string, values: Array<string>}> = [];
  @Input() public columns: Array<string>;

  // Internal references
  private _sort?: MatSort;
  private _paginator?: MatPaginator;

  // Template references
  readonly dataSource: SolrDataSource;
  columnsToDisplay: Array<string> = [];

  constructor(private readonly searchService: SearchService,
              private readonly dateTools: DateToolsService,
              private readonly primusRouter: PrimusRouterService) {
    this.rowClicked = new EventEmitter<SearchObject>();
    this.editRowClicked = new EventEmitter<EditRowEvent>();
    this.addRowClicked = new EventEmitter<AddRowEvent>();
    this.dataSource = new SolrDataSource(this.searchService);
    this.selectionChanged = this.dataSource.selectionChange$;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('solrQuery')) {
      this.solrQuery = changes.solrQuery.currentValue;
    }
    if (changes.hasOwnProperty('filterString')) {
      this.filterString = changes.filterString.currentValue || '';
      this.dataSource.filterString = this.filterString;
    }
    if (changes.hasOwnProperty('additionalFilters')) {
        this.additionalFilters = changes.additionalFilters.currentValue || [];
    }
    if (changes.hasOwnProperty('columns')) {
      this.columns = changes.columns.currentValue;
      this.dataSource.fieldList = this.columns;
    }
    if (changes.hasOwnProperty('enableSelection')) {
      this.enableSelection = changes.enableSelection.currentValue;
    }
    if (changes.hasOwnProperty('enableMultiSelection')) {
      this.enableMultiSelection = changes.enableMultiSelection.currentValue;
    }
    if (changes.hasOwnProperty('enableEditing')) {
      this.enableEditing = changes.enableEditing.currentValue;
    }
    if (changes.hasOwnProperty('enableSortHeader')) {
      this.enableSortHeader = changes.enableSortHeader.currentValue;
      if (changes.enableSortHeader.currentValue !== changes.enableSortHeader.previousValue) {
        this.enableOrDisableSorting();
      }
    }

    if (changes.hasOwnProperty('solrQuery') ||
        changes.hasOwnProperty('additionalFilters')) {
      const filters = this.additionalFilters.map(f =>
        `${f.field}:("${f.values.join('","')}")`
      ).join(' AND ');
      this.dataSource.filterQuery = !!filters ? `${this.solrQuery} AND (${filters})` : this.solrQuery;
    }

    if (changes.hasOwnProperty('enableSelection') ||
        changes.hasOwnProperty('enableMultiSelection')) {
      this.dataSource.setSelectable(this.enableSelection || this.enableMultiSelection, this.enableMultiSelection);
    }

    if (changes.hasOwnProperty('enableSelection') ||
        changes.hasOwnProperty('enableMultiSelection') ||
        changes.hasOwnProperty('enableEditing') ||
        changes.hasOwnProperty('columns')) {
      const columns = [...this.columns];

      if (this.enableSelection || this.enableMultiSelection) {
        columns.unshift('select');
      }

      if (this.enableEditing) {
        columns.push('editButton');
      }

      this.columnsToDisplay = columns;
      this.enableOrDisableSorting();
    }
  }


  private enableOrDisableSorting(): void {
    if (this._sort) {
      this._sort.sortables.forEach(s =>
        this.enableSortHeader ? this._sort.register(s) : this._sort.deregister(s)
      );
    }
  }

  getColumnLabel(col: string): string {
    return this.columnLabels && col ? this.columnLabels[col] || '' : '';
  }

  isBooleanValue(row: SearchObject, col: string): boolean {
    const value = this.getRowValue(row, col);
    return typeof value === 'boolean' || value === 'true' || value === 'false';
  }

  handleEditRow(row: SearchObject): void {
    this.editRowClicked.emit({
      row: row,
      refreshTable: this.dataSource.refreshData.bind(this.dataSource)
    });
  }

  handleAddRow(): void {
    this.addRowClicked.emit({
      cultureHubImport: false,
      refreshTable: this.dataSource.refreshData.bind(this.dataSource)
    });
  }

  handleCultureHubImport(): void {
    this.addRowClicked.emit({
      cultureHubImport: true,
      refreshTable: this.dataSource.refreshData.bind(this.dataSource)
    })
  }

  getRowValue(row: SearchObject, column: string): unknown {
    if (!row || !column) { return ''; }
    let value: unknown = row[column];
    if (this.rowValueTransformFn) {
      value = this.rowValueTransformFn(value, row, column);
    }
    if (['created_at', 'updated_at'].includes(column)) {
      value = this.dateTools.isoDateToString(<string>value, 'datetime');
    }
    return value ?? '';
  }

  async goToCurrentObject(row) {
    const state = 'home.primus.artifact';
    const params = {
      artifactId: row.artifact_id,
      listName: 'overview',
      admin_type: 'concepts'
    };
    await this.primusRouter.navigateState(state, params);
  }

}
