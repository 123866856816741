import {Component, Input, OnInit} from '@angular/core';
import {ViewField} from "../../core/definitions/object-view";

@Component({
  selector: 'app-object-view-show-more',
  templateUrl: './object-view-show-more.component.html',
  styleUrls: ['./object-view-show-more.component.scss']
})
export class ObjectViewShowMoreComponent implements OnInit {
  @Input() field: ViewField;
  listName: string;


  ngOnInit() {
    if (['children', 'parents', 'relationships'].includes(this.field.field_name)) {
      this.listName = this.field.field_name
    }
  }

}
