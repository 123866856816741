import {Injectable} from '@angular/core';
import {OperationDef, OperationDisableCheck} from '../core/definitions/operation-def';
import {CmsApiService} from '../core/cms-api.service';
import {GetOperationsParams} from '../core/definitions/get-operations-params';
import {CommonsService} from '../core/commons.service';
import {OperationType} from '../core/definitions/operation-type.enum';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {Selector} from '../core/definitions/reference';
import {OperationStepType} from '../core/definitions/operation-step-type.enum';
import {CreateSectionsContainerParams, ObjectEditService} from '../core/object-edit.service';
import {
  OperationContextObject,
  OperationStepExecutionParams
} from '../core/definitions/operation-step-execution-params';
import {OperationStepExecutionResult} from '../core/definitions/operation-step-execution-result';
import {OperationExecutionStatus} from '../core/definitions/operation-execution-status.enum';
import {OperationStep} from '../core/definitions/operation-step';
import {ObjectStorageService} from '../core/object-storage.service';
import {ToolbarAction} from '../core/definitions/toolbar-action';
import {OperationCancelStatus} from '../core/definitions/operation-cancel-status.enum';
import {TranslateService} from '@ngx-translate/core';
import {FieldValueService} from '../core/field-value.service';
import {UploadMediaContainer} from '../core/definitions/upload-media-container';
import {FieldParameters} from '../core/definitions/field-parameters';
import {MetaField} from '../core/definitions/meta-field';
import {FieldConditionService} from '../core/field-condition.service';
import {CurrentObjectService} from '../core/current-object.service';
import {OperationExecutorType} from '../core/definitions/operation-executor-type.enum';
import {OperationActionType} from '../core/definitions/operation-action-type.enum';
import {MatDialog} from '@angular/material/dialog';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {OperationUploadContainer} from '../core/definitions/operation-upload-container';
import {OperationChangeState} from '../core/definitions/operation-change-state';
import {RefParams, RefService} from '../core/ref.service';
import {GetOperationObjectsParams} from '../core/definitions/get-operation-objects-params';
import {ChangeTrackerService} from '../core/change-tracker.service';
import {CopyKeepService} from '../object-edit/copy-keep-checkbox/copy-keep.service';
import {JobStatusSubscriberService} from '../core/jobstatussubcriber/job-status-subscriber.service';
import {IsActiveService} from '../main-menu/jobstatus/is-active.service';
import {OperationTarget} from '../core/definitions/operation-target.enum';
import {SectionsContainer} from '../core/definitions/sections-container';
import {CmsErrorHandlerParams, CmsErrorHandlerService} from '../core/cms-error-handler.service';
import {UiToolsService} from '../core/ui-tools.service';
import {OperationContainer} from '../core/definitions/operation-container';
import {SearchContainer} from '../core/definitions/search-container';
import {IfType} from '../core/definitions/field-if';
import {PrimusRouterService} from '../core/primus-router.service';
import {Subject} from 'rxjs';

export interface OperationAndSectionsContainerParams extends CreateSectionsContainerParams {
  operationTarget: OperationTarget;     // Type of operation 'target', search view, object view etc
  executorType: string;                 // Set to an executor type if we need to set current operation step immediately
  operationStepType: string;            // Set to an operation step type if we need to set current operation step immediately
}

export interface AfterCloseOperation {
  keepSelectedObjects: boolean;
  refreshView: boolean;
  resultObject: any;
}

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  currentOperationContainer: OperationContainer;
  resultObjectPrefix = '{result_object.';
  selectedObjectsPrefix = '{selected_objects.';
  private _operationStepChange$ = new Subject<{
    step: OperationStep;
    result?: OperationStepExecutionResult;
  } | 'CANCEL_EDIT'>();
  get operationStepChange$() {
    return this._operationStepChange$.asObservable();
  }

  constructor(private primusRouter: PrimusRouterService,
              private translate: TranslateService,
              private cms: CmsApiService,
              private cmsErrorHandler: CmsErrorHandlerService,
              private commons: CommonsService,
              private objectEditService: ObjectEditService,
              private objectStorageService: ObjectStorageService,
              private fieldValueService: FieldValueService,
              private fieldConditionService: FieldConditionService,
              private currentObjectService: CurrentObjectService,
              private modalService: MatDialog,
              private ref: RefService,
              private changeTracker: ChangeTrackerService,
              private copyKeepService: CopyKeepService,
              private jobStatusSubscriber: JobStatusSubscriberService,
              private isActiveService: IsActiveService,
              private uiTools: UiToolsService) {
  }

  async createOperationAndRootSectionsContainer(params: OperationAndSectionsContainerParams): Promise<OperationContainer> {
    const operationContainer = await this.createOperationContainer(params.operationTarget);
    await this.createSetRootSectionsContainerOnOperationContainer(params, operationContainer);
    await this.setOperations(operationContainer);
    if (params.executorType && params.operationStepType) {
      await this.setCurrentOperationByType(operationContainer, params.executorType, params.operationStepType);
      operationContainer.currentOperation.$$currentStep.$$isCopy = params.isCopy;
    }
    return operationContainer;
  }

  async createOperationContainer(operationTarget: string,
                                 operationContextObjects?: SuperObjectModel[]): Promise<OperationContainer> {
    const operationContainer = new OperationContainer(operationTarget);
    this.currentOperationContainer = operationContainer;
    if (operationContextObjects) {
      operationContainer.art = operationContextObjects[0];
      this.setOperationContextObjects(operationContainer, operationContextObjects);
    }
    operationContainer.selectorCallback = (selectedObj: SuperObjectModel[], selector: Selector, callback: any) => {
      const currentStep = operationContainer.currentOperation.$$currentStep;
      if (currentStep.operation_step_type === OperationStepType.SELECT_EXISTING_OBJECTS ||
        currentStep.operation_step_type === OperationStepType.SELECT_STORE_EXISTING_OBJECTS) {
        operationContainer.currentOperation.$$selectedObjects = selectedObj;
        this.executeOperationStep(operationContainer).then(res => {
          if (callback) {
            callback(res);
          }
        });
      } else if (currentStep.operation_step_type === OperationStepType.SELECT_EDIT_EXISTING_OBJECT ||
        selector.selector_type === 'copy:single') {
        // User selected a copy, need to go to next operation step and set operation object.
        const operationStepExecutionResult = new OperationStepExecutionResult();
        operationStepExecutionResult.result_object = selectedObj[0];
        this.setCopyStep(operationContainer, operationStepExecutionResult).then();
      }
    };
    return operationContainer;
  }

  setOperationModelData(operationContainer: OperationContainer, modelData: SuperObjectModel) {
    this.setCurrentOperationSectionContainers(operationContainer, null);
    operationContainer.currentOperation.$$modelData = modelData;
  }

  getCurrentOperationSectionContainers(operationContainer: OperationContainer): SectionsContainer[] {
    let res: SectionsContainer[];
    const currentStep = this.getCurrentOperationStep(operationContainer);
    if (currentStep) {
      const stepType = currentStep.operation_step_type;
      if (stepType === OperationStepType.EDIT_OBJECT || stepType === OperationStepType.EDIT_NEW_OBJECT) {
        if (operationContainer.rootSectionsContainer) {
          res = [operationContainer.rootSectionsContainer];
        }
      } else {
        res = operationContainer.currentOperation.$$sectionsContainers;
      }
      if (res) {
        res = this.checkAddUploadMediaContainers(operationContainer, res);
      }

      return res;
    }
  }

  setCurrentOperationSectionContainers(operationContainer: OperationContainer,
                                       sectionsContainers: SectionsContainer[]) {
    const currentStep = this.getCurrentOperationStep(operationContainer);
    if (currentStep) {
      const stepType = currentStep.operation_step_type;
      if ([OperationStepType.EDIT_OBJECT.toString(), OperationStepType.EDIT_NEW_OBJECT.toString()].includes(stepType)) {
        operationContainer.rootSectionsContainer = sectionsContainers ? sectionsContainers[0] : null;
        this.currentObjectService.currentObject = operationContainer.rootSectionsContainer.rootObject;
      } else {
        operationContainer.currentOperation.$$sectionsContainers = sectionsContainers;
      }
    }
  }

  getCurrentOperationStep(operationContainer: OperationContainer): OperationStep {
    let res: OperationStep;
    const currentOperation = operationContainer.currentOperation;
    if (currentOperation) {
      res = currentOperation.$$currentStep;
      if (!res) {
        console.warn('No current operation step set');
      }
    } else {
      console.warn('No current operation set');
    }
    return res;
  }

  async setOperations(operationContainer: OperationContainer, searchContainer?: SearchContainer): Promise<void> {
    if (operationContainer.rootSectionsContainer?.operations) {
      operationContainer.operations = operationContainer.rootSectionsContainer.operations;
    } else {
      const operationList = await this.cms.getOperations(
        this.createGetOperationParams(operationContainer, searchContainer));
      operationContainer.operations = operationList.operations;
    }
    this.checkSetDisabledOperations(operationContainer, operationContainer.operations);
    operationContainer.operations.forEach((opd, key) => {
      if (opd.sub_operations) {
        for (const sub_opd of opd.sub_operations) {
          operationContainer.operations[key].$$noChildren = sub_opd.sub_operations === undefined;
        }
      }
    });
  }

  async getNextOperationStepFromQueue(queueId: string): Promise<OperationStep> {
    return this.cms.getNextOperationStep(queueId);
  }

  async setOperationStep(operationContainer: OperationContainer) {
    if (operationContainer.currentOperation) {
      const operation = operationContainer.currentOperation;
      const operationStep = operation.$$currentStep;
      switch (operationStep.operation_step_type) {
        case OperationStepType.CREATE_OBJECT:
        case OperationStepType.CREATE_OBJECT_INSTANTLY:
        case OperationStepType.SELECT_MODEL_FOR_NEXT_STEP:
        case OperationStepType.CREATE_OBJECT_FROM_MODEL_IN_PREV_STEP:
        case OperationStepType.CONTINUE_SUBMITTED_OBJECT:
        case OperationStepType.EDIT_SELECTED_OBJECT:
        case OperationStepType.COPY_OBJECT:
        case OperationStepType.GET_EDIT_OBJECT:
        case OperationStepType.DELETE_OBJECT:
        case OperationStepType.DELETE_FROM_OBJECT:
        case OperationStepType.CONTINUE_NOT_SUBMITTED_OBJECT_GET_OPERATION_CONTEXTS:
        case OperationStepType.REIMPORT_CULTURE_HUB:
          // Must make an exception for "continue not submitted object"-step due to needing props from section container
          if (operationStep.operation_step_type !== OperationStepType.CONTINUE_NOT_SUBMITTED_OBJECT_GET_OPERATION_CONTEXTS) {
            this.setCurrentOperationSectionContainers(operationContainer, null);
          }
          operation.$$operationUploadContainer = new OperationUploadContainer();
          await this.setOperationSectionsContainerForStep(operationContainer);
          break;
        case OperationStepType.UPLOAD_MEDIA:
          this.setCurrentOperationSectionContainers(operationContainer, null);
          await this.setOperationSectionsContainerForStep(operationContainer);
          const uploadMediaContainer = new UploadMediaContainer();
          uploadMediaContainer.uploadInfo = operation.upload_info;
          uploadMediaContainer.parentObject = operationContainer.art;
          const operationUploadContainer = new OperationUploadContainer();
          operationUploadContainer.uploadMediaContainers = [uploadMediaContainer];
          operation.$$operationUploadContainer = operationUploadContainer;
          break;
        case OperationStepType.EDIT_OBJECT:
        case OperationStepType.EDIT_NEW_OBJECT:
          operation.$$operationUploadContainer = new OperationUploadContainer();
          if (!operationContainer.rootSectionsContainer) {
            if (operationContainer.operationContextObjects) {
              await this.createSetRootSectionsContainerOnOperationContainer({
                useExistingObject: true,
                objectId: operationContainer.operationContextObjects[0].artifact_id,
                templateGroupId: operationContainer.templateGroupId
              } as CreateSectionsContainerParams, operationContainer);
            } else {
              console.warn('Unable to set root sections container');
            }
          }
          this.setCurrentOperationSectionContainers(operationContainer, [operationContainer.rootSectionsContainer]);
          break;
        case OperationStepType.CLOSE_OPERATION:
        case OperationStepType.CHANGE_STATE:
        case OperationStepType.MODIFY_SELECTED_OBJECTS:
        case OperationStepType.SELECT_EXISTING_OBJECTS:
        case OperationStepType.SELECT_STORE_EXISTING_OBJECTS:
          // No special handling required
          break;
        case OperationStepType.CLOSE_OPERATION_REFRESH:
          console.log('refresh');
          break;
        default:
          console.log('Unable to process ' + operationStep.operation_step_type);
      }
    }
  }

  goNextOperationStep(operationContainer: OperationContainer) {
    this.setCurrentStepIndexAndStep(
      operationContainer.currentOperation.$$currentStepIndex + 1, operationContainer);
  }

  goPrevOperationStep(operationContainer: OperationContainer) {
    this.setCurrentStepIndexAndStep(
      operationContainer.currentOperation.$$currentStepIndex - 1, operationContainer);
  }

  setCurrentOperation(operationContainer: OperationContainer, operation: OperationDef) {
    operationContainer.currentOperation = this.commons.copy(operation);
    this.setCurrentStepIndexAndStep(0, operationContainer);
    return operationContainer.currentOperation;
  }

  private setCurrentStepIndexAndStep(stepIndex: number, operationContainer: OperationContainer) {
    operationContainer.currentOperation.$$currentStepIndex = stepIndex;
    operationContainer.currentOperation.$$currentStep = null;
    const operationSteps = operationContainer.currentOperation?.operation_steps;
    if (operationSteps) {
      operationContainer.currentOperation.$$currentStep = operationSteps[stepIndex];
    }
  }

  setCurrentSelectedGroupOperation(operationContainer: OperationContainer, operation: OperationDef) {
    operationContainer.currentSelectedGroupOperation = this.commons.copy(operation);
    this.setCurrentStepIndexAndStep(0, operationContainer);
    return operationContainer.currentSelectedGroupOperation;
  }

  // async setEditOperation() {
  //   await this.setCurrentOperationByType(
  //     this.currentOperationContainer, OperationExecutorType.ARTIFACT, OperationStepType.EDIT_OBJECT);
  // }

  async setCurrentOperationByType(
    operationContainer: OperationContainer,
    executorType: string,
    stepType: string) {
    let foundIt = false;
    for (const operation of operationContainer.operations) {
      if (operation.executor_type === executorType) {
        if (!operation.operation_steps) {
          continue;
        }
        for (const [index, operationStep] of operation.operation_steps.entries()) {
          if (operationStep.operation_step_type === stepType) {
            this.setCurrentOperation(operationContainer, operation);
            this.setCurrentStepIndexAndStep(index, operationContainer);
            await this.setOperationStep(operationContainer);
            foundIt = true;
            break;
          }
        }
      }
      if (foundIt) {
        break;
      }
    }
  }

  async changeState(operationStep: OperationStep, operationStepExecutionResult?: OperationStepExecutionResult) {
    const changeState = operationStep.change_state;
    for (const operationChangeState of changeState) {
      if (operationChangeState.from_state && this.primusRouter.currentState() !== operationChangeState.from_state) {
        continue;
      }
      if (operationChangeState.state_from_result_object) {
        this.goToObjectState(
          operationStepExecutionResult.result_object, operationChangeState.state_params, operationStep.$$isCopy).then();
        break;
      }
      const [state, stateParams] = this.getStateParams(operationChangeState, operationStepExecutionResult);
      if (stateParams && stateParams['edit'] !== undefined) {
        this.currentObjectService.isEditing = stateParams['edit'] === true;
      }
      await this.primusRouter.navigateState(state, stateParams, {
        location: 'replace',
        reload: true
      });
      break;
    }
  }

  async openOperationView(operationContainer: OperationContainer,
                          operation: OperationDef,
                          parentOperation?: OperationDef,
                          dialogCallback?: any): Promise<OperationStepExecutionResult> {
    let res: OperationStepExecutionResult = null;
    if (parentOperation) {
      parentOperation.$$skipToggle = false;
    }
    if (operation.$$disabled) {
      return res;
    }
    operation.$$showOperationView = true;
    if (!this.checkShowOpenShowSubOperations(operationContainer, operation, parentOperation)) {
      const firstStep = this.getFirstStep(operation);
      if (firstStep.operation_step_type === OperationStepType.CHANGE_STATE) {
        this._operationStepChange$.next({
          step: operation.operation_steps[0],
        });
        if (!operationContainer.$$onlyEmitStateChange) {
          await this.changeState(operation.operation_steps[0]);
        }
      } else if (this.isExecuteInstantlyStep(firstStep)) {
        this.setCurrentOperation(operationContainer, operation);
        await this.setOperationStep(operationContainer);
        res = await this.executeOperationStep(operationContainer);
      } else {
        this.setCurrentOperation(operationContainer, operation);
        if (parentOperation) {
          parentOperation.$$showOperationMenu = false;
        }
        console.log('-----');
        console.log(operationContainer.currentOperation?.$$currentStep?.operation_step_type);

        if (this.isOpenSearchSelectorStep(firstStep)) {
          await this.openSearchSelector(operationContainer);
        } else if (operationContainer.currentOperation?.$$currentStep?.operation_step_type == 'create_new_report') {
            console.log('ny step type');
        } else if (operationContainer.openOperationDialogFn) {
          operationContainer.openOperationDialogFn(dialogCallback);
        }
      }
    }
    return res;
  }


  async toggleOperationMenu(operationContainer: OperationContainer,
                            operation: OperationDef,
                            noCloseCheck?: boolean,
                            callback?: any): Promise<OperationStepExecutionResult> {
    let res: OperationStepExecutionResult = null;
    let showMenu: boolean;
    if (operation.$$skipToggle) {
      operation.$$skipToggle = false;
      return res;
    }
    showMenu = !operation.$$showOperationMenu;
    if (!operation.sub_operations) {
      this.clearShowOperations(operationContainer, operationContainer.operations);
      res = await this.openOperationView(operationContainer, operation, null, callback);
    } else {
      this.clearShowOperations(operationContainer, operationContainer.operations);
      for (const m of operationContainer.operations) {
        m.$$showOperationMenu = false;
      }
      operation.$$showOperationMenu = showMenu;
      if (operation.operation_type === OperationType.OPERATION) {
        this.setCurrentOperation(operationContainer, operation);
      }
      if (!noCloseCheck) {
        if (operation.$$showOperationMenu) {
          window.addEventListener('mouseup', event => {
            this.checkCloseOperationCallback(operationContainer, event);
          }, true);
        } else {
          window.removeEventListener('mouseup', event => {
            this.checkCloseOperationCallback(operationContainer, event);
          }, true);
        }
      }
    }
    return res;
  }

  // Used in "operation selector vertical" component to mark operation as "selected"
  setSelectedOperation(operationContainer: OperationContainer, operation: OperationDef) {
    for (const operationItem of operationContainer.operations) {
      operationItem.$$operationSelected = false;
    }
    operation.$$operationSelected = true;
  }

  async executeOperationStep(operationContainer: OperationContainer,
                             action?: ToolbarAction, useCmsErrorHandler: boolean = true): Promise<OperationStepExecutionResult> {
    const progressModal = this.modalService.open(ProgressDialogComponent, {
      disableClose: true,
      panelClass: 'progress-modal'
    });
    try {
      const operationExecutionParams = this.generateOperationStepExecutionParams(operationContainer);
      const res = await this.cms.executeOperationStep(operationExecutionParams);
      if (res.status === OperationExecutionStatus.OPERATION_COMPLETE) {
        await this.closeCurrentOperation(operationContainer, false,
          {keepSelectedObjects: false, refreshView: false, resultObject: res.result_object});
      } else if (res.status === OperationExecutionStatus.STEP_COMPLETE) {
        if (!action || action.action_type !== OperationActionType.REGISTER_STAY_ON_STEP) {
          await this.setOperationObjectGoNext(operationContainer, res);
        } else {
          const updatedSectionsContainer = await this.objectEditService.loadObjectGetSectionsContainer(
            res.result_object.artifact_id, true, operationContainer.templateGroupId);
          this.setCurrentOperationSectionContainers(operationContainer, [updatedSectionsContainer]);
        }
      } else if (res.status === OperationExecutionStatus.QUEUED) {
        this.handleStepResultQueued(operationContainer, res);
      } else if (res.status === OperationExecutionStatus.FAILED) {
        if (useCmsErrorHandler) {
          this.cmsErrorHandler.errHandler(res, {
            clientMsg: 'TRANS__OPERATION__OPERATION_EXECUTION_FAILED'
          } as CmsErrorHandlerParams);
        }
      }
      this.checkHandleReportOperation(operationContainer);
      progressModal.close();
      return res;
    } catch (e) {
      console.error(e);
      progressModal.close();
    }
  }

  async cancelOperationStep(operationContainer: OperationContainer, action: ToolbarAction) {
    if (this.checkConfirmCancel(operationContainer)) {
      return;
    }
    if (!action.submit_required) {
      await this.closeCurrentOperation(operationContainer, true,
        {keepSelectedObjects: true, refreshView: false, resultObject: null});
    } else {
      const params = this.generateOperationStepExecutionParams(operationContainer);
      const res = await this.cms.cancelOperationStep(params);
      if (res.operation_cancel_status === OperationCancelStatus.CANCEL_COMPLETE) {
        await this.closeCurrentOperation(operationContainer, true,
          {keepSelectedObjects: true, refreshView: false, resultObject: null});
      } else if (res.operation_cancel_status === OperationCancelStatus.CANCEL_IN_PROGRESS) {
        operationContainer.currentOperation.$$inProgress = true;
      } else if (res.operation_cancel_status === OperationCancelStatus.CANCEL_FAILED) {
        this.cmsErrorHandler.errHandler(res, {
          clientMsg: 'TRANS__OPERATION__OPERATION_CANCEL_FAILED'
        } as CmsErrorHandlerParams);
      }
    }
  }

  async closeOperationStep(operationContainer: OperationContainer): Promise<OperationStepExecutionResult> {
    const resultObject = operationContainer.currentOperation.$$sectionsContainers[0]?.rootObject;
    await this.closeCurrentOperation(operationContainer, false,
      {keepSelectedObjects: true, refreshView: false, resultObject: resultObject});
    const res = new OperationStepExecutionResult();
    res.result_object = resultObject;
    return res;
  }

  canShowOperation(targetObject: SuperObjectModel, operation: OperationDef) {
    let res = true;
    const ifs = operation.ifs;
    if (ifs) {
      const obj = targetObject;
      const fieldParameters = new FieldParameters();
      fieldParameters.field = {} as MetaField;
      fieldParameters.field.field_ifs = ifs;
      fieldParameters.rootObject = obj;
      res = this.fieldConditionService.runIf(IfType.SHOW, fieldParameters).result;
    }
    return res;
  }

  isEditableOperation() {
    let res = false;
    const currentOperation = this.currentOperationContainer?.currentOperation;
    if (currentOperation) {
      res = currentOperation.executor_type === OperationExecutorType.ARTIFACT;
    }
    return res;
  }

  cancelOperation() {
    if (this.currentOperationContainer) {
      this.currentOperationContainer.cancelOperation();
    }
  }

  getStateParams(operationChangeState: OperationChangeState, operationStepExecutionResult?: OperationStepExecutionResult): [string, any] {
    if (operationStepExecutionResult?.result_object?.state_name) {
      return [
        operationStepExecutionResult.result_object.state_name,
        operationStepExecutionResult.result_object.state_params];
    }
    const origStateParams = operationChangeState.state_params;
    if (!origStateParams) {
      return [null, null];
    }
    const stateParams = {};
    for (const [key, origValue] of Object.entries(origStateParams)) {
      let value = origValue;
      if (typeof value === 'string' && value.indexOf('{') === 0) {
        if (!operationStepExecutionResult) {
          console.error('Unable to set state parameter, missing result object');
        } else {
          if (value.indexOf(this.resultObjectPrefix) === 0) {
            value = this.getStateParamValueFromResultObject(operationStepExecutionResult, value)
          } else if (value.indexOf(this.selectedObjectsPrefix) === 0) {
            value = this.getStateParamValueFromSelectedObjects(operationStepExecutionResult, value);
          }
        }
      }
      stateParams[key] = value;
    }
    const stateName = this.getStateNameFromOperationChangeState(operationChangeState);
    return [stateName, stateParams];
  }

  async getOperationObject(operationContainer: OperationContainer): Promise<SuperObjectModel> {
    const params = new GetOperationObjectsParams();
    params.operation_type_id = operationContainer.currentOperation.operation_type_id;
    params.operation_step_index = operationContainer.currentOperation.$$currentStepIndex;
    params.context_objects = this.getReducedContextObjects(
      operationContainer.operationContextObjects);
    if (operationContainer.currentOperation.executor_type === OperationExecutorType.FIELD_VALUE &&
      operationContainer.currentOperation.$$sectionsContainers) {
      params.select_field = operationContainer.currentOperation.$$sectionsContainers[0].rootObject['select_field'];
    }
    if (operationContainer.getTargetId) {
      const targetId = operationContainer.getTargetId();
      params.parent_object_id = targetId !== 'none' ? targetId : undefined;
    }
    if (operationContainer.currentOperation?.$$selectedObjects) {
      params.selected_objects = operationContainer.currentOperation.$$selectedObjects.map(obj => this.mapArtifact(obj));
    }
    const currentStep = operationContainer.currentOperation?.$$currentStep;
    if (currentStep?.operation_step_type === OperationStepType.GET_EDIT_OBJECT) {
      const getEditObjectIdFrom = operationContainer.currentOperation.$$currentStep.get_edit_object_id_from;
      if (getEditObjectIdFrom) {
        if (getEditObjectIdFrom === 'list_field_template_id') {
          params.get_edit_object = {
            artifact_id: operationContainer.listFieldTemplateId,
            object_type: currentStep.get_edit_object_object_type
          };
        } else {
          console.log('No other "get_edit_object_id_from" types defined yet');
        }
      }
    }
    return this.cms.getOperationObject(params);
  }

  setOperationContextObjects(operationContainer: OperationContainer, operationContextObjects: SuperObjectModel[]) {
    operationContainer.operationContextObjects = operationContextObjects;
  }

  private getReducedContextObjects(operationContextObjects: SuperObjectModel[]): SuperObjectModel[] {
    let res = [];
    for (let obj of operationContextObjects || []) {
      if (obj.meta_type == 'sub_model' || !obj.artifact_id) {
        res.push(obj)
      } else {
        res.push({
          artifact_id: obj.artifact_id,
          meta_type: obj.meta_type,
          object_type: obj.object_type,
          context_id: obj.context_id,
          superobject_type_id: obj.superobject_type_id,
          template_group_id: obj.template_group_id,
          model_type_id: obj.model_type_id
        });
      }
    }
    return res;
  }

  private getFirstStep(operation: OperationDef) {
    return operation.operation_steps ? operation.operation_steps[0] : null;
  }

  private checkShowOpenShowSubOperations(
    operationContainer: OperationContainer,
    operation: OperationDef,
    parentOperation: OperationDef) {
    let res = false;
    if (operationContainer.viewName === 'object-menus-small' && operation.sub_operations && parentOperation) {
      res = true
      this.showSubOperations(operation, parentOperation);
    }
    return res;
  }

  private isExecuteInstantlyStep(firstStep: OperationStep) {
    return [OperationStepType.MODIFY_SELECTED_OBJECTS.toString(),
      OperationStepType.CREATE_OBJECT_INSTANTLY.toString()].includes(firstStep.operation_step_type);
  }

  private isOpenSearchSelectorStep(firstStep: OperationStep) {
    return firstStep.operation_step_type === OperationStepType.SELECT_EXISTING_OBJECTS ||
      firstStep.operation_step_type === OperationStepType.SELECT_EDIT_EXISTING_OBJECT ||
      firstStep.operation_step_type === OperationStepType.SELECT_STORE_EXISTING_OBJECTS;
  }

  private checkAddUploadMediaContainers(operationContainer: OperationContainer, sectionsContainers: SectionsContainer[]) {
    const uploadMediaContainers = operationContainer.currentOperation.$$operationUploadContainer?.uploadMediaContainers;
    if (uploadMediaContainers && uploadMediaContainers.length) {
      if (operationContainer.currentOperation.upload_info) {
        sectionsContainers = [];
      }
      for (const uploadMediaContainer of uploadMediaContainers) {
        if (uploadMediaContainer.fileObjectSectionsContainers) {
          sectionsContainers = sectionsContainers.concat(uploadMediaContainer.fileObjectSectionsContainers);
        }
      }
    }
    return sectionsContainers;
  }

  private mapArtifact(obj: SuperObjectModel) {
    return {
      artifact_id: obj.artifact_id,
      object_type: obj.object_type,
      meta_type: obj.meta_type,
      template_group_id: obj['template_group_id'],
      model_type_id: obj['model_type_id'],
      template_group_type_id: obj['template_group_type_id'],
      'temporary_placement.placement_event_id': obj['temporary_placement.placement_event_id'],
      superobject_type_id: obj.superobject_type_id
    };
  }

  private checkConfirmCancel(operationContainer: OperationContainer): boolean {
    let cancelled = false;
    let checkObject: SuperObjectModel;
    if (operationContainer.rootSectionsContainer) {
      checkObject = operationContainer.rootSectionsContainer.rootObject;
    }
    if (this.currentObjectService.isEditing && checkObject && this.changeTracker.objectHasChanges(checkObject)) {
      const dialogText = this.translate.instant('TRANS__MODAL_DIALOG__ARE_YOU_SURE');
      if (!window.confirm(dialogText)) {
        cancelled = true;
      } else {
        this.currentObjectService.isEditing = false;
      }
    }
    return cancelled;
  }

  private createGetOperationParams(operationContainer: OperationContainer, searchContainer?: SearchContainer): GetOperationsParams {
    const params = new GetOperationsParams();
    params.operation_target = operationContainer.operationTarget;
    if (operationContainer.operationContextObjects) {
      this.setContextBasedOperationParams(operationContainer, params);
    }
    if (searchContainer) {
      params.search_result_view_type = searchContainer.searchResultViewName;
      params.search_view_name = searchContainer.currentPathView?.search_view.search_view_type;
    }
    params.parent_object_type = operationContainer.art?.object_type;
    params.parent_meta_type = operationContainer.art?.meta_type;
    return params;
  }

  private setContextBasedOperationParams(operationContainer: OperationContainer, params: GetOperationsParams) {
    for (const object of operationContainer.operationContextObjects) {
      if (params.object_types.indexOf(object.object_type) === -1) {
        params.object_types.push(object.object_type);
      }
      if (params.meta_types.indexOf(object.meta_type) === -1) {
        params.meta_types.push(object.meta_type);
      }
      if (object.status && object.status.status_type_id) {
        params.status_type = object.status.status_type_id;
      }
      if (object.artifact_id || object.context_id) {
        this.setOperationParamsContexts(params, object);
      } else {
        if (object.artifact_id !== operationContainer.rootSectionsContainer?.rootObject?.artifact_id) {
          console.warn('Unable to set contexts');
        }
      }
    }
  }

  private setOperationParamsContexts(params: GetOperationsParams, object: SuperObjectModel) {
    if (object.meta_type === 'sub_model') {
      // Keep the original object if it's a sub model in order for relation based operations to work
      params.operation_contexts.push(object);
    } else {
      params.operation_contexts.push({
        artifact_id: object.artifact_id,
        context_id: object.context_id,
        object_type: object.object_type,
        meta_type: object.meta_type,
        superobject_type_id: object.superobject_type_id
      } as SuperObjectModel);
    }
  }

  private checkSetDisabledOperations(operationContainer: OperationContainer,
                                     operations: Array<OperationDef>,
                                     parentToolTip?: string) {
    for (const operation of operations) {
      let subOperations: OperationDef[];
      const disableChecks = operation.disable_check || [];
      for (const disableCheck of disableChecks) {
        if (disableCheck.check_type === 'parentObject') {
          this.runDisableCheck(operation, [operationContainer.art], disableCheck);
        } else if (disableCheck.check_type === 'contextObjects') {
          this.runDisableCheck(operation, operationContainer.operationContextObjects, disableCheck)
        } else {
          console.warn('Check type not implemented: ' + disableCheck.check_type);
        }
      }
      this.checkSetOperationTooltip(operation, parentToolTip);
      subOperations = operation.sub_operations;
      if (subOperations) {
        this.checkSetDisabledOperations(operationContainer, subOperations, operation.$$toolTip);
      }
    }
  }

  private checkSetOperationTooltip(operation: OperationDef, parentToolTip: string) {
    if (!operation.$$toolTip) {
      operation.$$toolTip = this.translate.instant(operation.menu_title);
      if (parentToolTip) {
        operation.$$toolTip = parentToolTip + ' ' + operation.$$toolTip.toLowerCase();
      }
    }
  }

  private runDisableCheck(operation: OperationDef,
                          checkObjects: SuperObjectModel[],
                          disableCheck: OperationDisableCheck) {
    const comparator = disableCheck.comparator || '==';
    for (const checkObject of checkObjects) {
      if (this.fieldValueService.fieldIf(checkObject, disableCheck.field, comparator, disableCheck.value)) {
        operation.$$disabled = true;
        if (operation.disable_reason) {
          operation.$$toolTip = this.translate.instant(operation.disable_reason);
        }
        break;
      }
    }
  }

  private async setCopyStep(operationContainer: OperationContainer,
                            operationStepExecutionResult: OperationStepExecutionResult) {
    const copyStep = operationContainer.currentOperation.copy_step;
    if (!copyStep) {
      console.error('Copy step missing from operation.');
      return;
    }
    operationContainer.currentOperation.$$currentStep = copyStep;
    operationContainer.currentOperation.$$currentStepIndex = 1;
    await this.goStep(operationContainer, operationStepExecutionResult);
  }

  private async setOperationObjectGoNext(operationContainer: OperationContainer,
                                         operationStepExecutionResult: OperationStepExecutionResult) {
    const operation = operationContainer.currentOperation;
    const oldOperationStep = operation.$$currentStep;
    this.goNextOperationStep(operationContainer);
    if (!operation.$$currentStep) {
      console.log('No more steps');
      return;
    }
    operation.$$currentStep.$$isCopy = oldOperationStep.$$isCopy;
    await this.goStep(operationContainer, operationStepExecutionResult);
  }

  private async goStep(operationContainer: OperationContainer,
                       operationStepExecutionResult: OperationStepExecutionResult) {
    const operationStep = operationContainer.currentOperation.$$currentStep;
    if (operationStepExecutionResult.target_state) {
      this.currentObjectService.isEditing = false;
      await this.closeCurrentOperation(operationContainer, false, {
        keepSelectedObjects: false,
        refreshView: false,
        resultObject: operationStepExecutionResult.result_object
      });
      await this.primusRouter.navigateState(
        operationStepExecutionResult.target_state.state_name,
        operationStepExecutionResult.target_state.state_params,
        {location: 'replace', reload: true});
    } else if (operationStep.operation_step_type === OperationStepType.CHANGE_STATE) {
      if (!operationStep.information) {
        await this.closeCurrentOperation(operationContainer, false,
          {
            keepSelectedObjects: false,
            refreshView: false,
            resultObject: operationStepExecutionResult.result_object
          });
        this._operationStepChange$.next({
          step: operationStep,
          result: operationStepExecutionResult,
        });
        if (!operationContainer.$$onlyEmitStateChange) {
          await this.changeState(operationStep, operationStepExecutionResult);
        }
      } else {
        operationContainer.currentOperation.$$sectionsContainers = null;
      }
    } else if (operationStep.operation_step_type === OperationStepType.CLOSE_OPERATION) {
      await this.closeCurrentOperation(operationContainer, false,
        {
          keepSelectedObjects: false,
          refreshView: false,
          resultObject: operationStepExecutionResult.result_object
        });
    } else if (operationStep.operation_step_type === OperationStepType.CLOSE_OPERATION_REFRESH) {
      await this.closeCurrentOperation(operationContainer, false,
        {
          keepSelectedObjects: false,
          refreshView: true,
          resultObject: operationStepExecutionResult.result_object
        });
    } else if (operationStep.operation_step_type === OperationStepType.EDIT_SELECTED_OBJECT) {
      if (operationContainer.openOperationDialogFn) {
        operationContainer.openOperationDialogFn((res: any) => {
          console.log('Result: ' + res);
        });
      }
    } else if (operationStep.operation_step_type === OperationStepType.CREATE_OBJECT_FROM_MODEL_IN_PREV_STEP) {
      operationStep.operation_model = operationStepExecutionResult.result_object.object_type;
      await this.setOperationSectionsContainerForStep(operationContainer, true);
    } else if (operationStepExecutionResult.result_object) {
      this.setOperationModelData(operationContainer, operationStepExecutionResult.result_object);
    }
  }


  private generateOperationStepExecutionParams(operationContainer: OperationContainer): OperationStepExecutionParams {
    const currentOperationStep = operationContainer.currentOperation.$$currentStep;
    const res = new OperationStepExecutionParams();
    let sectionsContainers: SectionsContainer[];
    res.operation_type_id = operationContainer.currentOperation.operation_type_id;
    res.operation_step_index = operationContainer.currentOperation.$$currentStepIndex;
    if (currentOperationStep.operation_step_type === OperationStepType.SELECT_EXISTING_OBJECTS ||
      currentOperationStep.operation_step_type === OperationStepType.SELECT_STORE_EXISTING_OBJECTS) {
      res.operation_objects = operationContainer.currentOperation.$$selectedObjects.map(
        obj => this.objectStorageService.getCleanObject(obj));
    } else if (currentOperationStep.operation_step_type === OperationStepType.MODIFY_SELECTED_OBJECTS) {
      // Used for spectrum procedure operations
      res.operation_objects = [operationContainer.art];
    } else if (currentOperationStep.operation_step_type === OperationStepType.DELETE_FROM_OBJECT) {
      if (operationContainer.getTargetId) {
        res.operation_objects = res.operation_objects || [];
        res.operation_objects.push({artifact_id: operationContainer.getTargetId()});
      }
    } else {
      sectionsContainers = this.getCurrentOperationSectionContainers(operationContainer);
      this.setOperationStepParamsOperationObjectsFromSectionContainers(operationContainer, sectionsContainers, res);
    }
    this.setOperationStepParamsContexts(operationContainer, sectionsContainers, res);
    if (currentOperationStep.set_operation_params_from_source_array_filters) {
      res.operation_params = operationContainer.contentInfo.curContentListSource.sourceArrayFilters;
    }
    this.setTemplateBasedOperationStepParams(operationContainer, res);
    if (operationContainer.currentOperation.executor_type === OperationExecutorType.FIELD_VALUE) {
      res.operation_params = res.operation_params || {};
      res.operation_params.select_field = operationContainer.currentOperation.$$sectionsContainers[0].rootObject['select_field'];
    }
    return res;
  }

  private setOperationStepParamsOperationObjectsFromSectionContainers(operationContainer: OperationContainer,
                                                                      sectionsContainers: SectionsContainer[],
                                                                      operationStepExecutionParams: OperationStepExecutionParams) {
    if (!sectionsContainers) {
      return;
    }
    const currentOperationStep = operationContainer.currentOperation.$$currentStep;
    operationStepExecutionParams.operation_objects = sectionsContainers.map(sectionsContainer => {
      if (currentOperationStep.$$isCopy) {
        this.copyKeepService.removeNotKeep(sectionsContainer.rootObject);
      }
      return this.objectStorageService.getCleanObject(sectionsContainer.rootObject);
    });
  }

  private setTemplateBasedOperationStepParams(operationContainer: OperationContainer,
                                              operationStepExecutionParams: OperationStepExecutionParams) {
    if (operationContainer.templateGroupId || operationContainer.listFieldTemplateId) {
      operationStepExecutionParams.operation_params = operationStepExecutionParams.operation_params || {};
      if (operationContainer.templateGroupId) {
        operationStepExecutionParams.operation_params.template_group_id = operationContainer.templateGroupId;
      }
      if (operationContainer.listFieldTemplateId) {
        operationStepExecutionParams.operation_params.list_field_template_id = operationContainer.listFieldTemplateId;
      }
    }
  }

  private setOperationStepParamsContexts(operationContainer: OperationContainer,
                                         sectionsContainers: SectionsContainer[],
                                         operationStepExecutionParams: OperationStepExecutionParams) {
    const operationContextObjects = sectionsContainers?.length && sectionsContainers[0].operationContextObjects ?
      sectionsContainers[0].operationContextObjects :
      operationContainer.operationContextObjects;
    if (operationContextObjects?.length && operationContextObjects[0].meta_type === 'sub_model') {
      // Using 'raw' operation context objects in order for operation based on relation objects to work
      operationStepExecutionParams.operation_contexts = operationContextObjects;
    } else if (operationContextObjects) {
      operationStepExecutionParams.operation_contexts = operationContextObjects
        .filter(contextObj => !!(contextObj.artifact_id || contextObj.context_id))
        .map(contextObj => {
          return {
            artifact_id: contextObj.artifact_id,
            context_id: contextObj.context_id,
            object_type: contextObj.object_type,
            meta_type: contextObj.meta_type,
            superobject_type_id: contextObj.superobject_type_id
          } as OperationContextObject;
        });
    }
  }

  private async createSetRootSectionsContainerOnOperationContainer(params: CreateSectionsContainerParams,
                                                                   operationContainer: OperationContainer) {
    const sectionsContainer = await this.objectEditService.createSectionsContainer(params);
    if (sectionsContainer) {
      if (!operationContainer.operationContextObjects) {
        this.setOperationContextObjects(operationContainer, [sectionsContainer.rootObject]);
      }
      operationContainer.art = sectionsContainer.rootObject;
      sectionsContainer.operationContextObjects = operationContainer.operationContextObjects;
      operationContainer.rootSectionsContainer = sectionsContainer;
      if (sectionsContainer.templateGroupId) {
        operationContainer.templateGroupId = sectionsContainer.templateGroupId;
      }
    }
  }

  private async setOperationSectionsContainerForStep(
    operationContainer: OperationContainer, noServerCreate?: boolean) {
    const operation = operationContainer.currentOperation;
    const operationStep = operation.$$currentStep;
    if (noServerCreate) {
      operation.$$modelData = null;
    } else if (operationStep.operation_step_type === OperationStepType.CONTINUE_NOT_SUBMITTED_OBJECT_GET_OPERATION_CONTEXTS ||
      (!operation.$$modelData &&
        operationStep.operation_step_type !== OperationStepType.CREATE_OBJECT_FROM_MODEL_IN_PREV_STEP)) {
      operation.$$modelData = await this.getOperationObject(operationContainer);
    }
    const params = {
      usePrimeFields: operationStep.model_prime_fields,
      useExistingObject: operationStep.is_copy_step || [
        OperationStepType.CONTINUE_SUBMITTED_OBJECT.toString(),
        OperationStepType.EDIT_SELECTED_OBJECT.toString(),
        OperationStepType.GET_EDIT_OBJECT.toString()].indexOf(operationStep.operation_step_type) !== -1,
      object: operation.$$modelData,
      objectType: operationStep.operation_model,
      templateGroupId: operationContainer.templateGroupId,
      isCopy: operationStep.is_copy_step,
      createMultiple: operationStep.create_multiple
    } as CreateSectionsContainerParams;
    operationStep.$$isCopy = params.isCopy;
    // Get context objects from previous step, which might contain filtered context objects
    if (operationStep.operation_step_type === OperationStepType.CREATE_OBJECT_FROM_MODEL_IN_PREV_STEP) {
      const prevContextObjects = operation.$$sectionsContainers ? operation.$$sectionsContainers[0]['operationContextObjects'] : null;
      if (prevContextObjects) {
        this.setOperationContextObjects(operationContainer, prevContextObjects);
      }
    }
    const sectionsContainer = await this.objectEditService.createSectionsContainer(params);
    if (sectionsContainer) {
      sectionsContainer.operationContextObjects = operationContainer.operationContextObjects;
      sectionsContainer.useContextCreatingIdentifier = operationStep.use_context_creating_identifier;
      operation.$$sectionsContainers = [sectionsContainer];
    }
  }

  private checkCloseOperationCallback(operationContainer: OperationContainer, item: any) {
    const found = this.uiTools.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.closeOperationMenus(operationContainer.operations);
        window.removeEventListener('mouseup', event => {
          this.checkCloseOperationCallback(operationContainer, event);
        }, true);
      }, 100);
    }
  }

  private closeOperationMenus(operations: OperationDef[]) {
    for (const operation of operations) {
      operation.$$showOperationMenu = false;
      if (operation.sub_operations) {
        this.closeOperationMenus(operation.sub_operations);
      }
    }
  }

  // Display 3rd level menus (menu group/sub menus/sub menus)
  private showSubOperations(operation: OperationDef, parentOperation: OperationDef) {
    parentOperation.$$skipToggle = true;
    for (const m of parentOperation.sub_operations) {
      if (m.menu_title !== operation.menu_title) {
        m.$$showOperationMenu = false;
      } else {
        m.$$showOperationMenu = !m.$$showOperationMenu;
      }
    }
  }

  private clearShowOperations(operationContainer: OperationContainer, operations: OperationDef[]) {
    const curOperation = operationContainer.currentOperation;
    if (operations) {
      for (const m of operations) {
        if (!curOperation || m.menu_title !== curOperation.menu_title) {
          m.$$showOperationView = false;
        }
        this.clearShowOperations(operationContainer, m.sub_operations);
      }
    }
  }

  private async closeCurrentOperation(operationContainer: OperationContainer, changeState: boolean,
                                      afterClose: AfterCloseOperation) {
    if (!operationContainer.currentOperation) {
      return;
    }
    operationContainer.currentOperation.$$showOperationView = false;
    if (operationContainer.currentOperation.$$operationDialog) {
      operationContainer.currentOperation.$$operationDialog.close(afterClose);
      if (afterClose.refreshView && operationContainer.refreshFn) {
        operationContainer.refreshFn();
      }
    } else {
      if (!operationContainer.currentOperation.keep_current_operation_at_close) {
        setTimeout(() => {
          operationContainer.currentOperation = null;
        }, 1000);
      }
      if (afterClose.refreshView && operationContainer.refreshFn) {
        operationContainer.refreshFn();
      }
    }
    if (changeState) {

      const stepType = operationContainer.currentOperation.$$currentStep.operation_step_type;
      if (stepType === OperationStepType.EDIT_OBJECT) {
        this._operationStepChange$.next('CANCEL_EDIT');

        if (!operationContainer.$$onlyEmitStateChange) {
          await this.primusRouter.navigateState(this.primusRouter.currentState(),
            {edit: null},
            {location: 'replace', reload: true});
        }
      } else if (stepType === OperationStepType.EDIT_NEW_OBJECT && !operationContainer.$$onlyEmitStateChange) {
        window.history.back();
      }
    }
  }

  // private getCurrentOperation(operationContainer: OperationContainer): OperationDef {
  //   return operationContainer.currentOperation;
  // }
  //
  // private setOperationView(operationContainer: OperationContainer, setCurrentOperation?) {
  //   operationContainer.operations.forEach((operation, key) => {
  //     if (operation.sub_operations) {
  //       operation.sub_operations.forEach(subOperation => {
  //         operationContainer.operations[key].$$noChildren = subOperation.sub_operations === undefined;
  //       });
  //     }
  //     if (setCurrentOperation) {
  //       if (operation.content_list === operationContainer.contentInfo.curListName) {
  //         this.setCurrentOperation(operationContainer, operation);
  //       }
  //     }
  //   });
  // }

  private async goToObjectState(object: SuperObjectModel, stateParams: any, isCopy: boolean) {
    const refParams = new RefParams(object, stateParams);
    refParams.edit = !isCopy;
    this.currentObjectService.isEditing = false;
    const sRef = await this.ref.makeRef(refParams);
    await this.primusRouter.navigateState(sRef.stateName, sRef.param);
  }

  private async openSearchSelector(operationContainer: OperationContainer) {
    console.log('Open search selector: ', operationContainer);
    const currentOperation = operationContainer.currentOperation;
    if (currentOperation) {
      if (!currentOperation.$$currentStep) {
        await this.setOperationStep(operationContainer);
      }
      operationContainer.enableSelector(currentOperation.$$currentStep.selectors[0]);
    }
  }

  private handleStepResultQueued(operationContainer: OperationContainer, res: OperationStepExecutionResult) {
    operationContainer.currentOperation.$$inProgress = true;
    this.jobStatusSubscriber.startPolling(true);
    this.isActiveService.changeState(true);
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    const backDrop = document.getElementsByClassName('cdk-overlay-backdrop');
    if (dialog && backDrop) {
      dialog[0]['classList'].add('leave-animation-dialog');
      backDrop[0]['classList'].add('leave-animation-backdrop');
      setTimeout(() => {
        const changeState = operationContainer.currentOperation.$$currentStep.operation_step_type ===
          OperationStepType.EDIT_NEW_OBJECT;
        this.closeCurrentOperation(operationContainer, changeState,
          {keepSelectedObjects: false, refreshView: true, resultObject: res.result_object}).then();
      }, 500);
    }
  }

  private checkHandleReportOperation(operationContainer: OperationContainer) {
    // TODO: remove this when reports (backend) is moved into operations
    if (operationContainer.currentOperation.executor_type === OperationExecutorType.REPORT) {
      this.jobStatusSubscriber.startPolling(true);
      this.isActiveService.changeState(true);
      if (operationContainer.currentOperation.$$operationDialog !== undefined) {
        const dialog = document.getElementsByClassName('cdk-overlay-pane');
        const backDrop = document.getElementsByClassName('cdk-overlay-backdrop');
        if (dialog && backDrop) {
          dialog[0]['classList'].add('leave-animation-dialog');
          backDrop[0]['classList'].add('leave-animation-backdrop');
        }
      }
    }
  }

  private getStateParamValueFromResultObject(operationStepExecutionResult: OperationStepExecutionResult, value: string) {
    const resultObjectKey = value.substring(this.resultObjectPrefix.length, value.length - 1);
    if (operationStepExecutionResult.result_object) {
      value = operationStepExecutionResult.result_object[resultObjectKey];
    } else {
      console.warn('Operation result object not set!');
    }
    return value
  }

  private getStateParamValueFromSelectedObjects(operationStepExecutionResult: OperationStepExecutionResult, value: string) {
    const selectedObjectsKey = value.substring(this.selectedObjectsPrefix.length, value.length - 1);
    const selectedObjects = operationStepExecutionResult.selected_objects;
    if (selectedObjects) {
      value = selectedObjects[0][selectedObjectsKey];
    } else {
      console.warn('No selected objects returned in operation result!');
    }
    return value;
  }

  private getStateNameFromOperationChangeState(operationChangeState: OperationChangeState) {
    return operationChangeState.state_name === 'current' ? this.primusRouter.currentState() : operationChangeState.state_name;
  }
}
