<ng-container *ngFor="let operationGroup of operationGroups">
  <ng-container *ngIf="!showSubMenusOnly">
    <app-dropdown-menu [icon]="operationGroup.menu_icon"
                       [text]="operationGroup.menu_title"
                       [stylingClass]="'simple-setting-button'">
      <app-dropdown-menu-item
        *ngFor="let operation of operationGroup.sub_operations"
        class="operation-dropdown-menu__menu"
        [icon]="operation.menu_icon"
        (click)="openOperation(operation)"
        [text]="operation.menu_title"></app-dropdown-menu-item>
    </app-dropdown-menu>
  </ng-container>
  <ng-container *ngIf="showSubMenusOnly">
    <app-dropdown-menu-item
      *ngFor="let operation of operationGroup.sub_operations"
      class="operation-dropdown-menu__menu"
      [icon]="operation.menu_icon"
      (click)="openOperation(operation)"
      [text]="operation.menu_title"></app-dropdown-menu-item>
  </ng-container>
</ng-container>
