<div class="search-filter-hierarchic"
     [ngClass]="{'sideMenu': sideMenu, 'dropdownMenu': !sideMenu}">
  <ul>
    <li class="search-filter-hierarchic__title-content">
      <div class="search-filter-hierarchic__title">
        <span class="search-filter-hierarchic__title-text"
              [ngClass]="{'bold': sideMenu}"
              [translate]="searchContainer.filtersFacets.hierarchicFilterGroup.title"></span>
      </div>
    </li>
  </ul>
  <div class="dropdownIgnoreClicks">
    <div class="search-filter-hierarchic__input"
         *ngIf="searchContainer.filtersFacets.hierarchicFilterGroup.childrenArray.length > 10">
      <input type="text"
             [(ngModel)]="query"
             [placeholder]="'TRANS__SEARCH_FILTER_HIERARCHIC__QUERY' | translate"
             class="search-input"
             autocomplete="off">
      <mat-icon class="search-icon">search</mat-icon>
    </div>
    <app-search-filter-hierarchy
      class="search-filter-hierarchic__hierarchy"
      [sideMenu]="sideMenu"
      [nodeQuery]="query"
      [node]="searchContainer.filtersFacets.hierarchicFilterGroup"
      [searchContainer]="searchContainer"
    ></app-search-filter-hierarchy>
  </div>
</div>
