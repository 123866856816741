import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldParameters} from "../../core/definitions/field-parameters";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {MatRadioModule} from "@angular/material/radio";
import {InlineViewService} from "../../core/inline-view.service";
import {FieldValueService} from "../../core/field-value.service";

@Component({
  selector: 'app-edit-field-radio-inline-array',
  standalone: true,
  imports: [CommonModule, MatRadioModule, ReactiveFormsModule],
  templateUrl: './edit-field-radio-inline-array.component.html',
  styleUrl: './edit-field-radio-inline-array.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldRadioInlineArrayComponent)
    }
  ]

})
export class EditFieldRadioInlineArrayComponent implements OnInit, ControlValueAccessor {
  @Input() fieldParameters: FieldParameters;
  @Input() formControlName: string;
  radioFieldName: string;
  radioItems: any[] = [];
  private lastItem = null;

  constructor(private inlineViewService: InlineViewService,
              private fieldValueService: FieldValueService) {
  }

  ngOnInit(): void {
    const uniqueProps = this.fieldParameters.field.inline.unique_props
    if (!uniqueProps) {
      throw new Error('Unique props required for inline radio field');
    }
    this.radioFieldName = uniqueProps[0];
    this.radioItems = this.fieldParameters.object[this.fieldParameters.field.name];
    this.setItemView().then();
  }

  async setItemView() {
    for (const radioItem of this.radioItems) {
      radioItem.$$view = await this.inlineViewService.generateInlineViewString(radioItem);
    }
  }

  setSelected(item: any) {
    if (this.lastItem) {
      this.setRadioItemValue(this.lastItem, false);
    }
    this.setRadioItemValue(item, true)
    this.lastItem = item;
    this.fieldValueService.getControl(this.fieldParameters, this.fieldParameters.field.name).markAsDirty()
  }

  private setRadioItemValue(item: any, value: boolean) {
    item[this.radioFieldName] = value;
  }

  registerOnChange(/*fn: any*/): void {
    // N/A
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }

}
