import {BehaviorSubject, Observable} from 'rxjs';
import {IResultParser} from './iresult-parser';
import {JobStatusItem} from '../definitions/job-status-item';

/**
 * This is an example of a ResultParser class used to parse the result of the poll and emit the results.
 */
export class ResultParser implements IResultParser {
  private messages: BehaviorSubject<Array<JobStatusItem>> = new BehaviorSubject(Array<JobStatusItem>());

  public readonly messages$: Observable<Array<JobStatusItem>> = this.messages.asObservable();

  constructor() {

  }

  parse(data: any) {
    this.messages.next(data.messages);
  }
}
