import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';

@Component({
  selector: 'app-information-view-and-edit',
  templateUrl: './information-view-and-edit.component.html',
  styleUrls: ['./information-view-and-edit.component.scss']
})
export class InformationViewAndEditComponent implements OnInit {

  @Input() isEditing: boolean;
  @Input() sectionsContainer: SectionsContainer;

  constructor() { }

  ngOnInit() {}
}
