<div *ngIf="objectFields" class="search-result-view-list md-table">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <caption></caption>
    <ng-container matColumnDef="artifact_name" sticky>
      <th mat-header-cell
          *matHeaderCellDef
          (click)="setOrder(artifactNameField)"
          class="sorting-enabled"
          [ngClass]="{'active': searchContainer.getOrder() === AConst.ARTIFACT_NAME ||
          searchContainer.getOrder() === (AConst.ARTIFACT_NAME + ' desc')}">
        <span>{{'TRANS__FIELD__NAME' | translate}}</span>
        <i class="icon-arrow-up2 sortAsc"
           [ngClass]="{'increasing': searchContainer.getOrder().indexOf('desc') !== -1}"></i>
        <i class="icon-arrow-down2 sortDesc"
           [ngClass]="{'decreasing': searchContainer.getOrder().indexOf('desc') === -1}"></i>
      </th>
      <td mat-cell *matCellDef="let element; index as $index" class="locked-column">
        <div class="search-result-view-list__first-col">
          <div class="md-table__checkbox"
               [ngClass]="{'disabled': element.used}">
            <mat-checkbox
              class="white-bg-in-checkbox"
              (click)="select(element, $event)"
              [checked]="searchContainer.selections.selected[element[AConst.ARTIFACT_ID]]"
              [disabled]="element.used">
            </mat-checkbox>
          </div>
          <button mat-icon-button
                  [style.visibility]="!isExpandable(element) ? 'hidden' : ''"
                  [class.hideToggle]="!isExpandable(element) && !element['isLoading']"
                  [style.margin-left.px]="element.level > 1 ? (element.level * 10) : 0"
                  (click)="treeControl.toggle(element)">
            <mat-icon class="mat-icon-rtl-mirror" *ngIf="!element['isLoading']">
              {{treeControl.isExpanded(element) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            <mat-spinner color="accent" diameter="16" *ngIf="element['isLoading']"></mat-spinner>
          </button>
          <div class="md-table__name bold">
            <a [routerLink]="element['refData']['routerLink']"
               [queryParams]="element['refData']['queryParams']"
               queryParamsHandling="merge"
               (click)="setSearchItemIndex(element['refData'].param['searchItemIndex'])"
               class="table-first-column-list-name">
              <span class="object-url-content">
                <span class="list-icon-container">
                  <i class="searchIcon object-icon {{element.icon}}"
                     [class.text-blue]="element['meta_type'] === MetaTypes.ADM_EVENT ||
                     element['meta_type'] === 'folder'"></i>
                </span>
                <span class="text-link" [innerHTML]="element.name"></span>
              </span>
            </a>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container *ngFor="let col of searchContainer.currentPathView.search_view.overview_fields"
                  [matColumnDef]="col.$$fieldColumnName">
      <th mat-header-cell
          *matHeaderCellDef
          (click)="setOrder(col)"
          class="sorting-enabled"
          [ngClass]="{'active': searchContainer.getOrder() === col.field_name ||
           searchContainer.getOrder() === (col.field_name + ' desc')}">
        <span>{{col.$$fieldTitle}}</span>
        <i class="icon-arrow-up2 sortAsc"
           [ngClass]="{'increasing': searchContainer.getOrder().indexOf('desc') !== -1}"></i>
        <i class="icon-arrow-down2 sortDesc"
           [ngClass]="{'decreasing': searchContainer.getOrder().indexOf('desc') === -1}"></i>
      </th>
      <td mat-cell *matCellDef="let element">
        <div *ngFor="let field of getObjectFields(element)"
             title="{{field.title}}">
          <app-overview-field-value
            *ngIf="col.$$fieldColumnName === field.$$fieldColumnName"
            [object]="element.item"
            [viewName]="searchContainer.searchResultViewName"
            [overviewField]="field"
            [metaField]="col"
          ></app-overview-field-value>
        </div>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="'setting'" stickyEnd>
      <th mat-header-cell
          *matHeaderCellDef
          class="search-result-view-list__setting">
        <app-operation-dropdown-menu-for-search-container
          [searchContainer]="searchContainer"
          [operationGroupId]="'list_search_view_dropdown'"
          (operationPerformed)="onOperationPerformed($event)"
        ></app-operation-dropdown-menu-for-search-container>
      </th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedCol; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedCol;"></tr>
  </table>
</div>

