import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchObject} from '../../../../core/definitions/search-object';
import {UserCollectionItemsBase} from '../../../../core/definitions/user-collection-items-base';
import {AdminUsersService} from '../../admin-users.service';

@Component({
  selector: 'app-user-collection-rights-type-setter',
  templateUrl: './user-collection-rights-type-setter.component.html',
  styleUrls: ['./user-collection-rights-type-setter.component.scss']
})
export class UserCollectionRightsTypeSetterComponent implements OnInit {
  @Input() userCollection: UserCollectionItemsBase;
  @Input() disabled: boolean;
  @Input() collectionType: string;
  @Output() rightsSet = new EventEmitter<string>();
  collectionRightTypes: SearchObject[] = [];


  constructor(private adminUsersService: AdminUsersService) { }

  ngOnInit(): void {
    this.loadCollectionRightTypes().then();
  }

  onChangedCollectionRights(collection: UserCollectionItemsBase) {
    collection.collection_rights_id_value = this.collectionRightTypes.find(
      colLightsType => colLightsType.artifact_id === collection.collection_rights_id).artifact_name;
    this.rightsSet.emit(collection.collection_rights_id);
  }

  private async loadCollectionRightTypes() {
    this.collectionRightTypes = await this.adminUsersService.getCollectionRightTypes(this.collectionType);
  }
}
