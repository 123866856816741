<span class="action-toolbar">
  <button class="admin-stroked-button"
          type="button"
          mat-stroked-button
          color="primary"
          (click)="addConcept()">
     <mat-icon>add</mat-icon>
    <span class="bold uppercase admin-stroked-button__left-margin"
          translate="TRANS__ADMIN__ADD_NEW"></span>
  </button>
  <ng-container *ngIf="conceptsContainer.conceptType.search_kulturnav">
  <button class="admin-stroked-button"
          type="button"
          mat-stroked-button
          color="primary"
          (click)="getConceptsFromCultureHub()">
    <mat-icon>get_app</mat-icon>
    <span class="bold uppercase"
          translate="TRANS__FIELD_SELECT__GET_FROM_AUTHORITY"></span></button>
  </ng-container>
</span>
