import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObjectNewPageComponent} from './object-new-page/object-new-page.component';
import {SharedModule} from '../shared/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {ObjectNewObjectViewComponent} from './object-new-object-view/object-new-object-view.component';
import {ObjectNewCopyViewComponent} from './object-new-copy-view/object-new-copy-view.component';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {OperationsModule} from '../operations/operations.module';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';



@NgModule({
  declarations: [
    ObjectNewPageComponent,
    ObjectNewObjectViewComponent,
    ObjectNewCopyViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    FormsModule,
    MediaSelectorModule,
    ObjectSearchModule,
    OperationsModule,
    ObjectEditModule,
    MatButtonModule,
    MatInputModule
  ]
})
export class CreateObjectModule { }
