<div class="media-selector-image-vertical">
  <div class="media-selector-image-vertical__image-drop-zone"
       (drop)="onDropFile($event)"
       (dragover)="onDragOverFile($event)">
    <app-media-selector-selected-files
      class="full-width"
      [files]="images"
      [showSelected]="false"
      [text]="'TRANS__IMAGE_SELECTOR__DRAG_AND_DROP_FILE_HERE'"
      [imageIdField]="'image_id'"></app-media-selector-selected-files>
    <div class="media-selector-image-vertical__cameraOpen" *ngIf="cameraOpen">
      <div >
        <app-media-upload-camera
          [verticalView]="true"
          (uploadCameraImages)="uploadFiles($event)"> </app-media-upload-camera>
      </div>
    </div>
  </div>
  <div class="media-selector-image-vertical__button">
    <button mat-stroked-button
            color="primary">
      <label for="upload_file">
        <i class="icon-upload"></i>
        <span *ngIf="computer && multipleImages"
              class="bold uppercase"
              translate="TRANS__IMAGE_SELECTOR__UPLOAD_IMAGES"></span>
        <span *ngIf="!computer && multipleImages"
              class="bold uppercase"
              translate="TRANS__IMAGE_SELECTOR__UPLOAD_IMAGE_AND_CAMERA"></span>
        <span *ngIf="computer && !multipleImages"
              class="bold uppercase"
              translate="TRANS__IMAGE_SELECTOR__CHANGE_IMAGE"></span>
        <span *ngIf="!computer && !multipleImages"
              class="bold uppercase"
              translate="TRANS__IMAGE_SELECTOR__CHANGE_IMAGE_AND_CAMERA"></span>
      </label>
    </button>
    <input type="file"
           id="upload_file"
           (change)="onFileSelect($event)"
           [attr.multiple]="multipleImages ? 'multiple' : undefined"
           accept="image/*">
  </div>
  <div class="media-selector-image-vertical__button">
    <button mat-stroked-button
            color="primary"
            *ngIf="computer"
            (click)="openCamera()"
            [ngClass]="{'active': cameraOpen}" >
      <i class="icon-camera"></i>
      <span class="bold uppercase"
            translate="TRANS__ARTIFACT_NEW__NEW_IMAGE_CAMERA"></span>
    </button>
  </div>
</div>
