export class FieldValidation {
  min_length: number;
  max_length: number;
  min_number: number;
  max_number: number;
  compare: Compare;
  username: string;
  reg_exp: string;
}

export class Compare {
  field: string;
  comparator: string;
}

export enum ValidationType {
  MAX_LENGTH= 'max_length',
  MIN_LENGTH= 'min_length',
  MIN_NUMBER = 'min_number',
  MAX_NUMBER = 'max_number',
  COMPARE = 'compare',
  REG_EXP = 'reg_exp'
}
