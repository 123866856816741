function ___$insertStyle(css) {
  if (!css || typeof window === 'undefined') {
    return;
  }
  const style = document.createElement('style');
  style.setAttribute('type', 'text/css');
  style.innerHTML = css;
  document.head.appendChild(style);
  return css;
}
var getState = function getState() {
  return sessionStorage.getItem('state');
};
var setState = function setState(state) {
  return sessionStorage.setItem('state', state);
};
var getPreviousPath = function getPreviousPath() {
  return sessionStorage.getItem('previousPath');
};
var setPreviousPath = function setPreviousPath(previousPath) {
  return sessionStorage.setItem('previousPath', previousPath);
};
var getRedirectUri = function getRedirectUri() {
  var url = "".concat(window.location.protocol, "//").concat(window.location.hostname);
  if (window.location.port) {
    url = "".concat(url, ":").concat(window.location.port);
  }
  return "".concat(url, "/oauth2/callback");
};
var cleanStorage = function cleanStorage() {
  sessionStorage.removeItem('state');
  sessionStorage.removeItem('previousPath');
};
var setFutureRefresh = function setFutureRefresh(id) {
  return localStorage.setItem('kit.future_refresh', id.toString());
};
var getFutureRefresh = function getFutureRefresh() {
  return parseInt(localStorage.getItem('kit.future_refresh'));
};
var removeFutureRefresh = function removeFutureRefresh() {
  return localStorage.removeItem('kit.future_refresh');
};
var setAccessToken = function setAccessToken(accessToken) {
  return localStorage.setItem('kit.access_token', accessToken);
};
var removeAccessToken = function removeAccessToken() {
  return localStorage.removeItem('kit.access_token');
};
var setIdToken = function setIdToken(idToken) {
  return localStorage.setItem('kit.id_token', idToken);
};
var removeIdToken = function removeIdToken() {
  return localStorage.removeItem('kit.id_token');
};
var login = function login(_ref) {
  var _ref$locationPostLogi = _ref.locationPostLogin,
    locationPostLogin = _ref$locationPostLogi === void 0 ? null : _ref$locationPostLogi,
    _ref$redirectUri = _ref.redirectUri,
    redirectUri = _ref$redirectUri === void 0 ? null : _ref$redirectUri,
    _ref$loginHost = _ref.loginHost,
    loginHost = _ref$loginHost === void 0 ? 'https://beta-login.ekultur.org' : _ref$loginHost;
  var state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  var callbackUrl = redirectUri ? redirectUri : getRedirectUri();
  setPreviousPath(filterLocation(locationPostLogin));
  setState(state);
  var loginUrl = "".concat(loginHost, "/oauth2/authorize?redirect_uri=").concat(callbackUrl, "&state=").concat(state);
  window.location = loginUrl;
  return null;
};
var filterLocation = function filterLocation(locationPostLogin) {
  return locationPostLogin ? filterLocationBlacklist(locationPostLogin) : '/';
};
var filterLocationBlacklist = function filterLocationBlacklist(prevLocation) {
  switch (prevLocation) {
    case '/login':
      return '/';
    case '/logout':
      return '/';
    case '/oauth2/callback':
      return '/';
    default:
      return prevLocation;
  }
};
var logout = function logout(_ref) {
  var _ref$signal = _ref.signal,
    signal = _ref$signal === void 0 ? null : _ref$signal,
    _ref$onLogout = _ref.onLogout,
    onLogout = _ref$onLogout === void 0 ? null : _ref$onLogout,
    _ref$apiGateway = _ref.apiGateway,
    apiGateway = _ref$apiGateway === void 0 ? null : _ref$apiGateway;
  var abortController = new AbortController();
  var options = {
    method: 'POST',
    credentials: 'include'
  };
  if (signal) {
    options.signal = abortController.signal;
  }
  var gatewayHost = apiGateway || getApiGateway();
  fetch("".concat(gatewayHost, "/auths/oauth2/logout"), options).then(function () {
    cleanStorage();
    removeAccessToken();
    removeIdToken();
    clearTimeout(getFutureRefresh());
    removeFutureRefresh();
    if (onLogout) {
      onLogout();
    }
  });
};
var getApiGateway = function getApiGateway() {
  if (window._env_ && window._env_.REACT_APP_API_GATEWAY) {
    return window._env_.REACT_APP_API_GATEWAY;
  }
  return process.env.REACT_APP_API_GATEWAY || 'https://beta-apigateway.dimu.org';
};

// Unique ID creation requires a high quality random # generator. In the browser we therefore
// require the crypto API and do not support built-in fallback to lower quality random number
// generators (like Math.random()).
var getRandomValues;
var rnds8 = new Uint8Array(16);
function rng() {
  // lazy load so that environments that need to polyfill have a chance to do so
  if (!getRandomValues) {
    // getRandomValues needs to be invoked in a context where "this" is a Crypto implementation.
    getRandomValues = typeof crypto !== 'undefined' && crypto.getRandomValues && crypto.getRandomValues.bind(crypto);
    if (!getRandomValues) {
      throw new Error('crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported');
    }
  }
  return getRandomValues(rnds8);
}

/**
 * Convert array of 16 byte values to UUID string format of the form:
 * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
 */

var byteToHex = [];
for (var i = 0; i < 256; ++i) {
  byteToHex.push((i + 0x100).toString(16).slice(1));
}
function unsafeStringify(arr) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  // Note: Be careful editing this code!  It's been tuned for performance
  // and works in ways you may not expect. See https://github.com/uuidjs/uuid/pull/434
  return (byteToHex[arr[offset + 0]] + byteToHex[arr[offset + 1]] + byteToHex[arr[offset + 2]] + byteToHex[arr[offset + 3]] + '-' + byteToHex[arr[offset + 4]] + byteToHex[arr[offset + 5]] + '-' + byteToHex[arr[offset + 6]] + byteToHex[arr[offset + 7]] + '-' + byteToHex[arr[offset + 8]] + byteToHex[arr[offset + 9]] + '-' + byteToHex[arr[offset + 10]] + byteToHex[arr[offset + 11]] + byteToHex[arr[offset + 12]] + byteToHex[arr[offset + 13]] + byteToHex[arr[offset + 14]] + byteToHex[arr[offset + 15]]).toLowerCase();
}
var randomUUID = typeof crypto !== 'undefined' && crypto.randomUUID && crypto.randomUUID.bind(crypto);
var _native = {
  randomUUID: randomUUID
};
function v4(options, buf, offset) {
  if (_native.randomUUID && !buf && !options) {
    return _native.randomUUID();
  }
  options = options || {};
  var rnds = options.random || (options.rng || rng)(); // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`

  rnds[6] = rnds[6] & 0x0f | 0x40;
  rnds[8] = rnds[8] & 0x3f | 0x80; // Copy bytes to buffer, if provided

  if (buf) {
    offset = offset || 0;
    for (var i = 0; i < 16; ++i) {
      buf[offset + i] = rnds[i];
    }
    return buf;
  }
  return unsafeStringify(rnds);
}
var codeToToken = function codeToToken(_ref) {
  var code = _ref.code,
    redirectUri = _ref.redirectUri,
    state = _ref.state,
    onSuccess = _ref.onSuccess,
    onError = _ref.onError,
    _ref$apiGateway = _ref.apiGateway,
    apiGateway = _ref$apiGateway === void 0 ? 'https://beta-apigateway.dimu.org' : _ref$apiGateway,
    _ref$sameSite = _ref.sameSite,
    sameSite = _ref$sameSite === void 0 ? false : _ref$sameSite;
  var storedState = getState();
  if (state !== storedState) {
    if (onError) {
      onError('State mismatch.');
    } else {
      console.error('State mismatch.');
    }
    return;
  }
  var callbackUrl = redirectUri ? redirectUri : getRedirectUri();
  var data = new URLSearchParams();
  data.append('code', code);
  data.append('grant_type', 'authorization_code');
  data.append('redirect_uri', callbackUrl);
  if (sameSite) {
    data.append('same_site', sameSite.toString());
  }
  fetch("".concat(apiGateway, "/auths/oauth2/token"), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Correlation-Id': v4()
    },
    credentials: 'include',
    body: data
  }).then(function (response) {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    }
  }).then(function (data) {
    var previousPath = getPreviousPath();
    logUserIn({
      apiGateway: apiGateway,
      accessToken: data.access_token,
      expiresIn: data.expires_in,
      idToken: data.id_token
    });
    if (onSuccess) {
      onSuccess({
        previousPath: previousPath
      });
    }
    cleanStorage();
  })["catch"](function (error) {
    if (onError) {
      onError(error);
    } else {
      console.error(error);
    }
  });
};
var hasRefreshToken = function hasRefreshToken(_ref2) {
  var apiGateway = _ref2.apiGateway,
    _ref2$onSuccess = _ref2.onSuccess,
    onSuccess = _ref2$onSuccess === void 0 ? null : _ref2$onSuccess,
    _ref2$onError = _ref2.onError,
    onError = _ref2$onError === void 0 ? null : _ref2$onError;
  fetch("".concat(apiGateway, "/auths/oauth2/has-refresh-token"), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Correlation-Id': v4()
    },
    credentials: 'include'
  }).then(function (response) {
    if (onSuccess) {
      onSuccess(response.ok);
    }
  })["catch"](function (error) {
    if (onError) {
      onError(error);
    }
  });
};
var refreshToken = function refreshToken(_ref3) {
  var apiGateway = _ref3.apiGateway,
    _ref3$onSuccess = _ref3.onSuccess,
    onSuccess = _ref3$onSuccess === void 0 ? null : _ref3$onSuccess,
    _ref3$onError = _ref3.onError,
    onError = _ref3$onError === void 0 ? null : _ref3$onError;
  var data = new URLSearchParams();
  data.append('grant_type', 'refresh_token');
  data.append('redirect_uri', getRedirectUri());
  fetch("".concat(apiGateway, "/auths/oauth2/token"), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Correlation-Id': v4()
    },
    credentials: 'include',
    body: data
  }).then(function (response) {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(response.statusText);
    }
  }).then(function (data) {
    logUserIn({
      apiGateway: apiGateway,
      accessToken: data.access_token,
      expiresIn: data.expires_in,
      idToken: data.id_token
    });
    if (onSuccess) {
      onSuccess();
    }
  })["catch"](function (error) {
    removeAccessToken();
    removeIdToken();
    clearTimeout(getFutureRefresh());
    removeFutureRefresh();
    if (onError) {
      onError(error);
    } else {
      console.error(error);
    }
  });
};
var logUserIn = function logUserIn(_ref4) {
  var apiGateway = _ref4.apiGateway,
    accessToken = _ref4.accessToken,
    expiresIn = _ref4.expiresIn,
    _ref4$idToken = _ref4.idToken,
    idToken = _ref4$idToken === void 0 ? null : _ref4$idToken;
  setAccessToken(accessToken);
  if (idToken) {
    setIdToken(idToken);
  }
  var futureRefresh = setTimeout(function () {
    refreshToken({
      apiGateway: apiGateway
    });
  }, expiresIn * 1000 - 60000);
  setFutureRefresh(futureRefresh);
};
export { cleanStorage, codeToToken, getFutureRefresh, getPreviousPath, getRedirectUri, getState, hasRefreshToken, login, logout, refreshToken, removeAccessToken, removeFutureRefresh, removeIdToken, setAccessToken, setFutureRefresh, setIdToken, setPreviousPath, setState };
