import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss']
})
export class ContentSectionComponent {

  @Input() public headerPrimaryText: string;
  @Input() public headerSecondaryText: string;
  @Input() public headerHintText: string;

  constructor() { }
}
