<div class="procedure-content-list object-procedure-content-list-source"
     [ngClass]="{'smallScreen': smallScreen}">
  <div class="procedure-content-list__title"
       *ngIf="operationContainer.contentInfo.curListName !== 'spectrum_procedure_object'">
    <a *ngIf="smallScreen"
       (click)="onToggleMenu()"
       class="procedure-content-list__toggle-button">
      <i class="icon-hamburger"></i>
    </a>
    <h3 class="bold" *ngIf="operationContainer.contentInfo.selectedMainMenu">
      <span>{{operationContainer.contentInfo.curContentListSource?.array.length}} &nbsp;</span>
      <span [translate]="operationContainer.contentInfo.curContentListSource?.caption"></span>
    </h3>
  </div>

  <div *ngIf="operationContainer.contentInfo.curListName !== 'spectrum_procedure_object'">

    <ng-container *ngIf="!parentOperation?.sub_operations">
      <button mat-stroked-button
              type="button"
              class="add-button"
              color="primary"
              (click)="openModal()"
              [disabled]="isEditing || operationContainer.rootSectionsContainer.rootObject.read_only">
        <mat-icon>add</mat-icon>
        <span class="bold uppercase" translate="TRANS__HEADER__OBJECT_MENU__ADD"></span>
      </button>
    </ng-container>
    <ng-container *ngIf="parentOperation?.sub_operations">
      <app-operation-menus-two-levels
        *ngIf="parentOperation?.sub_operations?.length > 0"
        class="procedure-content-list__operation-buttons"
        [operationContainer]="operationContainer"
        [parentOperation]="parentOperation"
      ></app-operation-menus-two-levels>
    </ng-container>
    <button mat-stroked-button
            color="primary"
            class="procedure-content-list__change-image-order-button"
            *ngIf="operationContainer.contentInfo.curListName === 'images' && !smallScreen"
            [disabled]="operationContainer.art?.$$mediaContainer?.mediaTypes.length === 0"
            (click)="onToggleChangeImageOrder()">
      <mat-icon>unfold_more</mat-icon>
      <span class="bold uppercase" translate="TRANS__THUMBNAIL_STACK__CHANGE_IMAGE_ORDER"></span>
    </button>
  </div>

  <div class="procedure-content-list__content-list">
    <app-object-content-list-source-array
      *ngIf="operationContainer.contentInfo.curContentListSource"
      [contentListSource]="operationContainer.contentInfo.curContentListSource"
      (objectRefresh)="loadProcedure()"
      (newObjectCreated)="onNewObjectCreated($event)"
    ></app-object-content-list-source-array>
  </div>

  <div *ngIf="!operationContainer.contentInfo.curListContainer && !operationContainer.contentInfo.curContentListSource">
    <span translate="TRANS__CONTENT_LIST__NO_CONTENT"></span></div>
</div>

