import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-user-deleted-dialog',
  templateUrl: './user-deleted-dialog.component.html',
  styleUrls: ['./user-deleted-dialog.component.scss']
})
export class UserDeletedDialogComponent {

  constructor(private readonly dialogRef: MatDialogRef<UserDeletedDialogComponent>) { }

  close(doRestoreUser: boolean = false): void {
    this.dialogRef.close(!!doRestoreUser);
  }

}
