import {Injectable} from '@angular/core';
import {searchSettingsParams, searchStateParamMapper} from './search-state-params';
import {SearchTemplateGroupService} from './search-template-group.service';
import {SearchContainer} from '../core/definitions/search-container';
import {CommonsService} from '../core/commons.service';
import {PrimusRouterService} from '../core/primus-router.service';

@Injectable({
  providedIn: 'root'
})
export class SearchStateService {

  constructor(private primusRouter: PrimusRouterService,
              private commons: CommonsService,
              private searchTemplateGroupService: SearchTemplateGroupService) {
  }

  async setState(searchContainer: SearchContainer) {
    const stateParams = {};
    const targetState = searchContainer.state.targetState;
    const toState = this.primusRouter.currentState();
    if (toState && toState.indexOf(targetState) === 0) {
      for (const [stateParamName, scParamName] of Object.entries(searchStateParamMapper)) {
        this.setStateParam(searchContainer, stateParams, scParamName, stateParamName);
      }
      if (targetState === toState) {
        await this.primusRouter.navigateState(searchContainer.state.targetState, stateParams);
      }
    }
  }

  setSearchContainerPropsFromStateParams(searchContainer: SearchContainer, stateParams: Object) {
    const paramMapper = searchStateParamMapper;
    let containerParamsChanged = false;
    for (const [stateParamKey, stateParamVal] of Object.entries(stateParams)) {
      let conParamKey, conParamVal;
      if (stateParamVal) {
        conParamKey = paramMapper[stateParamKey];
        if (conParamKey) {
          const value = this.getStateParamVal(stateParamKey, stateParamVal);
          conParamVal = this.commons.getObjectValueFromPath(searchContainer, conParamKey);
          if (this.stateParamDiffersFromSc(value, conParamVal)) {
            containerParamsChanged = true;
            this.setScValFromStateParam(searchContainer, conParamKey, value);
          }
        }
      }
    }
    return containerParamsChanged;
  }

  setScValFromStateParam(searchContainer: SearchContainer, paramKey, stateParamVal) {
    if (this.isObject(stateParamVal)) {
      const objectTarget = this.commons.getObjectValueFromPath(searchContainer, paramKey);
      for (const [key, value] of Object.entries(stateParamVal)) {
        objectTarget[key] = value;
      }
    } else {
      this.commons.setObjectValueFromPath(searchContainer, paramKey, stateParamVal);
      if (paramKey === 'templateGroupId') {
        this.searchTemplateGroupService.setTemplateGroup(searchContainer, stateParamVal);
      }
      if (paramKey === 'listFieldTemplateId') {
        this.searchTemplateGroupService.setListFieldTemplateGroup(searchContainer, stateParamVal).then();
      }
    }
  }

  getDecodedStateParameter(origValue) {
    let res = origValue;
    if (origValue) {
      let val;
      do {
        val = res;
        res = decodeURIComponent(val);
      } while (res.length !== val.length);
    }
    return res;
  }

  private stateParamDiffersFromSc(stateParamVal: {[name: string]: any[]}, scVal) {
    let res = false;
    if (this.isObject(stateParamVal)) {
      for (const [key, values] of Object.entries(stateParamVal)) {
        const scValues = scVal[key];
        if (scValues && scValues.length === values.length) {
          for (const val of values) {
            if (scValues.indexOf(val.toString()) === -1) {
              res = true;
              break;
            }
          }
        } else {
          res = true;
        }
      }
    } else {
      res = stateParamVal !== scVal;
    }
    return res;
  }


  private getStateParamVal(stateParamKey, stateParamVal) {
    if (!Array.isArray(stateParamVal)) {
      stateParamVal = this.getDecodedStateParameter(stateParamVal);
    }
    let res = stateParamVal;
    const isArrayParam = ['selected'].indexOf(stateParamKey) !== -1;
    const isObjectParam = ['filter'].indexOf(stateParamKey) !== -1;
    if (stateParamVal === 'false') {
      res = false;
    } else if (stateParamVal === 'true') {
      res = true;
    } else if (!isNaN(stateParamVal) && searchSettingsParams[stateParamKey].type === 'int') {
      res = parseInt(stateParamVal, 10);
    } else if (isArrayParam) {
      if (typeof stateParamVal === 'string') {
        res = stateParamVal.split(',');
      }
    } else if (isObjectParam) {
      if (!Array.isArray(stateParamVal)) {
        stateParamVal = [stateParamVal];
      }
      res = {};
      stateParamVal.forEach((val) => {
        val = this.getDecodedStateParameter(val);
        const split = this.splitFilter(val);
        if (!res[split[0]]) {
          res[split[0]] = [];
        }
        res[split[0]].push(this.convertStateParamVal(split[1]));
      });
    }
    return res;
  }

  private setStateParam(searchContainer: SearchContainer, stateParams, scParamName, stateParamName) {
    const scParamVal = this.commons.getObjectValueFromPath(searchContainer, scParamName);
    if (this.isObject(scParamVal)) {
      const paramObj = <{[name: string]: any[]}>scParamVal;
      stateParams[stateParamName] = [];
      for (const [key, values] of Object.entries(paramObj)) {
        if (values && values.length > 0) {
          for (const value of values) {
            const param = key + ':' + value;
            stateParams[stateParamName].push(param);
          }
        }
      }
    } else {
      stateParams[stateParamName] = scParamVal || null;
    }
  }

  private isObject(param) {
    return param !== null && typeof param === 'object' && !Array.isArray(param);
  }

  private convertStateParamVal(valIn) {
    let res = valIn;
    if (valIn === 'true') {
      res = true;
    } else if (valIn === 'false') {
      res = false;
    }
    return res;
  }

  private splitFilter(val) {
    let splitIndex;
    const split = [];
    if (val.indexOf('[') !== -1) {
      // is probably range filter
      splitIndex = val.indexOf('[') - 1;
    } else {
      splitIndex = val.indexOf(':');
    }
    if (splitIndex !== -1) {
      split[0] = val.substring(0, splitIndex);
      split[1] = val.substring(splitIndex + 1);
    } else {
      split[0] = val;
    }
    return split;

  }


}
