import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {TranslateService} from '@ngx-translate/core';
import {ObjectFieldTraverseService, TraverseObjectResult} from './object-field-traverse.service';
import {MetaField} from './definitions/meta-field';
import {SuperObjectModel} from './definitions/super-object-model';
import {UserData} from './definitions/user-data';
import {BaseModel} from './definitions/base-model';
import {FieldInputType} from './definitions/field-input-type.enum';
import {FieldType} from './definitions/field-type.enum';
import {LoggerService} from "./logger.service";
import {SearchReferenceService} from "./search-reference.service";
import {FieldDateInfoService} from "./field-date-info.service";
import {System} from "./definitions/system";

@Injectable({
  providedIn: 'root'
})
export class FieldStateService {

  constructor(private logger: LoggerService,
              private objectFieldTraverse: ObjectFieldTraverseService,
              private translate: TranslateService,
              private searchReferenceService: SearchReferenceService,
              private fieldDateInfoService: FieldDateInfoService) {
  }

  isEditable(rootObject: SuperObjectModel, object: BaseModel, field: MetaField, userData: UserData): boolean {
    let res = false;
    let createdById = rootObject.created_by_id;
    if (!createdById && object) {
      createdById = object.created_by_id;
    }
    const userId = userData.artifact_id;

    const edit = field.edit;
    switch (edit) {
      case undefined:
      case null:
      case 'no_edit':
        break;
      case AConst.EDIT:
        res = true;
        break;
      case AConst.EDIT_OWNER:
        res = !createdById || createdById === userId;
        break;
      case AConst.EDIT_ONCE:
        const value = object[field.name];
        res = !value || !createdById || object.$$justAdded;
        break;
      case AConst.EDIT_ONCE_NOT_STORED:
        res = !object[field.name] && !object.artifact_id;
        break;
      default:
        this.logger.warn('Unable to handle edit type ' + edit);

    }
    return res;
  }

  getFieldInputType(field: MetaField, rootObject: SuperObjectModel, object: BaseModel, userData: UserData): string {
    let res: string;
    const editable = this.isEditable(rootObject, object, field, userData);
    if (editable) {
      switch (field.input_type) {
        case FieldInputType.MAP_ID:
        case FieldInputType.REF_ARRAY:
          res = FieldInputType.MAP_ID;
          const reference = this.searchReferenceService.getSearchReferenceFromField(field);
          if (reference.is_hierarchic) {
            res = FieldInputType.HIERARCHIC_SELECT;
          }
          break;
        case FieldInputType.TEXT_AREA:
          res = FieldInputType.TEXT_AREA;
          break;
        case 'select':
          res = 'select';
          break;
        case FieldInputType.INPUT:
          res = 'text';
          break;
        case FieldInputType.DATE_TIME_ISO:
        case FieldInputType.DATE_ISO:
          const dateInfo = this.fieldDateInfoService.getFieldDateInfo(field);
          if (dateInfo?.to_date_field) {
            res = FieldInputType.DATE_TIME_ISO_RANGE;
          } else if (dateInfo?.is_to_date_field) {
            res = null;
          } else {
            res = field.input_type;
          }
          break;
        case FieldInputType.IDENTIFIER:
          res = FieldInputType.IDENTIFIER;
          break;
        case FieldInputType.NUMBER:
          res = FieldInputType.NUMBER;
          break;
        case FieldInputType.CHECKBOX:
          res = FieldInputType.CHECKBOX;
          break;
        case FieldInputType.CHECK_ARRAY:
          res = FieldInputType.CHECK_ARRAY;
          break;
        case FieldInputType.RADIO_INLINE_ARRAY:
          res = FieldInputType.RADIO_INLINE_ARRAY;
          break;
        case FieldInputType.RADIO_OPTION:
          res = FieldInputType.RADIO_OPTION;
          break;
        case FieldInputType.SEARCH_SELECTOR:
          res = FieldInputType.SEARCH_SELECTOR;
          break;
        case FieldInputType.SEARCH_SELECTOR_MULTIPLE:
          res = FieldInputType.SEARCH_SELECTOR_MULTIPLE;
          break;
        case FieldInputType.PASSWORD:
          res = FieldInputType.PASSWORD;
          break;
        case FieldInputType.IMAGE:
          res = FieldInputType.IMAGE;
          break;
        case FieldInputType.META_OPERATION_FIELD:
          res = FieldInputType.META_OPERATION_FIELD;
          break;
        default:
          res = 'text';
      }
    } else {
      if (field.field_type === FieldType.OBJECT_USAGE) {
        res = FieldInputType.OBJECT_USAGE;
      } else {
        res = 'display';
      }
    }
    return res;
  }

  getFieldTitle(field: MetaField, rootObject: SuperObjectModel, translate: boolean, getPlaceholder: boolean): string {
    const traverseRes = this.objectFieldTraverse.traverseObjectByPath(rootObject, field.path, 1);
    let title = this.getTitleFromSubObject(traverseRes, field, getPlaceholder);
    title = title || field.title;
    title = Array.isArray(title) ? title[0] : title;
    if (title) {
      title = translate ? this.translate.instant(title) : title;
    }
    return title;
  }

  getSubObject(rootObject: BaseModel, field: MetaField, index?: number, parentIndex?: number) {
    let fieldPath: string;
    if (index !== undefined) {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, index, parentIndex);
    } else {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, parentIndex);
    }
    const traverseRes = this.objectFieldTraverse.traverseObjectByPath(rootObject, fieldPath, 1);
    return traverseRes.subObject
  }

  getParentSubObject(rootObject: BaseModel, field: MetaField, index: number, parentIndex: number): TraverseObjectResult {
    let fieldPath: string;
    if (index !== undefined) {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, index, parentIndex);
    } else {
      fieldPath = this.getFieldKeyWhileDrawingInputs(field, parentIndex);
    }
    return this.objectFieldTraverse.traverseObjectByPath(rootObject, fieldPath, 1);
  }

  // This is method must be used for getting field key during generation of field inputs as the indexes
  // are generated differently than getting field keys when generating form controls
  getFieldKeyWhileDrawingInputs(metaField: MetaField, index?: number, parentIndex?: number) {
    let fieldKey: string
    if (metaField) {
      if (metaField.key) {
        if (index === undefined) {
          fieldKey = metaField.key;
        } else if (parentIndex === undefined) {
          fieldKey = this.getFieldKey(metaField, index);
        } else {
          fieldKey = this.getFieldKey(metaField, parentIndex, index);
        }
      } else {
        fieldKey = metaField.name;
        if (metaField.path) {
          fieldKey = `${metaField.path}${System.keySep}${metaField.name}`;
        }
      }
    } else {
      this.logger.warn('Field is missing!');
    }
    return fieldKey;
  }

  // Get a field key in which inline array index names are replaced with the actual indexes
  getFieldKey(metaField: MetaField, index1?: number, index2?: number, fieldName?: string) {
    let fieldKey = metaField.key;
    if (!fieldKey) {
      this.logger.warn(`Key not generated for field ${metaField.name || fieldName}`);
      return null;
    }
    if (index1 !== undefined && index1 !== null) {
      fieldKey = fieldKey.replace('{index1}', index1.toString());
      if (index2 !== undefined && index2 !== null) {
        fieldKey = fieldKey.replace('{index2}', index2.toString());
      }
    } else if (index2 !== undefined && index2 !== null) {
      fieldKey = fieldKey.replace('{index1}', index2.toString());
    }
    if (fieldKey.indexOf('{') !== -1) {
      this.logger.warn('Key not properly generated: ' + fieldKey + ' index1: ' + index1 + ' index2: ' +
        index2 + ' key ' + metaField.key);
    }
    if (fieldName) {
      const parentKey = this.getKeysParentKey(fieldKey);
      if (parentKey) {
        fieldKey = `${parentKey}${System.keySep}${fieldName}`;
      } else {
        fieldKey = fieldName;
      }
    }
    return fieldKey;
  }

  // Recursively generate field key based on parent field keys
  generateFieldKey(metaField: MetaField, parentKey: string): string {
    let res = metaField.key;
    if (!res) {
      res = metaField.name;
      const parentName = metaField.parent_name;
      if (parentName && (!parentKey || !this.parentNameExists(parentKey, parentName))) {
        res = `${metaField.parent_name}${System.keySep}${res}`;
      }
      if (parentKey) {
        res = `${parentKey}${System.keySep}${res}`;
      }
      metaField.key = res;
    }
    return res;
  }

  getKeysParentKey(key: string) {
    const lastSep = key.lastIndexOf(System.keySep);
    let res = '';
    if (lastSep !== -1) {
      res = key.substring(0, lastSep);
    }
    return res;
  }

  /**
   * Checks whether a field is an "edit once" field and if so, returns "true" if it has a value.
   * @param field
   * @param rootObject
   * @param object
   */
  checkEditOnce(field: MetaField, rootObject: BaseModel, object: BaseModel) {
    let res = false;
    let createdById = rootObject.created_by_id;
    if (field.edit === AConst.EDIT_ONCE) {
      res = object[field.name] !== null && object[field.name] !== undefined && createdById !== null;
    }
    return res;
  }

  getInlineFieldName(metaField: MetaField) {
    let res = metaField.name;
    if (metaField.inline) {
      if (metaField.inline.prop) {
        res = metaField.inline.prop;
      } else if (metaField.inline.unique_props?.length) {
        res = metaField.inline.unique_props[0]
      } else {
        this.logger.warn(`Unable to get inline field name for ${metaField.name}`);
      }
    }
    return res;
  }

  private parentNameExists(parentKey: string, parentName: string) {
    let res = false;
    const existsIndex = parentKey.indexOf(parentName);
    if (existsIndex !== -1) {
      if (existsIndex === 0 || parentKey.endsWith(parentName) || parentKey.indexOf(`${System.keySep}${parentName}${System.keySep}`) !== -1 || parentKey.indexOf(`${System.keySep}${parentName}[`) !== -1) {
        res = true;
      }
    }
    return res;
  }

  private getTitleFromSubObject(traverseRes: TraverseObjectResult, field: MetaField, getPlaceholder: boolean) {
    let title: string;
    if (traverseRes.subObject) {
      const parentField: MetaField = traverseRes.subObject['$$meta'][traverseRes.fieldName];
      if (!getPlaceholder && parentField && parentField.title && parentField.field_type !== FieldType.ARRAY) {
        const dateInfo = this.fieldDateInfoService.getFieldDateInfo(field);
        const isHorizontalDate = dateInfo && dateInfo.view === 'horizontal';
        const parentFieldName = this.getInlineFieldName(parentField);
        const hasMatchingInlineProp = parentField.inline && field.name === parentFieldName;
        title = isHorizontalDate || hasMatchingInlineProp ? parentField.title : null;
      }
    }
    return title;
  }


}
