import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2
} from '@angular/core';
import {SearchObject} from '../../core/definitions/search-object';
import {SearchContainer} from '../../core/definitions/search-container';
import {ContentListContainer} from '../../core/definitions/object-content-tab/content-list-container';
import {SearchExecutorService} from '../../object-search/search-executor.service';
import {SearchResultSelectionsService} from '../../object-search/search-result-selections.service';

@Component({
  selector: 'app-object-content-list-regular-items',
  templateUrl: './object-content-list-regular-items.component.html',
  styleUrls: ['./object-content-list-regular-items.component.scss'],
})
export class ObjectContentListRegularItemsComponent implements OnChanges {

  @Input() contentListContainer: ContentListContainer;
  @Input() hasOperationMenu: boolean;
  @Output() objectRefresh = new EventEmitter<any>();

  searchContainer: SearchContainer;

  constructor(private renderer: Renderer2,
              private searchExecutorService: SearchExecutorService,
              private searchResultSelectionsService: SearchResultSelectionsService) {
  }

  ngOnChanges(): void {
    this.searchContainer = this.contentListContainer.searchContainer;
    if (this.contentListContainer && this.contentListContainer.operationContainer) {
      this.contentListContainer.refreshCallback = () => {
        this.objectRefresh.emit({store: false});
      };
    }
  }

  afterDelete() {
    if (this.objectRefresh) {
      this.objectRefresh.emit({store: false});
    }
    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  setSelected(event, art: SearchObject) {
    if (!this.contentListContainer.operationContainer.art.read_only) {
      this.searchResultSelectionsService.selectSearchResultItem(art, this.searchContainer, event.shiftKey);
    }
  }

}
