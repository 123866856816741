<div class="dropdown-menu-settings {{stylingClass}}"
     [ngClass]="{'is-open':isVisible}">
  <button mat-button
          class="dropdown-menu-settings__button"
          [ngClass]="{'color-white': stylingClass !== 'simple-setting-button' && isVisible,
          'text-blue-hover': stylingClass === 'simple-setting-button',
          'text-blue': stylingClass === 'simple-setting-button' && isVisible}"
          [matMenuTriggerRestoreFocus]="false"
          (menuOpened)="isVisible = true;"
          (menuClosed)="isVisible = false;"
          [matMenuTriggerFor]="dropDownMenu">
    <mat-icon *ngIf="icon" class="dropdown-menu-settings__button-icon">{{icon}}</mat-icon>
    <span *ngIf="text" class="removeTextOnSmallScreens" [translate]="text"></span>
    <mat-icon *ngIf="stylingClass !== 'simple-setting-button'"
              class="dropdown-menu-settings__button-arrow-container">expand_more</mat-icon>
  </button>
  <mat-menu #dropDownMenu="matMenu" class="dropdown-menu-settings__menu">
    <ng-content></ng-content>
  </mat-menu>
</div>
