import {Component, Input, OnInit, OnDestroy, OnChanges, ViewChild} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {AfterCloseOperation, OperationService} from '../../operations/operation.service';
import {OperationDialogService} from '../../operations/operation-dialog.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {SearchResultSelectionsService} from '../search-result-selections.service';
import {SearchExecutorService} from '../search-executor.service';
import {SearchResultViewType} from '../../core/definitions/search-result-view-type.enum';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {SearchTemplateGroupService} from '../search-template-group.service';
import {PrimusRouteService} from '../../core/primus-route.service';
import {SearchContainer} from '../../core/definitions/search-container';
import {SelectorCreationParams} from '../../core/definitions/selector-creation-params';
import {SearchViewFolderHeaderComponent} from './search-view-folder-header/search-view-folder-header.component';
import {OperationStepType} from "../../core/definitions/operation-step-type.enum";
import {Selector} from "../../core/definitions/reference";
import {SuperObjectModel} from "../../core/definitions/super-object-model";

@Component({
  selector: 'app-search-view-full',
  templateUrl: './search-view-full.component.html',
  styleUrls: ['./search-view-full.component.scss']
})
export class SearchViewFullComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchContainer: SearchContainer;
  @Input() searchViewType: string;
  @ViewChild(SearchViewFolderHeaderComponent) folderHeaderComponent: SearchViewFolderHeaderComponent;

  mediumScreen: boolean;
  sideMenuSmallScreen = false;
  isFolder = false;

  constructor(
    private primusRoute: PrimusRouteService,
    private uiTools: UiToolsService,
    private searchExecutorService: SearchExecutorService,
    private operationService: OperationService,
    private operationDialogService: OperationDialogService,
    private searchSelectorService: SearchSelectorService,
    private searchResultSelectionsService: SearchResultSelectionsService,
    private searchTemplateGroupService: SearchTemplateGroupService) {
  }

  ngOnChanges() {
    this.isFolder = this.searchViewType === 'folder';
    const stateTemplateGroupId = this.primusRoute.params['template_group_id'];
    if (!stateTemplateGroupId && this.searchContainer.templateGroupId) {
      this.searchTemplateGroupService.clearTemplateGroup(this.searchContainer);
      this.searchExecutorService.runSearch(this.searchContainer).then();
    }
    const listFieldTemplateId = this.primusRoute.params['list_field_template_id'];
    if (!listFieldTemplateId && this.searchContainer.listFieldTemplateId) {
      this.searchTemplateGroupService.clearListFieldTemplate(this.searchContainer);
      this.searchExecutorService.runSearch(this.searchContainer).then();
    } else if (listFieldTemplateId && !this.searchContainer.listFieldTemplateId) {
      this.searchTemplateGroupService.setListFieldTemplateGroup(this.searchContainer, listFieldTemplateId).then();
    }
  }

  ngOnInit() {
    if (this.searchContainer) {
      this.setOperationContainer().then();
    }
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.setOperationsCallback);
    this.searchContainer.selections.selectItemCallback = null;
  }

  onToggleSideMenuSmallScreen() {
    this.sideMenuSmallScreen = !this.sideMenuSmallScreen;
  }

  onOperationPerformed(afterCloseOperation: AfterCloseOperation) {
    if (afterCloseOperation && !afterCloseOperation.keepSelectedObjects) {
      this.searchResultSelectionsService.clearSelections(this.searchContainer);
    }
    if (afterCloseOperation && afterCloseOperation.refreshView) {
      this.searchExecutorService.runSearch(this.searchContainer).then();
    }
    if (this.folderHeaderComponent) {
      this.folderHeaderComponent.refreshHeader();
    }
  }

  onFilterRemoved() {
    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  get isListBased(): boolean {
    return this.searchContainer.searchResultViewName === SearchResultViewType.LIST;
  }

  private setOperationsCallback = () => {
    this.setOperations().then();
  }

  private async setOperationContainer() {
    this.searchContainer.operationContainer = await this.operationService.createOperationContainer(OperationTarget.SEARCH_VIEW);
    this.searchContainer.operationContainer.getTargetId = this.searchContainer.getTargetId;
    this.searchContainer.operationContainer.templateGroupId = this.searchContainer.templateGroupId;
    this.searchContainer.operationContainer.listFieldTemplateId = this.searchContainer.listFieldTemplateId;
    this.searchContainer.operationContainer.openOperationDialogFn = (callback: any) => {
      this.operationDialogService.openOperationDialog(this.searchContainer.operationContainer).then(data => {
        this.handleResultObject(data.resultObject);
        if (callback) {
          callback(data);
        }
      });
    };
    this.setSearchSelector();
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.setOperationsCallback);
    if (this.searchContainer.selections.selectItemCallback) {
      console.warn('Select item callback already set');
    }
    this.searchContainer.selections.selectItemCallback = () => {
      this.setOperations().then();
    };
  }

  private setSearchSelector() {
    this.searchContainer.operationContainer.enableSelector = (selector: Selector, params: SelectorCreationParams) => {
      this.searchContainer.operationContainer.contentInfo.setSelectorUsed(selector);
      this.searchSelectorService.enableSelector(selector, <SelectorContainer>this.searchContainer.operationContainer, params,
        {
          searchContainerCreated: () => {
          },
          selectorCallback: (selectedObj: any) => {
            this.searchSelectorService.disableSelector(<SelectorContainer>this.searchContainer.operationContainer);
            this.searchContainer.operationContainer.selectorCallback(selectedObj, selector, () => {
              if (this.searchContainer.operationContainer.currentOperation.$$currentStep.operation_step_type === OperationStepType.CLOSE_OPERATION_REFRESH) {
                this.searchResultSelectionsService.clearSelections(this.searchContainer);
                this.searchExecutorService.runSearch(this.searchContainer).then();
              } else {
                const resultObject = selectedObj[0];
                this.handleResultObject(resultObject).then();
              }
            });
          }
        }
      );
    };
  }

  private async handleResultObject(resultObject: SuperObjectModel) {
    if (resultObject && resultObject.object_type === 'TemplateModelOverviewFieldsForFieldSelect') {
      this.searchContainer.refreshTemplateOverviewFields = true;
      await this.searchTemplateGroupService.setListFieldTemplateGroup(this.searchContainer, resultObject.template_group_id);
      await this.searchExecutorService.runSearch(this.searchContainer);
    }
  }

  private async setOperations() {
    this.operationService.setOperationContextObjects(
      this.searchContainer.operationContainer,
      this.searchResultSelectionsService.getCleanItems(this.searchContainer));
    await this.operationService.setOperations(this.searchContainer.operationContainer, this.searchContainer);
  }

}
