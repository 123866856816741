<div class="information-headline" [ngClass]="{'smallScreen': smallScreen}">
  <div class="information-headline__title">
    <a *ngIf="smallScreen"
       (click)="onToggleMenu()"
       class="information-headline__toggle-button">
      <i class="icon-hamburger"></i>
    </a>

    <div class="information-headline__headline-container">
      <h3 *ngIf="operationContainer?.contentInfo.curListName === 'overview'">
        <i class="information-headline__headline-icon {{operationContainer.rootSectionsContainer.objectView.icon}}"></i>
        <span *ngIf="operationContainer.rootSectionsContainer"
              [innerHTML]="operationContainer.rootSectionsContainer.objectView.artifact_name"></span></h3>

      <h3 *ngIf="operationContainer?.contentInfo.curListName !== 'overview'">
        <i class="information-headline__headline-icon {{currentGroupOperation.menu_icon}}"></i>
        <span>{{contentListHeadline}} &nbsp;</span>
        <span *ngIf="!operationContainer.contentInfo.curContentListSource">({{operationContainer.contentInfo.selectedMainMenu.count}})</span>
        <span *ngIf="operationContainer.contentInfo.curContentListSource">({{operationContainer.contentInfo.curContentListSource?.array.length}})</span>
      </h3>
    </div>
  </div>

  <ng-container *ngIf="operationContainer?.currentOperation?.operation_type === 'operation'">
    <ng-container *ngFor="let operation of operationContainer.currentOperation.operation_steps">
      <button *ngIf="operation.operation_step_type === 'edit_object'"
              class="information-headline__button"
              mat-stroked-button
              color="primary"
              type="button"
              (click)="setEdit()"
              [disabled]="isEditing">
        <mat-icon>edit</mat-icon>
        <span class="bold uppercase" translate="TRANS__HEADER__EDIT"></span>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="operationContainer?.contentInfo.curListName !== 'overview' &&
  currentGroupOperation.operation_type === 'group'">
      <app-operation-menus-two-levels
        class="information-headline__button"
        [operationContainer]="operationContainer"
        [parentOperation]="currentGroupOperation"
      ></app-operation-menus-two-levels>
  </ng-container>
</div>
