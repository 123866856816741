<mat-form-field [formGroup]="formGroup">
  <mat-label>{{fieldOption.name}}</mat-label>
  <input type="text"
         placeholder="Sett verdi"
         aria-label="verdi"
         matInput
         [formControl]="formControl"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAlternative(fieldOption, $event)" [displayWith]="displayFn">
    <mat-option
      *ngFor="let alternative of fieldOption.filtered_alternatives"
      [value]="alternative" >{{alternative.title}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
