import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ImportFieldAlternative, ImportFieldOption} from "../import.service";
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {SearchParameters} from "../../../../core/definitions/search-parameters";
import {SearchService} from "../../../../core/search.service";

@Component({
  selector: 'app-concept-select',
  templateUrl: './concept-select.component.html',
  styleUrls: ['./concept-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ConceptSelectComponent)
    }
  ]

})
export class ConceptSelectComponent implements OnInit, ControlValueAccessor {
  @Input() fieldOption: ImportFieldOption;
  @Input() formGroup: FormGroup;
  formControl: FormControl;

  constructor(private searchService: SearchService) {
  }

  ngOnInit() {
    this.formControl = <FormControl<any>>this.formGroup.controls[this.fieldOption.name];
    this.setAlternatives().then();
  }

  displayFn(importAlt: ImportFieldAlternative): string {
    return importAlt ? importAlt.title : '';
  }

  selectAlternative(fieldOption: ImportFieldOption, event: MatAutocompleteSelectedEvent) {
    fieldOption.selected_alternative = event.option.value;
  }

  private async setAlternatives(): Promise<void> {
    this.fieldOption.alternatives = await this.searchForAlternatives(this.fieldOption);
    this.fieldOption.filtered_alternatives = this.fieldOption.alternatives;
  }

  private async searchForAlternatives(fieldOption: ImportFieldOption): Promise<ImportFieldAlternative[]> {
    if (fieldOption.alternatives.length) {
      return fieldOption.alternatives;
    }
    const searchParams = {
      fq: [
        `object_type:${fieldOption.concept_type}`,
        'valid:true'
      ],
      fl: ['artifact_name', 'artifact_id'],
      rows: 1000,
      sort: 'artifact_name asc'
    } as SearchParameters;
    if (fieldOption.concept_filters) {
      searchParams.fq = searchParams.fq.concat(fieldOption.concept_filters);
    }
    const searchRes = await this.searchService.search(searchParams);
    return searchRes.artifacts.map(art => {
      return {title: art.artifact_name, value: art.artifact_id}
    });
  }

  registerOnChange(/*fn: any*/): void {
    // N/A
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }

}
