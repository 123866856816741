import {ModelAdapter} from "./ModelAdapter";
import {UrlQueryParam} from "./UrlQueryParam";
import {TwoWayMap} from "./TwoWayMap";

export class DefaultModelAdapter<T> implements ModelAdapter<T> {
  singularKey: string;
  pluralKey: string;
  urlPrefix: string;
  urlForId: string;
  urlForList: string;
  keyConversionMap: TwoWayMap = new TwoWayMap();

  constructor(singularKey: string, pluralKey: string) {
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.urlPrefix = '/cms_api/v1.0';
    this.urlForId = null;
    this.urlForList = null;
  }

  /*
  * This method is used to convert a JSON object into a model object.The method expect there to be a payload with a
  * top-level key that matches the singularKey property of the adapter. Thus it will convert the JSON object:
  *
  * { "hero": { "id": "1", "name": "Superman", "dateOfBirth": "1970-01-01T00:00:00.000Z", "status": "new" } }
  *
  * into a Hero object, where the properties id, name, dateOfBirth and status are set on the object.
  * Date strings
  * will be converted into JS Date objects.
  */
  fromJsonObject(jsonString: any): T {
    return this.parseJSON(jsonString[this.singularKey]);
  }

  /*
  * This method is used to convert a JSON list into a list of model objects. The method expects there to be a payload
  * with a top-level key that matches the pluralKey property of the adapter. Thus it will convert the JSON object:
  *
  * { "heroes": [
  *   { "id": "1", "name": "Superman", "dateOfBirth": "1970-01-01T00:00:00.000Z", "status": "new" },
  *   { "id": "2", "name": "Batman", "dateOfBirth": "1970-01-02T00:00:00.000Z", "status": "new" },
  *   { "id": "3", "name": "Spiderman", "dateOfBirth": "1970-01-03T00:00:00.000Z", "status": "new" }
  * ] }
  *
  * into a list of Hero objects, where the properties id, name, dateOfBirth and status are set on the objects.
  * Date strings returning a JavaScript array of Hero object:
  *
  * [
  *   new Hero('1', 'Superman', new Date('1970-01-01')),
  *   new Hero('2', 'Batman', new Date('1970-01-02')),
  *   new Hero('3', 'Spiderman', new Date('1970-01-03'))
  * ]
  *
  */
  fromJsonList(jsonString: string): T[] {
    let returnList: T[] = [];
    // @ts-ignore
    let models = jsonString[this.pluralKey];
    if (models) {
      models.forEach((jsonModel: any) => {
        let modelObject: T = this.parseJSON(jsonModel);
        returnList.push(modelObject);
      });
    }

    return returnList;
  }

  /*
  * This method is used to convert a model object into a JSON object. The method will wrap the model object in a
  * payload with a top-level key that matches the singularKey property of the adapter. Thus it will convert the Hero
  * object:
  *
  * new Hero('1', 'Superman', new Date('1970-01-01'));
  *
  * into the JSON object:
  *
  * { "hero": { "id": "1", "name": "Superman", "dateOfBirth": "1970-01-01T00:00:00.000Z", "status": "new" } }
  *
  * Date objects will be converted into date string representations.
  *
  */
  toJsonObject(objectmodel: T): string {
    let dataObj = {};

    console.log('----- toJsonObject before conversion: ', dataObj);
    for (const key in objectmodel) {
      let keyToUse: string = key;

      if (this.keyConversionMap.hasKey(key)) {
        keyToUse = this.keyConversionMap.getByKey(key);
        console.log('\tkey: ', key, ' keyToUse: ', keyToUse);
        objectmodel[keyToUse] = objectmodel[key];
        delete objectmodel[key];
      }
    }
    console.log('----- toJsonObject after conversion: ', objectmodel);

    // @ts-ignore
    dataObj[this.singularKey] = objectmodel;

    return JSON.stringify(dataObj);
  }

  /*
  * This method is used to convert a list of model objects into a JSON list. The method will wrap the list of model
  * objects in a payload with a top-level key that matches the pluralKey property of the adapter. Thus it will convert
  * the list of Hero objects:
  *
  * let hero1 = new Hero('1', 'Superman', new Date('1970-01-01'));
  * let hero2 = new Hero('2', 'Batman', new Date('1970-01-02'));
  * let hero3 = new Hero('3', 'Spiderman', new Date('1970-01-03'));
  * let heroes = [hero1, hero2, hero3];
  *
  * into the JSON object:
  *
  * { "heroes": [
  *   { "id": "1", "name": "Superman", "dateOfBirth": "1970-01-01T00:00:00.000Z", "status": "new" },
  *   { "id": "2", "name": "Batman", "dateOfBirth": "1970-01-02T00:00:00.000Z", "status": "new" },
  *   { "id": "3", "name": "Spiderman", "dateOfBirth": "1970-01-03T00:00:00.000Z", "status": "new" }
  * ] }
  *
  * Date objects will be converted into Date string representations.
  */
  toJsonList(modelList: T[]): string {
    let dataList = {};
    // @ts-ignore
    dataList[this.pluralKey] = [];

    modelList.forEach(model => {
      // @ts-ignore
      dataList[this.pluralKey].push(JSON.parse(this.toJsonObject(model))[this.singularKey]);
    })

    return JSON.stringify(dataList);
  }

  /*
  * This method is used to parse a JSON object and convert all properties into a JavaScript object.
  * Date strings will be converted into Date objects.
  *
  * This method is used internally by the adapter to convert JSON objects into model objects.
  *
  */

  protected parseJSON<T>(obj: T): T {
    for (const key in obj) {
      let keyToUse : string = key;
      if (this.keyConversionMap.hasValue(key)) {
        keyToUse = this.keyConversionMap.getByValue(key);
      }

      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        // Check if the value is a string and resembles a date
        if (typeof value === 'string' && value.indexOf("-") === 4 && !isNaN(Date.parse(value))) {
          // @ts-ignore
          obj[keyToUse] = new Date(value);
        } else if (typeof value === 'object' && value !== null) {
          // Recursively parse nested objects
          obj[keyToUse] = this.parseJSON(value);
        } else {
          obj[keyToUse] = value;
        }
      }

      if (keyToUse !== key) {
        delete obj[key];
      }
    }

    // @ts-ignore
    obj['status'] = 'loaded';
    return obj;
  }

  public getUrlForId(id: string, queryParams: UrlQueryParam[]): string {
    let url = this.urlPrefix + '/' + this.pluralKey + "/" + id;

    if (this.urlForId) {
      url = this.urlPrefix + this.urlForId;
    }

    if (queryParams && queryParams.length > 0) {
      url = this.appendQueryParamsToUrl(url, queryParams);
    }
    return url;
  }

  public getUrlForList(queryParams: UrlQueryParam[]): string {
    let url = this.urlPrefix + '/' + this.pluralKey

    if (this.urlForList) {
      url = this.urlPrefix + this.urlForList;
    }

    if (queryParams && queryParams.length > 0) {
      url = this.appendQueryParamsToUrl(url, queryParams);
    }
    return url;
  }

  private appendQueryParamsToUrl(url: string, queryParams: UrlQueryParam[]) {
    if (queryParams && queryParams.length > 0) {
      url += '?' + queryParams.map(param => param.key + "=" + encodeURIComponent(param.value)).join('&');
    }

    return url;
  }

  /*
  This method returns the input jsonString as-is, as it is expected that the input is already in the correct format.
   */
  generateSimpleJsonApi(jsonString: string): string {
    return jsonString;
  }
}
