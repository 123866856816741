import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {UntypedFormControl} from '@angular/forms';
import {TreeNode, TreeNodeChildren} from './tree-node/tree-node.component';
import {Subscription} from 'rxjs';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';
import {SearchObject} from '../../core/definitions/search-object';
import {SearchService} from "../../core/search.service";

@Component({
  selector: 'app-primus-solr-tree-view',
  templateUrl: './primus-solr-tree-view.component.html',
  styleUrls: ['./primus-solr-tree-view.component.scss']
})
export class PrimusSolrTreeViewComponent implements OnInit, OnChanges, OnDestroy {
  private readonly ROOT_NODE_ID: string = 'root';
  private readonly searchInputChangeSubscription: Subscription;

  // GUI elements/controls
  @Input() public readonly searchInputLabel: string = 'TRANS__SOLR_TREE_VIEW__SEARCH_INPUT_LABEL';
  @Input() public readonly topNodeLabel: string = 'TRANS__SOLR_TREE_VIEW__ALL_NODES_LABEL';

  // Config
  @Input() public query: string;
  @Input() public tabName: string;
  @Input() public idProp = 'artifact_id';
  @Input() public labelProp = 'artifact_name';
  @Input() public parentIdProp = 'parent_id';
  @Input() public fullPathIdProp = 'm_path';
  @Input() public fullPathLabelProp = 'full_path';
  @Input() public fullPathIdSeparator = '/';
  @Input() public fullPathLabelSeparator = '» ';
  @Input() public secondaryLabelProp?: string;
  @Input() public selectedNode: TreeNode | null;
  @Input() public isLeafProp = 'is_leaf';

  // Outputs
  @Output() public readonly selectionChanged: EventEmitter<TreeNode | null> = new EventEmitter<TreeNode | null>();

  // Internal state
  readonly pageSize: number = 500;
  readonly searchControl: UntypedFormControl = new UntypedFormControl();
  searchChildren: TreeNodeChildren = {};

  readonly rootNode: TreeNode = {id: this.ROOT_NODE_ID, label: this.topNodeLabel};
  children: TreeNodeChildren = {};

  loading: TreeNode | null = null;

  /**
   * Whether or not a search is in progress
   * @type {boolean}
   */
  searching = false;
  currentSearchPageIndex = 0;
  enableSearchLoadMoreButton = false;

  constructor(private readonly searchService: SearchService) {
    this.searchInputChangeSubscription = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map(val => val || ''),
      tap(() => {
        this.currentSearchPageIndex = 0;
      })
    ).subscribe(() => this.search());
  }

  public ngOnInit(): void {
    if (!this.selectedNode) {
      this.selectedNode = this.rootNode;
    }
    this.loadChildren(this.rootNode).then();
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('topNodeLabel')) {
        this.rootNode.label = changes.topNodeLabel.currentValue;
    }
  }

  public ngOnDestroy(): void {
    if (!!this.searchInputChangeSubscription && !this.searchInputChangeSubscription.closed) {
      this.searchInputChangeSubscription.unsubscribe();
    }
  }

  async loadChildren(node: TreeNode, forceReload: boolean = false): Promise<void> {
    if (!node || (!forceReload && !!this.children[node.id])) {
      return;
    }
    try {
      this.loading = node;
      const isRootNode = node.id === this.ROOT_NODE_ID;

      const query = [
        isRootNode ? '-' : '',
        this.parentIdProp,
        ':',
        isRootNode ? '*' : `"${node.id}"`,
      ].join('');

      const res = await this.searchService.search({
        query,
        fq: [this.query],
        fl: [this.idProp, this.labelProp, this.parentIdProp, this.secondaryLabelProp, this.isLeafProp],
        sort: `${this.labelProp} asc`,
        rows: this.pageSize
      } as SearchParameters);
      this.children[node?.id] = (res?.artifacts || []).map(this.mapArtifactToTreeNode.bind(this));
    } catch (e) {
      console.error('Unable to load children', e);
      this.children[node?.id] = [];
    } finally {
      this.loading = null;
    }
  }

  async search(nextPage: boolean = false): Promise<void> {
    const value = this.searchControl.value;
    if (!value) {
      this.searchChildren = this.children;
      return;
    }
    try {
      if (nextPage) {
        this.currentSearchPageIndex++;
      }
      this.searching = true;
      const params = {
        query: `${this.labelProp}:*${value}*`,
        fq: [this.query],
        fl: [
          this.idProp, this.labelProp, this.parentIdProp,
          this.secondaryLabelProp, this.fullPathIdProp, this.fullPathLabelProp, this.isLeafProp
        ],
        rows: this.pageSize,
        start: this.pageSize * this.currentSearchPageIndex
      } as SearchParameters;
      const res = await this.searchService.search(params);
      const numberOfLoadedOptions = this.pageSize * (this.currentSearchPageIndex + 1);
      this.enableSearchLoadMoreButton = numberOfLoadedOptions < (res?.search_count || 0);
      const matches = res?.artifacts || [];

      const children: TreeNodeChildren = nextPage ? this.searchChildren : {
        [this.ROOT_NODE_ID]: []
      };


      matches.forEach(match => {
        const parentIds: Array<string> = (match[this.fullPathIdProp] || '')
          .split(this.fullPathIdSeparator)
          .filter(v => !!v);
        const parentNames: Array<string> = ((match[this.fullPathLabelProp] || '') as string)
          .split(this.fullPathLabelSeparator)
          .slice(0, -1)
          .filter(v => !!v);

        parentIds.unshift(this.rootNode.id);
        parentNames.unshift(this.rootNode.label);

        if (parentIds.length !== parentNames.length) {
          console.warn('Search returned node where parent names and parent ids are inconsistent:', match);
        }

        let current = this.mapArtifactToTreeNode(match);

        for (let i = parentIds.length - 1; i >= 0; i--) {
          const id = parentIds[i];
          if (!children[id]) {
            children[id] = [current];
          } else if (!children[id].some(c => c.id === current.id)) {
            children[id].push(current);
          }
          current = {id, label: parentNames[i]};
        }
      });

      this.searchChildren = children;
    } catch (e) {
      console.error('Error occurred while searching in locations', e);
    } finally {
      this.searching = false;
    }
  }

  clearSearch(): void {
    this.searchControl.reset('', {emitEvent: false});
  }

  onSelect(node: TreeNode): void {
    this.selectionChanged.emit(node?.id === this.ROOT_NODE_ID ? null : node);
  }

  private mapArtifactToTreeNode(so: SearchObject): TreeNode {
    return {
      id: so[this.idProp],
      label: so[this.labelProp],
      secondaryLabel: this.secondaryLabelProp ? so[this.secondaryLabelProp] : '',
      is_leaf: so[this.isLeafProp],
    } as TreeNode;
  }
}
