import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-procedure-content-view-selector',
  templateUrl: './procedure-content-view-selector.component.html',
  styleUrls: ['./procedure-content-view-selector.component.scss']
})
export class ProcedureContentViewSelectorComponent implements OnInit {

  @Input() currentProcedureContentViewName: string;
  @Output() viewSelected = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectView(viewName: string) {
    this.currentProcedureContentViewName = viewName;
    this.viewSelected.emit(viewName);
  }

}
