import {Injectable} from '@angular/core';
import {CmsApiService} from "./cms-api.service";
import {FeatureFlags} from "./definitions/feature-flags";

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;


@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  private featureFlags: FeatureFlags = {
    temporary: {
      placeCultureHubImport: false,
      multipleImageUrls: false,
      destroyFieldRenamed: false,
      displayMediaSelectorsNewObjects: false,
      displaySendErrorViaEmailOption: false,
      newJoinOperation: false,
      newImageUrlApi: false,
      clientAccessConfigServer: false,
    },
    experimental: {
      useNewObjectPage: false,
    },
    permanent: {
      featureFlags: false
    }
  };

  constructor(private cms: CmsApiService) {
  }

  async initFeatureFlags() {
    return this.cms.getFeatureFlags({suppressErrHandler: true}).then(featureFlags => {
      this.featureFlags = featureFlags;
      return this.applyOverrides(featureFlags);
    })
  }

  getFeatureFlags(): FeatureFlags {
    return this.applyOverrides(this.featureFlags);
  }

  getOverrides(): DeepPartial<FeatureFlags> | undefined {
    const overrides = localStorage.getItem('featureFlagsOverrides');
    try {
      return overrides ? JSON.parse(overrides) : undefined
    } catch (e) {
      return undefined;
    }
  }

  setOverrides(overrides: DeepPartial<FeatureFlags>) {
    localStorage.setItem('featureFlagsOverrides', JSON.stringify(overrides));
  }

  clearOverrides() {
    localStorage.removeItem('featureFlagsOverrides');
  }

  private applyOverrides(flags: FeatureFlags): FeatureFlags {
    const overrides = this.getOverrides();
    return {
      temporary: {
        ...flags.temporary,
        ...overrides?.temporary
      },
      experimental: {
        ...flags.experimental,
        ...overrides?.experimental
      },
      permanent: {
        ...flags.permanent,
        ...overrides?.permanent
      }
    }
  }
}
