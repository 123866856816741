<div class="field-list__container">
  <div class="field-list__group" *ngFor="let group of groups">

    <span class="group__letter">{{ group.groupLetter }}</span>

    <ul class="group__list" cdkDropListSortingDisabled cdkDropList>
      <li class="group__list-item"
          *ngFor="let field of group.items"
          [ngClass]="{'active': field.fieldId === selectedFieldId}"
          [cdkDragData]="field"
          [cdkDragDisabled]="deviceType === 'mobile'"
          [cdkDragStartDelay]="deviceType === 'tablet' ? 300 : 0"
          (cdkDragStarted)="setSelectedField(field)"
          (click)="setSelectedField(field)"
          cdkDrag>

        <span class="item-label">
          <span class="name">{{ field.title.name }}</span>
          <span class="alternate-name" *ngIf="field.title.alternateName">
            ({{ field.title.alternateName }})
          </span>
        </span>

        <i class="icon-expand grab-field-icon"></i>

        <div class="drag-preview" *cdkDragPreview>
          <span class="field-title">
            {{ field.title.name}}
            <span *ngIf="field.title.alternateName"> ({{ field.title.alternateName }})</span>
          </span>
        </div>

        <span class="drag-placeholder" *cdkDragPlaceholder></span>

      </li>
    </ul>

  </div>
</div>
