<app-m3-global-kpl-styles>
@if (object) {
  <div>
    <section>
      <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__FOLDERS' | translate }}</h2>
      <div>
        <app-object-folders [object]="object"></app-object-folders>
      </div>
    </section>

    <section>
      <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__CHANGES' | translate }}</h2>
      <div>
        <app-object-update-log [contextId]="object.artifact_id"
                               [getLogItems]="true"></app-object-update-log>
      </div>
    </section>

    <section>
      <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__IDENTIFICATION_NUMBER_LOG' | translate }}</h2>
      <div>
        <app-identification-number-log [object]="object"></app-identification-number-log>
      </div>
    </section>

    <section>
      <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__COLLECTION_LOG' | translate }}</h2>
      <div>
        <app-collection-log [object]="object"></app-collection-log>
      </div>
    </section>

    <section>
      <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__COLLECTION_STATUS_LOG' | translate }}</h2>
      <div>
        <app-collection-status-log [object]="object"></app-collection-status-log>
      </div>
    </section>

    <section>
      <h2>{{ 'TRANS__OBJECT_INFORMATION_TABS__QUANTITY_LOG' | translate }}</h2>
      <div>
        <app-quantity-log [object]="object"></app-quantity-log>
      </div>
    </section>
  </div>
}
</app-m3-global-kpl-styles>
