import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContentInfo} from '../../core/definitions/content-info';
import {AConst} from '../../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-object-content-list',
  templateUrl: './object-content-list.component.html',
  styleUrls: ['./object-content-list.component.scss']
})
export class ObjectContentListComponent implements OnInit {
  @Input() contentInfo: ContentInfo;
  @Output() objectRefresh = new EventEmitter<any>();
  AConst = AConst;
  constructor(private translateService: TranslateService) { }

  ngOnInit() {
  }

  setObjectRefresh(event) {
    this.objectRefresh.emit(event);
  }

  get count() {
    let res = '';
    if (this.contentInfo.curListContainer && this.contentInfo.curListContainer.searchContainer.searchResult) {
      res = this.contentInfo.curListContainer.searchContainer.searchResult.search_count.toString();
    } else if (this.contentInfo.curContentListSource) {
      res = this.contentInfo.curContentListSource.array.length.toString();
    }
    return res;
  }

  get headline() {
    let res = '';
    if (this.contentInfo.curListContainer) {
      res = this.contentInfo.curListContainer.searchContainer.currentPathView.search_view.title;
    } else if (this.contentInfo.curContentListSource) {
      res = this.contentInfo.curContentListSource.caption;
    }
    if (res) {
      res = this.translateService.instant(res);
    }
    return res;
  }
}
