import { Injectable } from '@angular/core';
import {SearchContainer} from "../core/definitions/search-container";
import {CheckFilterGroup} from "../core/definitions/search-objects";

@Injectable({
  providedIn: 'root'
})
export class SearchFilterGroupEnablerService {

  constructor() { }

  enableCheckedFilterGroups(searchContainer: SearchContainer, checkFilterGroups: CheckFilterGroup[]) {
    const checkedFilters = searchContainer.filtersFacets.checkedFilters;
    const checkedFilterNames = Object.keys(checkedFilters);
    if (checkedFilterNames.length === 0) {
      return;
    }
    for (const filterGroup of checkFilterGroups) {
      if (filterGroup.enabled) {
        continue;
      }
      const firstFilterName = filterGroup.filters[0].name;
      for (const filterName of checkedFilterNames) {
        if (firstFilterName === filterName) {
          filterGroup.enabled = true;
          filterGroup.active = true;
          break;
        }
      }
    }
  }

  setPreDefinedEnabledForFilterGroups(checkFilterGroups: CheckFilterGroup[]) {
    for (const filterGroup of checkFilterGroups) {
      if (filterGroup.enabled) {
        continue;
      }
      for (const filter of filterGroup.filters) {
        if (filter.checked_value !== undefined) {
          filterGroup.enabled = true;
          filterGroup.active = true;
          break;
        }
      }
    }
  }
}
