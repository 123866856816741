import {Component, OnInit} from '@angular/core';
import {CmsApiService} from '../../../core/cms-api.service';
import {AConst} from '../../../core/a-const.enum';

@Component({
  selector: 'app-admin-settings-faq',
  templateUrl: './admin-settings-faq.component.html',
  styleUrls: ['./admin-settings-faq.component.scss']
})
export class AdminSettingsFaqComponent implements OnInit {


  adminSettingsFq = [];
  AConst = AConst;

  constructor(private cms: CmsApiService) {
  }

  ngOnInit() {
    this.getFaq();
  }

  private getFaq() {
    this.cms.getFaq({category: 'settings'}).then((data) => {
        this.adminSettingsFq = data[AConst.FAQ];
      },
      function (failed) {
        console.error('This failed! ' + failed.message);
      }
    );
  }

}
