import {Component, HostListener, OnInit} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {FieldService} from '../services/field.service';
import {QueryService} from '../services/query.service';
import {SearchableField} from '../../../core/definitions/advanced-search/searchable-field';
import {PrimusRouterService} from '../../../core/primus-router.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: [
    './advanced-search.component.scss',
    './advanced-search.component.tablet.scss',
    './advanced-search.component.mobile.scss',
  ]
})
export class AdvancedSearchComponent implements OnInit {

  /**
   * This is only used on mobile devices to signify which page to show
   * @type {boolean}
   */
  fieldListPageActive: boolean;

  /**
   * All fields available for advanced search
   * @type {Array<SearchableField>}
   */
  allFields: Array<SearchableField>;

  /**
   * All fields available for advanced search after filters have been applied
   * @type {Array<SearchableField>}
   */
  filteredFields: Array<SearchableField>;

  /**
   * The current device-type
   * @type {"desktop" | "tablet" | "mobile"}
   */
  deviceType: 'desktop' | 'tablet' | 'mobile';

  /**
   * The field to add to the selected group.
   * Only used on mobile
   * @type {SearchableField}
   * @private
   */
  selectedField: SearchableField | null;

  /**
   * A list of all artifact-types that are selected for the search-result
   * @type {Array<string>}
   */
  selectedArtifactTypeIds: Array<string>;

  /**
   * Whether or not the component is loading data
   * @type {boolean}
   */
  loading: boolean;


  /**
   * Constructor: Sets default values
   * @param {FieldService} fieldService
   * @param {PrimusRouterService} primusRouter
   * @param {QueryService} queryService
   * @param {MatBottomSheetRef<AdvancedSearchComponent>} bottomSheetRef
   */
  constructor(private readonly fieldService: FieldService,
              private readonly primusRouter: PrimusRouterService,
              readonly queryService: QueryService,
              private readonly bottomSheetRef: MatBottomSheetRef<AdvancedSearchComponent>) {
    this.fieldListPageActive = false;
    this.allFields = [];
    this.filteredFields = [];
    this.deviceType = 'desktop';
    this.selectedField = null;
    this.selectedArtifactTypeIds = [];
    this.loading = false;
  }

  /**
   * Initializes the component. Loading artifact-types, fields, inits filters and sets the initial screen-size.
   */
  public ngOnInit(): void {
    this.loading = true;
    this.selectedArtifactTypeIds = this.queryService.getAllowedArtifactTypes();
    this.fieldService.getFields()
      .then(fields => {
        this.allFields = fields || [];
        this.handleFieldsFiltered(this.allFields);
        this.loading = false;
      })
      .catch(() => this.loading = false);

    // Call handler to set initial value on init
    // since the resize-event is not fired until
    // the window is actually resized.
    this.handleWindowResize();
  }

  /**
   * Toggles the active page (field-list og query-builder).
   * Only relevant on mobile devices
   */
  public togglePage(): void {
    this.fieldListPageActive = !this.fieldListPageActive;
  }

  /**
   * Callback for when the filter-component has filtered data.
   * This will update the filteredFields, and thus updating the available fields
   * @param {Array<SearchableField>} filteredFields
   */
  public handleFieldsFiltered(filteredFields: Array<SearchableField>): void {
    this.filteredFields = filteredFields;
  }

  /**
   * Sets the selected field, causing it to be displayed as active in the field-list.
   * Only relevant on mobile devices when only a single page is visible at a time.
   * @param {SearchableField} field
   * @see selectField
   */
  public setSelectedField(field: SearchableField): void {
    this.selectedField = field;
  }

  /**
   * Takes the field selected with setSelectedField and adds it to the selected group,
   * and returns the user to the query-builder-page.
   * Only relevant on mobile devices.
   *
   * @see setSelectedField
   */
  public selectField(): void {
    if (this.selectedField) {
      this.queryService.addQueryFieldToSelectedGroup(this.selectedField);
      this.fieldListPageActive = false;
    }
  }

  /**
   * Cancels the selection of the field selected with setSelectedField;
   * Only relevant on mobile devices.
   *
   * @see setSelectedField
   */
  public abortSelectField(): void {
    this.selectedField = null;
    this.fieldListPageActive = false;
  }

  /**
   * Switches the page to list-view when the user selects a group to add fields to.
   * Only relevant on mobile devices.
   */
  public handleGroupSelected(): void {
    this.fieldListPageActive = true;
  }

  /**
   * Executes the search by navigating to the advanced-search-result-view.
   */
  public async handleSearch(): Promise<void> {
    this.bottomSheetRef.dismiss('search');
    await this.primusRouter.navigateState('home.primus.advanced-search');
  }

  /**
   * Aborts the search
   */
  public cancelSearch(): void {
    this.queryService.clearQuery();
    this.bottomSheetRef.dismiss('cancel');
  }

  /**
   * Callback that updates the device-type when the screen-size changes.
   * @private
   */
  @HostListener('window:resize')
  private handleWindowResize(): void {
    const width = window.innerWidth;
    if (width < 642) {
      this.deviceType = 'mobile';
    } else if (width < 1025) {
      this.deviceType = 'tablet';
    } else {
      this.deviceType = 'desktop';
    }
  }
}
