import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-admin-concept-query-concept-types',
  templateUrl: './admin-concept-query-concept-types.component.html',
  styleUrls: ['./admin-concept-query-concept-types.component.scss']
})
export class AdminConceptQueryConceptTypesComponent implements OnInit {

  @Output() querySubmitter = new EventEmitter<string>();

  constructor(private formBuilder: UntypedFormBuilder) { }
  queryConceptTypeForm: UntypedFormGroup;
  queryConceptTypeControl: UntypedFormControl;

  ngOnInit(): void {
    this.queryConceptTypeControl = new UntypedFormControl('');
    this.queryConceptTypeForm = this.formBuilder.group({
      queryConceptTypeControl: this.queryConceptTypeControl
    });
  }

  submitQuery() {
    this.querySubmitter.emit(this.queryConceptTypeControl.value);
  }
}
