@if (hasRectoVerso) {
  <div class="recto-verso-buttons">
    <button mat-stroked-button color="primary"
            class="recto-verso-buttons__button"
            [disabled]="isRecto"
            [ngClass]="{'active': isRecto}" (click)="goToSearchItem(rectoObject)">
      @if (isRecto) {
        <mat-icon class="recto-verso-buttons__icon">visibility</mat-icon>
      }
      <span class="bold uppercase">Recto</span>
    </button>
    <button mat-stroked-button color="primary"
            class="recto-verso-buttons__button"
            [disabled]="isVerso"
            [ngClass]="{'active': isVerso}" (click)="goToSearchItem(versoObject)">
      @if (isVerso) {
        <mat-icon class="recto-verso-buttons__icon">visibility</mat-icon>
      }
      <span class="bold uppercase">Verso</span>
    </button>
  </div>
}
