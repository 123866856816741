import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CmsQueueService} from './cms-queue.service';
import {CmsApiService} from './cms-api.service';
import {ClientConfig} from './definitions/client-config';
import {LoggerService} from "./logger.service";

@Injectable({
  providedIn: 'root'
})
export class TranslationSetterService {

  constructor(
    private logger: LoggerService,
    private translate: TranslateService,
    private cmsQueueService: CmsQueueService,
    private cms: CmsApiService) {
  }

  setTranslations(lang?: string) {
    if (!lang) {
      lang = this.getLanguageUrlParam();
    }
    if (lang) {
      this.translate.use(lang);
    } else {
      if (!this.cms.getApiUrl(this.cms.getClientConfig, true, true)) {
        this.translate.use('no');
        return;
      }
      this.cmsQueueService.runCmsFnWithQueue(this.cms.getClientConfig, undefined, false,
        (data: ClientConfig) => {
          this.translate.use(data.CLIENT_LANGUAGE);
        },
        e => {
          this.logger.error(`An error occurred getting client config: ${e}`);
        }).then();
    }
  }

  private getLanguageUrlParam() {
    let lang;
    const langParam = 'lang=';
    const refString = window.location.hash || window.location.href;
    const existIndex = refString.indexOf(langParam);
    if (existIndex !== -1) {
      const endIndex = refString.indexOf('&', existIndex);
      if (endIndex !== -1) {
        lang = refString.substring(existIndex + langParam.length, endIndex);
      } else {
        lang = refString.substring(existIndex + langParam.length);
      }
    }
    return lang;
  }
}
