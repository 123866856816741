import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ResultViewService} from '../../result-view.service';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-menu-view-selector',
  templateUrl: './search-menu-view-selector.component.html',
  styleUrls: ['./search-menu-view-selector.component.scss']
})
export class SearchMenuViewSelectorComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() currentResultView: any;

  private oldTempGroupId: string;

  constructor(private searchExecutorService: SearchExecutorService,
              private resultViewService: ResultViewService) {
  }

  ngOnInit() {
    // if (this.searchContainer.searchResultViewName === 'content-list' ||
    //   this.searchContainer.searchResultViewName === 'selector') {
    //   this.searchViewClass = 'object';
    // }
    this.oldTempGroupId = this.searchContainer.templateGroupId;
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.setCurrentSearchResultView);
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.setCurrentSearchResultView);
  }

  setCurrentView(view) {
    this.currentResultView.icon = view.icon;
    this.currentResultView.description = view.description;
    this.currentResultView.name = view.name;
    this.resultViewService.setCurrentResultView(view.name, this.searchContainer);
    this.searchExecutorService.resetSearchPosition(this.searchContainer);
    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  private setCurrentSearchResultView = () => {
    this.setCurrentIcon(this.searchContainer);
  }

  private setCurrentIcon(searchContainer: SearchContainer) {
    if (searchContainer) {
      let views = searchContainer.currentPathView.search_view.search_result_views;
      if (!views) {
        views = searchContainer.searchView.search_result_views;
      }
      searchContainer.searchResultViews = views;
      views.views.forEach(view => {
        if (view.name === searchContainer.searchResultViewName) {
          this.currentResultView.icon = view.icon;
          this.currentResultView.description = view.description;
          this.currentResultView.name = view.name;
        }
      });
    }
  }

}
