<div class="search-tile-overview-fields"
     *ngFor="let field of searchObject.overview_simple; index as $index">

  <div *ngIf="$index < 2"
       class="search-tile-overview-fields__value"
       [ngClass]="{'line2': $index === 0, 'line3': $index === 1}">
    <span *ngIf="!field.artifact_id" [innerHTML]="field.value"></span>

    <span *ngIf="field.artifact_id">
      <app-object-url [object]="field">
        <div class="object-url-content">
          <span class="text-link">{{field.value}}</span>
        </div>
      </app-object-url>
    </span>
  </div>

</div>
