<button mat-flat-button (click)="openTemplateSelectModal()" class="open-template-selector" [disabled]="loading()">
  <mat-icon matButtonIcon class="material-symbols-outlined">tune</mat-icon>
  <div>
    @if (loading()) {
      <mat-spinner diameter="12"></mat-spinner>
    } @else if(selectedTemplateGroup) {
      <div>{{ selectedTemplateGroup.group_name }}</div>
    } @else {
      <div>{{ 'TRANS__SELECT_OBJECT_TEMPLATE_GROUP__SELECT_TEMPLATE_LABEL' | translate }}</div>
    }
  </div>
</button>
