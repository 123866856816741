<div class="md-table primus-table-wrapper admin-scroll-layout">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleSelectionForAllRows() : null"
                      [checked]="selectionState.hasValue() && isAllSelected()"
                      [disabled]="disableSelection"
                      [indeterminate]="selectionState.hasValue() && !isAllSelected()">
        </mat-checkbox>
        <span class="admin-table-select-all-name"
              [ngClass]="{'disabled': disableSelection}">{{'TRANS__EDIT_FIELD__CHOOSE' | translate}}</span>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? handleSelectionChanged(row) : null"
                      [disabled]="disableSelection || (rowSelectableFn && !rowSelectableFn(row))"
                      [checked]="selectionState.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container *ngFor="let col of columns" [matColumnDef]="col.property">
      <ng-container *ngIf="sortableColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="">{{ col.title | translate }}</th>
      </ng-container>
      <ng-container *ngIf="!sortableColumns">
        <th mat-header-cell *matHeaderCellDef>{{ col.title | translate }}</th>
      </ng-container>
      <td mat-cell
          *matCellDef="let row"
          [ngClass]="{'image-col': col.isImage}"
          (click)="handleRowClicked(row)">


        <app-thumbnail *ngIf="col.isImage && !!row[col.property]"
                       [imageIdField]="col.property"
                       [item]="row"
                       class="primus-table-thumbnail"
                       stylingClass="profile-image">
        </app-thumbnail>

        <mat-icon *ngIf="!!col.isImage && !row[col.property]" class="none-profile-image">image</mat-icon>


        <span *ngIf="!col.isImage && !isBool(row, col)">{{ row[col.property] }}</span>

        <span *ngIf="isBool(row, col)">
          <mat-icon *ngIf="!!row[col.property]">check_box</mat-icon>
          <mat-icon *ngIf="!row[col.property]">check_box_outline_blank</mat-icon>
        </span>

      </td>
    </ng-container>

    <ng-container matColumnDef="rowActions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button [disabled]="rowEditableFn && !rowEditableFn(row)" (click)="handleEditClicked(row)">
          <mat-icon *ngIf="editable">edit</mat-icon>
          <mat-icon *ngIf="!editable" color="accent">arrow_forward</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{'primus-table-row': enableRowSelection}">
    </tr>

  </table>

</div>
<div class="admin-action-toolbar">
  <button mat-stroked-button
          color="primary"
          type="button"
          *ngIf="addable !== false"
          [disabled]="actionButtonDisabled"
          (click)="addNewRowClicked.emit()">
    <mat-icon matPrefix>add</mat-icon>
    <span class="bold uppercase">{{ actionButtonName | translate: {parentName: actionButtonParent?.name || ''} }}</span>
  </button>

  <mat-paginator
    *ngIf="dataSource?.data?.length > 0 || dataSource['numRecords'] > 0"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [length]="availableItems"
    (page)="pageChanged.emit($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>
