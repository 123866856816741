import {Option} from './option-info';

export class NodeSelection {
  selectedNode = -1;
  selectedChildNode = -1;
  selectedChild = -1;
  level = 1;
}

export class HierarchicNode extends Option  {
  children: HierarchicNode[] = [];
  level = 0;
  full_path: string;
  $$nodeType = '';
  $$open = true;
  $$name: string;
  $$description: string;
  $$disabled: boolean;
  $$hasParent: boolean;
  $$rows: number;
  $$parentNode: HierarchicNode;
  $$grandChildrenSet: boolean;
}

export class Level {
  level = 1;
}
