import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionMenuItem, MenuGroupButtonComponent} from '../menu-group-button/menu-group-button.component';

@Component({
  selector: 'app-object-page-operations-toolbar',
  standalone: true,
    imports: [
        MenuGroupButtonComponent
    ],
  templateUrl: './object-page-operations-toolbar.component.html',
  styleUrl: './object-page-operations-toolbar.component.scss'
})
export class ObjectPageOperationsToolbarComponent {
  @Input() actions: ActionMenuItem[];

  @Output() actionClick = new EventEmitter<ActionMenuItem>();

  onActionClick(action: ActionMenuItem) {
    this.actionClick.emit(action);
  }
}
