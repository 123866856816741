<div class="search-filter-dropdown-menu {{searchViewClass}}">

  <button class="search-filter-dropdown-menu__button"
          mat-stroked-button
          [matMenuTriggerFor]="smallFilterMenu"
          [matMenuTriggerRestoreFocus]="false"
          (menuOpened)="dropdownMenu = true;"
          (menuClosed)="dropdownMenu = false;"
          [ngClass]="{'active': dropdownMenu}">
    <mat-icon class="search-filter-dropdown-menu__button-icon">filter_alt</mat-icon>

    <span class="search-filter-dropdown-menu__button-text-container">
      <a class="selected-filter-indicator">
        <input id="checkboxesFilter"
               [(ngModel)]="searchContainer.filtersFacets.filtersChecked"
               type="radio"
               value="checked">
        <label class="float-left" for="checkboxesFilter"></label></a>

      <span class="search-filter-dropdown-menu__button-text"
            translate="TRANS__FILTER__FILTRATE"></span></span>

    <mat-icon *ngIf="searchViewClass === 'content-list'"
              class="search-filter-dropdown-menu__button-arrow">{{dropdownMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>

<!--  *ngIf="showFilters && dropdownMenu"-->
  <mat-menu class="removePaddingInMatMenu"
            #smallFilterMenu="matMenu"
            [overlapTrigger]="false"
            (closed)="dropdownMenu = false;">
    <app-search-filter-menu
      (click)="$event.stopPropagation()"
      [dropdownMenu]="dropdownMenu"
      [sideMenu]="false"
      [searchContainer]="searchContainer"></app-search-filter-menu>
  </mat-menu>

</div>
