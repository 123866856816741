import {SuperObjectModel} from './super-object-model';
import {OperationChangeState} from './operation-change-state';


export class OperationStepExecutionResult {
  queue_id: string;
  status: string;
  status_message: string;
  error_message: string;
  progress: number;
  result_object: any;
  result_object_ids: string[];
  selected_objects: SuperObjectModel[];
  target_state: OperationChangeState;
}
