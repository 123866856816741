<div class="operation-selector-vertical">
  <ul class="operation-selector-vertical__items"
      [ngClass]="{'smallScreen': smallScreen, 'edit': editContent}">
    <ng-container *ngFor="let operation of operationContainer.operations">
      <li class="operation-selector-vertical__item"
          *ngIf="!operation.hide_menu_bar"
          [ngClass]="{'active': operation.$$operationSelected}">
        <a class="operation-selector-vertical__item-content"
           [ngClass]="{'text-blue-hover': !editContent, 'disabled-operation': editContent}"
           [class.text-blue]="operation.$$operationSelected"
           [routerLink]="primusStateMapper.routerLinkFromState(currentState)"
           [queryParams]="primusStateMapper.queryParams({listName: operation.content_list})"
           queryParamsHandling="merge"
           (click)="setActiveOperation(operation)">
          <i class="{{operation.menu_icon}}"></i>
          <span>{{operation.menu_title | translate}}</span>
        </a>
        <div *ngIf="operation.$$operationSelected"
             class="operation-selector-vertical__vertical-line bg-blue"></div>
      </li>
    </ng-container>
  </ul>
  <div class="operation-selector-vertical__remaining-space"></div>
</div>

