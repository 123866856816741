<div class="search-result-pager-content-list">
  <a class="page-item"
     [ngClass]="{'disabled': contentListSource.displayAll || contentListSource.page === 1}"
     (click)="prevPage()">
    <span class="text-link" translate="TRANS__SEARCH_RESULT_PAGER__PREVIOUS"></span>
  </a>
  <a *ngIf="contentListSource.page > 5"
     class="page-item"
     (click)="setPage(1)">
    <span class="text-link">1</span>
    <span class="page-item-first-page">&hellip;</span>
  </a>
  <a class="page-item"
     *ngFor="let page of getPages()"
     [ngClass]="{'disabled': contentListSource.displayAll || contentListSource.page === page}"
     (click)="setPage(page)">
    <span [ngClass]="{'current-page': page === contentListSource.page}">{{page}}</span>
  </a>

  <a *ngIf="maxPage > endPage"
     class="page-item page-item-last-page"
     [ngClass]="{'disabled': contentListSource.displayAll || contentListSource.page === maxPage}"
     (click)="setLastPage()">
    <span class="last-page-ellipsis">&hellip;</span>
    <span class="text-link">{{maxPage}}</span>
  </a>
  <a class="page-item"
     [ngClass]="{'disabled': contentListSource.displayAll || contentListSource.page === maxPage}"
     (click)="nextPage()">
    <span class="text-link" translate="TRANS__SEARCH_RESULT_PAGER__NEXT"></span>
  </a>
  <div class="page-item"
       [ngClass]="{'disabled': contentListSource.displayAll || maxPage < 2}">
    <span>|</span>
  </div>

  <a class="page-item"
     (click)="toggleDisplayAll()"
     [ngClass]="{'disabled': maxPage < 2 && !contentListSource.displayAll}">
    <span *ngIf="!contentListSource.displayAll"
          class="text-link"
          translate="TRANS__SEARCH_RESULT_PAGER__DISPLAY_ALL"></span>
    <span *ngIf="contentListSource.displayAll"
          class="text-link"
          translate="TRANS__SEARCH_RESULT_PAGER__DO_NOT_DISPLAY_ALL"></span>
  </a>

</div>
