<div class="field-filter-container">

  <mat-form-field class="search-bar" appearance="outline" color="accent">
    <mat-label>{{ placeholder | translate }}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <!--suppress HtmlFormInputWithoutLabel -->
    <input matInput
           class="filter-input"
           [placeholder]="placeholder | translate"
           [formControl]="filterValueControl"
           autocomplete="off"/>
    <button mat-icon-button matSuffix type="button" (click)="clearFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="external-filter-content-container">
    <ng-content></ng-content>
  </div>

</div>
