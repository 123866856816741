import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// import {CommonsService} from '../../core/commons.service';

@Component({
  selector: 'app-search-collapse',
  templateUrl: './search-collapse.component.html',
  styleUrls: ['./search-collapse.component.scss']
})
export class SearchCollapseComponent implements OnInit {
  @Input() isEditing: boolean;
  @Output() toggleSearch = new EventEmitter<boolean>();

  private isSearch = false;

  constructor(/*private commons: CommonsService*/) {
  }

  ngOnInit() {

  }

  openSearch() {
    this.isSearch = !this.isSearch;
    this.toggleSearch.emit(this.isSearch);
    // if (this.isSearch) {
    //   window.addEventListener('mouseup', (item) => { this.callback(item); }, true);
    // } else {
    //   window.removeEventListener('mouseup', (item) => { this.callback(item); }, true);
    // }
  }

  // private callback(item) {
  //   const found = this.commons.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
  //   if (!found) {
  //     setTimeout(() => {
  //       this.isSearch = false;
  //       this.toggleSearch.emit(this.isSearch);
  //       window.removeEventListener('mouseup', (itm) => { this.callback(itm); }, true);
  //     }, 1000);
  //   }
  // }

}
