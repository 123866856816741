import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {

  @Input() icon: string;
  @Input() text: string;
  @Input() stylingClass: string;

  isVisible = false;

  constructor() {
  }

  ngOnInit() {
  }

}
