<app-m3-wrapper>

  <div>
    <a routerLink="/service" class="back-link">
      <mat-icon class="material-symbols-outlined">arrow_back</mat-icon>
      Tilbake til tjenester
    </a>
  </div>

  <h1 class="heading">Flagg</h1>
  <p class="sub-heading">Her kan du overstyre enkelte feature-flagg manuelt. Endringen gjelder kun på denne enheten.</p>

  <form [formGroup]="form">
    <fieldset formGroupName="experimental">
      <legend>Eksperimentelle funksjoner</legend>
      <div class="flag">
        <div>
          <mat-checkbox formControlName="useNewObjectPage">
            Ny objektside
          </mat-checkbox>
        </div>
        <span class="description">
        Bruk ny layout og design for objektsiden.
      </span>
      </div>
    </fieldset>

    <button mat-flat-button type="submit" (click)="saveFlags()">Lagre</button>
    <button mat-button type="button" (click)="resetFlags()">Tilbakestill alt</button>
  </form>

  <mat-list class="notifications">
    @for (notification of notifications(); track notification.id) {
      <mat-list-item>{{ notification.message }}</mat-list-item>
    }
  </mat-list>

</app-m3-wrapper>
