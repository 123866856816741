<ul class="search-result-view-list-selector list-thumbnail" *ngIf="searchContainer.searchResult">
  <li *ngFor="let art of searchContainer.searchResult.artifacts; index as $index"
      [ngClass]="{'disabled': art.$$used}"
      class="list-thumbnail__frame-and-shadow">
    <div class="list-thumbnail__item">
      <div class="list-thumbnail__row">

        <div class="list-thumbnail__first-container">
          <div *ngIf="!searchContainer.selections.singleSelect"
               class="checkbox-underlay"
               [ngClass]="{'disabled': art.$$used}">
            <mat-checkbox
              class="white-bg-in-checkbox"
              (click)="select(art, $event)"
              [checked]="searchContainer.selections.selected[art.artifact_id]"
              [disabled]="art.$$used">
            </mat-checkbox></div>
          <div *ngIf="searchContainer.selections.singleSelect"
               class="checkbox-underlay"
               [ngClass]="{'disabled': art.$$used}">
            <mat-radio-button
              class="white-bg-in-radio-button"
              [name]="art.artifact_id"
              [checked]="selectedItemId === art.artifact_id"
              (change)="select(art, $event)"
              [disabled]="art.$$used">
            </mat-radio-button>
          </div>

          <div class="thumbnail-url">
            <app-thumbnail
              *ngIf="art.thumbnail_id"
              [item]="art"
              [stylingClass]="'thumbnail-image thumbnail-fixed-height'"></app-thumbnail>
            <i *ngIf="!art.thumbnail_id" class="icon-image imgIcon"></i>
          </div>
        </div>

        <div class="list-thumbnail__second-container" [ngClass]="{'no-image': !art.$$imageUrl}">
          <app-search-columns-overview-fields
            class="list-thumbnail__object-overview overview-fields__selector"
            [searchObject]="art"
            [viewName]="searchContainer.searchResultViewName"
            [disableLinks]="true"
          ></app-search-columns-overview-fields>
        </div>

      </div>
    </div>
  </li>
</ul>
