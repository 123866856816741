<div class="search-result-pager-content-list">
  <a class="page-item"
     [ngClass]="{'disabled': searchContainer.displayAll || searchContainer.searchPage === 1}"
     (click)="prevPage()">
    <span class="text-link" translate="TRANS__SEARCH_RESULT_PAGER__PREVIOUS"></span>
  </a>
  <a *ngIf="searchContainer.searchPage > 5"
     class="page-item"
     (click)="setPage(1)">
    <span class="text-link">1</span>
    <span class="page-item-first-page">&hellip;</span>
  </a>
  <a class="page-item"
     *ngFor="let page of pages"
     [ngClass]="{'disabled': searchContainer.displayAll || searchContainer.searchPage === page}"
     (click)="setPage(page)">
    <span [ngClass]="{'current-page': page === searchPage}">{{page}}</span>
  </a>

  <a *ngIf="searchContainer.maxPage > endPage"
     class="page-item page-item-last-page"
     [ngClass]="{'disabled': searchContainer.displayAll || searchContainer.searchPage === searchContainer.maxPage}"
     (click)="setLastPage()">
    <span class="last-page-ellipsis">&hellip;</span>
    <span class="text-link">{{searchContainer.maxPage}}</span>
  </a>
  <a class="page-item"
     [ngClass]="{'disabled': searchContainer.displayAll || searchContainer.searchPage === searchContainer.maxPage}"
     (click)="nextPage()">
    <span class="text-link" translate="TRANS__SEARCH_RESULT_PAGER__NEXT"></span>
  </a>
  <div *ngIf="searchContainer.searchResultViewName !== SearchResultViewNames.SELECTOR"
       class="page-item"
       [ngClass]="{'disabled': searchContainer.displayAll || searchContainer.maxPage < 2}">
    <span>|</span>
  </div>

  <a *ngIf="searchContainer.searchResultViewName !== SearchResultViewNames.SELECTOR"
     class="page-item"
     (click)="toggleDisplayAll()"
     [ngClass]="{'disabled': searchContainer.maxPage < 2 && !searchContainer.displayAll}">
        <span *ngIf="!searchContainer.displayAll"
              class="text-link"
              translate="TRANS__SEARCH_RESULT_PAGER__DISPLAY_ALL"></span>
    <span *ngIf="searchContainer.displayAll"
          class="text-link"
          translate="TRANS__SEARCH_RESULT_PAGER__DO_NOT_DISPLAY_ALL"></span>
  </a>

</div>
