<div class="media-selector-general">
  <div class="media-selector-general__step-text bold" translate="TRANS__IMAGE_SELECTOR__STEP_1_HEADLINE">
  </div>
  <div class="media-selector-general__image-drop-zone"
       (drop)="onDropFile($event)"
       (dragover)="onDragOverFile($event)">
    <app-media-selector-selected-files
      class="full-width"
      [files]="files"
      (fileDeleted)="handleFileDeleted($event)"
      [showSelected]="false"
      [text]="'TRANS__IMAGE_SELECTOR__DRAG_AND_DROP_FILE_HERE'"
      [_snackBar]="snackBarSignal"
      [imageIdField]="'image_id'"> </app-media-selector-selected-files>
    <div class="media-selector-general__cameraOpen" *ngIf="cameraOpen">
      <h5 class="bold" translate="TRANS__IMAGE_SELECTOR__NEW_IMAGE_CAMERA"></h5>
      <app-media-upload-camera
        (uploadCameraImages)="uploadFiles($event)"
        [fileAccept]="fileAccept"> </app-media-upload-camera>
    </div>
    <div *ngIf="selectImg && parentMedia.length > 0"
         class="full-width">
      <app-media-selector-selected-files
        class="full-width"
        [selectedFiles]="files"
        [files]="parentMedia"
        [showSelected]="true"
        [text]="'TRANS__IMAGE_SELECTOR__CHOOSE_OBJECT_IMAGE'"
        [imageIdField]="'image_id'"
        (itemSelected)="onSelectedItem($event)">
      </app-media-selector-selected-files>
    </div>
  </div>
  <div class="media-selector-general__buttons">
    <button mat-stroked-button
            type="button"
            color="primary"
            (click)="uploadFile()">
      <label for="uploadFiles">
        <i class="icon-upload"></i>
        <span *ngIf="computer && multipleFiles"
              class="bold uppercase"
              [translate]="uploadInfo.text_upload"></span>
        <span *ngIf="!computer && multipleFiles"
              class="bold uppercase"
              [translate]="uploadInfo.text_upload_tablet"></span>
        <span *ngIf="computer && !multipleFiles"
              class="bold uppercase"
              [translate]="uploadInfo.text_upload_single"></span>
        <span *ngIf="!computer && !multipleFiles"
              class="bold uppercase"
              [translate]="uploadInfo.text_upload_single_tablet"></span>
      </label>
      <input type="file"
             (change)="onFileSelect($event)"
             id="uploadFiles"
             [attr.multiple]="multipleFiles? 'multiple' : undefined"
             [attr.accept]="fileAccept">
    </button>
  </div>

  <div *ngIf="computer && fromCamera" class="media-selector-general__buttons">
    <button mat-stroked-button
            color="primary"
            type="button"
            (click)="openCamera()"
            [ngClass]="{'active':cameraOpen}">
      <i class="icon-camera"></i>
      <span class="bold uppercase" [translate]="uploadInfo.text_from_camera"></span>
    </button>
  </div>

  <div class="media-selector-general__buttons"
       *ngIf="selectFromParent && parentMedia.length">
    <button mat-stroked-button
            color="primary"
            type="button"
            (click)="selectFiles()"
            [ngClass]="{'active':selectImg}">
      <i class="icon-select1"></i>
      <span class="bold uppercase" [translate]="uploadInfo.text_select_from_parent"></span>
    </button>
  </div>

</div>
