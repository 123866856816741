import {Component, Input} from '@angular/core';
import {ReferenceUsageInfo} from "../../core/search-reference.service";

@Component({
  selector: 'app-reference-usage',
  templateUrl: './reference-usage.component.html',
  styleUrls: ['./reference-usage.component.scss']
})
export class ReferenceUsageComponent {
  @Input() showReferencesAsLink: boolean;
  @Input() referenceUsageInfo: ReferenceUsageInfo;

  constructor() {
  }

  displayUsage = false;

  toggleDisplayUsage() {
    this.displayUsage = !this.displayUsage;
  }


}
