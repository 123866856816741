import {Injectable} from '@angular/core';
import {SearchContainer} from '../core/definitions/search-container';
import {SearchObject} from '../core/definitions/search-object';
import {ObjectTypes} from '../core/definitions/object-types';
import {SearchParameterService} from './search-parameter.service';
import {ResultViewService} from './result-view.service';
import {SearchParameters} from '../core/definitions/search-parameters';
import {SolrFilterService} from '../core/solr-filter.service';
import {SearchService} from "../core/search.service";

@Injectable({
  providedIn: 'root'
})
export class HierarchicSearchService {

  constructor(private searchParameterService: SearchParameterService,
              private searchService: SearchService,
              private resultViewService: ResultViewService,
              private solrFilter: SolrFilterService) {
  }

  async getChildren(searchContainer: SearchContainer, node: SearchObject): Promise<SearchObject[]> {
    let res = [];
    if (this.isHierarchicModel(node)) {
      const searchParams = await this.searchParameterService.getSearchParams(searchContainer);
      searchParams.rows = 1000;
      this.setHierarchicParentFilter(searchParams, node);
      const result = await this.searchService.searchWithOverview(searchParams);
      await this.resultViewService.setSearchResultItemProps(searchContainer, result.artifacts);
      res = result.artifacts;
    }
    return res;
  }

  private isHierarchicModel(searchObject: SearchObject) {
    // Hardcoding hierarchic object type check for now
    return [ObjectTypes.FOLDER.toString(), ObjectTypes.PLACE.toString()].indexOf(searchObject.object_type) !== -1;
  }

  private setHierarchicParentFilter(searchParams: SearchParameters, searchObject: SearchObject) {
    this.solrFilter.removeExisting(searchParams, '-parent_id');
    this.solrFilter.removeExisting(searchParams, 'level');
    this.solrFilter.addFq(searchParams, 'parent_id', searchObject.artifact_id);
    searchParams.query = '*';
  }

}
