import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {SearchObject} from '../core/definitions/search-object';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SearchExecutorService} from './search-executor.service';
import {SearchContainer} from '../core/definitions/search-container';

export class VirtualScrollSearchDataSourceList  implements DataSource<SearchObject> {
  private dataStream = new BehaviorSubject<SearchObject[]>([]);
  private subscription = new Subscription();

  constructor(private searchExecutorService: SearchExecutorService, private searchContainer: SearchContainer) {

  }

  connect(collectionViewer: CollectionViewer): Observable<SearchObject[] | ReadonlyArray<SearchObject>> {
    this.dataStream.next(this.searchContainer.cachedScrollDataList);
    this.subscription.add(collectionViewer.viewChange.subscribe(range => {
      this.searchExecutorService.runVirtualScrollSearch(this.searchContainer, range, false).then(hasSearchResult => {
        if (hasSearchResult) {
          this.dataStream.next(this.searchContainer.cachedScrollDataList);
        }
      });
    }));
    return this.dataStream;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }

}
