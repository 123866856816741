<div class="selected-files">
  <div class="selected-files__headline">
    <h5 class="bold" [translate]="text"></h5>
  </div>
  <div class="selected-files__file-container">

    <div *ngIf="isOverflow() && !hideLeftOverflowButton" class="overflown-button-left">
      <button (click)="scrollLeft()" class="overflown-button">
        <mat-icon>navigate_before</mat-icon></button>
    </div>

    <div class="selected-files__images" #selectedImgContainer>
      <i *ngIf="getFiles().length === 0" class="selected-files__image-icon icon-image"></i>
      <div *ngIf="getFiles().length >= 1" class="selected-files__image-container">
        <ng-container *ngFor="let file of getFiles(); index as $index">
          <div *ngIf="getFiles().length > 0" class="selected-files__image" [ngClass]="{'hideSelected': isChosen(file)}">
            <app-thumbnail
              class="selected-files__thumbnail-container"
              [ngClass]="{'active': showSelected && isSelectedFile($index)}"
              [item]="file"
              (click)="setFile(file)"
              [_snackBar]="_snackBar"
              [imageIdField]="imageIdField"
              [stylingClass]="'image-uploaded-edit'"></app-thumbnail>
            <ng-container *ngIf="!showSelected" >
              <a class="selected-files__image-delete"
                 (click)="deleteFile(file)">
                <i class="icon-trash"></i></a>
            </ng-container>
            <ng-container *ngIf="showSelected" >
              <a *ngIf="isSelectedFile($index)"
                 class="selected-files__image-selected">
                <mat-icon>check</mat-icon>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isOverflow() && !hideRightOverflowButton" class="overflown-button-right">
      <button (click)="scrollRight()" class="overflown-button">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="showSelected" class="selected-files__select-button-container">
    <span  class="selected-files__selected-items-text">
      <span>{{chosenItems.length}}</span>
      <span translate="TRANS__SELECT_IMAGES__CHOSEN"></span>
    </span>
    <button mat-flat-button color="accent" (click)="selectFile()" [disabled]="chosenItems.length === 0">
      <span class="bold uppercase" translate="TRANS__SELECT_IMAGES__CHOOSE_IMAGE"></span>
    </button>
  </div>
</div>
