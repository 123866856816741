import {SearchObject} from '../core/definitions/search-object';

export class SearchSelections {
  selected: { [name: string]: boolean } = {};
  allSelected = false;
  includeSelectedInFilters = false;
  selectedItems: SearchObject[] = [];
  originalItems: SearchObject[] = [];
  singleSelect = false;
  firstSelectedObject: SearchObject;
  selectCallback: any;
  selectItemCallback: any;
}
