<div class="search-view search-view-content-list" id="searchMainContent">

  <div class="object-content-list__top">
    <div class="object-content-list__search-query"
         [ngClass]="{'noLeftMargin': !contentListContainer.operationContainer}">
      <app-search-query
        *ngIf="contentListContainer.searchContainer.searchView"
        [searchContainer]="contentListContainer.searchContainer"
        [newLayout]="false"
        [useQueryChange]="false"
        (queryChange)="onContentChange($event)"
      ></app-search-query>
    </div>

    <div class="object-content-list__top-button-actions"
         [ngClass]="{'active': contentListContainer.searchContainer?.toggleActionMenu}">
      <div class="object-content-list__top-button"
           [ngClass]="{'disable': disabled}"
           *ngIf="contentListContainer.operationContainer">
        <app-search-menu-select-all
          class="object-content-list__select-all"
          [searchContainer]="contentListContainer.searchContainer"
        ></app-search-menu-select-all>

        <app-operation-menus-content-list
          class="object-content-list__menu-actions"
          [contentListContainer]="contentListContainer"
          [disabled]="disabled"
          (operationExecuted)="onContentChange($event)"
        ></app-operation-menus-content-list>
      </div>
    </div>

    <div class="object-content-list__top-button-sort">
      <app-dropdown-menu-animated
        [menuContainer]="{
         view: { description: 'TRANS__SEARCH__ORDER_ON', icon: 'sort'},
         contentList: true,
         disableContainer: contentListContainer.searchContainer.reportContainer,
         disableProp: 'show'}"
        class="object-content-list__top-button">
        <app-search-menu-sort
          [searchContainer]="contentListContainer.searchContainer"
        ></app-search-menu-sort>
      </app-dropdown-menu-animated>
    </div>
    <div class="object-content-list__top-button-filter">
      <app-search-filter-menu-small
        [searchContainer]="contentListContainer.searchContainer"
        [showFilters]="true"
      ></app-search-filter-menu-small>
    </div>
  </div>

  <div id="innerCon" class="search-view-content-list__container">
    <app-object-content-list-search-view
      [contentListContainer]="contentListContainer"
      (objectRefresh)="setObjectRefresh($event)">
      ></app-object-content-list-search-view>

    <app-search-result-pager-list
      [searchContainer]="contentListContainer.searchContainer"
    ></app-search-result-pager-list>
  </div>

</div>
