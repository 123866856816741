import { Injectable } from '@angular/core';
import {FeatureFlagsService} from "./feature-flags.service";
import {BaseModel} from "./definitions/base-model";

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private featureFlagsService: FeatureFlagsService) {

  }

  getDestroyFieldName() {
    if (this.featureFlagsService.getFeatureFlags().temporary.destroyFieldRenamed) {
      return 'destroy';
    } else {
      return '_destroy';
    }
  }

  setDestroy(obj: any, destroy?: any) {
    if (destroy === undefined) {
      destroy = true;
    }
    if (this.featureFlagsService.getFeatureFlags().temporary.destroyFieldRenamed) {
      obj.destroy = destroy;
    } else {
      obj['_destroy'] = destroy;
    }
  }

  deleteDestroy(obj: BaseModel) {
    if (this.featureFlagsService.getFeatureFlags().temporary.destroyFieldRenamed) {
      delete obj.destroy;
    } else {
      delete obj['_destroy'];
    }
  }


  getDestroy(obj: any) {
    if (this.featureFlagsService.getFeatureFlags().temporary.destroyFieldRenamed) {
      return obj.destroy;
    } else {
      return obj['_destroy'];
    }
  }

  setCreate(obj: any, create?: boolean) {
    if (create === undefined) {
      create = true;
    }
    if (this.featureFlagsService.getFeatureFlags().temporary.destroyFieldRenamed) {
      obj.create = create;
    } else {
      obj['_create'] = create;
    }
  }

  deleteCreate(obj: BaseModel) {
    if (this.featureFlagsService.getFeatureFlags().temporary.destroyFieldRenamed) {
      delete obj.create;
    } else {
      delete obj['_create'];
    }
  }

  getCreate(obj: any) {
    if (this.featureFlagsService.getFeatureFlags().temporary.destroyFieldRenamed) {
      return obj.create;
    } else {
      return obj['_create'];
    }
  }

}
