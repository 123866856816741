<div class="image-annotation">
  <div class="image-annotation__container">
    <div class="image-annotation__inner-container">
      <div class="image-annotation__content" id="annotate-view">
        <div class="image-annotation__inner-content"
             id="annotate-parent-container" *ngIf="curAnn">

          <app-annotation-canvas
            class="image-annotation__canvas-container"
            *ngIf="curAnn.annotateImage && curAnn.annotateImage.image_id"
            [curAnn]="curAnn"
            [parentContainerId]="'annotate-parent-container'"
            [edit]="edit"
            [isDialog]="false"
            [dashboardId]="'annotate-dashboard'"
          ></app-annotation-canvas>

          <form class="image-annotation__dashboard-container"
                name="annotateDashboardForm">
            <app-annotation-dashboard
              *ngIf="curAnn.parentObject"
              class="image-annotation__dashboard"
              [curAnn]="curAnn"
              [mediaList]="false"
              id="annotate-dashboard"
            ></app-annotation-dashboard>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
