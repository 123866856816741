import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {QueryField} from '../../../../core/definitions/advanced-search/query-field';
import {InputFieldType} from '../../../../core/definitions/advanced-search/input-options';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnChanges {

  /**
   * Time in ms that should elapse from the last keystroke until the event is emitted.
   * @type {number}
   */
  public static readonly COMMON_DEBOUNCE_TIME: number = 300;

  /**
   * The field to edit
   * @type {QueryField}
   */
  @Input() field: QueryField;

  /**
   * Whether or not to use free-text-search
   * @type {boolean}
   */
  @Input() useFreeTextSearch: boolean;

  /**
   * Emits the new search value as it should appear in the query
   * @type {EventEmitter<string>}
   */
  @Output() public readonly searchValueChanged: EventEmitter<string>;

  /**
   * constructor
   */
  constructor() {
    this.searchValueChanged = new EventEmitter<string>();
    this.useFreeTextSearch = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('field') &&
        changes.field.currentValue &&
        changes.field.currentValue !== changes.field.previousValue) {
      this.field = changes.field.currentValue;
    }

    if (changes.hasOwnProperty('useFreeTextSearch') && !changes.useFreeTextSearch.isFirstChange()) {
      this.handleSearchValueChanged(null);
    }

  }

  /**
   * Computes the field type to use for this field
   * @return {InputFieldType | "FREE_TEXT"}
   */
  get inputFieldType(): InputFieldType | 'FREE_TEXT' {
    return this.useFreeTextSearch ? 'FREE_TEXT' : (this.field?.inputOptions?.inputFieldType || 'UNSUPPORTED');
  }

  /**
   * Computes the label to use for the field
   * @return {string}
   */
  get label(): string {
    if (!this.field?.label) {
      return '';
    }
    if (this.field.secondaryLabel) {
      return `${this.field.label} (${this.field.secondaryLabel})`;
    }
    return this.field.label;
  }

  /**
   * Emits a new search value
   * @param {string | null} value
   */
  handleSearchValueChanged(value: string | null): void {
    this.searchValueChanged.emit(value ?? '');
  }

}
