import {Component, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {AnnotationService} from '../annotation.service';
import {CommonsService} from '../../core/commons.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {AnnotationHandler} from '../annotation-handler';
import {ImageItem} from '../../core/definitions/image-item';
import {SuperObjectModel} from '../../core/definitions/super-object-model';

@Component({
  selector: 'app-image-annotation',
  templateUrl: './image-annotation.component.html',
  styleUrls: ['./image-annotation.component.scss']
})
export class ImageAnnotationComponent implements OnInit {

  edit: boolean;
  curAnn: AnnotationHandler;

  private parentObject: SuperObjectModel;

  constructor(private readonly primusRoute: PrimusRouteService,
              private readonly annotationService: AnnotationService,
              private readonly commons: CommonsService,
              private readonly currentObjectService: CurrentObjectService) {
  }

  ngOnInit() {
    this.curAnn = this.currentObjectService.curAnn;
    this.edit = this.primusRoute.params.edit === 'true';
    const imageId = this.primusRoute.params.imageId;
    const annotationId = this.primusRoute.params.annotationId;
    if (this.curAnn.parentObject) {
      this.parentObject = this.curAnn.parentObject;
    } else {
      // This will probably never happen
      if (this.primusRoute.params.parentId) {
        this.parentObject = {
          artifact_id: this.primusRoute.params.parentId,
          object_type: this.primusRoute.params.parentObjectType,
        } as SuperObjectModel;
      } else {
        console.warn('Missing parent id');
        // this.parentId = this.contextIds[0];
      }
    }
    const annotateTarget = {} as SuperObjectModel;
    const annotateImage = new ImageItem();
    annotateImage.image_id = imageId;

    this.curAnn.closeCallback = (curAnn: AnnotationHandler) => {
      if (curAnn.annotations && curAnn.annotations.length) {
        this.saveCloseAnnotation(curAnn);
      } else {
        this.closeAnnotation();
      }
    };

    this.annotationService.setCurAnnotation(
      this.curAnn,
      annotateTarget,
      annotateImage,
      this.parentObject,
      annotationId,
      true).then();
  }

  private saveCloseAnnotation(curAnn: AnnotationHandler) {
    this.annotationService.saveAnnotations(curAnn).then(() => {
      window.history.back();
    });
  }

  private closeAnnotation() {
    window.history.back();
  }


}
