<div class="container">

  <mat-nav-list>

    <mat-tree [dataSource]="items" [treeControl]="menuTreeControl">

      <mat-tree-node *matTreeNodeDef="let menuItem" class="menu-item-node">
        @if (menuItem.disabled) {
          <a class="menu-item" disabled mat-list-item routerLinkActive="active">
            <div class="flex gap-4 items-center font-bold">
              @if (menuItem.icon) {
                <mat-icon class="material-symbols-outlined">{{ menuItem.icon }}</mat-icon>
              }
              <span class="ellipse-text">{{ menuItem.label() }}</span>
            </div>
          </a>
        } @else {
          <a class="menu-item" mat-list-item [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" #activeLink="routerLinkActive" queryParamsHandling="merge">
            <div class="flex gap-4 items-center font-bold">
              @if (menuItem.icon) {
                <mat-icon class="material-symbols-outlined">{{ menuItem.icon }}</mat-icon>
              }
              <span class="ellipse-text">{{ menuItem.label() }}</span>
            </div>
          </a>
        }
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let menuItem; when: hasChild" class="menu-item-node">
        <button mat-list-item matTreeNodeToggle class="menu-item flex" [disabled]="menuItem.disabled">
          <div matListItemTitle class="flex">
            <div class="flex flex-1 gap-4 items-center">
              @if (menuItem.icon) {
                <mat-icon class="material-symbols-outlined">{{ menuItem.icon }}</mat-icon>
              }
              <span class="ellipse-text">{{ menuItem.label() }}</span>
            </div>
            <mat-icon class="material-symbols-outlined">
              @if (menuTreeControl.isExpanded(menuItem)) {
                keyboard_arrow_down
              } @else {
                keyboard_arrow_up
              }
            </mat-icon>
          </div>
        </button>
        @if (menuTreeControl.isExpanded(menuItem)) {
          <div role="group" class="font-weight-normal">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        }
      </mat-nested-tree-node>

    </mat-tree>

  </mat-nav-list>

</div>
