import {Component, OnDestroy, OnInit} from '@angular/core';
import {OperationTarget} from "../../core/definitions/operation-target.enum";
import {OperationService} from "../../operations/operation.service";
import {OperationContainer} from "../../core/definitions/operation-container";
import {OperationDef} from "../../core/definitions/operation-def";
import {OperationDialogService} from "../../operations/operation-dialog.service";
import {ObjectTypes} from "../../core/definitions/object-types";
import {SuperObjectModel} from "../../core/definitions/super-object-model";
import {UploadImageResult, UploadService, UploadWorkerMessage} from "../../core/upload.service";
import {UiToolsService} from "../../core/ui-tools.service";
import {PrimusRouterService} from "../../core/primus-router.service";
import {CmsApiService} from "../../core/cms-api.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-admin-mass-media-import',
  templateUrl: './admin-mass-media-import.component.html',
  styleUrls: ['./admin-mass-media-import.component.scss']
})
export class AdminMassMediaImportComponent implements OnInit, OnDestroy {
  constructor(private primusRouter: PrimusRouterService,
              private translate: TranslateService,
              private operationService: OperationService,
              private operationDialogService: OperationDialogService,
              private uploadService: UploadService,
              private uiTools: UiToolsService,
              private cms: CmsApiService) {
  }

  preOperationContainer: OperationContainer;
  mediaFolder: SuperObjectModel;
  computer: boolean;
  uploadProgress: number;
  displayedColumns: string[] = ['fileName', 'ok', 'status', 'statusText', 'uploadStatus'];
  lastUploadWorkerResult: UploadWorkerMessage;
  importButtonDisabled = false;
  preOpButtonsDisabled = false;
  parent: any;

  private uploadStatusCheckRunning = false;
  private uploadStatusCheckQueue: {[name: string]: UploadImageResult} = {};
  private maxUploadStatusChecks = 60;
  private uploadStatusTimeoutId: number;


  ngOnInit(): void {
    this.parent = {
      adminType: 'admin',
      hasSubMenus: false,
      currentState: this.primusRouter.currentState()
    };
    this.computer = this.uiTools.getClientType().isComputer;
    const lastUploadWorkerResult = this.uploadService.getLastUploadWorkerResult();
    if (lastUploadWorkerResult) {
      this.lastUploadWorkerResult = lastUploadWorkerResult;
      this.mediaFolder = this.uploadService.getLastMediaFolder();
    }
    this.createOperationContainers().then();
    if (this.uploadService.hasActiveUploadWorker()) {
      this.importButtonDisabled = true;
      this.preOpButtonsDisabled = true;
      this.uploadService.setUploadWorkerCallback((data: UploadWorkerMessage) => {
        this.workerMessageCallback(data);
      });
    }
  }

  ngOnDestroy() {
    if (this.uploadStatusTimeoutId) {
      clearTimeout(this.uploadStatusTimeoutId);
    }
  }

  async openPreProdOperation(operation: OperationDef) {
    this.operationService.setCurrentOperation(this.preOperationContainer, operation);
    const operationResult = await this.operationDialogService.openOperationDialog(this.preOperationContainer);
    let folderObject: SuperObjectModel;
    if (operationResult.resultObject?.object_type === ObjectTypes.FOLDER) {
      folderObject = operationResult.resultObject;
    } else if (operationResult.resultObject?.object_type === 'SelectMediaFolderOperationModel'
      && operationResult.resultObject.folder_id) {
      folderObject = await this.cms.getArtifact({
        artifact_id: operationResult.resultObject.folder_id, suppressErrHandler: true});
    }
    if (folderObject) {
      this.mediaFolder = folderObject;
      this.importButtonDisabled = false;
      this.lastUploadWorkerResult = null;
      this.uploadProgress = 0;
    }
  }

  onFileSelect(event: any) {
    this.preOpButtonsDisabled = true;
    this.importButtonDisabled = true;
    this.uploadService.uploadFilesWithWorker(event.target.files, this.mediaFolder, (data: UploadWorkerMessage) => {
      this.workerMessageCallback(data);
    });
  }

  private workerMessageCallback(data: UploadWorkerMessage) {
    if (data.progress) {
      this.uploadProgress = data.progress;
    }
    this.lastUploadWorkerResult = data;
    if (data.uploadStatus === 'uploadFinished' || data.uploadStatus === 'failed') {
      this.preOpButtonsDisabled = false;
      this.importButtonDisabled = false;
    }
    this.setDefaultUploadStatusText();
    for (const image of data.images) {
      if (image.status === 200 && !this.uploadStatusCheckQueue[image.imageId]) {
        this.uploadStatusCheckQueue[image.imageId] = image;
      }
    }
    if (Object.keys(this.uploadStatusCheckQueue) && !this.uploadStatusCheckRunning) {
      this.checkUploadStatus().then();
    }
  }


  private async createOperationContainers() {
    this.preOperationContainer = await this.operationService.createOperationContainer(
      OperationTarget.OPERATION_TARGET_MASS_MEDIA_IMPORT_PRE_OPS_VIEW);
    await this.operationService.setOperations(this.preOperationContainer);
  }

  private async checkUploadStatus() {
    this.uploadStatusCheckRunning = true;
    let mustCheckStatus = false;
    this.setDefaultUploadStatusText();
    for (const imageId of Object.keys(this.uploadStatusCheckQueue)) {
      const image = this.lastUploadWorkerResult.images.find(image => image.imageId === imageId);
      if (image.uploadStatus === 'done') {
        continue;
      }
      image.uploadStatus = await this.cms.getUploadStatus({artifact_id: image.imageId});
      image.uploadStatusText = this.getUploadStatusText(image.uploadStatus);
      if (image.uploadStatus === 'done' || image.uploadStatusCounter++ >= this.maxUploadStatusChecks) {
        if (image.uploadStatusCounter >= this.maxUploadStatusChecks) {
          image.uploadStatusText = this.translate.instant('TRANS__UPLOAD_STATUS__STOPPED_CHECKING')
        }
      } else {
        mustCheckStatus = true;
      }
    }
    if (mustCheckStatus) {
      this.redoUploadStatusCheck();
    } else {
      this.uploadStatusCheckRunning = false;
    }
  }

  private setDefaultUploadStatusText() {
    for (const image of this.lastUploadWorkerResult.images) {
      if (image.status === 200 && image.uploadStatusText === undefined) {
        image.uploadStatusText = this.translate.instant('TRANS__UPLOAD_STATUS__CHECKING_UPLOAD_STATUS');
      }
    }
  }

  private redoUploadStatusCheck() {
    if (this.uploadStatusTimeoutId) {
      clearTimeout(this.uploadStatusTimeoutId);
    }
    this.uploadStatusTimeoutId = setTimeout(() => {
      this.checkUploadStatus().then();
    }, 1000);
  }

  private getUploadStatusText(status: string) {
    let res = status;
    const statusTransKey = {
      'init': 'TRANS__UPLOAD_STATUS__INIT',
      'convert': 'TRANS__UPLOAD_STATUS__CONVERTING',
      'done': 'TRANS__UPLOAD_STATUS__DONE',
    }[status];
    if (statusTransKey) {
      res = this.translate.instant(statusTransKey);
    }
    return res;
  }
}
