import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParserService {

  parse(query: string, addWildCards: boolean, replaceFromString?: string, replaceWithString?: string) {
    let res = '';
    addWildCards = addWildCards !== undefined ? addWildCards : true;
    if (query) {
      const qTrim = query.trim();
      if (qTrim[0] === '"' && qTrim[qTrim.length - 1] === '"') {
        return qTrim.substring(1, qTrim.length - 1);
      }
      const hasQuotes = ['"', '\''].indexOf(qTrim.substring(qTrim.length - 1)) !== -1;
      res = this.splitAndCombineQuery(query, hasQuotes, addWildCards);
      if (replaceFromString && replaceWithString) {
        res = res.replaceAll(replaceFromString, replaceWithString)
      }
      res = res.trim();
      if (addWildCards && res.split(' ').length === 1 && !hasQuotes) {
        res = '*' + res;
      }
    } else if (addWildCards) {
      res = '*:*';
    }
    return res;
  }

  private splitAndCombineQuery(query: string, hasQuotes: boolean, addWildCards: boolean) {
    let res = '';
    let spc = '';
    const qSplit = this.cleanSplit(query);
    if (qSplit.length > 0) {
      for (let t = 0; t < qSplit.length - 1; t++) {
        res += spc + qSplit[t];
        spc = ' ';
      }
      const last = this.clean(qSplit[qSplit.length - 1]);
      res += spc + last;
      res = res.trim();
      if (!hasQuotes && addWildCards) {
        res += '*';
      }
    }
    return res;
  }

  private cleanSplit(query) {
    return query.split(/[\s\/()\-+:«»;?,."]/g).filter(q => q !== '' && q !== ' ');
  }

  private clean(query: string) {
    return query.replace(/[\^\[\]{}]/g, '').trim();
  }

}
