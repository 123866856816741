<mat-card appearance="outlined" [ngClass]="{'light-bg-blue': editModeEnabled, 'section-card': !disableOverflow}">

  <mat-card-title class="section-card__title bg-white">
    <span class="section-card__title-heading">{{ heading | translate }}</span>

    <button *ngIf="showEditButton && !editModeEnabled" mat-button
            type="button"
            color="primary"
            (click)="handleToggleEditMode()"
            [disabled]="anyOtherSectionInEditMode">
      <mat-icon matPrefix>edit</mat-icon>
      <span translate="TRANS__PROFILE__EDIT_SECTION"></span>
    </button>
    <div class="section-card__add-button">
      <button *ngIf="showEditButton && name === 'collections'" mat-button
              type="button"
              [disabled]="editModeEnabled"
              (click)="addCollectionRights(name)">
        <mat-icon>add</mat-icon>
        <span translate="TRANS__PROFILE__ADD_COLLECTION_RIGHTS"></span>
      </button>
      <button *ngIf="showEditButton && name === 'virtual_collections'" mat-button
              type="button"
              [disabled]="editModeEnabled"
              (click)="addCollectionRights(name)">
        <mat-icon>add</mat-icon>
        <span translate="TRANS__PROFILE__ADD_VIRTUAL_COLLECTION_RIGHTS"></span>
      </button>
    </div>
  </mat-card-title>

  <mat-card-content class="section-card__content bg-white">
    <ng-content></ng-content>
  </mat-card-content>

  <mat-card-actions *ngIf="editModeEnabled" class="button-container bg-white">
    <button mat-flat-button
            class="button-container__button"
            color="primary"
            type="button"
            (click)="handleSaveAndAbort(saveClicked)">
      <span class="bold uppercase">{{ 'TRANS__PROFILE__SAVE_BUTTON_LABEL' | translate }}</span>
    </button>
    <button mat-button
            class="button-container__button"
            color="primary"
            type="button"
            (click)="handleSaveAndAbort(abortClicked)">
      <span class="text-dark-primary bold uppercase">{{ 'TRANS__PROFILE__CANCEL_BUTTON_LABEL' | translate }}</span>
    </button>
  </mat-card-actions>
</mat-card>
