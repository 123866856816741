import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {OptionsService} from "../../../core/options.service";
import {BaseModel} from "../../../core/definitions/base-model";
import {CrudService} from "../../../core/crud.service";

@Component({
  selector: 'app-field-select-selected-options',
  templateUrl: './edit-field-select-selected-options.component.html',
  styleUrls: ['./edit-field-select-selected-options.component.scss']
})
export class EditFieldSelectSelectedOptionsComponent implements OnChanges {
  constructor(private optionsService: OptionsService,
              public crud: CrudService) {
  }

  valueProp: string;

  @Input() fieldParameters: FieldParameters;
  @Output() optionUnChecked = new EventEmitter<object>();

  arrayObject: BaseModel;
  arrayName: string;

  ngOnChanges() {
    [this.arrayObject, this.arrayName] = this.optionsService.getArrayObjectAndArrayName(this.fieldParameters);
    const fieldInfo = this.fieldParameters.field;
    if (fieldInfo.inline.unique_props?.length) {
      this.valueProp = '$$' + fieldInfo.inline.unique_props[0] + '_value';
    } else {
      console.log('No unique props!');
    }
  }

  unCheckOption(option) {
    if (option['artifact_id'] == null) {
      const prop = this.fieldParameters.field.inline.prop;
      option['artifact_id'] = option[prop];
    }
    this.optionUnChecked.emit(option);
  }
}
