<mat-form-field>
  <mat-label>Velg felt</mat-label>
  <input type="text"
         placeholder="Velg felt"
         aria-label="Felt"
         matInput
         [formControl]="selectFieldControl"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectField($event)" [displayWith]="displayFn">
    <mat-option *ngFor="let field of filteredFields"
                [value]="field">{{field.field_title}}</mat-option>
  </mat-autocomplete>
</mat-form-field>
