import { Injectable } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrimusRouteService {

  constructor(private route: ActivatedRoute) { }

  get params(): any {
    return this.route.snapshot.queryParamMap['params'];
  }
}
