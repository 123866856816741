<div class="admin-id-format-edit-section-wrapper">
    <app-admin-id-format-filter
      [heading]="heading"
      [description]="description"
      [actions]="actions"
      [actionDisplayProperty]="'name'"
      (actionClicked)="handleAction($event)"
      (filterChanged)="onFilterStringChanged($event)">
    </app-admin-id-format-filter>

    <app-primus-table
      class="admin-id-format-edit-section-wrapper__primus-table"
      [columns]="columns"
      [pageSize]="50"
      [data]="formats"
      [filterString]="currentFilterString"
      [disableSelection]="!actions || actions.length === 0"
      [selectable]="true"
      [editable]="true"
      [rowActions]="true"
      [actionButtonName]="'TRANS__ADMIN__ID_FORMAT__ADD_PREFIX_BUTTON_LABEL'"
      [actionButtonDisabled]="!canAddPrefixes"
      (addNewRowClicked)="handleAddPrefixClicked()"
      (selectionChanged)="handleSelectionChanged($event)"
      (editClicked)="handleEditPrefixClicked($event)">
    </app-primus-table>

</div>
