import {Injectable} from '@angular/core';
import {FieldParameters} from '../core/definitions/field-parameters';
import {Option} from '../core/definitions/option-info';
import {EditObjectDialogComponent} from './edit-object-dialog/edit-object-dialog.component';
import {
  CultureHubSearchDialogComponent
} from '../shared/culture-hub-search-dialog/culture-hub-search-dialog.component';
import {AlertDialogComponent} from '../shared/alert-dialog/alert-dialog.component';
import {ConfirmDialogData} from './confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MetaField} from '../core/definitions/meta-field';
import {CultureHubParams} from '../core/definitions/culture-hub-params';
import {SearchReferenceService} from "../core/search-reference.service";
import {EditObjectDialogData} from "./edit-object-dialog-data";
import {EditObjectChildDialogComponent} from "./edit-object-child-dialog/edit-object-child-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class OptionsDialogService {

  constructor(private modalService: MatDialog,
              private searchReferenceService: SearchReferenceService) {
  }

  async createOption(fieldParameters: FieldParameters, text?, parentId?): Promise<Option> {
    if (!fieldParameters.sectionsContainer?.isSecondDialog) {
      return this.createOptionMain(fieldParameters, text, parentId);
    } else {
      return this.createOptionChild(fieldParameters, text, parentId);
    }
  }

  searchCultureHub(field: MetaField, multiImport: boolean, phase: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      const reference = this.searchReferenceService.getSearchReferenceFromField(field);
      const data = {
        phrase: phase,
        entity_type: reference.kulturnav_params.entity_type,
        concept_type_id: reference.kulturnav_params.concept_type_id,
        sub_type_headline: reference.kulturnav_params.sub_type_headline,
        sub_type: reference.kulturnav_params.sub_type,
        multi_import: multiImport
      } as CultureHubParams;
      const modalRef = this.modalService.open(CultureHubSearchDialogComponent, {
        panelClass: ['edit-dialog', 'ignoreClicksModal-' + field.name],
        data: data
      });
      modalRef.afterClosed().subscribe(result => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }

  toggleDescription(option: Option) {
    option.$$showDescription = true;
    const description = option.$$description;
    const title = option.$$name;
    const modalRef = this.modalService.open(AlertDialogComponent, {
      panelClass: 'alert-dialog',
      data: {
        modalContent: description,
        modalTitle: title
      } as ConfirmDialogData
    });
    modalRef.afterClosed().subscribe(() => {
      // setTimeout(() => {
      option.$$showDescription = false;
      // }, 300);
    });
  }

  private createOptionMain(fieldParameters: FieldParameters, text?, parentId?): Promise<Option> {
    return new Promise((resolve, reject) => {
      const modalRef = this.modalService.open(EditObjectDialogComponent,
        this.getEditObjectDialogConfig(fieldParameters, text, parentId));
      modalRef.afterClosed().subscribe(result => {
        if (fieldParameters.sectionsContainer) {
          fieldParameters.sectionsContainer.isDialog = !!fieldParameters.sectionsContainer?.isSecondDialog;
          fieldParameters.sectionsContainer.isSecondDialog = false;
        }
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }

  private createOptionChild(fieldParameters: FieldParameters, text?, parentId?): Promise<Option> {
    return new Promise((resolve, reject) => {
      const modalRef = this.modalService.open(EditObjectChildDialogComponent,
        this.getEditObjectDialogConfig(fieldParameters, text, parentId));
      modalRef.afterClosed().subscribe(result => {
        if (fieldParameters.sectionsContainer) {
          fieldParameters.sectionsContainer.isDialog = !!fieldParameters.sectionsContainer?.isSecondDialog;
          fieldParameters.sectionsContainer.isSecondDialog = false;
        }
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }

  private getEditObjectDialogConfig(fieldParameters: FieldParameters, text, parentId?) {
    return {
      disableClose: true,
      panelClass: 'edit-dialog',
      data: {
        fieldParameters: fieldParameters,
        secondDialogOpen: fieldParameters.sectionsContainer?.isSecondDialog,
        createText: text,
        parentId: parentId
      } as EditObjectDialogData
    }
  }

}
