import { Injectable } from '@angular/core';
import {ConceptsContainer} from '../../core/definitions/concepts-container';
import {MetaOperationDef} from '../../core/definitions/meta-operation-def';
import {ConceptDialogService} from './concept-dialog.service';
import {MetaOperationService} from '../../core/meta-operation.service';
import {ConceptMenu} from './concept-menu';
import {ConceptJoinInfo} from '../../core/definitions/concept-join-info';

@Injectable({
  providedIn: 'root'
})
export class ConceptMetaOperationService {

  constructor(private conceptDialogService: ConceptDialogService,
              private metaOperationService: MetaOperationService) { }

  async getOperations(conceptsContainer: ConceptsContainer): Promise<Array<ConceptMenu>> {
    const res = [];
    const operationData = await this.metaOperationService.getOperationsByObjectType(conceptsContainer.conceptType.concept_type_id);
    for (let t = 0; t < operationData.operations.length; t++) {
      const operation = operationData.operations[t];
      const conceptMenu: ConceptMenu = {
        title: operation.operation_title,
        icon: operation.icon,
        action: this.getConceptMenuActionFromOperation(conceptsContainer, operation),
        disabled: (() => {
          return operation.minimum_items ? conceptsContainer.selected.length < operation.minimum_items : false;
        })
      };
      res.push(conceptMenu);
    }
    return res;
  }

  public executeJoinConcepts() {

  }

  private getConceptMenuActionFromOperation(conceptsContainer: ConceptsContainer, operation: MetaOperationDef) {
    return () => {
      const operationsContainer = this.metaOperationService.createMetaOperationContainer(operation);
      const metaOperation = operationsContainer.metaOperation;
      conceptsContainer.operationContainers.push(operationsContainer);
      if (operation.operation_name === 'join_concepts') {
        this.conceptDialogService.openJoinConcepts(conceptsContainer, (conceptJoinInfo: ConceptJoinInfo) => {
          metaOperation.operation_params.forEach(operationParam => {
            if (operationParam.param_name === 'winner') {
              operationParam.value = conceptJoinInfo.winner;
            } else if (operationParam.param_name === 'losers') {
              operationParam.value = conceptJoinInfo.losers;
            }
          });
          this.metaOperationService.validateAndExecuteOperation(operationsContainer).then();
        });
      } else {
        if (metaOperation.requires_items) {
          metaOperation.operation_items = conceptsContainer.selected.map(sel => sel.artifact_id);
        }
        metaOperation.operation_params.forEach(operationParam => {
          if (operationParam.param_name === 'concept_type_id') {
            operationParam.value = conceptsContainer.conceptType.concept_type_id;
          }
        });
        this.metaOperationService.validateAndExecuteOperation(operationsContainer).then();
      }
    };
  }


}
