import {SearchContainer} from '../../../core/definitions/search-container';
import {CheckFilter, SearchViewMenu} from '../../../core/definitions/search-objects';

export type FilterChip = MenuFilterChip | CheckfilterChip;

type BaseType = {
  key: string;
  title?: string;
  noTransTitle?: string;
};

type MenuFilterChip = BaseType & {
  type: "searchMenu";
  menu: SearchViewMenu;
}

type CheckfilterChip = BaseType & {
  type: 'checkFilter';
  filter: CheckFilter;
}

export function searchContainerToChipList(searchContainer: SearchContainer): FilterChip[] {
  const result: FilterChip[] = [];

  if (searchContainer.currentPathView?.search_view?.menus?.length) {
    for (const menuFilter of searchContainer.currentPathView.search_view.menus) {
      if (menuFilter.checked) {
        result.push({
          key: `search-menu-${menuFilter.title}-${menuFilter.path}`,
          type: 'searchMenu',
          title: menuFilter.title,
          menu: menuFilter
        })
      }
    }
  }

  if (searchContainer.filtersFacets?.filterGroups?.length) {
    for (const filterGroup of searchContainer.filtersFacets.filterGroups) {
      if (filterGroup?.checkFilters?.length) {
        for (const checkFilter of filterGroup.checkFilters) {
          if (checkFilter.checked) {
            result.push({
              key: `check-filter-${filterGroup.title}-${checkFilter.title}-${checkFilter.noTransTitle}`,
              type: 'checkFilter',
              title: checkFilter.title,
              noTransTitle: checkFilter.noTransTitle,
              filter: checkFilter
            })
          }
        }
      }
    }
  }

  return result;
}
