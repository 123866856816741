@if (!simple) {
  <a class="template-group-select-button"
     [ngClass]="{
   'collapseOnSmallScreen': collapseOnSmallScreen,
   'hasTemplate': templateGroupId,
   'active': templateSelectorContainer && templateSelectorContainer.selectorEnabled,
   'disable-button': disabled || locked}"
     (click)="enableTemplateSelector()">
    <div class="template-group-select-button__template-icon">
      <i class="icon-template"></i>
    </div>
    <div class="template-group-select-button__template-vertical-line"></div>
    <div class="template-group-select-button__text">
      <span translate="TRANS__ARTIFACT__CHOOSE_TEMPLATE_GROUP"></span>
      <span class="template-group-select-button__selected-template-text">
        @if (!templateGroupId) {
          <span translate="TRANS__ARTIFACT__NO_SELECTED_TEMPLATE_GROUP"></span>
        } @else {
          <app-object-name
            [object]="{artifact_id: templateGroupId}"
            class="template-text-blue"></app-object-name>
        }
    </span>
    </div>
  </a>
} @else {
  <button mat-stroked-button
          color="primary"
          (click)="enableTemplateSelector()"
          class="template-group-select-button__simple"
          [disabled]="disabled">
    <mat-icon>search</mat-icon>
    <span translate="TRANS__TEMPLATE_GROUP__SEARCH_FOR_TEMPLATE_GROUP"></span>
  </button>
}
