import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectMediaType} from '../../core/definitions/object-media-container';

@Component({
  selector: 'app-thumbnail-carousel-nav-button',
  templateUrl: './thumbnail-carousel-nav-button.component.html',
  styleUrls: ['./thumbnail-carousel-nav-button.component.scss']
})
export class ThumbnailCarouselNavButtonComponent implements OnInit {

  @Input() direction;
  @Input() mediaType: ObjectMediaType;
  @Input() disable: boolean;
  @Output() setActiveMediaIndex = new EventEmitter<object>();

  nextPrevMediaType = false;

  constructor() {
  }

  ngOnInit() {
  }

  setCurrentMedia() {
    if (this.direction === 'next') {
      this.mediaType.currentMediaIndex = this.nextMedia();
    } else {
      this.mediaType.currentMediaIndex = this.previousMedia();
    }
    const mediaTypeIndex = {
      index: this.mediaType.currentMediaIndex,
      nextPrevMediaType: this.nextPrevMediaType
    };
    this.setActiveMediaIndex.emit(mediaTypeIndex);
  }

  private previousMedia() {
    let index = this.mediaType.currentMediaIndex - 1;
    this.nextPrevMediaType = false;
    if (index < 0) {
      this.nextPrevMediaType = true;
      index = this.mediaType.mediaElements.length - 1;
    }
    return index;
  }

  private nextMedia() {
    let index = this.mediaType.currentMediaIndex + 1;
    this.nextPrevMediaType = false;
    if (index >= this.mediaType.mediaElements.length) {
      this.nextPrevMediaType = true;
      index = 0;
    }
    return index;
  }
}
