<div class="container" [ngClass]="{'darken': darken}">
  <div class="container__header">
    <div class="container__header__title">
      <span>{{'TRANS__ADVANCED_SEARCH__SEARCH_IN' | translate}} {{advancedFieldGroup.header | translate}} {{'TRANS__ADVANCED_SEARCH__WHERE' | translate}}</span>
      <button mat-button class="container__header__operator-button" color="primary" [matMenuTriggerFor]="operatorMenu">
        <span class="container__header__operator-operatortext" [translate]="getOperatorDisplayLabel()"></span>
        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
      </button>
      <span translate="TRANS__ADVANCED_SEARCH__THESE_RULES"></span>
    </div>

    <div class="container__header__operator">
      <mat-icon
        class="container__header__title-icon"
        matTooltip="Her kan du søke i utvalgte felter. Felter som står tomme blir ignorert av søket"
        matTooltipPosition="below"
      >
        help_outline
      </mat-icon>

      <button mat-icon-button class="container__header__operator-button" (click)="removeSelf.emit(advancedFieldGroup)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="container__group">
    @for (field of advancedFieldGroup.field_queries; track field; let first = $first) {
      <div class="container__group__fields">
        <app-advanced-search-query-builder-field
          class="container__group__fields-field"
          [field]="field"
          [parentGroup]="group"
          [rootGroup]="rootGroup"
          [searchContainer]="searchContainer"
          [triggerUpdateFields]="toggleUpdateFieldSelector"
          (removeSelf)="removeField($event)"
          (updateSelf)="updateField($event, field, first)"
        ></app-advanced-search-query-builder-field>
      </div>
    }

    @for (group of advancedFieldGroup.sub_groups; track group; let first = $first) {
      <app-advanced-search-query-builder-group
        class="container__group__subgroups"
        (removeSelf)="removeGroup($event)"
        (updateSelf)="updateGroup($event, group)"
        [group]="group"
        [rootGroup]="rootGroup"
        [darken]="!darken"
        [searchContainer]="searchContainer"
        [toggleUpdateFieldSelector]="toggleUpdateFieldSelector"
      ></app-advanced-search-query-builder-group>
    }

    <div class="container__group__buttons">
      <button mat-button color="primary" class="container__group__buttons-button" [matMenuTriggerFor]="addMenu">
        <span class="container__group__buttons-buttontext" translate="TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__ADD"></span>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu class="add" #addMenu="matMenu" xPosition="after" yPosition="above">
  <button mat-menu-item translate="TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__RULE" class="add-button" (click)="addField()">
  </button>
  @if (advancedFieldGroup.level < 1) {
    <button mat-menu-item translate="TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__SUBGROUP" class="add-button" (click)="addGroup()">
    </button>
  }
</mat-menu>

<mat-menu class="operator" #operatorMenu="matMenu" xPosition="after" yPosition="below">
  <button mat-menu-item (click)="updateFieldLogicalOperator('AND')">
    {{'TRANS__OPERATOR__AND__DISPLAY' | translate}}
  </button>

  <button mat-menu-item (click)="updateFieldLogicalOperator('OR')">
    {{'TRANS__OPERATOR__OR__DISPLAY' | translate}}
  </button>

  <button mat-menu-item (click)="updateFieldLogicalOperator('NOT')">
    {{'TRANS__OPERATOR__NOT__DISPLAY' | translate}}
  </button>
</mat-menu>
