<div class="operation-step-indicator">
  <div *ngFor="let step of currentOperation.operation_steps; index as $index">
    <div *ngIf="(currentOperation.operation_steps.length - 1 > 1) &&
    ![operationStepType.CLOSE_OPERATION_REFRESH.toString(),
      operationStepType.CLOSE_OPERATION.toString(),
      operationStepType.CHANGE_STATE.toString()].includes(step.operation_step_type)"
         class="operation-step-indicator__indicator operation-dialog-step-indicator-bg"
         [ngClass]="{'operation-dialog-step-indicator-bg-active': $index <= currentOperation.$$currentStepIndex}" ></div>
  </div>
  <span class="operation-step-indicator__text bold">{{currentOperation.$$currentStepIndex + 1}} / {{currentOperation.operation_steps.length - 1}}</span>
</div>
