import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-advanced-search-query-builder-information-dialog',
  templateUrl: './advanced-search-query-builder-information-dialog.component.html',
  styleUrl: './advanced-search-query-builder-information-dialog.component.scss'
})
export class AdvancedSearchQueryBuilderInformationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchQueryBuilderInformationDialogComponent>
  ) {}

  confirmClicked() {
    this.dialogRef.close();
  }
}
