import {Component, Input, OnInit} from '@angular/core';
import {CurrentObjectService} from '../../core/current-object.service';
import {OperationService} from '../../operations/operation.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '../../object-edit/confirm-dialog/confirm-dialog.component';
import {LoginService} from '../../core/login.service';
import {MatDialog} from '@angular/material/dialog';
import {GetArtifactParams} from '../../core/definitions/get-artifact-params';
import {CmsApiService} from '../../core/cms-api.service';
import {PrimusRouterService} from '../../core/primus-router.service';

@Component({
  selector: 'app-main-menu-user',
  templateUrl: './main-menu-user.component.html',
  styleUrls: ['./main-menu-user.component.scss']
})
export class MainMenuUserComponent implements OnInit {

  constructor(private currentObjectService: CurrentObjectService,
              private loginService: LoginService,
              private modalService: MatDialog,
              private primusRouter: PrimusRouterService,
              private operationService: OperationService,
              private cms: CmsApiService) {
  }

  modalContent = 'TRANS__MODAL_DIALOG__ARE_YOU_SURE';

  @Input() user;

  public userImage;

  ngOnInit(): void {
    this.setUserImage(this.user.artifact_id).then();
  }

  async goToCurrentUser() {
    const state = 'user-profile';
    const params = {
      userId: this.user.artifact_id
    };
    await this.primusRouter.navigateState(state, params);
  }

  logOut() {
    if (this.currentObjectService.isEditing ||
      this.operationService.isEditableOperation()) {
      this.safeLogout(true).then(confirmed => {
        if (confirmed) {
          this.safeLogoutFunction();
        }
      });
    } else {
      this.safeLogoutFunction();
    }
  }

  safeLogout(openDialog) {
    return new Promise(resolve => {
      if (openDialog) {
        const modalRef = this.modalService.open(ConfirmDialogComponent, {
          panelClass: 'confirm-dialog',
          data: {
            modalContent: this.modalContent,
            modalTitle: 'TRANS__CONFIRM_DIALOG__LOG_OUT_TITLE',
            modalConfirm: 'TRANS__CONFIRM_DIALOG__LOG_OUT',
            modalCancel: 'TRANS__CONFIRM_DIALOG__STAY_ON_PAGE'
          } as ConfirmDialogData
        });
        modalRef.afterClosed().subscribe(result => {
          resolve(result);
        });
      } else {
        resolve(null);
      }

    });
  }

  safeLogoutFunction() {
    this.operationService.cancelOperation();
    this.loginService.logoutUser();
  }

  async setUserImage(userId) {
    const params = new GetArtifactParams();
    params.artifact_id = userId;
    const user = await this.cms.getArtifact(params);
    this.userImage = user.images[0];
  }

}
