@if (chips().length) {
  <div class="scroll-x" #scrollContainer>
    <mat-chip-set role="list">
      <div class="chip-wrapper">
        @for (chip of chips(); track (chip.key)) {
          <mat-chip role="listitem" highlighted color="primary" removable (removed)="onRemoveChipClick(chip)">
            @if (chip.noTransTitle) {
              {{ chip.noTransTitle }}
            } @else if (chip.title) {
              {{ chip.title | translate }}
            }
            <button matChipRemove [attr.aria-label]="'remove ' + (chip.noTransTitle ?? (chip.title | translate))">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        }

        <div>
          <button class="clear-button" mat-stroked-button (click)="onClearAllClick()">
            <mat-icon>close</mat-icon>
            <span>{{ 'TRANS__FILTER_CHIPLIST__CLEAR_SEARCH' | translate }}</span>
          </button>
        </div>
      </div>
  </mat-chip-set>
  </div>

}
