import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CultureHubParams} from '../../core/definitions/culture-hub-params';

@Component({
  selector: 'app-culture-hub-search-dialog',
  templateUrl: './culture-hub-search-dialog.component.html',
  styleUrls: ['./culture-hub-search-dialog.component.scss']
})
export class CultureHubSearchDialogComponent {
  @ViewChild('optionContainer') optionContainer: ElementRef;

  constructor(public dialogRef: MatDialogRef<CultureHubSearchDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CultureHubParams) {
  }

}
