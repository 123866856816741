<div class="content-list-search-result-view" id="searchResultViews">
  <div *ngIf="searchContainer.searching" class="content-list-search-result-view__spinner-container">
    <div class="content-list-search-result-view__spinner-content">
      <mat-spinner color="accent" diameter="30"></mat-spinner>
      <p translate="TRANS__SEARCH__LOADING_INFO"></p>
    </div>
  </div>

  <div *ngIf="searchContainer && !searchContainer.searching && searchContainer.searchResult">

    <div *ngIf="searchContainer.searchResult.search_count === 0">
      <h1 translate="TRANS__SEARCH_RESULT__NO_SEARCH_HITS"></h1>
    </div>

    <div *ngIf="searchContainer.searchResult.search_count > 0">

      <app-object-content-list-regular-items
        [contentListContainer]="contentListContainer"
        [hasOperationMenu]="!!contentListContainer && !!contentListContainer.operationContainer"
        (objectRefresh)="onObjectRefresh($event)">
      </app-object-content-list-regular-items>

    </div>

  </div>

</div>
