import { Injectable } from '@angular/core';
import {CmsApiService} from "./cms-api.service";
import {DateInfo} from "./definitions/date-info";
import {MetaField} from "./definitions/meta-field";

@Injectable({
  providedIn: 'root'
})
export class FieldDateInfoService {
  private fieldDateInfo: {[id: string]: DateInfo}

  constructor(private cms: CmsApiService) { }

  setFieldDateInfo() {
    this.cms.getFieldDateInfo().then(fieldDateInfo => {
      this.fieldDateInfo = fieldDateInfo;
    })
  }

  getFieldDateInfo(metaField: MetaField): DateInfo {
    let res = null;
    if (metaField.date_info) {
      res = metaField.date_info;
    } else if (metaField.date_info_id) {
      res = this.fieldDateInfo[metaField.date_info_id];
    }
    return res;
  }

  getAllFieldDateInfo(): {[id: string]: DateInfo} {
    return this.fieldDateInfo || {};
  }
}
