// Container used for handling media object related to a specific object, primary used in media carousels
import {Media} from './media';

export class ObjectMediaContainer {
  mediaTypes: ObjectMediaType[] = [];
  currentMediaType: ObjectMediaType;
  thumbWidth: string;
}

export class ObjectMediaType {
  mediaType: string;
  objectMediaArrayName: string;
  objectMediaName: string;
  mediaElements: Media[];
  currentMediaIndex: number;
  canAnnotate = false;
}
