<div class="search-filter-menu-path"  [ngClass]="{'home': searchContainer.path === 'home'}">
  <mat-icon *ngIf="searchContainer.path === 'home'"
            class="search-filter-menu-path__home-icon">home</mat-icon>

  <div *ngFor="let menuPath of searchMenuPath; index as $index" class="search-filter-menu-path__items">

    <mat-icon *ngIf="$index > 0" class="search-filter-menu-path__arrow-icon">navigate_next</mat-icon>

    <button mat-button class="text-blue-hover"
            [disabled]="!menuPath.path"
            (click)="goToMenuPath(menuPath)">
      <span [ngClass]="{'search-filter-menu-path__link-text': menuPath.path, 'search-filter-menu-path__text': !menuPath.path}">
        {{ menuPath.title | translate }}
        @if (searchContainer.path === 'home') {
          <span>({{searchContainer.searchResult.search_count}}
            {{'TRANS__SEARCH__RESULT_COUNT' | translate}})
          </span>
        }
      </span>
    </button>

  </div>
</div>
