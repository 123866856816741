<div class="admin-id-format-collection-list-wrapper">

  <header>
    <h5>{{ 'TRANS__ADMIN__ID_FORMAT__COLLECTION_LIST_HEADING' | translate }}</h5>
    <p class="description tone-down-color">{{ 'TRANS__ADMIN__ID_FORMAT__COLLECTION_LIST_HINT' | translate }}
    </p>
  </header>

  <section>
    <mat-selection-list
      [multiple]="false"
      (selectionChange)="handleSelectionChange($event.options[0].value)"
      dense>
      <mat-list-option
        *ngFor="let collection of collections; let i = index;"
        [value]="collection"
        [selected]="i === 0">
        {{ collection.name }} ({{ collection.count }})
      </mat-list-option>
    </mat-selection-list>
  </section>

</div>
