import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {SearchContainer} from '../../../core/definitions/search-container';


@Component({
  selector: 'app-search-query-path-menu',
  templateUrl: './search-query-path-menu.component.html',
  styleUrls: ['./search-query-path-menu.component.scss']
})
export class SearchQueryPathMenuComponent implements OnInit {

  @Input() showPathMenu: boolean;
  @Input() searchContainer: SearchContainer;
  @Output() menuPathSet = new EventEmitter<string>();

  searchMenuPath: Array<any>;
  searchMenuPathTitle;

  constructor(private readonly searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
    this.setSearchMenuPath();
  }

  setSearchMenuPath() {
    this.searchMenuPath = this.searchHandler.getSearchMenuPath(this.searchContainer);
    this.searchMenuPath[this.searchMenuPath.length - 1].path = this.searchContainer.path;
    this.searchMenuPathTitle = this.searchContainer.currentPathView.search_view.title;
    this.menuPathSet.emit(this.searchMenuPathTitle);
  }


  togglePathMenu() {
    const pathMenus = this.searchMenuPath;
    if (pathMenus && pathMenus.length > 1) {
      this.showPathMenu = !this.showPathMenu;
    } else {
      // search();
    }
  }

  async selectFilterMenu(menu) {
    if (menu.path) {
      this.searchMenuPathTitle = menu.title;
      this.menuPathSet.emit(menu.title);
      await this.searchHandler.goPathView(menu.path, this.searchContainer);
    }
  }

}
