import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {
  ConceptListItem,
} from '../../../core/definitions/concepts';
import {ConceptDialogData, ConceptService} from '../../../core/concept.service';
import {DateToolsService} from '../../../core/date-tools.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CreateSectionsContainerParams, ObjectEditService} from '../../../core/object-edit.service';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {BaseModel} from '../../../core/definitions/base-model';
import {UiToolsService} from '../../../core/ui-tools.service';
import {TranslateService} from '@ngx-translate/core';
import {SearchReferenceService} from "../../../core/search-reference.service";
import {System} from "../../../core/definitions/system";

@Component({
  selector: 'app-admin-edit-item',
  templateUrl: './admin-edit-item.component.html',
  styleUrls: ['./admin-edit-item.component.scss']
})
export class AdminEditItemComponent implements OnInit {

  updatedAt = '';
  storing = false;
  ready = false;
  isHierarchic = false;
  isFirst = false;
  isLast = false;
  displayPrevNext = false;
  concept: BaseModel = new BaseModel();
  index: number;
  parents: Array<ConceptListItem> = [];
  sectionsContainer: SectionsContainer;
  errorMessage: string;

  private oldInvalidFieldLength = 0;
  private invalidFieldText = '';

  @HostListener('click', ['$event.target'])
  registerClick(event: any) {
    this.uiTools.registerDocumentClick(event);
  }

  constructor(
    public dialogRef: MatDialogRef<AdminEditItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConceptDialogData,
    private translate: TranslateService,
    private objectEditService: ObjectEditService,
    private conceptService: ConceptService,
    private uiTools: UiToolsService,
    private dateToolsService: DateToolsService,
    private searchReferenceService: SearchReferenceService) {

  }

  ngOnInit() {
    if (this.data.index !== -1) {
      this.index = this.data.index;
      this.displayPrevNext = true;
    }
    this.isHierarchic = this.data.conceptType.is_hierarchic;
    const concept = this.data.concept_id ? {artifact_id: this.data.concept_id} : this.data.concept;
    this.setConceptData(concept).then();
  }

  async closeEdit(stored: boolean) {
    this.dialogRef.close(stored);
  }

  async store() {
    this.storing = true;
    this.errorMessage = '';
    try {
      await this.objectEditService.storeObjectShowProgressModal(this.concept);
      this.data.conceptDataSource.refreshVisibleConcepts();
      this.storing = false;
      await this.closeEdit(true);
    } catch (e) {
      this.storing = false;
      this.errorMessage = e.error?.message;
    }
  }

  async next() {
    this.index++;
    await this.loadConceptByIndex();
  }

  async previous() {
    this.index--;
    await this.loadConceptByIndex();
  }

  get disabled() {
    return !this.sectionsContainer ||
      this.sectionsContainer.formGroup.invalid ||
      !this.sectionsContainer.formGroup.dirty ||
      this.storing;
  }

  get disabledReason() {
    let text = '';
    if (this.disabled) {
      if (this.concept.has_authority) {
        text = this.translate.instant('TRANS__DISABLE_REASON__HAS_AUTHORITY');
      } else if (this.sectionsContainer?.formGroup.invalid) {
        const invalidFields = this.getInvalidFields();
        if (invalidFields.length !== this.oldInvalidFieldLength) {
          this.oldInvalidFieldLength = invalidFields.length;
          this.invalidFieldText = this.generateDisabledReason(invalidFields);
        }
        text = this.invalidFieldText;
      } else {
        text = this.translate.instant('TRANS__DISABLE_REASON__NO_CHANGES');
      }
    } else {
      this.oldInvalidFieldLength = 0;
    }
    return text;
  }

  private generateDisabledReason(invalidFields: string[]) {
    let text = this.translate.instant('TRANS__VALIDATION__INVALID_FIELDS');
    text += ': ';
    let add = '';
    for (const name of invalidFields) {
      const firstName = name.split(System.keySep)[0];
      const fieldMeta = this.sectionsContainer.rootObject.$$meta[firstName];
      const fieldTitle = fieldMeta.title || fieldMeta.admin_title;
      text += add + this.translate.instant(fieldTitle);
      add = ', ';
    }
    return text;
  }

  private getInvalidFields() {
    const res = [];
    for (const name in this.sectionsContainer.formGroup.controls) {
      if (this.sectionsContainer.formGroup.controls[name].invalid) {
        res.push(name);
      }
    }
    return res;
  }

  private async loadConceptByIndex() {
    const conceptListItem = this.data.conceptList[this.index];
    await this.setConceptData(conceptListItem);
  }

  private async setConceptData(concept) {
    this.ready = false;
    if (concept.artifact_id) {
      this.sectionsContainer = await this.objectEditService.loadObjectGetSectionsContainer(concept.artifact_id, false);
    } else {
      this.sectionsContainer = await this.objectEditService.createSectionsContainer({
        objectType: concept.object_type,
        object: concept,
        useExistingObject: false,
        usePrimeFields: false
      } as CreateSectionsContainerParams);
    }
    this.sectionsContainer.isDialog = true;
    this.setParentFilters();
    this.concept = this.sectionsContainer.rootObject;
    const parentMeta = this.concept.$$meta.parent_id;
    const parentRef = this.searchReferenceService.getSearchReferenceFromField(parentMeta);
    if (parentRef && parentRef.ref_filter) {
      parentMeta.reference = parentRef;
      parentMeta.reference.ref_filter = [];
    }
    if (this.concept.updated_at) {
      this.updatedAt = this.dateToolsService.isoDateToString(this.concept.updated_at);
    }
    if (this.concept.artifact_id) {
      await this.conceptService.setConceptUsage([this.concept]);
    }
    if (this.index !== undefined) {
      this.isFirst = this.index === 0;
      this.isLast = this.index === this.data.conceptList.length - 1;
    }
    this.ready = true;
  }

  // Need to set filters for the 'parent_id' field, or else will get no search results
  private setParentFilters() {
    const parentField = this.sectionsContainer.sections[0].editFields.find(editField => editField.name === 'parent_id');
    if (parentField) {
      const parentRef = this.searchReferenceService.getSearchReferenceFromField(parentField);
      const refFilters = parentRef.ref_filter;
      const superobjectFilter = refFilters.find(refFilter => refFilter.filter_field === 'filters.superobject_type_id');
      if (superobjectFilter) {
        superobjectFilter.values = [];
      }
      const artifactId = this.sectionsContainer.rootObject.artifact_id;
      if (artifactId) {
        refFilters.push({filter_field: '-artifact_id', values: [artifactId], fq: undefined});
      }
    }
  }


}
