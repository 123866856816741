import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MetaField} from '../../../../core/definitions/meta-field';
import {SectionsContainer} from '../../../../core/definitions/sections-container';

@Component({
  selector: 'app-user-info-section',
  templateUrl: './user-info-section.component.html',
  styleUrls: ['./user-info-section.component.scss']
})
export class UserInfoSectionComponent implements OnChanges {

  @Input() enableEditMode: boolean;
  @Input() sectionsContainer: SectionsContainer;

  fields: Array<MetaField>;

  constructor() {
    this.fields = [];
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  // private loadFields(user: User): Array<MetaField> {
  //   if (user && user.$$meta) {
  //     const meta = user.$$meta;
  //     return Object.keys(meta)
  //       .map(key => meta[key])
  //       .filter(field => field.display && field.display.show === 'yes');
  //   }
  //   return [];
  // }

  fieldValue(field) {
    if (!field || !field.name) {
      return true;
    }
    let prop = field.name;
    if (field.name.endsWith('_id')) {
      prop = field.name + '_value';
    }
    let model = this.sectionsContainer.rootObject;
    if (field.input_type === 'inline') {
      model = model[field.inline.prop] || {};
    }
    return !model[prop];
  }

}
