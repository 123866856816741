<div class="model-full-screen"
     tabIndex="0"
     (keyup)="goBackWithKey($event)"
     #modelFullScreen>
  <div class="model-full-screen__active-model" id="model-viewer-container">

  </div>

  <button mat-icon-button class="model-full-screen__close" (click)="goBack()">
    <mat-icon>close</mat-icon>
  </button>
</div>
