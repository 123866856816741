import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {ContentMenusService} from '../content-menus.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {AConst} from '../../core/a-const.enum';
import {ObjectContentMainTabComponent} from '../object-content-main-tab/object-content-main-tab.component';
import {ContentInfo} from '../../core/definitions/content-info';
import {query, stagger, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn} from '../../shared/animations';
import {ContentMenu} from '../../core/definitions/object-content-tab/content-menus';

@Component({
  selector: 'app-object-content-tabs',
  templateUrl: './object-content-tabs.component.html',
  styleUrls: ['./object-content-tabs.component.scss'],
  animations: [
    trigger('slideInContentTab', [
      transition('* => *', [
        query(':enter', [
          style({opacity: 0}),
          stagger(75, useAnimation(fadeIn, {params: {time: '50ms'}}))
        ], {optional: true}),
      ])
    ])
  ]
})
export class ObjectContentTabsComponent implements OnChanges, OnDestroy {

  @ViewChild('menuControl', { static: true }) menuControl: ElementRef;
  @ViewChildren(ObjectContentMainTabComponent) menuItems: QueryList<ObjectContentMainTabComponent>;

  @Input() contentInfo: ContentInfo;
  @Input() isEditing: boolean;
  @Output() finishedLoading = new EventEmitter<boolean>();

  AConst = AConst;
  menuItemsReady = false;

  myRoot;
  private isNotComputer: boolean;

  constructor(private primusRoute: PrimusRouteService,
              private renderer: Renderer2,
              private contentMenusService: ContentMenusService,
              private uiTools: UiToolsService) {
  }

  ngOnChanges() {
    this.contentInfo.visibleDropDowns = [];
    this.myRoot = this.menuControl.nativeElement;
    this.isNotComputer = !this.uiTools.getClientType().isComputer;
    if (this.contentInfo && this.contentInfo.artifact) {
      this.contentInfo.setContentMenus = () => {
        this.setContentMenus().then();
      };
      this.setContentMenus().then(() => {
          this.finishedLoading.emit();
        }
      );
    }

    setTimeout(() => {
      this.menuItemsReady = true;
    }, 1000);
  }

  ngOnDestroy(): void {
  }

  openMenu(menu: ContentMenu) {
    let t, sm;
    const isOpen = menu.open;
    if (isOpen && menu.leaf_menu) {
      return;
    }
    this.closeMenus();

    if (!isOpen) {
      menu.open = true;
      if (menu.leaf_menu) {
        // TODO: In the previous AngularJS based component used $location.search method to replace url without doing actual state change
        // but has not found a way to do this in Angular:
        // $location.search('listName', menu[AConst.CONTENT_LIST]);
        // this.stateService.go(this.stateService.current, {'listName': menu[AConst.CONTENT_LIST]});
        this.setCurrentList(menu.content_list);
        const notSubMenu = this.contentInfo.menus.find((item: ContentMenu) => {
          return item.content_list === menu.content_list; });
        if (notSubMenu) {
          this.contentInfo.selectedMainMenu = menu;
          this.closeMenus();
        }
      } else {
        this.contentInfo.selectedMainMenu = menu;
        for (t = 0; t < menu.sub_menus.length; t++) {
          sm = menu.sub_menus[t];
          if (sm.count) {
            this.openMenu(menu.sub_menus[t]);
            break;
          }
          if (sm.count === 0) {
            this.openMenu(menu.sub_menus[0]);
            menu.open = true;
          }
        }
      }
    }
  }

  private closeMenus(menus?: Array<ContentMenu>) {
    (menus || this.contentInfo.menus || []).forEach(menu => {
      menu.open = menu.content_list === this.contentInfo.selectedMainMenu.content_list;
      if (menu.sub_menus) {
        this.closeMenus(menu.sub_menus);
      }
    });
  }

  private setCurrentList(listName) {
    const ci = this.contentInfo;
    ci.curListName = listName;
    ci.curListContainer = null;
    ci.setContentListSource(null);
    if (ci.contentListContainers[listName]) {
      this.contentMenusService.runListSearch(ci.curListName, ci).then(() => {
        this.contentMenusService.setActiveTopMenu(ci.menus, ci);
      });
    } else if (ci.sources && ci.sources[listName]) {
      ci.setContentListSource(ci.sources[listName]);
    }
  }

  private async setContentMenus() {
    this.contentInfo.setCurrentList = (listName) => {
      this.setCurrentList(listName);
    };
    if (!this.contentInfo.menus) {
      const menus = await this.contentMenusService.getMenus(this.contentInfo);
      this.contentInfo.menus = menus;
      await this.contentMenusService.setContentMenus(menus, this.contentInfo, this.primusRoute.params);
    } else {
      await this.contentMenusService.setContentMenus(this.contentInfo.menus, this.contentInfo, this.primusRoute.params);
    }
  }


}
