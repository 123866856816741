import {SuperobjectCollection} from '../../core/definitions/superobject-collection';
import {SettingsService} from "../../core/settings.service";

export class IdentifierFormat {
  public format: string;
  public prefix: string;
  public digits: number;
  public subtype_separator: string;
  public subtype_digits: number;
  public object_type_id: string;
  public object_type: string;

  public meta_type: string;
  public artifact_id: string;
  public artifact_name: string;
  public identifier_format_superobject_type_id: string;
  public identifier_format_superobject_type_id_value: string;
  public identifier_format_collection_id: string;
  public identifier_format_collection_id_value: string;
  public is_restricted: boolean;
  public restriction_type: number;
  public collection: SuperobjectCollection;

  constructor(
    partial?: Partial<IdentifierFormat>,
    identifier_format_superobject_type_id?: string
  ) {
    this.format = '<prefix>.<n>';
    this.prefix = '';
    this.digits = 1;
    this.subtype_separator = '.';
    this.subtype_digits = 1;
    this.object_type_id = 'IDENTIFIERFORMAT';
    this.object_type = 'IdentifierFormat';
    this.meta_type = 'Settings';
    this.artifact_id = '';
    this.artifact_name = '';
    // Default type: "Ukjent"
    this.identifier_format_superobject_type_id = identifier_format_superobject_type_id;
    this.identifier_format_superobject_type_id_value = '';
    this.identifier_format_collection_id = '';
    this.identifier_format_collection_id_value = '';
    this.is_restricted = false;
    this.restriction_type = 1;
    this.collection = null;

    if (!!partial) {
      Object.keys(partial).forEach(key => this[key] = partial[key]);
    }
  }
}
