<mat-card-title>
  {{ 'TRANS__PROFILE__USER_DELETED_HEADING' | translate }}
</mat-card-title>
<mat-card-content>
  {{ 'TRANS__PROFILE__USER_DELETED_DESCRIPTION' | translate}}
</mat-card-content>
<mat-card-actions>
  <button mat-raised-button
          color="warn"
          type="button"
          (click)="close(true)">
    {{ 'TRANS__PROFILE__RESTORE_USER_BUTTON_LABEL' | translate }}
  </button>
  <a routerLink="/users"
     mat-stroked-button
     color="accent"
     (click)="close()">
    {{ 'TRANS__PROFILE__BACK_TO_USERS_BUTTON_LABEL' | translate }}
    <mat-icon matSuffix>arrow_forward</mat-icon>
  </a>
</mat-card-actions>
