<div class="md-table admin-concept-concepts-list admin-scroll-layout" *ngIf="conceptFields && conceptFields.length">
  <table [attr.height]="height"
         mat-table matSort
         [dataSource]="conceptsContainer.conceptDataSource">
    <ng-container *ngFor="let field of conceptFields"
                  [matColumnDef]="field.name"
                  [sticky]="field.name === 'select' || field.name === 'name'"
                  [stickyEnd]="field.name === 'action'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header=""
          [disabled]="field.sortable === false">
        <mat-checkbox
          *ngIf="field.type === 'select'"
          class="admin-concept-concepts-list__checkbox"
          [(ngModel)]="conceptsContainer.allSelected"
          (ngModelChange)="selectAll()"
          [indeterminate]="!conceptsContainer.allSelected && conceptsContainer.selected.length > 0">
        </mat-checkbox>
        <span [ngClass]="{'admin-table-select-all-name': field.type === 'select'}">{{field.title | translate}}</span>
      </th>

      <td mat-cell *matCellDef="let row; let i = index">
        <ng-container *ngIf="field.type === 'value'">
          <span>{{row[field.name]}}</span></ng-container>
        <app-admin-list-item-special-value
          class="concept-special-value"
          [ngClass]="{'has-link': conceptsContainer.showLinkToObject}"
          *ngIf="field.type !== 'value'"
          [value]="row[field.name]"
          [concept]="row"
          [conceptField]="field"
          [conceptsContainer]="conceptsContainer"
          [index]="i"
          (conceptOpened)="openConcept($event)"
          (updateConceptList)="setReloadConcepts(false)"
        ></app-admin-list-item-special-value>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedCol; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedCol" class="concept-rows view-hover-color"></tr>
  </table>
</div>
<div class="admin-action-toolbar">
    <app-admin-list-action-toolbar
      [conceptsContainer]="conceptsContainer"
      (updateConceptList)="setReloadConcepts(false)"
    ></app-admin-list-action-toolbar>

    <mat-paginator
      [pageSizeOptions]="[20, 50, 100, 1000]"
      [pageSize]="20"
      (page)="changePage()"
      showFirstLastButtons
    ></mat-paginator>
</div>

