import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SearchContainer} from "../../../core/definitions/search-container";
import {SearchExecutorService} from "../../search-executor.service";
import {CommonsService} from "../../../core/commons.service";
import {SearchFilterService} from "../../search-filter.service";
import {DateToolsService} from "../../../core/date-tools.service";
import {UiToolsService} from "../../../core/ui-tools.service";
import {Facet, RangeGroup} from "../../../core/definitions/search-objects";
import {LoggerService} from "../../../core/logger.service";

@Component({
  selector: 'app-search-facet-range',
  templateUrl: './search-facet-range.component.html',
  styleUrls: ['./search-facet-range.component.scss']
})
export class SearchFacetRangeComponent implements OnInit, OnDestroy {
  @Input() searchContainer: SearchContainer;
  @Input() group: RangeGroup;
  @Input() sideMenu;
  @Output() scrollToBottom = new EventEmitter<object>();

  start: string;
  end: string;

  rangeSelected;

  constructor(private logger: LoggerService,
              private searchExecutorService: SearchExecutorService,
              private commons: CommonsService,
              private searchFilterService: SearchFilterService,
              private dateTools: DateToolsService,
              private uiTools: UiToolsService) {
  }

  ngOnInit() {
    if (this.searchContainer) {
      this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.searchResultCallbackFacetRange);
    }
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.searchResultCallbackFacetRange);
  }

  checkRange($event, range: Facet) {
    $event.preventDefault();
    if (range.total_count > 0) {
      this.searchFilterService.checkRangeFilter(this.group, range, this.searchContainer).catch();
      if (this.sideMenu && range.editable) {
        this.scrollToBottom.emit();
      }
    } else {
      // Handles deselecting search between, to prevent search filter - choices to be unavailable.
      if (range.checked && range.field_type === 'solr_date') {
        range.start = range.$$origStart;
        range.end = range.$$origEnd;
        // Update RangeFilter
        this.searchFilterService.checkRangeFilter(this.group, range, this.searchContainer).catch();
        this.searchFilterService.setRangeFilter(this.group, range, this.searchContainer).catch();
      }
    }
  }

  setRange(params) {
    const rangeGroup = params[0];
    const range = params[1];

    // below ensure that both ranges are updated on enter
    range.start = (this.start) ? this.start : range.start;
    range.end = (this.end) ? this.end : range.end;

    this.searchFilterService.setRangeFilter(rangeGroup, range, this.searchContainer).catch();
  }

  toggle() {
    this.group.enabled = !this.group.enabled;
    if (this.group.enabled === true) {
      window.addEventListener('mouseup', this.callback, true);
    } else {
      window.removeEventListener('mouseup', this.callback, true);
    }
    this.searchExecutorService.runSearch(this.searchContainer).then()
  }

  private callback = (item) => {
    const found = this.uiTools.findClassNameRecursively(item.target, 'dropdownIgnoreClicks');
    if (!found) {
      setTimeout(() => {
        this.group.enabled = false;
        window.removeEventListener('mouseup', this.callback, true);
      }, 100);
    }
  }

  private searchResultCallbackFacetRange = () => {
    this.searchFilterService.setCheckedRangeFacets(this.searchContainer);
    this.searchFilterService.setOldRangeFilters(this.searchContainer);
    this.setGroupRangesOrdered();
  }

  private setGroupRangesOrdered() {
    for (const group of this.searchContainer.filtersFacets.facetRangeGroups) {
      group.facet_ranges = this.commons.sortArray(group.facet_ranges, 'order');
    }
  }

  /**
   * Used to keep dates updated, so that both dates get updated on "enter"
   * this is ensured by keeping start and end variable updated onChange
   * @param date: YYYY-MM-DDTHH:mm:ss date format
   * @param propName: start or end, depending on which date range got triggered
   */
  updateDate(date: string, propName: string) {
    if (propName === 'start') {
      this.start = date;
    } else if (propName === 'end') {
      this.end = date;
    }
  }

  isDisabled(range: Facet) {
    if(range.total_count === 0) {
      // Makes search between possible to deselect if checked.
      return !(range.checked && range.field_type === 'solr_date');
    } else {
      return false
    }
  }
}
