import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FieldValidationService} from '../../core/field-validation.service';
import {FieldValueService} from '../../core/field-value.service';
import {InFocus} from '../in-focus';
import {GenerateIdentifierParams} from '../../core/definitions/generate-identifier-params';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {MetaTypes} from "../../core/definitions/meta-types";

@Component({
  selector: 'app-edit-field-identifier',
  templateUrl: './edit-field-identifier.component.html',
  styleUrls: ['./edit-field-identifier.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldIdentifierComponent)
    }
  ]
})
export class EditFieldIdentifierComponent implements ControlValueAccessor {

  @Input() fieldParameters: FieldParameters;
  @Input() formControlName: string;
  @Input() placeholder: string;
  @Input() inFocus: InFocus;
  @Output() fieldFocus = new EventEmitter<object>();
  @Output() fieldBlur = new EventEmitter<object>();

  onChange: any;

  constructor(private cms: CmsApiService,
              public fieldValidation: FieldValidationService,
              private fieldValueService: FieldValueService) {
  }

  async generateAnIdentifier() {
    if (!this.isDisabled) {
      await this.generateIdentifier();
    }
  }

  get isDisabled() {
    const rootObj = this.fieldParameters.sectionsContainer.rootObject;
    return !!rootObj.identification_number?.identification_number || !rootObj.collection?.collection_id;
  }

  private async generateIdentifier() {
    const sectionsContainer = this.fieldParameters.sectionsContainer;
    const params = new GenerateIdentifierParams();
    params.superobject_type_id = sectionsContainer.rootObject.superobject_type_id;
    if (sectionsContainer.rootObject.meta_type !== MetaTypes.SPECTRUM_PROCEDURE) {
      params.collection_id = sectionsContainer.rootObject.collection.collection_id;
    }
    if (sectionsContainer.rootObject.contexts) {
      sectionsContainer.rootObject.contexts.forEach(context => {
        if (context.context_id) {
          params.context_id = context.context_id;
        }
      });
    }
    if (sectionsContainer.useContextCreatingIdentifier && sectionsContainer.operationContextObjects) {
      params.context_id = sectionsContainer.operationContextObjects[0].artifact_id;
    }
    try {
      const data = await this.cms.getNextIdentifier(params);
      const formControl = sectionsContainer.formGroup.controls[this.formControlName];
      formControl.setValue(data.identifier);
      formControl.markAsDirty();
      this.fieldValueService.setFieldValue(sectionsContainer.rootObject, this.formControlName, data.identifier);
    } catch (response) {
      console.error('Unable to generate identifier: ' + response.message);
    }
  }

  onValueChange($event: any) {
    this.fieldValueService.setFieldValue(this.fieldParameters.sectionsContainer.rootObject, this.formControlName, $event.target.value);
  }

  clearValue() {
    this.fieldParameters.sectionsContainer.formGroup.controls[this.formControlName].setValue('');
    this.fieldValueService.setFieldValue(this.fieldParameters.sectionsContainer.rootObject, this.formControlName, '');
  }

  async pressedKey(evt: any) {
    if (evt.key === 'Enter') { // enter
      await this.generateAnIdentifier();
      evt.preventDefault();
    }

  }

  onFieldIdentifierBlur() {
    this.fieldBlur.emit();
  }

  onFieldIdentifierFocus() {
    this.fieldFocus.emit();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(/*fn: any*/): void {
    // No need to implement anything here
  }

  setDisabledState(/*isDisabled: boolean*/): void {
    // No need to implement anything here
  }

  writeValue(/*obj: any*/): void {
    // No need to implement anything here
  }

}
