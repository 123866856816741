import {Component, Input, OnInit} from '@angular/core';
import {AnnotationHandler} from '../../annotation-handler';

@Component({
  selector: 'app-annotation-parents',
  templateUrl: './annotation-parents.component.html',
  styleUrls: ['./annotation-parents.component.scss']
})
export class AnnotationParentsComponent implements OnInit {
  @Input() curAnn: AnnotationHandler;
  @Input() isDialog: boolean;

  constructor() { }

  ngOnInit() {
  }

}
