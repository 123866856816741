<div class="template-model">
  <app-top-header [parent]="parent"
                  backRouterLink="/template-group"
                  backRouterLinkLabel="TRANS__TEMPLATE_GROUP__TITLE"
  ></app-top-header>

  <app-header-and-description heading="TRANS__TEMPLATE_MODEL__TITLE"
                              description="TRANS__TEMPLATE_MODEL__INGRESS"
                              headingName="{{templateGroupName}}">

    <div class="template-model__content">
      <div class="template-button-container">
        <button mat-stroked-button
                color="primary"
                (click)="addTemplateModel()"
                class="template-model__button"
                [disabled]="createDisabled">
          <mat-icon>edit</mat-icon>
          <span translate="TRANS__TEMPLATE_GROUP__ADD_MODEL"></span>
        </button>
      </div>

      <ul class="template-list__content" *ngIf="templateModels">
        <li *ngFor="let model of templateModels[AConst.TEMPLATE_MODELS]"
            class="template-list__item">
          <div class="template-list__object-overview">
            <h2>
              <app-object-icon
                class="template-model__object-overview-icon"
                [object]="model.$$mockObject">
              </app-object-icon>
              <app-object-name
                [object]="model.$$mockObject"
                class="bold"></app-object-name></h2>
            <app-object-view-and-edit
              *ngIf="sectionsContainers[model.$$uuid]"
              [isEditing]="getModelIsEditing(model)"
              [sectionsContainer]="sectionsContainers[model.$$uuid]"
              [templateGroupId]="templateGroupId"
            ></app-object-view-and-edit>
          </div>
        </li>
      </ul>

      <div class="row"  *ngIf="debug && templateModels">
        <pre>{{templateModels | json}}</pre></div>
    </div>

    <div *ngIf="templateModel"
         class="overlay template-model__overlay"
         (click)="disableTemplateModel()"></div>

    <div *ngIf="templateModel" class="side-selector template-model__edit">
      <h3>
        <span>{{templateGroupName}}: </span>
        <span>{{templateModel.artifact_name}}</span></h3>

      <app-object-view-and-edit
        class="template-model__fields-container"
        *ngIf="sectionsContainers[templateModel.$$uuid]"
        [isEditing]="true"
        [sectionsContainer]="sectionsContainers[templateModel.$$uuid]"
        [templateGroupId]="templateGroupId"></app-object-view-and-edit>

      <div class="row"  *ngIf="debug">
        <pre>{{templateModel | json}}</pre></div>

      <div class="template-model__action-toolbar">
        <div class="template-model__action-toolbar-container">
          <div class="template-model__action-buttons-container">
            <div *ngFor="let field of actionButtonFields"
                 class="template-model__action-buttons">
              <app-field-action-button
                [sectionsContainer]="sectionsContainers[templateModel.$$uuid]"
                [field]="field"
                (actionRun)="onActionRun(field)"
              ></app-field-action-button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </app-header-and-description>
</div>
