<div class="admin-id-format-global-view-wrapper">
  <app-admin-id-format-edit-section
    heading="TRANS__ADMIN__ID_FORMATS__GLOBAL_HEADING"
    description="TRANS__ADMIN__ID_FORMATS__GLOBAL_DESCRIPTION"
    [formats]="formats"
    (addPrefixClicked)="handleAddOrEditPrefixClicked()"
    (editPrefixClicked)="handleAddOrEditPrefixClicked($event)"
    (formatDeleted)="formatDeleted.emit($event)">
  </app-admin-id-format-edit-section>
</div>
