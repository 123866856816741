import {Component, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {ChangeTrackerService} from '../../core/change-tracker.service';
import {UserDialogsService} from '../../shared/user-dialogs.service';
import {CmsApiService} from '../../core/cms-api.service';
import {PrimusFormGroupService} from '../../core/primus-form-group.service';
import {AConst} from '../../core/a-const.enum';
import {ObjectEditService} from '../../core/object-edit.service';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {FieldValueService} from '../../core/field-value.service';
import {CommonsService} from '../../core/commons.service';
import {TemplateGroups, TemplateGroup} from '../../core/definitions/template-models';
import {PrimusRouterService} from '../../core/primus-router.service';
import {FieldParameters} from "../../core/definitions/field-parameters";

@Component({
  selector: 'app-admin-template-group',
  templateUrl: './admin-template-group.component.html',
  styleUrls: ['./admin-template-group.component.scss']
})
export class AdminTemplateGroupComponent implements OnInit {

  AConst = AConst;
  searching = false;
  templateGroups: TemplateGroups;
  createDisabled = true;
  parent;
  templateSelectorContainer: SelectorContainer;

  private adminType;
  private curStateName = 'template-group';
  private sectionsContainers = {};

  constructor(private readonly primusRouter: PrimusRouterService,
              private readonly primusRoute: PrimusRouteService,
              private readonly changeTracker: ChangeTrackerService,
              private readonly userDialogsService: UserDialogsService,
              private readonly cms: CmsApiService,
              private readonly formGroupService: PrimusFormGroupService,
              private readonly objectEditService: ObjectEditService,
              private readonly fieldValueService: FieldValueService,
              private readonly commons: CommonsService) {
  }

  ngOnInit() {
    this.adminType = this.primusRoute.params['admin_type'];
    this.parent = {
      path: 'admin',
      adminType: this.adminType,
      hasSubMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.curStateName
    };
    this.loadTemplateGroups().then();
  }

  getSectionsContainer(group) {
    let res;
    if (group.$$uuid && this.sectionsContainers.hasOwnProperty(group.$$uuid)) {
      res = this.sectionsContainers[group.$$uuid];
    } else {
      this.setGroupHelperProps(group);
    }
    return res;
  }

  createTemplateGroup() {
    const fieldParameters = new FieldParameters();
    fieldParameters.object = this.templateGroups;
    fieldParameters.field = this.templateGroups.$$meta[AConst.TEMPLATE_GROUPS];
    const newGroup = this.fieldValueService.createAddItem(fieldParameters);
    newGroup['$$isEditing'] = true;
    this.setGroupHelperProps(newGroup);
    this.objectEditService.setTopModel(this.templateGroups, AConst.TEMPLATE_GROUPS);
    this.createDisabled = true;
    this.setTemplateGroupOrder();
  }

  deleteCallback() {
    this.loadTemplateGroups().then();
  }

  async setDeletable(group: TemplateGroup) {
    if (!group.template_group_id) {
      return;
    }
    const deletableRes = await this.cms.canDeleteTemplate({template_group_id: group.template_group_id});
    group.$$deletable = deletableRes.result;
  }

  showUsage(group: TemplateGroup) {
    this.userDialogsService.showObjectUsage(group.template_group_id).then();
  }

  onTemplateSelectorCreated(templateSelectorContainer) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  async onChangeTemplateGroup(templateGroupId) {
    await this.primusRouter.navigateState('template-model', {
      template_group_id: templateGroupId
    });
  }

  onCloseEdit(group) {
    if (group) {
      group['$$isEditing'] = false;
    }
    this.createDisabled = false;
  }

  private async loadTemplateGroups() {
    this.searching = true;
    const data = await this.cms.getMetaObject({
      objectType: 'TemplateGroups'
      // , template_group_type_id: "ct_208-1"
    });
    if (data) {
      const groups = await this.objectEditService.createModelItem('TemplateGroups', data);
      this.templateGroups = <TemplateGroups>groups;
      this.setTemplateGroupOrder();
      this.searching = false;
      this.createDisabled = false;
      this.objectEditService.setTopModel(groups, AConst.TEMPLATE_GROUPS);
      for (const templateGroup of this.templateGroups.template_groups) {
        await this.setDeletable(templateGroup);
      }
    } else {
      const groupsItem = await this.objectEditService.createModelItem('TemplateGroups', null);
      this.templateGroups = <TemplateGroups>groupsItem;
      this.searching = false;
      this.createDisabled = false;
    }
  }

  private setTemplateGroupOrder() {
    this.templateGroups.template_groups = this.commons.orderArray(
      this.templateGroups.template_groups, 'order_number', true);
  }

  private setGroupHelperProps(group) {
    group.$$uuid = this.commons.uuid();
    if (!group.$$isSetting) {
      group.$$isSetting = true;
      this.formGroupService.getSectionsAndFormGroup(group).then(sc => {
        sc.hideSectionTitle = true;
        sc.viewMode = 'admin';
        this.sectionsContainers[group.$$uuid] = sc;
      });
    }
  }

}
