import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {OperationService} from '../../../operations/operation.service';
import {OperationTarget} from '../../../core/definitions/operation-target.enum';
import {OperationDialogService} from '../../../operations/operation-dialog.service';
import {SearchTemplateGroupService} from '../../search-template-group.service';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {OperationDef} from '../../../core/definitions/operation-def';
import {BaseModel} from '../../../core/definitions/base-model';
import {SearchContainer} from '../../../core/definitions/search-container';
import {OperationContainer} from '../../../core/definitions/operation-container';
import {SuperObjectModel} from "../../../core/definitions/super-object-model";

@Component({
  selector: 'app-search-view-full-header',
  templateUrl: './search-view-full-header.component.html',
  styleUrls: ['./search-view-full-header.component.scss']
})
export class SearchViewFullHeaderComponent implements OnChanges {

  AConst = AConst;

  @Input() title: string;
  @Input() searchContainer: SearchContainer;
  @Input() listFieldTemplateId: string;

  operationContainer: OperationContainer;
  listFieldTemplateObject: BaseModel;

  constructor(private operationService: OperationService,
              private operationDialogService: OperationDialogService,
              private searchTemplateGroupService: SearchTemplateGroupService,
              private searchExecutorService: SearchExecutorService,
              private searchResultSelectionsService: SearchResultSelectionsService) {
  }

  ngOnChanges() {
    if (this.listFieldTemplateId) {
      this.listFieldTemplateObject = new BaseModel(this.listFieldTemplateId);
    }
    this.setOperationContainer().then();
  }

  async openOperation(operation: OperationDef) {
    await this.operationService.openOperationView(
      this.operationContainer, operation, null, (operationRes: any) => {
        console.log('Operation done: ' + operationRes);
      }
    );
  }

  private async setOperationContainer() {
    this.operationContainer = await this.operationService.createOperationContainer(
      OperationTarget.SEARCH_HEADER_VIEW);
    this.operationContainer.templateGroupId = this.searchContainer.templateGroupId;
    this.operationContainer.listFieldTemplateId = this.listFieldTemplateId;
    this.operationService.setOperationContextObjects(
      this.operationContainer,
      this.searchResultSelectionsService.getCleanItems(this.searchContainer));
    await this.operationService.setOperations(this.operationContainer, this.searchContainer);
    this.operationContainer.openOperationDialogFn = (callback: any) => {
      this.operationDialogService.openOperationDialog(this.operationContainer).then(data => {
        this.handleResultObject(data.resultObject).then(() => {
          if (callback) {
            callback(data);
          }
        });
      });
    };
  }

  private async handleResultObject(resultObject: SuperObjectModel) {
    if (resultObject && resultObject.object_type === 'TemplateModelOverviewFieldsForFieldSelect') {
      this.searchContainer.refreshTemplateOverviewFields = true;
      await this.searchTemplateGroupService.setListFieldTemplateGroup(
        this.searchContainer, resultObject.template_group_id);
      await this.searchExecutorService.runSearch(this.searchContainer);
    }
  }

}
