<li #moreMenuItem
    class="object-content-dropdown-menu"
    [style.display]="showMoreMenu ? 'inline-block' : 'none'"
    [ngClass]="{'isEditing': isEditing}"
    [attr.aria-hidden]="showMoreMenu ? 'false' : 'true'">
  <a class="object-content-dropdown-menu-button df-dropdown-toggle"
       [attr.df-dropdown-toggle]="''"
       [ngClass]="{
       'df-tab-menu-dropdown-open': dropdownOpen,
       'activeDropDownItem': selectedMainMenu && selectedMainMenu.open && contentInfo.visibleDropDowns[contentInfo.menus.indexOf(selectedMainMenu)],
       'disabled': isEditing}"
       [attr.aria-expanded]="dropdownOpen ? 'true': 'false'"
       aria-haspopup="true">
    <span class="top-line bg-blue"></span>
    <div class="object-content-tab-text object-content-dropdown-menu__toggle">
      <span [class.text-blue]="selectedMainMenu && selectedMainMenu.open && contentInfo.visibleDropDowns[contentInfo.menus.indexOf(selectedMainMenu)]"><i class="icon-more"></i></span>
    </div>
  </a>
  <ul role="menu"
      [attr.aria-hidden]="dropdownOpen ? 'false': 'true'"
      class="df-tab-menu-dropdown">

    <ng-container *ngFor="let menu of contentInfo.menus; index as i">
      <app-object-content-dropdown-tab
        *ngIf="contentInfo.visibleDropDowns[i]"
        [menu]="menu"
        [isEditing]="isEditing"
        (menuOpened)="openMenu(menu)"
      ></app-object-content-dropdown-tab>

    </ng-container>

  </ul>
</li>
