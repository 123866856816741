import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {AnnotationHandler} from '../annotation-handler';

export interface AnnotationDialogComponentData {
  file: SuperObjectModel;
  parentObject: SuperObjectModel;
  parentId: string;
  curAnn: AnnotationHandler;
}

@Component({
  selector: 'app-annotation-dialog',
  templateUrl: './annotation-dialog.component.html',
  styleUrls: ['./annotation-dialog.component.scss']
})
export class AnnotationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AnnotationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AnnotationDialogComponentData) {
  }

  ngOnInit() {
  }

}
