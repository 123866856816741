import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonsService} from '../../core/commons.service';
import {ProgressDialogComponent} from '../../shared/progress-dialog/progress-dialog.component';
import {ObjectStorageService} from '../../core/object-storage.service';
import {MatDialog} from '@angular/material/dialog';
import {ObjectMediaContainer} from '../../core/definitions/object-media-container';
import {MediaItem} from '../../core/definitions/media-item';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {MediaTypeChanges} from '../media-type-changes';

@Component({
  selector: 'app-change-media-order',
  templateUrl: './change-media-order.component.html',
  styleUrls: ['./change-media-order.component.scss']
})
export class ChangeMediaOrderComponent implements OnInit {

  modalRef;
  changes = false;
  private mediaTypesChangeContainer: { [name: string]: MediaTypeChanges } = {};

  @Input() mediaContainer: ObjectMediaContainer;
  @Input() sectionsContainer: SectionsContainer;
  @Output() toggleChangeMediaOrder = new EventEmitter<MediaTypeChanges[]>();

  constructor(private commons: CommonsService,
              private modalService: MatDialog,
              private objectStorage: ObjectStorageService) {

  }

  ngOnInit() {
  }

  onChanges(mediaTypeChanges: MediaTypeChanges) {
    this.changes = true;
    this.mediaTypesChangeContainer[mediaTypeChanges.objectMediaType.mediaType] = mediaTypeChanges;
  }

  getOrderedMediaItems(artifactArray: MediaItem[]): MediaItem[] {
    return this.commons.sortArray(artifactArray, 'order_number');
  }

  async saveChanges() {
    const mediaTypeChangeList = Object.values(this.mediaTypesChangeContainer);
    if (mediaTypeChangeList.length) {
      this.modalRef = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
      for (const mediaTypeChanges of mediaTypeChangeList) {
        const mediaItems = this.getOrderedMediaItems(mediaTypeChanges.mediaItems);
        this.sectionsContainer.rootObject[mediaTypeChanges.objectMediaType.objectMediaArrayName] = mediaItems;
        await this.loopAndStoreMediaItems(mediaItems);
      }
      this.modalRef.close();
    }
    this.closeAndUpdate();
  }

  async loopAndStoreMediaItems(mediaItems: MediaItem[]) {
    for (const mediaItem of mediaItems) {
      try {
        // Storing media items with the new order number
        const mediaItemStored = await this.objectStorage.storeObject(mediaItem);
        console.log(`Stored: ${mediaItemStored}`);
        // mediaItem.image_id = img.artifact_id;
      } catch (reason) {
        console.error('Store failed with message: ' + reason.error.message, reason);
      }
    }
  }

  closeAndUpdate() {
    this.toggleChangeMediaOrder.emit(Object.values(this.mediaTypesChangeContainer));
  }

  closeAndReset() {
    this.toggleChangeMediaOrder.emit();
  }

}
