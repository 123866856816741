import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperationMenusObjectComponent} from './operation-menus-object/operation-menus-object.component';
import {TranslateModule} from '@ngx-translate/core';
import {OperationMenusObjectSmallComponent} from './operation-menus-object-small/operation-menus-object-small.component';
import {OperationViewObjectComponent} from './operation-view-object/operation-view-object.component';
import {OperationViewSectionContainerComponent} from './operation-view-section-container/operation-view-section-container.component';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {OperationToolbarComponent} from './operation-toolbar/operation-toolbar.component';
import {OperationViewUploadComponent} from './operation-view-upload/operation-view-upload.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OperationSelectorVerticalComponent} from './operation-selector-vertical/operation-selector-vertical.component';
import {OperationViewDialogComponent} from './operation-view-dialog/operation-view-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { OperationMenusTwoLevelsComponent } from './operation-menus-two-levels/operation-menus-two-levels.component';
import { OperationToolbarDialogComponent } from './operation-toolbar-dialog/operation-toolbar-dialog.component';
import { OperationMenusContentListComponent } from './operation-menus-content-list/operation-menus-content-list.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { OperationStepIndicatorComponent } from './operation-step-indicator/operation-step-indicator.component';
import { OperationViewInformationComponent } from './operation-view-information/operation-view-information.component';
import { OperationDropdownMenuComponent } from './operation-dropdown-menu/operation-dropdown-menu.component';
import {SharedModule} from '../shared/shared.module';
import { OperationDropdownMenuForSearchContainerComponent } from './operation-dropdown-menu-for-search-container/operation-dropdown-menu-for-search-container.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [OperationMenusObjectComponent, OperationMenusObjectSmallComponent, OperationViewObjectComponent,
    OperationViewSectionContainerComponent, OperationToolbarComponent, OperationViewUploadComponent, OperationSelectorVerticalComponent,
    OperationViewDialogComponent,
    OperationMenusTwoLevelsComponent,
    OperationToolbarDialogComponent,
    OperationMenusContentListComponent,
    OperationStepIndicatorComponent,
    OperationViewInformationComponent,
    OperationDropdownMenuComponent,
    OperationDropdownMenuForSearchContainerComponent],
  exports: [
    OperationMenusObjectComponent,
    OperationMenusObjectSmallComponent,
    OperationViewObjectComponent,
    OperationToolbarComponent,
    OperationSelectorVerticalComponent,
    OperationMenusTwoLevelsComponent,
    OperationMenusContentListComponent,
    OperationDropdownMenuComponent,
    OperationDropdownMenuForSearchContainerComponent,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ObjectEditModule,
    MediaSelectorModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    SharedModule,
    MatExpansionModule,
    MatListModule,
    RouterModule
  ]
})
export class OperationsModule {
}
