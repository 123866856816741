import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {LoggerService} from "./logger.service";

@Injectable({
  providedIn: 'root'
})
export class CmsQueueService {

  constructor(private readonly logger: LoggerService,
              private readonly cms: CmsApiService) {
  }

  private callQueue = {};
  private errorCallbackQueue = {}
  private cachedCmsData = {};
  private maxCachedTime = 10 * 60 * 1000;

  public async runCmsFnWithQueue(fn, params, noCache, callback, errorCallback) {
    const fnName = fn.name;
    const paramKey = this.getParamKey(fnName, params);
    const cachedData = this.getCachedData(paramKey);

    if (cachedData && !noCache) {
      callback(cachedData);
    } else {
      if (!this.getCallQueue(fnName, paramKey, true)) {
        this.createCallQueue(fnName, paramKey, callback, errorCallback);
        try {
          const data = await this.cms[fnName](params);
          this.setCachedData(paramKey, data);
          this.executeCallQueue(fnName, paramKey, data);
        } catch (e) {
          this.executeErrorCallbackQueue(fnName, paramKey, e);
          this.clearQueue(fnName, paramKey);
        }
      } else {
        this.addToCallQueue(fnName, paramKey, callback, errorCallback);
      }
    }
  }

  private getCachedData(paramKey) {
    let data = this.cachedCmsData[paramKey];
    let thisTime;
    if (data) {
      thisTime = new Date().getTime();
      if (thisTime > data.$$cachedTime + this.maxCachedTime) {
        this.cachedCmsData[paramKey] = null;
        data = null;
      }
    }
    return data;
  }

  private setCachedData(paramKey, data) {
    if (data) {
      data.$$cachedTime = new Date().getTime();
    }
    this.cachedCmsData[paramKey] = data;
  }

  private getParamKey(fnName, params) {
    let res = fnName;
    if (!params) {
      return res;
    }
    for (const [key, value] of Object.entries(params)) {
      if (typeof value !== 'object' && value !== undefined && value !== null) {
        res += `:${key}:${value.toString()}`;
      } else if (value) {
        for (const [subKey, subVal] of Object.entries(value)) {
          if (typeof subVal !== 'object') {
            res += `:${key}:${subKey}:${subVal}`;
          } else if (Array.isArray(subVal)) {
            res += `:${key}:${subKey}:${subVal.join(':')}`;
          }
        }
      }
    }
    return res;
  }

  private createCallQueue(fnName, paramKey, callback, errorCallback) {
    if (callback) {
      if (!this.callQueue[fnName]) {
        this.callQueue[fnName] = {};
      }
      this.callQueue[fnName][paramKey] = {
        queue: [],
        time: new Date().getTime()
      };
    }
    if (errorCallback) {
      if (!this.errorCallbackQueue[fnName]) {
        this.errorCallbackQueue[fnName] = {};
      }
      this.errorCallbackQueue[fnName][paramKey] = [];
    }
    this.addToCallQueue(fnName, paramKey, callback, errorCallback);
  }

  private getCallQueue(functionName, paramKey, checkTimeout?) {
    let res, paramQueue;
    const functionQueue = this.callQueue[functionName];
    if (functionQueue) {
      paramQueue = functionQueue[paramKey];
      if (paramQueue) {
        if (checkTimeout &&
          paramQueue.time + 10000 < new Date().getTime()) {
          this.logger.warn(`Queue timed out for ${functionName} with params ${paramKey}`);
        } else {
          res = paramQueue.queue;
        }
      }
    }
    return res;
  }

  private addToCallQueue(fnName, paramKey, callback, errorCallback) {
    if (callback) {
      const queue = this.getCallQueue(fnName, paramKey);
      if (queue) {
        queue.push(callback);
      }
    }
    if (errorCallback) {
      this.errorCallbackQueue[fnName][paramKey].push(errorCallback);
    }
  }

  private clearQueue(fnName, paramKey) {
    this.callQueue[fnName][paramKey] = null;
    this.errorCallbackQueue[fnName][paramKey] = null;
  }

  private executeCallQueue(fnName, paramKey, data) {
    const queue = this.getCallQueue(fnName, paramKey);
    if (queue && queue.length > 0) {
      for (const callback of queue) {
        callback(data);
      }
    } else {
      this.logger.warn(`No queue for ${paramKey}`)
    }
    this.clearQueue(fnName, paramKey);
  }

  private executeErrorCallbackQueue(fnName, paramKey, exception) {
    const queue = this.errorCallbackQueue[fnName][paramKey];
    if (queue) {
      for (const errorCallback of queue) {
        errorCallback(exception);
      }
    }
  }
}
