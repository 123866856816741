<div mat-dialog-content
     class="modal-body modal-body-edit-fields culture-hub-fields-container">
  <div class="modal-main-content">

    <div class="culture-hub-fields">
      <form *ngIf="options && options.length > 1" class="culture-hub-fields__options-field">
        <mat-form-field appearance="outline">
          <mat-label [translate]="cultureHubData.sub_type_headline"></mat-label>
          <mat-select [(value)]="selectedOption.value">
            <mat-option *ngFor="let option of options" [value]="option.value">
              <span [translate]="option.label"></span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <form class="culture-hub-fields__search-field ">
        <mat-form-field appearance="outline">
          <mat-label translate="TRANS__CULTURE_HUB_IMPORTER__SEARCH_LABEL"></mat-label>
          <input matInput #query
                 placeholder="{{'TRANS__CULTURE_HUB_IMPORTER__SEARCH_PLACEHOLDER' | translate}}"
                 [value]="initQuery"
                 (change)="runSearch(query.value)">
          <mat-icon matPrefix color="accent">search</mat-icon>
          <button matSuffix
                  mat-button
                  type="button"
                  (click)="runSearch(query.value)"
                  color="accent">
            <span class="uppercase bold" translate="TRANS__FILTER__SEARCH_PLACEHOLDER"></span>
          </button>
        </mat-form-field>
      </form>

      <div class="culture-hub-table-container">
        <div class="md-table admin-scroll-layout culture-hub-table">
          <table mat-table [dataSource]="cultureHubConceptDataSource">
            <caption></caption>
            <ng-container matColumnDef="selected">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox (click)="$event.stopPropagation()"
                              [checked]="!!findSelected(element)"
                              (change)="!!findSelected(element) ? deselect(element): selection.select(element)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="conceptName">
              <th mat-header-cell *matHeaderCellDef><span
                translate="TRANS__CULTURE_HUB_IMPORTER__COLUMN__CONCEPT"></span></th>
              <td mat-cell *matCellDef="let element">
                <a href="https://kulturnav.org/{{element.uuid}}" target="_blank">{{element.conceptName}}</a>
              </td>
            </ng-container>

            <ng-container matColumnDef="dataSet">
              <th mat-header-cell *matHeaderCellDef><span
                translate="TRANS__CULTURE_HUB_IMPORTER__COLUMN__DATA_SET"></span></th>
              <td mat-cell *matCellDef="let element">{{element.dataSet}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="conceptColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: conceptColumns;"></tr>

            <tr class="mat-row culture-hub-table__no-data" *matNoDataRow>
              <td class="mat-cell" colspan="4">
              <span class="tone-down-color"
                    *ngIf="query.value && noResults && !searching"
                    translate="TRANS__CULTURE_HUB_SEARCH__NO_SEARCH_HITS"></span>
                <span class="tone-down-color"
                      *ngIf="!query.value && !searching"
                      translate="TRANS__CULTURE_HUB_SEARCH__NO_SEARCH"></span>
                <span *ngIf="searching" class="culture-hub-table__no-data-spinner">
                <mat-spinner color="accent" diameter="16"></mat-spinner>
                <span class="culture-hub-table__no-data-spinner-text"
                      translate="TRANS__CULTURE_HUB_SEARCH__SEARCHING"></span>
              </span>

              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="culture-hub-fields__selected-terms">
        <span class="bold" translate="TRANS__MODAL_DIALOG__YOU_HAVE_CHOSEN"></span>
        <mat-chip-listbox *ngIf="selection.selected.length > 0">
          <mat-chip-option *ngFor="let selected of selection.selected"
                    (removed)="deselect(selected)">
            {{selected.conceptName}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>


    </div>

  </div>

</div>
<div mat-dialog-actions class="modal-footer dialog-border-color culture-hub-footer-container">
  <span class="culture-hub-footer-container__feedback">
    <ng-container *ngFor="let error of errors">
      <span *ngIf="errors.length" class="error-warn-color bold" [translate]="error"></span></ng-container>
  </span>

  <button mat-flat-button
          [disabled]="importing || searching || selection.isEmpty()"
          color="accent"
          type="submit"
          (click)="runImport()">
    <span *ngIf="!importing" class="bold" translate="TRANS__CULTURE_HUB_IMPORTER__RUN_IMPORT"></span>
    <span *ngIf="importing" class="culture-hub-footer-container__downloading">
      <mat-spinner diameter="16" id="culture-hub-downloading"></mat-spinner>
      <span class="bold" translate="TRANS__CULTURE_HUB_SEARCH__IMPORTING"></span></span>
  </button>
  <button mat-button
          type="button"
          color="accent"
          (click)="closeImport()">
    <span class="bold" translate="TRANS__BUTTON__CLOSE"></span></button>
</div>
