@if (sectionsContainer) {
  <app-m3-global-kpl-styles>
    <app-object-overview-fields
      [objectView]="sectionsContainer.objectView"
      [isEditing]="isEditing"
    ></app-object-overview-fields>

    <app-object-view-and-edit
      [isEditing]="isEditing"
      [sectionsContainer]="sectionsContainer"
      [templateGroupId]="contentInfo.templateGroupId"
    ></app-object-view-and-edit>

    @if (isEditing && operationStep) {
      <app-operation-toolbar
        [operationContainer]="operationContainer"
        [operationStep]="operationStep"
      >
      </app-operation-toolbar>
    }
  </app-m3-global-kpl-styles>
}
