import { Injectable } from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {AConst} from './a-const.enum';

const PRIMUS_OPTIONS = 'primus';
const COPY_OPTIONS = 'copyOptions';
const FOCUS_OPTIONS = 'focusOptions';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private storage = localStorage;
  constructor(private cms: CmsApiService) { }

  public async getCopyOptions(): Promise<any> {
    return await this.getUserOptions(COPY_OPTIONS);
  }

  public storeCopyOptions(options) {
    this.storeOptions(COPY_OPTIONS, options);
  }

  private loadData(storeId) {
    let res;
    const data = this.storage.getItem(storeId);
    try {
      res = JSON.parse(data) || {};
    } catch (e) {
      res = {};
      console.warn('Parsing ' + data + ' failed');
    }
    return res;
  }

  private storeData(storeId, data) {
    this.storage.setItem(storeId, JSON.stringify(data));
  }

  private async getOptionStoreId(optionType): Promise<any> {
    const data = await this.cms.getUserData();
    return PRIMUS_OPTIONS + '-' + data[AConst.USER][AConst.ARTIFACT_ID] +
      '-' + optionType;
  }

  private async getUserOptions(optionType) {
    const storeId = await this.getOptionStoreId(optionType);
    return this.loadData(storeId);
  }

  private storeOptions(optionType, options) {
    this.getOptionStoreId(optionType).then((storeId) => {
      this.storeData(storeId, options);
    });
  }

}
