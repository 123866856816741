import {Component, Input, OnChanges} from '@angular/core';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {FieldParameters} from '../../../core/definitions/field-parameters';

@Component({
  selector: 'app-edit-field-inline-array-delete-button',
  templateUrl: './edit-field-inline-array-delete-button.component.html',
  styleUrls: ['./edit-field-inline-array-delete-button.component.scss']
})
export class EditFieldInlineArrayDeleteButtonComponent implements OnChanges {
  @Input() fieldParameters: FieldParameters;
  @Input() arrayIndex;
  @Input() checkUsage: boolean;

  confirm = false;

  constructor(private inlineArrayItemSvc: InlineArrayItemService) { }

  ngOnChanges() {
  }

  confirmDelete() {
    if (this.checkUsage) {

    } else {
      this.deleteItem();
    }

  }

  private deleteItem() {
    this.inlineArrayItemSvc.deleteInlineArrayItemFromForm(this.fieldParameters, this.arrayIndex);
  }

}
