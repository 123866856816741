import {Component, Input, OnChanges} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {SearchPageService} from '../search-page.service';
import {transition, trigger, useAnimation} from '@angular/animations';
import {slideInFromLeft, slideInToLeft} from '../../shared/animations';
import {SearchContainer} from '../../core/definitions/search-container';

@Component({
  selector: 'app-object-search-menu',
  templateUrl: './object-search-menu.component.html',
  styleUrls: ['./object-search-menu.component.scss'],
  animations: [
    trigger('slideInQueryMenu', [
      transition(':leave', [
        useAnimation(slideInToLeft, {params: {time: '750ms ease-in-out'}}),
      ]),
      transition(':enter', [
        useAnimation(slideInFromLeft, {params: {time: '750ms ease'}}),
      ]),
    ])
  ]
})
export class ObjectSearchMenuComponent implements OnChanges {
  @Input() isEditing: boolean;
  @Input() stylingClass: string;
  @Input() object;

  rootObjId: string;
  rootObjType: string;
  defaultSearchContainer: SearchContainer;
  isSearch = false;
  setSearchQueryFocus = false;
  newLayout = true;
  menuClasses = {};

  constructor(private searchPageService: SearchPageService,
              private primusRoute: PrimusRouteService) {
  }

  async ngOnChanges() {
    const stateParams = this.primusRoute.params;
    this.rootObjId = stateParams.rootObjId;
    this.rootObjType = stateParams.rootObjType;
    this.searchPageService.addSearchContainerCreationListener(searchContainer => {
      this.defaultSearchContainer = searchContainer;
    });
    this.menuClasses = this.getMenuClasses();
  }

  onToggleSearch(isSearch) {
    this.isSearch = isSearch;
  }

  getMenuClasses() {
      return {
        'edit': this.isEditing,
        'small-12': true,
        'medium-12': true,
        'large-pull-9': this.stylingClass !== 'sp-procedure-page' && this.stylingClass !== 'media-page',
        'large-pull-8': this.stylingClass !== 'sp-procedure-page' && this.stylingClass === 'media-page',
        'large-3': this.stylingClass !== 'sp-procedure-page' && this.stylingClass !== 'media-page',
        'large-4': this.stylingClass !== 'sp-procedure-page' && this.stylingClass === 'media-page',
        'large-12': this.stylingClass === 'sp-procedure-page',
        'columns': true
      };
  }

}
