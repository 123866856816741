import {Component, Input} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';

@Component({
  selector: 'app-edit-prime-fields',
  templateUrl: './edit-prime-fields.component.html',
  styleUrls: ['./edit-prime-fields.component.scss']
})
export class EditPrimeFieldsComponent {

  @Input() sectionsContainer: SectionsContainer;

}
