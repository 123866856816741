<!--<div class="user-info-section">-->
<!--  <div class="user-info-section__field"-->
<!--       *ngFor="let field of fields"-->
<!--       [ngClass]="{'no-value': fieldValue(field) && !enableEditMode}">-->
<!--    <app-standalone-edit-field [model]="user"-->
<!--                               [field]="field"-->
<!--                               [styleClass]="'user-form'"-->
<!--                               [edit]="enableEditMode">-->
<!--    </app-standalone-edit-field>-->
<!--  </div>-->

<!--</div>-->
<app-section-panel
  [sectionsContainer]="sectionsContainer"
  [sectionName]="'top'"
  [edit]="enableEditMode"
  [styleClass]="'user-form'"
></app-section-panel>
