<div style="margin: 5px 5px 5px 5px">
  <mat-form-field>
    <mat-label>Please select a service</mat-label>
    <mat-select [(value)]="selectedServicePath" (selectionChange)="setService()">
      <ng-container *ngFor="let endPoint of serviceRegister.end_points">
        <mat-option [value]="endPoint.path">{{endPoint.title}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <div *ngIf="selectedService">
    <form>
      <p>Selected service: {{selectedService.title}}</p>
      <ng-container *ngFor="let parameter of selectedService.parameters">
        <ng-container *ngIf="['add_to_path', 'string', 'number', 'list'].includes(parameter.param_type)">
          <mat-form-field>
            <mat-label>{{parameter.title}}</mat-label>
            <input matInput [formControl]="parameter.formControl">
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="parameter.param_type === 'object'">
          <mat-form-field>
            <mat-label>{{parameter.title}}</mat-label>
            <textarea matInput [formControl]="parameter.formControl"></textarea>
          </mat-form-field>
        </ng-container>
      </ng-container>
      <button type="submit" mat-button (click)="executeService()">Execute service</button>
    </form>
  </div>
  <div *ngIf="executeResult">
    <p>Last result:</p>
    <pre style="background: #b3b4b4">{{executeResult | json}}</pre>
  </div>
  <div *ngIf="errorMessage">
    <p>Error occurred:</p>
    <pre style="color: darkred">{{errorMessage}}</pre>
  </div>
</div>
