import {Injectable} from '@angular/core';
import {Folder} from './definitions/folder';
import {SearchParameters} from './definitions/search-parameters';
import {SolrFilterService} from './solr-filter.service';
import {SearchService} from "./search.service";
import {SettingsService} from "./settings.service";

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  constructor(private searchService: SearchService,
              private solrFilter: SolrFilterService,
              private settings: SettingsService) {
  }

  async setFilterItemsOnFolder(folders: Folder[]) {
    const foldersRelationField = this.settings.getClientConfig().FOLDERS_RELATION_FIELD || 'folders.folder_id';
    const params = {fl: ['artifact_id', foldersRelationField]} as SearchParameters;
    this.solrFilter.addFq(params, foldersRelationField, '[* TO *]');
    params.facets = [foldersRelationField];
    const searchResult = await this.searchService.search(params);
    for (const folder of folders) {
      folder.$$folderItemCount = 0;
      const item = searchResult.facets[0].items.find(i => i.id === folder.artifact_id);
      if (item) {
        folder.$$folderItemCount = item.count;
      }
    }
  }
}
