import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {MetaField} from '../../core/definitions/meta-field';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {FieldConditionService} from '../../core/field-condition.service';
import {IfType} from '../../core/definitions/field-if';
import {FieldInputType} from "../../core/definitions/field-input-type.enum";

@Component({
  selector: 'app-edit-field-inline-field',
  templateUrl: './edit-field-inline-field.component.html',
  styleUrls: ['./edit-field-inline-field.component.scss']
})
export class EditFieldInlineFieldComponent implements OnInit {

  @Input() displayRequiredIndication: boolean;
  @Input() subFieldIndex;
  @Input() sectionsContainer: SectionsContainer;
  @Input() grandParentObject;
  @Input() object;
  @Input() field: MetaField;
  @Input() index;
  @Input() parentIndex;

  private fieldParameters: FieldParameters;

  constructor(private fieldConditionService: FieldConditionService) {
  }

  ngOnInit(): void {
    const fieldParameters = new FieldParameters();
    fieldParameters.field = this.field;
    fieldParameters.object = this.object;
    fieldParameters.rootObject = this.sectionsContainer.rootObject;
    fieldParameters.grandParentObject = this.grandParentObject;
    fieldParameters.parentIndex = this.parentIndex;
    fieldParameters.index = this.index;
    fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters = fieldParameters;
  }


  canShow(): boolean {
    return !this.field.$$hide && this.fieldConditionService.runIf(IfType.SHOW, this.fieldParameters).result;
  }

  protected readonly FieldInputType = FieldInputType;
}
