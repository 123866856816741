import {Injectable} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {RefParams, RefService} from '../../core/ref.service';
import {ModelsService} from '../../core/models.service';
import {CommonsService} from '../../core/commons.service';
import {FieldValueService} from '../../core/field-value.service';
import {FieldMetaService} from '../../core/field-meta.service';
import {InlineArrayHeaderContainer} from './inline-array-header-container';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {SearchResult} from '../../core/definitions/search-result';
import {FieldDisplayLabel, MetaField} from '../../core/definitions/meta-field';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {UserCacheService} from '../../core/user-cache.service';
import {SolrFilterService} from '../../core/solr-filter.service';
import {SearchService} from "../../core/search.service";
import {InlineViewService} from "../../core/inline-view.service";
import {SearchReferenceService} from "../../core/search-reference.service";
import {InlineViewItem} from "../../core/definitions/inline-view-item";
import {LoggerService} from "../../core/logger.service";

@Injectable({
  providedIn: 'root'
})
export class InlineArrayHeaderService {

  private showErrors = false;
  private cancelPromise;
  private errors = ['Set meta attribute to e.g. display=Dis(label=Lab(prefix=\':\u00A0\')) on these fields: '];

  constructor(private logger: LoggerService,
              private ref: RefService,
              private models: ModelsService,
              private commons: CommonsService,
              private searchService: SearchService,
              private fieldValueService: FieldValueService,
              private fieldMetaService: FieldMetaService,
              private inlineArrayItemSvc: InlineArrayItemService,
              private userCacheService: UserCacheService,
              private solrFilter: SolrFilterService,
              private inlineViewService: InlineViewService,
              private searchReferenceService: SearchReferenceService) {
    this.userCacheService.getUserData().then((userData) => {
      if (['kenneth.oden@kulturit.no',
        'rita.johnsen@kulturit.no'].indexOf(userData.username) !== -1) {
        this.showErrors = true;
      }
    });
  }

  async createContainer(rootObject, field, arrayIndex, parentIndex): Promise<InlineArrayHeaderContainer> {
    const container = new InlineArrayHeaderContainer();
    const fieldParameters = new FieldParameters();
    fieldParameters.sectionsContainer = new SectionsContainer();
    fieldParameters.sectionsContainer.rootObject = rootObject;
    fieldParameters.field = field;
    fieldParameters.index = arrayIndex;
    fieldParameters.parentIndex = parentIndex;
    const items = this.inlineArrayItemSvc.getArrayItems(fieldParameters);
    container.arrayItem = items[arrayIndex];
    const metaProps = await this.getMetaProps(container.arrayItem);
    container.labels = metaProps.labels;
    container.headlineFields = metaProps.headlineFields;
    this.setLabelIcon(container);

    return container;
  }

  setValues(headerContainer: InlineArrayHeaderContainer, repeatSetValueTime?) {
    let waitTime = repeatSetValueTime;
    if (!repeatSetValueTime) {
      waitTime = 100;
    }
    if (headerContainer.timeOutId) {
      clearTimeout(headerContainer.timeOutId);
    }
    headerContainer.timeOutId = setTimeout(() => {
      headerContainer.values = headerContainer.values || {};
      headerContainer.labels.forEach((label) => {
        let parentObject;
        parentObject = this.commons.getObjectValueFromPath(headerContainer.arrayItem, label.path);
        headerContainer.values[label.key] = this.fieldValueService.getFieldTextValue(parentObject, label.name);
      });
      if (repeatSetValueTime) {
        this.setValues(headerContainer, repeatSetValueTime);
      }
    }, waitTime);
  }

  async getLabelFields(
    rootItem: SuperObjectModel, meta: { [name: string]: MetaField }, parentMeta?: MetaField): Promise<Array<FieldDisplayLabel>> {
    let sortMeta, labelFields = [];
    const inlineView = await this.inlineViewService.getInlineView(rootItem.object_type);
    if (meta) {
      sortMeta = this.getSortedMetaData(meta, inlineView, rootItem);
      for (const element of sortMeta) {
        const mi = element;
        const type = this.getLabelType(mi);
        if (type === 'inline') {
          const subLabelFields = await this.getSubLabelFields(rootItem, mi);
          labelFields = labelFields.concat(subLabelFields);
        } else {
          const label = await this.getLabelProps(rootItem, mi, parentMeta);
          labelFields.push(label);
        }
      }
    } else {
      console.warn('Label item not set');
    }
    if (this.showErrors) {
      this.checkMissingPrefixes(rootItem, labelFields);
    }
    return labelFields;
  }

  private async getMetaProps(item: SuperObjectModel): Promise<any> {
    const res = {
      labels: [],
      headlineFields: []
    };
    if (!item['$$label'] && this.fieldMetaService.checkSetMetaData(item)) {
      res.labels = await this.getLabelFields(item, item.$$meta);
    }
    return res;
  }

  private setLabelIcon(container: InlineArrayHeaderContainer) {
    container.labels.forEach((label) => {
      const icon = label.icon;
      let artifactId: string;
      if (icon) {
        if (icon === '{conceptIcon}') {
          artifactId = container.arrayItem[label.key];
          if (artifactId) {
            const params = {} as SearchParameters;
            this.solrFilter.addFq(params, 'artifact_id', artifactId);
            this.solrFilter.addFq(params, 'object_type', artifactId.split('-')[0]);
            this.searchService.search(params).then((res: SearchResult) => {
              let art;
              if (res.artifacts) {
                art = res.artifacts[0];
                container.arrayItem.icon = art.icon;
                container.arrayItem.icon_frame = art[AConst.ICON_FRAME];
              }
            });
          }
        } else {
          container.arrayItem.icon = label.icon;
        }
      }
    });
  }

  private async getLabelProps(rootItem, mi: MetaField, parentMeta?: MetaField): Promise<FieldDisplayLabel> {
    let inlineViewItem = await this.inlineViewService.getInlineViewForField(rootItem, mi);
    const label = <FieldDisplayLabel>inlineViewItem || new FieldDisplayLabel();
    let pLabel: FieldDisplayLabel;
    const labelVal = rootItem[mi.name];
    let objectType = '';
    let reference = null;
    if (mi.reference_id || mi.reference) {
      reference = this.searchReferenceService.getSearchReferenceFromField(mi);
      objectType = reference?.object_type || reference?.add_new_params?.new_object_type || '';
    }
    label.type = this.getLabelType(mi);
    label.title = mi.title;
    label.name = mi.name;
    label.key = mi.name;
    label.path = '';
    if (labelVal && mi.field_type === AConst.MAP_ID && objectType.indexOf('ct_') !== 0 && objectType !== 'MetaModelSection') {
      const object = new SuperObjectModel();
      object.artifact_id = labelVal;
      object.object_type = objectType;
      object.meta_type = reference?.meta_type;
      const sRef = await this.ref.makeRef(new RefParams(object));
      if (sRef) {
        label.sRef = sRef;
        label.sRef.param.reload = true;
      }
    }

    if (parentMeta) {
      label.key = parentMeta.name + '.' + label.key;
      const parentInlineView = await this.inlineViewService.getInlineViewForField(rootItem, parentMeta);
      pLabel = <FieldDisplayLabel>parentInlineView || new FieldDisplayLabel();
      label.title = label.title || parentMeta.title;
      label.prefix = label.prefix || pLabel.prefix;
      label.append = label.append || pLabel.append;
      label.css_class_edit = label.css_class_edit || pLabel.css_class_edit;
      label.path = parentMeta.name;
    }
    return label;
  }

  private checkMissingPrefixes(rootItem: SuperObjectModel, labelFields) {
    const origErrLen = this.errors.length;
    labelFields.forEach((label, index) => {
      let error;
      if (!label.prefix && index > 0 && !labelFields[index - 1].append) {
        error = rootItem.object_type + ': ' + label.key;
        if (this.errors.indexOf(error) === -1) {
          this.errors.push(error);
        }
      }
    });
    if (this.errors.length > origErrLen) {
      if (this.cancelPromise) {
        clearTimeout(this.cancelPromise);
      }
      this.cancelPromise = setTimeout(() => {
        let errStr = '';
        this.errors.forEach((error) => {
          errStr += error + '\n';
        });
        window.alert(errStr);
      }, 4000);
    }
  }

  private async getSubLabelFields(rootItem: SuperObjectModel, parentMeta: MetaField): Promise<Array<FieldDisplayLabel>> {
    const meta = this.models.getModelMeta(this.getInlineModel(parentMeta));
    return this.getLabelFields(rootItem, meta, parentMeta);
  }

  private getInlineModel(metaField: MetaField) {
    return metaField.inline ? metaField.inline.model : null;
  }

  private getLabelType(metaField: MetaField) {
    let fieldType = metaField.field_type;
    const inlineModel = this.getInlineModel(metaField);
    if (inlineModel === 'Timespan') {
      fieldType = 'timespan';
    }
    return fieldType;
  }

  private getSortedMetaData(meta: { [name: string]: MetaField },
                            inlineViewItems: InlineViewItem[],
                            rootItem: SuperObjectModel) {
    let metaArr = [];
    if (!meta) {
      return metaArr
    }
    if (inlineViewItems) {
      metaArr = this.sortMetaDataUsingInlineViewItems(meta, inlineViewItems);
    } else {
      metaArr = this.getVisibleMetaFields(meta, rootItem);
    }
    return metaArr;
  }

  private sortMetaDataUsingInlineViewItems(meta: { [name: string]: MetaField }, inlineViewItems: InlineViewItem[]) {
    const res = [];
    for (const inlineViewItem of inlineViewItems) {
      for (const [metaFieldName, mi] of Object.entries(meta)) {
        if (metaFieldName !== inlineViewItem.field) {
          continue;
        }
        const show = mi.display;
        if (show === 'yes' && mi.field_type !== 'array') {
          res.push(mi);
        }
      }
    }
    return res;
  }

  private getVisibleMetaFields(meta: { [name: string]: MetaField }, rootItem: SuperObjectModel) {
    let res = [];
    for (const mi of Object.values(meta)) {
      const show = mi.display;
      if (show === 'yes' && mi.field_type !== 'array') {
        res.push(mi);
      }
    }
    if (res.length > 1) {
      this.logger.warn(`Inline view data may be missing for object type "${rootItem.object_type}"`)
    }
    return res;
  }
}
