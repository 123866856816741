<div class="object-content-list-source-array-filter">
  <button mat-stroked-button
          [matMenuTriggerFor]="sourceFilter"
          (menuOpened)="dropdownMenu = true;"
          (menuClosed)="dropdownMenu = false;"
          class="object-content-list-source-array-filter__button"
          [ngClass]="{'active': dropdownMenu}">
    <mat-icon class="object-content-list-source-array-filter__button-icon">filter_alt</mat-icon>
    <span class="object-content-list-source-array-filter__button-text"
          translate="TRANS__FILTER__FILTRATE"></span>
    <mat-icon class="object-content-list-source-array-filter__button-arrow">{{dropdownMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>

  <mat-menu #sourceFilter="matMenu">
    <mat-accordion class="object-content-list-source-array-filter__accordion" displayMode="flat">
      <mat-expansion-panel
        #currentPanel
        *ngFor="let filterGroup of contentListSource.filterGroups; index as i"
        (click)="$event.stopPropagation()"
        (opened)="setStep(i)"
        class="no-active-blue-color object-content-list-source-array-filter__panel"
        [class.is-expanded]="currentPanel.expanded"
        [class.is-collapsed]="!currentPanel.expanded">

        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
          <mat-panel-title [translate]="filterGroup.title"></mat-panel-title>
        </mat-expansion-panel-header>

        <ul class="object-content-list-source-array-filter__items"
            *ngFor="let filterField of filterGroup.filters">
          <li *ngFor="let item of filterField.items"
              class="object-content-list-source-array-filter__item">
            <mat-checkbox (click)="setFilter($event, item)" [checked]="item.selected">
              <span *ngIf="item.name" class="object-content-list-source-array-filter__item-text-span"
                    [ngClass]="{'bold': item.selected}"
                    [translate]="item.name"></span>
              <span class="object-content-list-source-array-filter__countText"
                    [ngClass]="{'bold': item.selected}"
                    *ngIf="item.count">({{item.count}})</span></mat-checkbox>
          </li>
        </ul>

      </mat-expansion-panel>
    </mat-accordion>
  </mat-menu>
</div>
