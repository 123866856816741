import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {QueryField} from '../../../../../core/definitions/advanced-search/query-field';

@Component({
  selector: 'app-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss']
})
export class BooleanInputComponent implements OnChanges {

  @Input() field: QueryField;
  @Input() label: string;
  @Input() placeholder: string;

  @Output() public readonly searchValueChanged: EventEmitter<string | null>;

  selected: boolean;

  constructor() {
    this.searchValueChanged = new EventEmitter<string | null>();
    this.selected = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('field')) {
      this.field = changes.field.currentValue;
      this.selected = this.field?.searchValue === 'true';
    }
  }

  toggle(): void {
    this.selected = !this.selected;
    this.searchValueChanged.emit(this.selected ? 'true' : 'false');
  }

}
