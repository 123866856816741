export enum FieldType {
  ACTION_BUTTON = 'action_button',
  ANY = 'any',
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  COORDINATE = 'coordinate',
  DATE_ISO = 'date_iso',
  DATE_TIME_ISO = 'date_time_iso',
  DECIMAL = 'decimal',
  DYNAMIC_FIELDS = 'dynamic_fields',
  EXTERNAL = 'external',
  ID = 'id',
  IDENTIFIER = 'identifier',
  INLINE = 'inline',
  JSON = 'json',
  MAP_ID = 'map_id',
  NAME = 'name',
  NUMERIC = 'numeric',
  OBJECT_USAGE = 'object_usage',
  OPTION_STRING = 'option_string',
  PASSWORD = 'password',
  STRING = 'string',
  TEXT = 'text',
  URL = 'url'
}
