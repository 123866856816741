export class PerformanceTimer {

  constructor(timerName: string) {
    this.timerName = timerName?.trim();
  }
  private readonly logPrefix = '[PERF]';
  private readonly timerName: string;
  private startTime?: number;
  private lastMeasure?: number;

  private static logTimeSpent(message: string, time: number, warnAboveTime: number, errAboveTime: number): void {
    const txt = `${message}: ${time}ms`;
    if (time > errAboveTime) {
      console.error(`${txt} (Exceeding ${errAboveTime}ms)`);
    } else if (time > warnAboveTime) {
      console.warn(`${txt} (Exceeding ${warnAboveTime}ms)`);
    } else {
      console.log(txt);
    }
  }

  start() {
    this.startTime = Date.now();
    console.group(this.logPrefix, this.timerName);
  }

  stop(msg: string = 'Done', warnAboveTime: number = 1000, errAboveTime: number = (warnAboveTime + 4000)) {
    this.measure(msg, warnAboveTime, errAboveTime);
    console.groupEnd();
  }

  measure(msg: string, warnAboveTime: number = 1000, errAboveTime: number = (warnAboveTime + 4000)) {
    if (!this.startTime) {
      console.error(this.logPrefix, `Timer '${this.timerName}' not started! Call start() before measure()`);
      return;
    }
    const timeSinceStart = Date.now() - this.startTime;
    const timeSinceLastMeasure = this.lastMeasure ? Date.now() - this.lastMeasure : timeSinceStart;
    this.lastMeasure = Date.now();

    console.group(msg);
    PerformanceTimer.logTimeSpent('Time since last measure', timeSinceLastMeasure, warnAboveTime, errAboveTime);
    PerformanceTimer.logTimeSpent('Time since start', timeSinceStart, warnAboveTime, errAboveTime);
    console.groupEnd();
  }

}
