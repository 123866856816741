import {SectionsContainer} from './sections-container';
import {MetaField} from './meta-field';
import {SuperObjectModel} from './super-object-model';
import {BaseModel} from './base-model';

export class FieldParameters {
  sectionsContainer: SectionsContainer;
  rootObject: SuperObjectModel;
  grandParentObject: BaseModel;
  object: BaseModel;
  field: MetaField;
  edit: boolean;
  index: number;
  parentIndex: number;
  prevObject: SuperObjectModel;
  useMultipleSetter: boolean;
  canAddNew: boolean;
  canDelete = true;
}
