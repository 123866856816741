import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';
import {OperationContainer} from '../../core/definitions/operation-container';

@Component({
  selector: 'app-procedure-content-list',
  templateUrl: './procedure-content-list.component.html',
  styleUrls: ['./procedure-content-list.component.scss']
})
export class ProcedureContentListComponent implements OnChanges {

  constructor(private translateService: TranslateService) {
  }

  AConst = AConst;

  @Input() operationContainer: OperationContainer;
  @Input() smallScreen;
  @Input() isEditing;
  // @Input() actionMenuContainer: ActionMenuContainer;
  @Output() toggleMenu = new EventEmitter();
  @Output() procedureLoad = new EventEmitter();

  ngOnChanges() {
    console.log('here');
  }

  openModal() {
    this.operationContainer.openOperationDialogFn(this.operationContainer);
  }

  // async openSearchSelector() {
  //   const currentOperation = this.operationContainer.currentOperation;
  //   if (currentOperation) {
  //     if (!currentOperation.$$currentStep) {
  //       await this.operationService.setOperationStep(this.operationContainer);
  //     }
  //     this.operationContainer.enableSelector(currentOperation.$$currentStep.selectors[0]);
  //   }
  // }

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  loadProcedure() {
    this.procedureLoad.emit();
  }

  // Need to translate caption explicit due to bug in translation service that causes keys to not always be translated
  get caption() {
    let res = '';
    const caption = this.operationContainer.contentInfo?.selectedMainMenu?.caption;
    if (caption) {
      res = this.translateService.instant(caption);
    }
    return res;
  }
}
