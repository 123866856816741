<div class="admin-id-format-collection-view-wrapper">
  <div class="list-wrapper admin-scroll-layout">
    <app-admin-id-format-collection-list [formats]="formats"
                                         (selectionChange)="handleSelectionChange($event)">
    </app-admin-id-format-collection-list>
  </div>
  <div class="table-wrapper">
    <app-admin-id-format-edit-section
      heading="TRANS__ADMIN__ID_FORMATS__COLLECTION_HEADING"
      description="TRANS__ADMIN__ID_FORMATS__COLLECTION_DESCRIPTION"
      [canAddPrefixes]="!!selectedCollection"
      [formats]="formatsToDisplay"
      (addPrefixClicked)="handleAddOrEditPrefixClicked()"
      (editPrefixClicked)="handleAddOrEditPrefixClicked($event)"
      (formatDeleted)="formatDeleted.emit($event)">
    </app-admin-id-format-edit-section>
  </div>
</div>
