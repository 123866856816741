<div class="search-facet-range" [ngClass]="{'sideMenu': sideMenu, 'dropdownMenu': !sideMenu}">
  <div class="search-facet-range__content">
    <button mat-button (click)="toggle()" class="search-facet-range__title-button">
      <span class="search-facet-range__title-button-text-container">
        <span class="search-facet-range__title-button-text" [ngClass]="{'bold': sideMenu}"
              [translate]="group.title"></span>
        <mat-icon *ngIf="!sideMenu">{{group.enabled ? 'expand_less' : 'expand_more'}}</mat-icon>
      </span>
    </button>

    <div *ngIf="group.enabled" class="dropdownIgnoreClicks">
      <ul class="search-facet-range__items">
        <mat-radio-group [(ngModel)]="rangeSelected">
          <li class="search-facet-range__item"
              *ngFor="let range of group.facet_ranges; index as i">
            <mat-radio-button
              class="search-facet-range__radio-button gray-radio-button"
              (click)="checkRange($event, range)"
              [value]="range.title"
              [disabled]="isDisabled(range)"
              [checked]="range.checked">
            <span class="search-facet-range__radio-button-text"
                  [ngClass]="{'bold': range.checked, 'disabled': range.total_count === 0}"
                  [translate]="range.title"></span>
              <span class="search-facet-range__radio-button-count-text"
                    [ngClass]="{'bold': range.checked, 'disabled': range.total_count === 0}"
                    *ngIf="range.total_count">({{range.total_count}})</span>
            </mat-radio-button>

            <div class="search-facet-range__item-range"
                 *ngIf="!searchContainer.searching && range.editable && range.checked">
              <div *ngIf="range.field_type === 'solr_date'"
                   class="search-facet-range__item-range-content">
                <app-date-search-field
                  class="search-facet-range__item-range-first-field"
                  [parentModel]="range"
                  [propName]="'start'"
                  [lessThan]="'end'"
                  (dateChange)="setRange($event)"
                  (dateUpdate)="updateDate($event, 'start')"
                  [dateChangeArgs]="[group, range]"
                ></app-date-search-field>
                <app-date-search-field
                  [parentModel]="range"
                  [propName]="'end'"
                  [greaterThan]="'start'"
                  (dateChange)="setRange($event)"
                  (dateUpdate)="updateDate($event, 'end')"
                  [dateChangeArgs]="[group, range]"
                ></app-date-search-field>
              </div>
            </div>

          </li>
        </mat-radio-group>
      </ul>
    </div>
  </div>
</div>
