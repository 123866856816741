import { Component, OnInit } from '@angular/core';
import {AdminIdFormatService} from './admin-id-format.service';
import {IdentifierFormat} from './identifier-format';

@Component({
  selector: 'app-admin-id-format',
  templateUrl: './admin-id-format.component.html',
  styleUrls: ['./admin-id-format.component.scss']
})
export class AdminIdFormatComponent implements OnInit {

  private allIdFormats: Array<IdentifierFormat> = [];
  globalFormats: Array<IdentifierFormat> = [];
  collectionFormats: Array<IdentifierFormat> = [];
  parent;

  constructor(private idFormatService: AdminIdFormatService) { }

  async ngOnInit(): Promise<void> {
    this.parent = {
      path: 'admin',
      adminType: 'identificationNumber',
      hasSubMenus: true,
      target_params: {admin_type: 'identificationNumber'},
      currentState: 'id-format'
    };
    this.allIdFormats = await this.idFormatService.getAll();
    this.filterFormatsByGlobalAndCollection();
  }

  async handleFormatAdded(identifierFormat: IdentifierFormat): Promise<void> {
    if (!identifierFormat) {
      return;
    }
    identifierFormat = await this.idFormatService.save(identifierFormat);

    if (identifierFormat) {

      if (!!identifierFormat.artifact_id) {
        const idx = this.allIdFormats.findIndex(f => f.artifact_id === identifierFormat.artifact_id);
        if (idx > -1) {
          this.allIdFormats.splice(idx, 1);
        }
      }

      this.allIdFormats = [...this.allIdFormats, identifierFormat];
      this.filterFormatsByGlobalAndCollection();

    } else {
      console.error('Unable to save IdentifierFormat');
    }
  }

  private filterFormatsByGlobalAndCollection(): void {
    if (this.allIdFormats) {
      this.globalFormats = [...this.allIdFormats.filter(format => !format.identifier_format_collection_id)];
      this.collectionFormats = [...this.allIdFormats.filter(format => !!format.identifier_format_collection_id)];
    }
  }

  handleFormatDeleted(deletedFormat: IdentifierFormat): void {
    if (!!deletedFormat?.artifact_id && !!this.allIdFormats?.length) {
      const idx = this.allIdFormats.findIndex(format => format.artifact_id === deletedFormat.artifact_id);
      if (idx >= 0) {
        this.allIdFormats.splice(idx, 1);
        this.filterFormatsByGlobalAndCollection();
      }
    }
  }
}
