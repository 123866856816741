<div class="object-view-and-edit"
     [ngClass]="{'template-selector': this.sectionsContainer.isTemplate}">
  <div>
    <app-object-view
      *ngIf="!isEditing"
      [objectId]="sectionsContainer.rootObject.artifact_id"
      [sectionsContainer]="sectionsContainer"
      [templateGroupId]="templateGroupId || sectionsContainer.templateGroupId"
      [hideSectionTitle]="sectionsContainer.hideSectionTitle"
    ></app-object-view>
    <app-object-edit
      *ngIf="isEditing"
      [sectionsContainer]="sectionsContainer"
      (closeEdit)="onCloseEdit()"
    ></app-object-edit>
    <div *ngIf="sectionsContainer.debug" style="float:left;"><pre>{{sectionsContainer.rootObject | json}}</pre></div>
  </div>
</div>
