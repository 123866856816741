import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {AfterCloseOperation, OperationService} from '../../../operations/operation.service';
import {OperationDef} from '../../../core/definitions/operation-def';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-menu-actions',
  templateUrl: './search-menu-actions.component.html',
  styleUrls: ['./search-menu-actions.component.scss']
})
export class SearchMenuActionsComponent implements OnChanges {

  @Input() searchContainer: SearchContainer;
  @Input() reportContainer;
  @Input() operations: OperationDef[];
  @Input() disabled;
  @Output() operationPerformed = new EventEmitter<AfterCloseOperation>();

  AConst = AConst;
  actionMenuOperations: OperationDef[] = [];

  constructor(private operationService: OperationService) {
  }

  ngOnChanges() {
    this.setActionMenuOperations();
  }

  setActionMenuOperations() {
    if (this.searchContainer.operationContainer?.operations) {
      this.searchContainer.operationContainer.operations.forEach(operation => {
        if (operation.operation_type_id === 'manage_selections') {
          this.actionMenuOperations = operation.sub_operations;
        }
      });
    }
  }

  async toggleSearchActionMenu() {
    this.searchContainer.toggleSortMenu = false;
    this.searchContainer.toggleActionMenu = !this.searchContainer.toggleActionMenu;
  }

  canShowOperation(operation: OperationDef) {
    return this.operationService.canShowOperation(this.searchContainer.targetObject, operation);
  }

  async executeOperation(operation: OperationDef, parentOperation: OperationDef) {
    console.log('executeOperation', operation, parentOperation);
    await this.operationService.openOperationView(
      this.searchContainer.operationContainer, operation, parentOperation, operationRes => {
        this.operationPerformed.emit(operationRes);
      });
  }

  get selectedCount() {
    return this.searchContainer.selections.selectedItems.length;
  }


}
