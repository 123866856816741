import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {UserCollectionItems} from '../../core/definitions/user-collection-items';
import {UserVirtualCollectionItems} from '../../core/definitions/user-virtual-collection-items';

export enum UserPrivilege {
  GUEST = 'GUEST',
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPER_USER = 'SUPER_USER'
}

export default class User extends SuperObjectModel {
  superobject_type_id: string;
  object_type: string;
  meta_type: string;
  identifier: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  deactivated: boolean;
  rights_level_id: string;
  rights_level: string;
  created_by_id: string;
  created_by_id_value: string;
  main_collection_id: string;
  collections: UserCollectionItems[];
  virtual_collections: UserVirtualCollectionItems[];
  rights: Array<any>;
  ekultur_user: boolean;

  constructor(part?: Partial<User>) {
    super();
    if (part) {
      Object.keys(part).forEach(key => this[key] = part[key]);
    }
  }
}
