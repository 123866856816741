export class InlineViewItem {
  append: string[];
  checkbox: boolean;
  checkbox_field: string;
  css_class_edit: string;
  field: string;
  icon: string
  headline: boolean;
  new_line: boolean;
  order: number;
  prefix: string[];
  sub_views: InlineViewItem[];
  view: string;
}
