import {Component, Input, OnInit} from '@angular/core';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SearchSelectorService} from '../../core/search-selector.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {BaseModel} from '../../core/definitions/base-model';
import {SearchExecutorService} from '../search-executor.service';
import {SearchContainer} from '../../core/definitions/search-container';

@Component({
  selector: 'app-search-view-selector',
  templateUrl: './search-view-selector.component.html',
  styleUrls: ['./search-view-selector.component.scss'],
  animations: [
    trigger('slideSelector', [
      transition(':leave', [
        style({overflow: 'hidden'}),
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ]),

      transition(':enter', [
        style({overflow: 'hidden', transform: 'translateX(100%)'}),
        animate('200ms ease-out', style({transform: 'translateX(0%)'}))
      ])
    ])
  ]
})
export class SearchViewSelectorComponent implements OnInit {

  @Input() selectorContainer: SelectorContainer;
  @Input() modalIsOpen: boolean;
  @Input() stylingClass: string;

  setSearchQueryFocus = true;
  newLayout = false;
  searchPlaceholder = 'TRANS__HEADER__SEARCH_PLACEHOLDER';
  searchContainer: SearchContainer;
  nameObject: BaseModel;

  constructor(private searchSelectorService: SearchSelectorService,
              private searchExecutorService: SearchExecutorService) {
  }

  ngOnInit() {
    this.searchContainer = this.selectorContainer.searchContainer;
    if (this.searchContainer.selections.originalItems.length) {
      this.nameObject = new BaseModel(this.searchContainer.selections.originalItems[0].artifact_id);
    }
    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  disableSelector() {
    if (!this.selectorContainer) {
      console.error('A selector container must be set!');
    }
    this.searchSelectorService.disableSelector(this.selectorContainer);
  }

  cancelTemplate() {
    if (this.searchContainer.selections.selectCallback) {
      this.searchContainer.selections.selectCallback(undefined);
    } else {
      console.warn('Missing select callback');
    }
  }

}
