import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable()
export class AdminMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange.subscribe(() => {
      this.getTranslations();
    });

    this.getTranslations();
  }

  getTranslations() {
    this.itemsPerPageLabel = this.translate.instant('TRANS__ADMIN_PAGINATOR__ITEMS_PER_PAGE_LABEL');
    this.nextPageLabel = this.translate.instant('TRANS__ADMIN_PAGINATOR__NEXT_PAGE_LABEL');
    this.previousPageLabel = this.translate.instant('TRANS__ADMIN_PAGINATOR__PREVIOUS_PAGE_LABEL');
    this.firstPageLabel = this.translate.instant('TRANS__ADMIN_PAGINATOR__FIRST_PAGE_LABEL');
    this.lastPageLabel = this.translate.instant('TRANS__ADMIN_PAGINATOR__LAST_PAGE_LABEL');
    this.changes.next();
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return this.translate.instant('TRANS__ADMIN_PAGINATOR__RANGE', {startIndex, endIndex, length});
  }
}

