import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationContainer} from '../../core/definitions/operation-container';

@Component({
  selector: 'app-information-headline',
  templateUrl: './information-headline.component.html',
  styleUrls: ['./information-headline.component.scss']
})
export class InformationHeadlineComponent implements OnInit, OnChanges {

  @Input() operationContainer: OperationContainer;
  @Input() smallScreen;
  @Input() isEditing;
  @Input() currentGroupOperation: OperationDef;

  @Output() toggleChangeImageOrder = new EventEmitter<void>();
  @Output() toggleMenu = new EventEmitter();
  @Output() objectLoad = new EventEmitter();
  @Output() edit = new EventEmitter<object>();

  constructor( private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  loadObject() {
    this.objectLoad.emit();
  }

  get contentListHeadline() {
    let res = this.currentGroupOperation.menu_title;
    res = this.translateService.instant(res);
    return res;
  }

  setEdit() {
    this.edit.emit();
  }

  // async openSearchSelector() {
  //   const currentOperation = this.operationContainer.currentOperation;
  //   if (currentOperation) {
  //     if (!currentOperation.$$currentStep) {
  //       await this.operationService.setOperationStep(this.operationContainer);
  //     }
  //     this.operationContainer.enableSelector(currentOperation.$$currentStep.selectors[0]);
  //   }
  // }
}
