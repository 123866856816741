import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConceptListItem} from '../../../core/definitions/concepts';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';
import {ConceptMenu} from '../../admin-concept-lists/concept-menu';

@Component({
  selector: 'app-admin-list-item-action',
  templateUrl: './admin-list-item-action.component.html',
  styleUrls: ['./admin-list-item-action.component.scss']
})
export class AdminListItemActionComponent implements OnInit {

  @Input() concept: ConceptListItem;
  @Input() conceptsContainer: ConceptsContainer;
  @Output() addChild = new EventEmitter<void>();

  menus: Array<ConceptMenu> = [];

  private addChildMenu: ConceptMenu = {
    icon: 'playlist_add',
    title: 'TRANS__CONCEPT_ACTION__MENU__ADD_CHILD',
    action: (() => {
      this.addChildConcept();
    }),
    disabled: (() => {
      return false;
    })
  };

  ngOnInit(): void {
    if (this.conceptsContainer.conceptType.is_hierarchic) {
      this.menus.push(this.addChildMenu);
    }
  }

  private addChildConcept() {
    this.addChild.emit();
  }

}
