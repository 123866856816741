<div class="template-group">
  <app-top-header backRouterLink="/admin"
                  backRouterLinkLabel="TRANS__SECTION__ADMIN__TITLE"></app-top-header>
  <app-header-and-description heading="TRANS__TEMPLATE_GROUP__TITLE"
                              description="TRANS__TEMPLATE_GROUP__INGRESS">
  <div id="innerCon">
    <div class="template-group__content">
      <div class="template-button-container">
        <button mat-stroked-button
                (click)="createTemplateGroup()"
                color="primary"
                class="template-group__create-template-button"
                [disabled]="createDisabled">
          <mat-icon>edit</mat-icon>
          <span translate="TRANS__TEMPLATE_GROUP__CREATE_GROUP"></span>
        </button>
        <app-template-group-select-button
          [collapseOnSmallScreen]="false"
          (templateSelectorCreated)="onTemplateSelectorCreated($event)"
          (changeTemplateGroup)="onChangeTemplateGroup($event)"
          [noPreselect]="true"
          [disabled]="createDisabled"
          [simple]="true"
        ></app-template-group-select-button>
      </div>
      @if (searching) {
        <div class="template-group__spinner-container">
          <div class="template-group__spinner-content">
            <mat-spinner color="accent" diameter="30"></mat-spinner>
            <p translate="TRANS__SEARCH__LOADING_INFO"></p>
          </div>
        </div>
      }
      @if (templateGroups) {
        <ul class="template-list__content">
          @for (group of templateGroups[AConst.TEMPLATE_GROUPS]; track group; let last = $last) {
            <li class="template-list__item"
                [id]="last ? 'lastGroup' : group.$$id"
                [ngClass]="{'active': group.$$isEditing}">
              <div class="template-list__object-overview">
                <h2><span class="bold">{{group[AConst.GROUP_NAME]}}</span></h2>
                @if (getSectionsContainer(group)) {
                  <app-object-view-and-edit
                    [isEditing]="group.$$isEditing"
                    [sectionsContainer]="getSectionsContainer(group)"
                    (closeEdit)="onCloseEdit(group)"
                  ></app-object-view-and-edit>
                }
              </div>
              <div class="template-list__settings">
                <app-dropdown-menu [icon]="'settings'">
                  @if (group.$$deletable) {
                    <app-delete-item-button
                      [rootObject]="templateGroups"
                      [parentObject]="templateGroups"
                      [fieldName]="'template_groups'"
                      [item]="group"
                      (callback)="deleteCallback()"
                      [deleteFromStore]="true"
                      [checkUsage]="true"
                    ></app-delete-item-button>
                  }
                  <button mat-menu-item (click)="showUsage(group)">
                    <span translate="TRANS__TEMPLATE_GROUP__SHOW_USAGE"></span>
                  </button>
                </app-dropdown-menu>
              </div>
            </li>
          }
        </ul>
      }
    </div>

    @if (templateSelectorContainer && templateSelectorContainer.selectorEnabled) {
      <app-search-view-selector
        [selectorContainer]="templateSelectorContainer"
      ></app-search-view-selector>
    }

  </div>
  </app-header-and-description>
</div>
