<app-panel-layout closePanelText="TRANS__ADVANCED_SEARCH__CLOSE_PANEL_TOOLTIP"
                  headlineText="TRANS__ADVANCED_SEARCH__ADVANCED_SEARCH_HEADING"
                  description="TRANS__ADVANCED_SEARCH__DESCRIPTION"
                  [loading]="loading"
                  (onClose)="cancelSearch()"
                  cdkDropListGroup>

  <ng-container filter>
    <mat-divider></mat-divider>
    <div class="filter-container">
      <app-field-filter [fields]="allFields"
                        [selectedObjectTypes]="selectedArtifactTypeIds"
                        placeholder="TRANS__ADVANCED_SEARCH__FILTER_FIELDS_PLACEHOLDER"
                        (fieldsFiltered)="handleFieldsFiltered($event)">
      </app-field-filter>
    </div>
  </ng-container>

  <div class="advanced-search-content-container">

    <div class="content-wrapper">
      <app-content-section class="field-list"
                           [ngClass]="{active: fieldListPageActive}"
                           headerPrimaryText="TRANS__ADVANCED_SEARCH__FIELD_LIST_HEADING"
                           [headerSecondaryText]="' (' + (filteredFields?.length || 0) + ')'"
                           headerHintText="TRANS__ADVANCED_SEARCH__FIELD_LIST_TOOLTIP">
        <app-field-list [fields]="filteredFields"
                        [deviceType]="deviceType"
                        (selectedFieldChanged)="setSelectedField($event)">
        </app-field-list>

        <ng-container footer>
          <span class="mobile-only"></span>
          <span class="mobile-only">
            <button mat-flat-button
                    type="button"
                    color="accent"
                    (click)="selectField()"
                    [disabled]="!selectedField">
              {{ 'TRANS__ADVANCED_SEARCH__SELECT_FIELD_BUTTON_LABEL' | translate}}
            </button>

            <button mat-flat-button type="button" (click)="abortSelectField()">
              {{ 'TRANS__ADVANCED_SEARCH__CANCEL_BUTTON_LABEL' | translate }}
            </button>
          </span>
        </ng-container>
      </app-content-section>

      <app-content-section class="query-builder"
                           [ngClass]="{active: !fieldListPageActive}"
                           headerPrimaryText="TRANS__ADVANCED_SEARCH__QUERY_BUILDER_HEADER">
        <ng-container action>
          <button mat-flat-button type="button" (click)="queryService.addQueryGroup()">
            <i class="icon-plus-circle"></i>
            {{ 'TRANS__ADVANCED_SEARCH__ADD_GROUP_BUTTON_LABEL' | translate }}
          </button>
        </ng-container>

        <app-query-builder [deviceType]="deviceType"
                           (selectedArtifactIdsChanged)="selectedArtifactTypeIds = $event"
                           (groupSelected)="handleGroupSelected()">
        </app-query-builder>

        <ng-container footer>
          <span></span>
          <span>
          <button mat-flat-button
                  type="button"
                  color="accent"
                  [disabled]="!selectedArtifactTypeIds || selectedArtifactTypeIds.length === 0"
                  (click)="handleSearch()">
            {{ 'TRANS__ADVANCED_SEARCH__SEARCH_BUTTON_LABEL' | translate}}
          </button>
          <button mat-flat-button type="button" (click)="cancelSearch()">
            {{ 'TRANS__ADVANCED_SEARCH__CANCEL_BUTTON_LABEL' | translate }}
          </button>
        </span>
        </ng-container>

      </app-content-section>

    </div>

    <div class="footer mobile-only" (click)="togglePage()">
      <span class="page-signifier" [ngClass]="{active: fieldListPageActive}"></span>
      <span class="page-signifier" [ngClass]="{active: !fieldListPageActive}"></span>
    </div>

  </div>

</app-panel-layout>
