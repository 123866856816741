import { Injectable } from '@angular/core';
import {SearchParameters} from './definitions/search-parameters';
import {SearchFilters} from './definitions/search-filters';

@Injectable({
  providedIn: 'root'
})
export class SolrFilterService {
  // Search parameter filters should be generated using addFq in order to ensure correct format
  addFq(searchParameters: SearchParameters, name: string, value: any, arrayAndOrOr = 'OR') {
    if (value === null || value === undefined) {
      return;
    }
    searchParameters.fq = searchParameters.fq || [];
    this.removeExisting(searchParameters, name);
    let fqString: string;
    value = Array.isArray(value) && value.length === 1 ? value[0] : value;
    if (Array.isArray(value)) {
      if (this.isQueryString(value[0])) {
        const joinStr = `" ${arrayAndOrOr} "`;
        fqString = `${name}:("${value.join(joinStr)}")`;
      } else {
        const joinStr = ` ${arrayAndOrOr} `;
        fqString = `${name}:(${value.join(joinStr)})`;
      }
    } else {
      fqString = this.isQueryString(value) ? `${name}:"${value}"` : `${name}:${value}`;
    }
    searchParameters.fq.push(fqString);
  }

  removeExisting(searchParameters: SearchParameters, name: string) {
    if (!searchParameters.fq) {
      return;
    }
    for (let index = 0 ; index < searchParameters.fq.length ; index++) {
      const filter = searchParameters.fq[index];
      if (filter.indexOf(`${name}:`) === 0) {
        searchParameters.fq.splice(index, 1);
        break;
      }
    }
  }

  // TODO: Implement "operator" for more fine tuned queries
  setFqFromObject(searchParameters: SearchParameters, refFilterData: SearchFilters) {
    for (const filterFieldName in refFilterData) {
      if (refFilterData.hasOwnProperty(filterFieldName)) {
        if (filterFieldName === 'fq') {
          searchParameters.fq = searchParameters.fq.concat(refFilterData.fq);
        } else {
          const filterValues = refFilterData[filterFieldName];
          this.addFq(searchParameters, filterFieldName, filterValues);
        }
      }
    }
  }

  private isQueryString(value) {
    return (
      typeof value === 'string' &&
      value.indexOf('*') === -1 &&
      !(value.indexOf('[') ===0 && value.slice(-1) === ']')
      ) || typeof value === 'boolean';
  }

}
