<div class="side-menu-toggle">
  <button class="side-menu-toggle__button"
         (click)="toggleSideFilterMenuSmallScreen()">
    <span class="side-menu-toggle__button-content">
      <span class="side-menu-toggle__button-filter-text">
        <mat-icon>filter_alt</mat-icon>
        <span translate="TRANS__SEARCH__LIMIT_RESULT"></span></span>

      <span class="side-menu-toggle__selected-filter">
        <ng-container *ngIf="searchContainer.path !== 'home'">
          <span *ngIf="searchContainer.totalSelectedFilters === 0">
            (<span class="side-menu-toggle__selected-filter-number">1</span>
            <span translate="TRANS__SEARCH__HEADER__FILTER_SELECTED"></span>)
          </span>
          <span *ngIf="searchContainer.totalSelectedFilters >= 1">
            (<span class="side-menu-toggle__selected-filter-number">{{searchContainer.totalSelectedFilters + 1}}</span>
            <span translate="TRANS__SEARCH__HEADER__FILTER_SELECTED"></span>)
          </span>
        </ng-container>

        <ng-container *ngIf="searchContainer.path === 'home' && searchContainer.filtersFacets.filtersChecked === 'notChecked'">
         (<span translate="TRANS__SEARCH__HEADER__NO_FILTER"></span>)
        </ng-container>
      </span>

    </span>
    <span class="side-menu-toggle__selected-focus-name">
      <span class="side-menu-toggle__vertical-line"></span>
      <mat-icon [class.text-blue]="searchContainer.focus.curFocusId">visibility</mat-icon>
      <span *ngIf="searchContainer.focus.curFocusId"
            class="side-menu-toggle__selected-focus-name-text">{{searchContainer.focus.currentFocusName}}</span>
      <span *ngIf="!searchContainer.focus.curFocusId"
            class="side-menu-toggle__selected-focus-name-text"
            translate="TRANS__FOCUS__INFO_NO_FOCUS_SELECTED"></span>
    </span>
  </button>
</div>
