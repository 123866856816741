import {Component, Input} from '@angular/core';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {MetaTypes} from "../../core/definitions/meta-types";
import {SuperObjectModel} from "../../core/definitions/super-object-model";

@Component({
  selector: 'app-object-new-copy-view',
  templateUrl: './object-new-copy-view.component.html',
  styleUrls: ['./object-new-copy-view.component.scss']
})
export class ObjectNewCopyViewComponent {

  @Input() object: SuperObjectModel;
  @Input() selectorContainer: SelectorContainer;
  @Input() sectionsContainer: SectionsContainer;
  @Input() createMultiple: boolean;
  protected readonly MetaTypes = MetaTypes;
}
