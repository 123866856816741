import {Component, Input, OnChanges} from '@angular/core';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {MetaField} from '../../../core/definitions/meta-field';
import {IndexNotification} from '../../../core/definitions/inline';
import {BaseModel} from "../../../core/definitions/base-model";

@Component({
  selector: 'app-edit-field-inline-array-add-button',
  templateUrl: './edit-field-inline-array-add-button.component.html',
  styleUrls: ['./edit-field-inline-array-add-button.component.scss']
})
export class EditFieldInlineArrayAddButtonComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() field: MetaField;
  @Input() object: BaseModel;
  @Input() parentIndex: number;
  @Input() disabled: boolean;

  addItemNotifications: IndexNotification[] = [];
  private fieldParameters: FieldParameters;

  constructor(private readonly inlineArrayItemSvc: InlineArrayItemService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.field = this.field;
    this.fieldParameters.object = this.object;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  async addItem(event: MouseEvent) {
    event.preventDefault();
    await this.inlineArrayItemSvc.addInlineArrayItemToForm(this.fieldParameters);
  }

  canAddItem() {
    const inlineList = this.field.inline.inline_list;
    let res = true;
    if (!inlineList) {
      return res;
    }
    let overMax = false;
    const arr = this.object[this.field.name];
    if (arr === undefined) {
      throw new Error('Could not find array named ' + this.field.name);
    }
    const listLength = arr.filter((item: any) => !item.destroy).length;
    const fixed = inlineList.fixed;
    const maxLength = inlineList.max_length;
    if (maxLength) {
      overMax = listLength >= maxLength;
    }
    res = !fixed && !overMax;
    const notifications = inlineList.index_notifications;
    for (const indexNot of notifications || []) {
      if (listLength >= indexNot.index) {
        if (!this.addItemNotifications.includes(indexNot)) {
          indexNot.hide = false;
          this.addItemNotifications.push(indexNot);
        }
      } else if (this.addItemNotifications.includes(indexNot)) {
        this.addItemNotifications.splice(this.addItemNotifications.indexOf(indexNot), 1)
      }
    }
    return res;
  }


}
