<span class="inline-header-value">
  @if (isString()) {
    <span class="inline-header-value__text"
          [ngClass]="{'hasIcon': label.icon === '{conceptIcon}',
        'ellipseTextLabel': label.type === 'text' || label.name === 'url'}">
      @if (container.values[label.key].indexOf('TRANS__') === -1) {
        <span [innerHtml]="container.values[label.key]"></span>
      } @else {
        <span [translate]="container.values[label.key]"></span>
      }
  </span>

  }
  @if (label.sRef) {
    <span class="object-url__object-link">
    <span [innerHtml]="container.values[label.key]"></span>
  </span>
  }

</span>
