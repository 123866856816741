import {Component, EventEmitter, inject, Input, OnInit, Output, signal} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {
  SelectObjectTemplateModalComponent
} from './select-object-template-modal/select-object-template-modal.component';
import {TemplateGroup} from '../core/definitions/template-models';
import {UserCacheService} from '../core/user-cache.service';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-select-object-template-button',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatProgressSpinner,
    TranslateModule,
  ],
  templateUrl: './select-object-template-button.component.html',
  styleUrl: './select-object-template-button.component.scss'
})
export class SelectObjectTemplateButtonComponent implements OnInit {
  @Input() superobject_type_id: string;
  @Input() templateGroupId: string;

  @Output() changeTemplateGroupId = new EventEmitter<string>();

  readonly dialog = inject(MatDialog);

  loading = signal(false);
  templateGroups = signal([] as TemplateGroup[]);

  get selectedTemplateGroup() {
    return this.templateGroups().find((templateGroup) => templateGroup.template_group_id === this.templateGroupId);
  }

  constructor(private userCacheService: UserCacheService) {
  }

  ngOnInit() {
    this.userCacheService.getTemplateGroups().then((templateGroups) => {
      this.templateGroups.set(templateGroups);
    }).finally(() => {
      this.loading.set(false);
    });
  }


  openTemplateSelectModal() {
    this.dialog.open(SelectObjectTemplateModalComponent, {
      data: {
        selectedTemplateGroup: this.selectedTemplateGroup,
        templateGroups: this.templateGroups(),
      }
    }).afterClosed().subscribe((templateGroup: TemplateGroup) => {
      if (templateGroup) {
        this.changeTemplateGroupId.emit(templateGroup.template_group_id);
      } else if (templateGroup === null) {
        this.changeTemplateGroupId.emit(null);
      }
    });
  }
}
