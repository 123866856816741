import {Component, Input, OnChanges} from '@angular/core';
import {MetaField} from '../../core/definitions/meta-field';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {LoggerService} from "../../core/logger.service";
import {SearchService} from "../../core/search.service";
import {SearchParameters} from "../../core/definitions/search-parameters";

@Component({
  selector: 'app-edit-field-context',
  templateUrl: './edit-field-context.component.html',
  styleUrls: ['./edit-field-context.component.scss']
})
export class EditFieldContextComponent implements OnChanges {
  @Input() contexts: SuperObjectModel[];
  @Input() field: MetaField;

  currentConcept: { superobjectType: string, showItems: boolean, count: number, items: Array<SuperObjectModel> };
  contextTypes: { superobjectType: string, showItems: boolean, count: number, items: Array<any> }[] = [];

  constructor(private logger: LoggerService,
              private searchService: SearchService) {
  }

  ngOnChanges(): void {
    this.setContexts().then();
  }

  toggleContext(contextType: any) {
    this.contextTypes.forEach(con => {
      if (con.superobjectType !== contextType.superobjectType) {
        con.showItems = false;
      }
    });
    this.currentConcept = contextType;
    contextType.showItems = !contextType.showItems;
  }

  private async setContexts() {
    this.contextTypes = [];
    await this.setIdentifiers();
    for (let context of this.contexts) {
      let contextExisted = false;
      for (const contextType of this.contextTypes) {
        if (context.superobject_type_id_value === contextType.superobjectType) {
          contextType.showItems = false;
          if (context.context_id !== null) {
            contextType.items.push({uuid: context.context_id, identifier: context.$$id});
          }
          contextType.count++;
          contextExisted = true;
        }
      }
      if (!contextExisted) {
        this.contextTypes.push({
          count: 1,
          showItems: false,
          superobjectType: context.superobject_type_id_value,
          items: [{uuid: context.context_id, identifier: context.$$id}]
        });
      }
    }
  }

  private async setIdentifiers() {
    let contextIds = [];
    for (let context of this.contexts) {
      context.$$id = context.context_id_value || context.context_id;
      if (context.identification_number && context.identification_number['identification_number']) {
        context.$$id = context.identification_number['identification_number'];
      } else if (context.context_id_value) {
        const contextIdSplit = context.context_id_value.split(':');
        context.$$id = contextIdSplit[contextIdSplit.length - 1];
      } else if (context.context_id) {
        contextIds.push(context.context_id);
      } else {
        this.logger.warn(`Unable to obtain identification number due to missing value in context_id_value for
        object type ${context.object_type}`)
      }
    }
    // When id number is missing, need to make a search for artifact ids and obtain id numbers from search result.
    // This may happen when creating admin events for selected objects in a procedure
    if (contextIds.length) {
      await this.searchForContextIdentifiers(contextIds);
    }
  }

  private async searchForContextIdentifiers(contextIds: string[]) {
    const solrIdField = 'identification_number.identification_number';
    const searchParams = {
      fl: ['artifact_id', solrIdField],
      fq: [`artifact_id:(${contextIds.join(' ')})`],
      query: '*:*',
      rows: contextIds.length,
      sort: 'artifact_id',
      start: 0
    } as SearchParameters;
    const searchRes = await this.searchService.search(searchParams);
    for (let context of this.contexts) {
      const matchingArtifact = searchRes.artifacts.find(
        (searchObj) => searchObj.artifact_id === context.context_id
      );
      if (matchingArtifact) {
        context.$$id = matchingArtifact[solrIdField];
      }
    }
  }
}
