<div class="edit-field-inline-array-add-button">
  @if (canAddItem()) {
    <button mat-stroked-button
            color="primary"
            type="button"
            (click)="addItem($event)"
            [disabled]="disabled"
            (keydown.enter)="addItem($event)">
      <i class="icon-plus-circle"></i>
      <span class="bold uppercase" translate="TRANS__FIELD__ARRAY_ADD"></span>
      <span>&nbsp;</span>
      <span class="bold uppercase" [translate]="field.title"></span>
    </button>
  }

  @for (notification of addItemNotifications; track notification) {
    <div class="add-button-notification">
      @if (notification && !notification['hide']) {
        <div class="add-button-notification__content">
      <span class="{{notification.message_class}}">
        <span class="icon-information"></span>
        <span class="icon-warning"></span>
        <span [translate]="notification.message"></span>
        <button (click)="notification.hide = true"><mat-icon>close</mat-icon></button>
      </span>
        </div>
      }
    </div>
  }
</div>
