import { Pipe, PipeTransform } from '@angular/core';
import {CultureHubFolder} from '../../core/definitions/culture-hub-folder';

@Pipe({
  name: 'cultureHubFolderFilter'
})
export class CultureHubFolderFilterPipe implements PipeTransform {

  /**
   * Filter CultureHubFolder by entity_name
   * @param dataSource list of CultureHubFolder
   * @param search query string
   */
  transform(dataSource: CultureHubFolder[], search: string): unknown {
    if (!search) {
      return dataSource;
    } else {
      return dataSource.filter(data => data.name.name.trim().toLowerCase().includes(search.trim().toLowerCase()));
    }
  }

}
