import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChildren} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SearchResultSelectionsService} from '../../object-search/search-result-selections.service';
import {
  ContentListPageItem,
  ContentListSourceContainer
} from '../../core/definitions/object-content-tab/content-list-source-container';

@Component({
  selector: 'app-object-content-list-source-array-items',
  templateUrl: './object-content-list-source-array-items.component.html',
  styleUrls: ['./object-content-list-source-array-items.component.scss'],
  animations: [
    trigger('animateHeight', [
      state('collapse', style({height: '122px'})),
      state('expand', style({height: '*'})),
      transition('collapse => expand', [
        style({height: '122px'}),
        animate('150ms linear', style({height: '*'}))
      ]),

      transition('expand => collapse', [
        style({height: '*'}),
        animate('150ms linear', style({height: '122px'}))
      ])
    ])
  ]
})
export class ObjectContentListSourceArrayItemsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() contentListSource: ContentListSourceContainer;
  @Input() triggerAnimation: boolean;
  @Input() hasOperationMenu: boolean;
  @Output() afterDeleted = new EventEmitter<boolean>();
  @ViewChildren('thirdContent') thirdContent;
  @ViewChildren('secondContent') secondContent;

  private resizeListener;

  constructor(private renderer: Renderer2,
              private searchResultSelectionsService: SearchResultSelectionsService) { }

  ngOnInit() {
    this.resizeListener = this.renderer.listen('window', 'resize', () => {
      this.loopAndCheckOverflow();
    });
  }

  ngOnChanges() {
    setTimeout(() => {
      this.loopAndCheckOverflow();
    }, 500);
  }

  loopAndCheckOverflow() {
    for (let t = 0; t < this.secondContent.toArray().length; t++) {
      this.contentIsOverflown(t);
    }
  }

  contentIsOverflown(index) {
    if (this.secondContent.toArray() && this.secondContent.toArray()[index]) {
      const secondElement = this.secondContent.toArray()[index].nativeElement;
      const thirdElement = this.thirdContent.toArray()[index].nativeElement;
      const item = this.contentListSource.items[index];
      if (item) {
        item.expanded = false;
        if (window.innerWidth > 1200 ) {
          this.contentListSource.items[index].showExpandButton =
            secondElement.scrollHeight >
            secondElement.clientHeight + 2 ||
            thirdElement.scrollHeight >
            thirdElement.clientHeight;
        } else {
          this.contentListSource.items[index].showExpandButton = true;
        }
      } else {
        console.warn('Trying to get content list source item ' + index + ' of ' + this.contentListSource.items.length);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.resizeListener) {
      this.resizeListener();
    }
  }

  afterDelete() {
    this.afterDeleted.emit(true);
  }

  toggleExpand(pageItem: ContentListPageItem) {
    pageItem.listItem.expanded = !pageItem.listItem.expanded;
  }

  setSelected(event, pageItem: ContentListPageItem) {
    this.searchResultSelectionsService.selectSearchResultItem(
      pageItem.listItem.object, this.contentListSource.searchContainer, event.shiftKey);
  }
}
