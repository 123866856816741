// An operation context object contains a subset of properties from an object
// that will be used for retrieving context information that in turn will be
// used when creating new or updating existing operation objects.
export interface OperationContextObject {
  artifact_id: string;
  object_type: string;
  meta_type: string;
  superobject_type_id: string;
  context_id: string;
  // parent_id: string;
}

export class OperationStepExecutionParams {
  // UUID of the operation, necessary for the server to identify the operation.
  operation_type_id: string;

  // The operation step number that will be executed on the server.
  operation_step_index: number;

  // The operation object contains the operation data to be used in the operation.
  // The operation object will always be based on a model. The model may be based
  // on a database object, like a "thing", but may also be based on metamodels,
  // which are used to perform operations that may not necessarily be based on database
  // information.
  operation_objects: any;

  // If set, the operation context id list will contain one or more object ids that
  // are used in connection with the operation. E.g. when creating an admin event
  // to be added to multiple things, the operationObject will consist of an admin
  // event object. Multiple admin events will then be created, each one with a context
  // id from the operation context id list.
  operation_contexts: OperationContextObject[];

  // Extra operation parameters that are either common for the operation objects, like template group id
  // (template_group_id), or that will be used as constant values in object actions, as seen in the
  // spectrum operations. E.g.:
  // target_model='{operation_model}SuperobjectItem'
  // constant_value='{status_id}'
  operation_params: any;
  timezone_offset = new Date().getTimezoneOffset();
}
