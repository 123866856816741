import {Injectable} from '@angular/core';
import {SuperObjectModel} from './definitions/super-object-model';
import {Section, SectionsContainer} from './definitions/sections-container';
import {UserCacheService} from './user-cache.service';

@Injectable({
  providedIn: 'root'
})
export class ModelSectionsService {

  constructor(private userCacheService: UserCacheService) {
  }

  async getCreateSectionsContainer(
    rootObject: SuperObjectModel, templateGroupId?: string): Promise<SectionsContainer> {
    const sectionsContainer = new SectionsContainer();
    sectionsContainer.sections = await this.getObjectSections(rootObject, templateGroupId);
    sectionsContainer.rootObject = rootObject;
    sectionsContainer.templateGroupId = templateGroupId;
    return sectionsContainer;
  }

  nextError(sectionsContainer: SectionsContainer) {
    const errors = this.getSectionErrors(sectionsContainer);
    const errorId = errors[sectionsContainer.errorPointer];
    sectionsContainer.errorPointer++;
    if (sectionsContainer.errorPointer >= errors.length) {
      sectionsContainer.errorPointer = 0;
    }
    return errorId;
  }


  getSectionErrors(sectionsContainer: SectionsContainer): string[] {
    const errors = [];
    for (const fieldName in sectionsContainer.formGroup.controls) {
      if (sectionsContainer.formGroup.controls.hasOwnProperty(fieldName)) {
        const fieldCtrl = sectionsContainer.formGroup.controls[fieldName];
        if (fieldCtrl.invalid) {
          errors.push(fieldName);
        }
      }
    }
    return errors;
  }

  // Getting section information for the object.
  private async getObjectSections(object: SuperObjectModel, templateGroupId?): Promise<Section[]> {
    let res: Section[];
    const objectType = object.object_type;
    if (objectType) {
      res = object.template_sections;
      if (!res) {
        res = await this.userCacheService.getModelSections(objectType, templateGroupId);
      }
    }
    return res;
  }

}
