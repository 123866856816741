<mat-card appearance="outlined" class="admin-settings-faq">
  <mat-card-header>
    <mat-card-title><span translate="TRANS__ADMIN_SETTINGS__TITLE_FAQ"></span></mat-card-title>
  </mat-card-header>
  <mat-accordion [multi]="false">
    <mat-expansion-panel *ngFor="let fq of adminSettingsFq">
      <mat-expansion-panel-header>
        {{fq.question}}
      </mat-expansion-panel-header>
      <span>{{fq[AConst.ANSWER]}}</span>
      <a *ngIf="fq.url" target="_blank" [href]="fq.url">{{fq.url}}</a>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>
