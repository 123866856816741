import {Component, Input, OnInit} from '@angular/core';
import User from '../../User';
import {SearchParameters} from '../../../../core/definitions/search-parameters';
import {CommonsService} from '../../../../core/commons.service';
import {MatSelectionListChange} from '@angular/material/list';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SearchService} from "../../../../core/search.service";
import {CrudService} from "../../../../core/crud.service";

interface RightsItemNode {
  children: RightsItemNode[];
  id: string;
  name: string;
  parent_id: string;
  selected: boolean;
  is_leaf: boolean;
}

interface RightsFlatNode {
  id: string;
  name: string;
  level: number;
  expandable: boolean;
  parent_id: string;
  selected: boolean;
}

@Component({
  selector: 'app-access-rights-section',
  templateUrl: './access-rights-section.component.html',
  styleUrls: ['./access-rights-section.component.scss']
})
export class AccessRightsSectionComponent implements OnInit {

  @Input() user: User;
  @Input() enableEditMode: boolean;

  dataIsLoaded = false;

  rightsTreeControl: FlatTreeControl<RightsFlatNode>;
  treeFlattener: MatTreeFlattener<RightsItemNode, RightsFlatNode>;
  rightsDataSource: MatTreeFlatDataSource<RightsItemNode, RightsFlatNode>;
  transformer: (node: RightsItemNode, level: number)  => RightsFlatNode;


  constructor(private readonly searchService: SearchService,
              readonly commons: CommonsService,
              private readonly crud: CrudService) {
    this.transformer = (node: RightsItemNode, level: number) => {
      const selected = (!!node.children && node.children.length > 0) ?
        this.setCheckboxOnNodesOnLoad(node) : this.userHasRight(node.id);
      return {
        expandable: !!node.children && node.children.length > 0,
        id: node.id,
        name: node.name,
        level: level,
        parent_id: node.parent_id,
        selected: selected
      };
    };
    this.rightsTreeControl = new FlatTreeControl<RightsFlatNode>(
      node => node.level, node => node.expandable);

    this.treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, node => node.expandable, node => node.children);
    this.rightsDataSource = new MatTreeFlatDataSource(this.rightsTreeControl, this.treeFlattener);
  }

  hasChild = (_: number, node: RightsFlatNode) => node.expandable;

  public async ngOnInit(): Promise<void> {
    await this.getData().then(rightsData => {
      this.rightsDataSource.data = rightsData;
      this.dataIsLoaded = true;
    });
  }

  private async getData(): Promise<RightsItemNode[]> {
    const rightsTreeData: RightsItemNode[] = [];
    const rightsData: RightsItemNode[] = [];
    const rightsDataList = {};
    const res = await this.searchService.search({
      query: 'object_type:"ct_139"'
    } as SearchParameters);

    if (!res || !res.artifacts) {
      return [];
    }

    res.artifacts.forEach(a => {
      const select = this.userHasRight(a.id);
      rightsData.push({
        id: a.id,
        name: a.artifact_name,
        children: [],
        parent_id: a.parent_id,
        selected: select,
        is_leaf: a.is_leaf
      });
    });

    rightsData.forEach(t => {
      rightsDataList[t.id] = t;
      rightsDataList[t.id].children = [];
    });

    rightsData.forEach(t => {
      return  t.parent_id ? rightsDataList[t.parent_id].children.push(t) : rightsTreeData.push(t);
    });

    return rightsTreeData;
  }

  setCheckboxOnNodesOnLoad(node: any) {
    if (node.children && node.children.length > 0) {
      const childArray = node.children.filter((child: any) => child.selected === true);
      return node.children.length === childArray.length;
    }
  }

  userHasRight(rightId: string): boolean {
    return this.user && this.user.rights &&
      this.user.rights.map(u => u.user_rights_id).includes(rightId);
  }

  toggleAllLeafNodes(node: RightsFlatNode) {
    const children = this.rightsTreeControl.getDescendants(node);
    children.forEach(child => child.selected = node.selected);
    children.forEach(child => {
      const grandChildren = this.rightsTreeControl.getDescendants(child);
      // Skip adding children that have grandChildren, they don't have any function other than being a holder
      if (!grandChildren || !grandChildren.length) {
        if (child.selected) {
          this.createRightItems(child);
        } else {
          this.deleteRightsItem(child);
        }
      }
    });
  }

  checkIfChildNodeIsSelected(node: RightsFlatNode) {
    if (this.dataIsLoaded) {
      const children = this.rightsTreeControl.getDescendants(node);
      return children.some(child => child.selected === false) &&
        !children.every(child => child.selected === false);
    }
  }

  checkIfAllChildrenIsSelected(node: RightsFlatNode) {
    if (this.dataIsLoaded) {
      const children = this.rightsTreeControl.getDescendants(node);
      node.selected = children.every(child => child.selected === true);
      return node.selected;
    }
  }

  handleSelectionChange(selectionChangeEvent: MatSelectionListChange, child: any): void {
    if (!selectionChangeEvent || !selectionChangeEvent.options) {
      return;
    }
    const opt = selectionChangeEvent.options[0];
    child.selected = opt.selected;

     if (child.parent_id) { // update parent node if all children are checked
       const parent = this.rightsTreeControl.dataNodes.find(parentNode => parentNode.id === child.parent_id);
       const children = this.rightsTreeControl.getDescendants(parent);
       parent.selected = children.every(c => c.selected === true);
     }
    if (opt.selected) {
      this.createRightItems(child);
    } else {
      this.deleteRightsItem(child);
    }
  }

  private createRightItems(item: any) {
    const right = this.user.rights.find(r => r.user_rights_id === item.id);

    // If right exists, set destroy to null, if not add a new right to list
    if (!!right) {
      this.crud.setDestroy(right,null);
    } else {
      const userRight = {
        user_rights_id: item.id,
        user_rights_id_value: item.name,
        object_type: 'UserRightsItem',
      }
      this.crud.setCreate(userRight);
      this.user.rights.push(userRight);
    }
  }

  private deleteRightsItem(item: any) {
    const accessRightToDelete = this.user.rights.find(r => r.user_rights_id === item.id);

    // If right exists, check if its newly added or not
    // If newly added, remove it from list, if not set destroy to true.
    if (!!accessRightToDelete) {
      if (this.crud.getCreate(accessRightToDelete) === true) {
          const index = this.user.rights.indexOf(accessRightToDelete);
          // Safety check, indexOf return -1 if not found
          if (index >= 0) {
            this.user.rights.splice(index, 1);
          }
      } else {
        this.crud.setDestroy(accessRightToDelete, true);
      }
    }
  }
}
