import {Injectable} from '@angular/core';
import {SearchResult} from '../../../core/definitions/search-result';
import {SearchParameters} from '../../../core/definitions/search-parameters';
import {SearchObject} from '../../../core/definitions/search-object';
import {CommonsService} from '../../../core/commons.service';
import {PerformanceTimer} from '../../../core/performance-timer';
import {Reference, ReferenceFilter} from '../../../core/definitions/reference';
import {FieldInputType} from '../../../core/definitions/field-input-type.enum';
import {Dictionary, InputFieldType, InputOptions} from '../../../core/definitions/advanced-search/input-options';
import {SearchableField} from '../../../core/definitions/advanced-search/searchable-field';
import {FieldGroup} from '../../../core/definitions/advanced-search/field-group';
import {SettingsService} from '../../../core/settings.service';
import {SearchService} from "../../../core/search.service";
import {CmsApiService} from "../../../core/cms-api.service";
import {GetSolrFieldsParams} from "../../../core/definitions/get-solr-fields-params";
import {SearchReferenceService} from "../../../core/search-reference.service";

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  /**
   *
   * @param {SearchService} searchService
   * @param {CmsApiService} cms
   * @param {SearchReferenceService} searchReferenceService
   * @param {CommonsService} commons
   * @param settings
   */
  constructor(private readonly searchService: SearchService,
              private readonly cms: CmsApiService,
              private readonly searchReferenceService: SearchReferenceService,
              private readonly commons: CommonsService,
              private readonly settings: SettingsService) {
    this.inputOptionsCache = {};
  }

  /**
   * Fields with these input-types will not appear in the field-list
   * @type {Array<FieldInputType>}
   * @private
   */
  private static excludedInputTypes: Array<FieldInputType> = [
    FieldInputType.INLINE,
    FieldInputType.INLINE_ARRAY,
    FieldInputType.REF_ARRAY,
    FieldInputType.ACTION_BUTTON,
    FieldInputType.CHECK_ARRAY,
    FieldInputType.CONTEXT,
    FieldInputType.RADIO_INLINE_ARRAY,
  ];

  /**
   * All modelNames (ArtifactTypes) that has been loaded
   * @type {Dictionary}
   * @private
   */
  private modelNamesCache: Dictionary;
  /**
   * Cache for all fields, to avoid doing heavy computing all the time
   * @type {Array<SearchableField>}
   * @private
   */
  private cachedFields: Array<SearchableField>;
  /**
   * Caches the InputOptions to avoid regenerating the same options more that once.
   * @type {Dictionary<InputOptions>}
   * @private
   */
  private inputOptionsCache: Dictionary<InputOptions>;

  /**
   * Sorts a list of fields by their respective names,
   * and groups them by the first letter in their names (case insensitive).
   * @param {Array<SearchableField>} fields
   * @return {Array<FieldGroup>}
   */
  public static groupFields(fields: Array<SearchableField>): Array<FieldGroup> {
    const grp = [...fields]
      .sort(SearchableField.sortFn)
      .reduce((groups, field: SearchableField) => {
        const firstLetter: string = String(field.title.name || '').substring(0, 1).toUpperCase();
        if (!groups[firstLetter]) {
          groups[firstLetter] = [];
        }
        groups[firstLetter].push(field);
        return groups;
      }, {} as Dictionary<Array<SearchableField>>);

    return Object.keys(grp).map(letter => ({
      groupLetter: letter,
      items: grp[letter]
    } as FieldGroup));
  }

  /**
   * Generates the SearchParams to use to fetch the options available on a field
   * @param {Reference} reference the field to fetch the params for
   * @param rows Numbers of rows to fetch at a time
   * @return {SearchParameters | null}
   */
  private static getOptionsSearchParamsField(reference: Reference, rows: number = 10): SearchParameters | null {
    const {optionsIdProperty, optionsLabelProperty} = this.getOptionsIdLabelPropForField(reference);
    const params = {
      query: `${optionsLabelProperty}:*`,
      fl: [optionsIdProperty, optionsLabelProperty],
      sort: `${optionsLabelProperty} asc`,
      rows
    } as SearchParameters;

    let fq = '-(valid:false)';

    if (reference.object_type) {
      fq = `${fq} AND (object_type:"${reference.object_type}")`;
    }

    if (reference.meta_type) {
      fq = `${fq} AND (meta_type:"${reference.meta_type}")`;
    }


    if (reference.ref_filter) {
      const filters: Array<ReferenceFilter> = Array.isArray(reference.ref_filter) ? reference.ref_filter : [reference.ref_filter];
      const values = filters.map(filter => `${filter.filter_field}:(${filter.values?.filter(val => typeof val === 'string').join(',')})`);
      fq += ` AND (${values.join(' AND ')})`;
    }
    params.fq = [fq];

    if (reference.sort) {
      params.sort = reference.sort;
    }

    return params;
  }

  /**
   * Returns the propertied to use for id and label for the available options
   * @param {Reference} reference
   * @return {Pick<InputOptions, "optionsIdProperty" | "optionsLabelProperty">}
   * @private
   */
  private static getOptionsIdLabelPropForField(reference: Reference): Pick<InputOptions, 'optionsIdProperty' | 'optionsLabelProperty'> {
    return {
      optionsIdProperty: reference?.ref_prop || 'artifact_id',
      optionsLabelProperty: reference?.label_prop || 'artifact_name',
    };
  }

  /**
   * Maps the input-type of the field to the relevant Input-field
   * @param {SearchableField} field
   * @return {InputFieldType}
   * @private
   */
  private static getInputFieldTypeForField(field: SearchableField): InputFieldType {
    const inputType = (field?.meta?.input_type as FieldInputType) || FieldInputType.INPUT;
    switch (inputType) {
      case FieldInputType.INPUT:
      case FieldInputType.TEXT_AREA:
      case FieldInputType.IDENTIFIER:
      case FieldInputType.COMPARE_VALUE:  // TODO: Should this be here?
        return 'TEXT';

      case FieldInputType.NUMBER:
        return 'NUMBER';

      case FieldInputType.DATE_TIME_ISO:
      case FieldInputType.DATE_ISO:
        return 'DATE';

      case FieldInputType.MAP_ID:
      case FieldInputType.SEARCH_SELECTOR:
        return 'SELECT';

      case FieldInputType.SEARCH_SELECTOR_MULTIPLE:
        return 'SELECT_MULTIPLE';

      case FieldInputType.RADIO_OPTION: // TODO: Need more implementation?
      case FieldInputType.CHECKBOX:
        return 'BOOLEAN';

      case FieldInputType.DATE_TIME_ISO_RANGE: // TODO: Support this?
      case FieldInputType.IMAGE:
      case FieldInputType.META_OPERATION_FIELD:
      case FieldInputType.PASSWORD:
      case FieldInputType.INLINE:
      case FieldInputType.INLINE_ARRAY:
      case FieldInputType.REF_ARRAY:
      case FieldInputType.ACTION_BUTTON:
      case FieldInputType.CHECK_ARRAY:
      case FieldInputType.CONTEXT:
      default:
        return 'UNSUPPORTED';
    }
  }

  /**
   * Fetches all fields available to Advanced search and Search Category,
   * and performs mapping of related/needed data.
   * @return {Promise<Array<SearchableField>>}
   */
  public async getFields(): Promise<Array<SearchableField>> {
    if (!this.cachedFields) {
      const timer = new PerformanceTimer('Load searchable fields');
      timer.start();
      const modelNames = await this.loadModelNames();
      timer.measure('Load model names');
      const fields: any[] = await this.cms.getSolrFields({} as GetSolrFieldsParams);
      // Reset input-options-cache since fields has changed
      this.inputOptionsCache = {};

      timer.measure('Load fields', 2000);

      this.cachedFields = fields.filter(field => {
        // Filter input-types here to avoid letting them appear in the field-list,
        // but still be available to map parents.
        return !FieldService.excludedInputTypes.includes(field.input_type);
      }).map(field => {

        // Map to searchableFiled.
        // All mapping that require computing should be done in the next mapper
        // to optimize performance
        return {
          fieldId: field.field_uuid,
          title: {
            name: field.name,
            alternateName: '',
          },
          indexName: field.index_query_field,
          meta: field,
        } as SearchableField;

      }).map(field => {
        const meta = field.meta;

        field.appearsOn = this.commons.sortByProperty(
          (meta.superobject_type_ids || []).map((id: string) => ({
            artifactId: id,
            name: modelNames[id]
          })),
          'name'
        );

        field.title.alternateName = (meta.parent_field_ids || [])
          .filter((id: string) => !id.includes(field.fieldId))
          .map((pid: string) => fields.find(f => f.field_uuid === pid)?.title)
          .filter((a: any) => !!a)
          .sort()
          .join(', ');

        field.inputOptions = this.getInputOptionsForField(meta);

        return field;
      });

      timer.stop('Done', 2000, 3000);
    }
    return this.cachedFields;
  }

  /**
   * Creates an object wil all properties that are needed by the advanced-search input-component
   * @return {InputOptions | null}
   * @param field
   */
  public getInputOptionsForField(field: any): InputOptions | null {
    const fieldId = field?.fieldId;
    if (this.inputOptionsCache[fieldId]) {
      return this.inputOptionsCache[fieldId];
    }
    const reference = this.searchReferenceService.getSearchReferenceFromField(field);
    const inputFieldType = FieldService.getInputFieldTypeForField(field);
    const {optionsIdProperty, optionsLabelProperty} = FieldService.getOptionsIdLabelPropForField(reference);
    const optionsSearchParams = FieldService.getOptionsSearchParamsField(reference);
    this.inputOptionsCache[fieldId] = {
      inputFieldType,
      optionsIdProperty,
      optionsLabelProperty,
      optionsSearchParams,
    };
    return this.inputOptionsCache[fieldId];
  }

  /**
   * Loads all superobject type ids and caches the result
   * @return {Promise<Dictionary>}
   * @private
   */
  private async loadModelNames(): Promise<Dictionary> {
    if (!this.modelNamesCache) {
      const models = await this.fetchAllSearch({
        query: `object_type:"${this.settings.getClientConfig().CONCEPT_TYPE_SUPEROBJECT_TYPE}"`,
        fl: ['artifact_id', 'artifact_name'],
      } as SearchParameters);
      this.modelNamesCache = models.reduce((acc, m) => ({
        ...acc,
        [m.artifact_id]: m.artifact_name
      }), {});
    }
    return this.modelNamesCache;
  }

  /**
   * Utility-method to get all rows in a solr-search.
   * WARNING! THIS METHOD SHOULD <b>NOT</b> BE USED ON LARGE LISTS!
   *
   * @param {Partial<SearchParameters>} searchParams
   * @param {number} initialFetchAmount
   * @return {Promise<Array<SearchObject>>}
   * @private
   */
  private async fetchAllSearch(searchParams: Partial<SearchParameters>, initialFetchAmount: number = 100): Promise<Array<SearchObject>> {
    if (!searchParams) {
      throw Error('Missing searchParams');
    }
    const initialResult: SearchResult = await this.searchService.search({
      ...searchParams,
      start: 0,
      rows: initialFetchAmount
    } as SearchParameters);
    const searchObjects = initialResult?.artifacts || [];

    if (initialResult?.search_count > initialFetchAmount) {
      const rest: SearchResult = await this.searchService.search({
        ...searchParams,
        start: initialFetchAmount,
        rows: initialResult.search_count - initialFetchAmount
      } as SearchParameters);
      searchObjects.push(...(rest?.artifacts || []));
    }

    return searchObjects;
  }

}
