import {Component, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {InputComponent} from '../input.component';
import {QueryField} from '../../../../../core/definitions/advanced-search/query-field';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnChanges {

  @Input() field: QueryField;
  @Input() label: string;
  @Input() placeholder: string;

  @Output() public readonly searchValueChanged: Observable<string>;

  readonly control: UntypedFormControl;

  constructor() {
    this.control = new UntypedFormControl(null);
    this.searchValueChanged = this.control.valueChanges.pipe(
      filter(() => !!this.field),
      debounceTime(InputComponent.COMMON_DEBOUNCE_TIME),
      map(val => !Number.isNaN(Number(val)) ? Number(val) : ''),
      tap(val => this.field.inputOptions.additionalData = {value: val}),
      map(val => `"${val}"`)
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('field')) {
      this.field = changes.field.currentValue;
      const value = this.field?.inputOptions?.additionalData?.value || '';
      this.control.reset(value, {emitEvent: false});
    }
  }


}
