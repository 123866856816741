import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CmsApiService} from "../../../core/cms-api.service";

export interface TargetImportField {
  field_title: string;
  field_name: string;
  required: boolean;
}
export interface ImportFieldMapItem {
  import_file_field_name?: string;
  target_field: TargetImportField;
  columns: string[];
  filtered_columns: string[];
}
export interface ImportFieldAlternative {
  title: string;
  value: string;
}

export interface ImportFieldOption {
  name: string;
  concept_type: string;
  concept_filters?: string[],
  alternatives: ImportFieldAlternative[];
  filtered_alternatives?: ImportFieldAlternative[];
  selected_alternative: ImportFieldAlternative;
  required: boolean;
}

export interface ImportField {
  field_id: string;
  field_title: string;
  field_type: string;
  concept_type?: string;
  description?: string;
  field_map: ImportFieldMapItem[];
  field_options: ImportFieldOption[];
}

export interface ImportFields {
  main_options: ImportField;
  import_fields: ImportField[];
}

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(private cms: CmsApiService) {
  }

  async getMainOptions(): Promise<ImportField> {
    const res: ImportFields = await this.cms.getImportFields();
    return res.main_options;
  }

  async getFields(): Promise<ImportField[]> {
    const res: ImportFields = await this.cms.getImportFields();
    return res.import_fields;
  }

  createFormGroup(importField: ImportField): FormGroup {
    const group = {};
    for (const mapField of importField.field_map) {
      group[mapField.target_field.field_name] = this.createFormControlForMapField(mapField);
    }
    for (const fieldOption of importField.field_options) {
      group[fieldOption.name] = this.createFormControlForFieldOption(fieldOption);
    }
    return new FormGroup(group);
  }

  private createFormControlForMapField(mapField: ImportFieldMapItem) {
    const validators = [];
    if (mapField.target_field.required) {
      validators.push(Validators.required);
    }
    const formControl = new FormControl<string | ImportFieldMapItem>(mapField.import_file_field_name, validators);
    formControl.valueChanges.subscribe(newValue => {
      mapField.filtered_columns = this.filterColumns(mapField, newValue);
    });
    return formControl;

  }

  private filterColumns(mapField: ImportFieldMapItem, newValue: string | ImportFieldMapItem) {
    let importFieldName = typeof newValue === 'string' ? newValue : newValue.import_file_field_name;
    if (importFieldName) {
      return mapField.columns.filter(
        column => column.toLowerCase().startsWith(importFieldName.toLowerCase()));
    } else {
      return mapField.columns;
    }
  }

  private createFormControlForFieldOption(fieldOption: ImportFieldOption) {
    const validators = [];
    if (fieldOption.required) {
      validators.push(Validators.required)
    }
    const formControl = new FormControl<string | ImportFieldAlternative>(fieldOption.selected_alternative, validators);
    formControl.valueChanges.subscribe(newValue => {
      fieldOption.filtered_alternatives = this.filterAlternatives(fieldOption, newValue);
    });
    return formControl;
  }

  private filterAlternatives(fieldOption: ImportFieldOption, newValue: string | ImportFieldAlternative) {
    let title = typeof newValue === 'string' ? newValue : newValue.title;
    if (title) {
      return fieldOption.alternatives.filter(
        alternative => alternative.title.toLowerCase().startsWith(title.toLowerCase()));
    } else {
      return fieldOption.alternatives;
    }
  }

}
