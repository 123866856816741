<div class="primus-solr-table-filter-wrapper">
  <header>
    <h5 class="heading">{{ filterHeading | translate }}</h5>
  </header>
  <section class="filter-options">


    <ng-content></ng-content>



    <div class="small-mat-form-outline">
      <mat-form-field appearance="outline" color="primary">
        <mat-label>{{ searchBarPlaceholder | translate }}</mat-label>
        <!--suppress HtmlFormInputWithoutLabel -->
        <input matInput [formControl]="filterControl" autocomplete="off">
        <button mat-icon-button
                matSuffix
                aria-label="clear"
                type="button"
                class="clear-icon"
                (click)="filterControl.setValue('')"
                *ngIf="filterControl && filterControl.value">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button
                matSuffix
                class="search-icon"
                type="button">
          <mat-icon matSuffix color="accent">search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <app-primus-menu-button menuButtonIcon="settings"
                            [menuItems]="settings"
                            (menuItemClicked)="settingClicked.emit($event)"
                            *ngIf="!!settings?.length">
    </app-primus-menu-button>

  </section>
</div>
