<div class="adminEventCreate operation-view-upload" *ngIf="uploadMediaContainer">
  <div class="row operation-view-upload__selector-general"
       [ngClass]="{'disabled': parentObject.$$annotateImage}">
    <app-media-selector-general
      [uploadInfo]="uploadMediaContainer.uploadInfo"
      [parentObject]="operationContainer.contentInfo.artifact"
      [files]="uploadMediaContainer.files"
      (deleteImage)="handleDeleteImage($event)"
      (uploadComplete)="handleUploadComplete()"
    ></app-media-selector-general>
  </div>

  <div class="row operation-view-upload__uploaded-content">
    <span class="operation-view-upload__uploaded-headline bold" translate="TRANS__IMAGE_SELECTOR__STEP_2_HEADLINE"></span>
    <span class="operation-view-upload__uploaded-description" translate="TRANS__IMAGE_SELECTOR__STEP_2_DESCRIPTION"></span>
    <hr>
    <div>
      <app-media-list
        [uploadMediaContainer]="uploadMediaContainer"
        [parentObject]="parentObject"
        (deleteMedia)="handleDeleteImage($event)"
      ></app-media-list>
    </div>
  </div>
</div>
