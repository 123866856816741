import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {FaqLoader} from './faqloader';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {OperationService} from '../../operations/operation.service';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {OperationDef} from '../../core/definitions/operation-def';

@Component({
  selector: 'app-admin-main-page',
  templateUrl: './admin-main-page.component.html',
  styleUrls: ['./admin-main-page.component.scss']
})
export class AdminMainPageComponent implements OnInit, AfterViewInit {

  AConst = AConst;
  parent;
  adminFq = [];
  visibleFq = {};
  operationContainer: OperationContainer;
  adminOperation: OperationDef;

  constructor(private primusRouter: PrimusRouterService,
              public primusStateMapper: PrimusStateMapperService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private operationService: OperationService) {
  }

  ngOnInit() {
    this.parent = {
      adminType: 'admin',
      hasSubMenus: false,
      currentState: this.primusRouter.currentState()
    };
  }

  ngAfterViewInit() {
    this.getMenus().then();
  }

  toggleFq(index) {
    this.visibleFq[index] = !this.visibleFq[index];
  }

  private async getMenus() {
    this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.MAIN_MENU_VIEW);
    await this.operationService.setOperations(this.operationContainer);
    if (this.operationContainer.operations) {
      const faqLoader = new FaqLoader(this.operationContainer.operations, this.cms);
      const faqData = await faqLoader.getFAQData();
      faqData.forEach(f => {
        f.faq.forEach(fq => {
          this.adminFq.push(fq);
        });
      });
      this.setAdminOperations();
    }
  }

  private setAdminOperations() {
    this.operationContainer.operations.forEach(operation => {
      const menuType = operation.menu_category;
      if (menuType === 'admin') {
        this.adminOperation = operation;
      }
    });
  }
}
