import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContentListSourceContainer} from '../../core/definitions/object-content-tab/content-list-source-container';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationDef} from '../../core/definitions/operation-def';

@Component({
  selector: 'app-procedure-content-list-source',
  templateUrl: './procedure-content-list-source.component.html',
  styleUrls: ['./procedure-content-list-source.component.scss']
})
export class ProcedureContentListSourceComponent implements OnInit {

  @Input() operationContainer: OperationContainer;
  @Input() smallScreen;
  @Input() isEditing;

  @Output() toggleChangeImageOrder = new EventEmitter<void>();
  @Output() toggleMenu = new EventEmitter();
  @Output() procedureLoad = new EventEmitter();

  parentOperation: OperationDef;

  constructor() {
  }

  ngOnInit() {
    this.parentOperation = this.operationContainer.currentOperation;
  }

  openModal() {
    this.operationContainer.openOperationDialogFn(this.operationContainer);
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  onToggleChangeImageOrder() {
    this.toggleChangeImageOrder.emit();
  }

  loadProcedure() {
    this.procedureLoad.emit();
  }

  onNewObjectCreated(objectType: string) {
    let newCurrentContentListSource: ContentListSourceContainer;
    this.operationContainer.contentInfo.curContentListSource.subMenus.forEach(subMenu => {
      if (subMenu.relatedObjectType === objectType) {
        newCurrentContentListSource = subMenu;
      }
    });
    if (newCurrentContentListSource) {
      this.operationContainer.contentInfo.setContentListSource(newCurrentContentListSource);
    } else {
      console.warn('Unable to find sub menu from object type ' + objectType);
    }
  }
}
