<div class="change-media-order">
  <div class="change-media-order__content dropdownIgnoreClicks">
    <button mat-icon-button class="change-media-order__close"
            type="button"
         (click)="closeAndReset()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="change-media-order__headline">
      <h3 translate="TRANS__CHANGE_MEDIA_ORDER__HEADLINE"></h3>
      <span translate="TRANS__CHANGE_MEDIA_ORDER__INGRESS"></span></div>
    @for (mediaType of mediaContainer.mediaTypes; track mediaType) {
      <div class="change-media-order__media-type">
        <div class="change-media-order__move">
          <i class="icon-{{mediaType.mediaType.toLowerCase()}}
          change-media-order__media-type-icon"></i>
          <span class="bold" [translate]="mediaType.objectMediaName"></span>
        </div>
        <app-change-media-order-media-type
          [objectMediaType]="mediaType"
          (mediaOrderChanges)="onChanges($event)"
        ></app-change-media-order-media-type>
      </div>
    }
  </div>

  <div class="change-media-order__action-toolbar">
    <button mat-flat-button
            color="accent"
            type="submit"
            class="change-media-order__store-button"
            [disabled]="!changes"
            (click)="saveChanges()">
      <span class="bold" translate="TRANS__CHANGE_MEDIA_ORDER__SAVE"></span>
    </button>
    <button mat-button
            class="change-media-order__cancel-button"
            type="button"
            (click)="closeAndReset()">
      <span class="bold" translate="TRANS__CHANGE_MEDIA_ORDER__CANCEL"></span></button>
  </div>
</div>

