import {Injectable} from '@angular/core';
import {Facet, FacetItem, FilterGroupHierarchyNode, HierarchicFilterGroup} from '../core/definitions/search-objects';
import {CommonsService} from '../core/commons.service';
import {SearchParameterService} from './search-parameter.service';
import {SearchContainer} from '../core/definitions/search-container';
import {LoggerService} from '../core/logger.service';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SearchService} from "../core/search.service";

@Injectable({
  providedIn: 'root'
})
export class HierarchicFilterGroupService {

  constructor(private matDialog: MatDialog,
              private commons: CommonsService,
              private logger: LoggerService,
              private searchParameterService: SearchParameterService,
              private searchService: SearchService) {
  }

  async setHierarchicFilterGroup(searchContainer: SearchContainer): Promise<void> {
    const orig = searchContainer.currentPathView.search_view.hierarchic_filter_group;
    let res: HierarchicFilterGroup;
    if (orig) {
      if (searchContainer.filtersFacets.hierarchicFilterGroup &&
        searchContainer.filtersFacets.hierarchicFilterGroup.title === orig.title) {
        // This may happen when returning to search page after being in another route state
        return;
      }
      const progressModal = this.matDialog.open(ProgressDialogComponent, {
        disableClose: true,
        panelClass: 'progress-modal'
      });
      try {
        res = this.commons.copy(orig);
        await this.generateHierarchy(searchContainer, res);
        this.generateHierarchyArrays(res);
        progressModal.close();
      } catch (e) {
        this.logger.error('Error generating filter hierarchy: ' + e);
        progressModal.close();
      }
    }
    searchContainer.filtersFacets.hierarchicFilterGroup = res;
  }

  private async generateHierarchy(searchContainer: SearchContainer, hierarchicGroup: HierarchicFilterGroup) {
    hierarchicGroup.children = {};
    const searchParams = await this.searchParameterService.getSearchParams(searchContainer);
    searchParams.fl = ['artifact_id'];
    const searchRes = await this.searchService.search(searchParams);
    const facet = this.getHierarchicFacet(hierarchicGroup.list_filter_field, searchRes.facets);
    if (!facet) {
      return;
    }
    for (const item of facet.items) {
      const itemName = this.getCleanItemName(item.name);
      const splitNames = itemName.split('» ');
      let currentHierarchy = hierarchicGroup;
      for (const name of splitNames) {
        currentHierarchy.children[name] = currentHierarchy.children[name] || new HierarchicFilterGroup();
        currentHierarchy = <HierarchicFilterGroup>currentHierarchy.children[name];
      }
      currentHierarchy.facet = this.beautifyItem(item);
      currentHierarchy.facet.selected = false;
    }
  }

  private getHierarchicFacet(facetName: string, facets: Facet[]): Facet {
    let res = facets.find(facet => facet.f_name === facetName);
    if (!res) {
      this.logger.warn(`Facet not found: ${facetName}`);
    }
    return res;
  }

  // Remove extra item name information that prevents building of hierarchy from working
  private getCleanItemName(itemNameIn: string) {
    let res = itemNameIn.replace('<del>', '');
    if (itemNameIn.endsWith(')')) {
      const lastParenthesisStart = itemNameIn.lastIndexOf(' (');
      if (lastParenthesisStart !== -1) {
        res = res.substring(0, lastParenthesisStart);
      }
    }
    for (const unclean of [': ']) {
      const unCleanIndex = res.indexOf(unclean);
      if (unCleanIndex !== -1) {
        res = res.substring(0, unCleanIndex);
        break;
      }
    }
    return res;
  }

  private beautifyItem(item: FacetItem): FacetItem {
    let itemName = item.name;
    let addDel = itemName.startsWith('<del>') ? '<del>' : '';
    const splitNames = itemName.split('» ');
    item.shortName = addDel + splitNames[splitNames.length - 1];
    return item;
  }

  // Generating and using arrays will speed up the GUI for drawing nodes
  private generateHierarchyArrays(filterGroupHierarchyNode: FilterGroupHierarchyNode) {
    filterGroupHierarchyNode.childrenArray = Object.values(filterGroupHierarchyNode.children).sort(this.sortNodes);
    for (const node of filterGroupHierarchyNode.childrenArray) {
      this.generateHierarchyArrays(node);
    }
  }

  private sortNodes(node1: FilterGroupHierarchyNode, node2: FilterGroupHierarchyNode) {
    if (!node1.facet.name || !node2.facet.name) {
      return 0;
    }
    const node1Name = node1.facet.name.toLocaleUpperCase();
    const node2Name = node2.facet.name.toLocaleUpperCase();
    if (node1Name < node2Name) {
      return -1
    } else if (node1Name > node2Name) {
      return 1
    } else {
      return 0;
    }
  }
}
