<div class="input-wrapper">

  <app-text-input *ngIf="inputFieldType === 'FREE_TEXT'"
                  [field]="field"
                  [label]="label"
                  placeholder="TRANS__ADVANCED_SEARCH__FREE_TEXT_SEARCH_PLACEHOLDER"
                  (searchValueChanged)="handleSearchValueChanged($event)">
  </app-text-input>

  <app-text-input *ngIf="inputFieldType === 'TEXT'"
                  [field]="field"
                  [label]="label"
                  placeholder="TRANS__ADVANCED_SEARCH__TEXT_SEARCH_PLACEHOLDER"
                  (searchValueChanged)="handleSearchValueChanged($event)">
  </app-text-input>

  <app-number-input *ngIf="inputFieldType === 'NUMBER'"
                    [field]="field"
                    [label]="label"
                    placeholder="TRANS__ADVANCED_SEARCH__NUMBER_SEARCH_PLACEHOLDER"
                    (searchValueChanged)="handleSearchValueChanged($event)">
  </app-number-input>

  <app-boolean-input *ngIf="inputFieldType === 'BOOLEAN'"
                     [field]="field"
                     [label]="label"
                     placeholder="TRANS__ADVANCED_SEARCH__CHECKBOX_SELECT_PLACEHOLDER"
                     (searchValueChanged)="handleSearchValueChanged($event)">
  </app-boolean-input>

  <app-date-input *ngIf="inputFieldType === 'DATE'"
                  [field]="field"
                  [label]="label"
                  fromDatePlaceholder="TRANS__ADVANCED_SEARCH__DATE_FROM_PLACEHOLDER"
                  toDatePlaceholder="TRANS__ADVANCED_SEARCH__DATE_TO_PLACEHOLDER"
                  (searchValueChanged)="handleSearchValueChanged($event)">
  </app-date-input>

  <app-select-input *ngIf="inputFieldType === 'SELECT' || inputFieldType === 'SELECT_MULTIPLE'"
                    [field]="field"
                    [label]="label"
                    [multiple]="inputFieldType === 'SELECT_MULTIPLE'"
                    placeholder="TRANS__ADVANCED_SEARCH__SEARCH_DROPDOWN_PLACEHOLDER"
                    (searchValueChanged)="handleSearchValueChanged($event)">
  </app-select-input>

  <span *ngIf="inputFieldType === 'UNSUPPORTED'">{{ label }}</span>

</div>
