import {Component, ElementRef, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {ContentMenu} from '../../core/definitions/object-content-tab/content-menus';

@Component({
  selector: 'app-object-content-main-tab',
  templateUrl: './object-content-main-tab.component.html',
  styleUrls: ['./object-content-main-tab.component.scss']
})
export class ObjectContentMainTabComponent implements OnChanges {
  @Input() menu: ContentMenu;
  @Input() invisible: boolean;
  @Input() isEditing: boolean;
  @Output() menuOpened = new EventEmitter<ContentMenu>();

  AConst = AConst;
  private tabItem;

  constructor(private tabElementRef: ElementRef) {
  }

  ngOnChanges() {
    this.tabItem = this.tabElementRef.nativeElement;
  }

  openMenu() {
    this.menuOpened.emit(this.menu);
  }

  getTabElement() {
    return this.tabElementRef.nativeElement;
  }

}
