@for (img of media; track img; let i = $index) {
  <div class="thumbnails-carousel"
       (click)="setMedia(i)"
       [attr.data-index]="i"
       [ngStyle]="{'width': 'calc(' + thumbnailWidth + 'px - 2px)'}"
       [ngClass]="{'selected border-blue': i === mediaIndex &&
     currentMediaType === img.mediaObject.object_type}">
    <img id='thumb-{{img.mediaObject.object_type}}-{{i}}'
         [alt]="img.mediaObject.artifact_name"
         [src]='img.mediaObject.$$thumbUrl'>
    <div class="thumbnails-carousel__media-type">
      <i class="icon-{{img.mediaObject.object_type.toLowerCase()}}"></i>
    </div>
  </div>
}
