@if (hideHideSectionWarning) {
  <div class="hide-section-warning">
        <span class="embedded-alert-warning">
            <span class="icon-warning"></span>
            <span [translate]="hideSectionWarning"></span>
          <button type="button" (click)="setHideHideSectionWarning()"><mat-icon>close</mat-icon></button>
  </span></div>
}

<div class="section-view-title-edit">
  <h4 [ngClass]="{'disable-button': sectionsContainer.isTemplate &&
      section.section_visible !== undefined &&
      !section.section_visible}"
      class="bold"
      [translate]="section.title"></h4>
  @if (sectionInfo.showKeepCheckbox) {
    <mat-checkbox
      class="section-view-title-edit__checkbox"
      (change)="setSectionKeep()"
      [id]="section.name"
      [checked]="sectionInfo.keep"
      (keyup)="onSetSectionKeepWithKey($event)">
    </mat-checkbox>
  }
  @if (sectionsContainer.isTemplate && section.section_visible !== undefined && section.name !== 'overview') {
    <mat-checkbox
      [checked]="section.section_visible"
      (change)="toggleSectionVisible()"
      class="section-view-title-edit__checkbox"
    ><span translate="TRANS__SECTION__CHECK_VISIBLE"></span></mat-checkbox>
  }
</div>
