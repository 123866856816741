import {Component, Input, OnInit} from '@angular/core';
import {AnnotationHandler} from '../../annotation-handler';

@Component({
  selector: 'app-annotation-description',
  templateUrl: './annotation-description.component.html',
  styleUrls: ['./annotation-description.component.scss']
})
export class AnnotationDescriptionComponent implements OnInit {

  @Input() curAnn: AnnotationHandler;
  @Input() isDialog: boolean;
  private timeoutId;

  constructor() {
  }

  ngOnInit() {
  }

  textChange() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.curAnn.setDescription();
    }, 500);
  }

}
