import {Component, Input, OnInit} from '@angular/core';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';
import {CmsApiService} from '../../../core/cms-api.service';
import {ConceptSetting} from '../../../core/definitions/concept-setting';
import {UserCacheService} from "../../../core/user-cache.service";

@Component({
  selector: 'app-admin-list-settings',
  templateUrl: './admin-list-settings.component.html',
  styleUrls: ['./admin-list-settings.component.scss']
})
export class AdminListSettingsComponent implements OnInit {

  @Input() conceptsContainer: ConceptsContainer;

  conceptSetting: ConceptSetting;

  constructor(private cms: CmsApiService,
              private userCacheService: UserCacheService) {

  }

  ngOnInit(): void {
    this.setConceptSetting().then();
  }

  async store() {
    await this.cms.putSettingConcept(this.conceptSetting);
  }

  private async setConceptSetting() {
    const userData = await this.userCacheService.getUserData()
    if (userData.rights_level.toLowerCase() === 'admin') {
      this.conceptSetting = await this.cms.getSettingConcept(this.conceptsContainer.conceptType.concept_type_id);
    }
  }
}
