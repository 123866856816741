import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FocusServiceImplementation} from '../../search-focus.service';
import {Focus} from '../../../core/definitions/focus';

export interface SearchFocusEditDialogData {
  fsi: FocusServiceImplementation;
  focusNameQuery: string;
  originalFocus: boolean;
  focusInEdit: Focus;
}

@Component({
  selector: 'app-search-focus-edit-dialog',
  templateUrl: './search-focus-edit-dialog.component.html',
  styleUrls: ['./search-focus-edit-dialog.component.scss']
})
export class SearchFocusEditDialogComponent implements OnInit {

  validateError = null;

  constructor(public dialogRef: MatDialogRef<SearchFocusEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SearchFocusEditDialogData) {
  }

  ngOnInit() {
  }

  clearNameInput() {
    this.data.focusNameQuery = '';
  }

  validateCurrentAndAddNewName() {
    let res = false, t, f;
    const query = this.data.focusNameQuery;
    if (query !== undefined && query !== '') {
      const focuses = this.getFocuses();
      for (t = 0; t < focuses.length; t++) {
        f = focuses[t];
        if (this.data.focusInEdit.focus.name !== query.trim() && f.focus.name.trim() === query.trim()) {
          this.validateError = 'TRANS__FOCUS__EDIT__VALIDATE__NAME_EXISTS';
          res = true;
          break;
        }
      }
      if (this.data.focusInEdit.focus.name === query.trim() &&
        this.data.originalFocus === this.data.focusInEdit.focus.isDefault) {
        res = true;
        this.validateError = null;
      }
    } else {
      this.validateError = 'TRANS__FOCUS__EDIT__VALIDATE__NO_NAME';
      res = true;
    }
    return res;
  }

  toggleIsDefault(fo) {
    this.data.fsi.toggleFocusIsDefault(fo);
  }

  saveFocus() {
    this.dialogRef.close(this.data.focusNameQuery);
  }

  private getFocuses() {
    return this.data.fsi.getFocuses();
  }
}
