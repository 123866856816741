import { Injectable } from '@angular/core';
import {MetaField} from '../core/definitions/meta-field';
import {SearchParameterService} from './search-parameter.service';
import {TranslateService} from '@ngx-translate/core';
import {SearchContainer} from '../core/definitions/search-container';
import {OverviewField} from "../core/definitions/object-view";

export interface SubTitleCounter {
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class SearchViewListService {

  private initializingObjectFields = false;


  constructor(private translateService: TranslateService,
              private searchParameterService: SearchParameterService) { }

  initObjectFields(searchContainer: SearchContainer, objectFields) {
    const searchObjects = searchContainer ? searchContainer.searchResult.artifacts : null;
    let modelOverviewFields: Array<MetaField>;
    if (searchContainer && searchObjects && !this.initializingObjectFields) {
      modelOverviewFields = searchContainer.currentPathView.search_view.overview_fields;
      this.checkCanSetOrder(modelOverviewFields);
      this.initializingObjectFields = true;
      for (let searchObject of searchObjects) {
        objectFields[searchObject.artifact_id] = searchObject.overview;
      }
      this.initializingObjectFields = false;
    }
  }

  setDisplayedColumns(searchContainer: SearchContainer, displayedCol: string[]) {
    displayedCol[0] = 'artifact_name';
    if (searchContainer) {
      const modelOverviewFields: Array<MetaField> = searchContainer.currentPathView.search_view.overview_fields;
      if (modelOverviewFields) {
        for (let column of modelOverviewFields) {
          if (column && column.field_name) {
            displayedCol.push(this.setFieldColumnName(column));
          }
        }
      }
    }
  }

  setFieldColumnName(field: MetaField | OverviewField) {
    if (!field.$$fieldColumnName) {
      field.$$fieldColumnName = `${field.field_name}-${field.order}`;
    }
    return field.$$fieldColumnName;
  }

  getTitle(field: MetaField, subTitleCounter: SubTitleCounter) {
    const fieldTitle = field.title || field.admin_title;
    let res = 'No title!';
    if (fieldTitle) {
      if (!Array.isArray(fieldTitle)) {
        res = this.translateService.instant(fieldTitle);
      } else {
        res = this.translateService.instant(fieldTitle[subTitleCounter.count++]);
        if (subTitleCounter.count === fieldTitle.length) {
          subTitleCounter.count = 0;
        }
      }
    } else {
      console.warn('Field has no title: ' + field.field_uuid + ': ' + field.field_name);
    }
    return res;
  }

  private checkCanSetOrder(modelOverviewFields: Array<MetaField>) {
    for (let field of modelOverviewFields || []) {
      field.isSortable = this.searchParameterService.checkFieldIsSortable(field);
    }
  }
}
