<mat-dialog-content>
  <h2 matDialogTitle>{{ 'TRANS__SELECT_OBJECT_TEMPLATE_GROUP__SELECT_TEMPLATE_LABEL' | translate }}</h2>
  <p>{{ 'TRANS__SELECT_OBJECT_TEMPLATE_GROUP__SELECT_TEMPLATE_DESCRIPTION' | translate }}</p>

  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>{{ 'TRANS__SELECT_OBJECT_TEMPLATE_GROUP__SELECT_TEMPLATE_LABEL' | translate }}</mat-label>
    <input type="text"
           matInput
           width="100%"
           [formControl]="formControl"
           [matAutocomplete]="auto">

    @if (formControl.value) {
      <button matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
        <mat-icon class="material-symbols-outlined">close</mat-icon>
      </button>
    }

    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn"
    >
      @for (option of filteredOptions | async; track option.template_group_id) {
        <mat-option [value]="option">
          {{ option.group_name }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">{{ 'TRANS__SELECT_OBJECT_TEMPLATE_GROUP__CANCEL' | translate }}</button>
  <button mat-flat-button (click)="confirmSelection()">{{ 'TRANS__SELECT_OBJECT_TEMPLATE_GROUP__OK' | translate }}</button>
</mat-dialog-actions>
