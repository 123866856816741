<div class="concept-list-selector admin-scroll-layout">
  <div class="admin-content-top concept-list-selector__list-title">
    <h5 translate="TRANS__SEARCH_VIEWS__CONCEPT_HOME"></h5></div>
  <div class="concept-table">
    <ng-container *ngIf="conceptTypeMainMenus.data.length && currentConceptTypeMenu;else noConceptTypes">
      <table [dataSource]="conceptTypeMainMenus" mat-table>

        <ng-container matColumnDef="letter">
          <td mat-cell *matCellDef="let conceptTypeMainMenu"
              class="concept-table__letter text-primary">
            <span>{{conceptTypeMainMenu['letter']}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="list">
          <td mat-cell *matCellDef="let conceptTypeMainMenu"
              class="concept-table__lists">
            <div *ngFor="let subMenu of conceptTypeMainMenu['subMenus']"
                 class="concept-table__list">

              <div class="concept-table__list-content text-primary-hover" *ngIf="subMenu"
                   (click)="toggleConcept(subMenu)"
                   [ngClass]="{'active': subMenu['menuTitle'] === currentConceptTypeMenu['menuTitle'],
                         'active-parent': subMenu['menuTitle'] === currentConceptTypeMenu.parentName}">
                <div *ngIf="subMenu['menuTitle'] === currentConceptTypeMenu['menuTitle']"
                     class="concept-table__list-vertical-line"></div>
                <div class="concept-table__list-name-content">
                  <span class="concept-table__list-name" [translate]="subMenu['menuTitle']"></span>
                  <mat-icon *ngIf="subMenu['subMenus'].length === 0">chevron_right</mat-icon>
                  <a *ngIf="subMenu['subMenus'].length > 0 && !subMenu.open">
                          <span class="concept-table__list-has-sub-menu toneDownTextColor">
                            <span translate="TRANS__ADMIN_CONCEPT__SHOW_SUB_CATEGORY"></span>
                            <span>({{subMenu['subMenus'].length}})</span></span>
                    <mat-icon>expand_more</mat-icon>
                  </a>
                  <a *ngIf="subMenu['subMenus'].length > 0 && subMenu.open">
                          <span class="concept-table__list-has-sub-menu">
                            <span translate="TRANS__ADMIN_CONCEPT__CLOSE_SUB_CATEGORY"></span></span>
                    <mat-icon>close</mat-icon>
                  </a>
                </div>
              </div>

              <div *ngIf="subMenu.open"
                   class="concept-table__sub-lists">
                <div class="concept-table__sub-list text-blue-hover"
                     *ngFor="let subSubMenu of subMenu['subMenus']"
                     [ngClass]="{'active': subSubMenu['menuTitle'] === currentConceptTypeMenu['menuTitle']}"
                     (click)="toggleConcept(subSubMenu)">
                  <div *ngIf="subSubMenu['menuTitle'] === currentConceptTypeMenu['menuTitle']"
                       class="concept-table__list-vertical-line"></div>
                  <div class="concept-table__sub-list-content">
                    <span class="concept-table__list-name" [translate]="subSubMenu['menuTitle']"></span>
                    <mat-icon>chevron_right</mat-icon>
                    <hr>
                  </div>

                </div>
              </div>
            </div>
            <div *ngIf="conceptTypeMainMenu['subMenus'].length === 0"
                 class="concept-table__no-list">
              <div class="concept-table__list-name-content">
                <span class="toneDownTextColor" translate="TRANS__ADMIN_CONCEPT__NO_LISTS_IN_CATEGORY"></span>
              </div>
            </div>

          </td>
        </ng-container>
        <tr *matRowDef="let element; columns: listColumns;"
            class="concept-rows" mat-row></tr>
      </table>
    </ng-container>
    <ng-template #noConceptTypes>
      <span class="concept-table__no-concept-type" translate="TRANS__ADMIN_CONCEPT__NO_CONCEPT_TYPES"></span>
    </ng-template>
  </div>
</div>
