<div class="dialog admin-id-format-edit-dialog">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span>
      {{
        (
          !!data && !!data.artifact_id ?
            'TRANS__ADMIN__ID_FORMAT__EDIT_EXISTING_HEADING'
            :
            'TRANS__ADMIN__ID_FORMAT__CREATE_NEW_HEADING'
        ) | translate
        }}
      </span>
    </h1>
    <span *ngIf="!!data && !!data.identifier_format_collection_id" class="admin-id-format-edit-dialog__description">
      {{ 'TRANS__ADMIN__ID_FORMAT__IN_COLLECTION_HEADING' | translate }}
      : {{ data.identifier_format_collection_id_value | translate }}
    </span>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content class="modal-body">
    <form class="modal-main-content" [formGroup]="form">

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_FORMAT_LABEL' | translate }}</mat-label>
        <input matInput
               formControlName="format"
               autocomplete="off"
               [placeholder]="'TRANS__ADMIN__ID_FORMAT__TABLE_COL_FORMAT_LABEL' | translate"
               type="text"
               required>

        <mat-hint>{{ 'TRANS__ADMIN__ID_FORMAT__HINT__FORMAT' | translate }}</mat-hint>

        <mat-error *ngIf="form.hasError('required', 'format')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__REQUIRED' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('startWithPrefix', 'format')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__START_WITH_PREFIX' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('mustContainNumber', 'format')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__MUST_CONTAIN_NUMBER' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('invalidFormatting', 'format')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__INVALID_FORMATTING' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('invalidSegment', 'format')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__INVALID_SEGMENT' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('hasDuplicates', 'format')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__DUPLICATE_SEGMENT' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('invalidSeparator', 'format')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__INVALID_SEPARATOR' | translate }}
        </mat-error>

      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>{{ 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_PREFIX_LABEL' | translate }}</mat-label>
        <input matInput
               formControlName="prefix"
               autocomplete="off"
               [placeholder]="'TRANS__ADMIN__ID_FORMAT__TABLE_COL_PREFIX_LABEL' | translate"
               type="text"
               required>

        <mat-hint>{{ 'TRANS__ADMIN__ID_FORMAT__HINT__PREFIX' | translate }}</mat-hint>

        <mat-error *ngIf="form.hasError('required', 'prefix')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__REQUIRED' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('pattern', 'prefix')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__INVALID_CHARACTER' | translate }}
        </mat-error>

      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>{{ 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_NUM_DIGITS_LABEL' | translate }}</mat-label>
        <input matInput
               formControlName="digits"
               autocomplete="off"
               [placeholder]="'TRANS__ADMIN__ID_FORMAT__TABLE_COL_NUM_DIGITS_LABEL' | translate"
               type="number"
               required>


        <mat-error *ngIf="form.hasError('required', 'digits')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__REQUIRED' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('min', 'digits')">
          {{
          'TRANS__ADMIN__ID_FORMAT__ERROR__MIN_NUMBER' |
            translate: {min: form.getError('min', 'digits').min}
          }}
        </mat-error>

      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>{{ 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_SEPARATOR_LABEL' | translate }}</mat-label>
        <input matInput
               formControlName="subtype_separator"
               autocomplete="off"
               [placeholder]="'TRANS__ADMIN__ID_FORMAT__TABLE_COL_SEPARATOR_LABEL' | translate"
               type="text"
               required>


        <mat-error *ngIf="form.hasError('required', 'subtype_separator')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__REQUIRED' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('maxlength', 'subtype_separator')">
          {{
          'TRANS__ADMIN__ID_FORMAT__ERROR__MAX_LENGTH' |
            translate: {maxLength: form.getError('maxlength', 'subtype_separator')['requiredLength']}
          }}
        </mat-error>

        <mat-error *ngIf="form.hasError('pattern', 'subtype_separator')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__ONLY_SYMBOLS' | translate }}
        </mat-error>

      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>{{ 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_NUM_SUB_DIGITS_LABEL' | translate }}</mat-label>
        <input matInput
               formControlName="subtype_digits"
               autocomplete="off"
               [placeholder]="'TRANS__ADMIN__ID_FORMAT__TABLE_COL_NUM_SUB_DIGITS_LABEL' | translate"
               type="number"
               required>


        <mat-error *ngIf="form.hasError('required', 'subtype_digits')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__REQUIRED' | translate }}
        </mat-error>

        <mat-error *ngIf="form.hasError('min', 'subtype_digits')">
          {{
          'TRANS__ADMIN__ID_FORMAT__ERROR__MIN_NUMBER' |
            translate: {min: form.getError('min', 'subtype_digits').min}
          }}
        </mat-error>

      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>{{ 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_OBJECT_TYPE_LABEL' | translate }}</mat-label>
        <mat-select formControlName="identifier_format_superobject_type_id"
                    [placeholder]="'TRANS__ADMIN__ID_FORMAT__TABLE_COL_OBJECT_TYPE_LABEL' | translate"
                    required>
          <mat-option *ngFor="let objectType of objectTypes"
                      [value]="objectType.id">
            {{ objectType.name | translate }}
          </mat-option>
        </mat-select>


        <mat-error *ngIf="form.hasError('required', 'identifier_format_superobject_type_id')">
          {{ 'TRANS__ADMIN__ID_FORMAT__ERROR__REQUIRED' | translate }}
        </mat-error>

      </mat-form-field>


    </form>
  </div>

  <div mat-dialog-actions class="modal-footer">
    <button mat-flat-button
            class="bold"
            type="button"
            color="accent"
            [disabled]="form.pending || form.invalid || !form.dirty"
            (click)="save()">
      {{ 'TRANS__ADMIN__ID_FORMAT__SAVE_BUTTON_LABEL' | translate }}
    </button>
    <button mat-button
            type="button"
            color="accent"
            class="bold"
            (click)="cancel()">
      {{ 'TRANS__ADMIN__ID_FORMAT__CANCEL_BUTTON_LABEL' | translate }}
    </button>
  </div>

</div>
