<mat-list  class="object-content-list-source-array-sort common-search-menu {{viewName}}">

  <button *ngFor="let s of contentListSource.sortFields; index as $index"
          mat-menu-item
          [ngClass]="{'active':contentListSource.order === s}">
    <mat-radio-button
      [checked]="contentListSource.order === s"
      [value]="s"
      (click)="changeSortOrder(s)">
      <span class="common-search-menu__radio-text" [translate]="s.description"></span>
    </mat-radio-button>
  </button>

</mat-list>
