import {Component, Input, OnChanges} from '@angular/core';
import {OperationService} from '../operation.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationContainer} from '../../core/definitions/operation-container';

@Component({
  selector: 'app-operation-menus-object-small',
  templateUrl: './operation-menus-object-small.component.html',
  styleUrls: ['./operation-menus-object-small.component.scss']
})
export class OperationMenusObjectSmallComponent implements OnChanges {

  @Input() operationContainer: OperationContainer;

  constructor(private operationService: OperationService,
              private currentObjectService: CurrentObjectService) {
  }

  ngOnChanges(): void {
    this.operationService.setOperations(this.operationContainer).then();
  }

  get isEditing() {
    return this.currentObjectService.isEditing;
  }

  get mediaPage() {
    return this.operationContainer.operationContextObjects[0].meta_type === 'media';
  }

  get rootObject(): SuperObjectModel {
    return this.operationContainer.operationContextObjects[0];
  }

  openOperation(operation: OperationDef, parentOperation: OperationDef, trigger) {
    if (!operation.sub_operations) {
      trigger.closeMenu();
    }
    this.operationService.openOperationView(this.operationContainer, operation, parentOperation, operationRes => {
      console.log('Operation res: ' + operationRes);
    }).then(res => {
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
  }

  toggleOperation(operation: OperationDef) {
    this.operationContainer.viewName = 'object-menus-small';
    this.operationService.toggleOperationMenu(this.operationContainer, operation, true).then(res => {
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
  }


}
