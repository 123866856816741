import { Component, OnInit } from '@angular/core';
import {ConceptService} from '../../core/concept.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {ConceptsContainer} from '../../core/definitions/concepts-container';

@Component({
  selector: 'app-admin-named-object',
  templateUrl: './admin-named-object.component.html',
  styleUrls: ['./admin-named-object.component.scss']
})
export class AdminNamedObjectComponent implements OnInit {

  constructor(private conceptService: ConceptService,
              private primusRouter: PrimusRouterService) { }

  conceptsContainer: ConceptsContainer;
  parent;

  ngOnInit(): void {
    this.setAgentConceptsContainer().then();
    this.parent = {
      path: 'admin',
      adminType: 'named_object',
      hasSubMenus: true,
      target_params: {admin_type: 'named_object'},
      currentState: this.primusRouter.currentState()
    };
  }

  async setAgentConceptsContainer() {
    const conceptType = await this.conceptService.getConceptType('named_object');
    this.conceptService.setConceptsContainer(conceptType).then(conceptsContainer => {
      this.conceptsContainer = conceptsContainer;
      this.conceptsContainer.showLinkToObject = true;
    });

  }

}
