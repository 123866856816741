<ul class="list-thumbnail object-content-list-regular-items" *ngIf="searchContainer.searchResult">
  <li class="list-thumbnail__frame-and-shadow"
      *ngFor="let art of searchContainer.searchResult.artifacts; index as i"
      [id]="'item-' + i">
    <div class="list-thumbnail__item">
      <div class="list-thumbnail__row">

        <div class="list-thumbnail__first-container">
          <div class="checkbox-underlay" *ngIf="hasOperationMenu">
            <mat-checkbox
              class="white-bg-in-checkbox"
              (click)="setSelected($event, art)"
              [disabled]="contentListContainer.operationContainer.art.read_only"
              [checked]="searchContainer.selections.selected[art.artifact_id]">
            </mat-checkbox>
          </div>

          <app-object-url
            class="object-content-list-regular-items__object-url"
            [object]="art"
            [searchContainer]="searchContainer"
            [listName]="'overview'"
            [hasImage]="true"
            [aClass]="'thumbnail-url'">
            <span class="object-url-content">
              <app-thumbnail
                *ngIf="art.thumbnail_id"
                [item]="art"
                [stylingClass]="'thumbnail-image thumbnail-fixed-height'"></app-thumbnail>
              <i *ngIf="!art.thumbnail_id" class="icon-image imgIcon"></i></span>
          </app-object-url>
        </div>

        <div class="list-thumbnail__second-container" [ngClass]="{'no-image': !art.$$imageUrl}">
          <app-search-columns-overview-fields
            class="list-thumbnail__object-overview overview-fields__content-list"
            [searchObject]="art"
            [viewName]="searchContainer.searchResultViewName"
          ></app-search-columns-overview-fields>
        </div>

      </div>
    </div>
  </li>
</ul>
