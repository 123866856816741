import {ConceptType} from '../../core/definitions/concept-types';

export class ConceptTypeMainMenu {
  subMenus: Array<ConceptTypeMenu> = [];

  constructor(public letter: string) {}
}

export class ConceptTypeMenu {
  subMenus: Array<ConceptTypeMenu> = [];
  menuTitle: string;
  conceptType: ConceptType;
  parentName: string;
  open = false;
}
