<div id="section-updated"
     class="quantity-log information-tabs-table">
  <table mat-table [dataSource]="logItems" *ngIf="logItems.length">
    <caption></caption>
    <ng-container matColumnDef="updated_at" *ngIf="logItems.length">
      <th mat-header-cell *matHeaderCellDef translate="TRANS__FIELD__UPDATED_AT"></th>
      <td mat-cell *matCellDef="let element">{{element['updated_at']}} </td>
    </ng-container>
    <ng-container matColumnDef="updated_by" *ngIf="logItems.length">
      <th mat-header-cell *matHeaderCellDef translate="TRANS__FIELD__UPDATED_BY"></th>
      <td mat-cell *matCellDef="let element"> {{element['updated_by']}} </td>
    </ng-container>
    <ng-container matColumnDef="to_quantity" *ngIf="logItems.length">
      <th mat-header-cell *matHeaderCellDef translate="TRANS__FIELD__TO_QUANTITY"></th>
      <td mat-cell *matCellDef="let element"> {{element['to_quantity']}} </td>
    </ng-container>
    <ng-container matColumnDef="from_quantity" *ngIf="logItems.length">
      <th mat-header-cell *matHeaderCellDef translate="TRANS__FIELD__FROM_QUANTITY"></th>
      <td mat-cell *matCellDef="let element"> {{element['from_quantity']}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="logItems.length === 0">
    <span translate="TRANS__OBJECT_UPDATE_LOG__NO_REGISTERED_CHANGES"></span>
  </div>
</div>
