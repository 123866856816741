import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {FocusServiceImplementation, SearchFocusService} from '../../search-focus.service';
import {SearchContainer} from '../../../core/definitions/search-container';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-search-focus-menu',
  templateUrl: './search-focus-menu.component.html',
  styleUrls: ['./search-focus-menu.component.scss']
})
export class SearchFocusMenuComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  focusButtonDropdown = false;
  showFocusPanel = false;
  fsi: FocusServiceImplementation;

  private subscriptions: Subscription[] = [];

  constructor(private searchHandler: SearchHandlerService,
              private searchFocusService: SearchFocusService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.fsi = this.searchFocusService.createFSI(this.searchContainer);
    this.subscriptions.push(this.searchFocusService.openFocusEditPanel$.subscribe(() => {
      this.showFocusPanel = true;
    }));
  }


  hasChanges() {
    return this.fsi.focusHasChanges(this.searchContainer.focus.curFocus);
  }

  toggleFocusPanel() {
    this.showFocusPanel = !this.showFocusPanel;
  }

  toggleFocusButtonDropDown() {
    this.focusButtonDropdown = !this.focusButtonDropdown;
  }

  async deselectFocus() {
    if (this.searchContainer.focus.curFocus) {
      this.searchContainer.focus.curFocus = null;
      this.searchContainer.focus.curFocusId = null;
      this.searchContainer.focus.currentFocusName = null;
      await this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer);
    }
  }













}
