<div class="query-builder">
  @if(searchContainer.currentPathView.path_name === 'home') {
    <div class="query-builder__information-container">
      <mat-icon>info_outlined</mat-icon>
      <span translate="TRANS__ADVANCED_SEARCH__NO_CATEGORY_INFORMATION"></span>
    </div>
  }
  @else {
    <div class="query-builder__builder-container">
      @for (ruleGroup of searchContainer.advancedSearchParams.query_groups; track ruleGroup; let first = $first) {
        @if (!first) {
          <div class="query-builder__builder-container__logical-operator-container">
            <mat-chip [disabled]="true">
              {{"TRANS__OPERATOR__" + ruleGroup.logical_operator + "__LITERAL" | translate}}
            </mat-chip>
          </div>
        }

        <app-advanced-search-query-builder-group
          class="query-builder__builder-container__rule-group"
          (removeSelf)="removeRuleGroup($event)"
          (updateSelf)="updateAdvancedSearchParams($event, ruleGroup)"
          [group]="ruleGroup"
          [rootGroup]="ruleGroup"
          [darken]="false"
          [searchContainer]="searchContainer"
          [toggleUpdateFieldSelector]="updateFieldSelector"
        ></app-advanced-search-query-builder-group>
      }

      <button mat-stroked-button color="primary" class="query-builder__button-container__rule-group-button" (click)="addRuleGroup()">
        <mat-icon>add</mat-icon>
        <span translate="TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__RULE_GROUP"></span>
      </button>

      <div class="query-builder__search-button-container search-clear">
        <button mat-flat-button color="primary" class="query-builder__button-container__search-button" (click)="triggerAdvancedSearch()">
          <span translate="TRANS__SEARCH_ADVANCED_SEARCH_QUERY_BUILDER__SEARCH"></span>
        </button>

        @if (localStorage.getItem('isWhitelisted')) {
          <div class="query-builder__search-button-container__db-search">
            <mat-checkbox (click)="toggleFieldUpdate()" [(ngModel)]="searchContainer.advancedSearchParams.db_search"></mat-checkbox>
            <span>SQL-søk</span>
          </div>
        }

        @if (!advancedSearchTools.hasNoRulesSet(searchContainer)) {
          <button mat-button (click)="clearAdvancedSearch()">
            <mat-icon>close</mat-icon>
            <span translate="TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__CLEAR_RULES"></span>
          </button>
        }
      </div>
    </div>
  }
</div>
