import {Component, Input, OnChanges} from '@angular/core';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {SearchObject} from '../../core/definitions/search-object';
import {RefData, RefParams, RefService, StateParams} from '../../core/ref.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {FeatureFlagsService} from '../../core/feature-flags.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-recto-verso-buttons',
  templateUrl: './recto-verso-buttons.component.html',
  styleUrls: ['./recto-verso-buttons.component.scss']
})
export class RectoVersoButtonsComponent implements OnChanges {
  @Input() object: SuperObjectModel;

  rectoObject: SuperObjectModel = null;
  versoObject: SuperObjectModel = null;
  isRecto = false;
  isVerso = false;
  hasRectoVerso = false;

  constructor(private readonly ref: RefService,
              private featureFlagsService: FeatureFlagsService,
              private router: Router) {
  }

  ngOnChanges(): void {
    this.hasRectoVerso =
      (this.object.recto_verso && !!this.object.recto_verso.relation_id) ||
      (this.object.verso_recto && !!this.object.verso_recto.context_id);
    this.versoObject = new SuperObjectModel();
    this.rectoObject = new SuperObjectModel();
    if (this.object.recto_verso?.relation_id) {
      this.versoObject.artifact_id = this.object.recto_verso.relation_id;
      this.isRecto = true;
    } else {
      this.versoObject.artifact_id = this.object.artifact_id;
    }
    if (this.object.verso_recto?.context_id) {
      this.rectoObject.artifact_id = this.object.verso_recto.context_id;
      this.isVerso = true;
    } else {
      this.rectoObject.artifact_id = this.object.artifact_id;
    }
  }
  async goToSearchItem(item: SearchObject) {
    if (item) {
      const url = ['/artifact']
      const refInfo = await this.createRef(item);
      if (this.featureFlagsService.getFeatureFlags().experimental.useNewObjectPage) {
        url.push(item.artifact_id, 'overview')
      }

      await this.router.navigate(url, {
        queryParams: refInfo.queryParams,
        queryParamsHandling: 'merge'
      })
    }
  }

  private async createRef(searchItem: SearchObject): Promise<RefData> {
    const params = new RefParams(searchItem);
    params.searchItemIndex = null;
    params.rootObjId = null;
    params.rootObjType = null;
    params.stateParams = {
      listName: 'overview'
    } as StateParams;
    return await this.ref.makeRef(params);
  }
}
