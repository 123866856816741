<div class="object-content-list-overview-fields" #sizeElement>
  <app-object-url
    class="list-overview-fields__headline"
    [object]="objectView"
    [listName]="'overview'"
    [disable]="false">
    <h2 class="object-url-content">
      <i class="list-item-icon object-icon {{objectView.icon}}"
         [class.text-blue]="objectView.meta_type === MetaTypes.ADM_EVENT"></i>
      <span class="bold list-overview-fields__headline-text text-link">{{objectView.artifact_name}}</span>
    </h2>
  </app-object-url>

  <div id="fieldContent"
       class="row list-overview-fields__content content-selector-source__content">
    <div *ngFor="let overviewFields of columns"
         [ngClass]="{'small-12 medium-12 columns': columns.length === 1,
                        'small-6 columns': columns.length === 2,
                        'small-4 columns': columns.length === 3}">
      <div *ngFor="let field of overviewFields" class="row {{field.container_class}}">

        <div class="list-overview-fields__field">
          <div class="list-overview-fields__field-title">
            <span>{{field.title}}:</span>
          </div>

          <div class="list-overview-fields__field-content">
            <app-overview-field
              [overviewField]="field"
              [toneDownTextColor]="true"
            ></app-overview-field>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
