<div class="procedure-content-view-selector">
  <button (click)="selectView('steps')"
          mat-stroked-button
          color="primary"
          type="button"
          [ngClass]="{'active': currentProcedureContentViewName === 'steps'}"
  ><i class="icon-procedure2"></i></button>
  <button (click)="selectView('objects')"
          mat-stroked-button
          color="primary"
          type="button"
          [ngClass]="{'active': currentProcedureContentViewName === 'objects'}"
  ><i class="icon-list-view"></i></button>
</div>
