@for (select of arrayObject[arrayName]; track select) {
  @if (!crud.getDestroy(select)) {
    <li class="edit-field-selected-options">
      @if (select[valueProp]) {
        <span [innerHtml]="select[valueProp]"></span>
      }
      <a (click)="unCheckOption(select)">
        <mat-icon>close</mat-icon>
      </a>
      <div class="button-overlay"></div>
    </li>
  }
}
