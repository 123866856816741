import {Component, Input, OnInit} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {AConst} from '../../../core/a-const.enum';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-menu-sort',
  templateUrl: './search-menu-sort.component.html',
  styleUrls: ['./search-menu-sort.component.scss']
})
export class SearchMenuSortComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() viewName: String;

  AConst = AConst;
  constructor(private searchHandler: SearchHandlerService) {
  }

  ngOnInit() {
    if (!this.viewName) {
      this.viewName = 'search';
    }
  }



  changeSortOrder(s) {
    this.searchHandler.setOrder(this.searchContainer, s[AConst.ORDER]);
  }

}
