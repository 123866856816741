import {Component, Input} from '@angular/core';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-filter-hierarchic',
  templateUrl: './search-filter-hierarchic.component.html',
  styleUrls: ['./search-filter-hierarchic.component.scss']
})
export class SearchFilterHierarchicComponent {
  @Input() sideMenu;
  @Input() searchContainer: SearchContainer;

  query = '';

}
