import {Component, EventEmitter, OnInit, Output, signal, WritableSignal} from '@angular/core';
import {CmsApiService} from "../core/cms-api.service";

@Component({
  selector: 'app-faq-sidebar',
  templateUrl: './faq-sidebar.component.html',
  styleUrl: './faq-sidebar.component.scss'
})
export class FaqSidebarComponent implements OnInit{
  @Output() closeFaqSidebar = new EventEmitter();

  faqData: WritableSignal<any> = signal([]);

  constructor(
    private cms: CmsApiService
  ) {}

  ngOnInit() {
    this.cms.getFaq().then((result) => {
      // @ts-ignore
      this.faqData.set(result.categories);
    })
  }
}
