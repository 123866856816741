import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FieldService} from '../services/field.service';
import {MatSelectionList} from '@angular/material/list';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';
import {CmsApiService} from '../../../core/cms-api.service';
import {MatSelectionListChange} from '@angular/material/list';
import {SearchableField} from '../../../core/definitions/advanced-search/searchable-field';
import {FieldGroup} from '../../../core/definitions/advanced-search/field-group';
import {SearchCategory} from '../../../core/definitions/advanced-search/search-category';

@Component({
  selector: 'app-search-category',
  templateUrl: './search-category.component.html',
  styleUrls: [
    './search-category.component.scss',
    './search-category.component.tablet.scss',
    './search-category.component.mobile.scss',
  ]
})
export class SearchCategoryComponent implements OnInit {
  /**
   * Reference to the list-instance.
   * Used to clear selection on close
   * @type {MatSelectionList}
   * @private
   */
  @ViewChild(MatSelectionList) private readonly list: MatSelectionList;

  /**
   * Modify this number to limit the number of selected items shown in the GUI
   * @type {number}
   */
  readonly NUMBER_OF_SELECTED_ITEMS_TO_SHOW: number = 3;

  /**
   * Form-control controlling the name of the current category
   * @type {FormControl}
   */
  readonly categoryNameControl: UntypedFormControl;

  /**
   * A list of all fields available.
   * @type {Array<SearchableField>}
   */
  allFields: Array<SearchableField>;

  /**
   * The ID of all fields currently selected
   * @type {Array<SearchCategoryField>}
   */
  selectedFieldIds: Array<string>;

  /**
   * The names of existing search-categories for this user.
   * Used to validate when creating new search-categories.
   * @type {Array<string>}
   * @private
   */
  private existingCategoryNames: Array<string>;

  /**
   * A list of all fields currently available after filters are applied.
   * @type {Array<SearchableField>}
   */
  filteredFields: Array<SearchableField>;

  /**
   * All filtered fields grouped by the fieldnames first letter
   * @type {Array<FieldGroup>}
   */
  fieldGroups: Array<FieldGroup>;

  /**
   * The category currently being created/edited
   * @type {SearchCategory}
   */
  currentSearchCategory: SearchCategory;

  /**
   *
   * @type {Array<string>}
   */
  selectedObjectTypeIds: Array<string>;

  loading: boolean;


  constructor(private readonly bottomSheetRef: MatBottomSheetRef<SearchCategoryComponent>,
              private readonly fieldService: FieldService,
              private readonly api: CmsApiService,
              @Inject(MAT_BOTTOM_SHEET_DATA) private readonly data: SearchCategory) {
    this.allFields = [];
    this.filteredFields = [];
    this.fieldGroups = [];
    this.existingCategoryNames = [];
    this.currentSearchCategory = {
      category: {
        name: null,
        selectedFields: []
      },
      ...this.data,
    };
    this.selectedObjectTypeIds = [];
    this.selectedFieldIds = this.currentSearchCategory.category.selectedFields.map(f => f.fieldId);

    this.categoryNameControl = new UntypedFormControl(this.currentSearchCategory.category.name, [
      Validators.required,
      Validators.maxLength(200),
      (control: AbstractControl) => {
        return control.value && this.existingCategoryNames.includes(control.value) ? {exists: true} : null;
      }
    ]);

    this.loading = false;
  }

  ngOnInit(): void {
    this.loading = true;
    // Load fields
    this.fieldService.getFields().then(fields => {
      this.allFields = fields || [];
      this.setFilteredFields(fields);
      this.loading = false;
    }).catch(() => this.loading = false);
    // Load existing categories
    this.api.getStoredSearchCategoriesForUser().then((s: Array<SearchCategory> = []) => {
      this.existingCategoryNames = s.filter(c => c.categoryId !== this.currentSearchCategory.categoryId)
        .map(c => c.category.name);
    });
  }

  close(): void {
    this.list.deselectAll();
    this.selectedFieldIds = [];
    this.bottomSheetRef.dismiss(this.currentSearchCategory);
  }

  async saveCurrentCategory(): Promise<void> {
    this.currentSearchCategory.category = {
      name: this.categoryNameControl.value,
      selectedFields: this.getSelectedFields().map(f => ({
        fieldId: f.fieldId,
        fieldName:  f.title.name,
        indexField: f.indexName
      })),
    };
    await this.api.createOrUpdateSearchCategory(this.currentSearchCategory);
    this.close();
  }

  handleSelectionChanged(event: MatSelectionListChange): void {
    event.options.forEach(option => {
      const id = (option.value as SearchableField).fieldId;
      if (option.selected && !this.selectedFieldIds.includes(id)) {
        this.selectedFieldIds.push(id);
      } else if (!option.selected && this.selectedFieldIds.includes(id)) {
        this.selectedFieldIds.splice(this.selectedFieldIds.indexOf(id), 1);
      }
    });
  }

  removeSelectedField(fieldId: string): void {
    const idx = this.selectedFieldIds.indexOf(fieldId);
    if (idx >= 0) {
      this.selectedFieldIds.splice(idx, 1);
    }
  }

  isSelected(fieldId: string): boolean {
    return this.selectedFieldIds.includes(fieldId);
  }

  // TODO: Calling this method from the template causes potential performance issues. Should be reworked so
  //  that the 'model-names' list is generated once only.
  getModelNames(field: SearchableField): string {
    return field.appearsOn.map(a => a.name).join(', ');
  }

  getSelectionCount(): number {
    return this.selectedFieldIds?.length || 0;
  }

  getSelectedFields(): Array<SearchableField> {
    return this.allFields.filter(f => this.selectedFieldIds.includes(f.fieldId));
  }

  setFilteredFields(fields: Array<SearchableField>): void {
    this.filteredFields = fields;
    this.fieldGroups = FieldService.groupFields(fields);
  }
}
