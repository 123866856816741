<ng-container *ngFor="let breadcrumb of searchContainer.breadcrumbs; index as i">
  <div *ngIf="finishedLoading && i < searchContainer.breadcrumbs.length - 1"
       class="search-hierarchic-bread-crumbs__items">
    <i *ngIf="i === 0" class="icon-folder-open text-blue"></i>
    <a mat-button
       [routerLink]="breadcrumb.$$refData?.routerLink"
       [queryParams]="breadcrumb.$$refData?.queryParams"
       [title]="breadcrumb.artifact_name"
       class="search-result-view-thumbnail__thumbnail-url text-blue"
       [ngClass]="{'hide-items': showMoreButton &&
       (i !== 0 && i !== searchContainer.breadcrumbs.length - 2) }">{{breadcrumb.artifact_name}}</a>

    <button *ngIf="showMoreButton && i === 1"
            (click)="showAllItems()"
            mat-button>...</button>

    <mat-icon [ngClass]="{'hide-items': showMoreButton &&
    (i !== 0 && i !== 1 && i !== searchContainer.breadcrumbs.length - 2)}">navigate_next</mat-icon>
  </div>

  <div *ngIf="finishedLoading && i === searchContainer.breadcrumbs.length - 1 && i !== 0"
       [ngStyle]="{'max-width': !showMoreButton ?  ((100 / searchContainer.breadcrumbs.length + 2) + '%'): '150px'}"
       class="search-hierarchic-bread-crumbs__last-item">
    <span>{{breadcrumb.artifact_name}}</span></div>

  <a *ngIf="finishedLoading && i === searchContainer.breadcrumbs.length - 1 && i === 0"
     mat-button
     (click)="goToMenuPath()"
     class="search-hierarchic-bread-crumbs__back-to-folders text-blue">
    <mat-icon>navigate_before</mat-icon>
    <span>{{'TRANS__SEARCH_HIERARCHIC_BREAD_CRUMBS__BACK_TO_FOLDERS' | translate}}</span>
    </a>

</ng-container>
