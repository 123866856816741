import {UserCollectionItemsBase} from './user-collection-items-base';

export class UserVirtualCollectionItems implements UserCollectionItemsBase {
  object_type = 'UserVirtualCollectionItems';
  create: boolean;
  destroy: boolean;
  collection_id: string;
  collection_id_value: string;
  collection_rights_id: string;
  collection_rights_id_value: string;
  context_id: string;
}
