import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ImportFieldMapItem} from "../import.service";
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'app-column-select',
  templateUrl: './column-select.component.html',
  styleUrls: ['./column-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColumnSelectComponent)
    }
  ]
})
export class ColumnSelectComponent implements OnInit, ControlValueAccessor {
  @Input() mapField: ImportFieldMapItem;
  @Input() formGroup: FormGroup;
  formControl: FormControl;

  ngOnInit() {
    this.formControl = <FormControl<any>>this.formGroup.controls[this.mapField.target_field.field_name];
  }

  displayFn(column: string): string {
    return column || '';
  }

  selectColumn(event: MatAutocompleteSelectedEvent) {
    this.mapField.import_file_field_name = event.option.value;
  }

  registerOnChange(/*fn: any*/): void {
    // N/A
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }
}
