import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';

@Component({
  selector: 'app-object-edit',
  templateUrl: './object-edit.component.html',
  styleUrls: ['./object-edit.component.scss']
})
export class ObjectEditComponent {

  @Input() sectionsContainer: SectionsContainer;
  @Input() sectionsToShow: string[];
  @Output() closeEdit = new EventEmitter<any>();

  onCloseEdit() {
    this.closeEdit.emit();
  }

}
