<a class="main-menu-component__user-content" tabindex="0" (click)="goToCurrentUser()">
  <div class="main-menu-component__user-image">
    <div class="main-menu-component__user-image-content">
      <app-thumbnail *ngIf="userImage" [item]="userImage" [imageIdField]="'image_id'"></app-thumbnail>
      <i class="icon-user"></i>
    </div>
  </div>
  <div class="main-menu-component__user-name">
    <div *ngIf="user && user.name">{{user.name}}</div>
    <div *ngIf="user && !user.name">{{user.username}}</div>
  </div>
</a>
<div class="main-menu-component__user-log-out">
  <a (click)="logOut()" tabindex="0" title="Log out"><mat-icon class="log-out-icon">logout</mat-icon></a>
</div>