<mat-list class="search-menu-view-selector common-search-menu">

  <ng-container *ngFor="let view of searchContainer.searchResultViews.views">
    <button mat-menu-item
            (click)="setCurrentView(view)"
            [ngClass]="{'active':searchContainer.searchResultViewName === view.name}">
      <mat-icon>{{view.icon}}</mat-icon>
      <span [translate]="view.description"></span>
    </button>
    </ng-container>

</mat-list>
