import {SuperobjectCollection} from './superobject-collection';
import {Status} from './status';
import {ImageItem} from './image-item';
import {BaseModel} from './base-model';
import {Context} from './context';
import {SuperobjectName} from './superobject-name';
import {RectoVerso, VersoRecto} from './recto-verso';
import {ObjectMediaContainer} from './object-media-container';
import {SuperobjectFolderItem} from './super-object-folder-item';
import {ObjectUsage} from './object-usage';
import {SuperobjectLogIdentificationNumberItem} from "./superobject-log-identification-number-item";
import {SuperobjectLogCollectionItem} from "./superobject-log-collection-item";
import {SuperobjectLogCollectionStatusItem} from "./superobject-log-collection-status-item";
import {SuperobjectQuantityLogItem} from "./superobject-quantity-log-item";

export class SuperObjectModel extends BaseModel {
  $$mediaContainer: ObjectMediaContainer;
  read_only: boolean;
  $$mediaPlaybackUrls: any[];
  $$uploadProgress: string;
  $$uploadTimeoutId;
  $$uploadWaitCancel = false;
  object_usage: ObjectUsage[];

  adm_events: BaseModel[];
  superobject_type_id: string;
  superobject_type_id_value: string;
  artifacts: Array<SuperObjectModel>; // Used in spectrum procedures
  collection: SuperobjectCollection;
  collection_log: SuperobjectLogCollectionItem[];
  collection_status_log: SuperobjectLogCollectionStatusItem[];
  context: Context; // Used by admin events
  contexts: Array<Context>;
  description: any = {};
  folders: SuperobjectFolderItem[];
  history_events: any[];
  identifier: string;
  identification_number: any;
  identification_number_log: SuperobjectLogIdentificationNumberItem[];
  images: Array<ImageItem>;
  model_type_id: string;
  name: SuperobjectName;
  parent_id: string; // Used by admin events
  parent_id_value: string;
  quantity_log: SuperobjectQuantityLogItem[];
  recto_verso: RectoVerso;
  status: Status;
  super_artifact_id: string;
  template_group_id: string;
  verso_recto: VersoRecto;

  create_multiple: boolean;
  create_multiple_count: number;

  operation_contexts: any[];
  operation_contexts_restricted: any[];

}
