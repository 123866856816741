import {Component, EventEmitter, HostListener, Input, OnChanges, Output} from '@angular/core';
import {FieldStateService} from '../../core/field-state.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {UiToolsService} from '../../core/ui-tools.service';
import {MetaField} from '../../core/definitions/meta-field';
import {BaseModel} from '../../core/definitions/base-model';
import {FieldInputType} from '../../core/definitions/field-input-type.enum';

@Component({
  selector: 'app-edit-field-switch',
  templateUrl: './edit-field-switch.component.html',
  styleUrls: ['./edit-field-switch.component.scss']
})
export class EditFieldSwitchComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() displayRequiredIndication: boolean;
  @Input() field: MetaField;
  @Input() grandParentObject: BaseModel;
  @Input() object: BaseModel;
  @Input() index: number;
  @Input() parentIndex: number;
  @Output() closeEdit = new EventEmitter<any>();

  myObject: BaseModel | BaseModel[];
  computer: boolean;
  FieldInputType = FieldInputType;

  constructor(private fieldStateSvc: FieldStateService,
              private uiTools: UiToolsService) {
  }

  @HostListener('keyup', ['$event'])
  onEvent(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Tab') {
      this.checkIfFieldOutsideTheScreen();
    }
  }

  ngOnChanges() {
    this.computer = this.uiTools.getClientType().isComputer;
    if (!this.object) {
      return;
    }
    this.myObject = this.object;
    if (!this.field.path) {
      return;
    }
    const fieldValue = this.object[this.field.name];
    if (fieldValue === undefined || typeof fieldValue === 'object') {
      if (this.index === undefined && this.field.key.indexOf('{index1}') !== -1) {
        // This will make sure array based prime fields work
        this.index = 0;
      }
      const parentSub = this.fieldStateSvc.getParentSubObject(
        this.sectionsContainer.rootObject, this.field, this.index, this.parentIndex);
      this.myObject = parentSub.subObject;
      if (this.myObject instanceof BaseModel && parentSub.parentObject instanceof  BaseModel &&
        this.myObject.object_type !== parentSub.parentObject.object_type) {
        this.grandParentObject = parentSub.parentObject;
      }
    }
  }

  checkIfFieldOutsideTheScreen() {
    setTimeout(() => {
      if (this.computer && document.activeElement) {
        const activeElement = document.activeElement.getBoundingClientRect();
        const windowTop = (window.innerHeight - 50) + window.pageYOffset;
        if (activeElement) {
          const positionTop = activeElement.top + 220 + window.pageYOffset;
          if (positionTop > windowTop) {
            this.scrollWindowToField(window.pageYOffset);
          }
        }
      }
    }, 300);
  }

  scrollWindowToField(offset: number) {
    const scrollTo = offset + 250;
    window.scrollTo({top: scrollTo, behavior: 'smooth'});
  }

  onCloseEdit() {
    this.closeEdit.emit();
  }

}
