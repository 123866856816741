<div class="dialog user-collections-adder">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span *ngIf="collectionType === 'collections'"
            translate="TRANS__USER_COLLECTIONS_ADDER__TITLE"></span>
      <span *ngIf="collectionType === 'virtual_collections'"
            translate="TRANS__USER_COLLECTIONS_ADDER__VIRTUAL_COLLECTION_TITLE"></span>
    </h1>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <mat-dialog-content class="modal-body modal-body-edit-fields">
    <div class="modal-main-content">
      <mat-form-field appearance="outline" class="user-collections-adder__select-collections">
        <mat-label [translate]="collectionType === 'collections' ? 'TRANS__USER_COLLECTIONS_ADDER__SELECT_COLLECTIONS' :
        'TRANS__USER_COLLECTIONS_ADDER__SELECT_VIRTUAL_COLLECTIONS'"></mat-label>
        <mat-select [formControl]="selectedCollections" multiple (selectionChange)="toggleUserCollections($event)">
          <mat-option *ngFor="let collection of allCollections" [value]="collection.artifact_id">{{collection.artifact_name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <h3 *ngIf="collectionType === 'collections'" translate="TRANS__USER_COLLECTIONS_ADDER__SELECTED_COLLECTIONS"></h3>
      <h3 *ngIf="collectionType === 'virtual_collections'"
          translate="TRANS__USER_COLLECTIONS_ADDER__SELECTED_VIRTUAL_COLLECTIONS"></h3>

      <mat-slide-toggle
        *ngIf="collectionItemsList.length"
        [formControl]="enableCommonRights"
        class="user-collections-adder__enable-common-rights">
        {{'TRANS__USER_COLLECTIONS_ADDER__ENABLE_COMMON_RIGHTS' | translate}}</mat-slide-toggle>

      <table class="user-collections-adder__table">
        <tr>
          <th [translate]="collectionType === 'virtual_collections' ?
          'TRANS__USER_COLLECTIONS_ADDER__TABLE_HEADER_VISUAL_COLLECTION' :
          'TRANS__USER_COLLECTIONS_ADDER__TABLE_HEADER_COLLECTION'"></th>
          <th [translate]="collectionType === 'collections' ? 'TRANS__USER_COLLECTIONS__IS_MAIN_COLLECTION' : ' '"></th>
          <th translate="TRANS__USER_COLLECTIONS_ADDER__TABLE_HEADER_COLLECTION_RIGHTS"></th>
        </tr>
        <tr *ngIf="enableCommonRights.value" class="user-collections-adder__common-rights-setter">
          <td></td>
          <td translate="TRANS__USER_COLLECTIONS_ADDER__COMMON_RIGHTS"></td>
          <td>
            <app-user-collection-rights-type-setter
              [userCollection]="commonRightsCollection"
              [collectionType]="collectionType"
              (rightsSet)="onCommonRightsSet($event)"
            ></app-user-collection-rights-type-setter>
          </td>
        </tr>
        <ng-container *ngFor="let userCollection of collectionItemsList">
          <tr *ngIf="!crud.getDestroy(userCollection)" class="user-collections-adder__rights-items">
            <td>
              <button type="button" mat-stroked-button
                      [title]="userCollection.collection_id_value"
                      (click)="removeUserCollection(userCollection, true)">
                <span class="user-collections-adder__rights-items-button-text">{{userCollection.collection_id_value}}</span>
                <mat-icon>close</mat-icon>
              </button>
            </td>
            <td>
              <mat-radio-group *ngIf="collectionType === 'collections'"
                               [(ngModel)]="userCollection['is_main_collection']"
                               (ngModelChange)="onChangedMainCollection(userCollection)">
                <mat-radio-button [value]="true" [disabled]="enableCommonRights.value"></mat-radio-button>
              </mat-radio-group>
            </td>
            <td>
              <app-user-collection-rights-type-setter
                [userCollection]="userCollection"
                [disabled]="enableCommonRights.value"
                [collectionType]="collectionType"
              ></app-user-collection-rights-type-setter></td>
          </tr>
        </ng-container>
      </table>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions class="modal-footer">
    <button mat-flat-button type="button" color="accent"  class="bold" (click)="closeDialog(true)">
      <span translate="TRANS__USER_COLLECTIONS_ADDER__SAVE"></span></button>
    <button mat-button
            class="bold"
            color="accent"
            (click)="closeDialog(false)">
      <span translate="TRANS__USER_COLLECTIONS_ADDER__CANCEL"></span></button>
  </div>
</div>

