import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ImportField, ImportService} from "../import.service";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-import-field-select',
  templateUrl: './import-field-select.component.html',
  styleUrls: ['./import-field-select.component.scss']
})
export class ImportFieldSelectComponent implements OnInit {
  @Output() importFieldSelected = new EventEmitter<ImportField>();

  selectFieldControl = new FormControl<string | ImportField>('')
  filteredFields: ImportField[];

  constructor(private importService: ImportService) {
  }

  ngOnInit() {
    this.importService.getFields().then(fields => {
      this.filteredFields = fields;
      this.selectFieldControl.valueChanges.subscribe(newValue => {
        this.filterFields(newValue).then(fields => {
          this.filteredFields = fields;
        })
      })
    })
  }

  selectField(event: MatAutocompleteSelectedEvent) {
    this.importFieldSelected.emit(event.option.value);
  }

  displayFn(field: ImportField): string {
    return field ? field.field_title : '';
  }

  private async filterFields(newValue: string | ImportField) {
    let fieldTitle = typeof newValue === 'string' ? newValue : newValue.field_title;
    if (fieldTitle) {
      const fields = await this.importService.getFields()
      return fields.filter(
        field => field.field_title.toLowerCase().indexOf(fieldTitle.toLowerCase()) === 0);
    } else {
      return this.importService.getFields();
    }
  }

}
