import {Component, Input, OnInit} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {UploadService} from '../../core/upload.service';
import {MetaField} from "../../core/definitions/meta-field";

@Component({
  selector: 'app-edit-field-image-selector',
  templateUrl: './edit-field-image-selector.component.html',
  styleUrls: ['./edit-field-image-selector.component.scss']
})
export class EditFieldImageSelectorComponent implements OnInit {
  @Input() field: MetaField;
  @Input() object: any;

  computer = true;

  constructor(private uiTools: UiToolsService, private uploadService: UploadService) {
  }

  ngOnInit() {
    this.computer = this.uiTools.getClientType().isComputer;
  }

  deleteImage() {
    this.object[this.field.name].value = null;
  }

  // At the drag drop area
  // (drop)="onDropFile($event)"
  onDropFile(event: any) {
    event.preventDefault();
    this.uploadFiles(event.dataTransfer.files);
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  // At the file input element
  // (change)="selectFile($event)"
  onFileSelect(event: any) {
    this.uploadFiles(event.target.files);
  }

  private uploadFiles(files: any) {
    if (files.length > 0) {
      this.object[this.field.name].value = null;
      this.uploadService.uploadFiles(files, 'image', this.object).then(res => {
        if (res && res[0]) {
          this.object[this.field.name].value = res[0].artifact_id;
          this.object[this.field.name].thumbnail_id = res[0].artifact_id;
          this.object[this.field.name].$$uploading = true;
        } else {
          console.warn('Illegal result received from upload: ' + res);
        }
      });
    }
  }
}
