<div class="edit-field-inline-array-item-top"><!--list-inline-array-item-content-->

  <a class="edit-field-inline-array-item-top__header"
     (click)="toggleOpenItem()"
     tabindex="0"
     (keyup.enter)="toggleOpenItemWithKey()">
    @if (container && container.values) {
      <div class="inline-array-header">
        @for (label of container.labels; track label) {
          <div class="inline-array-header__container"
               [ngClass]="{'textLabel': label.type === 'text' || label.name === 'url'}">
            @if (label.name !== crud.getDestroyFieldName()) {
              <app-inline-array-header-item
                [label]="label"
                [container]="container"
              ></app-inline-array-header-item>
            }
          </div>
        }

      </div>
    }

  </a>
</div>


