<li class="object-content-main-tab"
    role="presentation"
    *ngIf="!invisible"
    [attr.aria-hidden]="invisible ? 'true' : 'false'"
    [ngClass]="{'isEditing': isEditing}">
  <a (click)="openMenu()"
     class="object-content-main-tab-button"
     [ngClass]="{
   'disabled': isEditing,
   'activeLeafMenu': menu.open && menu.leaf_menu,
   'activeMenuWithSubMenus': menu.open && menu.sub_menus}">
    <span class="top-line bg-primary"></span>
    <span class="object-content-tab-text"
          [class.text-primary]="menu.open && menu.leaf_menu ||  menu.open && menu.sub_menus">
      <span class="object-content-tab-caption bold"
            [translate]="menu.caption"></span>
      <span *ngIf="menu.count" class="object-content-tab-count">
        <span class="text-primary">{{menu.count}}</span>
      </span>
    </span>
    <mat-icon *ngIf="menu.open" [class.text-primary]="menu.open && menu.leaf_menu ||  menu.open && menu.sub_menus">expand_more</mat-icon>
  </a>
</li>
