<div class="edit-field-inline-array-item-list">
  <div class="edit-field-inline-array-item-list__container"
       [ngClass]="{'no-field-title': !fieldParameters.field.title}"
       cdkDropList
       (cdkDropListDropped)="drop($event)">
    @if (isInvalid) {
      <div class="error-warn-bg-color error-warn-border-color error-warn-color
      edit-field-inline-array-item-list__error">
        <mat-icon color="warn">report_problem</mat-icon>
        <span>
          <span translate="TRANS__ARRAY_FIELD__ERROR__REQUIRED"></span>&nbsp;
          <span class="lowercase" [translate]="field.title || field.admin_title"></span>.
        </span>
      </div>
    }
    @if (items.length) {
      <ul>
        @for (item of items ; track item; let i = $index) {
          @if (!crud.getDestroy(item)) {
            <li class="inline-array-item"
                [ngClass]="{'dialog': fieldParameters.sectionsContainer.isDialog}"
                cdkDrag cdkDragBoundary="edit-field-inline-array-item-list__container"
                (cdkDragStarted)="onDragStart()">
              <div class="inline-array-item-placeholder" *cdkDragPlaceholder></div>
              <app-edit-field-inline-array-item
                [fieldParametersIn]="fieldParameters"
                [item]="item"
                [arrayIndex]="i"
                (fieldFocus)="checkFieldOutsideTheScreen()"
              ></app-edit-field-inline-array-item>
              @if (!item.$$disabled) {
                <div class="edit-field-inline-array-item-list__buttons"
                     [ngClass]="{'is-copy': fieldParameters.sectionsContainer.isCopy}">
                  @if ((!field.inline.inline_list || !field.inline.inline_list.fixed) && fieldParameters.canDelete) {
                    <button mat-button class="edit-field-inline-array-item-list__setting-button text-blue-hover"
                            type="button"
                            [matMenuTriggerRestoreFocus]="false"
                            (menuOpened)="item.$$activeDropdown = true;"
                            (menuClosed)="item.$$activeDropdown = false;"
                            [matMenuTriggerFor]="arrayItemMenu">
                      <mat-icon>settings</mat-icon>
                    </button>
                  }
                  <button mat-button type="button"
                          class="edit-field-inline-array-item-list__move-button text-blue-hover">
                    <i class="icon-row-sort" cdkDragHandle></i></button></div>
              }

              <mat-menu #arrayItemMenu="matMenu"
                        class="edit-field-inline-array-item-list__setting-menu">
                @if (fieldParameters.canDelete && (item.$$deletable || item.$$justAdded)) {
                  <app-edit-field-inline-array-delete-button
                    [fieldParameters]="fieldParameters"
                    [arrayIndex]="i"
                  ></app-edit-field-inline-array-delete-button>
                }
              </mat-menu>
            </li>
          }
        }

      </ul>
    }
  </div>

</div>
