import {SuperObjectModel} from './super-object-model';

export class GetOperationsParams {
  operation_target: string;
  object_types: string[] = [];
  meta_types: string[] = [];
  parent_object_type: string;
  parent_meta_type: string;
  operation_contexts: SuperObjectModel[] = [];
  search_result_view_type: string;
  search_view_name: string;
  status_type: string;
}
