import {Component, Input, OnInit} from '@angular/core';
import {SuperObjectModel} from "../../../core/definitions/super-object-model";
import {DateToolsService} from "../../../core/date-tools.service";

@Component({
  selector: 'app-quantity-log',
  templateUrl: './quantity-log.component.html',
  styleUrls: ['./quantity-log.component.scss']
})
export class QuantityLogComponent implements OnInit {
  @Input() object: SuperObjectModel;

  constructor(private dateTools: DateToolsService) {
  }

  logItems: any[] = [];
  displayedColumns = ['updated_at', 'updated_by', 'to_quantity', 'from_quantity'];

  ngOnInit(): void {
    const logData = this.object.quantity_log || [];
    this.logItems = logData.map(item => {
      return {
        updated_at: this.dateTools.isoDateToString(item.updated_at),
        updated_by: item.updated_by_id_value,
        to_quantity: item.to_quantity,
        from_quantity: item.from_quantity
      }
    });
  }
}
