import {DataSource} from '@angular/cdk/table';
import {SearchObject} from '../../core/definitions/search-object';
import {CollectionViewer, SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {debounceTime, map, mergeMap, tap} from 'rxjs/operators';
import {SearchService} from "../../core/search.service";

/**
 * A datasource that uses Solr as a datasource.
 * Provides caching and dynamic paging and sorting
 */
export default class SolrDataSource extends DataSource<SearchObject> {
  private readonly defaultIdProperty: string = 'artifact_id';
  private readonly defaultSortProperty: string = 'artifact_name_upper';

  private pageChangeSubscription: Subscription;
  private sortChangeSubscription: Subscription;
  private selectionChangeSubscription: Subscription;
  private sortRef: MatSort;
  private paginatorRef: MatPaginator;
  private selectionState: SelectionModel<string>;

  private totalSearchCount = 0;

  private _filterString = '';

  private readonly searchParams: SearchParameters = {
    query: '*:*',
    fq: [''],
    sort: `${this.defaultSortProperty} asc`,
    fl: ['artifact_name'],
    rows: 100,
    start: 0
  } as SearchParameters;

  private readonly dataSubject: BehaviorSubject<SearchParameters> = new BehaviorSubject<SearchParameters>(this.searchParams);
  private readonly loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly selectionSubject: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);

  public readonly loading$: Observable<boolean> = this.loadingSubject.asObservable();
  public readonly selectionChange$: Observable<Array<string>> = this.selectionSubject.asObservable();

  /**
   * @param searchService The api-service. Used to fetch the data
   */
  constructor(private readonly searchService: SearchService) {
    super();
  }

  private getSelectedRowIds(): Array<string> {
    return this.selectionState?.selected || [];
  }

  public refreshData(resetPage: boolean = false): void {
    if (resetPage && this.paginatorRef) {
      this.paginatorRef.firstPage();
    }
    this.dataSubject.next(this.searchParams);
  }

  public clearSelected() {
    this.selectionState.clear();
  }

  protected getQuery(): string {
    let query = this.searchParams.query || '';
    if (this._filterString) {
      if (query) {
        query = `*${this._filterString}* AND (${query})`;
      } else {
        query = `*${this._filterString}*`;
      }
    } else {
      query = '*:*';
    }
    const selectedIds = this.getSelectedRowIds();
    if (!!selectedIds?.length) {
      query = `${this.defaultIdProperty}:("${selectedIds.join('","')}") OR (${query})`;
    }
    return query;
  }

  public connect(collectionViewer: CollectionViewer): Observable<SearchObject[] | ReadonlyArray<SearchObject>> {
    return this.dataSubject.asObservable().pipe(
      debounceTime(200),
      tap(() => this.loadingSubject.next(true)),
      map(sp => {
        sp.query = this.getQuery();
        sp.rows = this.paginatorRef ? this.paginatorRef.pageSize : 100;
        sp.start = this.paginatorRef ? this.paginatorRef.pageIndex * sp.rows : 0;
        return sp;
      }),
      mergeMap(async sp => {
        try {
          const res = await this.searchService.search(sp);
          this.totalSearchCount = res?.search_count ?? res?.artifacts?.length ?? 0;
          if (this.paginatorRef) {
            this.paginatorRef.length = this.totalSearchCount;
          }
          return res?.artifacts || [];
        } catch (err) {
          console.error('[SolrDataSource] Error occurred while fetching data:', this.searchParams, err);
          return [];
        }
      }),
      tap(() => this.loadingSubject.next(false)),
    );
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.selectionSubject.complete();
    if (this.sortChangeSubscription && !this.sortChangeSubscription.closed) {
      this.sortChangeSubscription.unsubscribe();
    }
    if (this.pageChangeSubscription && !this.pageChangeSubscription.closed) {
      this.pageChangeSubscription.unsubscribe();
    }
    if (this.selectionChangeSubscription && !this.selectionChangeSubscription.closed) {
      this.selectionChangeSubscription.unsubscribe();
    }
  }

  public set query(query: string) {
    query = query || '*:*';
    if (this.searchParams.query !== query) {
      this.searchParams.query = query;
      this.refreshData(true);
    }
  }

  public set fieldList(fieldList: Array<string>) {
    if (!fieldList?.length) {
      console.warn('[SolrDataSource] Set empty fieldList. This will result in an empty dataset');
      fieldList = [this.defaultIdProperty];
    } else if (!fieldList.includes(this.defaultIdProperty)) {
      fieldList = [this.defaultIdProperty, ...fieldList];
    }
    const isFieldsAdded = fieldList.some(col => !this.searchParams.fl.includes(col));
    this.searchParams.fl = fieldList;
    if (isFieldsAdded) {
      this.refreshData();
    }
  }

  public set filterQuery(filterQuery: string) {
    filterQuery = filterQuery || '';
    if (!filterQuery) {
      console.warn('[SolrDataSource] set empty filterQuery. This will result in an empty dataset');
    }
    if (this.searchParams.fq[0] !== filterQuery) {
      this.searchParams.fq[0] = filterQuery;
      this.refreshData(true);
    }
  }

  public set filterString(filterString: string) {
    const escaped = String(filterString ?? '').trim()
      .replace(/([+\-&|!(){}\[\]^"~*?:\/])/g, '\\$1');
    if (this._filterString !== escaped) {
      this._filterString = escaped;
      this.refreshData(true);
    }
  }

  public set paginator(paginator: MatPaginator) {
    if (this.paginatorRef === paginator) {
      return;
    }
    paginator.length = this.totalSearchCount;

    if (this.pageChangeSubscription && !this.pageChangeSubscription.closed) {
      this.pageChangeSubscription.unsubscribe();
    }
    this.paginatorRef = paginator;
    this.pageChangeSubscription = paginator.page.subscribe(() => {
      this.refreshData();
    });
  }

  public set sort(sort: MatSort) {
    if (this.sortRef === sort) {
      return;
    }
    if (this.sortChangeSubscription && !this.sortChangeSubscription.closed) {
      this.sortChangeSubscription.unsubscribe();
    }
    this.sortRef = sort;
    this.sortChangeSubscription = sort.sortChange.subscribe((event: Sort) => {
      const sortProp = event.direction ? event.active : this.defaultSortProperty;
      const sortDirection = event.direction || 'asc';
      this.searchParams.sort = `${sortProp} ${sortDirection}`;
      this.refreshData();
    });
  }

  public setSelectable(selectable: boolean = true, selectMultiple: boolean = false): void {
    if (!selectable) {
      if (this.selectionChangeSubscription && !this.selectionChangeSubscription.closed) {
        this.selectionChangeSubscription.unsubscribe();
      }
      this.selectionState = null;
      return;
    }
    if (!this.selectionState || this.selectionState.isMultipleSelection() !== selectMultiple) {
      if (this.selectionChangeSubscription && !this.selectionChangeSubscription.closed) {
        this.selectionChangeSubscription.unsubscribe();
      }
      this.selectionState = new SelectionModel<string>(selectMultiple, this.getSelectedRowIds());
      this.selectionChangeSubscription = this.selectionState.changed.asObservable()
        .subscribe(() => this.selectionSubject.next(this.getSelectedRowIds()));
    }
  }

  public toggleSelection(row: SearchObject): void {
    if (!row || !row[this.defaultIdProperty]) {
      return;
    }
    this.selectionState?.toggle(row[this.defaultIdProperty]);
  }

  public isSelected(row: SearchObject): boolean {
    if (!row || !row[this.defaultIdProperty]) {
      return false;
    }
    return this.selectionState.isSelected(row[this.defaultIdProperty]);
  }

}
