import {Component, ViewEncapsulation} from '@angular/core';

/**
 * Provides the (OLD) global primus-styles.
 * Useful for reusing old components inside the new wrapper (shadow-dom)
 */
@Component({
  selector: 'app-m3-global-kpl-styles',
  standalone: true,
  imports: [],
  template: `<ng-content></ng-content>`,
  styleUrl: './m3-global-kpl-styles.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
  styles: [`
    :host {
      --mdc-outlined-button-container-shape: 4px;
    }
  `]
})
export class M3GlobalKplStylesComponent {
}
