import {
  ChangeDetectorRef,
  Component,
  Input, OnChanges, OnDestroy,
  ViewChild,
} from '@angular/core';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';
import {ConceptField} from '../../admin-concept-lists/concept-field';
import {ConceptService} from '../../../core/concept.service';
// @ts-ignore
import {TableColumn} from '../../../shared/primus-tables/primus-table/primus-table.component';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {Concept} from '../../../core/definitions/concepts';
import {CmsApiService} from "../../../core/cms-api.service";
import {MetaField} from "../../../core/definitions/meta-field";
import {FieldType} from "../../../core/definitions/field-type.enum";

@Component({
  selector: 'app-admin-list-table',
  templateUrl: './admin-list-table.component.html',
  styleUrls: ['./admin-list-table.component.scss'],
})
export class AdminListTableComponent implements OnChanges, OnDestroy {

  @Input() conceptsContainer: ConceptsContainer;
  @Input() reloadConcepts;
  private savePageIndex: number;

  /**
   * Sets up pagination when the MatPaginator-component is loaded
   * @param {MatPaginator} paginator
   */
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.conceptsContainer.conceptDataSource.paginator = paginator;
    paginator.pageIndex = this.conceptsContainer.pageIndex;
    this.setupSortChange();
  }

  /**
   * Sets up sorting when the MatSort-directive is loaded
   * @param {MatSort} sort
   */
  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.conceptsContainer.conceptDataSource.sort = sort;
    this.setupSortChange();
  }


  conceptFields: Array<ConceptField>;
  columns: Array<TableColumn<Concept>>;
  displayedCol: string[] = [];
  currentConceptTypeId = null;

  private sortSub: Subscription;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private readonly conceptService: ConceptService,
    private readonly cms: CmsApiService) {
  }

  ngOnChanges() {
    this.setReloadConcepts(true).then(() => {
      this.setConceptFields().then();
    });
  }

  public ngOnDestroy(): void {
    if (this.sortSub && !this.sortSub.closed) {
      this.sortSub.unsubscribe();
    }
  }

  get height() {
    return window.innerHeight - 460;
  }


  async setReloadConcepts(resetStart, goBack?: boolean) {
    await this.conceptService.setConceptList(this.conceptsContainer, resetStart, goBack, this.savePageIndex);
    this.conceptsContainer.conceptDataSource.paginator.length = this.conceptsContainer.concepts.hits;
  }

  async changePage() {
    this.conceptsContainer.pageIndex = this.conceptsContainer.conceptDataSource.paginator.pageIndex;
    await this.setReloadConcepts(false);
  }

  selectAll() {
    this.conceptService.selectAll(this.conceptsContainer);
  }

  async openConcept(concept: {conceptId: string, goBack: boolean, pageIndex: number | null}) {
    if(!concept.goBack) {
      this.savePageIndex = concept.pageIndex
    }
    if (!this.conceptsContainer.loadingConcepts && this.conceptsContainer.conceptType.is_hierarchic) {
      this.conceptsContainer.conceptsParams.parent_id = concept.conceptId;
      await this.setReloadConcepts(true, concept.goBack);
    }
  }

  private setupSortChange() {
    if (this.conceptsContainer.conceptDataSource.sort) {
      if (this.sortSub && !this.sortSub.closed) {
        this.sortSub.unsubscribe();
      }
      this.sortSub = this.conceptsContainer.conceptDataSource.sort.sortChange.subscribe(() => {
        this.conceptsContainer.pageIndex = 0;
        this.setReloadConcepts(true).then();
      });
    }
  }

  private async setConceptFields() {
    const modelOverviewFields = await this.cms.getModelOverviewFields({
      modelName: this.conceptsContainer.conceptType.concept_type_id})
    this.conceptFields = [];
    const fieldSelect = new ConceptField(
      'select', 'TRANS__EDIT_FIELD__CHOOSE', 'select', false);
    this.conceptFields.push(fieldSelect);
    const conceptName = this.conceptsContainer.conceptType.concept_name;
    if (conceptName) {
      const fieldConceptName = new ConceptField(
        'name', conceptName, 'name', true);
      this.conceptFields.push(fieldConceptName);
    }
    for (let field of modelOverviewFields) {
      this.conceptFields.push(this.metaFieldToConceptField(field));
    }
    const fieldAction = new ConceptField(
      'action', '', 'action', false);
    this.conceptFields.push(fieldAction);

    if (this.currentConceptTypeId !== this.conceptsContainer.conceptType.concept_type_id) {
      this.displayedCol = this.conceptFields.map(conceptField => conceptField.name);
      this.currentConceptTypeId = this.conceptsContainer.conceptType.concept_type_id;
    }
  }

  private metaFieldToConceptField(field: MetaField): ConceptField {
    let fieldName = field.name;
    if (field.field_type === FieldType.MAP_ID && fieldName.endsWith('_id')) {
      fieldName = `${fieldName}_value`;
    }
    return new ConceptField(fieldName, field.title, this.mapType(field), true);
  }

  private mapType(field: MetaField): string {
    let res = 'value';
    switch (field.field_type) {
      case FieldType.BOOLEAN:
        res = 'check';
        break;
      case FieldType.DATE_TIME_ISO:
      case FieldType.DATE_ISO:
        res = 'datetime';
        break;
    }
    if (field.name === 'name') {
      res = 'name';
    }
    return res;
  }
}
