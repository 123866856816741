<span *ngIf="fieldItem"
      [class]="viewName"
      [ngClass]="{'multiple-values': fieldItem.field_values.values.length > 1,
      'content-has-icon': fieldItem.field_values.field_icon}">
    <i *ngIf="fieldItem.field_values.field_icon"
       class="{{fieldItem.field_values.field_icon.icon}}">{{fieldItem.field_values.field_icon.icon_frame}}</i>

  <ng-container *ngFor="let value of fieldItem.field_values.values">
    <span *ngIf="!valueIsRef(value)" [innerHTML]="value.value"
            [ngClass]="{'text-value': fieldItem.field_values.values.length === 1}">
        <span *ngIf="!last">, </span></span>

    <span *ngIf="valueIsRef(value)">
        <app-overview-field-link
          *ngIf="value.$$linkItems"
          [toneDownTextColor]="toneDownTextColor"
          [items]="value.$$linkItems"
          [stylingClass]="viewName"
          [disableLinks]="disableLinks"
        ></app-overview-field-link>
    </span>

    </ng-container>
</span>
