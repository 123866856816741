@if (myField) {
  <app-edit-field
    [displayRequiredIndicaton]="displayRequiredIndication"
    [sectionsContainer]="sectionsContainer"
    [field]="myField"
    [object]="compareObject"
    [index]="index"
    [parentIndex]="parentIndex"
  ></app-edit-field>
}
