import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {OperationService} from '../operation.service';
import {OperationDef} from '../../core/definitions/operation-def';
import {SearchContainer} from '../../core/definitions/search-container';

@Component({
  selector: 'app-operation-dropdown-menu-for-search-container',
  templateUrl: './operation-dropdown-menu-for-search-container.component.html',
  styleUrls: ['./operation-dropdown-menu-for-search-container.component.scss']
})
export class OperationDropdownMenuForSearchContainerComponent implements OnChanges {

  @Input() searchContainer: SearchContainer;
  @Input() operationGroupId: string;
  @Output() operationPerformed = new EventEmitter<any>();

  constructor(private operationService: OperationService) {
  }

  ngOnChanges(): void {

  }

  get operations(): OperationDef[] {
    const operationContainer = this.searchContainer.operationContainer;
    let res: OperationDef[]  = [];
    if (operationContainer?.operations) {
      if (this.operationGroupId) {
        if (operationContainer.operations) {
          operationContainer.operations.forEach(operation => {
            if (operation.operation_type_id === this.operationGroupId) {
              res = operation.sub_operations;
            }
          });
        }
      } else {
        res = operationContainer.operations;
      }
    }
    return res;
  }

  async openOperation(operation: OperationDef) {
    if (!operation.$$disabled) {
      await this.operationService.openOperationView(this.searchContainer.operationContainer, operation, null,
        operationRes => {
          this.operationPerformed.emit(operationRes);
        }
      );
    }
  }
}
