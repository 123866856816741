import {Component, Inject, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogData} from '../../object-edit/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  AConst = AConst;
  isArray: boolean;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
  }

  ngOnInit() {
    this.isArray = Array.isArray(this.data.modalContent);
  }
}
