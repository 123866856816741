<button mat-stroked-button
        [matMenuTriggerFor]="filterMenu"
        type="button"
        class="admin-stroked-button">
  <mat-icon>tune</mat-icon>
  <span class="bold uppercase admin-stroked-button__left-margin"
        translate="TRANS__FILTER__FILTRATE"></span>
</button>
<mat-menu #filterMenu="matMenu"
          xPosition="before"
          class="removePaddingInMatMenu">
  <div *ngFor="let filterGroup of conceptsContainer.filterGroups">
    <ng-container *ngIf="filterGroup?.filters">
      <div *ngIf="filterGroup.filters.length > 1 || filterGroup.filters[0].checked_value !== null"
            mat-menu-item  [matMenuTriggerFor]="secondFilterMenu"><span [translate]="filterGroup.title"></span></div>
      <mat-menu #secondFilterMenu="matMenu" class="removePaddingInMatMenu">
        <ng-container *ngFor="let filter of filterGroup.filters">
          <button *ngIf="filter.value !== undefined"
                  [ngClass]="{'active': filter.checked}"
                  mat-menu-item>
            <mat-checkbox [labelPosition]="'after'"
                          [(ngModel)]="filter.checked"
                          (ngModelChange)="filterChanged(filterGroup, filter)"
                          [name]="filter.name">
              <span [translate]="filter.title"></span><span *ngIf="filter.count"> ({{filter.count}})</span></mat-checkbox>
          </button>
        </ng-container>
      </mat-menu>

    </ng-container>
  </div>
</mat-menu>
