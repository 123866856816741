<div class="search-result-view-gallery">
  <ul class="search-result-view-gallery__items">
    <cdk-virtual-scroll-viewport [itemSize]="resultViewService.defaultRows.gallery.tileHeight"
                                 [style.width]="width"
                                 class="scroll-viewport-height"
                                 [ngClass]="{'folder': searchContainer.currentPathView.search_view.search_view_type === 'folder',
                                 'open': searchContainer.isFolderInfoOpen}">
      <ng-container *cdkVirtualFor="let searchRow of ds">
        <ng-container *ngIf="!searchRow">
          <li class="search-result-view-gallery__item">
            Loading...
          </li>
        </ng-container>

        <ng-container *ngIf="searchRow">
          <li class="search-result-view-gallery__item"
              *ngFor="let art of searchRow.columns">
            <div class="search-result-view-gallery__checkbox">
              <div class="checkbox-underlay"
                   [ngClass]="{'disabled': art['$$used']}">
                <mat-checkbox
                  class="white-bg-in-checkbox"
                  (click)="select(art, $event)"
                  [checked]="searchContainer.selections.selected[art.artifact_id]"
                  [disabled]="art.$$used">
                </mat-checkbox></div>
            </div>

            <a [routerLink]="art.$$refData?.routerLink"
               [queryParams]="art.$$refData?.queryParams"
               queryParamsHandling="merge"
               (click)="setSearchItemIndex(art.$$refData.param['searchItemIndex'])"
               class="search-result-view-gallery__item-image-container"
               [ngClass]="{'folder': art.object_type === 'folder'}">
              <img *ngIf="art.$$imageUrl else elseNotThumbnail" alt="{{art.artifact_name}}"
                   [src]="art.$$imageUrl">
              <ng-template #elseNotThumbnail>
                <i class="{{art.$$noImageIcon}} noImgIcon"
                   [ngClass]="{'text-blue': art.object_type === 'folder'}"></i></ng-template>

              <div class="search-result-view-gallery__item-information">
                <div class="search-result-view-gallery__item-information-name-container">
                  <div class="search-result-view-gallery__item-information-icon">
                    <i class="object-icon {{art.$$icon}}"></i>
                  </div>
                  <div class="search-result-view-gallery__item-information-name bold text-link">
                    <span class="object-url-content" [innerHTML]="art.$$name"></span>
                  </div>
                </div>

                <div class="search-result-view-gallery__item-information-fields"
                     *ngFor="let field of art.overview_simple; index as $index">
                  <div *ngIf="$index < 2"
                       class="search-result-view-gallery__item-information-fields-container">
                    <span *ngIf="!field.artifact_id">{{field.value}}</span>

                    <span *ngIf="field.artifact_id">
                  <app-object-url [object]="field">
                    <div class="object-url-content">
                      <span class="text-link">{{field.value}}</span>
                    </div>
                  </app-object-url>
                </span>

                  </div>
                </div>
              </div>

            </a>

          </li>
        </ng-container>
      </ng-container>

    </cdk-virtual-scroll-viewport>
  </ul>
</div>
