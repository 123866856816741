import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ObjectView, OverviewField} from '../../core/definitions/object-view';
import {UiToolsService} from '../../core/ui-tools.service';
import {MetaTypes} from '../../core/definitions/meta-types';

@Component({
  selector: 'app-object-content-list-overview-fields',
  templateUrl: './object-content-list-overview-fields.component.html',
  styleUrls: ['./object-content-list-overview-fields.component.scss']
})
export class ObjectContentListOverviewFieldsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() objectView: ObjectView;
  @ViewChild('sizeElement', { static: true }) sizeElementRef: ElementRef;
  MetaTypes = MetaTypes;
  columns: Array<Array<OverviewField>> = [];
  private stopSizeWatch;

  constructor(private uiTools: UiToolsService) { }

  ngOnInit() {
    this.stopSizeWatch = this.uiTools.addWindowSizeListener((newVal, oldVal) => {
      if ((newVal.width !== oldVal.width) && (newVal.width > 740 && oldVal.width < 760)) {
        this.setColumns();
      }
    });
  }
  ngOnChanges(): void {
    this.setColumns();
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  private getColumns(overviewFields) {
    const columns = [];
    const el = this.sizeElementRef.nativeElement;
    if (el) {
      const width = el.offsetWidth;
      overviewFields.forEach((ovf, index) => {
        const colIndex = Math.floor(index / 4);
        if (colIndex === 0 || width > 500) {
          if (index < 12) {
            if (columns.length <= colIndex) {
              columns.push([]);
            }
            columns[colIndex].push(ovf);
          }
        }
      });
    }
    return columns;
  }

  setColumns() {
    if (this.objectView) {
      const overviewFields = this.objectView['overview'];
      if (overviewFields) {
        this.columns = this.getColumns(overviewFields);
        if (this.columns.length === 0) {
          console.warn('No columns set!');
        }
      } else {
        console.error('No overview fields found');
      }
    }
  }
}
