<mat-expansion-panel class="job-status-message-list"
  (afterExpand)="afterExpand()" (afterCollapse)="afterCollapse()">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="job-status-message-list__header">
        <div class="job-status-message-list__header-content">
          <div class="job-status-message-list__header-title">
            <span>{{message.task_name | translate}}</span><span>{{message.fileTypeName}}</span>
          </div>
          <div class="job-status-message-list__header-sub-title">
            <span class="tone-down-color">{{message.taskMetaData}}</span>
          </div>
        </div>
        <div class="job-status-message-list__status" [ngSwitch]="message.statusType">
          <div *ngSwitchCase="'active'" class="medium-icon-bg active">
            <i class="icon-spinner job-status__spinner-icon color-white"></i>
          </div>
          <div *ngSwitchCase="'finished'" class="medium-icon-bg finished">
            <mat-icon class="job-status__finished-icon color-white">done</mat-icon></div>
          <div *ngSwitchCase="'failed'" class="medium-icon-bg failed">
            <mat-icon class="job-status__failed-icon color-white">close</mat-icon></div>
          <span *ngIf="message.status !== 'failed'" class="tone-down-color">
            {{message.statusMessage}}
          </span>
          <a *ngIf="message.status === 'failed'"
                (click)="onShowError(message)">
            <span class="tone-down-color">{{'TRANS__JOB_STATUS__FAILED' | translate}}</span>
          </a>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <!-- content-->
  <div class="job-status-message-list__content tone-down-color">ID: {{message.queue_id}}</div>
  <button mat-stroked-button color="primary"
    *ngIf="message.task_type === 'reports' && (message.status === 'finished' || message.status === 'downloaded')"
    (click)="downloadReport(message.queue_id, message.filetype)">
    <span>{{'TRANS__JOB_STATUS__DOWNLOAD' | translate}}</span></button>
  <ng-container *ngIf="message.ref">
    <a mat-stroked-button color="primary"
       [routerLink]="primusStateMapper.routerLinkFromState(message.ref)"
       [queryParams]="primusStateMapper.queryParams(message.refParams)"
       queryParamsHandling="merge"
      (click)="onClosePanel()">
      <span>{{'TRANS__JOB_STATUS__OPEN' | translate}}</span>
    </a>
  </ng-container>
</mat-expansion-panel>
