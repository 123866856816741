export class TwoWayMap {
  private keyToValue: Map<string, string>;
  private valueToKey: Map<string, string>;

    constructor() {
        this.keyToValue = new Map();
        this.valueToKey = new Map();
    }

    set(key: string, value: string) {
        this.keyToValue.set(key, value);
        this.valueToKey.set(value, key);
    }

    getByKey(key: string) {
        return this.keyToValue.get(key);
    }

    hasKey(key: string) {
        return this.keyToValue.has(key);
    }

    hasValue(value: string) {
        return this.valueToKey.has(value);
    }

    getByValue(value: string) {
        return this.valueToKey.get(value);
    }

    deleteByKey(key: string) {
        const value = this.keyToValue.get(key);
        this.keyToValue.delete(key);
        this.valueToKey.delete(value);
    }

    deleteByValue(value: string) {
        const key = this.valueToKey.get(value);
        this.valueToKey.delete(value);
        this.keyToValue.delete(key);
    }
}
