@if (generatedReport.status === 'loading') {
  <div class="loading-spinner">Laster dine rapportmaler...</div>
} @else if (generatedReport.status === 'loaded') {
  <div class="report-template-list">
    <div class="report-template-list-item report-template-list-item-header">
      <div class="report-template-list-item-field">Rapport ID</div>
      <div class="report-template-list-item-field">Tittel</div>
      <div class="report-template-list-item-field">Beskrivelse</div>
      <div class="report-template-list-item-field">Status</div>
      <div class="report-template-list-item-field">Handling</div>
    </div>
    @for(template of generatedReport; track template.id) {
      <div class="report-template-list-item">
        <div class="report-template-list-item-field">{{template.id}}</div>
        <div class="report-template-list-item-field">{{template.name}}</div>
        <div class="report-template-list-item-field">{{template.shortDescription}}</div>
        <div class="report-template-list-item-field">-</div>
        <div class="report-template-list-item-field">
          <a [routerLink]="['/reports/generated-reports', template.id]">
            <button mat-flat-button color="primary">Tilpass</button>
          </a>
        </div>
      </div>
    }
  </div>
}

<router-outlet></router-outlet>
