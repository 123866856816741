import {Component, OnInit} from '@angular/core';
import {ConceptService} from "../../core/concept.service";
import {PrimusRouterService} from "../../core/primus-router.service";
import {ConceptsContainer} from "../../core/definitions/concepts-container";

@Component({
  selector: 'app-admin-address',
  templateUrl: './admin-address.component.html',
  styleUrl: './admin-address.component.scss'
})
export class AdminAddressComponent implements OnInit {

  constructor(private conceptService: ConceptService,
              private primusRouter: PrimusRouterService) { }

  conceptsContainer: ConceptsContainer;
  parent: any;

  ngOnInit(): void {
    this.setAddressConceptsContainer().then();
    this.parent = {
      path: 'admin',
      adminType: 'address',
      hasSubMenus: true,
      target_params: {admin_type: 'address'},
      currentState: this.primusRouter.currentState()
    };
  }

  async setAddressConceptsContainer() {
    const conceptType = await this.conceptService.getConceptType('address');
    this.conceptService.setConceptsContainer(conceptType).then(conceptsContainer => {
      this.conceptsContainer = conceptsContainer;
      this.conceptsContainer.showLinkToObject = true;
    });

  }

}
