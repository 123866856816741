import {QueryMenu} from "./definitions/search-objects";

export class FieldSearchQueryFragment {
  inputType: string = undefined;
  isExcluded: boolean = false;
  name: string = undefined;
  solrOperand: boolean = false;
  solrQueryField: string = undefined;
  value: string | number | boolean = undefined;
}

export class QueryContainer {
  fieldSearchQueryFragments: FieldSearchQueryFragment[] = [];
  currentQueryPlaceholder: string = undefined;
  defaultQueryTooltip: string;
  query: string = undefined;
  queryField: string = undefined;
  queryPlaceholder: string;
  selectedQueryMenu: QueryMenu;
}
