import {Injectable} from '@angular/core';

import * as Sentry from "@sentry/browser";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private logData = {};
  private clearLog = 0;

  constructor() {
  }

  warn(message: string, data?) {
    if (this.checkAddExisting(message, data)) {
      console.warn(this.getLogStatement('warning', message, data));
    }
  }

  error(message: string, data?) {
    if (this.checkAddExisting(message, data)) {
      console.error(this.getLogStatement('error', message, data));
      Sentry.captureException(new Error(this.getLogStatement('error', message, data)));
    }
  }

  debug(message: string, data?) {
    if (this.checkAddExisting(message, data)) {
      console.log(this.getLogStatement('debug', message, data));
    }
  }

  info(message: string, data?) {
    if (this.checkAddExisting(message, data)) {
      console.log(this.getLogStatement('info', message, data));
    }
  }

  private getLogMessage(message: string, data?) {
    return data ? ` ${message}: ${JSON.stringify(data)}` : message;
  }

  private getLogStatement(level: string, message: string, data?) {
    return `[${level}] [${new Date().toLocaleString()}] ${this.getLogMessage(message, data)}`;
  }

  private checkAddExisting(message: string, data?) {
    const logMessage = this.getLogMessage(message, data);
    let doLog = true;
    if (this.clearLog++ > 1000) {
      this.clearLog = 0;
      this.logData = {};
    }
    const existing = this.logData[logMessage];
    if (existing) {
      if (existing.count < 10) {
        existing.count++;
      } else {
        doLog = false;
        if (!existing.countOverflowLogged) {
          console.warn(`The message '${logMessage}' has already been logged ${existing.count} times.`);
          console.warn(`Stopping logging now in order to prevent client from crashing.`);
          existing.countOverflowLogged = true;
        }
      }
    } else {
      this.logData[logMessage] = {
        count: 0,
        countOverflowLogged: false
      };
    }
    return doLog;
  }
}
