<div class="message-container">

  <div class="previousMessage"
       *ngIf="notifications.length > 1">
    <a class="previousMessage-button"
       (click)="previousMessage()">
      <mat-icon>navigate_before</mat-icon></a>
  </div>

  <ul>
    <li *ngFor="let message of notifications; let i = index">
      <div class="message-container__message"
           *ngIf="currentIndex === i">
        <div *ngIf="message.name !== 'upComing'">
          <div class="message-container__message__title">
            <h3>{{message.title}}</h3></div>
          <div *ngIf="message.message"
               class="message-container__message__ingress">
            <div class="message-container__message__ingress__vertical-line bg-blue"></div>
            <h5>{{message.message}}</h5></div></div>
        <div *ngIf="message.name === 'upComing'">
          <div class="message-container__message__title">
            <h3>{{message.message.title}}</h3></div>
          <div *ngIf="message.message.description"
               class="message-container__message__ingress">
            <div class="message-container__message__ingress__vertical-line bg-blue"></div>
            <h5>{{message.message.description}}</h5></div>
          <div class="what-is-new__table">
            <div class="what-is-new__table__row what-is-new__table__row-header">
              <div class="what-is-new__table__row-item">
                    <span translate="TRANS__EDITION__WHATS_NEW"
                          [translateParams]="{version: message.version}"></span></div>
              <div class="what-is-new__table__row-item"
                   translate="TRANS__EDITION__SMALL"></div>
              <div class="what-is-new__table__row-item"
                   translate="TRANS__EDITION__LARGE"></div>
              <div class="what-is-new__table__row-item"
                   translate="TRANS__EDITION__MEDIUM"></div></div>
            <div class="what-is-new__table__row what-is-new__table__row-content"
                 *ngFor="let change of message.what_is_new">
              <div class="what-is-new__table__row-item">
                <span>{{change.description}}</span></div>
              <div *ngFor="let edition of change.edition"
                   class="what-is-new__table__row-item {{edition}}">
                <mat-icon *ngIf="edition === 'small' || edition === 'large' || edition === 'medium'">check</mat-icon></div></div>
          </div>
        </div>
      </div>
    </li>

  </ul>
  <ul class="carousel-indicators"
      *ngIf="notifications.length > 1">
    <li *ngFor="let message of notifications; let i = index"
        (click)="goToMessage(i)"
        [ngClass]="{'bg-blue': currentIndex === i}"></li></ul>

  <div *ngIf="notifications.length > 1"
       class="carousel-indicators-text">
        <span translate="TRANS__MESSAGE__INDICATOR_TEXT"
              [translateParams]="{page: currentIndex + 1,
              total: notifications.length}"></span></div>

  <div class="nextMessage"
       *ngIf="notifications.length > 1">
    <a class="nextMessage-button"
       (click)="nextMessage()">
      <mat-icon>arrow_forward_ios</mat-icon></a>
  </div>


  <div class="message-container__bottom">
    <i class="icon-primus"></i>
    <div class="vertical-line vLine1"></div>
    <span translate="TRANS__ADMIN__HELP__CONTACT"></span>
    <span translate="TRANS__ADMIN__HELP__NUMBER"></span>
    <div class="vertical-line vLine2"></div>
    <span translate="TRANS__ADMIN__HELP__MAIL"></span>
    <a class="text-blue-hover" href="mailto:support@kulturit.no">support&#64;kulturit.no</a>
  </div>
</div>
