import {Component, Input, OnInit} from '@angular/core';
import {AnnotationHandler} from '../../annotation-handler';

@Component({
  selector: 'app-annotation-points',
  templateUrl: './annotation-points.component.html',
  styleUrls: ['./annotation-points.component.scss']
})
export class AnnotationPointsComponent implements OnInit {

  @Input() curAnn: AnnotationHandler;
  @Input() isDialog: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
