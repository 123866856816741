import {Component, Input, OnInit} from '@angular/core';
import {AnnotationHandler} from '../../annotation-handler';

@Component({
  selector: 'app-annotation-color-menu',
  templateUrl: './annotation-color-menu.component.html',
  styleUrls: ['./annotation-color-menu.component.scss']
})
export class AnnotationColorMenuComponent implements OnInit {

  @Input() curAnn: AnnotationHandler;
  @Input() isDialog: boolean;

  constructor() { }

  ngOnInit() {
  }

}
