<ng-template #content>
  <ng-content select=".object-url-content" class="text-link"></ng-content>
</ng-template>
<a *ngIf="!externalLink"
   [ngClass]="{'object-url__object-link': !hasImage, 'disable': disable}" class="{{aClass || 'objName-text'}}"
   [routerLink]="!disable ? routerLink : null"
   [queryParams]="queryParams"
   queryParamsHandling="merge"
   (click)="clickItem()">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<a *ngIf="externalLink"
   [ngClass]="{'object-url__object-link': !hasImage, 'disable': disable}" class="{{aClass || 'objName-text'}}"
   (click)="clickExternalItem(externalLink)"
   appExternalUrl
   >
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

