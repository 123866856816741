@if (fieldParameters) {
  <div class="field-select" [formGroup]="fieldParameters.sectionsContainer.formGroup"
       [ngClass]="{'fieldSelectOpen': inFocus.value}">
    @if (cachedParams) {
      <app-edit-field-select-query
        [sectionsContainer]="fieldParameters.sectionsContainer"
        [fieldParameters]="fieldParameters"
        [reference]="reference"
        [query]="query"
        [inFocus]="inFocus"
        [showOptions]="showOptions"
        [fullSearch]="fullSearch"
        [temporaryFieldValueName]="cachedParams.temporaryFieldValueName"
        (queryEvent)="onChildEvent($event)"
      ></app-edit-field-select-query>
    }

    @if (showOptions.show) {
      <div style="padding: 0" class="show-options"
           [ngClass]="fieldParameters.sectionsContainer.isDialog ?
     (fieldParameters.sectionsContainer.isSecondDialog ? 'ignoreClicksSecondModal-' + fieldParameters.field.name :
     'ignoreClicksModal-' + fieldParameters.field.name) :
     'ignoreClicks-' + fieldParameters.field.name"
           [class.show-options-in-modal]="fieldParameters.sectionsContainer.isDialog">
        @if (fieldParameters) {
          <app-field-select-options
            [fieldParameters]="fieldParameters"
            [reference]="reference"
            [query]="query.value"
            [isArray]="isArray"
            [selectedRow]="selectedRow"
            [fullSearch]="fullSearch.value"
            [dataSource]="ds"
            [keyPressed]="keyPressed"
            (optionSelected)="onOptionsSelected([$event])"
            (optionUnChecked)="onOptionUnchecked($event)"
            (keyEventsInShowOptions)="onKeyEventsInShowOptions($event)"
          ></app-field-select-options>
        }
        @if (fieldParameters.canAddNew) {
          <div class="createConcept">
            <button mat-button
                    (keyup)="createOptionWithKey($event)"
                    (blur)="createOptionBlur()"
                    type="button"
                    (click)="createOption(); $event.stopPropagation();" #addNewOption>
              <mat-icon>add</mat-icon>
              <span>{{'TRANS__FIELD_SELECT__ADD_NEW' | translate}}</span>
            </button>
            @if (reference?.search_kulturnav) {
              <button mat-stroked-button
                      (click)="searchCultureHub(); $event.stopPropagation();"
                      (keyup)="searchCultureHubWithKey($event)"
                      type="button"
                      (blur)="searchCultureHubBlur()">
                <mat-icon>get_app</mat-icon>
                <span>{{'TRANS__FIELD_SELECT__GET_FROM_AUTHORITY' | translate}}</span>
              </button>
            }
          </div>
        }
      </div>
    }
    @if (isArray) {
      <ul class="field-select__multi-selected-options"
          [ngClass]="{'focusMultiSelectedOptions': inFocus.value}"> <!-- if multi-select -->
        <app-field-select-selected-options
          [dragula]="fieldParameters.field.name"
          [(dragulaModel)]="fieldParameters.object[fieldParameters.field.name]"
          [fieldParameters]="fieldParameters"
          (optionUnChecked)="onOptionUnchecked($event)"
        ></app-field-select-selected-options>
      </ul>
    }
  </div>
}

