@if (referenceUsageInfo.usage.length) {
  <div class="reference-usage dialog-border-color">
    <button mat-stroked-button
            color="primary"
            type="button"
            class="reference-usage__button"
            (click)="toggleDisplayUsage()"
            [ngClass]="{'active': displayUsage}">
      <span class="bold" [translate]="referenceUsageInfo.showUsageText"></span>
      <span class="bold">: {{referenceUsageInfo.usage.length}}</span>
      <mat-icon>{{displayUsage ? 'expand_less' : 'expand_more'}}</mat-icon>
    </button>
    @if (displayUsage) {
      <mat-chip-listbox class="reference-usage__objects">
        @for (usageObj of referenceUsageInfo.usage; track usageObj) {
          <mat-chip-option class="reference-usage__object">
            @if (showReferencesAsLink) {
              <app-object-url [object]="usageObj">
                <span class="object-url-content">{{usageObj.artifact_name}}</span>
              </app-object-url>
            } @else {
              <span>{{usageObj.artifact_name}}</span>
            }
          </mat-chip-option>
        }
      </mat-chip-listbox>
    }
    @if (referenceUsageInfo.usageWarning) {
      <p [translate]="referenceUsageInfo.usageWarning"></p>
    }
  </div>
}
