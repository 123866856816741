import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminUsersService } from '../../admin-users.service';

@Component({
  selector: 'app-user-profile-section',
  templateUrl: './user-profile-section.component.html',
  styleUrls: ['./user-profile-section.component.scss']
})
export class UserProfileSectionComponent implements AfterViewInit, OnDestroy {

  static anySectionInEditMode = false;

  @Input() name: string;
  @Input() heading: string;
  @Input() showEditButton: boolean;
  @Input() disableOverflow: boolean;

  @Output() public readonly editModeChanged: EventEmitter<boolean>;
  @Output() public readonly editEnd: EventEmitter<void>;
  @Output() public readonly saveClicked: EventEmitter<void>;
  @Output() public readonly abortClicked: EventEmitter<void>;
  @Output() public readonly addCollectionRightsClicked = new EventEmitter<string>();

  editModeEnabled: boolean;

  private editSectionSubscriber: Subscription;
  private cancelEditSectionSubscriber: Subscription;

  constructor(private adminUsersService: AdminUsersService) {
    this.heading = '';

    this.editModeChanged = new EventEmitter<boolean>();
    this.editEnd = new EventEmitter<void>();
    this.saveClicked = new EventEmitter<void>();
    this.abortClicked = new EventEmitter<void>();

    this.editModeEnabled = false;
  }

  ngAfterViewInit() {
    // Subscribe to which of the sections that has been
    // selected by the user for editing.
    this.editSectionSubscriber = this.adminUsersService.editSection.subscribe(name => {
      if (name === null) {
        return;
      }
      if (this.name === name) {
        this.editModeEnabled = true;
      } else {
        this.editModeEnabled = false;
        this.editEnd.emit();
      }
      this.editModeChanged.emit(this.name === name);
    });

    // Subscribe to event passed when the user has clicked another tab.
    // This event is used to cancel edit-mode, if active, for the named section.
    this.cancelEditSectionSubscriber = this.adminUsersService.cancelEditSection.subscribe(name => {
      if (this.name === name) {
        this.editModeEnabled = false;
        this.editEnd.emit();
        this.editModeChanged.emit(this.editModeEnabled);
      }
    });
  }

  ngOnDestroy() {
    this.editSectionSubscriber?.unsubscribe();
    this.cancelEditSectionSubscriber?.unsubscribe();
  }

  handleToggleEditMode(): void {
    this.editModeEnabled = !this.editModeEnabled;
    if (this.editModeEnabled) {
      this.adminUsersService.setEditMode(this.name);
    } else {
      this.adminUsersService.cancelEditMode(this.name);
    }
  }

  handleSaveAndAbort(emitter: EventEmitter<void>): void {
    this.handleToggleEditMode();
    emitter.emit();
  }

  get anyOtherSectionInEditMode(): boolean {
    return UserProfileSectionComponent.anySectionInEditMode;
  }

  addCollectionRights(collectionType: string) {
    this.addCollectionRightsClicked.emit(collectionType);
  }
}
