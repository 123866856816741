import {Component, OnInit} from "@angular/core";
import {CmsApiService} from "../../../core/cms-api.service";
import {ModelStore} from "../../../core/ModelStore/ModelStore";
import {ReportUserGenerated} from "../../../core/ModelStore/models/ReportUserGenerated";
import {ModelCacheArray} from "../../../core/ModelStore/ModelCacheArray";

@Component({
  selector: 'report-generated',
  templateUrl: './report-generated.component.html',
  styleUrls: ['./report-generated.component.scss']
})
export class ReportGeneratedComponent implements OnInit {
  generatedReport: ModelCacheArray<ReportUserGenerated>;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore) {

  }

  ngOnInit(): void {
    this.generatedReport = this.modelStore.findAllModels('report_user_generated')
  }

}
