import { Injectable } from '@angular/core';
import {BaseModel} from '../core/definitions/base-model';
import {FieldValue} from '../core/definitions/object-view';
import {CommonsService} from '../core/commons.service';
import {SettingsService} from '../core/settings.service';

@Injectable({
  providedIn: 'root'
})
export class OverviewFieldsService {

  constructor(private commons: CommonsService,
              private settings: SettingsService) { }

  async getLinkItems(linkValues: FieldValue[]): Promise<BaseModel[]> {
    const items = [];
    for (let t = 0; t < linkValues.length; t++) {
      const linkValue = linkValues[t];
      let objectType = linkValue.ref_object_type;
      if (!objectType) {
        objectType = await this.commons.getObjectTypeFromObjectId(linkValue.ref_id);
      }
      const icon = await this.settings.objectIcon(objectType);
      items.push({
        artifact_id: linkValue.ref_id,
        artifact_name: linkValue.value,
        object_type: objectType,
        meta_type: linkValue.ref_meta_type,
        $$icon: icon
      } as BaseModel);
    }
    return items;
  }

  valueIsRef(value: FieldValue) {
    return value.ref_id && value.ref_id.indexOf('ct_') === -1;
  }

}
