<div class="media-selectors">
  <mat-tab-group color="accent" class="media-selectors__tab-group">
    <mat-tab *ngFor="let mediaContainer of operationUploadContainer.uploadMediaContainers"
             label="{{mediaContainer.name | translate}}">

      <div class="row">
        <app-media-selector-general
          [uploadInfo]="mediaContainer.uploadInfo"
          [parentObject]="object"
          [files]="mediaContainer.files"
        ></app-media-selector-general>
      </div>

      <div class="row" *ngIf="mediaContainer.files.length > 0">
        <app-media-list
          class="media-selectors__media-list"
          [uploadMediaContainer]="mediaContainer"
          [isDialog]="isDialog"
          [parentObject]="object"
        ></app-media-list>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
