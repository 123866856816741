import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {FieldDisplayLabel} from '../../../core/definitions/meta-field';
import {InlineArrayHeaderContainer} from '../inline-array-header-container';

@Component({
  selector: 'app-inline-array-header-item',
  templateUrl: './inline-array-header-item.component.html',
  styleUrls: ['./inline-array-header-item.component.scss']
})
export class InlineArrayHeaderItemComponent implements OnChanges {
  @Input() label: FieldDisplayLabel;
  @Input() container: InlineArrayHeaderContainer;
  @Input() isHeadline: boolean;

  AConst = AConst;
  labelClass = '';

  constructor() { }

  ngOnChanges() {
    if (this.label.css_class_edit) {
      this.labelClass = this.label.css_class_edit;
    }
  }

}
