import {Injectable} from '@angular/core';
import {RootSearchView, SearchResultViews, SearchViewPath} from '../core/definitions/search-objects';
import {SearchObject} from '../core/definitions/search-object';
import {RefParams, RefService} from '../core/ref.service';
import {CommonsService} from '../core/commons.service';
import {MediaHelperService} from '../core/media-helper.service';
import {SettingsService} from '../core/settings.service';
import {FolderService} from '../core/folder.service';
import {SearchResultViewType} from '../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../core/definitions/search-container';
import {ObjectTypes} from "../core/definitions/object-types";
import {FeatureFlagsService} from "../core/feature-flags.service";

export interface RowsAndColumns {
  rows: number;
  columns: number;
  tileRows: number;
  tileHeight: number;
}

@Injectable({
  providedIn: 'root'
})
export class ResultViewService {
  defaultRows = {
    'thumbnail': {
      columns: 10,
      tileHeight: 256
    },
    'gallery': {
      columns: 4,
      tileHeight: 400
    }
  };

  constructor(
    private refService: RefService,
    private commons: CommonsService,
    private mediaHelper: MediaHelperService,
    private settings: SettingsService,
    private folderService: FolderService,
    private featureFlagsService: FeatureFlagsService) {
  }

  // If new path view contains own search result views, check
  // whether current search result view exists in the path view's
  // search results views, and if not set current search result
  // view to path view's default search result view
  setCurrentResultViewFromPathView(searchContainer: SearchContainer, pathView: SearchViewPath) {
    let searchResultViews = pathView.search_view.search_result_views;
    if (!searchResultViews) {
      searchResultViews = searchContainer.searchView.search_result_views;
    }
    this.setDefaultOrExistingSearchView(searchContainer, searchResultViews);
  }

  setCurrentResultView(viewName: string, searchContainer: SearchContainer) {
    this.calculateAndSetsDefaultRowsColumns(searchContainer);
    if (searchContainer) {
      this.resetSearchPosition(searchContainer);
      const searchResultViews = searchContainer.currentPathView?.search_view?.search_result_views
        || searchContainer.searchView.search_result_views;
      if (searchResultViews.default !== viewName && searchContainer.searchResultViewDefaultSet) {
        searchContainer.searchResultViewDefaultSet = false
      } else if (searchResultViews.default === viewName && !searchContainer.searchResultViewDefaultSet) {
        searchContainer.searchResultViewDefaultSet = true
      }
      searchContainer.searchResultViewName = viewName;
      searchContainer.combineHorizontal = viewName !== SearchResultViewType.LIST;
      const rowsAndColumns = this.getDefaultCalculatedRowsColumns(viewName);
      if (rowsAndColumns) {
        searchContainer.rows = searchContainer.rows || {};
        searchContainer.rows[viewName] = rowsAndColumns.rows;
      }
    }
  }

  /*
  Returns the default search result view, but also checks whether
  the default search view name is a part of the defined search views
  */
  getDefaultSearchResultView(view: RootSearchView) {
    let res = null;

    if (!view.search_result_views) {
      console.error('No search result views defined for ' + view);
    }
    for (const searchResultView of view.search_result_views.views) {
      if (searchResultView.name === view.search_result_views.default) {
        res = searchResultView;
        break;
      }
    }
    if (!res) {
      console.error('Default search result view not found');
    }
    return res;
  }

  setDefaultSearchResultView(searchContainer: SearchContainer) {
    const defaultSearchView = this.getDefaultSearchResultView(searchContainer.searchView);
    this.setCurrentResultView(defaultSearchView.name, searchContainer);
  }

  calculateAndSetsDefaultRowsColumns(searchContainer: SearchContainer) {
    for (const viewName of Object.values(SearchResultViewType)) {
      for (const view of searchContainer.searchResultViews.views) {
        if (view.name === viewName) {
          this.defaultRows[viewName] = this.calcSearchRowsAndColumns(viewName);
        }
      }
    }
  }

  getDefaultCalculatedRowsColumns(view: any, tileHeight?: number): RowsAndColumns {
    let res = {
      rows: 50,
      columns: 1,
      tileRows: 10,
      tileHeight: tileHeight
    } as RowsAndColumns;
    const viewName = this.getViewName(view);
    if (this.defaultRows[viewName]) {
      res = this.defaultRows[viewName];
    }
    return res;
  }

  getViewTileData() {
    const artLowerEl = document.getElementById('artifactLower');
    let heightOffset = 200;
    if (artLowerEl) {
      heightOffset = artLowerEl.offsetTop + 100;
    }
    return {
      'content-list': {
        minTileRows: 5,
        tileHeight: 140,
        heightOffset: heightOffset,
        noReport: true,
        scrollAddRows: 20
      },
      'thumbnail': {
        minTileRows: 4,
        tileHeight: 256,
        tileWidth: 202
      },
      'list-thumbnail': {
        minTileRows: 10,
        tileHeight: 122
      },
      'gallery': {
        minTileRows: 2,
        tileHeightAspectRatio: 0.727
      },
      'list': {
        minTileRows: 10,
        tileHeight: 48,
        heightOffset: 50,
        scrollAddRows: 30
      },
      'list-hierarchic': {
        minTileRows: 10,
        tileHeight: 56,
        heightOffset: 27,
        scrollAddRows: 30
      },
      'folder': {
        minTileRows: 10,
        tileHeight: 140,
        scrollAddRows: 20
      },
      'selector': {
        minTileRows: 10,
        tileHeight: 136,
        noReport: true,
        scrollAddRows: 5
      }
    };
  }

  calcSearchRowsAndColumns(viewName: string) {
    const params = this.getViewTileData()[viewName];
    const minTileRows = params.minTileRows;
    let tileHeight = params.tileHeight;
    const tileWidth = params.tileWidth;
    let res = this.getDefaultCalculatedRowsColumns(viewName, tileHeight);
    let tileColumns = 1;
    const s = this.getSearchViewSizes(params);
    if (s) {
      if (tileWidth) {
        tileColumns = Math.floor(s.width / tileWidth);
      } else if (viewName === SearchResultViewType.GALLERY) {
        if (s.width > 2500) {
          tileColumns = 4;
        } else if (s.width > 1500) {
          tileColumns = 3;
        } else if (s.width > 700) {
          tileColumns = 2;
        }
        tileHeight = Math.floor((s.width / tileColumns) * params.tileHeightAspectRatio);
        res.tileHeight = tileHeight;
      }
      const tileRows = Math.max(minTileRows, Math.floor(s.height / tileHeight) + 1);
      res.tileRows = tileRows;
      res.rows = tileColumns * tileRows;
      res.columns = tileColumns;
    }
    return res;
  }

  private getSearchViewSizes(params: any) {
    const innerConEl = document.getElementById('innerCon');
    const searchResultViewsEl =
      document.getElementById('searchResultViews');
    const heightOffset = params.heightOffset || 0;
    if (!innerConEl || !searchResultViewsEl) {
      return null;
    }
    let offsetTop: number;
    if (searchResultViewsEl.offsetTop > 140 && !params.noReport) {
      offsetTop = 140;
    } else {
      offsetTop = searchResultViewsEl.offsetTop;
    }
    const scrollBarWidth = 15;
    const width = innerConEl.offsetWidth - scrollBarWidth;
    const height = window.innerHeight - offsetTop - heightOffset;
    return {
      width: width,
      height: height
    };
  }

  private getViewName(view: any) {
    let res: any;
    if (typeof view === 'object') {
      const searchResultView = this.getDefaultSearchResultView(view);
      if (searchResultView) {
        res = searchResultView.name;
      }
    } else {
      res = view;
    }
    return res;
  }

  // This is duplicated from search handler, must be placed somewhere more general
  private resetSearchPosition(searchContainer: SearchContainer) {
    searchContainer.searchPage = 1;
    searchContainer.searchRow = undefined;
  }

  private setDefaultOrExistingSearchView(searchContainer: SearchContainer, searchResultViews: SearchResultViews) {
    let setDefaultView = true;
    if (!searchResultViews.force_set_default && !searchContainer.searchResultViewDefaultSet) {
      searchContainer.searchResultViews = searchResultViews;
      for (const searchResultView of searchResultViews.views) {
        if (searchResultView.name === searchContainer.searchResultViewName) {
          setDefaultView = false;
          break;
        }
      }
    }
    if (setDefaultView) {
      this.setCurrentResultView(searchResultViews.default, searchContainer);
    }
  }

  async setSearchResultItemProps(searchContainer: SearchContainer, searchObjects?: SearchObject[], indexAdd?: number) {
    let loopObjects: SearchObject[];
    if (searchObjects) {
      loopObjects = searchObjects;
      if (indexAdd === undefined) {
        indexAdd = searchContainer.searchResult.artifacts.length;
      }
    } else {
      loopObjects = searchContainer.searchResult.artifacts;
    }
    if (!loopObjects) {
      console.warn('No objects found');
      return;
    }
    indexAdd = indexAdd || 0;
    const mediaObjects = [];
    const folders = [];
    for (let index = 0; index < loopObjects.length; index++) {
      const item = loopObjects[index];
      const refParams = new RefParams(item);
      refParams.searchItemIndex = index + indexAdd;
      refParams.rootObjId = item.artifact_id;
      refParams.rootObjType = item.object_type;
      item.$$refData = await this.refService.makeRef(refParams);
      item.$$name = this.commons.applyWordBreakOpportunity(item.artifact_name);
      this.setImageUrl(searchContainer, item);
      item.$$icon = await this.settings.objectIcon(item.object_type);
      item.$$noImageIcon = 'icon-image';
      if (item.object_type === 'folder') {
        item.$$noImageIcon = 'icon-folder-open';
        folders.push(item);
      }
      if (searchContainer.targetObject && item.meta_type === 'media') {
        mediaObjects.push(item);
      }
    }
    if (folders.length) {
      await this.folderService.setFilterItemsOnFolder(folders).then();
    }
    this.setMediaObjectsProps(searchContainer, mediaObjects).then();
  }

  private async setMediaObjectsProps(searchContainer: SearchContainer, mediaObjects: SearchObject[]) {
    if (mediaObjects.length) {
      await this.mediaHelper.setMediaProps(mediaObjects, searchContainer.targetObject);
      if (searchContainer.searchResultViewName !== 'list') {
        await this.mediaHelper.addThumbnailImageUrl(mediaObjects);
      }
    }
  }

  private setImageUrl(searchContainer: SearchContainer, searchObject: SearchObject) {
    let getImageUrl = false;
    if (searchObject.object_type === ObjectTypes.AUDIO) {
      searchObject.$$imageUrl = this.mediaHelper.getAudioPlaceholderImageUrl();
    } else if (searchObject.thumbnail_url && searchContainer.searchResultViewName !== SearchResultViewType.GALLERY) {
      searchObject.$$imageUrl = searchObject.thumbnail_url;
    } else if (searchObject.thumbnail_id && searchContainer.searchResultViewName === SearchResultViewType.GALLERY) {
      if (this.featureFlagsService.getFeatureFlags().temporary.multipleImageUrls) {
        searchObject.$$imageUrl = searchObject.medium_image_url;
      } else {
        this.mediaHelper.getImageUrl(searchObject, 'medium').then(imageUrl => {
          searchObject.$$imageUrl = imageUrl;
        })
      }
    }
    return getImageUrl;
  }
}
