<div class="procedure-content-list object-procedure-content-list"
     [ngClass]="{'smallScreen': smallScreen}">
  <div class="procedure-content-list__title">
    <a *ngIf="smallScreen"
       (click)="onToggleMenu()"
       class="procedure-content-list__toggle-button">
      <i class="icon-hamburger"></i>
    </a>
    <h3 class="bold" *ngIf="operationContainer.contentInfo.selectedMainMenu">
      <span>{{operationContainer.contentInfo.selectedMainMenu.count}} &nbsp;</span>
      <span>{{caption}}</span>
    </h3>
  </div>

  <div>
<!--    <a class="procedure-button add-button"-->
<!--       (click)="openSearchSelector()"-->
<!--       [ngClass]="{'disable-button': isEditing}">-->
<!--      <i class="icon-plus"></i>-->
<!--      <span translate>TRANS__PROCEDURE_CONTENT_LIST__SELECT_EXISTING</span>-->
<!--    </a>-->

    <button mat-stroked-button
            *ngIf="!operationContainer.currentOperation?.sub_operations"
            type="button"
            class="add-button"
            color="primary"
            (click)="openModal()"
            [disabled]="isEditing || operationContainer.rootSectionsContainer.rootObject.read_only">
      <mat-icon>add</mat-icon>
      <span  class="bold uppercase" translate="TRANS__HEADER__OBJECT_MENU__ADD"></span>
    </button>
    <!-- TODO: removed this because it caused two sets of "add objects" menus -->
<!--    <app-operation-menus-two-levels-->
<!--      *ngIf="operationContainer.currentOperation?.sub_operations?.length > 0"-->
<!--      class="procedure-content-list__operation-buttons"-->
<!--      [operationContainer]="operationContainer"-->
<!--      [parentOperation]="operationContainer.currentOperation"-->
<!--    ></app-operation-menus-two-levels>-->
  </div>

  <div class="procedure-content-list__content-list">
    <app-object-content-list-regular
      *ngIf="operationContainer.contentInfo.curListContainer"
      [contentListContainer]="operationContainer.contentInfo.curListContainer"
      (objectRefresh)="loadProcedure()"
    ></app-object-content-list-regular>
  </div>

</div>

