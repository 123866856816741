@if (!disabled) {
  @if (checkboxInputTypes.includes(fieldType)) {

  }
  @else if (fieldOperator === 'range' || fieldOperator === 'not range') {
    @if (textInputTypes.includes(fieldType) || dateInputTypes.includes(fieldType)) {
      <form class="value-input__form-group">
        <mat-form-field appearance="outline" class="form">
          <mat-label translate="TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__FROM"></mat-label>
          <input
            matInput
            class="input"
            type="text"
            [disabled]="disabled"
            [value]="getIntervalDisplayValue(0)"
            (focus)="$event.target.select()"
            (input)="intervalValue($event, null)"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="form">
          <mat-label translate="TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__TO"></mat-label>
          <input
            matInput
            class="input"
            type="text"
            [disabled]="disabled"
            [value]="getIntervalDisplayValue(1)"
            (focus)="$event.target.select()"
            (input)="intervalValue(null, $event)"
          />
        </mat-form-field>
      </form>
    }
    @else if (numberInputTypes.includes(fieldType)) {
      <form class="value-input__form">
        <mat-form-field appearance="outline" class="form grow">
          <mat-label translate="TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__FROM"></mat-label>
          <input
            matInput
            class="input"
            type="number"
            [disabled]="disabled"
            [value]="getIntervalDisplayValue(0)"
            (focus)="$event.target.select()"
            (input)="intervalValue($event, null)"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="form grow">
          <mat-label translate="TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__TO"></mat-label>
          <input
            matInput
            class="input"
            type="number"
            [disabled]="disabled"
            [value]="getIntervalDisplayValue(1)"
            (focus)="$event.target.select()"
            (input)="intervalValue(null, $event)"
          />
        </mat-form-field>
      </form>
    }
  }
  @else {
    <form class="value-input__form">
      @if (!selectInputTypes.includes(fieldType)) {
        <mat-form-field appearance="outline" class="form grow">
          @if (dateInputTypes.includes(fieldType)) {
            <input
              matInput
              class="input"
              type="text"
              [disabled]="disabled"
              [value]="value"
              (focus)="$event.target.select()"
              (input)="textValue($event)"
            />
          }

          @else if (numberInputTypes.includes(fieldType)) {
            <input
              matInput
              class="input"
              type="number"
              [disabled]="disabled"
              [value]="value"
              (focus)="$event.target.select()"
              (input)="textValue($event)"
            />
          }


          @else if (textInputTypes.includes(fieldType)) {
            <input
              matInput
              class="input"
              type="text"
              [disabled]="disabled"
              [value]="value"
              (focus)="$event.target.select()"
              (input)="textValue($event)"
            />
          }

          @else {
            <input
              disabled
              matInput
              type="text"
            />
          }

          @if (query() !== '') {
            <mat-icon class="icon" matSuffix (click)="clearValue()">close</mat-icon>
          }

          @if ((!textInputTypes.includes(fieldType) && !numberInputTypes.includes(fieldType) && !dateInputTypes.includes(fieldType)) && (fieldOperator !== 'like' && fieldOperator !== 'not like')) {
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
          }
        </mat-form-field>
      }
      @if(selectInputTypes.includes(fieldType) && (fieldOperator !== 'not in' && fieldOperator !== 'in' && fieldOperator !== 'contains' && fieldOperator !== 'not contains')) {
        <mat-form-field appearance="outline" class="form grow">
          <input
            autocomplete="off"
            matInput
            class="input"
            [placeholder]="'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__TYPE_TO_SEARCH' | translate"
            type="text"
            (blur)="hideDropdownOnCondition($event)"
            (focus)="showDropdown($event)"
            (input)="setQuery($event)"
            [disabled]="disabled"
            [ngClass]="{'line-through': query().includes('<del>')}"
            [value]="getSelectDisplayValue()"
          />
          @if (query() !== '') {
            <mat-icon class="icon" matSuffix (click)="clearValue()">close</mat-icon>
          }
          <mat-icon matSuffix>arrow_drop_down</mat-icon>
        </mat-form-field>
      }
      @else if (selectInputTypes.includes(fieldType) && (fieldOperator === 'not in' || fieldOperator === 'in' || fieldOperator === 'contains' || fieldOperator === 'not contains')) {
        <mat-form-field appearance="outline" class="form grow">
          <span class="display" matTextPrefix [innerHTML]="getSelectedDisplayArrayValue()">
          </span>

          <input
            autocomplete="off"
            matInput
            class="input"
            (blur)="hideDropdownOnCondition($event)"
            (focus)="showDropdown($event)"
            (input)="setQuery($event)"
            [disabled]="disabled"
            [placeholder]="'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__SEARCH_SELECT' | translate"
          />
          @if (value !== null) {
            <mat-icon class="icon" matSuffix (click)="clearValue()">close</mat-icon>
          }
          <mat-icon matSuffix>arrow_drop_down</mat-icon>
        </mat-form-field>
      }

      <div
        class="dropdown"
        [ngClass]="{'show': showSelector}"
      >
        <app-advanced-search-query-builder-options-view
          filterMode="parent"
          id="value-options-view"
          [filterQuery]="query"
          [multiselect]="fieldOperator === 'in' || fieldOperator === 'not in'"
          [selectedValue]="value"
          [showLimitDescription]="true"
          [options]="valueOptions"
          (arrayOptionDeselected)="removeArrayValue($event)"
          (arrayOptionSelected)="addArrayValue($event)"
          (optionSelected)="selectValue($event)"
        >
        </app-advanced-search-query-builder-options-view>
      </div>
    </form>
  }
}

@if (showSelector) {
  <!-- //NOSONAR --><div class="backdrop" (click)="hideDropdown()"></div>
}
