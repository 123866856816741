<ng-container [formGroup]="fieldParameters.sectionsContainer.formGroup">
  <mat-radio-group
    [formControlName]="formControlName"
    (change)="onChange($event)">
    @for (opt of getFieldOptions(); track opt) {
      <mat-radio-button
        [value]="opt.value"
        [checked]="opt.value === fieldParameters.object[fieldParameters.field.name]"
        class="radio-option">
        {{opt.label | translate}}
      </mat-radio-button>
    }
  </mat-radio-group>
</ng-container>
