export interface SearchCategoryField {
  fieldId: string;
  fieldName: string;
  indexField: string;
}

export class SearchCategoryData {
  public name: string;
  public selectedFields: Array<SearchCategoryField>;
}


export class SearchCategory {
  public categoryId: string;
  public hid: number;
  public userId: string;
  public private: boolean;
  public category: SearchCategoryData;

  public static parseQuery(query: string, category: SearchCategory): string {
    try {
      const queryStrings = (query || '').replace(',', ' ')
        .split(/\s/g)
        .map(SearchCategory.escapeSpecialChars);
      const fields = category.category.selectedFields
        .map(field => field.indexField.endsWith('_id') ? field.indexField + '_value' : field.indexField);

      return fields.map(index =>
        '(' + queryStrings.map(q => `${index}:*${q}*`).join( ' AND ') + ')')
        .join(' OR ');
    } catch (e) {
      console.error(`Unable to parse query '${query}' for SearchCategory`, category);
      console.error(e);
      return '""';
    }
  }

  /**
   * Escapes all character that are reserved by Solr
   * @param {string} value
   * @return {string}
   */
  private static escapeSpecialChars(value: string): string {
    return String(value).replace(/([+\-&|!(){}\[\]^"~*?:\/])/g, '\\$1');
  }

}
