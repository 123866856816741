import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class EventHarvesterService {
  private readonly _storage: any;
  private readonly _storageName: string = 'peh';
  private readonly _maxItems: number = 10;

  constructor(private cms: CmsApiService,
              private translate: TranslateService) {
    this._storage = window.localStorage;
  }

  /**
   * Adds a timestamp to the payload, required when saving the event data.
   * @param payload object
   * @private
   */
  private _addTimestamp(payload: any) {
    if (!payload.timestamp) {
      payload.timestamp = new Date().getTime();
    }
    return payload;
  }

  /**
   * Method used to fetch event data from local storage.
   * @private
   */
  private _getItems() {
    if (!this._storage) {
      return null;
    }
    const data = this._storage.getItem(this._storageName) || '[]';
    const items = JSON.parse(data);
    return !Array.isArray(items) ? [items] : items;
  }

  /**
   * Method used to push event data to the local storage for later bulk-operations.
   * @param eventData
   * @private
   */
  private _bulkAdd(eventData: any) {
    eventData = this._addTimestamp(eventData);
    const items = this._getItems();
    if (items !== null) {
      items.push(eventData);
      const data = JSON.stringify(items);
      this._storage.setItem(this._storageName, data);
      if (items.length >= this._maxItems) {
        this._bulkSave(); // Force saving if exceeded max. items.
      }
    }
  }

  /**
   * Method used to push event data in a bulk-operation to the eventharvest server.
   * @private
   */
  private _bulkSave() {
    const items = this._getItems();
    if (items !== null) {
      this.cms.eventSave(items).then(() => {
        this._storage.removeItem(this._storageName); // Clear storage when bulk has completed.
      }, () => {

      });
    }
  }

  /**
   * Method used to save event data to the local storage (for later bulk-operation) or directly
   * to the eventharvest server.
   * @param eventType
   * @param payload
   * @param force
   * @private
   */
  private _save(eventType: string, payload: any, force: boolean) {
    if (payload) {
      payload['eventType'] = eventType;
    } else {
      payload = {
        'eventType': eventType
      };
    }

    this._bulkAdd(payload); // Add event data to local storage

    if (force) {
      // If force, push all items in local storage to the eventharvest server.
      this._bulkSave();
    } else if (!this._storage) {
      // If no local storage is available, push event to the eventharvest server.
      payload = this._addTimestamp(payload);
      this.cms.eventSave([payload]).then();
    }
  }

  /**
   * Method used to save the logon event.
   */
  loggedOn() {
    this._save('logon', null, true);
  }

  /**
   * Method used to save the logoff event.
   */
  loggedOff() {
    this._save('logoff', null, true);
  }

  /**
   * Method used to save the file download event.
   * @param fileType
   * @param fileName
   */
  fileDownload(fileType: string, fileName: string) {
    const name = this.translate.instant(fileName);
    this._save('download' + fileType,
      {'name': name},
      false);
  }

  /**
   * Method used to save an event when the user clicks the FAQ item in the top menu.
   */
  faqClicked() {
    this._save('faqclicked', null, false);
  }

  /**
   * Method used to save information about a FAQ-category the user has clicked.
   * @param categoryName
   */
  faqCategoryClicked(categoryName: string) {
    this._save(
      'faqclicked',
      {'data': {'category': categoryName}},
      false);
  }
}
