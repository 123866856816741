import {Component, Input, OnChanges} from '@angular/core';
import {MetaTypes} from '../../core/definitions/meta-types';
import {SettingsService} from '../../core/settings.service';
import {ClientConfig} from "../../core/definitions/client-config";

@Component({
  selector: 'app-overview-title',
  templateUrl: './overview-title.component.html',
  styleUrls: ['./overview-title.component.scss']
})
export class OverviewTitleComponent implements OnChanges {

  @Input() objectView: any;
  @Input() noTitle: boolean;
  @Input() smallTit: boolean;
  @Input() titleAsLink: boolean;

  MetaTypes = MetaTypes;
  title: string;
  clientConfig: ClientConfig;

  constructor(private settingsService: SettingsService) { }

  ngOnChanges() {
    this.clientConfig = this.settingsService.getClientConfig();
    this.title = '';
    if (this.objectView.artifact_name && !this.noTitle) {
      this.title = this.objectView.artifact_name.replace('<del>', '').replace('</del>', '');
    }
  }

}
