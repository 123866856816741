import {Component, inject, OnInit, signal, ViewChild} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent, MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {AsyncPipe, JsonPipe} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {TemplateGroup} from '../../core/definitions/template-models';
import {MatFormField, MatInput, MatSuffix} from '@angular/material/input';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatLabel} from '@angular/material/form-field';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-select-object-template-modal',
  standalone: true,
  imports: [
    MatDialogContent,
    JsonPipe,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatAutocomplete,
    MatOption,
    MatInput,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatButton,
    MatFormField,
    MatLabel,
    AsyncPipe,
    MatSuffix,
    MatIconButton,
    TranslateModule
  ],
  templateUrl: './select-object-template-modal.component.html',
  styleUrl: './select-object-template-modal.component.scss'
})
export class SelectObjectTemplateModalComponent implements OnInit {

  data: { templateGroups: TemplateGroup[]; selectedTemplateGroup?: TemplateGroup } = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  formControl = new FormControl<any>('');
  filteredOptions: Observable<TemplateGroup[]> | undefined;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor() {
  }

  ngOnInit(): void {
      this.filteredOptions = this.formControl.valueChanges.pipe(
        startWith(''),
        map((value: any) => {
          if (typeof value === 'string')  {
            return this._filter(value);
          }
          return this.data.templateGroups;
        })
      )
    if (this.data.selectedTemplateGroup) {
      this.formControl.setValue(this.data.templateGroups.find((templateGroup) => templateGroup.template_group_id === this.data.selectedTemplateGroup?.template_group_id));
    }
  }

  displayFn(templateGroup: TemplateGroup): string {
    return templateGroup && templateGroup.group_name ? templateGroup.group_name : '';
  }

  clear() {
    this.formControl.setValue(null);
  }

  confirmSelection() {
    const selected = this.formControl.getRawValue();
    if (typeof selected === 'string') {
      this.dialogRef.close(undefined);
    } else {
      this.dialogRef.close(selected);
    }
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  private _filter(value: string): TemplateGroup[] {
    const filterValue = value.toLowerCase();
    return this.data.templateGroups?.filter(option => option.group_name.toLowerCase().includes(filterValue));
  }

  isSelected(option: TemplateGroup) {
    if (this.formControl.value && typeof this.formControl.value === 'object') {
      return option.template_group_id === this.formControl.value.template_group_id;
    }
  }
}
