<div class="operation-menus-two-levels">
  <div *ngFor="let operation of operations">
    <button
      mat-stroked-button
      color="primary"
      type="button"
      [disabled]="operationContainer.rootSectionsContainer?.rootObject.read_only"
      (click)="openOperation(operation)">
      <i class="{{operation.menu_icon}} menuItem-icon"></i>
      <span class="bold uppercase" [translate]="operation.menu_title"></span>
    </button>

    <div *ngIf="operation.$$showOperationMenu">
      <button
        *ngFor="let subOperation of operation.sub_operations"
        mat-stroked-button
        color="primary"
        type="button"
        (click)="openOperation(subOperation)">
        <i class="{{subOperation.menu_icon}} menuItem-icon"></i>
        <span class="bold uppercase" [translate]="subOperation.menu_title"></span>
      </button>

    </div>
  </div>
</div>
