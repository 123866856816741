import {Component, EventEmitter, Inject, Input, LOCALE_ID, Output, signal} from '@angular/core';
import {MatButton, MatFabButton, MatIconButton} from '@angular/material/button';
import {MatFormField, MatLabel, MatPrefix, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {PrimusInstanceDetails} from '../../core/primus-backend-instance.service';
import {MatRipple} from '@angular/material/core';
import {
  RecentRegistration,
  RecentRegistrationsListComponent
} from './recent-registrations-list/recent-registrations-list.component';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';

const DEFAULT_NUMBER_OF_LIST_ITEMS = 7;

export interface SearchSuggestion {
  id: string;
  label: string;
  path: string;

  [key: string]: any;
}

export interface ListItem {
  id: string;
  label: string;
  count?: number;
  icon?: string;

  searchSuggestions?: SearchSuggestion[];

  [key: string]: any;
}

@Component({
  selector: 'app-welcome-screen-ui',
  standalone: true,
  imports: [MatButton, MatFormField, MatIcon, MatInput, MatLabel, MatMenu, MatMenuItem, MatMenuTrigger, MatPrefix, MatSuffix, CommonModule, MatTooltip, MatProgressSpinner, MatIconButton, TranslateModule, MatFabButton, MatRipple, RecentRegistrationsListComponent, MatButtonToggleGroup, MatButtonToggle,],
  templateUrl: './welcome-screen-ui.component.html',
  styleUrl: './welcome-screen-ui.component.scss'
})
export class WelcomeScreenUiComponent {
  @Input() savedSearches: ListItem[] = [];
  @Input() objectList: ListItem[] = [];
  @Input() categories: ListItem[] = [];
  @Input() loadingSavedSearches = false;
  @Input() loadingObjectList = false;
  @Input() loadingCategories = false;
  @Input() canUseAdvancedSearch = false;
  @Input() museumInstance: PrimusInstanceDetails | null = null;
  @Input() loadingRecentRegistrations = false;
  @Input() recentRegistrations: RecentRegistration[] = [];
  @Input() onlyMyOwnRegistrations = false;

  @Output() advancedSearchClick = new EventEmitter<any>();
  @Output() listItemClick = new EventEmitter<ListItem>();
  @Output() searchSubmit = new EventEmitter<{ query: string; }>();
  @Output() editFocusesClick = new EventEmitter<void>();
  @Output() searchSuggestionClick = new EventEmitter<SearchSuggestion>();
  @Output() recentRegistrationClick = new EventEmitter<RecentRegistration>();
  @Output() onlyMyOwnRegistrationsChange = new EventEmitter<boolean>();

  numberOfFocuses = signal(DEFAULT_NUMBER_OF_LIST_ITEMS);
  numberOfArtifacts = signal(DEFAULT_NUMBER_OF_LIST_ITEMS);
  protected readonly DEFAULT_NUMBER_OF_LIST_ITEMS = DEFAULT_NUMBER_OF_LIST_ITEMS;

  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  onAdvancedSearchClick() {
    this.advancedSearchClick.emit();
  }

  onListItemClick(item: ListItem) {
    this.listItemClick.emit(item);
  }

  onShowMoreFocuses() {
    this.numberOfFocuses.set(this.savedSearches.length);
  }

  onShowMoreArtifacts() {
    this.numberOfArtifacts.set(this.objectList.length);
  }

  onShowLessFocuses() {
    this.numberOfFocuses.set(DEFAULT_NUMBER_OF_LIST_ITEMS);
  }

  onShowLessArtifacts() {
    this.numberOfArtifacts.set(DEFAULT_NUMBER_OF_LIST_ITEMS);
  }

  onSearchSubmit($e: Event, form: HTMLFormElement) {
    $e.preventDefault();
    $e.stopPropagation();
    const formData = new FormData(form);
    this.searchSubmit.emit({
      query: formData.get('query') as string
    });
  }

  onEditFocusesClick() {
    this.editFocusesClick.emit();
  }

  onSearchSuggestionClick($event: MouseEvent, suggestion: SearchSuggestion) {
    $event.preventDefault();
    $event.stopPropagation();
    this.searchSuggestionClick.emit(suggestion);
  }

  onRecentRegistrationClick(registration: RecentRegistration) {
    this.recentRegistrationClick.emit(registration);
  }

  onOnlyMyOwnRecentRegistrationsChange({ value }: { value: boolean }) {
    this.onlyMyOwnRegistrationsChange.emit(value);
  }
}
