<div class="primus-menu-button-wrapper">
  <button mat-stroked-button
          class="admin-stroked-button"
          type="button"
          *ngIf="menuButtonLabel || !menuButtonIcon"
          [disabled]="disabled"
          [matMenuTriggerFor]="menu.menu">
    <mat-icon *ngIf="menuButtonIcon">{{ menuButtonIcon }}</mat-icon>
    <span class="bold uppercase admin-stroked-button__left-margin">{{ menuButtonLabel | translate }}</span>
  </button>

  <button mat-icon-button
          class="admin-stroked-button"
          type="button"
          *ngIf="menuButtonIcon && !menuButtonLabel"
          [color]="color"
          [disabled]="disabled"
          [matMenuTriggerFor]="menu.menu">
    <mat-icon>{{ menuButtonIcon }}</mat-icon>
  </button>

  <app-primus-menu-button-item #menu
                               [color]="color"
                               [items]="menuItems"
                               [selectionState]="selectionGroups"
                               (menuItemClicked)="handleMenuItemClicked($event)">
  </app-primus-menu-button-item>

</div>
