export enum OperationTarget {
  ADMIN_LIST_VIEW = 'admin_list_view',
  CONTENT_LIST_VIEW = 'content_list_view',
  CREATE_OBJECT_VIEW = 'create_object_view',
  DROPDOWN_MENU_VIEW = 'dropdown_menu_view',
  FOLDER_SEARCH_VIEW = 'folder_search_view',
  INDEX_VIEW = 'index_view',
  INFORMATION_VIEW = 'information_view',
  KULTUR_NAV_FOLDER_IMPORT = 'kultur_nav_folder_import',
  MAIN_MENU_VIEW = 'main_menu_view',
  OPERATION_TARGET_MASS_MEDIA_IMPORT_PRE_OPS_VIEW = 'mass_media_import_pre_ops_view',
  NEW_OBJECT_VIEW = 'new_object_view',
  OBJECT_NEW_PAGE = 'object_new_page',
  OBJECT_VIEW = 'object_view',
  PROCEDURE_VIEW = 'procedure_view',
  SEARCH_HEADER_VIEW = 'search_header_view',
  SEARCH_VIEW = 'search_view',
}
