<div [formGroup]="sectionsContainer.formGroup"
     class="edit-field-inline-array"
     [id]="field.name">
  <div class="field-level-{{field['array_level']}}">

    <div class="edit-field-inline-array__field-title">
      <app-field-title
        [displayRequiredIndication]="displayRequiredIndication"
        [field]="field"
        [rootObject]="sectionsContainer.rootObject"
        [edit]="true"
        [editable]="true"
        [showToolTip]="true"
      ></app-field-title>
    </div>
    <app-edit-field-inline-array-item-list
      [items]="items"
      [disabled]="isDisabled"
      [fieldParameters]="fieldParameters"
      (fieldOutsideTheScreen)="onFieldOutsideTheScreen()"
    ></app-edit-field-inline-array-item-list>

    <div class="edit-field-inline-array__add-button">
      <app-edit-field-inline-array-add-button
        [sectionsContainer]="sectionsContainer"
        [field]="field"
        [object]="object"
        [parentIndex]="parentIndex"
        [disabled]="isDisabled"
      ></app-edit-field-inline-array-add-button>
    </div>
  </div>
</div>
