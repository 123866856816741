import {Component, EventEmitter, Input, OnChanges, Output, Renderer2} from '@angular/core';
import {ConceptsContainer} from '../../../core/definitions/concepts-container';

@Component({
  selector: 'app-admin-list-query',
  templateUrl: './admin-list-query.component.html',
  styleUrls: ['./admin-list-query.component.scss']
})
export class AdminListQueryComponent implements OnChanges {

  @Input() conceptsContainer: ConceptsContainer;
  @Input() hideIcon: boolean;
  @Input() setFocus;

  @Output() queryChange = new EventEmitter<string>();

  showMenu = false;
  mediumScreen: boolean;
  queryTimeoutId;
  queryMessage = '';


  constructor(private readonly renderer: Renderer2) {
  }

  ngOnChanges() {
    if (this.setFocus) {
      setTimeout(() => {
        const element = this.renderer.selectRootElement('#searchQuery');
        element.focus();
      }, 500);
    }
  }

  queryChanged() {
    if (this.queryTimeoutId) {
      clearTimeout(this.queryTimeoutId);
      this.queryTimeoutId = undefined;
    }
    this.queryTimeoutId = setTimeout(() => {
      this.search();
    }, 500);
  }

  submit() {
    if (this.conceptsContainer.conceptsParams.query && this.conceptsContainer.conceptsParams.query.length < 2) {
      this.queryMessage = 'TRANS__CONCEPT_QUERY__QUERY_TOO_SHORT';
    } else {
      this.search();
    }
  }

  clearSearch() {
    this.conceptsContainer.conceptsParams.query = '';
    this.queryChanged();
  }


  private search() {
    this.queryChange.emit(this.conceptsContainer.conceptsParams.query);
  }


}
