import {Injectable, isDevMode} from '@angular/core';
import {codeToToken, login, refreshToken} from '@ekultur/authentication';
import {BehaviorSubject, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {AccessTokenService} from './access-token.service';
import {LoggerService} from './logger.service';


export interface ApplicationResponseApplication {
  id: string;
}
export interface ApplicationResponseMuseum {
  applications: ApplicationResponseApplication[];
  id: string;
}
export interface ApplicationResponse {
  museums: ApplicationResponseMuseum[]
}

export interface MuseumResponseMuseum {
  id: string;
  name: string;
  short_name: string;
  profile: string;
  logo: string;
}
export interface MuseumResponse {
  museums: MuseumResponseMuseum[]
}

export interface PrimusServerUrl {
  url: string;
  museumId: string;
}
export interface PrimusInstanceDetails {
  id: string;
  name: string;
  shortName: string;
  api: string;
  online: boolean;
  profile: string;
  logo: string;
  checked: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PrimusBackendInstanceService {

  constructor(private logger: LoggerService,
              private accessTokenService: AccessTokenService,
  ) {
    this.loginURL = environment.ekulturLoginGatewayURL;
    this.apiURL = environment.ekulturAPIGatewayURL;
    this.appID = environment.ekulturPrimusAppID;
  }

  responseStatusSubject = new Subject();

  private static readonly logID = '[EKAuthService]';

  private static STORAGE_KEY = 'primus.client.main';
  private readonly loginURL: string;
  private readonly apiURL: string;
  private readonly appID: string;
  private apiAuthenticated: boolean;
  private userAuthenticated: boolean;

  authenticated: BehaviorSubject<any> = new BehaviorSubject(false);
  authError: BehaviorSubject<string> = new BehaviorSubject('');

  static getApiUrl(): string {
    return this.getInstanceDetails()?.api;
  }

  static cleanInstanceDetails() {
    window.localStorage.removeItem(this.STORAGE_KEY);
  }

  static getInstanceDetails(): PrimusInstanceDetails {
    return JSON.parse(window.localStorage.getItem(this.STORAGE_KEY));
  }

  static saveInstanceDetails(museum: PrimusInstanceDetails) {
    window.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(museum));
  }

  private static getCode(url: string) {
    const re = new RegExp(/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i);
    const matches = RegExp(re).exec(url);
    if (matches === null || matches.length < 1) {
      throw new Error(`${PrimusBackendInstanceService.logID} -- Unable to retrieve auth. code.`);
    }
    return matches[0];
  }

  private static getState(url: string) {
    const re = /(?=.)([0-9A-Za-z]*)$/;
    const matches = RegExp(re).exec(url);
    if (matches === null || matches.length < 1) {
      throw new Error(`${PrimusBackendInstanceService.logID} -- Unable to retrieve auth. state.`);
    }
    return matches[0];
  }

  setApiAuthenticated(state: boolean) {
    this.apiAuthenticated = state;
  }

  async setRefreshTokenSetAuthenticated() {
    try {
      if (localStorage.getItem('kit.generatingReport') && localStorage.getItem('kit.id_token') && localStorage.getItem('kit.access_token')) {
        this.userAuthenticated = true;
      } else {
        this.userAuthenticated = await this.setRefreshToken();
      }

      this.broadcastAuthStatus();
    } catch (e) {
      this.userAuthenticated = false;
      this.broadcastAuthStatus();
      // If token code => token exchange failed, redirect user to login.
      this.eCultureLogin();
    }
  }

  async setRefreshToken(): Promise<boolean> {
    console.log('----- setRefreshToken', window.location.href);
    const url = window.location.href;
    if (url.indexOf('/oauth2/callback') > -1) {
      return this.setJWTToken(url);
    } else {
      return this.eCultureRefreshToken();
    }
  }

  async fetchApiLogin(authUrl: string, token: string) {
    fetch(authUrl, {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      method: 'GET',
    })
      .then(response => {

        // Feed new value to Subject.
        this.responseStatusSubject.next(response);

        if (!response.ok) {
          if (response?.status === 403) {
            throw Error(`${PrimusBackendInstanceService.logID} -- API-authentication failed, missing privileges`);
          } else if (response.status === 401) {
            window.alert('Not authenticated! You will be redirected to login')
            this.apiAuthFailedRedirectToLogin();
          } else if (response?.status !== 200) {
            throw Error(`${PrimusBackendInstanceService.logID} -- API-authentication failed`);
          }
        } else {
          this.setApiAuthenticated(true);
          this.broadcastAuthStatus();
          if (isDevMode()) {
            this.logger.debug(`${PrimusBackendInstanceService.logID} -- API authenticated`);
          }
        }
      }).catch(e => {
        this.logger.error('fetchApiLogin - failed: ', e);
        this.setApiAuthenticated(false);
        this.broadcastAuthStatus();
    });
  }

  apiAuthFailedRedirectToLogin() {
    PrimusBackendInstanceService.cleanInstanceDetails();
    this.setApiAuthenticated(false);
    setTimeout(() => {
      login({
        locationPostLogin: null,
        redirectUri: null,
        loginHost: environment.ekulturLoginGatewayURL
      });
    }, 500);
  }

  apiAuthenticate(museum: PrimusInstanceDetails) {
    const self = this;
    if (!museum) {
      this.broadcastAuthStatus();
    }

    PrimusBackendInstanceService.saveInstanceDetails(museum);

    try {
      const authUrl = `${museum.api}/cms_api/v1.0/login`;
      const token = this.accessTokenService.getToken();
      this.fetchApiLogin(authUrl, token).then().catch(reason => {
        self.setApiAuthenticated(false);
        if (isDevMode()) {
          this.authError.next('API-authentication failed, retrying...');
        }
        this.broadcastAuthStatus();
        this.logger.error('Error fetching api login', reason);
      });
    } catch (e) {
      self.setApiAuthenticated(false);
      if (isDevMode()) {
        this.authError.next('API-authentication failed, retrying...');
      }
      this.broadcastAuthStatus();
      throw e;
    }
  }

  async getUserPrimusMuseumIds() {
    try {
      const headers = this.getAPIAuthHeader();
      const user = await this.getECultureUser();
      const userId = user['uniqueId'];
      const url = `${this.apiURL}/authz/users/${userId}/applications/`;
      const response = await fetch(url, {
        headers: headers
      });
      const data: ApplicationResponse = await response.json();
      return data?.museums?.filter(m => m.applications.find(a => a.id === this.appID))
        .map(m => m.id);
    } catch (e) {
      this.logger.error(`${PrimusBackendInstanceService.logID} -- ${e}`);
      this.authError.next('Failed to fetch organizations');
    }
    return null;
  }

  async getUserPrimusServers(): Promise<PrimusInstanceDetails[]> {
    const museumIds = await this.getUserPrimusMuseumIds();
    const museums = await this.getECultureMuseums();
    const serverUrls = await this.getPrimusServerUrls();

    const availableUrls =
      serverUrls.filter(u => museumIds.includes(u.museumId) && u.url !== '')
        .map(u => {
          return {'url': u.url, 'museumId': u.museumId};
        });

    const availableMuseums = museums.filter(m => museumIds.includes(m.id)).map(m => {
      return {'id': m.id, 'name': m.name, 'shortName': m.shortName, 'profile': m.profile, 'logo': m.logo} as PrimusInstanceDetails;
    });

    const userMuseums: PrimusInstanceDetails[] = availableMuseums
      .filter(m => museumIds.includes(m.id))
      .map(m => {
        return {
          'id': m.id,
          'name': m.name,
          'shortName': m.shortName,
          'profile': m.profile,
          'logo': m.logo,
          'api': availableUrls.find(url => url.museumId === m.id)?.url
        } as PrimusInstanceDetails;
      })
      .filter(um => um.api);

    if (isDevMode() || window.location.port === '8080') {
      userMuseums.push({
        name: 'Localhost',
        shortName: 'DEV',
        api: 'http://localhost:5000',
        logo: '',
        id: '1'
      } as PrimusInstanceDetails);
    }

    return userMuseums;
  }

  private async getECultureMuseums(): Promise<PrimusInstanceDetails[]> {
    const url = `${this.apiURL}/museum-api/museums`;
    const response = await fetch(url, {});
    const data: MuseumResponse = await response.json();
    return data.museums.map(m => {
      return {
        'id': m.id,
        'name': m.name,
        'shortName': m.short_name,
        'profile': (m.profile ? `https://${m.profile}`: ''),
        'logo': (m.logo ? `https://${m.logo}` : ''),
      } as PrimusInstanceDetails;
    });
  }

  private async getPrimusServerUrls(): Promise<PrimusServerUrl[]> {
    const headers = this.getAPIAuthHeader();
    const url = `${this.apiURL}/app-registry/urls/?appId=${this.appID}`;
    const response = await fetch(url, {
      headers: headers
    });
    return response.json();
  }

  private async getECultureUser() {
    const url = `${this.apiURL}/auths/oauth2/userInfo`;
    const headers = this.getAPIAuthHeader();
    if (headers) {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (!response?.ok) {
        throw new Error(`${PrimusBackendInstanceService.logID} -- failed to load user`);
      }
      return response.json();
    } else {
      throw new Error(`${PrimusBackendInstanceService.logID} -- failed to load user invalid credentials`);
    }
  }

  private getAPIAuthHeader() {
    const token = this.accessTokenService.getToken();
    if (token) {
      return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
    } else {
      return null;
    }
  }

  private setJWTToken(url: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (url.indexOf('code') > -1) {
        try {
          const code = PrimusBackendInstanceService.getCode(url);
          const state = PrimusBackendInstanceService.getState(url);
          const sameSite = this.apiURL.indexOf('primus.org') !== -1 && !url.startsWith('http://localhost');
          // Exchange received code with token.
          codeToToken({
            code: code,
            state: state,
            sameSite: sameSite,
            redirectUri: null,
            apiGateway: this.apiURL,
            onSuccess: (data) => {
              console.log('------ codeToToken ------');
              console.log(data);
              setTimeout(() => {
                resolve(true);
              }, 100);
            },
            onError: (err: any) => {
              this.logger.error(`${PrimusBackendInstanceService.logID} -- Exchanging code for token failed: ${err}`);
              resolve(false);
            }
          });
        } catch (e) {
          this.logger.error(`${PrimusBackendInstanceService.logID} -- ${e}`);
          reject(e);
        }
      }
    });
  }

  private broadcastAuthStatus() {
    this.authenticated.next({user: this.userAuthenticated, api: this.apiAuthenticated});
  }

  private eCultureRefreshToken(): Promise<boolean> {
    console.log( '-------- eCultureRefreshToken()');
    return new Promise<boolean>((resolve, reject) => {
      refreshToken({
        apiGateway: this.apiURL,
        onSuccess: () => {
          setTimeout(() => {
            resolve(true);
          }, 200);
        },
        onError: (error: any) => {
          this.logger.error('Error refreshing token', error);
          reject(error);
        }
      });

    });
  }

  private eCultureLogin() {
    login({
      locationPostLogin: null,
      redirectUri: null,
      loginHost: this.loginURL
    });
  }
}
