<div class="authentication-container normal-bg-color" *ngIf="!isAuthenticated()">
  <div class="authentication-container__auth-error-container">


    <div class="authentication-container__auth-error" *ngIf="authErr !== ''">
      <mat-icon>error</mat-icon>
      <span>{{authErr}}</span>
      <button mat-flat-button color="warn" (click)="this.redirectToLogin()">
        {{'TRANS__APP_COMPONENT__BACK_TO_LOGIN' | translate}}
      </button>
    </div>
  </div>

  <!-- Primus loader -->
  <div id="loader" class="authentication-container__loading-container"
    *ngIf="!showApiSelector && !isAuthenticated() && !hideLoader && authErr === ''">
    <div class="authentication-container__loading">
      <div class="authentication-container__spinner-container">
        <mat-spinner color="primary" diameter="50" id="awaiting-authentication"
          class="authentication-container__spinner"></mat-spinner>
        <div><span>{{'TRANS__APP_COMPONENT__LOADING_CONTENTS' | translate}}</span>...</div>
      </div>
    </div>
  </div>

</div>
