<div class="field-select-input"
     [class.fieldSelectOpen]="inFocus.value">
  <label class="clearInputContainer"
         [ngClass]="fieldParameters.sectionsContainer.isDialog ?
     (fieldParameters.sectionsContainer.isSecondDialog ? 'ignoreClicksSecondModal-' + fieldParameters.field.name :
     'ignoreClicksModal-' + fieldParameters.field.name) :
     'ignoreClicks-' + fieldParameters.field.name">
    <input type="text"
           [id]="fieldKey"
           [name]="fieldKey"
           [placeholder]="'&#xe62c;&nbsp;' + placeHolderSearchFor + placeholder"
           [(ngModel)]="query.value"
           [ngModelOptions]="{standalone: true}"
           [disabled]="isDisabled"
           (keyup)="onInputKey($event)"
           (keydown)="onInputKeyDown($event)"
           (click)="refreshAndShowOptions()"
           (focus)="onFieldSelectFocus()"
           [ngClass]="{'errorInputFrame': !showOptions.show && fieldValidation.isFieldInvalid(sectionsContainer, fieldKey)}"
           tabindex="0"
           autocomplete="off" #fieldSelectInput>
    @if (query.value && !reference.no_clear) {
      <a class="clearInputField"
         (click)="clearField()"
         type="button">
        <mat-icon>close</mat-icon>
      </a>
    }
    @if (!isArray && query.valueWithTag && query.valueWithTag === textValue) {
      <span class="selected-concept-in-input"> <!-- If single select -->
        @if (!fieldParameters.object[temporaryFieldValueName]) {
          <span>{{ textValue }}</span>
        } @else {
          <span
            [ngClass]="{'withAuthority': fieldParameters.object[temporaryFieldValueName].indexOf('concept-name') !== -1}"
            [innerHtml]="fieldParameters.object[temporaryFieldValueName]"></span>
        }
        </span>
    } @else if (isArray && isSingleItemArray && query.valueWithTag === textValue) {
      <span class="selected-concept-in-input"> <!-- If isSingleItemArray  -->
        @if (!fieldArray[fieldArray.length - 1][temporaryFieldValueName]) {
          <span>{{ textValue }}</span>
        } @else {
          <span [innerHtml]="fieldArray[fieldArray.length -1][temporaryFieldValueName]"></span>
        }
        </span>
    }
  </label>
  <span class="field-select-input__dropdown-button"
        [ngClass]="{'open': showOptions.show}"
        (click)="toggleShowOptions(undefined)">
        <span class="vertical-line"></span>
       <mat-icon>expand_more</mat-icon>
  </span>
</div>
