import {Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CurrentObjectContext} from '../current-object.context';
import {ObjectViewModule} from '../../object-view/object-view.module';
import {M3GlobalKplStylesComponent} from '../../m3-global-kpl-styles/m3-global-kpl-styles.component';

@Component({
  selector: 'app-object-page-v2-changelog',
  standalone: true,
  imports: [
    TranslateModule,
    ObjectViewModule,
    M3GlobalKplStylesComponent,
  ],
  templateUrl: './object-page-v2-changelog.component.html',
  styleUrl: './object-page-v2-changelog.component.scss'
})
export class ObjectPageV2ChangelogComponent {

  constructor(private currentObjectContext: CurrentObjectContext) {
  }

  get object() {
    return this.currentObjectContext.art
  }

}
