import {Component, Input, OnChanges} from '@angular/core';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationStep} from '../../core/definitions/operation-step';
import {Selector} from '../../core/definitions/reference';
import {animate, style, transition, trigger} from '@angular/animations';
import {OperationService} from '../operation.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {OperationContainer} from '../../core/definitions/operation-container';

@Component({
  selector: 'app-operation-view-section-container',
  templateUrl: './operation-view-section-container.component.html',
  styleUrls: ['./operation-view-section-container.component.scss'],
  animations: [
    trigger('slideContent', [
      transition(':enter', [
        style({overflow: 'hidden',  height: '0px'}),
        animate('1000ms linear', style({height: '*'}))
      ])
    ])
  ]
})
export class OperationViewSectionContainerComponent implements OnChanges {
  @Input() operationContainer: OperationContainer;
  @Input() operationStepIndex: number;
  @Input() isDialog: boolean;

  operation: OperationDef;
  operationStep: OperationStep;
  sectionsContainers: SectionsContainer[];

  constructor(private operationService: OperationService) { }

  ngOnChanges(): void {
    this.setOperationStep().then();
  }

  async setOperationStep() {
    this.operation = this.operationContainer.currentOperation;
    await this.operationService.setOperationStep(this.operationContainer);
    this.operationStep = this.operation.$$currentStep;
    this.sectionsContainers = this.operationService.getCurrentOperationSectionContainers(this.operationContainer);
    if (this.isDialog && this.sectionsContainers) {
      this.sectionsContainers[0].isDialog = true;
      this.sectionsContainers[0].hideSectionTitle = true;
    }
  }

  enableSelector(selector: Selector) {
    if (this.isDialog) {
      this.operationContainer.inModal = true;
    }
    this.operationContainer.enableSelector(selector);
  }

}
