import {Injectable} from '@angular/core';
import {AnnotationHandler} from '../image-annotation/annotation-handler';
import {ModelFactoryService} from './model-factory.service';
import {ObjectDeletionService} from './object-deletion.service';
import {UiToolsService} from './ui-tools.service';
import {LoginService} from './login.service';
import {DateToolsService} from './date-tools.service';
import {CrudService} from "./crud.service";

@Injectable({
    providedIn: 'root'
})
export class CurrentObjectService {

    get isEditing(): boolean {
        return this._isEditing;
    }

    set isEditing(value: boolean) {
        this._isEditing = value;
    }

    get currentObject() {
        return this._currentObject;
    }

    set currentObject(value) {
        this._currentObject = value;
    }

    get curAnn(): AnnotationHandler {
        if (!this._curAnn) {
            this._curAnn = new AnnotationHandler(
                this.isEditingCallback,
                this.modelFactory,
                this.objectDeletionService,
                this.uiTools,
                this.loginService,
                this.dateToolsService,
                this.crud);
        }
        return this._curAnn;
    }

    set curAnn(value: AnnotationHandler) {
        this._curAnn = value;
    }

    private _isEditing = false;
    private _currentObject: any;
    private _curAnn: AnnotationHandler;

    constructor(private modelFactory: ModelFactoryService,
                private objectDeletionService: ObjectDeletionService,
                private uiTools: UiToolsService,
                private loginService: LoginService,
                private dateToolsService: DateToolsService,
                private crud: CrudService) {
    }

    isEditingCallback = (isEditing: boolean) => {
        this.isEditing = isEditing;
    }
}
