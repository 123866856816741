import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {CmsApiService} from '../../../core/cms-api.service';
import {AConst} from '../../../core/a-const.enum';
import {OverviewFieldsFromIdsParams} from '../../../core/definitions/overview-fields-from-ids-params';
import {OverviewFieldsFromIdsRes} from '../../../core/definitions/overview-fields-from-ids-res';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SearchResultSelectionsService} from '../../search-result-selections.service';

@Component({
  selector: 'app-search-selection-basket',
  templateUrl: './search-selection-basket.component.html',
  styleUrls: ['./search-selection-basket.component.scss']
})
export class SearchSelectionBasketComponent implements OnChanges {
  @Input() selectedCount;
  @Input() searchContainer: SearchContainer;
  @Output() removeSelectionItem = new EventEmitter<object>();
  @Output() removeAllItems = new EventEmitter<object>();

  selectedItems: Array<OverviewFieldsFromIdsRes>;
  AConst = AConst;
  loading = false;
  start = 0;
  rows = 8;


  constructor(private cms: CmsApiService,
              private searchResultSelectionsService: SearchResultSelectionsService) {
  }

  ngOnChanges() {
    this.setRowCount();
    this.setOverviewFields().then();
  }

  private async setOverviewFields() {
    this.loading = true;
    this.selectedItems = [];
    if (this.searchContainer.selections.selectedItems.length > 0) {
      const itemsSlice = this.searchContainer.selections.selectedItems.slice(this.start, this.start + this.rows);
      const itemIds = itemsSlice.map(item => this.searchResultSelectionsService.getItemId(item));
      const cmsParams = {
        object_ids: itemIds,
        template_group_id: this.searchContainer.templateGroupId,
        combine_horizontal: true
      } as OverviewFieldsFromIdsParams;
      this.selectedItems = await this.cms.getOverviewFieldsFromIds(cmsParams);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  remove(art) {
    this.removeSelectionItem.emit(art);
  }

  removeAll() {
    this.removeAllItems.emit();
  }

  async prev() {
    if (this.start >= this.rows) {
      this.start -= this.rows;
      await this.setOverviewFields();
    }
  }

  async next() {
    if (this.start + this.rows < this.searchContainer.selections.selectedItems.length) {
      this.start += this.rows;
      await this.setOverviewFields();
    }
  }

  private setRowCount() {
    this.rows = Math.max(Math.round((window.innerHeight - 360) / 48), 1);
  }
}
