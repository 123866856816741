import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';

@Component({
  selector: 'app-object-view-and-edit',
  templateUrl: './object-view-and-edit.component.html',
  styleUrls: ['./object-view-and-edit.component.scss']
})
export class ObjectViewAndEditComponent {
  @Input() sectionsContainer: SectionsContainer;
  @Input() templateGroupId: string;
  @Input() isEditing: boolean;
  @Output() closeEdit = new EventEmitter<any>();

  onCloseEdit(): void {
    this.closeEdit.emit();
  }

}
