<button mat-icon-button
        class="action-icon-in-table text-blue-hover"
        [matMenuTriggerFor]="menu"
        [disabled]="!menus.length"
        aria-label="Example icon-button with a menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let conceptMenu of menus"
          (click)="conceptMenu.action(concept)"
          [disabled]="conceptMenu.disabled()">
    <mat-icon>{{conceptMenu.icon}}</mat-icon>
    <span [translate]="conceptMenu.title"></span>
  </button>
</mat-menu>
