import {ContentMenuObjectSourceList, SortField} from './content-menus';
import {ContentListBaseContainer} from './content-list-base-container';
import {SearchObject} from '../search-object';
import {CheckFilterGroup} from '../search-objects';
import {OperationContainer} from '../operation-container';
import {SuperObjectModel} from '../super-object-model';
import {SearchContainer} from '../search-container';
import {ObjectView} from '../object-view';

export class ContentListItem {
  fields: Array<any>;
  object: SearchObject;
  showExpandButton = false;
  expanded = false;

  constructor(fields: Array<any>, object: any) {
    this.fields = fields;
    this.object = object;
  }
}

export class ContentListSourceContainer implements ContentListBaseContainer {
  array: SearchObject[];
  displayAll = false;
  items: ContentListItem[] = [];
  caption: string;
  relationSearchField: string;
  relatedObjectIdField: string;
  objectType: string;
  maxRows = 5;
  ownerObject;
  page = 1;
  relatedMetaTypes: string[];
  relatedObjectType: string;
  relatedObjectContextIdField;
  displayRelatedObject: boolean;
  sortFields: SortField[];
  filterGroups: CheckFilterGroup[];
  operationContainer: OperationContainer;
  order: SortField;
  parentObject: SuperObjectModel;
  fieldName: string;
  sourceArrayFilters: any;
  relatedObjects: {[id: string]: ObjectView} = {};
  deleteTargetModel: string;
  deleteTargetModelArrayName: string;
  deleteContextMapParentToChild: string[];
  totalPageItems: ContentListPageItem[] = [];
  pageItems: ContentListPageItem[] = [];
  searchContainer: SearchContainer;
  subMenus: ContentListSourceContainer[] = [];
  refreshCallback;

  constructor(menu: ContentMenuObjectSourceList,
              ownerObject: SuperObjectModel) {
    this.fieldName = menu.source_array;
    this.sourceArrayFilters = menu.source_array_filters;
    this.relatedObjectIdField = menu.related_object_id_field;
    this.relationSearchField = menu.relation_search_field;
    this.caption = menu.caption;
    this.objectType = menu.object_type;
    this.ownerObject = ownerObject;
    this.relatedMetaTypes = menu.related_meta_types;
    this.relatedObjectType = menu.related_object_type;
    this.relatedObjectContextIdField = menu.related_object_context_id_field;
    this.displayRelatedObject = menu.display_related_object;
    this.sortFields = menu.sort_fields;
    if (this.sortFields) {
      this.order = this.sortFields[0];
    }
    this.deleteTargetModel = menu.delete_target_model;
    this.deleteTargetModelArrayName = menu.delete_target_model_array_name;
    this.deleteContextMapParentToChild = menu.delete_context_map_parent_to_child;
  }
}

export class ContentListPageItem {
  listItem: ContentListItem;
  relatedObject: ObjectView;
  checked = false;
  thumbnailInfo = new SearchObject();

  constructor(listItem: ContentListItem, relatedObject: ObjectView, checked: boolean) {
    this.listItem = listItem;
    this.relatedObject = relatedObject;
    this.checked = checked;
  }
}

