export enum OperationExecutionStatus {
  DOWNLOADED = 'downloaded',
  FAILED = 'failed',
  FINISHED = 'finished',
  IN_PROGRESS = 'in progress',
  NO_DATA = 'no data',
  OPERATION_COMPLETE = 'operation complete',
  QUEUED = 'queued',
  REGISTERED = 'registered',
  STEP_COMPLETE = 'step complete'
}
