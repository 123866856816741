import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {CurrentObjectContext} from '../current-object.context';
import {ObjectContentTabModule} from '../../object-content-tab/object-content-tab.module';
import {M3GlobalKplStylesComponent} from '../../m3-global-kpl-styles/m3-global-kpl-styles.component';

@Component({
  selector: 'app-object-page-v2-subpage',
  standalone: true,
  imports: [
    ObjectContentTabModule,
    M3GlobalKplStylesComponent
  ],
  templateUrl: './object-page-v2-subpage.component.html',
  styleUrl: './object-page-v2-subpage.component.scss'
})
export class ObjectPageV2SubpageComponent {
  constructor(
    private route: ActivatedRoute,
    private currentObjectContext: CurrentObjectContext) {

    this.route.paramMap.subscribe(p => {
      this.currentObjectContext.setCurrentList(p.get("segment"))
    });

  }

  segment = toSignal(this.route.paramMap.pipe(map(p => p.get("segment"))))

  contentInfo = this.currentObjectContext.contentInfo

}
