import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ModelFactoryService} from '../../core/model-factory.service';
import {Option, OptionInfo} from '../../core/definitions/option-info';
import {FieldOptionsService} from '../../core/field-options.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {ValueOptionService} from "../../core/value-option.service";

@Component({
  selector: 'app-edit-field-check-array',
  templateUrl: './edit-field-check-array.component.html',
  styleUrls: ['./edit-field-check-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldCheckArrayComponent)
    }
  ]
})
export class EditFieldCheckArrayComponent implements OnInit, ControlValueAccessor {

  @Input() fieldParameters: FieldParameters;
  @Input() formControl;

  checkField;
  options: Option[];
  private onChange;
  private optionsInitialized = false;

  constructor(private readonly modelFactory: ModelFactoryService,
              private fieldOptionsService: FieldOptionsService,
              private valueOptionService: ValueOptionService) {
  }

  ngOnInit() {
    this.fieldOptionsService.initOptions(this.fieldParameters).then(() => {
      this.optionsInitialized = true;
    });
  }

  getOptions(): Option[] {
    if (!this.optionsInitialized) {
      return [];
    }
    const valueOptions = this.valueOptionService.getValueOptionsForField(this.fieldParameters.field);
    if (this.options && valueOptions.$$hasNoConditions) {
      return this.options;
    }
    const field = this.fieldParameters.field;
    const optionInfo: OptionInfo = this.valueOptionService.getValueOptionsForField(field);
    this.checkField = optionInfo.inline_check_field;
    this.options = this.fieldParameters.object[field.name];
    const options = this.getFieldOptions(this.fieldParameters);
    if (options) {
      for (const [index, opt] of options.entries()) {
        if (typeof this.options[index] === 'undefined') {
          this.options[index] = opt;
        } else {
          this.options[index].$$label = opt.$$label;
        }
      }
    } else {
      throw new Error('Check array requires options');
    }
    return this.options;
  }

  toggleItem($event, item) {
    $event.preventDefault();
    item[this.checkField] = !item[this.checkField];
    if (item['$$isAllToggle']) {
      for (const option of this.options) {
        option[this.checkField] = item[this.checkField];
      }
    }
    this.formControl.markAsDirty();
  }

  private getFieldOptions(fieldParameters: FieldParameters): any[] {
    let res = [];
    const field = fieldParameters.field;
    const optionInfo: OptionInfo = this.valueOptionService.getValueOptionsForField(field);
    const valueField = optionInfo.inline_value_field;
    const checkField = optionInfo.inline_check_field;
    const modelName = field.inline ? field.inline.model : null;
    const options = this.fieldOptionsService.getOptions(fieldParameters);
    if (modelName) {
      for (const opt of options) {
        const modelData = {};
        if (valueField) {
          modelData[valueField] = opt.value;
        }
        if (checkField) {
          modelData[checkField] = false;
        }
        const modelItem = this.modelFactory.createModelItem(modelName, modelData);
        modelItem['$$isAllToggle'] = opt['is_all_toggle'];
        modelItem['$$label'] = opt.label;
        modelItem['$$description'] = opt.description;
        res.push(modelItem);
      }
    } else {
      res = options;
    }
    return res;
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  setDisabledState(/*isDisabled: boolean*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }

}
