<div class="admin-agent">
  <app-top-header [parent]="parent"
                  backRouterLink="/admin"
                  backRouterLinkLabel="TRANS__SECTION__ADMIN__TITLE"
                  class="admin-header-container"></app-top-header>
  <app-header-and-description heading="TRANS__ADMIN_AGENT__INFORMATION_TITLE"
                              description="TRANS__ADMIN_AGENT__INFORMATION_DESCRIPTION">
    <app-admin-list
      *ngIf="conceptsContainer"
      [conceptsContainer]="conceptsContainer"
      [menuTitle]="'TRANS__ADMIN_AGENT__MENU_TITLE'"
    ></app-admin-list>
  </app-header-and-description>
</div>
