import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ObjectMediaContainer, ObjectMediaType} from '../../core/definitions/object-media-container';

@Component({
  selector: 'app-thumbnail-stack',
  templateUrl: './thumbnail-stack.component.html',
  styleUrls: ['./thumbnail-stack.component.scss']
})
export class ThumbnailStackComponent implements OnChanges {
  invalidSrc = {};
  @Input() mediaContainer: ObjectMediaContainer;
  @Input() mediaType: ObjectMediaType;
  @Input() isEditing: boolean;
  @Input() imageGallery;
  @Output() setActiveImg = new EventEmitter<object>();

  constructor() {
  }

  ngOnChanges() {
  }

  setMedia(index) {
    const mediaIndex = {
      index: index
    };
    this.setActiveImg.emit(mediaIndex);
  }

  setInvalidSrc(index) {
    this.invalidSrc[index] = true;
  }

}
