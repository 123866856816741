import { Component, Input, OnInit } from '@angular/core';
import { PrimusInstanceDetails } from "../../core/primus-backend-instance.service";

@Component({
  selector: 'app-museum-logo',
  templateUrl: './museum-logo.component.html',
  styleUrls: ['./museum-logo.component.scss']
})
export class MuseumLogoComponent implements OnInit {
  @Input() server: PrimusInstanceDetails;
  initials = '';

  ngOnInit(): void {
    this.initials = this.server.shortName.substring(0, 3);
  }
}
