import {Model} from "../Model";

export class ReportViewType implements Model {
  id: string;
  loading: Promise<Model> | undefined;
  status: string;
  name: string;
  viewChoices: string;
  reportViewType: string;
  templateCode: string;
  templateCodePerObject: string;
}
