<div class="admin-concept-query-concept-types">
  <form [formGroup]="queryConceptTypeForm" (ngSubmit)="submitQuery()">
    <mat-form-field appearance="outline" color="primary">
      <mat-label><span translate="TRANS__ADMIN_CONCEPT__QUERY_CONCEPT_TYPES"></span></mat-label>
      <input id="code" matInput [formControl]="queryConceptTypeControl" autocomplete="off">
      <button mat-icon-button
              matSuffix
              aria-label="clear"
              type="button"
              class="clear-icon"
              (click)="queryConceptTypeControl.setValue('')"
              *ngIf="queryConceptTypeControl && queryConceptTypeControl.value">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button
              matSuffix
              class="search-icon"
              type="button"
              (click)="submitQuery()">
        <mat-icon matSuffix color="accent">search</mat-icon>
      </button>
    </mat-form-field>

  </form>
</div>
