import { AfterViewInit, Component, isDevMode, OnInit } from '@angular/core';
import { environment } from "../../environments/environment";
import { logout } from '@ekultur/authentication';
import { PrimusBackendInstanceService, PrimusInstanceDetails } from "../core/primus-backend-instance.service";
import { ClientConfig } from "../core/definitions/client-config";
import { CmsQueueService } from "../core/cms-queue.service";
import { CmsApiService } from "../core/cms-api.service";
import { PrimusRouteService } from "../core/primus-route.service";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "../core/commons.service";
import { PrimusRouterService } from "../core/primus-router.service";

@Component({
  selector: 'app-authenticator',
  templateUrl: './authenticator.component.html',
  styleUrls: ['./authenticator.component.scss']
})
export class AuthenticatorComponent implements OnInit {
  public authErr: string;
  public hideLoader: boolean;
  public showApiSelector: boolean;
  public txtToLogin: string;
  private apiAuthRetryMaxCount = 10;
  private apiAuthRetryCounter = 0;
  private apiAuthRetryMs = 100;
  private authenticating = false;
  private authState = {
    user: false,
    api: false
  }
  private languageSet = false;
  private loginComplete: boolean;
  private txtApiAuthenticationFailed: string;

  constructor(
    private cms: CmsApiService,
    private cmsQueue: CmsQueueService,
    private commons: CommonsService,
    private readonly primusBackendInstanceService: PrimusBackendInstanceService,
    private primusRoute: PrimusRouteService,
    private primusRouter: PrimusRouterService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.primusBackendInstanceService.authError.subscribe(err => {
      this.showApiSelector = false;
      this.authErr = err;
    });

    this.registerAuthSubscriber();
  }

  isAuthenticated() {
    if (this.authState.user && this.authState.api && this.loginComplete) {
      this.primusRouter.navigateState('home');
    }
    return false;
  }

  redirectToLogin = () => {
    logout({
      signal: null,
      apiGateWay: environment.ekulturAPIGatewayURL,
      onLogout: () => {
        PrimusBackendInstanceService.cleanInstanceDetails();
        window.location.replace( '/home');
      }
    })
  }

  private registerAuthSubscriber() {
    this.primusBackendInstanceService.authenticated.subscribe(async (state) => {
      if (!state.user && !state.api) {
        return;
      }

      /* eslint-disable no-console */
      if (isDevMode()) {
        console.debug(`[APP] -- user authenticated: ${state.user}`);
        console.debug(`[APP] -- api authenticated: ${state.api}`);
        console.debug(`[APP] -- api auth failed: ${this.authErr}`);
      }
      /* eslint-enable no-console */

      this.authState = state;
      const museum = PrimusBackendInstanceService.getInstanceDetails();

      const showApiSelector = state.user && !state.api && !museum && !this.authenticating;
      const retry = (!state.user || !state.api || this.authenticating || this.authErr !== '') && !showApiSelector;
      const runInit = state.user && state.api && museum && !showApiSelector;

      if (retry) {
        this.showApiSelector = false;
      }

      if (showApiSelector) {
        this.showApiSelector = showApiSelector;
        this.authenticating = true;
        this.apiAuthRetryCounter++;
      } else if (retry) {
        this.retryApiAuth();
      } else if (typeof runInit !== 'undefined') {
        this.hideLoader = true;
        // this.init();
      }
      try {
        await this.setLanguageAndObjectTypes();
      } catch (e) {
        this.authErr = e.error?.message || 'An unknown error occurred';
      }
    });
  }

  private retryApiAuth() {
    const museum = PrimusBackendInstanceService.getInstanceDetails();
    if (this.apiAuthRetryCounter > this.apiAuthRetryMaxCount) {
      // API-authentication failed after multiple retries, inform the user.
      this.authErr = `${museum.name} - ${this.txtApiAuthenticationFailed}`;
      this.hideLoader = true;
      this.showApiSelector = false;
      this.authenticating = false;
    } else {
      try {
        if (this.apiAuthRetryCounter > 0) {
          if (isDevMode()) {
            console.log(`[APP] -- Retrying API-auth in ${this.apiAuthRetryMs}, attempt: ${this.apiAuthRetryCounter}`);
          }
          setTimeout(() => {
            this.primusBackendInstanceService.apiAuthenticate(museum);
          }, this.apiAuthRetryMs);
        } else {
          this.primusBackendInstanceService.apiAuthenticate(museum);
        }
        this.apiAuthRetryCounter++;
      } catch (e) {
        console.error(e);
      }
    }
  }

  private async setLanguageAndObjectTypes(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.authState?.api) {
        this.cmsQueue.runCmsFnWithQueue(this.cms.getClientConfig, undefined, false,
          (data: ClientConfig) => {
            // eslint-disable-next-line no-console
            if (isDevMode()) {
              console.debug('[APP] -- cms.getClientConfig() complete');
            }
            if (this.primusRoute.params.lang) {
              this.setTranslationLanguage(this.primusRoute.params.lang)
            } else {
              this.setTranslationLanguage(data.CLIENT_LANGUAGE);
            }
            this.commons.setObjectTypes(data.OBJECT_TYPES);
            this.setSomeTranslations();
            resolve();
          },
          e => {
            reject(e);
          });
      }
    });
  }

  private setSomeTranslations() {
    if (!this.languageSet) {
      let lang = 'no';
      if (navigator.language.toLowerCase().includes('sv')) {
        lang = 'sv';
      }
      this.translate.use(lang);
    }

    this.translate.get('TRANS__APP_COMPONENT__AUTHENTICATION_FAILED').subscribe(s => {
      this.txtApiAuthenticationFailed = s;
    });

    this.translate.get('TRANS__APP_COMPONENT__BACK_TO_LOGIN').subscribe(s => {
      this.txtToLogin = s;
    });
  }

  private setTranslationLanguage(language) {
    this.translate.use(language);
    this.languageSet = true;
  }
}

