<div class="annotation-shape-menu" [ngClass]="{'isDialog': isDialog}">
  <div class="medium-12 columns annotation-shape-menu__button-container">
    <mat-form-field appearance="fill" class="annotation-shape-menu__select">
      <span matPrefix class="annotation-shape-menu__icon-container text-blue"
            [ngClass]="{'disabled': !curAnn.state.edit}">
        <i class="icon-{{curAnn.annotationType}} annotation-shape-menu__icon"></i></span>
      <mat-select [disabled]="!curAnn.state.edit"
                  [matTooltip]="'TRANS__ANNOTATE_MENU__DISABLED_TOOLTIP' | translate"
                  [matTooltipDisabled]="curAnn.state.edit">
        <mat-option *ngFor="let aType of curAnn.aTypes"
                    class="annotation-shape-menu__option"
                    (click)="curAnn.selectAnnotationType(aType.type)"
                    [value]="aType.type"
                    [ngClass]="{'selected': aType.type === curAnn.annotationType}">
          <span class="{{aType.type}} annotation-shape-menu__option-button"
                [class.bg-white]="aType.type !== curAnn.annotationType"
                [class.bg-blue]="aType.type === curAnn.annotationType"></span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="medium-12 columns annotation-shape-menu__text-container">
    <span class="annotation-shape-menu__text" translate="TRANS__ANNOTATE_MENU__SHAPE"></span>
  </div>
</div>
