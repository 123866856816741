export enum FieldInputType {
  ACTION_BUTTON = 'action_button',
  CHECK_ARRAY = 'check_array',
  CHECKBOX = 'checkbox',
  COMPARE_VALUE = 'compare_value',
  CONTEXT = 'context',
  DATE_ISO = 'date_iso',
  DATE_TIME_ISO = 'date_time_iso',
  DATE_TIME_ISO_RANGE = 'date_time_iso_range',
  HIERARCHIC_SELECT = 'hierarchic_select',
  IDENTIFIER = 'identifier',
  IMAGE = 'image',
  INLINE = 'inline',
  INLINE_ARRAY = 'inline_array',
  INPUT = 'input',
  MAP_ID = 'map_id',
  META_OPERATION_FIELD = 'meta_operation_field',
  NUMBER = 'number',
  OBJECT_USAGE = 'object_usage',
  PASSWORD = 'password',
  RADIO_INLINE_ARRAY = 'radio_inline_array',
  RADIO_OPTION = 'radio_option',
  REF_ARRAY = 'ref_array',
  SEARCH_SELECTOR = 'search_selector',
  SEARCH_SELECTOR_MULTIPLE = 'search_selector_multiple',
  TEXT_AREA = 'text_area',
}
