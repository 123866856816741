<div class="object-overview-fields" [ngClass]="{'edit': isEditing}">

  <app-overview-title [objectView]="objectView" [noTitle]="noTitle"></app-overview-title>

  <div *ngFor="let field of fields; index as $index"
       class="row object-overview-fields__item"
       [ngClass]="{'border': $index < 5}">

    <div class="object-overview-fields__field">
      <div class="object-overview-fields__field-title"><!--overview-field-title-->
        <div class="overview-field-title-content">{{field.title}}:</div>
      </div>

      <div class="object-overview-fields__field-content"><!--field-content-->
        <app-overview-field
          [overviewField]="field"
          [viewName]="'object-overview'"
          [disableLinks]="disableLinks"
          [toneDownTextColor]="false"
        ></app-overview-field>
      </div>
    </div>

  </div>

  <div *ngIf="errMsg">
    <span [translate]="errMsg" class="error"></span>
  </div>

</div>
