import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef, OnInit
} from '@angular/core';
import {ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";

@Component({
  selector: 'report-picture-with-overview',
  templateUrl: './report-picture-with-overview.component.html',
  styleUrls: ['./report-picture-with-overview.component.scss']
})
export class ReportPictureWithOverviewComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() reportTemplate: ReportUserGenerated;
  @Input() extractedArtifacts: any;
  @Input() viewOptionsCheckboxValues: any;
  @Input() preview: boolean = false;

  constructor(private renderer: Renderer2,
              private cdRef: ChangeDetectorRef) {

  }

  async ngOnInit(): Promise<void> {

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewChecked() {

  }
}
