<div class="faq-sidebar">
  <div class="faq-sidebar__header-container">
    <span translate="TRANS__FAQ__TITLE"></span>

    <button mat-icon-button (click)="closeFaqSidebar.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="faq-sidebar__ingress-container">
    <span translate="TRANS__FAQ__INGRESS"></span>
    <a href="mailto:support@kulturit.no">support&commat;kulturit.no</a>
  </div>

  <div class="faq-sidebar__category-container">
    <mat-accordion>
      @for (category of faqData(); track $index; let first=$first) {
        <mat-expansion-panel [expanded]="first" id="faq-expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>{{category.category_title}}</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="faq-sidebar__category-container__item-container">
            @for (faq of category.faq; track $index) {
              <div class="faq-sidebar__category-container__item">
                <span class="faq-sidebar__category-container__item__question">{{faq.question}}</span>
                <span class="faq-sidebar__category-container__item__answer">{{faq.answer}} </span>
                @if (faq.url) {
                  <a class="faq-sidebar__category-container__item__url" target="_blank" href="{{faq.url}}">{{faq.url}}</a>
                }
              </div>
            }
          </div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
</div>
