import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef, OnInit
} from '@angular/core';
import {ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() reportTemplate: ReportUserGenerated;
  @Input() extractedArtifacts: any;
  @Input() viewOptionsCheckboxValues: any;
  @Input() preview: boolean = false;
  reportHeaderColumns : string[] = [];

  constructor(private renderer: Renderer2,
              private cdRef: ChangeDetectorRef) {

  }

  async ngOnInit(): Promise<void> {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.extractedArtifacts && this.extractedArtifacts && this.extractedArtifacts.length > 0) {
      console.log('------');
      console.log(this.extractedArtifacts);
      //count the number of extractedArtifacts.sections.field there are
      this.reportHeaderColumns = [];
      this.extractedArtifacts[0].sections?.forEach(section => {
        section.fields.forEach(field => {
          this.reportHeaderColumns.push(field.key)
        })
      });
      console.log('NUM FIELDS SELECTED: ', this.reportHeaderColumns.length);
      this.cdRef.detectChanges();
    }
  }

  ngAfterViewChecked() {

  }
}
