export interface AdvFieldQueryOperator {
  operator: '=' | '<>' | '>=' | '<=' | 'like' | 'not like' | 'empty' | 'not empty' | 'range' | 'not range' | 'in' | 'not in' | 'true' | 'false';
  operatorTitle: string;
  forFieldTypes: string[];
}

export type AdvFieldQueryLogicalOperator = 'AND' | 'OR' | 'NOT' | null;

export interface AdvFieldQuery {
  parent_field_ids: string[];
  relation_superobject_type_id: string | null;
  field_title: string;
  field_name: string;         // Obtain from AdvancedSearchField.field_name
  path: string;               // Obtain from AdvancedSearchField.path
  input_type: string;         // Obtain from AdvancedSearchField.input_type
  reference_id: string;       // Obtain from AdvancedSearchField.reference_id
  is_array_field: boolean;    // Obtain from AdvancedSearchField.is_array_field
  // Context fields needs only to be set for relation object fields, and only for the root query
  context_field: string;
  is_array_context_field: boolean;
  // Child document type must be set for inline array fields
  child_document_type: string | null;
  // Obtain using method AdvancedSearchToolsService.getOperatorsForFieldType using AdvancedSearchField.input_type
  operators: AdvFieldQueryOperator[];
  // The operator selected by the user
  operator_selected: '=' | '<>' | '>=' | '<=' | 'like' | 'not like' | 'empty' | 'not empty' | 'range' | 'not range' | 'in' | 'not in' | 'true' | 'false';
  value: any;                 // The query value selected by the user
  valueDisplay: string | string[];       // The display value pf selected value by the user

  // For relation fields, this must be the same as the superobject_type_id in AdvancedSearchField.superobject_type_id
  superobject_type_id: string;
  valid: boolean;
}

export class AdvFieldQueryGroup {
  relation_superobject_type_id: string | null;
  superobject_type_id: string;
  child_document_type: string | null;
  header: string;
  field_queries: AdvFieldQuery[];
  logical_operator: AdvFieldQueryLogicalOperator;
  field_logical_operator: AdvFieldQueryLogicalOperator;
  sub_groups: AdvFieldQueryGroup[];
  level: number;
}

export class AdvancedSearchParams {
  superobject_type_ids: string[];
  field_queries: AdvFieldQuery[];
  query_groups: AdvFieldQueryGroup[];
  db_search: boolean;
}

export class SearchSuggestion {
  id: string
  object_types: string[]
  label: string
  advancedSearchParams: AdvancedSearchParams
}
