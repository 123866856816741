import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';

import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {PrimusFormGroupService} from '../../core/primus-form-group.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {FieldValueService} from '../../core/field-value.service';
import {FieldActionService} from '../../shared/field-action.service';
import {CommonsService} from '../../core/commons.service';
import {MetaField} from '../../core/definitions/meta-field';
import {TemplateGroups, TemplateModel, TemplateModels} from '../../core/definitions/template-models';
import {BaseModel} from '../../core/definitions/base-model';
import {SolrFilterService} from '../../core/solr-filter.service';
import {UserCacheService} from '../../core/user-cache.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {LoggerService} from '../../core/logger.service';
import {Subscription} from 'rxjs';
import {ModelsService} from '../../core/models.service';
import {FieldParameters} from "../../core/definitions/field-parameters";

@Component({
  selector: 'app-admin-template-model',
  templateUrl: './admin-template-model.component.html',
  styleUrls: ['./admin-template-model.component.scss']
})
export class AdminTemplateModelComponent implements OnInit, OnDestroy {

  AConst = AConst;
  sectionsContainers = {};
  parent;
  templateModel: TemplateModel = null;
  debug;
  createDisabled: boolean;
  templateModels: TemplateModels;
  actionButtons = [];
  actionButtonFields: Array<MetaField> = [];
  templateGroups;
  templateGroupName;
  templateGroupId;

  private adminType;
  private curStateName = 'template-model';
  private routerSubscription: Subscription;

  constructor(private primusRouter: PrimusRouterService,
              private primusRoute: PrimusRouteService,
              private logger: LoggerService,
              private cms: CmsApiService,
              private formGroupService: PrimusFormGroupService,
              private objectEditService: ObjectEditService,
              private fieldValueService: FieldValueService,
              private fieldActionService: FieldActionService,
              private commons: CommonsService,
              private solrFilter: SolrFilterService,
              private userCacheService: UserCacheService,
              private modelsService: ModelsService) {
  }

  ngOnInit() {
    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      this.init().then();
    });
    this.init().then();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  async disableTemplateModel() {
    await this.primusRouter.navigateState('template-model', {
      objectType: this.adminType,
      template_group_id: this.templateGroupId,
      model_type_id: null
    });
  }

  async addTemplateModel() {
    const fieldParameters = new FieldParameters();
    fieldParameters.object = this.templateModels;
    fieldParameters.field = this.templateModels.$$meta.template_models;
    const newModel = this.fieldValueService.createAddItem(fieldParameters, {
      template_group_id: this.primusRoute.params['template_group_id']
    });
    newModel['$$isEditing'] = true;
    await this.setModelHelperProps(newModel);
    this.createDisabled = true;
  }

  async getGroupName(templateGroupId) {
    const data = await this.cms.getMetaObject({
      objectType: 'TemplateGroups'
    });
    if (data) {
      const item = <TemplateGroups>await this.objectEditService.createModelItem('TemplateGroups', data);
      this.templateGroups = item;
      for (const group of item.template_groups) {
        if (group.template_group_id === templateGroupId) {
          this.templateGroupName = group.group_name;
        }
      }
    }
  }

  getModelIsEditing(model: TemplateModel) {
    return !!model.$$isEditing || !this.sectionsContainers[model.$$uuid]['rootObject']['artifact_id'];
  }

  onActionRun(actionButton: MetaField) {
    if (actionButton.name === 'store_fields') {
      // Purging model section cache in order to load model section with changes to template models
      this.userCacheService.purgeModelSections();
    }
  }

  private async init() {
    this.templateGroupId = this.primusRoute.params['template_group_id'];
    if (!this.templateGroupId) {
      // This happens when going back to template group component for some reason
      return;
    }
    this.templateModel = null;
    this.adminType = this.primusRoute.params['admin_type'];
    this.parent = {
      path: 'template-group',
      adminType: this.adminType,
      hasSubMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.curStateName
    };

    const modelTypeId = this.primusRoute.params['model_type_id'];
    this.debug = this.primusRoute.params['debug'];
    this.createDisabled = true;
    await this.getGroupName(this.templateGroupId);
    const data = await this.cms.getMetaObject({
      objectType: 'TemplateModels',
      template_group_id: this.templateGroupId,
      model_type_id: modelTypeId
    });
    if (data) {
      const templateModels = <TemplateModels>await this.objectEditService.createModelItem('TemplateModels', data);
      for (const item of templateModels.template_models) {
        await this.setModelHelperProps(item);
      }
      templateModels.template_models = this.commons.orderArray(
        templateModels.template_models, AConst.ORDER_NUMBER, true);
      this.templateModels = templateModels;
      this.createDisabled = false;
      if (modelTypeId) {
        await this.setTemplateModel(modelTypeId, this.templateGroupId);
      }
    }
  }

  private getActionButtonFields(): Array<MetaField> {
    const fields: Array<MetaField> = [];
    for (const actionButton of this.actionButtons) {
      fields.push(actionButton['field']);
    }
    return fields;
  }

  private async setTemplateModel(modelTypeId, templateGroupId) {
    this.createDisabled = true;
    const data = await this.cms.getMetaObject({
      objectType: 'TemplateModel',
      template_group_id: templateGroupId,
      model_type_id: modelTypeId
    });
    const modelName = this.getModelName(modelTypeId);
    const item = <TemplateModel>await this.objectEditService.createModelItem(
      'template_model_' + modelName, data);
    await this.setFormSections(item, true);
    this.templateModel = item;
    this.templateModel.name = modelName;
    this.actionButtons = this.getActionButtons();
    this.actionButtonFields = this.getActionButtonFields();
  }

  // Get model name using concept search
  private getModelName(superObjectTypeId: string): string {
    let modelName;
    const objectTypes = this.modelsService.getObjectTypesFromSuperObjectTypeIds([superObjectTypeId]);
    const objectType = objectTypes[superObjectTypeId];
    if (objectType) {
      modelName = objectType;
    } else {
      throw new Error('Model name not found');
    }
    return modelName;
  }

  private getActionButtons() {
    let res = [];
    if (this.templateModel) {
      res = this.fieldActionService.getActionButtonsFromModel(this.templateModel, 'header');
    }
    return res;
  }

  private async setModelHelperProps(model) {
    await this.setFormSections(model, false, 'admin');
    model.$$mockObject = new BaseModel();
    model.$$mockObject.object_type = model.object_type;
    if (model.model_type_id) {
      model.$$mockObject.artifact_id = model.model_type_id;
    } else {
      model.$$mockObject.artifact_name = 'TRANS__TEMPLATE_GROUP__SELECT_MODEL';
    }
  }

  private async setFormSections(item, isTemplate: boolean, viewMode?: string) {
    const sc = await this.formGroupService.getSectionsAndFormGroup(item);
    // sc.hideSectionTitle = true;
    sc.isTemplate = isTemplate;
    sc.viewMode = viewMode;
    item.$$uuid = this.commons.uuid();
    this.sectionsContainers[item.$$uuid] = sc;
  }

}
