import {Component, EventEmitter, Input, Output, signal, WritableSignal} from '@angular/core';

import {AdvancedSearchToolsService} from "../../../../core/advanced-search-tools.service";
import {SearchObject} from "../../../../core/definitions/search-object";

@Component({
  selector: 'app-advanced-search-query-builder-value-input',
  templateUrl: './advanced-search-query-builder-value-input.component.html',
  styleUrl: './advanced-search-query-builder-value-input.component.scss'
})
export class AdvancedSearchQueryBuilderValueInputComponent {
  @Input() disabled: boolean;
  @Input() fieldType: string;
  @Input() fieldOperator: string;
  @Input() query: WritableSignal<string> = signal('');
  @Input() value: boolean | string | string[] | number | null = null;
  @Input() valueDisplay = null;
  @Input() valueIsValid: boolean = true;
  @Input() valueOptions: null | WritableSignal<SearchObject[]> = null

  @Output() arrayValueInput = new EventEmitter();
  @Output() intervalValueInput = new EventEmitter();
  @Output() queryChanged = new EventEmitter();
  @Output() valueCleared = new EventEmitter();
  @Output() valueInput = new EventEmitter();
  @Output() valueSelected = new EventEmitter();

  inputTimeout = null;
  isSearching: boolean = false;
  showSelector: boolean = false;

  checkboxInputTypes: string[] = [
    this.advancedSearchTools.FIELD_TYPE_CHECKBOX
  ];

  dateInputTypes: string[] = [
    this.advancedSearchTools.FIELD_TYPE_DATE
  ];

  numberInputTypes: string[] = [
    this.advancedSearchTools.FIELD_TYPE_NUMBER
  ];

  selectInputTypes: string[] = [
    this.advancedSearchTools.FIELD_TYPE_MAP_ID,
    this.advancedSearchTools.FIELD_TYPE_REF_ARRAY,
    this.advancedSearchTools.FIELD_TYPE_SEARCH_SELECTOR_MULTIPLE,
    this.advancedSearchTools.FIELD_TYPE_SEARCH_SELECTOR
  ]

  textInputTypes: string[] = [
    this.advancedSearchTools.FIELD_TYPE_INPUT,
    this.advancedSearchTools.FIELD_TYPE_TEXT,
    this.advancedSearchTools.FIELD_TYPE_IDENTIFIER
  ];

  constructor(
    private advancedSearchTools: AdvancedSearchToolsService
  ) {}

  clearValue() {
    this.query.set('');
    this.valueCleared.emit();
  }

  getIntervalDisplayValue(index) {
    if (!Array.isArray(this.value)) {
      return '';
    }
    else {
      return this.value[index];
    }
  }

  getSelectDisplayValue() {
    return this.query().replace('<del>', '').replace('</del>', '');
  }

  getSelectedDisplayArrayValue() {
    let display = '';

    this.valueDisplay?.forEach((val, index) => {
      if (index === this.valueDisplay.length - 1) {
        display += val.artifact_name + '&nbsp;';
      }
      else {
        display += val.artifact_name + ', ';
      }
    });

    return display;
  }

  getValueLength(): number {
    if (!Array.isArray(this.valueDisplay)) {
      return 0;
    }
    else {
      return this.valueDisplay.length;
    }
  }

  hideDropdown() {
    this.showSelector = false;
  }

  hideDropdownOnCondition(event) {
    event.preventDefault();

    setTimeout(() => {
      if (!document.activeElement.closest('#value-options-view')) {
        this.showSelector = false;
        if (this.fieldOperator === 'in' || this.fieldOperator === 'not in') {
          event.target.value = '';
        }
      }
    }, 1);
  }

  addArrayValue(event) {
    let temp = Array.isArray(this.valueDisplay) ? this.valueDisplay : [];
    // @ts-ignore
    temp.push(event);

    this.arrayValueInput.emit(temp);
  }

  removeArrayValue(event) {
    let temp =  Array.isArray(this.valueDisplay) ? this.valueDisplay : [];
    let index = temp.findIndex(i => i.artifact_id === event.artifact_id);

    if (index >= 0) {
      if (temp.length <= 1) {
        temp = [];
      }
      else {
        // @ts-ignore
        temp.splice(index, 1);
      }
    }

    this.arrayValueInput.emit(temp);
  }

  intervalValue(value1 = null, value2 = null) {
    this.intervalValueInput.emit([value1, value2]);
  }

  selectValue(event) {
    this.query.set(event.artifact_name || event.$$name);
    this.valueSelected.emit(event);
    this.showSelector = false;
  }

  setQuery(event) {
    if (this.inputTimeout !== null) {
      clearTimeout(this.inputTimeout);
    }

    this.isSearching = true;

    this.inputTimeout = setTimeout(() => {
      this.query.set(event.target.value);
      this.queryChanged.emit(event);
    }, 500);
  }

  setDivQuery(event) {
    if (this.inputTimeout !== null) {
      clearTimeout(this.inputTimeout);
    }

    this.isSearching = true;

    this.inputTimeout = setTimeout(() => {
      this.query.set(event.target.innerHTML);
      this.queryChanged.emit(event);
    }, 500);
  }

  showDropdown(event, valueIsChild = false) {
    if (valueIsChild) {
      let range = document.createRange();
      range.selectNodeContents(event.target);

      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
    else {
      event.target.select();
    }
    this.showSelector = true;
  }

  textValue(event) {
    if (this.inputTimeout !== null) {
      clearTimeout(this.inputTimeout);
    }

    this.inputTimeout = setTimeout(() => {
      this.query.set(event.target.value);
      this.valueInput.emit(event);
    }, 200);
  }
}
