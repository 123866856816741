import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-message-and-notifications',
  templateUrl: './message-and-notifications.component.html',
  styleUrls: ['./message-and-notifications.component.scss']
})
export class MessageAndNotificationsComponent implements OnInit {

  constructor() { }

  @Input() notifications;
  @Input() currentIndex;

  ngOnInit(): void {
  }

  previousMessage() {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.notifications.length - 1;
    }
    return this.currentIndex;
  }

  nextMessage() {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex >= this.notifications.length) {
      this.currentIndex = 0;
    }
    return this.currentIndex;
  }
  goToMessage(index) {
    this.currentIndex = index;
  }
}
