import {Component, Input, OnInit} from '@angular/core';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {CmsApiService} from '../../core/cms-api.service';
import {ObjectUsage} from '../../core/definitions/object-usage';
import {SearchReferenceService} from "../../core/search-reference.service";

@Component({
  selector: 'app-object-usage-field',
  templateUrl: './object-usage-field.component.html',
  styleUrls: ['./object-usage-field.component.scss']
})
export class ObjectUsageFieldComponent implements OnInit {

  @Input() fieldParameters: FieldParameters;

  objectUsages: ObjectUsage[] = [];
  someAreUsed = false;
  lastShowUsage: ObjectUsage;

  constructor(private cms: CmsApiService,
              private searchReferenceService: SearchReferenceService) {
  }

  ngOnInit(): void {
    this.setObjectUsage().then();
  }

  async setObjectUsage() {
    const reference = this.searchReferenceService.getSearchReferenceFromField(this.fieldParameters.field);
    const objectUsageRefProps = reference.ref_prop.split('.');
    const parentValue = objectUsageRefProps.length > 1 ?
      this.fieldParameters.rootObject[objectUsageRefProps[0]] : this.fieldParameters.rootObject;
    const parentValues = Array.isArray(parentValue) ? parentValue : [parentValue];
    const subProp = objectUsageRefProps[objectUsageRefProps.length - 1];
    const objectUsages: ObjectUsage[] = parentValues.filter(value => value[subProp] !== null).map(
      value => {
        return {
          objectId: value[subProp],
          objectName: value[`${subProp}_value`] || value.artifact_name
        } as ObjectUsage;
      });
    if (objectUsages.length) {
      const usages = await this.cms.getConceptUsage({
        artifact_ids: objectUsages.map(usageObject => usageObject.objectId), writable_usages_only: false});
      for (const usageObject of objectUsages) {
        usageObject.objectsUsing = usages[usageObject.objectId];
        if (usageObject.objectsUsing.length) {
          this.someAreUsed = true;
        }
      }
    }
    this.objectUsages = objectUsages;
    this.fieldParameters.object[this.fieldParameters.field.name] = objectUsages;
  }

  toggleShowUsage(objectUsage: ObjectUsage) {
    if (this.lastShowUsage && this.lastShowUsage.objectId === objectUsage.objectId) {
      this.lastShowUsage.showItems = !this.lastShowUsage.showItems;
    } else {
      if (this.lastShowUsage) {
        this.lastShowUsage.showItems = false;
      }
      this.lastShowUsage = objectUsage;
      this.lastShowUsage.showItems = true;
    }
  }

}
