<div class="information-view-and-edit">
  <app-object-view
    class="information-view-and-edit__view"
    *ngIf="!isEditing"
    [hideSectionTitle]="true"
    [objectId]="sectionsContainer.rootObject.artifact_id"
    [sectionsContainer]="sectionsContainer"
    [templateGroupId]="sectionsContainer.templateGroupId"
  ></app-object-view>
  <div *ngIf="isEditing"
       class="information-view-and-edit__edit">
    <app-object-edit
      *ngIf="isEditing && sectionsContainer"
      [sectionsContainer]="sectionsContainer"
    ></app-object-edit>
  </div>
</div>
