<div class="object-search-menu {{stylingClass}} "
     [ngClass]="menuClasses">
  <div class="landing-page-top">
    <app-search-collapse
      class="float-left"
      [isEditing]="isEditing"
      (toggleSearch)="onToggleSearch($event)"></app-search-collapse>
    <app-object-navigator
      class="object-search-menu__object_navigator"
      [currentObject]="object"
      [rootObjId]="rootObjId"
      [rootObjType]="rootObjType"
      [isEditing]="isEditing">
    </app-object-navigator>
  </div>
</div>
<div class="object-search-menu__input-container"
     [ngClass]="{'active': isSearch,
     'small-12 medium-12 large-9 columns': stylingClass !== 'sp-procedure-page',
     'withoutMediaCarousel': stylingClass === 'sp-procedure-page'}">
  <div class="object-search-menu__input dropdownIgnoreClicks"
       *ngIf="isSearch" @slideInQueryMenu>
    <app-search-query
      *ngIf="defaultSearchContainer"
      [searchContainer]="defaultSearchContainer"
      [setFocus]="setSearchQueryFocus"
      [newLayout]="newLayout"
      [noBorder]="true"
      [hideIcon]="true"
    ></app-search-query>
  </div>
</div>
