@if (contextTypes.length > 0) {
  <div class="edit-field-context dialog-border-color">
  <span class="bold edit-field-context__headline"
        [translate]="field.title || 'TRANS__MODAL_DIALOG__YOU_HAVE_CHOSEN'"></span>

    @for (contextType of contextTypes; track contextType) {
      <button mat-stroked-button color="primary"
              (click)="toggleContext(contextType)"
              class="edit-field-context__button"
              type="button"
              [ngClass]="{'active': contextType.showItems}">
        <mat-icon>{{contextType.showItems ? 'expand_less' : 'expand_more'}}</mat-icon>
        <span class="edit-field-context__button-text">{{contextType.superobjectType}} ({{contextType.count}})</span>
      </button>
    }

    @if (currentConcept && currentConcept.showItems) {
      <mat-chip-listbox class="edit-field-context__objects">
        @for (ob of currentConcept.items; track ob) {
          <mat-chip-option class="edit-field-context__object"> {{ob.identifier}} </mat-chip-option>
        }
      </mat-chip-listbox>
    }
  </div>
}
