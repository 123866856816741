<ul class="content-list-source-array-thumbnail"
    *ngIf="contentListSource.pageItems.length > 0 && !triggerAnimation">
  <li *ngFor="let pageItem of contentListSource.pageItems;"
      class="content-list-source-array__item">

    <div class="list-thumbnail__item">
      <div class="list-thumbnail__row"
           [@animateHeight]="pageItem.listItem.expanded ? 'expand' : 'collapse'">

        <div class="content-list-source-array-thumbnail__first-column"
             [ngClass]="{'relatedObjectView': pageItem.relatedObject,
             'hasExpandButton': pageItem.listItem.showExpandButton,
              'overflown': pageItem.listItem.showExpandButton && !pageItem.listItem.expanded}"
             #secondContent>
          <div class="list-thumbnail__first-container">
            <div class="checkbox-underlay" *ngIf="hasOperationMenu">
              <mat-checkbox
                class="white-bg-in-checkbox"
                [disabled]="contentListSource.operationContainer.art.read_only"
                [(ngModel)]="pageItem.checked"
                (ngModelChange)="setSelected($event, pageItem)"
                [checked]="pageItem.checked">
              </mat-checkbox>
            </div>

            <app-object-url [object]="pageItem.relatedObject" [aClass]="'thumbnail-url-source-content'"
                            class="content-list-source-array-thumbnail__object-url">
              <div class="object-url-content">
                <app-thumbnail
                  *ngIf="pageItem.thumbnailInfo.thumbnail_url || pageItem.thumbnailInfo.thumbnail_id"
                  [item]="pageItem.thumbnailInfo"
                  [stylingClass]="'thumbnail-image thumbnail-fixed-height'"></app-thumbnail>
                <i *ngIf="!pageItem.thumbnailInfo.thumbnail_url && !pageItem.thumbnailInfo.thumbnail_id"
                   class="icon-image imgIcon"></i>
              </div>
            </app-object-url>
          </div>

          <div class="list-thumbnail__second-container"
               [ngClass]="{'overflown': pageItem.listItem.showExpandButton && !pageItem.listItem.expanded}">
            <div class="list-thumbnail__object-overview">
              <app-object-content-list-overview-fields
                *ngIf="pageItem.relatedObject.overview"
                [objectView]="pageItem.relatedObject"
              ></app-object-content-list-overview-fields>
            </div>
          </div>

        </div>

        <div class="content-list-source-array-thumbnail__second-column"
             #thirdContent
             [ngClass]="{'overflown': pageItem.listItem.showExpandButton && !pageItem.listItem.expanded,
             'hasExpandButton': pageItem.listItem.showExpandButton}">
          <div class="list-thumbnail__object-overview">
            <div class="headline-container"><i class="icon-link"></i>
              <span class="bold" translate="TRANS__OBJECT_CONTENT_LIST_SOURCE_ARRAY_ITEMS__RELATION_HEADLINE"></span></div>
            <ul class="content-list-source-array-thumbnail__second-column-items">
              <li *ngFor="let field of pageItem.listItem.fields">
                <span class="content-list-source-array-thumbnail__second-column-item-title">
                  {{field.title | translate}}:</span>
                <span>{{field.value}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button (click)="toggleExpand(pageItem)"
              mat-button
              *ngIf="pageItem.listItem.showExpandButton"
              class="expand-button">
        <span *ngIf="!pageItem.listItem.expanded" translate="TRANS__OBJECT_CONTENT_LIST_SOURCE_ARRAY_ITEMS__SHOW_MORE"></span>
        <span *ngIf="pageItem.listItem.expanded" translate="TRANS__OBJECT_CONTENT_LIST_SOURCE_ARRAY_ITEMS__SHOW_LESS"></span>
      </button>
    </div>

  </li>
</ul>

