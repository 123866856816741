import {Injectable} from '@angular/core';
import {CmsApiService} from "./cms-api.service";
import {Reference} from "./definitions/reference";
import {LoggerService} from "./logger.service";
import {MetaField} from "./definitions/meta-field";
import {FieldParameters} from "./definitions/field-parameters";
import {SearchObject} from "./definitions/search-object";
import {SearchParameters} from "./definitions/search-parameters";
import {SearchService} from "./search.service";
import {SolrFilterService} from "./solr-filter.service";
import {SuperObjectModel} from "./definitions/super-object-model";

export interface ReferenceUsageInfo {
  usage: SearchObject[],
  showUsageText: string;
  usageWarning: string;
  usageConfirmStoreText: string;
}
@Injectable({
  providedIn: 'root'
})
export class SearchReferenceService {
  private searchReferences: {[id: string]: Reference};
  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private searchService: SearchService,
              private solrFilter: SolrFilterService,) {
  }

  async setSearchReferences(): Promise<void> {
    this.searchReferences = await this.cms.getAllSearchReferences();
  }

  getSearchReferenceFromField(metaField: MetaField): Reference {
    if (!metaField) {
      return null;
    }
    if (metaField.reference && !metaField.reference.reference_id) {
      return metaField.reference;
    }
    const referenceId = this.getReferenceId(metaField);
    if (!referenceId) {
      return null;
    }
    const ref = this.getSearchReferenceFromReferenceId(referenceId);
    return {...ref};
  }

  getSearchReferenceFromReferenceId(referenceId: string): Reference {
    return this.searchReferences[referenceId];
  }

  async getReferenceUsage(fieldParameters: FieldParameters, parentObject?: SuperObjectModel): Promise<ReferenceUsageInfo> {
    let res = {
      usage: [],
      showUsageText: '',
      usageWarning: ''
    } as ReferenceUsageInfo;
    const ref = this.getSearchReferenceFromField(fieldParameters.field);
    if (ref?.show_usage && fieldParameters.object) {
      res.showUsageText = ref.show_usage_text || 'TRANS__USAGE_COUNT';
      res.usageWarning = ref.usage_warning;
      res.usageConfirmStoreText = ref.usage_confirm_store_text;
      const usageObjectId = fieldParameters.object.artifact_id;
      const solrField = `${fieldParameters.field.name}.${ref.ref_prop}`;
      const searchParams = {} as SearchParameters;
      this.solrFilter.addFq(searchParams, solrField, usageObjectId);
      if (parentObject) {
        this.solrFilter.addFq(searchParams, '-artifact_id', parentObject.artifact_id);
      }
      const searchRes = await this.searchService.search(searchParams);
      res.usage = searchRes.artifacts || [];
    }
    return res;
  }

  private getReferenceId(metaField: MetaField) {
    let referenceId = metaField.reference_id;
    if (!referenceId && metaField.reference && metaField.reference.reference_id) {
      // This only happens when field comes from search results
      referenceId = metaField.reference.reference_id;
    }
    if (!referenceId) {
      this.logger.warn(`No reference id found for field ${metaField.field_uuid}`);
    }
    return referenceId;
  }
}
