import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SpectrumProcedureStep} from '../../core/definitions/setting-spectrum-procedure';
import {OperationContainer} from '../../core/definitions/operation-container';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {PrimusRouterService} from '../../core/primus-router.service';

@Component({
  selector: 'app-procedure-stepper',
  templateUrl: './procedure-stepper.component.html',
  styleUrls: ['./procedure-stepper.component.scss']
})
export class ProcedureStepperComponent implements OnInit, OnChanges {

  @Input() isEditing: boolean;
  @Input() operationContainer: OperationContainer;
  @Input() procedureSteps: SpectrumProcedureStep[];
  @Input() currentProcedureStep: SpectrumProcedureStep;
  @Output() stepSelected = new EventEmitter<SpectrumProcedureStep>();

  // @Input() actionMenuContainer: ActionMenuContainer;

  constructor(public primusStateMapper: PrimusStateMapperService,
              private primusRouter: PrimusRouterService) {
  }

  currentState: string;

  ngOnInit() {
    this.currentState = this.primusRouter.currentState();
  }

  ngOnChanges() {

  }

  setCurrentStepperMenu(step: SpectrumProcedureStep) {
    this.stepSelected.emit(step);
  }

}
