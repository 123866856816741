<div class="active-media" [ngClass]="{'draggable': zoomValue > 1}" xmlns="">
  <div class="active-media__container">
    @if (!playMedia && validSrc && imageUrl) {
      <img [src]="imageUrl"
           [alt]="'TRANS__ACTIVE_MEDIA__ACTIVE_MEDIA' | translate"
           id="mediaItem-{{activeMedia.artifact_id}}"
           (error)="setDefaultImg()">
    }
    @if (!validSrc && activeMedia && activeMedia.object_type === 'Attachment') {
      <div class="default-image">
        <i class="icon-image-attention"></i>
        <span translate="TRANS__ACTIVE_MEDIA__NO_ATTACHMENT_IMAGE"></span>
      </div>
    }

    @if (is3DModel && playMedia) {
      <div class="model-3d-placeholder">
        <i class="icon-object"></i>
      </div>
    }
    @if (showPlayback) {
      @if (!playMedia) {
        <a (click)="onPlayMedia()"
           class="playButton"><i class="icon-play2"></i></a>
      } @else {
        <div class="video-player-max-size">
          @if (status === 'done') {
            <div>
              <div id="dms-video" class="dms-video">
                <kit-dms-video dmsApi="http://localhost:5000/cms_api/v1.0/dms" dmsIds="0138Xuczt8u9"></kit-dms-video>
              </div>

              @if (activeMedia.object_type === 'Audio') {
                <audio controls autoplay>
                  @for (pu of activeMedia.$$mediaPlaybackUrls; track pu) {
                    <source [src]="pu.url" type="{{pu.type}}">
                  }
                </audio>
              }
            </div>
          } @else {
            <div class="default-image">
              <i class="icon-hour-glass"></i>
              <span translate="TRANS__ACTIVE_MEDIA__MEDIA_NOT_READY"></span>
            </div>
          }
        </div>
      }
    }
  </div>
</div>
