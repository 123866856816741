import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsActiveService {
  private _active = new BehaviorSubject(false);
  isActive = this._active.asObservable();

  constructor() { }

  changeState(active: boolean) {
    this._active.next(active);
  }
}
