export function getSearchGroupSetup(groupName: string) {
  switch (groupName) {
    case 'historicalevent':
      return {
        label: 'Historisk hendelse',
        name: 'historicalevent',
        setup: {
          relation_superobject_type_id: null,
          superobject_type_id: '',
          child_document_type: 'SuperobjectHistoryEventItem',
          child_field_used: null,
          header: 'TRANS__ADVANCED_SEARCH__HEADER__HISTORICALEVENT',
          field_queries: [
            {
              relation_superobject_type_id: null,
              context_field: null,
              child_document_type: 'SuperobjectHistoryEventItem',
              is_array_context_field: null,
              field_title: "",
              field_name: "",
              input_type: "",
              is_array_field: true,
              operator_selected: undefined,
              operators: [],
              path: "",
              reference_id: "",
              superobject_type_id: '',
              value: undefined,
              valueDisplay: ''
            }
          ],
          logical_operator: null,
          field_logical_operator: null,
          sub_groups: [],
          level: 0
        }
      }

    case 'objectinformation':
      return {
        label: 'Objektinformasjon',
        name: 'objectinformation',
        setup: {
          relation_superobject_type_id: null,
          superobject_type_id: '',
          child_document_type: null,
          child_field_used: null,
          header: 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION',
          field_queries: [
            {
              relation_superobject_type_id: null,
              context_field: null,
              child_document_type: null,
              is_array_context_field: null,
              field_title: "",
              field_name: "",
              input_type: "",
              is_array_field: true,
              operator_selected: undefined,
              operators: [],
              path: "",
              reference_id: "",
              superobject_type_id: '',
              value: undefined,
              valueDisplay: ''
            }
          ],
          logical_operator: null,
          field_logical_operator: null,
          sub_groups: [],
          level: 0
        }
      }

    case 'rights':
      return {
        label: 'Tilkoblede rettigheter',
        name: 'rights',
        setup: {
          relation_superobject_type_id: null,
          superobject_type_id: '',
          child_document_type: null,
          child_field_used: null,
          header: '',
          field_queries: [],
          logical_operator: null,
          field_logical_operator: null,
          sub_groups: [],
          level: 0
        }
      }

    default:
      return null;
  }
}

export const searchGroups = [
  {
    label: 'Objektinformasjon',
    name: 'objectinformation'
  },
  {
    label: 'Historisk hendelse',
    name: 'historicalevent'
  },
 /* {
    label: 'Tilkoblede rettigheter',
    name: 'rights'
  },*/
]
