import {SearchObject} from './search-object';
import {SuperobjectName} from './superobject-name';
import {SuperObjectDescription} from './super-object-description';
import {BaseModel} from './base-model';

export class SearchConcepts {
  hits: number;
  concepts: Array<ConceptListItem>;
  facets: Array<any>;
}

export class ConceptFilter {
  concept_id: string;
  filterid: string;
  object_type = 'ConceptFilter';
  superobject_type_id: string;
  superobject_type_id_value: string;
  destroy: boolean;
}

export class ConceptListItem {
  $$deleted = false;
  $$selected = false;
  $$updatedAt: string;
  $$usage: Array<SearchObject> = [];

  artifact_id = '';
  artifact_name = '';
  authority_id = null;
  authority_id_value = '';
  authority_marked = false;
  authority_dataset_id = null;
  authority_dataset_id_value = '';
  authority_source = '';
  children: Array<ConceptListItem>;
  code = '';
  description = '';
  filters: any;
  has_authority = false;
  is_leaf: boolean;
  is_parent: boolean;
  parent_path = '';
  level = 0;
  meta_type = '';
  name = '';
  valid = true;
  object_type: string;
  open = false;
  parent_id = '';
  parent_id_value: string;
  superobject_type_id: string;
  updated_at: string;
  updated_by_id: string;
  updated_by_id_value: string;
}

export class Concept extends BaseModel {
  $$usage: Array<SearchObject> = [];

  authority_id = null;
  authority_id_value = '';
  authority_marked = false;
  authority_dataset_id_value = '';
  code = '';
  description: SuperObjectDescription;
  valid = true;
  name: SuperobjectName;
  updated_by_id = '';
  updated_by_id_value = '';

  // Non-server properties
  edit: boolean;
}

export class ConceptHierarchic extends Concept {
  children: Array<ConceptListItem>;
  is_leaf = true;
  level = 0;
  parent_id = '';
  parent_id_value: string;
  parent_path: string;
}

export enum SortDir {
  Asc = 'asc',
  Desc = 'desc'
}

export class ConceptsParams {
  concept_id: string;
  concept_type_id: string;
  meta_type: string;
  exclude_ids: string[];
  is_leaf: boolean;
  valid: boolean;
  parent_id: string;
  query: string;
  rows: number;
  start: number;
  sort: string;
  sort_dir: SortDir;
}
