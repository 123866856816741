import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContentListContainer} from '../../core/definitions/object-content-tab/content-list-container';

@Component({
  selector: 'app-object-content-list-regular',
  templateUrl: './object-content-list-regular.component.html',
  styleUrls: ['./object-content-list-regular.component.scss']
})
export class ObjectContentListRegularComponent implements OnInit {

  @Input() contentListContainer: ContentListContainer;
  @Output() objectRefresh = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit() {
  }

  setObjectRefresh(event) {
    this.objectRefresh.emit(event);
  }

  onContentChange(event) {
    console.log('Event performed: ' + event);
  }
  get disabled() {
    let res = this.contentListContainer.operationContainer.art.read_only;
    if (!res) {
      const selections = this.contentListContainer.searchContainer.selections;
      res = !(selections.allSelected || !!selections.selectedItems.length);
    }
    return res;
  }
}
