<input type="file" (change)="handleFileInput($event.target['files'])"/>

<div *ngIf="formGroup"
     [formGroup]="formGroup">
  <div>
    <ng-container *ngFor="let fieldOption of mainOptions.field_options">
      <app-concept-select
        [fieldOption]="fieldOption"
        [formGroup]="formGroup"
      ></app-concept-select>
    </ng-container>
  </div>
  <div>
    <div *ngFor="let importField of importFields">
      <a (click)="openEditImportFieldData(importField)">{{getImportFieldTitle(importField)}}</a>
    </div>
    <button mat-button (click)="addField()" [disabled]="!displayedColumns.length">Legg til felt</button>
  </div>
</div>


<mat-table [dataSource]="dataSource">
  <!-- Display columns dynamically based on the CSV file headers -->
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <mat-header-cell *matHeaderCellDef [ngClass]="{'has-data': columnFieldData[column]}">
      {{ column }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element[column] }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator [pageSizeOptions]="[50, 100, 1000]" showFirstLastButtons></mat-paginator>
