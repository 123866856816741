import {Injectable} from '@angular/core';
import {AnnotationContainer} from './annotation-container';
import {AnnotationRendererService} from './annotation-renderer.service';

@Injectable({
  providedIn: 'root'
})
export class AnnotationCursorService {
  private adding = false;

  constructor(private readonly annotationRenderService: AnnotationRendererService) {
  }

  setCursor(container: AnnotationContainer) {
    if (container.curAnn.descSet) {
      this.drawBullets(container);
      this.drawCursor(container);
      container.curAnn.annotations.forEach(annotation => {
        if (!annotation.$$sx) {
          annotation.$$sx = 0.005 - (Math.random() / 100);
          annotation.$$sy = 0.005 - (Math.random() / 100);
        }
        annotation.x1 += annotation.$$sx;
        annotation.x2 += annotation.$$sx;
        annotation.y1 += annotation.$$sy;
        annotation.y2 += annotation.$$sy;
        if (annotation.x2 < 0) {
          const diff = annotation.x2 - annotation.x1;
          annotation.x1 = 1;
          annotation.x2 = annotation.x1 + diff;
        }
        if (annotation.y2 < 0) {
          const diff = annotation.y2 - annotation.y1;
          annotation.y1 = 1;
          annotation.y2 = annotation.y1 + diff;
        }
        if (annotation.x1 > 1) {
          const diff = annotation.x2 - annotation.x1;
          annotation.x2 = 0;
          annotation.x1 = annotation.x2 - diff;
        }
        if (annotation.y1 > 1) {
          const diff = annotation.y2 - annotation.y1;
          annotation.y2 = 0;
          annotation.y1 = annotation.y2 - diff;
        }
      });
    }
  }

  private drawCursor(container: AnnotationContainer) {
    if (!container.curAnn.cursor.hit) {
      const ctx = container.ctx;
      const x = this.annotationRenderService.ax2cx(container, container.curAnn.cursor.x);
      const y = this.annotationRenderService.ay2cy(container, container.curAnn.cursor.y);
      const radians = container.curAnn.cursor.angle * Math.PI / 180;
      const annotationHit = this.annotationRenderService.getHoverAnnotation(container, {x: x, y: y});
      if (annotationHit) {
        container.curAnn.cursor.hit = true;
        for (let t = 0; t < 10; t++) {
          const angle = Math.random() * 360;
          this.addBullet(container, angle, 'fragment');
        }
      }
      ctx.translate(x, y);
      ctx.rotate(radians);
      this.drawObject(container, 0, 0, 0, 10, 3, 'rgba(255, 255, 255, 0)');
      if (container.curAnn.cursor.engineOn) {
        this.drawObject(container, -10, 0, 180, 5, 3, 'rgba(255, 255, 255, 1)');
      }
      ctx.rotate(-radians);
      ctx.translate(x, y);

      if (container.curAnn.cursor.engineOn) {
        const addSpeedX = Math.cos(radians) * 0.0003;
        const addSpeedY = Math.sin(radians) * 0.0003;
        container.curAnn.cursor.sx += addSpeedX;
        container.curAnn.cursor.sy += addSpeedY;
        container.curAnn.cursor.engineOn = false;
      }
      container.curAnn.cursor.x += container.curAnn.cursor.sx;
      container.curAnn.cursor.y += container.curAnn.cursor.sy;
      if (container.curAnn.cursor.x < 0) {
        container.curAnn.cursor.x = 1;
      }
      if (container.curAnn.cursor.x > 1) {
        container.curAnn.cursor.x = 0;
      }
      if (container.curAnn.cursor.y < 0) {
        container.curAnn.cursor.y = 1;
      }
      if (container.curAnn.cursor.y > 1) {
        container.curAnn.cursor.y = 0;
      }
    }

  }

  private addBullet(container: AnnotationContainer, angle, bulletType) {
    const radians = angle * Math.PI / 180;
    container.curAnn.cursor.bullets.push({
      x: container.curAnn.cursor.x,
      y: container.curAnn.cursor.y,
      angle: angle,
      sx: container.curAnn.cursor.sx + Math.cos(radians) * 0.01,
      sy: container.curAnn.cursor.sy + Math.sin(radians) * 0.01,
      liveCount: 50,
      bulletType: bulletType
    });

  }

  private drawBullets(container: AnnotationContainer) {
    if (container.curAnn.cursor.fire && !container.curAnn.cursor.hit) {
      this.addBullet(container, container.curAnn.cursor.angle, 'bullet');
      container.curAnn.cursor.fire = false;
    }
    const removeBullets = [];
    container.curAnn.cursor.bullets.forEach((bullet, bulletIndex) => {
      const ctx = container.ctx;
      const x = this.annotationRenderService.ax2cx(container, bullet.x);
      const y = this.annotationRenderService.ay2cy(container, bullet.y);
      if (!bullet.liveCount--) {
        removeBullets.push(bulletIndex);
      } else if (bullet.bulletType === 'bullet') {
        const annotationHit = this.annotationRenderService.getHoverAnnotation(container, {x: x, y: y});
        if (annotationHit) {
          container.curAnn.deleteAnnotation(annotationHit);
          removeBullets.push(bulletIndex);
        }
      }
      if (container.curAnn.getAnnotations().length === 0) {
        this.addAnnotations(container);
      }
      ctx.beginPath();
      ctx.rect(x, y, 2, 2);
      ctx.closePath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#FFFFFF';
      ctx.fillStyle = 'rgba(255, 255, 255, 1)';
      ctx.shadowColor = '#000';
      ctx.shadowBlur = 3;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      ctx.stroke();
      ctx.fill();
      bullet.x += bullet.sx;
      bullet.y += bullet.sy;
      if (bullet.x > 1) {
        bullet.x = 0;
      }
      if (bullet.y > 1) {
        bullet.y = 0;
      }
      if (bullet.x < 0) {
        bullet.x = 1;
      }
      if (bullet.y < 0) {
        bullet.y = 1;
      }
    });
    if (removeBullets.length) {
      for (let t = removeBullets.length - 1; t >= 0; t--) {
        container.curAnn.cursor.bullets.splice(removeBullets[t], 1);
      }
    }
  }

  private drawObject(container: AnnotationContainer, dx, dy, dAngle, size, sideCount, fillStyle) {
    const ctx = container.ctx;
    const radians = dAngle * Math.PI / 180;
    ctx.beginPath();
    ctx.moveTo(size * Math.cos(radians) + dx, size * Math.sin(radians) + dy);
    for (let i = 1; i <= sideCount; i += 1) {
      ctx.lineTo(size * Math.cos((i * 2 * Math.PI / sideCount) + radians) + dx,
        size * Math.sin((i * 2 * Math.PI / sideCount) + radians) + dy);
    }
    ctx.closePath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#FFFFFF';
    ctx.fillStyle = fillStyle;
    ctx.shadowColor = '#000';
    ctx.shadowBlur = 3;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.stroke();
    ctx.fill();
  }

  private addAnnotations(container: AnnotationContainer) {
    if (!this.adding) {
      this.adding = true;
      setTimeout(() => {
        const descriptions = ['436f64696e67206279', '52697461204a6f686e73656e', '546f6d20416e646572732044616c73656e67',
          '4a6f6e617320412e204f6c73746164', '4b656e6e657468204f64656e', '416c74206572206261726520647269747462726121',
          '5468697320456173746572206567672069732064656469636174656420746f2045726d79617320596f68616e6e657321',
          '5765276c6c206e6576657220666f7267657420746f2c20627564647921'];
        const speedConst = 0.003;
        for (let t = 0; t < 8; t++) {
          const border = Math.floor(Math.random() * 4);
          let x, y, xs, ys;
          if (border === 0 || border === 1) {
            if (border === 0) {
              x = 0;
              xs = speedConst;
            } else {
              x = 1;
              xs = -speedConst;
            }
            y = Math.random();
            ys = Math.random() * speedConst;
          } else {
            if (border === 2) {
              y = 0;
              ys = speedConst;
            } else {
              y = 1;
              ys = -speedConst;
            }
            x = Math.random();
            xs = Math.random() * speedConst;
          }
          container.curAnn.createAnnotation(x, y).then(annotation => {
            annotation.x2 = annotation.x1 + 0.1;
            annotation.y2 = annotation.y1 + 0.1;
            annotation.$$sx = xs;
            annotation.$$sy = ys;
            annotation.$$$unfinished = false;
            annotation.description.description = this.hx2ascii(descriptions[t]);
            this.adding = false;
          });
        }
      }, 3000);

    }
  }

  private hx2ascii(strIn) {
    const hex = strIn.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  }

}
