import {SuperObjectModel} from '../core/definitions/super-object-model';

// Make sure that changes to the server side Annotation model is reflected here!
export class Annotation extends SuperObjectModel {
  a_type: string;
  color: string;
  image_id: string;
  object_type = 'Annotation';
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  $$$unfinished: boolean;
  $$selected: boolean;
  $$updated: boolean;
  $$sx: number;
  $$sy: number;
  parentId: string;
  parentObjectType: string;
}
