import {SuperObjectModel} from '../core/definitions/super-object-model';
import {Injectable} from '@angular/core';
import {ObjectEditService} from '../core/object-edit.service';
import {CurrentObjectService} from '../core/current-object.service';
import {MediaHelperService} from '../core/media-helper.service';
import {CommonsService} from '../core/commons.service';
import {ContentMenusService} from '../object-content-tab/content-menus.service';
import {SearchSelectorService} from '../core/search-selector.service';
import {UiToolsService} from '../core/ui-tools.service';
import {OperationAndSectionsContainerParams, OperationService} from '../operations/operation.service';
import {FeatureFlagsService} from '../core/feature-flags.service';
import {OperationTarget} from '../core/definitions/operation-target.enum';
import {ContentInfo} from '../core/definitions/content-info';
import {PrimusRouterService} from '../core/primus-router.service';
import {PrimusRouteService} from '../core/primus-route.service';
import {TranslateService} from '@ngx-translate/core';
import {SectionMenuContainer} from '../object-view/section-menu/section-menu.component';
import {TemplateSelectorContainer} from '../core/definitions/template-selector-container';
import {OperationContainer} from '../core/definitions/operation-container';
import {Subject, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OperationExecutorType} from '../core/definitions/operation-executor-type.enum';
import {OperationStepType} from '../core/definitions/operation-step-type.enum';
import {Selector} from '../core/definitions/reference';
import {SelectorCreationParams} from '../core/definitions/selector-creation-params';
import {SelectorContainer} from '../core/definitions/selector-container';
import {ContentListSourceContainer} from '../core/definitions/object-content-tab/content-list-source-container';
import {OperationDef} from '../core/definitions/operation-def';
import {AnnotationHandler} from '../image-annotation/annotation-handler';
import {ModelFactoryService} from '../core/model-factory.service';
import {CrudService} from '../core/crud.service';
import {DateToolsService} from '../core/date-tools.service';
import {LoginService} from '../core/login.service';
import {ObjectDeletionService} from '../core/object-deletion.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentObjectContext {
  art = new SuperObjectModel();
  artifactId: string;
  sectionMenuContainer = new SectionMenuContainer();
  contentInfo: ContentInfo;
  templateSelectorContainer = new TemplateSelectorContainer();
  smallObjectMenus: boolean;
  mediumScreen: boolean;
  smallScreen: boolean;
  scrollContent: boolean;
  imageFullScreen = false;
  modelFullscreen = false;
  toggleAnnotations: boolean;
  contextIds: string[];
  isNew: boolean;
  reload = false;
  menus = [];
  status = 'closed';
  // Used to display annotation on image gallery
  annotation = {};
  loadFailed = false;
  operationContainer: OperationContainer;
  isEditing = false;
  finishedLoading = false;
  displayMediaSelectorsNewObjects = false;
  private templateGroupId: string;
  private stopSizeWatch: string;
  private routerSubscription: Subscription;
  private _curAnn: AnnotationHandler;
  private _loadingOperations: boolean = false;
  get loadingOperations() {
    return this._loadingOperations;
  }

  private _loadingArtifact: boolean = false;
  get loadingArtifact() {
    return this._loadingArtifact;
  }

  constructor(private primusRouter: PrimusRouterService,
              private primusRoute: PrimusRouteService,
              private objectEditService: ObjectEditService,
              public currentObjectService: CurrentObjectService,
              private mediaHelper: MediaHelperService,
              private commons: CommonsService,
              private contentMenusService: ContentMenusService,
              private searchSelectorService: SearchSelectorService,
              private uiTools: UiToolsService,
              private operationService: OperationService,
              private featureFlagsService: FeatureFlagsService,
              private translate: TranslateService,
              private _snackbar: MatSnackBar,
              private modelFactory: ModelFactoryService,
              private objectDeletionService: ObjectDeletionService,
              private loginService: LoginService,
              private dateToolsService: DateToolsService,
              private crud: CrudService,
              ) {
  }

  private _objectLoaded$ = new Subject<any>();

  get objectLoaded$() {
    return this._objectLoaded$.asObservable();
  }

  get sectionsContainer() {
    return this.operationContainer?.rootSectionsContainer;
  }

  get currAnn(): AnnotationHandler {
    if (!this._curAnn) {
      this._curAnn = new AnnotationHandler(
        (isEditing: boolean) => {
          this.isEditing = isEditing;
        },
        this.modelFactory,
        this.objectDeletionService,
        this.uiTools,
        this.loginService,
        this.dateToolsService,
        this.crud);
    }
    return this._curAnn;
  }

  objectRefresh(event: any) {
    if (event.store) {
      this.operationContainer.rootSectionsContainer.submitted = true;
      if (!this.operationContainer.rootSectionsContainer.formGroup.invalid) {
        this.storeObject(true, true, false).then();
      }
    } else {
      this.loadObject().then();
    }
  }

  isFinishedLoading() {
    this.finishedLoading = true;
  }

  selectorOverlay() {
    if (this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled) {
      this.searchSelectorService.disableSelector(this.templateSelectorContainer);
    } else {
      this.disableSelector();
    }
  }

  onTemplateSelectorCreated(templateSelectorContainer: any) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  async onChangeTemplateGroup(newGroupId: string) {
    if (newGroupId === this.templateGroupId) {
      return;
    }
    this.templateGroupId = newGroupId;
    this.contentInfo.templateGroupId = newGroupId;
    this.objectRefresh({});
  }

  async init(artifactId: string) {
    this.contentInfo = new ContentInfo();
    this.currentObjectService.isEditing = this.primusRoute.params.edit === 'true';
    this.isEditing = this.currentObjectService.isEditing;
    this.stopSizeWatch = this.uiTools.addWindowSizeListener((newVal: any) => {
      this.smallObjectMenus = newVal.width < 1025;
      this.mediumScreen = newVal.width < 1025;
      this.smallScreen = newVal.width < 642;
    });
    const oldArtifactId = this.artifactId;
    this.artifactId = artifactId;
    this.contextIds = this.primusRoute.params.contextIds;
    const isNew = this.primusRoute.params.isNew === 'true';
    this.isNew = isNew && this.isEditing;
    this.menus = [];

    if (this.artifactId) {
      if (oldArtifactId !== this.artifactId) {
        this._loadingArtifact = true;
        this._loadingOperations = true;
      }
      await this.loadObject();
      this._loadingArtifact = false;
      this._loadingOperations = false;
    }

    await this.contentMenusService.getMenus(this.contentInfo).then((menus) => {
      this.menus = menus;
    });

    this.contentInfo.menus = this.menus;
    await this.contentMenusService.setContentMenus(this.menus, this.contentInfo, this.primusRoute.params);

    // TODO: 02/07/2024 move to component
    const windowSize = this.uiTools.windowSize;
    this.mediumScreen = windowSize.width < 1025;
    this.smallObjectMenus = windowSize.width < 1025;
    this.smallScreen = windowSize.width < 642;
    window.addEventListener('scroll', () => {
      this.checkScroll();
    });

    // TODO: 02/07/2024 move to component
    if (this.isNew) {
      this._snackbar.open(this.translate.instant('TRANS__OBJECT_PAGE__NEW_OBJECT_REGISTERED'), null, {
        duration: 8000,
        panelClass: 'object-edit-new__snackbar-panel',
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      })
    }
  }

  openImageFullScreen(activeImage: any) {
    this.toggleAnnotations = activeImage.toggleAnnotations;
    this.imageFullScreen = true;
  }

  openModelFullscreen(activeModel: any) {
    this.toggleAnnotations = false;
    this.modelFullscreen = true;
  }

  closeImageFullScreen() {
    this.imageFullScreen = false;
  }

  closeModelFullscreen() {
    this.modelFullscreen = false;
  }

  setCurrentList(listName: string) {
    const ci = this.contentInfo;
    ci.curListName = listName;
    ci.curListContainer = null;
    ci.setContentListSource(null);
    if (ci.contentListContainers[listName]) {
      this.contentMenusService.runListSearch(ci.curListName, ci).then(() => {
        this.contentMenusService.setActiveTopMenu(ci.menus, ci);
      });
    } else if (ci.sources && ci.sources[listName]) {
      ci.setContentListSource(ci.sources[listName]);
    }
  }

  setCurrentOperation(operation: OperationDef) {
    operation.$$showOperationView = true;
    this.operationService.setCurrentOperation(this.operationContainer, operation);
  }

  private async loadObject() {
    try {
      this.operationContainer = await this.operationService.createOperationAndRootSectionsContainer(this.getCreateParams());
      this.operationContainer.$$onlyEmitStateChange = true;
      this.operationContainer.refreshFn = () => {
        this.objectRefresh({});
      };
      await this.postLoadOperations();
    } catch (reason) {
      this.loadFailed = true;
      console.error(`Failed loading object: ${reason.error?.message || reason.message}`);
      this.art = new SuperObjectModel();
      this.art.artifact_id = this.artifactId;
    }
  }

  private getCreateParams(): OperationAndSectionsContainerParams {
    const params = {
      useExistingObject: true,
      objectId: this.artifactId,
      getSourceObject: !this.isEditing,
      operationTarget: OperationTarget.OBJECT_VIEW,
      templateGroupId: this.templateGroupId
    } as OperationAndSectionsContainerParams;
    if (this.isEditing) {
      params.executorType = OperationExecutorType.ARTIFACT;
      params.operationStepType = OperationStepType.EDIT_OBJECT;
    }
    return params;
  }

  private async postLoadOperations() {
    const sectionsContainer = this.operationContainer.rootSectionsContainer;
    this.sectionMenuContainer.artifact = sectionsContainer.rootObject;
    this.sectionMenuContainer.sections = sectionsContainer.sections;
    this.sectionMenuContainer.contentInfo = this.contentInfo;
    let listName = this.primusRoute.params.listName;
    if (this.contentInfo.curListName) {
      listName = this.contentInfo.curListName;
    }
    this.art = sectionsContainer.rootObject;
    this.currentObjectService.currentObject = sectionsContainer.rootObject;

    this.contentInfo.updateContentInfo(this.isEditing, this.art, listName);
    this.contentInfo.templateGroupId = this.templateGroupId;

    this.getMedia();
    this.operationContainer.contentInfo = this.contentInfo;
    this.operationContainer.enableSelector = (selector: Selector, params: SelectorCreationParams) => {
      this.enableSelector(selector, this.operationContainer, params);
    };
    this.operationContainer.disableSelector = () => {
      this.disableSelector();
    };
    this.setCurrentStepAttributes();
    this._objectLoaded$.next(1);
  }

  private setCurrentStepAttributes() {
    if (this.operationContainer?.currentOperation?.$$currentStep) {
      this.operationContainer.currentOperation.$$currentStep.$$hideRegisterStayOnStep = this.isNew;
      if (this.isEditing && this.isNew && this.displayMediaSelectorsNewObjects) {
        // Must skip dirty check in order to be able to upload media
        this.operationContainer.currentOperation.$$currentStep.skip_dirty_check = true;
      }
    }
  }

  private async storeObject(editing: boolean, edit: boolean, reload: boolean) {
    await this.objectEditService.setObjectValuesStoreObject(this.operationContainer.rootSectionsContainer);
    this.currentObjectService.isEditing = editing;
    this.isEditing = editing;
    if (reload) {
      await this.primusRouter.navigateState(this.primusRouter.currentState(), {
        edit: edit,
        isNew: undefined
      }, {location: 'replace', reload: reload});
    } else {
      await this.loadObject();
    }
  }

  private async getMedia() {
    this.art.$$mediaContainer = await this.mediaHelper.getMediaContainerForAllObjectMedia(this.art);
  }

  private enableSelector(selector: Selector, mc: SelectorContainer, params?: SelectorCreationParams) {
    let restrictVal: string[];
    const contentListName = mc.currentMenu.content_list;
    const contentMenu = contentListName ? this.contentMenusService.getMenuFromListName(contentListName, this.contentInfo) : null;
    if (selector.context_restrict && contentMenu && contentMenu.count) {
      const contexts = this.commons.getContextIds(this.art.contexts);
      if (contexts && contexts.length > 0) {
        restrictVal = contexts;
      } else {
        restrictVal = [this.art.artifact_id];
      }
      if (!selector.context_restrict_field) {
        console.warn(`Selector "${selector.view}" missing context_restrict_field`);
      }
      selector.restrictions = [{
        fieldId: selector.context_restrict_field,
        value: restrictVal,
        on: true,
        title: 'TRANS__SELECTOR__CONTEXT_RESTRICTION'
      }

      ];
    }
    let currentSource: ContentListSourceContainer;
    if (contentListName && this.contentInfo.sources[contentListName]) {
      currentSource = this.contentInfo.sources[contentListName];
    }
    this.contentInfo.setSelectorUsed(selector, this.contentInfo.sources, currentSource);
    this.searchSelectorService.enableSelector(selector, mc, params, {
      searchContainerCreated: () => {
      }, selectorCallback: (selectedObj: any) => {
        this.searchSelectorService.disableSelector(mc);
        mc.selectorCallback(selectedObj, selector);
      }
    });
  }

  private disableSelector() {
    this.searchSelectorService.disableSelector(<SelectorContainer>this.operationContainer);
  }

  private checkScroll() {
    if (!this.mediumScreen) {
      if (!this.contentInfo || this.contentInfo.curListName === 'overview') {
        this.scrollContent = window.scrollY > 50 && window.scrollY < 290;
      } else {
        this.scrollContent = window.scrollY > 50;
      }
    }
  }
}
