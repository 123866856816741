<h1 mat-dialog-title>Redigere importfeltinformasjon for {{importField.field_title}}</h1>
<div mat-dialog-content *ngIf="importFieldSet" [formGroup]="formGroup">
  <div>
  <ng-container *ngFor="let mapField of importField.field_map">
    <app-column-select [mapField]="mapField" [formGroup]="formGroup"></app-column-select>
  </ng-container>
  </div>
  <div>
    <p *ngIf="importField.field_options.length === 1">Dette feltet krever en forhåndssatt verdi:</p>
    <p *ngIf="importField.field_options.length > 1">Dette feltet krever forhåndssatte verdier:</p>
    <p *ngIf="importField.description">{{importField.description}}</p>
    <ng-container *ngFor="let fieldOption of importField.field_options">
      <app-concept-select [fieldOption]="fieldOption" [formGroup]="formGroup"></app-concept-select>
    </ng-container>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Avbryt</button>
  <button mat-button (click)="close()" [disabled]="isInvalid()">Ok</button>
</div>
