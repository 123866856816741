import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubSetting} from '../../../core/definitions/sub-setting';
import {AdminSelectOption} from './admin-select-option';
import {TranslateService} from '@ngx-translate/core';
import {CmsApiService} from '../../../core/cms-api.service';
import {ConceptsParams} from '../../../core/definitions/concepts';
import {LoggerService} from '../../../core/logger.service';
import {SettingsService} from '../../../core/settings.service';

@Component({
  selector: 'app-admin-settings-field-value',
  templateUrl: './admin-settings-field-value.component.html',
  styleUrls: ['./admin-settings-field-value.component.scss']
})
export class AdminSettingsFieldValueComponent implements OnInit {

  @Input() subSetting: SubSetting;
  @Input() editing: boolean;
  @Output() changed = new EventEmitter<void>();

  fieldType = 'text';
  imageField = {name: 'image'};
  imageObject = {image: null};
  options: Array<AdminSelectOption> = [];

  private displayValueTrue: string;
  private displayValueFalse: string;

  constructor(private logger: LoggerService,
              private translate: TranslateService,
              private cmsApiService: CmsApiService,
              private settings: SettingsService) {
  }

  ngOnInit() {
    const collectionSuperobjectTypeId = this.settings.getClientConfig().COLLECTION_SUPEROBJECT_TYPE_ID;
    if (this.subSetting.key === '9c4f9dc3-d6dd-426e-913f-492468087ee3') {
      this.fieldType = 'select';
      // Need to use the get method for obtaining the first translation in order for translations to be available
      this.translate.get('TRANS__LANGUAGE__NORWEGIAN').subscribe(value => {
        this.options.push(new AdminSelectOption('no', value));
        this.options.push(new AdminSelectOption('se', this.translate.instant('TRANS__LANGUAGE__SWEDISH')));
        this.options.push(new AdminSelectOption('en', this.translate.instant('TRANS__LANGUAGE__ENGLISH')));
      });
    } else if (this.subSetting.value_type.toLowerCase() === 'collection' || (typeof this.subSetting.value === 'string' &&
      (this.subSetting.value.indexOf('ct_') === 0 || this.subSetting.value.includes(collectionSuperobjectTypeId)))) {
      this.fieldType = 'select';
      this.setOptions().then();
    } else if (typeof this.subSetting.value === 'string' && this.subSetting.value.includes(collectionSuperobjectTypeId)) {
      this.fieldType = 'collection';
    } else if (this.subSetting.value_type === 'image') {
      this.fieldType = 'image';
      this.imageObject = {image: this.subSetting};
    } else if (this.subSetting.value_type === 'integer') {
      this.fieldType = 'number';
    } else if (this.subSetting.value_type === 'boolean') {
      this.fieldType = 'checkbox';
      this.translate.get('TRANS__ADMIN_SETTINGS__VALUE_TRUE').subscribe(trueValue => {
        this.displayValueTrue = trueValue;
        this.displayValueFalse = this.translate.instant('TRANS__ADMIN_SETTINGS__VALUE_FALSE');
      });
    } else if (this.subSetting.value_type === 'string') {
      this.fieldType = 'text';
    } else {
      this.logger.warn(`Unknown settings type ${this.subSetting.value_type}`);
    }
  }

  async setOptions() {
    const conceptParams = new ConceptsParams();
    if (this.subSetting.value.indexOf('ct_') === 0) {
      const conceptTypeIndex = this.subSetting.value.indexOf('-');
      conceptParams.concept_type_id = this.subSetting.value.substring(0, conceptTypeIndex);
    } else {
      conceptParams.concept_type_id = 'Collection';
    }
    conceptParams.meta_type = 'Settings';
    conceptParams.rows = 50000;
    const concepts = await this.cmsApiService.getConcepts(conceptParams);
    for (const concept of concepts.concepts) {
      this.options.push(new AdminSelectOption(concept.artifact_id, concept.name));
    }
  }

  get displayValue() {
    let res = this.subSetting.value;
    if (this.fieldType === 'select') {
      res = this.getOptionDisplayValue();
    } else if (this.fieldType === 'checkbox') {
      res = this.subSetting.value ? this.displayValueTrue : this.displayValueFalse;
    }
    return res !== null && res !== undefined ? res.toString() : '';
  }

  get editable() {
    return this.editing && this.subSetting.editable;
  }

  valueChanged() {
    this.changed.emit();
  }

  private getOptionDisplayValue() {
    let res = '';
    for (let t = 0; t < this.options.length; t++) {
      if (this.options[t].value === this.subSetting.value) {
        res = this.options[t].viewValue;
        break;
      }
    }
    return res;
  }

}
