<div class="information" [ngClass]="{'edit': currentObjectService.isEditing}">
  <div class="information__search-menu">
    <app-object-search-menu
      class="information__search-menu-content"
      [object]="operationContainer?.rootSectionsContainer?.rootObject"
      [isEditing]="currentObjectService.isEditing"
      [stylingClass]="'sp-procedure-page'"
    ></app-object-search-menu>
    <div class="information__template">
      <div class="information__template-button">
        <app-template-group-select-button
          *ngIf="operationContainer?.contentInfo?.artifact"
          [object]="operationContainer.contentInfo.artifact"
          [collapseOnSmallScreen]="false"
          (templateSelectorCreated)="onTemplateSelectorCreated($event)"
          (changeTemplateGroup)="onChangeTemplateGroup($event)"
          [templateGroupId]="operationContainer.contentInfo.templateGroupId"
          [disabled]="currentObjectService.isEditing"
        ></app-template-group-select-button>
      </div>
    </div>
  </div>

  <app-search-view-selector
    *ngIf="operationContainer?.selectorEnabled"
    class="information__selector"
    [selectorContainer]="operationContainer"
  ></app-search-view-selector>
  <app-search-view-selector
    class="information__selector"
    *ngIf="templateSelectorContainer.selectorEnabled"
    [selectorContainer]="templateSelectorContainer"
  ></app-search-view-selector>
  <div *ngIf="showImageOrder"
       class="side-selector change-img-order-selector information__change-image-order-selector">
    <app-change-media-order
      *ngIf="operationContainer.rootSectionsContainer.rootObject && operationContainer.rootSectionsContainer.rootObject.$$mediaContainer?.mediaTypes.length"
      [mediaContainer]="operationContainer.rootSectionsContainer.rootObject.$$mediaContainer"
      [sectionsContainer]="operationContainer.rootSectionsContainer"
      (toggleChangeMediaOrder)="onToggleChangeImageOrder()"></app-change-media-order>
  </div>
  <div class="information__content">
    <div class="information__lower"
         [ngClass]="{'edit': currentObjectService.isEditing,
         'smallScreen': smallScreen}">
      <div class="information__vertical-menu"
           [@showContentMenu]="smallScreen && openMenu ? 'expandMenu' : 'collapseMenu'">
        <div *ngIf="loadingObjectInfo" class="loading-operation-selector-vertical">
          <div class="loading-operation-selector-vertical__selected-first-menu"
               [ngClass]="{'isEditing': currentObjectService.isEditing}"></div></div>
        <app-operation-selector-vertical
          *ngIf="!loadingObjectInfo && operationContainer"
          [operationContainer]="operationContainer"
          [contentList]="operationContainer.contentInfo.curListName"
          [smallScreen]="smallScreen"
          [editContent]="currentObjectService.isEditing"
          (selectOperation)="onSelectOperation($event)"
        ></app-operation-selector-vertical>
      </div>
      <div class="information__lower-content"
           [@showContentMenu]="smallScreen && openMenu ? 'pushContent' : 'pullContent'">

        <div *ngIf="loadingObjectInfo" class="information__loading-content">
          <div class="information__loading-content-text-container">
            <mat-spinner color="accent" diameter="40"></mat-spinner>
            <span translate="TRANS__EDIT_FIELD_SELECT__HIERARCHY__LOADING"></span></div></div>

        <ng-container *ngIf="!loadingObjectInfo && operationContainer?.contentInfo">
          <app-information-headline
            [isEditing]="currentObjectService.isEditing"
            [currentGroupOperation]="operationContainer.currentSelectedGroupOperation"
            [smallScreen]="smallScreen"
            [operationContainer]="operationContainer"
            (toggleMenu)="onToggleMenu()"
            (edit)="setEdit()"
            (objectLoad)="loadObject()">
          </app-information-headline>

          <app-information-view-and-edit
            *ngIf="operationContainer?.rootSectionsContainer?.rootObject &&
             operationContainer?.contentInfo.curListName === 'overview'"
            [isEditing]="currentObjectService.isEditing"
            [sectionsContainer]="operationContainer.rootSectionsContainer"
            class=""></app-information-view-and-edit>


          <app-object-content-list-source-array
            *ngIf="operationContainer.contentInfo.curContentListSource &&
            operationContainer?.contentInfo.curListName !== 'overview'"
            [contentListSource]="operationContainer.contentInfo.curContentListSource"
            (objectRefresh)="loadObject()"
            (newObjectCreated)="onNewObjectCreated($event)"
          ></app-object-content-list-source-array>

          <app-object-content-list-regular
            *ngIf="operationContainer.contentInfo.curListContainer"
            [contentListContainer]="operationContainer.contentInfo.curListContainer"
            (objectRefresh)="loadObject()"
          ></app-object-content-list-regular>

          <span *ngIf="noObjectId" translate="TRANS__PROCEDURE__UNABLE_TO_FIND_PROCEDURE"></span>
          <div  *ngIf="!operationContainer?.rootSectionsContainer?.rootObject &&
          !operationContainer.contentInfo.curListContainer && !operationContainer.contentInfo.curContentListSource">
            <span translate="TRANS__CONTENT_LIST__NO_CONTENT"></span></div>
        </ng-container>

      </div>
    </div>
  </div>

  <div class="object-page__action-toolbar"
       *ngIf="currentObjectService.isEditing">
    <app-operation-toolbar
      *ngIf="operationContainer?.currentOperation?.$$currentStep"
      [operationContainer]="operationContainer"
      [operationStep]="operationContainer.currentOperation.$$currentStep"
    ></app-operation-toolbar>
  </div>

  <div class="overlay"
       *ngIf="operationContainer?.selectorEnabled || templateSelectorContainer.selectorEnabled"
       (click)="selectorOverlay()"></div>
  <div class="overlay"
       *ngIf="operationContainer?.rootSectionsContainer?.rootObject?.$$mediaContainer && showImageOrder"
       (click)="onToggleChangeImageOrder()"></div>
</div>

