import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AfterCloseOperation, OperationService} from '../operation.service';
import {OperationDef} from '../../core/definitions/operation-def';
import {ContentListBaseContainer} from '../../core/definitions/object-content-tab/content-list-base-container';

@Component({
  selector: 'app-operation-menus-content-list',
  templateUrl: './operation-menus-content-list.component.html',
  styleUrls: ['./operation-menus-content-list.component.scss']
})
export class OperationMenusContentListComponent implements OnInit {

  @Input() contentListContainer: ContentListBaseContainer;
  @Input() disabled;
  @Output() operationExecuted = new EventEmitter();

  constructor(private operationService: OperationService) {
  }

  ngOnInit(): void {
  }

  openOperation(operation: OperationDef, parentOperation?: OperationDef) {
    this.operationService.openOperationView(this.contentListContainer.operationContainer, operation, parentOperation,
      (afterCloseOperation: AfterCloseOperation) => {
        console.log('Operation res: ' + afterCloseOperation);
      }).then(res => {
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
  }

  onClickPanelHeader(event, operation: OperationDef) {
    event.stopPropagation();
    if (!operation.sub_operations) {
      this.openOperation(operation);
    }
  }

  toggleOperationMenus() {
    if (!this.disabled) {
      this.contentListContainer.searchContainer.toggleActionMenu = !this.contentListContainer.searchContainer.toggleActionMenu;
    }
    this.contentListContainer.searchContainer.toggleSortMenu = false;
  }

  toggleOperationMenu(operation: OperationDef) {
    this.operationService.toggleOperationMenu(this.contentListContainer.operationContainer, operation).then(res => {
      this.toggleOperationMenus();
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
  }

  get selectedCount() {
    return this.contentListContainer.searchContainer.selections.selectedItems.length;
  }

}
