@if (operationContainer) {
  <div class="object-new-page" id="scrollToThisFromCopyCallback">
    <div class="object-new-page__container" id="innerCon">

      <div class="object-new-page__top">

        <div class="object-new-page__top-content">
          <h1>
            @if (objectTypeName) {
              <app-object-icon
                class="object-new-page__top-icon"
                [objectType]="objectTypeName"></app-object-icon>
            }
            <span translate="TRANS__MODAL__CREATE"></span>
            <span>:</span>
            <span class="object-new-page__top-object"
                  [translate]="newObjectText"></span>
          </h1>
        </div>

        <div class="object-new-page__copy">
          @if (object.create_multiple !== undefined) {
            <button mat-stroked-button
                    class="object-new-page__create-multiple-button"
                    color="primary"
                    type="button"
                    [class.active]="createMultiple"
                    (click)="enableCreateMultiple()">
              <i class="icon-create-new"></i>
              <span class="bold uppercase"
                    translate="TRANS__OBJECT_NEW__CREATE_MULTIPLE_BUTTON"></span></button>
          }

          @if (newModelInfo) {
            @for (selector of newModelInfo.selectors; track selector) {
              <button mat-stroked-button
                      class="object-new-page__copy-button"
                      color="primary"
                      type="button"
                      [disabled]="isCopy"
                      (click)="enableSelector(selector)">
                <i class="icon-copy"></i>
                <span class="bold uppercase"
                      [translate]="selector.selector_button_text"></span></button>
            }
          }
          @if (createMultiple) {
            <div class="object-new-page__create-multiple">
              <mat-form-field appearance="outline">
                <mat-label translate="TRANS__OBJECT_NEW__MULTIPLE_OBJECT_COUNT"></mat-label>
                <input matInput type="number"
                       [(ngModel)]="createMultipleCount"
                       [min]="1"
                       [max]="maxCreateCount"
                       (change)="setMultipleCount()">
              </mat-form-field>

              @if (createMultipleOperationContainer?.operations?.length) {
                <button mat-stroked-button
                        class="object-new-page__create-folder-button"
                        color="primary"
                        type="button"
                        *ngFor="let operation of createMultipleOperationContainer.operations"
                        [disabled]="!!createMultipleFolder"
                        (click)="openMultipleCreationOperation(operation)">
                  <i class="{{operation.menu_icon}}"></i>
                  <span class="bold uppercase"
                        [translate]="operation.menu_title"></span>
                </button>
              }
              @if (createMultipleFolder) {
                <span class="bold object-new-page__created-folder">
              <span translate="TRANS__OBJECT_NEW__CREATED_FOLDER"></span>
              <span translate="TRANS__OBJECT_NEW__TARGET_FOLDER"></span>: {{createMultipleFolder.artifact_name}}</span>
              }
            </div>
          }

        </div>

        @if (!isCopy && operationContainer?.rootSectionsContainer) {
          <app-object-new-object-view
            [selectorContainer]="selectorContainer"
            [sectionsContainer]="operationContainer.rootSectionsContainer"
          ></app-object-new-object-view>
        }

        @if (isCopy && operationContainer?.rootSectionsContainer && selectorContainer) {
          <app-object-new-copy-view
            [object]="object"
            [createMultiple]="createMultiple"
            [selectorContainer]="selectorContainer"
            [sectionsContainer]="operationContainer.rootSectionsContainer"
          ></app-object-new-copy-view>
        }
      </div>

      <div class="object-new-page__sidebar">
        @if (canSelectImages()) {
          <app-media-selector-image-vertical
            [object]="object"
            [fieldName]="'images'"
            [target]="object"
            [curAnn]="currentObjectService.curAnn"
            [multipleImages]="object.object_type !== 'user' && object.object_type !== 'actor'"
          ></app-media-selector-image-vertical>
        }

        @if (selectorContainer && selectorContainer.selectorEnabled) {
          <app-search-view-selector
            [selectorContainer]="selectorContainer"
          ></app-search-view-selector>
        }
      </div>

      @if (debug) {
        <div class="row">
          <pre>{{object | json}}</pre>
        </div>
      }
    </div>

    @if (object && operationContainer?.currentOperation?.$$currentStep) {
      <div class="row relative">
        <app-operation-toolbar
          [operationContainer]="operationContainer"
          [operationStep]="operationContainer.currentOperation.$$currentStep"
          [disabled]="createMultipleCount > maxCreateCount"
        ></app-operation-toolbar>
      </div>
    }
  </div>
}
