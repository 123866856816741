import {Component, Input, OnInit} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {FieldStateService} from '../../core/field-state.service';
import {MetaField} from '../../core/definitions/meta-field';

@Component({
  selector: 'app-field-tool-tip',
  templateUrl: './field-tool-tip.component.html',
  styleUrls: ['./field-tool-tip.component.scss']
})
export class FieldToolTipComponent implements OnInit {

  loading = false;
  showToolTip = false;
  fieldName;
  tooltip = {
    loaded: false,
    default: '',
    spectrum: ''
  };

  @Input() field: MetaField;
  @Input() rootObject;

  constructor(private cms: CmsApiService,
              private fieldStateSvc: FieldStateService) {
  }

  ngOnInit() {
    this.fieldName = this.fieldStateSvc.getFieldTitle(this.field, this.rootObject, true, false);
  }

  getToolTip(field: MetaField) {
    this.cms.getToolTip({field_uuid: field.field_uuid}).then(
      (data) => {
        this.tooltip.spectrum = data['spectrum'];
        this.tooltip.default = data['default'];
        this.tooltip.loaded = true;
        this.loading = false;
      },
      () => {
        this.loading = false;
        console.warn('failed to get tooltip for field ' + field.field_uuid);
      }
    );
  }

  toggleToolTip() {
    if (this.showToolTip) {
      this.loading = true;
      this.getToolTip(this.field);
    }
  }
}
