import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {AConst} from '../../core/a-const.enum';
import {MetaField} from '../../core/definitions/meta-field';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {FieldDateInfoService} from "../../core/field-date-info.service";
import {System} from "../../core/definitions/system";

@Component({
  selector: 'app-edit-field-precision-date-range',
  templateUrl: './edit-field-precision-date-range.component.html',
  styleUrls: ['./edit-field-precision-date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldPrecisionDateRangeComponent)
    }
  ]

})
export class EditFieldPrecisionDateRangeComponent implements OnInit, ControlValueAccessor {
  @Input() fieldParameters: FieldParameters;
  @Input() fromDateField: MetaField;
  @Input() fromDateControlName: string;

  toDateField: MetaField;
  toDateControlName: string;

  AConst = AConst;
  fromDateName: string;
  toDateName: string;
  fromDateControl: AbstractControl;
  toDateControl: AbstractControl;
  placeholderFromDate = 'not set';
  placeholderToDate = 'not set';

  inFocus = false;

  constructor(private fieldDateInfoService: FieldDateInfoService) {
  }

  ngOnInit() {
    this.setToDateInfo();
    this.placeholderFromDate = this.fromDateField.placeholder || this.fromDateField.title;
    this.placeholderToDate = this.toDateField.title;
    this.fromDateControl = this.fieldParameters.sectionsContainer.formGroup.controls[this.fromDateControlName];
    if (!this.fromDateControl) {
      console.error('Control not found: ' + this.fromDateControlName);
    }
    this.toDateControl = this.fieldParameters.sectionsContainer.formGroup.controls[this.toDateControlName];
    if (!this.toDateControl) {
      console.error('Control not found: ' + this.toDateControlName);
    }
  }

  registerOnChange(/*fn: any*/): void {
    // N/A
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  setDisabledState(/*isDisabled: boolean*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }

  private setToDateInfo() {
    let parentName = '';
    const lastDot = this.fromDateControlName.lastIndexOf(System.keySep);
    if (lastDot !== -1) {
      parentName = this.fromDateControlName.substring(0, lastDot) + System.keySep;
    }
    const dateInfo = this.fieldDateInfoService.getFieldDateInfo(this.fieldParameters.field);
    const toDateName = dateInfo.to_date_field;
    this.toDateField = this.fieldParameters.object.$$meta[toDateName];
    this.toDateControlName = parentName + toDateName;
    this.fromDateName = this.fromDateField.name;
    this.toDateName = this.toDateField.name;
  }


}
