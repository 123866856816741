<div class="edit-field-date-picker field-date-input"  [formGroup]="fieldParameters.sectionsContainer.formGroup">
  <input
    [formControl]="pickerDateControl"
    [placeholder]="placeholder"
    title="{{placeholder}}"
    [matDatepicker]="myDatepicker"
    (dateChange)="onDateSelection($event)"
    [id]="formControlName"
    [name]="formControlName"
    type="text"
    (blur)="onFieldBlur()"
    (focus)="onFieldFocus()"
    (keyup)="onValueChange($event)"
    autocomplete="off"
    [min]="minDate"
    [max]="maxDate"
    [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy, 'errorInputFrame': isInvalid()}">
  <mat-datepicker-toggle [for]="myDatepicker">
    <i class="icon-calendar" matDatepickerToggleIcon></i></mat-datepicker-toggle>
  <mat-datepicker #myDatepicker></mat-datepicker>
</div>
