import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {OperationService} from '../operation.service';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationContainer} from '../../core/definitions/operation-container';

// 'Simpler' operation menu component displaying max two hierarchy levels

@Component({
  selector: 'app-operation-menus-two-levels',
  templateUrl: './operation-menus-two-levels.component.html',
  styleUrls: ['./operation-menus-two-levels.component.scss']
})
export class OperationMenusTwoLevelsComponent implements OnChanges {

  @Input() operationContainer: OperationContainer;
  @Input() parentOperation: OperationDef;
  @Output() operationOpened = new EventEmitter<OperationDef>();

  operations: OperationDef[];

  constructor(private operationService: OperationService) {
  }

  ngOnChanges(): void {
    if (this.parentOperation) {
      if (this.parentOperation.sub_operations) {
        this.operations = this.parentOperation.sub_operations;
      } else {
        this.operations = [this.parentOperation];
      }
    } else {
      this.operations = this.operationContainer.operations;
    }
  }

  openOperation(operation: OperationDef) {
    this.operationService.toggleOperationMenu(this.operationContainer, operation).then(res => {
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
    if (!operation.sub_operations) {
      this.operationOpened.emit(operation);
    }
  }

}
