<mat-form-field class="select-input-wrapper">
  <mat-label>{{ label | translate }}</mat-label>
  <!--suppress HtmlFormInputWithoutLabel -->
  <input matInput
         type="text"
         [formControl]="searchableSelectControl"
         [matAutocomplete]="searchableSelect"
         [placeholder]="placeholder | translate"
         autocomplete="off"/>

  <mat-hint>{{ getSelectedOptionNames() || ('TRANS__ADVANCED_SEARCH__SEARCH_DROPDOWN_HINT' | translate) }}</mat-hint>

  <mat-progress-spinner matSuffix
                        *ngIf="loading"
                        mode="indeterminate"
                        color="accent"
                        [diameter]="15"
                        [strokeWidth]="3">
  </mat-progress-spinner>

  <!--  FIXME: Proper styling and event handling when mode is multiselect-->
  <mat-autocomplete #searchableSelect="matAutocomplete"
                    [displayWith]="displayWith">
    <mat-option *ngIf="availableOptionCount === 0" disabled>
      {{ 'TRANS__ADVANCED_SEARCH__SEARCH_DROPDOWN__NO_OPTIONS_AVAILABLE' | translate }}
    </mat-option>
    <mat-option *ngFor="let opt of options$ | async"
                [value]="opt"
                (onSelectionChange)="setSearchValue($event)">
      <mat-checkbox [checked]="isSelected(opt)" *ngIf="multiple"></mat-checkbox>
      {{ displayWith(opt) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
