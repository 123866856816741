<app-primus-solr-data-table-filter [filterHeading]="(parentName || 'TRANS__ADMIN__PLACE__FILTER_HEADING') | translate"
                                   searchBarPlaceholder="TRANS__ADMIN__PLACE__FILTER_INPUT_PLACEHOLDER"
                                   (filterStringChange)="filterStringChanged.emit($event)">
  <div class="admin-stroked-button">

    <button mat-stroked-button
            type="button"
            [mat-menu-trigger-for]="ActionMenu"
            [disabled]="selectedPlaces.length === 0">
      <mat-icon class="mat-button-icon-prefix">more_horiz</mat-icon>
      <span class="bold uppercase admin-stroked-button__left-margin"
            translate="TRANS__ADMIN__LOCATION__SELECT_BUTTON_LABEL"></span>
    </button>

    <mat-menu #ActionMenu="matMenu">
      <app-operation-dropdown-menu
        [contextObjects]="operationContextObjects"
        [operationTarget]="'admin_list_view'"
        [showSubMenusOnly]="true"
        (operationPerformed)="onActionPerformed($event)"
      ></app-operation-dropdown-menu>
    </mat-menu>
  </div>
  <app-primus-menu-button color="primary"
                          menuButtonLabel="TRANS__PRIMUS_TABLE__FILTER__FILTERS"
                          menuButtonIcon="tune"
                          [menuItems]="filterItems"
                          (menuItemClicked)="handleFilterClicked($event)">
  </app-primus-menu-button>
</app-primus-solr-data-table-filter>
