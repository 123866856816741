import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SelectFieldTypeDialogData} from "../admin-service-import.component";
import {ImportField} from "../import.service";

@Component({
  selector: 'app-select-field-dialog',
  templateUrl: './select-field-dialog.component.html',
  styleUrls: ['./select-field-dialog.component.scss']
})
export class SelectFieldDialogComponent {
  selectedField: ImportField;

  constructor(
    public dialogRef: MatDialogRef<SelectFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectFieldTypeDialogData
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
