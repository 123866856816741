<table mat-table [dataSource]="dataSource"
       matSort matSortActive="index_query_field" matSortDisableClear matSortDirection="asc"
       style="width: 100%">
  <ng-container matColumnDef="field_uuid">
    <th mat-header-cell *matHeaderCellDef>Field uuid</th>
    <td mat-cell *matCellDef="let element">{{element['field_uuid']}} </td>
  </ng-container>

  <ng-container matColumnDef="field_title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="field_title" disableClear>Field title</th>
    <td mat-cell *matCellDef="let element">{{element['field_title']}} </td>
  </ng-container>

  <ng-container matColumnDef="index_query_field">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="index_query_field" disableClear>Field name</th>
    <td mat-cell *matCellDef="let element">{{element['index_query_field']}} </td>
  </ng-container>

  <ng-container matColumnDef="superobject_type_ids_values">
    <th mat-header-cell *matHeaderCellDef>Objects</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngFor="let superObject of element['superobject_type_ids_values']; index as i">
        <ng-container *ngIf="superObject !== 'Alle' && (element['$$showAll'] || i < 8)">
          <span>{{superObject}} </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="element['superobject_type_ids_values'].length > 8">
        <a (click)="element['$$showAll']=!element['$$showAll']" style="color: #3b4040; font-weight: bold"> Toggle show all</a>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="fieldNames"></tr>
  <tr mat-row *matRowDef="let row; columns: fieldNames"></tr>

</table>
