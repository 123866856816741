import {Injectable} from '@angular/core';
import {CommonsService} from '../core/commons.service';
import {SearchContainer} from '../core/definitions/search-container';
import {SearchViewPath} from '../core/definitions/search-objects';
import {CmsApiService} from '../core/cms-api.service';
import {Focus} from '../core/definitions/focus';
import {FocusData} from '../core/definitions/focus-data';
import {LoggerService} from "../core/logger.service";
import {BehaviorSubject, Subject} from 'rxjs';

export class FocusServiceImplementation {

  private readonly focusParamKeys = [
    'path',
    'queryContainer.query',
    'queryContainer.selectedQueryMenu',
    'filtersFacets.checkedFilters',
    'order',
    'searchResultViewName',
    'templateGroupId',
    'advancedSearchParams',
  ];
  public curFocusId = '';

  constructor(private searchContainer: SearchContainer,
              private commons: CommonsService,
              private readonly cmsApi: CmsApiService,
              private logger: LoggerService) {
  }

  public async loadSavedFocuses(): Promise<Array<Focus>> {
    const focuses: Array<Focus> = await this.cmsApi.getStoredFocusesForUser();
    this.searchContainer.focus.focuses = focuses;
    return focuses;
  }

  public getFocuses(): Array<Focus> {
    return this.searchContainer.focus.focuses || [];
  }

  public getFocus(focusId: string): Focus | null {
    const focus: Focus = this.getFocuses().find(f => f.focusId === focusId);
    return focus ? focus : null;
  }

  public setCurrentFocus(focusOrId: string | Focus): Focus {
    let focus: Focus;
    if (typeof focusOrId === 'object') {
      focus = focusOrId;
    } else {
      focus = this.getFocus(focusOrId);
    }
    this.searchContainer.focus.curFocusId = focus ? focus.focusId : null;
    this.searchContainer.focus.curFocus = focus ? focus : null;
    this.searchContainer.focus.currentFocusName = focus ? focus.focus.name : null;
    if (!this.searchContainer.focus.curFocusId) {
      this.curFocusId = null;
    }
    return focus;
  }

  public getCurrentFocus(): Focus {
    return this.searchContainer.focus.curFocus;
  }

  public getDefaultFocus(): Focus | null {
    const focus: Focus = this.getFocuses().find(f => f.focus.isDefault);
    return focus ? focus : null;
  }

  public getCurrentOrDefaultFocus(): Focus | null {
    let focus: Focus = this.getCurrentFocus();
    if (!focus) {
      focus = this.getDefaultFocus();
    }
    return focus;
  }

  public setFocusPathParamsOnSearchContainer(pathParams: object): void {
    pathParams = pathParams || {};
    for (const paramKey of this.focusParamKeys) {
      this.commons.setObjectValueFromPath(this.searchContainer, paramKey, pathParams[paramKey], true);
    }
  }

  public getSetFocusPathParams(focusId: string, path: string): Array<object> {
    const focus: Focus = this.getFocus(focusId);
    let pathParams: any;
    if (focus && focus.focus.pathParams) {
      pathParams = focus.focus.pathParams[path];
      if (!pathParams) {
        if (path.indexOf(focus.focus.homePath) === 0) {
          pathParams = this.createFocusPathParams(focus.focus, path);
        }
      }
    }
    if (!pathParams) {
      this.setCurrentFocus(null);
    }
    return pathParams;
  }

  public async createOrUpdateFocus(focus: Focus, privateFocus: boolean = true): Promise<Focus> {
    if (focus && focus.focus) {
      if (this.nameOfFocusAlreadyExists(focus.focusId, focus.focus.name)) {
        throw Error('Name already exists'); // Rejects returned promise
      }
      focus.private = privateFocus;
      focus.focus.stored = true;
      focus.focus.pathParams[this.searchContainer.path] = this.getFocusPathParamsFromSearchContainer();
      await this.cmsApi.createOrUpdateFocus(focus);
      const existingIds: Array<string> = this.getFocuses().map(f => f.focusId);
      await this.loadSavedFocuses();
      focus = this.getFocuses().find(f => {
        return f.focusId === focus.focusId || existingIds.indexOf(f.focusId) === -1;
      });
    }
    return focus;
  }

  public createNewFocus(): Focus {
    const focus: Focus = {
      private: true,
      focus: {
        name: '',
        isDefault: false,
        marked: true,
        homePath: this.searchContainer.path,
        pathParams: {},
        stored: false
      }
    };
    focus.focus.pathParams[this.searchContainer.path] = null;
    return focus;
  }

  public async storeFocusAsNewFocus(focus: Focus, newName: string): Promise<Focus> {
    if (focus && focus.focusId && focus.focus) {
      const oldFocus: Focus = this.getFocus(focus.focusId);
      let newFocus: Focus = JSON.parse(JSON.stringify(focus));
      delete newFocus.focusId;
      newFocus.focus.name = newName;

      if (newFocus.focus.isDefault) {
        oldFocus.focus.isDefault = false;
      }

      oldFocus.focus.pathParams[this.searchContainer.path] = JSON.parse(JSON.stringify(
        newFocus.focus.pathParams[this.searchContainer.path]
      ));

      newFocus.focus.pathParams[this.searchContainer.path] = this.getFocusPathParamsFromSearchContainer(newFocus.focus.path);

      await this.createOrUpdateFocus(oldFocus, oldFocus.private);
      newFocus = await this.createOrUpdateFocus(newFocus, newFocus.private);

      this.setCurrentFocus(newFocus);
      return newFocus;
    }
  }

  public focusHasChanges(focus: Focus): boolean {
    const excludeKeys: Array<keyof Focus | keyof FocusData> = [
      '$$optionNameWeighed',
      '$$weight',
      'settingMenu'
    ];

    const currentCopy: Focus = JSON.parse(JSON.stringify(focus));
    const storedCopy: Focus = JSON.parse(JSON.stringify(this.getFocus(focus.focusId)));

    excludeKeys.forEach(key => {
      delete currentCopy[key];
      delete currentCopy.focus[key];
      if (storedCopy) {
        delete storedCopy[key];
        delete storedCopy.focus[key];
      }
    });

    let changed = !this.commons.areObjectsEqual(currentCopy, storedCopy);
    if (!changed) {
      const searchPathParams = this.getFocusPathParamsFromSearchContainer(this.searchContainer.path);
      const focusPathParams = focus.focus.pathParams[this.searchContainer.path];
      changed = !this.commons.areObjectsEqual(searchPathParams, focusPathParams);
    }
    return changed;
  }

  public async deleteFocus(focusId: string): Promise<void> {
    if (this.getCurrentFocus() && this.getCurrentFocus().focusId === focusId) {
      this.setCurrentFocus(null);
    }

    try {
      await this.cmsApi.deleteFocus(focusId);
      await this.loadSavedFocuses();
    } catch (e) {
      this.logger.error(`Failed to delete focus ${focusId}`, e);
      throw e;
    }
  }

  public toggleFocusIsDefault(focus: Focus): void {
    if (!focus) {
      return;
    }
    focus = this.getFocus(focus.focusId);
    if (focus) {
      focus.focus.isDefault = !focus.focus.isDefault;
    }
  }

  private nameOfFocusAlreadyExists(focusId: string, name: string): boolean {
    return this.getFocuses()
      .filter(f => f.focusId !== focusId)
      .map(f => f.focus.name)
      .indexOf(name) > -1;
  }

  private createFocusPathParams(focus: FocusData, path: string): any {
    focus.pathParams[path] = this.getFocusPathParamsFromSearchContainer(path);
    focus.pathParams[path].path = path;
    return focus.pathParams[path];
  }

  private getFocusPathParamsFromSearchContainer(path?: string): any {
    const res = {};
    this.focusParamKeys.forEach(paramKey => {
      let value = this.commons.getObjectValueFromPath(this.searchContainer, paramKey);
      if (value !== undefined) {
        value = this.commons.copy(value, {ignoreEmptyArray: true});
        let resVal = value;
        if (path && paramKey === 'filtersFacets.checkedFilters') {
          resVal = this.getValidCheckedFilters(value, path);
        }
        res[paramKey] = resVal;
      }
    });
    return res;
  }

  // Remove filters that are not relevant for the path view, e.g.
  // "object_type" if going from "home/artifacts" to "home/artifacts/design"
  private getValidCheckedFilters(filtersIn: {[name: string]: any[]}, path: string): {[name: string]: any[]} {
    const filters: {[name: string]: any[]} = {};
    const searchView = this.searchContainer.currentPathView.search_view;
    if (!this.searchContainer.filtersFacets.filterGroups || this.curFocusId !== this.searchContainer.focus.curFocusId) {
      if (searchView.check_filter_groups) {
        this.searchContainer.filtersFacets.filterGroups = JSON.parse(JSON.stringify(searchView.check_filter_groups));
      } else {
        this.searchContainer.filtersFacets.filterGroups = [];
      }
      this.curFocusId = this.searchContainer.focus.curFocusId;
    }
    for (const [filterName, filterValues] of Object.entries(filtersIn)) {
      filters[filterName] = [...filterValues];
    }
    const validFilters = {};
    const pathViews: Array<SearchViewPath> = this.commons.filter(this.searchContainer.searchView.paths, {path_name: path});
    if (pathViews && pathViews.length > 0) {
      this.setValidFilters(validFilters);
      for (const filterName in filters) {
        if (!validFilters[filterName]) {
          delete filters[filterName];
        }
      }
    }
    this.setEnabledFilterGroups(filters);
    return filters;
  }

  private setEnabledFilterGroups(filters: {[name: string]: any[]} = {}) {
    const filterGroups = this.searchContainer.filtersFacets.filterGroups;
    const filterNames = Object.keys(filters);
    for (const filterGroup of filterGroups) {
      const filterGroupFilterNames = filterGroup.filters.map(filter => filter.name);
      if (filterNames.some(filterName => filterGroupFilterNames.includes(filterName))) {
        filterGroup.enabled = true;
      }
    }
  }
  private setValidFilters(validFilters: {[name: string]: boolean} = {}) {
    const filterGroups = this.searchContainer.filtersFacets.filterGroups;
    if (filterGroups) {
      for (const filterGroup of filterGroups) {
        for (const filter of filterGroup.filters) {
          validFilters[filter.name] = true;
        }
      }
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SearchFocusService {

  constructor(private readonly commons: CommonsService,
              private readonly cmsApi: CmsApiService,
              private readonly logger: LoggerService ) {
  }

  private _openFocusEditPanel$ = new Subject<boolean>();
  openFocusEditPanel$ = this._openFocusEditPanel$.asObservable();

  public openFocusEditPanel() {
    this._openFocusEditPanel$.next(true);
  }

  public createFSI(searchContainer: SearchContainer): FocusServiceImplementation {
    return new FocusServiceImplementation(searchContainer, this.commons, this.cmsApi, this.logger);
  }

  /**
   *
   * @param {string} path
   * @param {SearchContainer} searchContainer
   * @return {boolean}
   */
  public checkPathParamsExists(path: string, searchContainer: SearchContainer): boolean {
    let res = false;
    if (searchContainer.focus.curFocusId) {
      for (const focus of Object.values(searchContainer.focus.curFocus.focus.pathParams)) {
        if (focus && focus.path === path) {
          res = true;
          break;
        }
      }
    }
    return res;
  }
}
