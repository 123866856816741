import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-admin-id-format-filter',
  templateUrl: './admin-id-format-filter.component.html',
  styleUrls: ['./admin-id-format-filter.component.scss']
})
export class AdminIdFormatFilterComponent<A> implements OnInit, OnDestroy {

  @Input() heading: string;
  @Input() description: string;
  @Input() actions: Array<A>;
  @Input() actionDisplayProperty?: keyof A;

  @Output() public readonly actionClicked: EventEmitter<A>;
  @Output() public readonly filterChanged: EventEmitter<string>;

  readonly filterControl: UntypedFormControl = new UntypedFormControl();
  private filterChangeSubscription: Subscription;

  constructor() {
    this.actionClicked = new EventEmitter<A>();
    this.filterChanged = new EventEmitter<string>();
  }

  ngOnInit(): void {
    this.filterChangeSubscription = this.filterControl.valueChanges.pipe(
      debounceTime(250),
    ).subscribe(value => this.filterChanged.emit(value));
  }

  ngOnDestroy(): void {
    if (this.filterChangeSubscription && !this.filterChangeSubscription.closed) {
      this.filterChangeSubscription.unsubscribe();
    }
  }

}
