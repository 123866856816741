import {
    MAT_DIALOG_DATA,
    MatDialogRef
} from "@angular/material/dialog";
import {Component, Inject, Input, OnInit} from "@angular/core";
import {AppNotification} from "../notification.service";
import {CmsApiService} from "../../core/cms-api.service";
import {UserCacheService} from "../../core/user-cache.service";

export interface DialogData {
    notification: AppNotification;
}

@Component({
    selector: 'notification-send-email',
    styleUrl: './notification-send-email.component.scss',
    templateUrl: 'notification-send-email.component.html'
})
export class NotificationSendEmailComponent implements OnInit {
    @Input() actionBeforeError = '';
    @Input() searchValue = '';
    @Input() openObject = '';
    actionBeforeErrorInputError = null;
    showEmailSuccess = false;

    constructor(public dialogRef: MatDialogRef<NotificationSendEmailComponent>,
            private cmsApiService: CmsApiService,
            private userCacheService: UserCacheService,
            @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    async sendNotification(): Promise<void> {
        const userData = await this.userCacheService.getUserData(false, false);

        this.actionBeforeErrorInputError = null;

        if (this.actionBeforeError.length < 10) {
            this.actionBeforeErrorInputError = 'Vennligst fyll inn en beskrivelse av hva som skjedde før feilen oppstod';
            document.getElementById('actionBeforeErrorInputField').focus();
            return;
        }

        let notificationData = {
            notification: {
                messages: this.data.notification.messages,
                type: this.data.notification.type,
                status: this.data.notification.status,
                correlationId: this.data.notification.correlationId,
                actionBeforeError: this.actionBeforeError,
                searchValue: this.searchValue,
                openObject: this.openObject,
                url: window.location.href,
                reporterEmail: userData.email
            }
        };

        this.cmsApiService.sendNotificationEmail(notificationData).then((response) => {
            this.showEmailSuccess = true;
        });


    }

    onNoClick(): void {
        this.actionBeforeError = '';
        this.searchValue = '';
        this.openObject = '';

        this.dialogRef.close();
    }

    ngOnInit(): void {
    }
}
