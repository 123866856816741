<div class="dialog">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title" translate="TRANS__OBJECT_USAGE__TITLE"></h1>

    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <div mat-dialog-content class="modal-body">
    <div class="modal-main-content">
      <span *ngIf="data.hasUsageMessage" [translate]="data.hasUsageMessage"></span>
      <span translate="TRANS__OBJECT_USAGE__INFORMATION"
            [translateParams]="{'usageSize': data.objects.length}"></span>
      <ul>
        <li *ngFor="let object of data.objects">
          <app-object-url [object]="object">
            <app-object-name class="object-url-content"  [object]="object"></app-object-name>
          </app-object-url>
        </li>
      </ul>
    </div>
  </div>

  <div mat-dialog-actions class="modal-footer">
    <button type="submit"
            mat-flat-button
            color="accent"
            (click)="dialogRef.close()">
      <span class="bold" translate="TRANS__MODAL_DIALOG__OK"></span></button>
  </div>


</div>
