import {Injectable} from '@angular/core';
import {SectionsContainer} from './definitions/sections-container';

@Injectable({
  providedIn: 'root'
})
export class FieldValidationService {

  private missingControls = {};

  constructor() {
  }

  isFieldInvalid(sectionsContainer: SectionsContainer, formControlName: string) {
    const field = sectionsContainer.formGroup.controls[formControlName];
    let res = false;
    if (field) {
      // Since the form.ngSubmit.emit() doesn't cause form.submitted to be set, need to use attribute from sections container
      res = (field.touched || !field.pristine || sectionsContainer.submitted) && field.invalid;
    } else {
      if (!this.missingControls[formControlName]) {
        console.warn('Field key \'' + formControlName + '\' not found in form group controls');
        this.missingControls[formControlName] = true;
      }
    }
    return res;
  }
}
