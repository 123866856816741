import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  OperationViewDialogComponent,
  OperationViewDialogData
} from './operation-view-dialog/operation-view-dialog.component';
import {OperationContainer} from '../core/definitions/operation-container';
import {AfterCloseOperation} from './operation.service';

@Injectable({
  providedIn: 'root'
})
export class OperationDialogService {

  constructor(private modalService: MatDialog) {

  }

  async openOperationDialog(operationContainer: OperationContainer): Promise<AfterCloseOperation> {
    return new Promise<any>(resolve => {
      const modalRef = this.modalService.open(OperationViewDialogComponent, {
        disableClose: true,
        panelClass: 'edit-dialog',
        data: {
          operationContainer: operationContainer
        } as OperationViewDialogData
      });
      modalRef.afterClosed().subscribe((data) => {
        resolve(data);
      });
    });
  }

}
