import {Component, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {EventHarvesterService} from '../../core/event-harvester.service';
import {SimpleClientCache} from '../../core/simple-client-cache';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  res = [];
  AConst = AConst;
  parentInfo = {};

  private cache: SimpleClientCache = null;

  constructor(private readonly cms: CmsApiService,
              private readonly commons: CommonsService,
              private readonly eventHarvester: EventHarvesterService) {
    this.cache = new SimpleClientCache('faq');
  }

  ngOnInit() {
    this.parentInfo = {
      adminType: 'faq',
      hasSubMenus: false,
      currentState: window.location.href
    };

    const cachedData = this.cache.get();
    if (cachedData) {
      const d = this.cache.get();
      this.parseData(d);
    }

    this.cms.getFaq().then((data) => {
      if (data[AConst.CATEGORIES]) {
        const d = data[AConst.CATEGORIES];
        this.cache.put(d);

        if (!cachedData) {
          this.parseData(d);
        }
      }
    });
  }

  private parseData(data) {
    this.res = data;
    this.commons.sortArray(this.res, 'category_title');
  }

  toggleCategory(category) {
    if (category.selected === undefined) {
      category.selected = true;
    } else {
      category.selected = !category.selected;
    }
    if (category.selected) {
      this.toggleFq(category.faq[0]);
      this.eventHarvester.faqCategoryClicked(category.category);
    } else {
      category[AConst.FAQ].forEach(faq => {
        faq.selected = false;
      });
    }
  }

  toggleFq(fq) {
    if (fq.selected === undefined) {
      fq.selected = true;
    } else {
      fq.selected = !fq.selected;
    }
  }
}
