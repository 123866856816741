import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AfterCloseOperation, OperationService} from '../../../operations/operation.service';
import {OperationDialogService} from '../../../operations/operation-dialog.service';
import {OperationTarget} from '../../../core/definitions/operation-target.enum';
import {OperationDef} from '../../../core/definitions/operation-def';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SelectorCreationParams} from '../../../core/definitions/selector-creation-params';
import {SelectorContainer} from '../../../core/definitions/selector-container';
import {SearchSelectorService} from '../../../core/search-selector.service';
import {SearchExecutorService} from '../../search-executor.service';

@Component({
  selector: 'app-search-menu-folder-settings',
  templateUrl: './search-menu-folder-settings.component.html',
  styleUrls: ['./search-menu-folder-settings.component.scss']
})
export class SearchMenuFolderSettingsComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Output() operationPerformed = new EventEmitter<AfterCloseOperation>();

  toggleMenu = false;

  constructor(private operationService: OperationService,
              private operationDialogService: OperationDialogService,
              private searchSelectorService: SearchSelectorService,
              private searchExecutorService: SearchExecutorService) {
  }

  ngOnInit(): void {
  }

  toggleFolderSettingMenu() {
    this.getMenus().then();
  }

  async getMenus() {
    if (this.searchContainer.targetObject) {
      this.searchContainer.operationContainerDropDown = await this.operationService.createOperationContainer(
        OperationTarget.DROPDOWN_MENU_VIEW, [this.searchContainer.targetObject]);
      this.setSearchSelector();
      await this.operationService.setOperations(this.searchContainer.operationContainerDropDown);
      this.searchContainer.operationContainerDropDown.openOperationDialogFn = () => {
        this.operationDialogService.openOperationDialog(this.searchContainer.operationContainerDropDown).then(data => {
          this.operationPerformed.emit(data);
        });
      };
    }
  }

  async openOperation(operation: OperationDef) {
    await this.operationService.openOperationView(this.searchContainer.operationContainerDropDown, operation, null,
      operationRes => {
        console.log('Operation res: ' + operationRes);
      }
    );
  }


  private setSearchSelector() {
    this.searchContainer.operationContainerDropDown.enableSelector = (selector, params: SelectorCreationParams) => {
      this.searchContainer.operationContainerDropDown.contentInfo.setSelectorUsed(selector);
      this.searchSelectorService.enableSelector(selector, <SelectorContainer>this.searchContainer.operationContainerDropDown, params,
        {
          searchContainerCreated: () => {
          },
          selectorCallback: selectedObj => {
            this.searchSelectorService.disableSelector(<SelectorContainer>this.searchContainer.operationContainerDropDown);
            this.searchContainer.operationContainerDropDown.selectorCallback(selectedObj, selector, () => {
              this.searchExecutorService.runSearch(this.searchContainer).then();
            });
          }
        }
      );
    };
  }

}
