import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {AnnotationRendererService} from '../../image-annotation/annotation-renderer.service';
import {AnnotationContainer} from '../../image-annotation/annotation-container';
import {AnnotationHandler} from '../../image-annotation/annotation-handler';
import {ImageItem} from '../../core/definitions/image-item';

@Component({
  selector: 'app-active-annotate-image',
  templateUrl: './active-annotate-image.component.html',
  styleUrls: ['./active-annotate-image.component.scss']
})
export class ActiveAnnotateImageComponent implements AfterViewInit, OnDestroy, OnChanges {
  canvas;
  annotationContainer: AnnotationContainer;
  imageSize = {width: 0, height: 0};
  naturalImageSize = {width: 0, height: 0};
  imageLoaded = 0;
  mouseCoord = {x: 0, y: 0};
  newQuality = 70;
  parentContainerId = 'activeImage';
  heightIsSmallerThanContainer = false;
  @Input() curAnn: AnnotationHandler;
  @Input() imgIndex;
  @Input() largeImg;
  @Input() zoomValue;
  @Input() dashboardId;
  @Input() lockHeight;
  @Input() container;
  @Input() edit;

  constructor(private annotationRenderer: AnnotationRendererService,
              private elementRef: ElementRef,
              private mediaHelper: MediaHelperService) {
  }

  ngAfterViewInit() {
    this.canvas = this.getCanvasItem();
    this.imageLoaded = 1;
    this.resizeActiveAnnotationImage(this.zoomValue).then();
    if (this.largeImg == null) {
      this.largeImg = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('imgIndex') && changes.imgIndex.previousValue !== changes.imgIndex.currentValue &&
      !!changes.imgIndex.currentValue) {
      this.imgIndex = changes.imgIndex.currentValue;
      this.setActiveAnnotationImageSize(this.imgIndex);
      this.resizeActiveAnnotationImage(this.zoomValue).then();
    } else {
      if (changes.hasOwnProperty('zoomValue') &&
        changes.zoomValue.previousValue !== changes.zoomValue.currentValue &&
        !changes.zoomValue.firstChange) {
        this.resizeActiveAnnotationImage(changes.zoomValue.currentValue).then();
      }
    }
  }

  getCanvasItem() {
    return this.imgIndex === undefined ? this.elementRef.nativeElement.querySelector('#canvasItem-') :
      this.elementRef.nativeElement.querySelector('#canvasItem-' + this.imgIndex);
  }

  setActiveAnnotationImageSize(index) {
    this.ngOnDestroy();
    this.imgIndex = index;
    setTimeout(() => {
      this.canvas = this.getCanvasItem();
    }, 100);
    setTimeout(() => {
      this.getImageNaturalSize(this.naturalImageSize).then((naturalImageSize) => {
        this.imageSize.width = naturalImageSize.width;
        this.imageSize.height = naturalImageSize.height;
        this.imageLoaded = 1;
      });
    }, 200);
  }

  async resizeActiveAnnotationImage(value): Promise<void> {
    if (this.imageLoaded === 1) {
      this.zoomValue = value;

      const naturalImageSize = await this.getImageNaturalSize(this.naturalImageSize)
      if (this.imageSize.width === 0 && this.imageSize.height === 0) {
        this.imageSize.width = naturalImageSize.width;
        this.imageSize.height = naturalImageSize.height;
      }
      if (Number(value) === 1 && (this.container.offsetHeight > naturalImageSize.height)) {
        this.heightIsSmallerThanContainer = true;
        this.imageSize.height = naturalImageSize.height;
      } else {
        this.heightIsSmallerThanContainer = false;
      }
      this.mediaHelper.resizeImage(
        this.container,
        this.zoomValue,
        this.canvas,
        naturalImageSize,
        this.imageSize, true);
      this.initAnnotateService();
      if (this.canvas.clientWidth > this.container.offsetWidth ||
        this.canvas.clientHeight > this.container.offsetHeight) {
        this.mediaHelper.imgDrag(
          this.container,
          this.imageLoaded,
          this.canvas,
          this.imageSize,
          this.zoomValue);
      } else {
        this.stopCallBack();
      }
    }
  }

  async getImageNaturalSize(naturalImageSize): Promise<any> {
    return new Promise(resolve => {
      const imageObj = new Image();
      const imgArtifact = new ImageItem();
      const imageSize = this.largeImg ? 'large' : 'medium';
      imgArtifact.image_id = this.curAnn.annotateImage.image_id;
      this.mediaHelper.getImageUrl(imgArtifact, imageSize).then(src => {
        imageObj.src = src;
        if (imageObj.src) {
          imageObj.onload = () => {
            naturalImageSize.width = imageObj.naturalWidth;
            naturalImageSize.height = imageObj.naturalHeight;
            resolve(naturalImageSize);
          }
        } else {
          resolve(naturalImageSize);
        }
      });
    });
  }

  initAnnotateService() {
    const ac = new AnnotationContainer();
    this.annotationContainer = ac;
    ac.curAnn = this.curAnn;
    ac.canvas = this.canvas;
    ac.parentContainerId = this.parentContainerId;
    ac.dashboardId = this.dashboardId;
    ac.lockHeight = this.lockHeight;
    ac.heightIsSmallerThanContainer = this.heightIsSmallerThanContainer;
    ac.editable = true;
    ac.imageSize = this.largeImg ? 'large' : 'medium';
    ac.quality = this.newQuality;
    ac.imageId = this.curAnn.annotateImage.image_id;
    this.annotationRenderer.drawAnnotations(this.annotationContainer, {mouseCoord: this.mouseCoord});
  }

  stopCallBack(): void {
    this.mediaHelper.stopCallBack();
  }

  ngOnDestroy() {
    this.stopCallBack();
    this.imageLoaded = 0;
  }
}
