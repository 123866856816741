import {Component, Input, OnInit} from '@angular/core';
import {UploadMediaContainer} from '../../core/definitions/upload-media-container';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationStep} from '../../core/definitions/operation-step';
import {OperationService} from '../operation.service';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationUploadContainer} from '../../core/definitions/operation-upload-container';
import {OperationContainer} from '../../core/definitions/operation-container';
import {BaseModel} from "../../core/definitions/base-model";

@Component({
  selector: 'app-operation-view-upload',
  templateUrl: './operation-view-upload.component.html',
  styleUrls: ['./operation-view-upload.component.scss']
})
export class OperationViewUploadComponent implements OnInit {
  @Input() operationContainer: OperationContainer;
  @Input() operationStepIndex: number;

  uploadMediaContainer: UploadMediaContainer;
  operation: OperationDef;
  operationStep: OperationStep;
  operationUploadContainer: OperationUploadContainer;
  parentObject: SuperObjectModel;

  constructor(private operationService: OperationService) { }

  ngOnInit(): void {
    this.setOperationStep().then();
  }

  handleDeleteImage($e: BaseModel) {
    // Remove the current file from the list of files in the UploadMediaContainer control,
    // in order to remove it from the GUI and the list of files to be uploaded.
    const artifactID = $e.artifact_id;
    const container = this.uploadMediaContainer;

    function remove(storageArr: string, subObjectName?: string) {
      const uploadContainerFileIx = container[storageArr].findIndex((f: BaseModel) => {
        if (!subObjectName) {
          return f.artifact_id === artifactID;
        } else {
          return f[subObjectName].artifact_id === artifactID;
        }
      });
      if (uploadContainerFileIx > -1) {
        container[storageArr].splice(uploadContainerFileIx, 1);
      }
    }
    remove('files');
    remove('fileObjects');
    remove('fileObjectSectionsContainers', 'rootObject');
    this.uploadMediaContainer.changesOccurred = true;
  }

  handleUploadComplete() {
    this.uploadMediaContainer.changesOccurred = true;
  }

  async setOperationStep() {
    await this.operationService.setOperationStep(this.operationContainer);
    this.operation = this.operationContainer.currentOperation;
    this.operationStep = this.operation.$$currentStep;
    this.operationUploadContainer = this.operation.$$operationUploadContainer;
    this.uploadMediaContainer = this.operationUploadContainer.uploadMediaContainers[0];
    this.parentObject = this.operationContainer.art;
  }

}
