import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {VirtualScrollSearchDataSourceColumns} from '../../virtual-scroll-search-data-source-columns';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchHandlerService} from '../../search-handler.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SearchContainer} from '../../../core/definitions/search-container';
import {MetaTypes} from '../../../core/definitions/meta-types';
import {SettingsService} from '../../../core/settings.service';
import {ClientConfig} from "../../../core/definitions/client-config";
import {SearchObject} from "../../../core/definitions/search-object";
import {ResultViewService} from "../../result-view.service";
import {SearchResultViewType} from "../../../core/definitions/search-result-view-type.enum";
import {FeatureFlagsService} from '../../../core/feature-flags.service';

@Component({
  selector: 'app-search-result-view-thumbnail',
  templateUrl: './search-result-view-thumbnail.component.html',
  styleUrls: ['./search-result-view-thumbnail.component.scss']
})
export class SearchResultViewThumbnailComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Input() searchContainer: SearchContainer;
  @Input() width: string;

  MetaTypes = MetaTypes;
  columns: number;
  ds: VirtualScrollSearchDataSourceColumns;
  clientConfig: ClientConfig;

  readonly objectPageV2 = this.featureFlags.getFeatureFlags().experimental.useNewObjectPage;

  constructor(private searchExecutorService: SearchExecutorService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private searchHandler: SearchHandlerService,
              private settingsService: SettingsService,
              private resultViewService: ResultViewService,
              private featureFlags: FeatureFlagsService) {
  }

  ngOnInit(): void {
    this.clientConfig = this.settingsService.getClientConfig();
    this.ds = new VirtualScrollSearchDataSourceColumns(this.searchExecutorService, this.searchContainer);
    this.getColumns();
    if (this.searchContainer.virtualScrollViewOffset) {
      setTimeout(() => {
        this.virtualScroll.scrollToOffset(this.searchContainer.virtualScrollViewOffset);
      }, 2000);
    }
  }

  async select(obj: SearchObject, event: any) {
    this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
  }

  setSearchItemIndex(searchItemIndex: number) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchContainer.virtualScrollViewOffset = this.virtualScroll.getOffsetToRenderedContentStart();
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }
  getColumns() {
    const sizes = this.resultViewService.calcSearchRowsAndColumns(SearchResultViewType.THUMBNAIL);
    this.columns = sizes.columns;
  }
}
