@for (item of getOptions(); track item) {
  <div class="edit-field-check-array">
    <input
      class="edit-field-check-array__checkbox white-bg-in-checkbox"
      type="checkbox"
      name="checkbox"
      [checked]="item[checkField]"
      (change)="toggleItem($event, item)"
    />

    <div class="edit-field-check-array__label-container">
    <span class="edit-field-check-array__text"
          [ngClass]="{'bold': item[checkField]}"
          [translate]="item['$$label']"></span>
      @if (item.$$description) {
        <div class="edit-field-check-array__description"
             [translate]="item.$$description"></div>
      }
    </div>
  </div>
}
