<div class="admin-main-page">
  <app-top-header [parent]="parent"
                  class="admin-header-container"></app-top-header>

  <app-header-and-description heading="TRANS__ADMIN__TITLE"
                              description="TRANS__ADMIN__INGRESS">
    <div class="admin-row">
      <mat-card appearance="outlined" class="admin-column admin-card">
        <mat-card-header class="admin-card__header">
          <mat-card-title>{{'TRANS__ADMIN__MANAGE_SETUP' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="admin-card__content">
          <mat-list *ngIf="adminOperation">
            <ng-container *ngFor="let operation of adminOperation.sub_operations">
              <mat-list-item>

                <a class="admin-main-page__list-menu-item blue-hover-icon"
                   [routerLink]="primusStateMapper.routerLinkFromState(operation.operation_steps[0].change_state[0].state_name)"
                   [queryParams]="primusStateMapper.queryParams(operation.operation_steps[0].change_state[0].state_params)"
                   queryParamsHandling="merge">
                  <i class="{{operation.menu_icon}}"></i>
                  <span>{{operation.menu_title | translate}}</span></a>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </mat-card-content>
      </mat-card>

      <div class="admin-column">
        <div class="admin-row">
          <mat-card appearance="outlined" class="admin-column admin-card">
            <mat-card-header class="admin-card__header">
              <mat-card-title>{{'TRANS__ADMIN__HELP__TITLE' | translate}}</mat-card-title></mat-card-header>

            <mat-card-content class="admin-card__content">
              <mat-list>
                <mat-list-item><span class="bold">{{'TRANS__ADMIN__HELP__CONTACT' | translate}}</span></mat-list-item>
                <mat-list-item><span>{{'TRANS__ADMIN__HELP__NUMBER' | translate}}</span></mat-list-item>
                <mat-list-item>
                  <span>{{'TRANS__ADMIN__HELP__MAIL' | translate}}</span>
                  <a class="admin-email text-primary-hover" href="mailto:support@kulturit.no">support&#64;kulturit.no</a></mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="admin-row">
          <mat-card appearance="outlined" class="admin-column admin-card">
            <mat-card-header class="admin-card__header">
              <mat-card-title>{{'TRANS__ADMIN__FQ__TITLE' | translate}}</mat-card-title></mat-card-header>
            <mat-card-content class="admin-main-page__fq-container admin-card__content">
              <mat-list>
                <mat-list-item *ngFor="let fq of adminFq; index as $index">
                  <div class="admin-main-page__fq-item">
                    <button mat-button class="admin-main-page__fq-button"
                       (click)="toggleFq($index)">
                      <span class="admin-main-page__fq-button-text"
                            [ngClass]="{'bold': visibleFq[$index]}">{{fq.question | translate}}</span>
                      <mat-icon>{{visibleFq[$index] ? 'expand_less' : 'expand_more'}}</mat-icon></button>
                    <div class="admin-main-page__fq-drop-down" *ngIf="visibleFq[$index]">
                      <span class="admin-card__content__answer">{{fq[AConst.ANSWER] | translate}}</span>
                      <a *ngIf="fq.url" target="_blank" [href]="fq.url">{{fq.url}}</a></div>
                    <hr class="admin-fq__hr">
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-card-content>
          </mat-card>

        </div>
      </div>
    </div>
  </app-header-and-description>
</div>
