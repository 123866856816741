<div class="search-query-path-menu search-input-menu-trigger">
  <button class="search-query-path-menu__button"
          type="button"
          [matMenuTriggerFor]="pathMenu"
          (click)="togglePathMenu()"
          [ngClass]="{
          'active': showPathMenu,
          'isButton': searchMenuPath.length > 1,
          'notButton': searchMenuPath.length <= 1}">
    <span class="vertical-line"></span>
    <span class="search-query-path-menu__button-content">
      <span class="search-query-path-menu__button-text flex-grow">{{searchMenuPathTitle | translate}}</span>
       <mat-icon *ngIf="searchMenuPath.length > 1">{{showPathMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
    </span>

  </button>
  <mat-menu #pathMenu="matMenu"
            yPosition="below"
            (closed)="showPathMenu = false;"
            class="removePaddingInMatMenu search-input-menu-drop-down">
      <button mat-menu-item *ngFor="let menu of searchMenuPath"
              (click)="selectFilterMenu(menu)"
              [ngClass]="{'active': menu['path'] === searchContainer.path}">
          <span [translate]="menu['title']"></span>
      </button>
  </mat-menu>
</div>
