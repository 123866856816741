import {Component, Input, OnDestroy, OnInit, WritableSignal} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {SearchObject} from '../../core/definitions/search-object';
import {BaseModel} from "../../core/definitions/base-model";
import {snackbarObject} from "../../media-selector/media-selector-general/media-selector-general.component";

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnInit, OnDestroy {
  public imgClass = '';

  @Input() item: BaseModel;
  @Input() viewMode: string;
  @Input() imageIdField: string;
  @Input() stylingClass: string;
  @Input() _snackBar: WritableSignal<snackbarObject>;

  constructor(private readonly mediaHelper: MediaHelperService) {

  }

  ngOnInit() {
    if (this.item) {
      this.mediaHelper.setThumbnailUrl(<SearchObject>this.item, this.imageIdField, this._snackBar).then(() => {
        this.showImageOrPlaceholder();
      });
    }
  }

  ngOnDestroy() {
    // This will stop upload status timeout started in mediaHelper
    this.mediaHelper.cancelUploadWait(<SearchObject>this.item);
  }

  private showImageOrPlaceholder() {
    this.imgClass = this.stylingClass ? this.stylingClass + ' ' : '';
    if (this.item.$$thumbnailUrl) {
      if (this.viewMode && this.viewMode === 'thumbnail') {
        this.imgClass += 'searchThumbnailBig';
      } else {
        this.imgClass += 'searchThumbnail';
      }
    } else {
      if (this.viewMode && this.viewMode === 'thumbnail') {
        this.imgClass += 'imgPlaceholderBig';
      } else {
        this.imgClass += 'imgPlaceholder';
      }
    }
  }

}
