<div class="admin-settings-field-value">
  <span *ngIf="fieldType !== 'image'">
    <span *ngIf="!editable"><strong>{{subSetting.name}}:</strong>
      <span *ngIf="displayValue.indexOf('http') !== 0; else showUrl"
            class="admin-settings-field-value__view-text"> {{displayValue}}</span>
      <ng-template #showUrl>
        <a [href]="displayValue" class="admin-settings-field-value__view-text"> {{displayValue}}</a>
      </ng-template>
    </span>

    <span *ngIf="editable">
      <ng-container *ngIf="fieldType !== 'checkbox'">

      <mat-form-field [ngSwitch]="fieldType" appearance="outline" class="admin-settings-field-value__form-field">
        <mat-label>{{subSetting.name}}</mat-label>

        <label *ngSwitchCase="'text'">
          <input matInput type="text" [(ngModel)]="subSetting.value" (ngModelChange)="valueChanged()">
        </label>

        <label *ngSwitchCase="'number'">
          <input matInput type="number" [(ngModel)]="subSetting.value" (ngModelChange)="valueChanged()">
        </label>

        <mat-select *ngSwitchCase="'select'" [(ngModel)]="subSetting.value" (ngModelChange)="valueChanged()">
          <mat-option *ngFor="let option of options" [value]="option.value">
            <span>{{option.viewValue}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      </ng-container>

      <span *ngIf="fieldType === 'checkbox'">
        <mat-checkbox labelPosition="before" [(ngModel)]="subSetting.value" (ngModelChange)="valueChanged()">
          {{subSetting.name}}:
        </mat-checkbox>
      </span>
    </span>
  </span>

  <span *ngIf="fieldType === 'image'">
    <span><strong>{{subSetting.name}}: </strong></span>
    <app-edit-field-image-selector
      *ngIf="editable"
      [field]="imageField"
      [object]="imageObject"
    ></app-edit-field-image-selector>
    <app-thumbnail
      *ngIf="!editable && imageObject[imageField.name].value && imageObject[imageField.name].value !== ('None' || null)"
      [item]="imageObject[imageField.name]"
      [imageIdField]="'value'"
      [stylingClass]="'image-uploaded-view'"></app-thumbnail>
</span>
</div>

