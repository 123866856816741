<!--<img [alt]="'TRANS__THUMBNAIL__IMAGE' | translate"-->
<!--     [src]="item.$$thumbnailUrl"-->
<!--     *ngIf="!item.$$uploading"-->
<!--     [class]="imgClass" />-->
<!--<div class="uploading-placeholder"-->
<!--     *ngIf="item.$$uploading"-->
<!--     [class]="imgClass">-->
<!--  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>-->
<!--&lt;!&ndash;  <i class="icon-image"></i>&ndash;&gt;-->
<!--  @if (item.$$dmsStatus === 'convert' || item.$$dmsStatus === 'converting') {-->
<!--    <span>-->
<!--      Lager miniatyrbilde-->
<!--    </span>-->
<!--  }-->
<!--</div>-->

<div class="thumbnail__container">
  @if (item.$$dmsStatus === 'convert' || item.$$dmsStatus === 'done' || item.$$dmsStatus === 'fail') {
  <div class="thumbnail__container__status-badge" [ngClass]="{'error-bg': item.$$dmsStatus === 'fail'}"
    [translate]="item.$$dmsStatus === 'convert' || item.$$dmsStatus === 'done' ? 'TRANS__IMAGE_SELECTOR__UPLOAD_DONE' : 'TRANS__IMAGE_SELECTOR__UPLOAD_FAIL'">
    @if (item.$$dmsStatus === 'convert' || item.$$dmsStatus === 'done') {
    <mat-icon>check</mat-icon>
    }
    @else {
    <mat-icon>error</mat-icon>
    }
    <span
      [translate]="item.$$dmsStatus === 'convert' || item.$$dmsStatus === 'done' ? 'TRANS__IMAGE_SELECTOR__UPLOAD_DONE' : 'TRANS__IMAGE_SELECTOR__UPLOAD_FAIL'"></span>
  </div>
  }
  @if (!item.$$dmsStatus || item.$$dmsStatus === 'done') {
  <img [alt]="'TRANS__THUMBNAIL__IMAGE' | translate" [class]="imgClass" [src]="item.$$thumbnailUrl">
  }
  @else if (item.$$dmsStatus === 'init' || item.$$dmsStatus === 'converting' || item.$$dmsStatus === 'convert') {
  <div class="thumbnail__container__uploading-placeholder">
    @if (item.$$dmsStatus === 'convert') {
    <div class="thumbnail__container__uploading-placeholder__spinner-container">
      <span><mat-progress-spinner diameter="16" color="accent"
          mode="indeterminate"></mat-progress-spinner></span><span translate="TRANS__IMAGE_SELECTOR__CONVERTING"></span>
    </div>
    }
    @if (item.$$dmsStatus === 'init' || item.$$dmsStatus === 'converting') {
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    }
  </div>
  }
</div>
