import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContentListSourceContainer} from '../../core/definitions/object-content-tab/content-list-source-container';
import {SortField} from '../../core/definitions/object-content-tab/content-menus';

@Component({
  selector: 'app-object-content-list-source-array-sort',
  templateUrl: './object-content-list-source-array-sort.component.html',
  styleUrls: ['./object-content-list-source-array-sort.component.scss']
})
export class ObjectContentListSourceArraySortComponent implements OnInit {

  @Input() contentListSource: ContentListSourceContainer;
  @Output() setOrder = new EventEmitter<boolean>();
  @Input() viewName: String;

  constructor() {
  }

  ngOnInit() {
    if (!this.viewName) {
      this.viewName = 'search';
    }
  }

  changeSortOrder(s: SortField) {
    this.contentListSource.order = s;
    this.setOrder.emit(true);
  }

}
