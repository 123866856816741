import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IdentifierFormat} from '../identifier-format';
import {AdminIdFormatService} from '../admin-id-format.service';
import {SettingsService} from "../../../core/settings.service";

@Component({
  selector: 'app-admin-id-format-global-view',
  templateUrl: './admin-id-format-global-view.component.html',
  styleUrls: ['./admin-id-format-global-view.component.scss']
})
export class AdminIdFormatGlobalViewComponent {

  @Input() formats: Array<IdentifierFormat>;
  @Output() public readonly formatAdded: EventEmitter<IdentifierFormat>;
  @Output() public readonly formatDeleted: EventEmitter<IdentifierFormat>;

  constructor(private readonly idFormatService: AdminIdFormatService, private settings: SettingsService) {
    this.formatAdded = new EventEmitter<IdentifierFormat>();
    this.formatDeleted = new EventEmitter<IdentifierFormat>();
  }


  async handleAddOrEditPrefixClicked(format?: IdentifierFormat): Promise<void> {
    const newFormat = await this.idFormatService.addOrEditIdentifierFormat(format ? format : new IdentifierFormat(null, this.settings.getClientConfig().IDENTIFIER_FORMAT_SUPEROBJECT_TYPE_ID));
    if (newFormat) {
      this.formatAdded.emit(newFormat);
    }
  }
}
