<div class="admin-id-format">
  <app-top-header [parent]="parent"
                  backRouterLink="/admin"
                  backRouterLinkLabel="TRANS__SECTION__ADMIN__TITLE"
                  class="admin-header-container"></app-top-header>
  <app-header-and-description heading="TRANS__ADMIN__ID_FORMAT_HEADING"
                              description="TRANS__ADMIN__ID_FORMAT_DESCRIPTION">
    <mat-tab-group dynamicHeight class="admin-id-format__tabs">
      <mat-tab [label]="'TRANS__ADMIN__ID_FORMAT_GLOBAL_TAB_LABEL' | translate">

        <app-admin-id-format-global-view [formats]="globalFormats"
                                         (formatAdded)="handleFormatAdded($event)"
                                         (formatDeleted)="handleFormatDeleted($event)">
        </app-admin-id-format-global-view>

      </mat-tab>
      <mat-tab [label]="'TRANS__ADMIN__ID_FORMAT_COLLECTION_TAB_LABEL' | translate">

        <app-admin-id-format-collection-view [formats]="collectionFormats"
                                             (formatAdded)="handleFormatAdded($event)"
                                             (formatDeleted)="handleFormatDeleted($event)">
        </app-admin-id-format-collection-view>

      </mat-tab>
    </mat-tab-group>
  </app-header-and-description>
</div>
