import {Component, effect} from '@angular/core';
import {MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatTreeModule, MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {TranslateModule} from '@ngx-translate/core';
import {WelcomeService} from '../welcome.service';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {iconToMaterialSymbol} from '../../shared/material-icons';
import {MatTooltip} from '@angular/material/tooltip';
import {MatActionList, MatListItem, MatListOption, MatSelectionList} from '@angular/material/list';
import {Router} from '@angular/router';

interface TreeNode {
  label: string;
  children?: TreeNode[];
  icon?: string;
  tooltip?: string;
  link?: {
    path: string;
    queryParams: any;
  }
}

@Component({
  selector: 'app-create-object-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    TranslateModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatTooltip,
    MatSelectionList,
    MatListOption,
    MatListItem,
    MatActionList,
  ],
  templateUrl: './create-object-dialog.component.html',
  styleUrl: './create-object-dialog.component.scss'
})
export class CreateObjectDialogComponent {

  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();

  constructor(
    private welcomeService: WelcomeService,
    private router: Router,
    public dialogRef: MatDialogRef<CreateObjectDialogComponent>
  ) {
    effect(() => {
      this.dataSource.data = this.welcomeService.createNewOperations().map(operation => ({
        label: operation.menu_title,
        icon: '',
        children: operation.sub_operations?.map(subOperation => {
          const { objectType } = subOperation.operation_steps?.[0]?.change_state?.[0]?.state_params || {};
          let link = undefined;
          if (objectType) {
            link = {
              path: '/new',
              queryParams: {
                objectType,
              }
            }
          }
          return ({
            label: subOperation.menu_title,
            icon: iconToMaterialSymbol(subOperation.menu_icon),
            tooltip: subOperation.menu_tooltip,
            link
          });
        })
      }))
    });
  }

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  onLeafNodeClick($event: Event, node: TreeNode) {
    if (node.link) {
      this.dialogRef.close();
      this.router.navigate([node.link.path], { queryParams: node.link.queryParams });
    }
  }
}
