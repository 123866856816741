<mat-tree [dataSource]="rightsDataSource" [treeControl]="rightsTreeControl"
          class="access-rights-section"
            [ngClass]="{'light-bg-blue': enableEditMode}">

    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node"
                   matTreeNodePadding=""
                   [matTreeNodePaddingIndent]="'24px'">
      <mat-selection-list (selectionChange)="handleSelectionChange($event, node)">
        <mat-list-option class="override-disabled-background"
                        [value]="node"
                        [selected]="node.selected"
                        [disabled]="!enableEditMode">
          {{ node.name | translate }}
        </mat-list-option>
      </mat-selection-list>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                   matTreeNodePadding=""
                   [matTreeNodePaddingIndent]="'24px'">
      <mat-selection-list>
        <div class="mat-list-option override-disabled-background">
          <div class="access-rights-section__expandable-node">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{rightsTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>

            <span class="access-rights-section__main-node-text">{{node.name | translate}}</span>
            <mat-checkbox [(ngModel)]="node.selected"
                          [disabled]="!enableEditMode"
                          labelPosition="before"
                          (ngModelChange)="toggleAllLeafNodes(node)"
                          [checked]="checkIfAllChildrenIsSelected(node)"
                          [indeterminate]="checkIfChildNodeIsSelected(node)">
            </mat-checkbox>
          </div>
        </div>
      </mat-selection-list>
    </mat-tree-node>

  </mat-tree>
