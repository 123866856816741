import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {CmsApiService} from '../../../core/cms-api.service';
import {SearchParameters} from '../../../core/definitions/search-parameters';
import {SolrFilterService} from '../../../core/solr-filter.service';
import {SettingsService} from '../../../core/settings.service';
import {ModelsService} from '../../../core/models.service';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchService} from "../../../core/search.service";

interface ObjectFilterNode {
  name: string;
  type: string;
  icon: string;
  selected: boolean;
  children?: ObjectFilterNode[];
}

/** Flat node with expandable and level information */
interface ObjectFilterFlatNode {
  expandable: boolean;
  name: string;
  type: string;
  icon: string;
  selected: boolean;
  level: number;
}

@Component({
  selector: 'app-admin-concept-object-filter-menu',
  templateUrl: './admin-concept-object-filter-menu.component.html',
  styleUrls: ['./admin-concept-object-filter-menu.component.scss']
})
export class AdminConceptObjectFilterMenuComponent implements OnInit {

  @Output() objectFilterChange = new EventEmitter<ObjectFilterNode[]>();

  treeControl: FlatTreeControl<ObjectFilterFlatNode>;
  treeFlattener: MatTreeFlattener<ObjectFilterNode, ObjectFilterFlatNode>;
  dataSource: MatTreeFlatDataSource<ObjectFilterNode, ObjectFilterFlatNode>;
  transformer: (node: ObjectFilterNode, level: number) => ObjectFilterFlatNode;
  selectedFilters: Array<ObjectFilterNode> = [];
  chipDisplayLimit = 6;

  constructor(private cms: CmsApiService,
              private solrFilter: SolrFilterService,
              private settings: SettingsService,
              private models: ModelsService,
              private searchService: SearchService) {
    this.transformer = (node: ObjectFilterNode, level: number) => {
      return {
        expandable: !!node.children && node.children.length > 0,
        name: node.name,
        level: level,
        type: node.type,
        icon: node.icon,
        selected: node.selected
      };
    };
    this.treeControl = new FlatTreeControl<ObjectFilterFlatNode>(
      node => node.level, node => node.expandable);

    this.treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, node => node.expandable, node => node.children);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  hasChild = (_: number, node: ObjectFilterFlatNode) => node.expandable;

  ngOnInit(): void {
    this.getObjectFilterMenuData().then(menuData => {
      this.dataSource.data = menuData;
    });
  }

  async toggleAllLeafNodes(node: ObjectFilterFlatNode) {
    const children = this.treeControl.getDescendants(node);
    children.forEach((child: ObjectFilterFlatNode) => {
      if (node.selected) {
        if (this.selectedFilters.indexOf(child) === -1) {
          child.selected = node.selected;
          this.selectedFilters.push(child);
        }
      } else {
        if (this.selectedFilters.indexOf(child) !== -1) {
          child.selected = node.selected;
          this.selectedFilters.splice(this.selectedFilters.indexOf(child), 1);
        }
      }
    });
    this.objectFilterChange.emit(this.selectedFilters);
  }

  checkIfChildNodeIsSelected(node: ObjectFilterFlatNode): boolean {
    if (node.selected) {
      const children = this.treeControl.getDescendants(node);
      return children.some(child => child.selected === false);
    }
  }

  async setObjectFilter(objectFilterNode: ObjectFilterNode) {
    if (this.selectedFilters.indexOf(objectFilterNode) === -1) {
      this.selectedFilters.push(objectFilterNode);
      this.objectFilterChange.emit(this.selectedFilters);
    } else {
      await this.removeObjectFilter(objectFilterNode);
    }
  }

  async removeObjectFilter(objectFilterNode: ObjectFilterNode) {
    if (objectFilterNode.selected) {
      objectFilterNode.selected = false;
    }
    this.selectedFilters.splice(this.selectedFilters.indexOf(objectFilterNode), 1);
    this.objectFilterChange.emit(this.selectedFilters);
  }

  private async getObjectFilterMenuData(): Promise<ObjectFilterNode[]> {
    const objectFilterMenuData: ObjectFilterNode[] = [];
    const modelIcons = await this.cms.getModelIcons();
    const superObjects = await this.getSuperObjects();
    const objectTypes = this.models.getObjectTypesFromSuperObjectTypeIds(
      superObjects.map(artifact => artifact.artifact_id));
    const superObjectParents = await this.getSuperObjectParents();
    superObjectParents.forEach(parentItem => {
      const objectTypeMenus: Array<ObjectFilterNode> = [];
      superObjects.forEach(objectTypeSearchItem => {
        if (objectTypeSearchItem.parent_id === parentItem.artifact_id) {
          objectTypeMenus.push({
            type: objectTypes[objectTypeSearchItem.artifact_id],
            name: objectTypeSearchItem.artifact_name,
            icon: modelIcons[objectTypeSearchItem.description],
            selected: false
          });
        }
      });
      objectFilterMenuData.push({
        type: parentItem.artifact_id,
        name: parentItem.artifact_name,
        children: objectTypeMenus,
        icon: '',
        selected: false
      });
    });
    return objectFilterMenuData;
  }

  private async getSuperObjectParents(): Promise<SearchObject[]> {
    const conceptSuperobjectType = this.settings.getClientConfig().CONCEPT_TYPE_SUPEROBJECT_TYPE;
    const superObjectParentSearchParams = {} as SearchParameters;
    this.solrFilter.addFq(superObjectParentSearchParams, 'object_type', conceptSuperobjectType);
    this.solrFilter.addFq(superObjectParentSearchParams, '-parent_id', '[* TO *]');
    this.solrFilter.addFq(superObjectParentSearchParams, 'is_leaf', false);
    superObjectParentSearchParams.sort = 'artifact_name';
    const superObjectParentSearchRes = await this.searchService.search(superObjectParentSearchParams);
    return superObjectParentSearchRes.artifacts;
  }

  private async getSuperObjects(): Promise<SearchObject[]> {
    const conceptSuperobjectType = this.settings.getClientConfig().CONCEPT_TYPE_SUPEROBJECT_TYPE;
    const superObjectSearchParams = {} as SearchParameters;
    this.solrFilter.addFq(superObjectSearchParams, 'object_type', conceptSuperobjectType);
    superObjectSearchParams.sort = 'artifact_name';
    const superObjectSearchRes = await this.searchService.search(superObjectSearchParams);
    return superObjectSearchRes.artifacts;
  }
}
