import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import { SearchObject } from 'src/app/core/definitions/search-object';
import { SearchResult } from 'src/app/core/definitions/search-result';
import { AdminUsersService } from './admin-users.service';


class AdminUsersDataSource extends DataSource<SearchObject> {

  constructor(private userService: AdminUsersService) {
    super();
  }

  public numUsersSubject = new BehaviorSubject<number>(0);
  private usersSubject = new BehaviorSubject<SearchObject[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public numRecords = 0;

  private artifacts;

  private loadUsersSubscriber: any;

  connect(collectionViewer: CollectionViewer): Observable<SearchObject[]> {
    return this.usersSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.usersSubject.complete();
    this.loadingSubject.complete();
    this.loadUsersSubscriber?.unsubscribe();
  }

  public async loadUsers(pageSize, pageIndex, filterSettings) {
    this.loadingSubject.next(true);
    this.loadUsersSubscriber = this.userService.getUserTableDataNew(pageSize, pageIndex, filterSettings)
      .pipe(
        tap(_ => this.loadingSubject.next(false))
      )
      .subscribe((res: SearchResult) => {
        if (res) {
          this.artifacts = res.artifacts;
          this.numRecords = res.search_count;
          this.numUsersSubject.next(res.search_count);
          this.usersSubject.next(res.artifacts);
        }
      });
  }

  public getAllRows() {
    return this.artifacts;
  }
}

export default AdminUsersDataSource;
