import {Component, Input, OnInit} from '@angular/core';
import {AnnotationHandler} from '../../annotation-handler';

@Component({
  selector: 'app-annotation-shape-menu',
  templateUrl: './annotation-shape-menu.component.html',
  styleUrls: ['./annotation-shape-menu.component.scss']
})
export class AnnotationShapeMenuComponent implements OnInit {

  @Input() curAnn: AnnotationHandler;
  @Input() isDialog: boolean;

  constructor() { }

  ngOnInit() {
  }

}
