<div class="reports-index-page">
  <div class="reports-header">
    <h1>Rapporter</h1>
  </div>

  <div class="reports-menu">
    <ul class="reports-menu-list">
      @for (menuItem of menuItems; track menuItem.id) {
        <li [class.closed]="!menuItem.isOpen" [class.open]="menuItem.isOpen">
          <a [routerLink]="menuItem.openButtonLink">
            @if (menuItem.icon) {
              <i class="material-icons">{{menuItem.icon}}</i>
            }
            <span>{{menuItem.name}}</span>
          </a>
        </li>
      }
      </ul>
    </div>

  <div class="reports-content">
    <router-outlet></router-outlet>
  </div>

</div>
