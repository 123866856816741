import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {SearchContainer} from '../../../core/definitions/search-container';
import {DateToolsService} from '../../../core/date-tools.service';
import {BaseModel} from '../../../core/definitions/base-model';
import {Folder} from '../../../core/definitions/folder';
import {GetArtifactParams} from '../../../core/definitions/get-artifact-params';
import {CmsApiService} from '../../../core/cms-api.service';
import {TranslateService} from '@ngx-translate/core';

interface TabItem {
  header: string;
  object: BaseModel;
}

@Component({
  selector: 'app-search-view-folder-header',
  templateUrl: './search-view-folder-header.component.html',
  styleUrls: ['./search-view-folder-header.component.scss']
})
export class SearchViewFolderHeaderComponent implements OnChanges {

  @Input() searchContainer: SearchContainer;
  @Input() folderId: string;
  @ViewChild('scrollMenu') scrollMenu: ElementRef;
  openedObject: BaseModel;
  folder: Folder;
  tabItems: TabItem[] = [];

  constructor(private translateService: TranslateService,
              private dateTools: DateToolsService,
              private cms: CmsApiService) { }

  ngOnChanges() {
    this.openedObject = null;
    this.loadFolder().then();
  }

  scrollTo(direction?) {
    if (direction) {
      const scrollAmount = 156 * ((direction === 'prev') ? -1 : 1);
      this.scrollMenu.nativeElement.scrollLeft += scrollAmount;
    }
  }

  toggleOpen(object: BaseModel) {
    this.searchContainer.isFolderInfoOpen = false;
    if (!this.openedObject || this.openedObject.artifact_id !== object.artifact_id) {
      this.openedObject = null;
      setTimeout(() => {
        this.openedObject = object;
        this.searchContainer.isFolderInfoOpen = true;
      }, 200);
    } else {
      this.openedObject = null;
    }
  }

  getDate() {
    return this.dateTools.isoDateToString(this.folder.date);
  }

  refreshHeader() {
    this.openedObject = null;
    this.searchContainer.isFolderInfoOpen = false;
    this.loadFolder().then();
  }

  private async loadFolder() {
    const params = new GetArtifactParams();
    params.artifact_id = this.folderId;
    this.folder = <Folder>await this.cms.getArtifact(params);
    this.tabItems = [{
      header: this.translateService.instant('TRANS__FOLDER__VIEW__SHOW_FOLDER_DESC'),
      object: this.folder
    }];
    if (this.folder.tasks?.length) {
      for (const task of this.folder.tasks.sort((task1, task2) => task1.order_number - task2.order_number)) {
        this.tabItems.push({
          header: task.artifact_id_value,
          object: task
        });
      }
    }
  }

}
