import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Input, OnDestroy, OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {CmsApiService} from "../../core/cms-api.service";

@Component({
  selector: 'app-model-full-screen',
  templateUrl: './model-full-screen.component.html',
  styleUrl: './model-full-screen.component.scss'
})
export class ModelFullScreenComponent implements OnInit, OnDestroy {
  data: WritableSignal<any> = signal({});

  @Input() mediaContainer;
  @Output() closeModelFullScreen = new EventEmitter();
  @ViewChild('modelFullScreen', {static: true}) modelFullscreen: ElementRef;

  constructor(
    private cmsApi: CmsApiService,
    private readonly elementRef: ElementRef,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.cmsApi.orderDownloadUrl(this.mediaContainer.currentMediaType.mediaElements[this.mediaContainer.currentMediaType.currentMediaIndex].mediaObject.artifact_id).then((url) => {
      this.data.set({
        model: {
          type: 'Object',
          url: url.url,
          rotation: { x: -0.4, y: Math.PI, z: 0 }
        }
      });

      this.loadModel();
    });
  }

  ngOnDestroy() {
    window['modelViewer']['unmount']('model-viewer-container');
  }

  goBack() {
    this.closeModelFullScreen.emit();
  }

  goBackWithKey(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.goBack();
    }
  }

  loadModel() {
    window['modelViewer']['renderModelViewer']({
      showGizmo: false,
      onProgressChange: null,
      showToolbar: false,
      enablePan: true,
      containerId: 'model-viewer-container',
      data: this.data(),
      adminMode: false,
      onClose: null,
      onMediaSelect: null,
      onChange: null,
      onGroupItemChange: null,
      showAnnotations: true
    });
  }
}
