<div class="field-select-options" [tabIndex]="0" #focusOption>
  <mat-list>
    <cdk-virtual-scroll-viewport itemSize="26" #scrollViewport
                                 [style.height]="viewportHeight"
                                 minBufferPx="65"
                                 maxBufferPx="260"
                                 class="field-select-options__content">
      <mat-list-item [tabIndex]="i === selectedRow ? 0 : -1"
                       [ngStyle]="setStyle(i)"
                       class="field-select-options__option-item"
                       *cdkVirtualFor="let option of dataSource; let i = index; let count = count">
        <span class="alignChildren" (click)="selectOption($event, option)">
          @if (option.icon) {
            <span><i class="{{option.icon}}">{{option.icon_frame}}</i></span>
          }
          @if (isArray) {
            <input id="check" type="checkbox" [checked]="this.selectedMap.has(option.artifact_id)" disabled />
          }
          <span style="padding: 10px" [innerHTML]='markQuery(getOptionName(option))'></span>
          <span [ngClass]="{'concept-authority-right': option.has_authority}"></span>
        </span>
      </mat-list-item>

      <!-- Feedback text, if no options available or no search result -->
      @if (!dataSource.isLoading() && (dataSource.asObservable() | async)?.length === 0) {
        @if (!query) {
          <div class="user-feedback">
            <span class="user-feedback-text" translate="TRANS__FIELD_SELECT_OPTIONS__NO_AVAILABLE_OPTIONS"></span>
          </div>
        } @else {
          <div class="user-feedback">
            <span class="user-feedback-text" translate="TRANS__FIELD_SELECT_OPTIONS__NO_SEARCH_RESULT"></span>
          </div>
        }
      } @else if (dataSource.isLoading()) {
        <div class="spinner-wrapper">
          <mat-spinner color="accent" diameter="16"></mat-spinner>
          <span style="padding-left: 5px" translate="TRANS__SEARCH__LOADING_INFO"></span>
        </div>
      }
    </cdk-virtual-scroll-viewport>
  </mat-list>
</div>
