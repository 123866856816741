import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ObjectContentListSourceArrayService} from '../object-content-list-source-array.service';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {ContentListSourceContainer} from '../../core/definitions/object-content-tab/content-list-source-container';

@Component({
  selector: 'app-object-content-list-source-array',
  templateUrl: './object-content-list-source-array.component.html',
  styleUrls: ['./object-content-list-source-array.component.scss']
})
export class ObjectContentListSourceArrayComponent implements OnChanges {

  @Input() contentListSource: ContentListSourceContainer;
  @Output() objectRefresh = new EventEmitter<any>();
  @Output() newObjectCreated = new EventEmitter<string>();

  triggerAnimation = false;

  constructor(private objectContentSourceArrayService: ObjectContentListSourceArrayService) {
  }

  ngOnChanges() {
    this.triggerAnimation = true;
    this.objectContentSourceArrayService.setPageItems(this.contentListSource, true, false).then(() => {
      this.triggerAnimation = false;
    });
    if (this.contentListSource.operationContainer) {
      this.contentListSource.refreshCallback = () => {
        this.objectRefresh.emit({store: false});
      };
    }
  }

  onContentChange(keepRelatedObjects, resetPage, object?: SuperObjectModel) {
    if (object) {
      const changedObjectType = object.object_type;
      if (changedObjectType !== this.contentListSource.parentObject.object_type) {
        this.newObjectCreated.emit(changedObjectType);
        return;
      }
    }
    this.objectContentSourceArrayService.setPageItems(
      this.contentListSource, resetPage, keepRelatedObjects, object).then(() => {
    });
  }
  get disabled() {
    let res = this.contentListSource.operationContainer.art.read_only;
    if (!res) {
      const selections = this.contentListSource.searchContainer.selections;
      res = !(selections.allSelected || !!selections.selectedItems.length) || !this.contentListSource.operationContainer?.operations.length;
    }
    return res;
  }

  onAfterDeleted() {
    this.objectRefresh.emit({store: false});
  }

}
