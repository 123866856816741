import {Injectable} from '@angular/core';
import {FieldValueService} from '../core/field-value.service';
import {ModelFactoryService} from '../core/model-factory.service';
import {FieldMetaHandlerService, MetaPropParams} from '../core/field-meta-handler.service';
import {CmsApiService} from '../core/cms-api.service';
import {ObjectEditService} from '../core/object-edit.service';
import {FieldActionParameters} from './field-action-parameters';
import {MetaField} from '../core/definitions/meta-field';
import {FieldAction, FieldActionItem} from '../core/definitions/field-action';
import {CopyObjectParams} from '../core/definitions/copy-object-params';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {BaseModel} from '../core/definitions/base-model';
import {PrimusRouterService} from '../core/primus-router.service';
import {CrudService} from "../core/crud.service";

@Injectable({
  providedIn: 'root'
})
export class FieldActionService {

  constructor(
    private primusRouter: PrimusRouterService,
    private fieldValueService: FieldValueService,
    private fieldMetaHandler: FieldMetaHandlerService,
    private modelFactory: ModelFactoryService,
    private cms: CmsApiService,
    private objectEditService: ObjectEditService,
    private crud: CrudService) {
  }

  async runActions(params: FieldActionParameters): Promise<void> {
    let actions: Array<FieldAction> = params.field.actions;
    if (!actions && params.field.actions_id) {
      actions = await this.cms.getFieldActions({actions_id: params.field.actions_id});
    }
    if (!actions) {
      const metaPropParams = new MetaPropParams();
      metaPropParams.metaPropName = 'actions';
      metaPropParams.fieldName = params.field.name;
      metaPropParams.parentModel = params.object;
      actions = <Array<FieldAction>>this.fieldMetaHandler.getMetaFieldValue(metaPropParams);
    }
    if (actions) {
      await this.actionLooper(params, actions, 0, 0);
    }
  }

  getActionButtonsFromModel(model: BaseModel, showType: string) {
    const fields = model.$$meta;
    const buttons = [];
    showType = showType || 'yes';
    for (const fieldName in fields) {
      if (fields.hasOwnProperty(fieldName)) {
        const metaField: MetaField = fields[fieldName];
        if (metaField.field_type === 'action_button' && metaField.display === showType) {
          buttons.push({
            rootModel: model,
            parentModel: model,
            propName: metaField.name,
            field: metaField
          });
        }
      }
    }
    return buttons;
  }

  private async actionLooper(params: FieldActionParameters,
                             actions: Array<FieldAction>,
                             actionIndex: number,
                             actionItemIndex: number): Promise<void> {
    const action = actions[actionIndex];
    if (action.trigger === params.trigger) {
      const actionList: Array<FieldActionItem> = action.action_list;
      await this.runAction(params, actionList[actionItemIndex]);
      if (++actionItemIndex >= actionList.length) {
        actionItemIndex = 0;
        if (++actionIndex >= actions.length) {
          return;
        }
      }
      await this.actionLooper(params, actions, actionIndex, actionItemIndex);
    }
  }

  private async runAction(params: FieldActionParameters, actionItem: FieldActionItem): Promise<void> {
    switch (actionItem.action_type) {
      case 'add_to_prop':
        await this.addToItem(params, actionItem);
        break;
      case 'copy_me':
        await this.copyMe(params, actionItem);
        break;
      case 'copy_template_group':
        await this.copyTemplateGroup(params, actionItem);
        break;
      case 'go_state':
        this.goState(params, actionItem).then();
        break;
      case 'move_to':
        this.moveTo(params, actionItem);
        break;
      case 'remove_new_array_item':
        this.removeNewArrayItem(params, actionItem);
        break;
      case 'scroll_to_top':
        this.scrollToTop();
        break;
      case 'set_prop':
        await this.setProp(params, actionItem);
        break;
      case 'store_changes':
        await this.storeChanges(params);
        break;
      case 'get_coordinates':
        await this.getCoordinates(params, actionItem);
        break;
      case 'reset_form':
        this.objectEditService.resetSectionsContainerFormGroup(params.sectionsContainer);
        break;
      case 'close_edit':
        params.closeEdit.emit();
        break;
      default:
        console.warn('Unknown action type ' + actionItem.action_type);
    }
  }

  private async addToItem(params: FieldActionParameters, actionItem: FieldActionItem): Promise<void> {
    let value = this.getTargetVal(params, actionItem);
    value += actionItem.value;
    await this.setTargetVal(params, actionItem, value);
  }

  private async copyMe(params: FieldActionParameters, actionItem: FieldActionItem): Promise<void> {
    const artifactId = params.sectionsContainer.rootObject.artifact_id;
    const copyObjectParams = new CopyObjectParams();
    copyObjectParams.artifact_id = artifactId;
    const copy = await this.cms.copyArtifact(copyObjectParams);
    if (copy) {
      const data = await this.modelFactory.setModelItemAsync(copy.object_type, copy);
      // Should have run store.setSetModelItemHelperProps
      // here, but will cause circular dependency issues
      params.object = <SuperObjectModel>data;
      if (actionItem.target) {
        params.newData = data;
        await this.setProp(params, actionItem);
      }
    }
  }

  private async copyTemplateGroup(params: FieldActionParameters, actionItem: FieldActionItem): Promise<void> {
    const artifactId = params.sectionsContainer.rootObject.artifact_id;
    const copyObjectParams = new CopyObjectParams();
    copyObjectParams.artifact_id = artifactId;
    const copy = await this.cms.copyTemplateGroup(copyObjectParams);
    if (copy) {
      const data = await this.modelFactory.setModelItemAsync(copy.object_type, copy);
      // Should have run store.setSetModelItemHelperProps
      // here, but will cause circular dependency issues
      params.object = <SuperObjectModel>data;
      if (actionItem.target) {
        params.newData = data;
        await this.setProp(params, actionItem);
      }
    }
  }

  private async goState(params: FieldActionParameters, actionItem: FieldActionItem) {
    const stateParams = {};
    for (const key in actionItem.value) {
      if (actionItem.value.hasOwnProperty(key)) {
        const subst = actionItem.value[key];
        let value = subst;
        const fieldName = this.fieldValueService.getSubstituteString(
          subst, '{', '}');
        if (fieldName) {
          value = this.fieldValueService.getFieldValFromFieldParameters(params, fieldName);
        }
        stateParams[key] = value;
      }
    }
    await this.primusRouter.navigateState(actionItem.target, stateParams);
  }

  private moveTo(params: FieldActionParameters, actionItem: FieldActionItem) {
    const targetArray = this.fieldValueService.getFieldValFromFieldParameters(params, actionItem.target);
    const parentArray = params.grandParentObject[params.field.parent_name];
    const moveItem = params.object;
    let deleteIndex: number;

    deleteIndex = this.getItemIndex(parentArray, moveItem, actionItem.source);
    if (deleteIndex !== -1) {
      parentArray.splice(deleteIndex, 1);
    }
    targetArray.push(moveItem);
  }

  private removeNewArrayItem(params: FieldActionParameters, actionItem: FieldActionItem) {
    const item = params.object;
    if (this.crud.getCreate(item)) {
      const targetModel = this.getTargetModel(params, actionItem);
      const targetArray = targetModel[actionItem.target];
      const itemIndex = targetArray.indexOf(item);
      if (itemIndex !== -1) {
        this.modelFactory.deleteArrayItem(targetArray, itemIndex, targetModel);
      }
    }
  }

  private async setProp(params: FieldActionParameters, actionItem: FieldActionItem): Promise<void> {
    let sourceVal = null;
    if (params.newData) {
      if (actionItem.source) {
        sourceVal = params.newData[actionItem.source];
      } else {
        sourceVal = params.newData;
      }
    }
    if (sourceVal) {
      await this.setTargetVal(params, actionItem, sourceVal);
    } else {
      await this.getSetProp(params, actionItem);
    }
  }

  private scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


  private async storeChanges(params: FieldActionParameters): Promise<void> {
    params.newData = await this.objectEditService.setObjectValuesStoreObject(params.sectionsContainer, true);
    params.edit = false;
  }

  private async getSetProp(params: FieldActionParameters, actionItem: FieldActionItem): Promise<void> {
    let value: any;
    if (actionItem.source) {
      value = this.getSourceVal(params, actionItem);
    } else {
      value = actionItem.value;
    }
    if (value !== undefined) {
      await this.setTargetVal(params, actionItem, value);
    }
  }

  private async setTargetVal(params: FieldActionParameters, actionItem: FieldActionItem, value: any): Promise<void> {
    const targetField = actionItem.target;
    const targetModel = this.getTargetModel(params, actionItem);
    if (!Array.isArray(targetModel[targetField])) {
      const metaField = {name: targetField} as MetaField;
      await this.fieldValueService.setFieldValueAndControlValue(params, targetModel, metaField, value);
    } else {
      targetModel[targetField].push(value);
    }
  }

  private getTargetModel(params: FieldActionParameters, actionItem: FieldActionItem) {
    const target = actionItem.target;
    let targetModel: BaseModel;
    if (params.grandParentObject &&
      params.grandParentObject[target] !== undefined) {
      targetModel = params.grandParentObject;
    } else if (params.sectionsContainer.rootObject &&
      params.sectionsContainer.rootObject[target] !== undefined) {
      targetModel = params.sectionsContainer.rootObject;
    } else if (params.sectionsContainer.rootObject['$$topModel'] &&
      params.sectionsContainer.rootObject['$$topModel'][target] !== undefined) {
      targetModel = params.sectionsContainer.rootObject['$$topModel'];
    } else {
      targetModel = params.object;
    }
    return targetModel;
  }

  private getTargetVal(params: FieldActionParameters, actionItem: FieldActionItem) {
    return this.fieldValueService.getFieldValFromModel(params, params.object, actionItem.target);
  }


  private getSourceVal(params: FieldActionParameters, actionItem: FieldActionItem) {
    return this.fieldValueService.getFieldValFromFieldParameters(params, actionItem.source);
  }

  private getItemIndex(array: BaseModel[], arrayItem: BaseModel, compareField: string) {
    let foundIndex = -1;
    array.forEach((item, index) => {
      const compareValue = item[compareField];
      if (compareValue) {
        if (compareValue === arrayItem[compareField]) {
          foundIndex = index;
        }
      } else {
        console.warn('Array items require field "' + compareField + '"!');
      }
    });
    return foundIndex;
  }

  private async getCoordinates(params: FieldActionParameters, actionItem: FieldActionItem): Promise<void> {
    return new Promise<void>(resolve => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          // Warning! The coordinate properties are hardcoded, which will be a problem if model is changed!
          const object = params.object;
          const targets = actionItem.target.split(';');
          let countDown = targets.length;
          targets.forEach((target: string) => {
            const nameVal = target.split('=');
            const field = {name: nameVal[0]} as MetaField;
            this.fieldValueService.setFieldValueAndControlValue(params, object, field, position.coords[nameVal[1]]).then(() => {
              if (!--countDown) {
                resolve();
              }
            });
          });
        });
      } else {
        console.warn('Geolocation is not supported by this browser.');
        resolve();
      }
    });
  }

}
