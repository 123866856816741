<div class="edit-field-search-selector" [formGroup]="fieldParameters.sectionsContainer.formGroup">
  <ul class="edit-field-search-selector__selected-options">
    @if (fieldParameters.object[fieldParameters.field.name]) {
      <li class="edit-field-search-selector__selected-option bold">
      <span class="edit-field-search-selector__selected-option-text">
        <app-object-icon
          [object]="{artifact_id: fieldParameters.object[fieldParameters.field.name]}"></app-object-icon>
        <span>{{fieldParameters.sectionsContainer.formGroup.controls[formControlName].value}}</span>
      </span>
      </li>
    }

    <li>
      <button mat-stroked-button
              color="primary"
              type="button"
              (click)="clickEnableSelector()"
              (keypress)="($event.key === 'Enter') && clickEnableSelector()">
        @if (!fieldParameters.object[fieldParameters.field.name]) {
          <span>
          <i class="icon-select1"></i>
          <span class="bold uppercase" translate="TRANS__FIELD__ARRAY_ADD"></span>
          <span>&nbsp;</span>
          <span class="bold uppercase">{{fieldParameters.field.title | translate}}</span></span>
        } @else {
          <span>
          <i class="icon-edit-alternative"></i>
          <span class="bold uppercase" translate="TRANS__FIELD__ARRAY_CHANGE"></span>
          <span>&nbsp;</span>
          <span class="bold uppercase">{{fieldParameters.field.title | translate}}</span></span>
        }
      </button>
    </li>
  </ul>
</div>
