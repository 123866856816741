<li class="object-content-dropdown-tab"
    role='menuitem'>
  <a (click)="openMenu()"
     class=""
     [ngClass]="{
   'disabled': isEditing,
   'activeLeafMenu': menu.open && menu.leaf_menu,
   'activeMenuWithSubMenus': menu.open && menu.sub_menus}">
    <span class="object-content-tab-text"
          [class.text-blue]="menu.open && menu.leaf_menu || menu.open && menu.sub_menus">
      <span class="object-content-tab-caption light"
            [ngClass]="{'bold': menu.open && menu.leaf_menu ||
            menu.open && menu.sub_menus}"
            [translate]="menu.caption"></span>
      <span *ngIf="menu.count" class="object-content-tab-count">
        <span class="text-blue">{{menu.count}}</span>
      </span>
    </span>
  </a>
</li>
