import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-header-and-description',
  templateUrl: './heading-and-description.component.html',
  styleUrls: ['./heading-and-description.component.scss']
})
export class HeadingAndDescriptionComponent {

  @Input() heading?: string;
  @Input() headingName?: string;
  @Input() description?: string;
  @Input() count?: number;
  @Input() href?: string;

  constructor() {}

}
