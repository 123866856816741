<div class="admin-users-filter-wrapper">
  <header>
    <h5>{{ 'TRANS__ADMIN__USERS__FILTER_HEADING' | translate }}</h5>
  </header>
  <section class="filter-options">

    <!-- DISPLAY OPTIONS -->

    <button mat-stroked-button
            class="admin-stroked-button"
            type="button"
            [matMenuTriggerFor]="listOptions">
      <mat-icon>{{ (selectedListType | async).icon }}</mat-icon>
      <span class="bold uppercase admin-stroked-button__left-margin">{{ (selectedListType | async).label | translate }}</span>
    </button>

    <mat-menu #listOptions="matMenu">
      <ng-container *ngFor="let opt of listTypeOptions">
        <button mat-menu-item
                type="button"
                role="option"
                *ngIf="!opt.adminOnly || isAdmin"
                (click)="listTypeChanged$.next(opt.value)">
          <mat-icon>{{ opt.icon }}</mat-icon>
          {{ opt.label | translate }}
        </button>
      </ng-container>
    </mat-menu>

    <!-- ACTION OPTIONS -->

    <button mat-stroked-button
            type="button"
            class="admin-stroked-button"
            [matMenuTriggerFor]="actionOptions"
            [disabled]="!isUsersSelected">
      <mat-icon>more_horiz</mat-icon>
      <span class="bold uppercase admin-stroked-button__left-margin">{{ 'TRANS__ADMIN__USERS__ACTIONS_BUTTON_LABEL' | translate }}</span>
    </button>

    <mat-menu #actionOptions="matMenu">
      <ng-container *ngFor="let opt of actionOptionsList">
        <button mat-menu-item
                type="button"
                role="option"
                *ngIf="!opt.adminOnly || isAdmin"
                (click)="actionEvent.emit(opt.value)">
          <mat-icon>{{ opt.icon }}</mat-icon>
          <span>{{ opt.label | translate }}</span>
        </button>
      </ng-container>
      <app-operation-dropdown-menu
        [contextObjects]="selectedUsers"
        [operationTarget]="'admin_list_view'"
        [showSubMenusOnly]="true"
      ></app-operation-dropdown-menu>
    </mat-menu>

    <!-- FILTER OPTIONS -->

    <button mat-stroked-button
            type="button"
            class="admin-stroked-button"
            [matMenuTriggerFor]="filterMenu">
      <mat-icon>tune</mat-icon>
      <span class="bold uppercase admin-stroked-button__left-margin">{{ 'TRANS__ADMIN__USERS__FILTER_BUTTON_LABEL' | translate }}</span>
    </button>

    <mat-menu #filterMenu="matMenu">
      <button mat-menu-item
              type="button"
              [matMenuTriggerFor]="activeMenu"
              role="option">
        {{ 'TRANS__ADMIN__USERS__FILTER__LABEL__ACTIVE' | translate }}
      </button>
      <button mat-menu-item
              type="button"
              [matMenuTriggerFor]="rightsMenu"
              [disabled]="!availableAccessRights || availableAccessRights.length === 0"
              role="option">
        {{ 'TRANS__ADMIN__USERS__FILTER__LABEL__RIGHTS' | translate }}
      </button>
<!--
      <button mat-menu-item
              type="button"
              [matMenuTriggerFor]="createdByMenu"
              role="option">
        {{ 'TRANS__ADMIN__USERS__FILTER__LABEL__CREATED_BY' | translate }}
      </button>
-->
      <button mat-menu-item
              type="button"
              [matMenuTriggerFor]="createdTimeMenu"
              role="option">
        {{ 'TRANS__ADMIN__USERS__FILTER__LABEL__CREATED_TIME' | translate }}
      </button>
    </mat-menu>

    <mat-menu #activeMenu="matMenu">
      <button mat-menu-item type="button" [ngClass]="{'active': displayActive}" (click)="active = true">
        <mat-icon class="on-active" color="accent">radio_button_checked</mat-icon>
        <mat-icon class="on-not-active" color="accent">radio_button_unchecked</mat-icon>
        {{ 'TRANS__ADMIN__USERS__FILTER_ACTIVE__ACTIVE' | translate }}
      </button>
      <button mat-menu-item type="button" [ngClass]="{'active': !displayActive}" (click)="active = false">
        <mat-icon class="on-active" color="accent">radio_button_checked</mat-icon>
        <mat-icon class="on-not-active" color="accent">radio_button_unchecked</mat-icon>
        {{ 'TRANS__ADMIN__USERS__FILTER_ACTIVE__DELETED' | translate }}
      </button>
    </mat-menu>

    <mat-menu #rightsMenu="matMenu">
      <button mat-menu-item
              type="button"
              *ngFor="let right of availableAccessRights"
              [ngClass]="{'active': selectedAccessRightIds.includes(right.key)}"
              (click)="toggleSelectItem(selectedAccessRightIds, right.key, $event)">
        <mat-icon class="on-active" color="accent">check_box</mat-icon>
        <mat-icon class="on-not-active" color="accent">check_box_outline_blank</mat-icon>
        {{ right.value }}
      </button>
    </mat-menu>

    <mat-menu>
      <button mat-menu-item
              type="button"
              *ngFor="let user of availableCreateByUser"
              [ngClass]="{'active': selectedCreatedByUser.includes(user.key)}"
              (click)="toggleSelectItem(selectedCreatedByUser, user.key, $event)">
        <mat-icon class="on-active" color="accent">check_box</mat-icon>
        <mat-icon class="on-not-active" color="accent">check_box_outline_blank</mat-icon>
        {{ user.value }}
      </button>
    </mat-menu>

    <mat-menu #createdTimeMenu="matMenu">
      <button mat-menu-item
              type="button"
              *ngFor="let createdTimeOption of createdTimeFilterOptions"
              [ngClass]="{'active': selectedCreatedTime === createdTimeOption.value}"
              (click)="createdTimeFilterValue = createdTimeOption.value">
        <mat-icon class="on-active" color="accent">radio_button_checked</mat-icon>
        <mat-icon class="on-not-active" color="accent">radio_button_unchecked</mat-icon>
        {{ createdTimeOption.label | translate }}
      </button>
    </mat-menu>


    <!-- SEARCH -->

    <div class="small-mat-form-outline">
      <mat-form-field appearance="outline" color="primary">
        <mat-label>{{ 'TRANS__ADMIN__USERS__FILTER_INPUT_PLACEHOLDER' | translate }}</mat-label>
        <input matInput [formControl]="filterControl" autocomplete="off">
        <button mat-icon-button
                matSuffix
                aria-label="clear"
                type="button"
                class="clear-icon"
                (click)="filterControl.setValue('')"
                *ngIf="filterControl && filterControl.value">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button
                matSuffix
                class="search-icon"
                type="button">
          <mat-icon matSuffix color="accent">search</mat-icon>
        </button>
      </mat-form-field>
    </div>

  </section>
</div>
