import {Injectable} from '@angular/core';
import {
  AdvancedSearchParams,
  AdvFieldQuery,
  AdvFieldQueryGroup, AdvFieldQueryLogicalOperator,
  AdvFieldQueryOperator, SearchSuggestion
} from "./definitions/advanced-search-params";
import {SearchContainer} from "./definitions/search-container";
import {
  AdvancedSearchField
} from "./definitions/advanced-search-field";
import {CmsApiService} from "./cms-api.service";
import {FieldInputType} from "./definitions/field-input-type.enum";

@Injectable({
  providedIn: 'root'
})
export class AdvancedSearchToolsService {

  FIELD_TYPE_INPUT = FieldInputType.INPUT.toString();
  FIELD_TYPE_NUMBER = FieldInputType.NUMBER.toString();
  FIELD_TYPE_DATE = FieldInputType.DATE_ISO.toString();
  FIELD_TYPE_IDENTIFIER = FieldInputType.IDENTIFIER.toString();
  FIELD_TYPE_MAP_ID = FieldInputType.MAP_ID.toString();
  FIELD_TYPE_TEXT = FieldInputType.TEXT_AREA.toString();
  FIELD_TYPE_REF_ARRAY = FieldInputType.REF_ARRAY.toString();
  FIELD_TYPE_CHECKBOX = FieldInputType.CHECKBOX.toString();
  FIELD_TYPE_SEARCH_SELECTOR = FieldInputType.SEARCH_SELECTOR.toString();
  FIELD_TYPE_SEARCH_SELECTOR_MULTIPLE = FieldInputType.SEARCH_SELECTOR_MULTIPLE.toString();

  static searchSuggestions: SearchSuggestion[] = [];
  private advFieldQueryOperators: AdvFieldQueryOperator[];

  constructor(private cms: CmsApiService) {
    Promise.all([
     this.fetchFieldQueryOperators(),
     this.fetchSearchSuggestions()
    ]).then();
  }

  createAdvFieldQueryFromSearchField(searchField: AdvancedSearchField): AdvFieldQuery {
    return {
      parent_field_ids: searchField.parent_field_ids,
      field_title: searchField.field_title,
      field_name: searchField.field_name,
      path: searchField.path,
      input_type: searchField.input_type,
      reference_id: searchField.reference_id,
      is_array_field: searchField.is_array_field,
      // @ts-ignore
      index_query_field: searchField.index_query_field,
      operators: this.getOperatorsForFieldType(searchField.input_type),
      operator_selected: '=',
      value: '',
      superobject_type_id: searchField.superobject_type_id,
      context_field: searchField.context_field,
      is_array_context_field: searchField.is_array_context_field,
      child_document_type: searchField.child_document_type,
      logical_operator: null,
      valid: searchField.valid
    };
  }

  getRelationInfoFromGroup(group: AdvFieldQueryGroup, rootGroup: AdvFieldQueryGroup) {
    let res: {
      superobjectTypeId: string,
      contextField: string,
      isArrayContextField: boolean,
      childDocumentType: string
    } = null;
    let id = rootGroup?.relation_superobject_type_id || group?.superobject_type_id;
    let childDocumentType = group?.child_document_type;
    let contextField: string;
    let isArrayContextField: boolean;
    if (rootGroup?.field_queries?.length) {
      contextField = rootGroup.field_queries[0].context_field;
      isArrayContextField = rootGroup.field_queries[0].is_array_context_field;
      if (!isArrayContextField && group.field_queries?.length && group.field_queries[0].is_array_field && group.field_queries[0].child_document_type) {
        isArrayContextField = true;
      }
    }
    if (!id && rootGroup?.field_queries?.length) {
      id = rootGroup.field_queries[0].relation_superobject_type_id;
    }
    if (!childDocumentType && group?.field_queries?.length) {
      childDocumentType = group.field_queries[0].child_document_type;
    }
    if (id || childDocumentType) {
      res = {
        superobjectTypeId: id,
        contextField: contextField,
        isArrayContextField: isArrayContextField,
        childDocumentType: childDocumentType
      };
    } else if (group?.sub_groups?.length) {
      res = this.getRelationInfoFromGroup(group.sub_groups[0], rootGroup);
    }

    return res || null;
  }

  getOperatorsForFieldType(fieldType: string): AdvFieldQueryOperator[] {
    return this.advFieldQueryOperators.filter(operator => {
      return operator.forFieldTypes.indexOf(fieldType) !== -1;
    })
  }

  initAdvancedSearchParams(searchContainer: SearchContainer) {
    const advancedSearchParams = new AdvancedSearchParams();
    searchContainer.advancedSearchParams = advancedSearchParams;
    advancedSearchParams.superobject_type_ids = searchContainer.currentPathView.search_view.superobject_types;
    advancedSearchParams.field_queries = [];
    advancedSearchParams.query_groups = [];
    advancedSearchParams.db_search = false;
  }

  addFieldQuery(
    searchContainer: SearchContainer,
    newFieldQuery: AdvFieldQuery,
    queryGroup?: AdvFieldQueryGroup,
    rootGroup?: AdvFieldQueryGroup
  ) {
    const relationInfo = this.getRelationInfoFromGroup(queryGroup, rootGroup);
    newFieldQuery.relation_superobject_type_id = relationInfo ? relationInfo.superobjectTypeId : null;
    newFieldQuery.superobject_type_id = relationInfo ? relationInfo.superobjectTypeId : null;
    newFieldQuery.context_field = relationInfo ? relationInfo.contextField : null;
    newFieldQuery.is_array_context_field = relationInfo ? relationInfo.isArrayContextField : null;
    newFieldQuery.child_document_type = relationInfo ? relationInfo.childDocumentType : null;

    if (!queryGroup) {
      searchContainer.advancedSearchParams.field_queries = searchContainer.advancedSearchParams.field_queries || [];
      searchContainer.advancedSearchParams.field_queries.push(newFieldQuery)
    } else {
      queryGroup.field_queries = queryGroup.field_queries || [];
      queryGroup.field_queries.push(newFieldQuery);

      queryGroup.field_logical_operator = 'AND';
    }
  }

  addQueryGroup(searchContainer: SearchContainer,
                fieldLogicalOperator: AdvFieldQueryLogicalOperator = 'AND',
                parentGroup?: AdvFieldQueryGroup,
                rootGroup?: AdvFieldQueryGroup,
                setup = null,
                header = 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION'): AdvFieldQueryGroup {
    const newGroup = setup || new AdvFieldQueryGroup();
    const relationInfo = this.getRelationInfoFromGroup(parentGroup, rootGroup);

    if (setup) {
      if (searchContainer.advancedSearchParams.query_groups.length) {
        newGroup.logical_operator = 'AND';
      }

      newGroup.field_logical_operator = fieldLogicalOperator ?? 'AND';
      searchContainer.advancedSearchParams.query_groups.push(newGroup);

      newGroup.level = 0;

      return newGroup;
    }

    newGroup.relation_superobject_type_id = relationInfo?.superobjectTypeId || null;
    newGroup.child_document_type = relationInfo?.childDocumentType || null;

    const queryGroups = searchContainer.advancedSearchParams.query_groups;

    if (!parentGroup) {
      queryGroups.length && (newGroup.logical_operator = 'AND');

      newGroup.header = header;
      newGroup.field_logical_operator = fieldLogicalOperator ?? 'AND';

      queryGroups.push(newGroup);
    } else {
      const { sub_groups, field_queries } = parentGroup;

      (sub_groups.length || field_queries.length) &&
      (newGroup.logical_operator = 'AND');

      newGroup.header = header;

      sub_groups.push(newGroup);
    }

    newGroup.level = parentGroup ? parentGroup.level + 1 : 0;

    newGroup.field_logical_operator = fieldLogicalOperator;

    return newGroup;
  }

  addSiblingQueryGroup(searchContainer: SearchContainer,
                       node: AdvFieldQuery,
                       parentGroup?: AdvFieldQueryGroup,
                       rootGroup?: AdvFieldQueryGroup) {
    if (parentGroup && parentGroup.sub_groups.length > 0) {
      this.addQueryGroup(searchContainer, 'AND', parentGroup, rootGroup);
      this.replaceFirstFieldQueryOfLastGroup(searchContainer, node, parentGroup);
    } else {
      this.addQueryGroup(searchContainer);
      this.replaceFirstFieldQueryOfLastGroup(searchContainer, node);
    }
  }

  addSubGroup(
    searchContainer: SearchContainer, parentGroup: AdvFieldQueryGroup, rootGroup: AdvFieldQueryGroup, fieldQuery: AdvFieldQuery) {
    const newGroup = this.addQueryGroup(
      searchContainer, 'AND', parentGroup, rootGroup);
    newGroup.child_document_type = fieldQuery.child_document_type;
    newGroup.relation_superobject_type_id = rootGroup.relation_superobject_type_id;
    fieldQuery.superobject_type_id = rootGroup.relation_superobject_type_id;
    newGroup.field_queries = [fieldQuery];
  }

  clearAdvancedSearchQuery(searchContainer: SearchContainer) {
    searchContainer.advancedSearchParams.field_queries = [];
    searchContainer.advancedSearchParams.query_groups = [];
  }

  hasNoRulesSet(searchContainer: SearchContainer): boolean {
    let noRulesSet = true;
    if (searchContainer.advancedSearchParams && searchContainer.advancedSearchParams.query_groups.length > 0) {
      for (const group of searchContainer.advancedSearchParams.query_groups) {
        if (this.groupHasRulesSet(group)) {
          noRulesSet = false;
        }
      }
    }

    return noRulesSet;
  }

  hasSelectedFields(group: AdvFieldQueryGroup): boolean {
    let fieldsSelected = false;

    if (group.field_queries.length > 0) {
      for (const field of group.field_queries) {
        if (field.field_title !== '') {
          fieldsSelected = true;
          break;
        }
      }
    }

    return fieldsSelected;
  }

  // isNewRelationField(field: AdvFieldQuery | AdvancedSearchField, group: AdvFieldQueryGroup): boolean {
  //   const relationId = this.getRelationInfoFromGroup(group);
  //   return field.context_field && (!relationId || relationId === '') && group.field_queries.length >= 1;
  // }

  groupHasRulesSet(group: AdvFieldQueryGroup): boolean {
    const hasFieldQueries = group.field_queries?.some(field =>
      field.operator_selected?.includes('empty') || field.value || field.operator_selected === 'true' || field.operator_selected === 'false'
    ) ?? false;

    const hasSubGroups = group.sub_groups?.some((subGroup) => this.groupHasRulesSet(subGroup)) ?? false;
    return hasFieldQueries || hasSubGroups;
  }

  removeFieldQuery(searchContainer: SearchContainer, fieldQuery: AdvFieldQuery, parentGroup?: AdvFieldQueryGroup): void {
    if (!parentGroup) {
      searchContainer.advancedSearchParams.field_queries = searchContainer.advancedSearchParams.field_queries.filter(f => f !== fieldQuery);
    } else {
      parentGroup.field_queries = parentGroup.field_queries.filter(f => f !== fieldQuery);
    }
  }

  removeFieldQueryGroup(searchContainer: SearchContainer, group: AdvFieldQueryGroup, parentGroup?: AdvFieldQueryGroup): void {
    if (!parentGroup) {
      searchContainer.advancedSearchParams.query_groups = searchContainer.advancedSearchParams.query_groups.filter(g => g !== group);
    } else {
      parentGroup.sub_groups = parentGroup.sub_groups.filter(g => g !== group);
    }
  }

  replaceFirstFieldQueryOfLastGroup(searchContainer: SearchContainer, node: AdvFieldQuery, parentGroup?: AdvFieldQueryGroup) {
    if (parentGroup) {
      parentGroup.sub_groups[parentGroup.sub_groups.length - 1].field_queries[0] = node;
      parentGroup.sub_groups[parentGroup.sub_groups.length - 1].relation_superobject_type_id = node.relation_superobject_type_id;
    } else {
      searchContainer.advancedSearchParams.query_groups[searchContainer.advancedSearchParams.query_groups.length - 1].field_queries = [node];
      searchContainer.advancedSearchParams.query_groups[searchContainer.advancedSearchParams.query_groups.length - 1].relation_superobject_type_id = node.relation_superobject_type_id;
    }
  }

  shouldResetFieldRestrictions(group: AdvFieldQueryGroup) {
    for (const field of group.field_queries) {
      if (field.relation_superobject_type_id && field.relation_superobject_type_id !== '') {
        return false;
      }
      if (field.child_document_type && field.child_document_type !== '') {
        return false;
      }
    }

    return true;
  }

  updateFieldQuery(searchContainer: SearchContainer, oldFieldQuery: AdvFieldQuery, newFieldQuery: AdvFieldQuery, parentGroup?: AdvFieldQueryGroup): void {
    if (!parentGroup) {
      let i = searchContainer.advancedSearchParams.field_queries.findIndex(f => f === oldFieldQuery);
      searchContainer.advancedSearchParams.field_queries[i] = newFieldQuery;
    } else {
      let i = parentGroup.field_queries.findIndex(f => f === oldFieldQuery);
      parentGroup.field_queries[i] = newFieldQuery;
      if (newFieldQuery.relation_superobject_type_id || newFieldQuery.child_document_type) {
        parentGroup.relation_superobject_type_id = newFieldQuery.relation_superobject_type_id;
        parentGroup.child_document_type = newFieldQuery.child_document_type;
      }
    }
  }

  updateFieldQueryGroup(searchContainer: SearchContainer, oldFieldQueryGroup: AdvFieldQueryGroup, newFieldQueryGroup: AdvFieldQueryGroup, parentGroup?: AdvFieldQueryGroup): void {
    if (newFieldQueryGroup.relation_superobject_type_id || newFieldQueryGroup.child_document_type) {
      for (const field of newFieldQueryGroup.field_queries) {
        if (field.field_title !== '') {
          newFieldQueryGroup.header = field.field_title.split('/')[0];
        }
      }
    }

    if (!parentGroup) {
      let i = searchContainer.advancedSearchParams.query_groups.findIndex(f => f === oldFieldQueryGroup);
      searchContainer.advancedSearchParams.query_groups[i] = newFieldQueryGroup;
    } else {
      let i = parentGroup.sub_groups.findIndex(f => f === oldFieldQueryGroup);
      parentGroup.sub_groups[i] = newFieldQueryGroup;
    }
  }

  getSearchSuggestions() {
    return AdvancedSearchToolsService.searchSuggestions
  }

  getAdvFieldQueryOperators() {
    return this.advFieldQueryOperators
  }

  private async fetchFieldQueryOperators() {
    this.cms.getFieldQueryOperators().then(res => {
      this.advFieldQueryOperators = res
    })
  }

  private async fetchSearchSuggestions() {
    this.cms.getSearchSuggestions().then(res => {
      AdvancedSearchToolsService.searchSuggestions = res
    })
  }
}

