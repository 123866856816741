<app-panel-layout closePanelText="TRANS__SEARCH_CATEGORY__CLOSE_PANEL_TOOLTIP"
                  headlineText="TRANS__SEARCH_CATEGORY__QUICK_SEARCH_HEADING"
                  description="TRANS__SEARCH_CATEGORY__DESCRIPTION"
                  [loading]="loading"
                  (onClose)="close()"
                  cdkDropListGroup>

  <ng-container filter>
    <mat-form-field class="search-name-input" appearance="outline" color="accent">
      <mat-icon matSuffix *ngIf="categoryNameControl.dirty && categoryNameControl.invalid && categoryNameControl.hasError('exists')" color="warn">
        error
      </mat-icon>
      <mat-icon matSuffix *ngIf="categoryNameControl.valid" style="color: green;">
        check_circle
      </mat-icon>
      <mat-label>{{ 'TRANS__SEARCH_CATEGORY__NAME_INPUT_PLACEHOLDER' | translate }}</mat-label>
      <!--suppress HtmlFormInputWithoutLabel -->
      <input matInput
             [formControl]="categoryNameControl"
             [placeholder]="'TRANS__SEARCH_CATEGORY__NAME_INPUT_PLACEHOLDER' | translate"
             autocomplete="off"
             required>
    </mat-form-field>

    <div class="filter-container">
      <app-field-filter [fields]="allFields"
                        [selectedObjectTypes]="selectedObjectTypeIds"
                        (filterCleared)="selectedObjectTypeIds = []"
                        (fieldsFiltered)="setFilteredFields($event)"
                        placeholder="TRANS__SEARCH_CATEGORY__FILTER_FIELDS_PLACEHOLDER">
        <app-select-object-type-button buttonLabel="TRANS__SEARCH_CATEGORY__OBJECT_TYPE_FILTER_BUTTON_LABEL"
                                       (selectionChanged)="selectedObjectTypeIds = $event">
        </app-select-object-type-button>
      </app-field-filter>
    </div>
  </ng-container>

  <div class="content-container">
    <app-content-section headerPrimaryText="TRANS__SEARCH_CATEGORY__FIELD_LIST_HEADING"
                         [headerSecondaryText]="'(' + (filteredFields?.length || 0) + ')'">

      <div class="list-container">
        <mat-selection-list dense (selectionChange)="handleSelectionChanged($event)">
          <ng-container *ngFor="let grp of fieldGroups">

            <h3 matSubheader>{{ grp.groupLetter }}</h3>

            <mat-list-option *ngFor="let field of grp.items" [value]="field" [selected]="isSelected(field.fieldId)">

            <span matLine class="field-list-item">
              <span class="field-item-name">
                {{ field.title.name }}
                <span class="secondary" *ngIf="field.title.alternateName">
                  ({{ field.title.alternateName }})
                </span>
              </span>
            </span>

              <span matLine class="field-item-model-name" *ngIf="field && field.appearsOn && field.appearsOn.length > 0">
              {{ getModelNames(field) }}
            </span>

            </mat-list-option>

            <mat-divider></mat-divider>

          </ng-container>
        </mat-selection-list>
      </div>

      <ng-container footer>

        <div class="selection-container">
          <span class="footer__label">
            {{ 'TRANS__SEARCH_CATEGORY__SELECTED_FIELDS' | translate }}
            ({{ getSelectionCount() }})
          </span>

          <mat-chip-listbox>
            <mat-chip-option *ngFor="let selected of getSelectedFields() | slice: 0:NUMBER_OF_SELECTED_ITEMS_TO_SHOW"
                      (removed)="removeSelectedField(selected.fieldId)">
              {{ selected.title.name }}
              <mat-icon matChipRemove>close</mat-icon>
            </mat-chip-option>

            <span *ngIf="getSelectionCount() > NUMBER_OF_SELECTED_ITEMS_TO_SHOW">
              +{{ getSelectionCount() - NUMBER_OF_SELECTED_ITEMS_TO_SHOW }}
            </span>
          </mat-chip-listbox>
        </div>

        <span class="button-container">
          <button mat-flat-button
                  type="button"
                  color="accent"
                  [disabled]="categoryNameControl.invalid || getSelectionCount() === 0"
                  (click)="saveCurrentCategory()">
            {{ 'TRANS__SEARCH_CATEGORY__SAVE_SEARCH_BUTTON_LABEL' | translate }}
          </button>

          <button mat-flat-button type="button" (click)="close()">
            {{ 'TRANS__SEARCH_CATEGORY__CANCEL_BUTTON_LABEL' | translate }}
          </button>
        </span>

      </ng-container>

    </app-content-section>
  </div>


</app-panel-layout>
