import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IdentifierFormat} from '../identifier-format';
import {SelectionChangedEvent, TableColumn} from '../../../shared/primus-tables/primus-table/primus-table.component';
import {AdminIdFormatService} from '../admin-id-format.service';

interface FilterAction {
  id: string;
  name: string;
}

enum Actions {
  DELETE = 'delete'
}

@Component({
  selector: 'app-admin-id-format-edit-section',
  templateUrl: './admin-id-format-edit-section.component.html',
  styleUrls: ['./admin-id-format-edit-section.component.scss']
})
export class AdminIdFormatEditSectionComponent {

  readonly columns: Array<TableColumn<IdentifierFormat>> = [
    {
      title: 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_OBJECT_TYPE_LABEL',
      property: 'identifier_format_superobject_type_id_value'
    },
    {
      title: 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_PREFIX_LABEL',
      property: 'prefix'
    },
    {
      title: 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_FORMAT_LABEL',
      property: 'format'
    },
    {
      title: 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_NUM_DIGITS_LABEL',
      property: 'digits'
    },
    {
      title: 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_SEPARATOR_LABEL',
      property: 'subtype_separator'
    },
    {
      title: 'TRANS__ADMIN__ID_FORMAT__TABLE_COL_NUM_SUB_DIGITS_LABEL',
      property: 'subtype_digits'
    }
  ];

  @Input() heading: string;
  @Input() description: string;
  @Input() formats: Array<IdentifierFormat>;
  @Input() canAddPrefixes: boolean;
  @Output() public readonly addPrefixClicked: EventEmitter<void>;
  @Output() public readonly editPrefixClicked: EventEmitter<IdentifierFormat>;
  @Output() public readonly formatDeleted: EventEmitter<IdentifierFormat>;

  readonly actions: Array<FilterAction>;
  currentFilterString: string;
  private currentlySelected: Array<IdentifierFormat>;

  constructor(private readonly idFormatService: AdminIdFormatService) {
    this.formats = [];
    this.canAddPrefixes = true;
    this.addPrefixClicked = new EventEmitter<void>();
    this.editPrefixClicked = new EventEmitter<IdentifierFormat>();
    this.formatDeleted = new EventEmitter<IdentifierFormat>();
    this.currentlySelected = [];
    this.actions = [
      {
        id: Actions.DELETE,
        name: 'TRANS__ADMIN__ID_FORMAT__ACTIONS__DELETE_SELECTED'
      }
    ];
  }

  onFilterStringChanged(filterString: string) {
    this.currentFilterString = filterString;
  }

  handleAction(action: any): void {
    if (!this.currentlySelected?.length) {
      return;
    }
    if (action.id === Actions.DELETE) {
      this.currentlySelected.forEach(async (idFormat) => {
        const deletedId = await this.idFormatService.delete(idFormat);
        if (deletedId) {
          this.formatDeleted.emit(this.formats.find(format => format.artifact_id === deletedId));
        }
      });
    }
  }

  handleSelectionChanged(event: SelectionChangedEvent<IdentifierFormat>): void {
    this.currentlySelected = event.selected || [];
  }

  handleAddPrefixClicked() {
    this.addPrefixClicked.emit();
  }

  handleEditPrefixClicked(format: IdentifierFormat): void {
    this.editPrefixClicked.emit(format);
  }
}
