import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges
} from '@angular/core';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {OptionsService} from '../../../core/options.service';
import {HierarchicNode} from '../../../core/definitions/hierarchic-objects';
import {CurrentQuery} from '../../../core/definitions/current-query';
import {FieldType} from '../../../core/definitions/field-type.enum';
import {OptionsDialogService} from '../../options-dialog.service';
import {Reference} from "../../../core/definitions/reference";

@Component({
  selector: 'app-hierarchic-search-panel',
  templateUrl: './hierarchic-search-panel.component.html',
  styleUrls: ['./hierarchic-search-panel.component.scss']
})
export class HierarchicSearchPanelComponent implements OnChanges {

  @ViewChild('queryInput') queryInput: ElementRef;
  @Input() fieldParameters: FieldParameters;
  @Input() reference: Reference;
  @Input() query: CurrentQuery;
  @Input() searchPanelOpen: boolean;
  @Output() nodeSelected = new EventEmitter<HierarchicNode>();
  @Output() nodeOpened = new EventEmitter<HierarchicNode>();

  rootNode: HierarchicNode;

  searching = false;
  private searchStartTime: number;
  private searchTimeoutId: number;
  private page = 0;
  private pageSize = 200;
  treeControl = new NestedTreeControl<HierarchicNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<HierarchicNode>();
  isArray: boolean;

  constructor(private optionsService: OptionsService,
              private optionsDialogService: OptionsDialogService) {
  }

  ngOnChanges(): void {
    this.isArray = this.fieldParameters.field.field_type === FieldType.ARRAY;
    if (this.searchPanelOpen) {
      setTimeout(() => {
        this.queryInput.nativeElement.focus();
      }, 100);
    }
  }

  async queryChanged() {
    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = 0;
    }
    this.searchTimeoutId = window.setTimeout(() => {
      this.getNodes().then();
    }, 300);
  }

  clearQuery() {
    this.query.value = '';
    this.resetNodes();
  }

  onNodeSelected(node: HierarchicNode, isLeafNode: boolean) {
    if (!isLeafNode && this.reference.hierarchic_parent_select_forbidden) {
      if (!this.treeControl.isExpanded(node)) {
        this.treeControl.expand(node);
        this.onNodeOpened(node);
      } else {
        this.treeControl.collapse(node);
      }
    } else {
      this.nodeSelected.emit(node);
    }
  }

  onNodeOpened(node: HierarchicNode) {
    this.nodeOpened.emit(node);
  }

  hasChild = (_: number, node: HierarchicNode) => !!node.children && node.children.length > 0;

  // Will be called upon by parent component, must thus be public
  setNodeIsSelectedById(itemId, isSelected) {
    this.optionsService.setNodeIsSelectedById(this.rootNode, itemId, isSelected);
  }

  private setSelectedHierarchicNodes() {
    this.optionsService.setSelectedHierarchicNodes(this.rootNode, this.fieldParameters);
  }

  private async getNodes() {
    if (this.query.value.length >= 2 && !this.searching) {
      this.searching = true;
      this.searchStartTime = (new Date()).getTime();
      this.rootNode = await this.optionsService.hierarchicQuerySearch(
        this.fieldParameters, this.query.value, this.page, this.pageSize);
      this.setSelectedHierarchicNodes();
      this.dataSource.data = this.rootNode.children;
      this.treeControl.dataNodes = this.dataSource.data;
      this.treeControl.expandAll();
      this.searching = false;
    } else if (!this.query.value.length) {
      this.resetNodes();
    }
    if (this.searching && (new Date()).getTime() - this.searchStartTime > 30000) {
      this.searching = false;
      console.warn('Restarting search due to timeout');
    }
  }

  private resetNodes() {
    this.dataSource.data = [];
    this.treeControl.dataNodes = [];
  }

  async openDescription(node: HierarchicNode) {
    node.$$description = node['description.description'];
    const nodeDisplayName = await this.optionsService.getNodeDisplayField(this.reference, 'name.name');
    node.$$name = node[nodeDisplayName] || node['name.name'];
    this.optionsDialogService.toggleDescription(node);
  }

}
