import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchExecutorService} from '../../object-search/search-executor.service';
import {SearchContainer} from '../../core/definitions/search-container';
import {ResultViewService} from '../../object-search/result-view.service';
import {ContentListContainer} from '../../core/definitions/object-content-tab/content-list-container';

@Component({
  selector: 'app-object-content-list-search-view',
  templateUrl: './object-content-list-search-view.component.html',
  styleUrls: ['./object-content-list-search-view.component.scss']
})
export class ObjectContentListSearchViewComponent implements OnInit {

  @Input() contentListContainer: ContentListContainer;
  @Output() objectRefresh = new EventEmitter<any>();

  searchContainer: SearchContainer;

  constructor(private searchExecutorService: SearchExecutorService,
              private resultViewService: ResultViewService) { }

  ngOnInit(): void {
    this.searchContainer = this.contentListContainer.searchContainer;
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.checkSetSearchRowsInit);
  }

  onObjectRefresh(event) {
    this.objectRefresh.emit(event);
  }

  private checkSetSearchRowsInit = () => {
    this.checkSetSearchRows();
  }

  private checkSetSearchRows() {
    this.resultViewService.calculateAndSetsDefaultRowsColumns(this.searchContainer);

    const viewName = this.searchContainer.searchResultViewName;
    const oldRows = this.searchContainer.rows[viewName];
    const calculatedRows = this.resultViewService.getDefaultCalculatedRowsColumns(viewName).rows;
    let mustSearch = false;
    if (calculatedRows && calculatedRows !== oldRows) {
      mustSearch = true;
      this.searchContainer.rows[viewName] = calculatedRows;
    }
    if (mustSearch && !this.searchContainer.displayAll) {
      this.searchExecutorService.runSearch(this.searchContainer).then();
    }
  }


}
