<div class="date-input-wrapper">
  <mat-form-field class="date-input" color="accent">
    <!--suppress HtmlFormInputWithoutLabel, AngularInvalidExpressionResultType -->
    <input matInput
           [matDatepicker]="dateFrom"
           [placeholder]="(label | translate) + ' ' + (fromDatePlaceholder | translate)"
           [(ngModel)]="fromDate"
           [max]="toDate"
           (dateChange)="dateChanged()"
           autocomplete="off">
    <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
    <mat-datepicker #dateFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="date-input" color="accent">
    <!--suppress HtmlFormInputWithoutLabel, AngularInvalidExpressionResultType -->
    <input matInput
           [matDatepicker]="dateTo"
           [(ngModel)]="toDate"
           [min]="fromDate"
           [placeholder]="(label | translate) + ' ' + (toDatePlaceholder | translate)"
           (dateChange)="dateChanged()"
           autocomplete="off">
    <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
    <mat-datepicker #dateTo></mat-datepicker>
  </mat-form-field>
</div>
