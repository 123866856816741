@if (generalFieldType) {
  <div [formGroup]="fieldParameters.sectionsContainer.formGroup" class="edit-field-input">
    @switch (fieldInputType) {
      @case ('display') {
        <span>{{fieldTextValue}}</span>
      }
      @case (FieldInputType.TEXT_AREA) {
        <textarea
          rows="3"
          [formControlName]="fieldKey"
          [placeholder]="placeholder"
          title="{{placeholder}}"
          [id]="fieldKey"
          [name]="fieldKey"
          (change)="onValueChange($event)"
          (blur)="onFieldBlur()"
          (focus)="onFieldFocus()"
          (keyup)="autoExpand()"
          [tabIndex]="isDisabled ? -1 : 0"
          [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy,
        'errorInputFrame': fieldValidation.isFieldInvalid(fieldParameters.sectionsContainer, fieldKey),
        'disabled': isDisabled}"
          #textarea></textarea>
      }
      @case ('select') {
        <div class="edit-field-input__select-container">
          <mat-icon class="edit-field-input__select-icon">arrow_drop_down</mat-icon>
          <select
            [id]="fieldKey"
            [name]="fieldKey"
            [formControlName]="fieldKey"
            title="{{placeholder}}"
            (change)="onValueChange($event)"
            class="custom-select edit-field-input__select"
            [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy, 'selectedValue': fieldParameters.object[fieldParameters.field.name]}">
            @if (fieldParameters.field.is_required !== 'required') {
              <option [value]=""></option>
            }
            @for (opt of valueOptionService.getValueOptionsForField(fieldParameters.field).options; track opt) {
              <option [value]="opt['value']">{{opt['label'] | translate}}</option>
            }
          </select>
          @if (fieldTextValue === '') {
            <label class="custom-select__placeholder">
              <span translate="TRANS__EDIT_FIELD__CHOOSE"></span>&nbsp;
              <span class="custom-select__placeholder-key">{{placeholder}}..</span></label>
          }
        </div>
      }
      @case (FieldInputType.RADIO_OPTION) {
        <app-edit-field-radio-options
          [formControlName]="fieldKey"
          [fieldParameters]="fieldParameters"
        ></app-edit-field-radio-options>
      }
      @case (FieldInputType.MAP_ID) {
        <app-edit-field-select
          [fieldParameters]="fieldParameters"
          [formControlName]="fieldKey"
          title="{{placeholder}}"
          [inFocus]="inFocus"
          [forceUpdate]="forceUpdate"
          (fieldBlur)="onFieldBlur()"
          (fieldFocus)="onFieldFocus()"
          [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
        ></app-edit-field-select>
      }
      @case (FieldInputType.HIERARCHIC_SELECT) {
        <app-edit-field-hierarchic-select
          [formControlName]="fieldKey"
          [fieldParameters]="fieldParameters"
          [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
        ></app-edit-field-hierarchic-select>
      }
      @case (FieldInputType.IDENTIFIER) {
        <app-edit-field-identifier
          [fieldParameters]="fieldParameters"
          [formControlName]="fieldKey"
          [placeholder]="placeholder"
          title="{{placeholder}}"
          [inFocus]="inFocus"
          (fieldBlur)="onFieldBlur()"
          (fieldFocus)="onFieldFocus()"
          [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
        ></app-edit-field-identifier>
      }
      @case (FieldInputType.SEARCH_SELECTOR) {
        <app-edit-field-search-selector
          [fieldParameters]="fieldParameters"
          [formControlName]="fieldKey"
          [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
        ></app-edit-field-search-selector>
      }
      @case (FieldInputType.SEARCH_SELECTOR_MULTIPLE) {
        <app-edit-field-search-selector-multiple
          [fieldParameters]="fieldParameters"
          [formControlName]="fieldKey"
          [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
        ></app-edit-field-search-selector-multiple>
      }
      @case (FieldInputType.CHECKBOX) {
        <mat-checkbox
          class="edit-field-input__type-checkbox white-bg-in-checkbox"
          [formControlName]="fieldKey"
          (keyup)="checkValueWithKey($event)"
          [id]="fieldKey"
          (change)="onValueChange($event)"></mat-checkbox>
      }
      @case (FieldInputType.CHECK_ARRAY) {
        <app-edit-field-check-array
          [fieldParameters]="fieldParameters"
          [formControlName]="fieldKey"
          [formControl]="getFormControlField()"
        ></app-edit-field-check-array>
      }
      @case (FieldInputType.RADIO_INLINE_ARRAY) {
        <app-edit-field-radio-inline-array
          [fieldParameters]="fieldParameters"
          [formControlName]="fieldKey"
          [formControl]="getFormControlField()"
        ></app-edit-field-radio-inline-array>
      }
      @case (FieldInputType.DATE_ISO) {
        <div class="edit-field-input__edit-field-precision-date">
          <app-edit-field-date-historic
            [fieldParameters]="fieldParameters"
            [field]="fieldParameters.field"
            [formControlName]="fieldKey"
            [placeholder]="placeholder"
          ></app-edit-field-date-historic>
        </div>
      }
      @case (FieldInputType.DATE_TIME_ISO) {
        <div class="edit-field-input__edit-field-precision-date">
          <app-edit-field-date-picker
            [fieldParameters]="fieldParameters"
            [field]="fieldParameters.field"
            [formControlName]="fieldKey"
            [placeholder]="placeholder"
          ></app-edit-field-date-picker>
        </div>
      }
      @case (FieldInputType.DATE_TIME_ISO_RANGE) {
        <app-edit-field-precision-date-range
          [fieldParameters]="fieldParameters"
          [fromDateField]="fieldParameters.field"
          [fromDateControlName]="fieldKey"
        ></app-edit-field-precision-date-range>
      }
      @case (FieldInputType.OBJECT_USAGE) {
        <app-object-usage-field
          [fieldParameters]="fieldParameters"
        ></app-object-usage-field>
      }
      @default {
        <input [formControlName]="fieldKey"
               [placeholder]="placeholder"
               title="{{placeholder}}"
               [id]="fieldKey"
               [name]="fieldKey"
               [type]="fieldInputType"
               (blur)="onFieldBlur()"
               (focus)="onFieldFocus()"
               (change)="onValueChange($event)"
               [tabIndex]="isDisabled ? -1 : 0"
               [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy,
             'errorInputFrame': fieldValidation.isFieldInvalid(fieldParameters.sectionsContainer, fieldKey),
              'disabled': isDisabled}"
               autocomplete="off">
      }
    }
    @if (fieldParameters.sectionsContainer.isCopy) {
      <app-copy-keep-checkbox
        class="copy-keep-checkbox"
        [sectionsContainer]="fieldParameters.sectionsContainer"
        [object]="fieldParameters.object"
        [field]="fieldParameters.field"
      ></app-copy-keep-checkbox>
    }
    @if (fieldWarning) {
      <div class="field-warning error-warn-color"><span>{{fieldWarningText | translate}}</span></div>
    }
    @if (checkDisabled && disabledReason) {
      <div class="disable-reason">
        <i class="icon-warning"></i>
        <span>{{disabledReason | translate}}</span></div>
    }
    @if (isFieldInvalid) {
      <div class="errorInEditField"
           [ngClass]="{'unfocused': !inFocus.value, 'focused': inFocus.value}">
        <i class="icon-warning"></i>
        @if (invalidTypeErrors['maxlength']) {
          <span translate=""
                [translateParams]="{maxLength: invalidTypeErrors['maxlength']['requiredLength'], propLength: invalidTypeErrors['maxlength']['actualLength']}">TRANS__VALIDATION__INVALID_MAX_LENGTH</span>
        }
        @if (invalidTypeErrors['minlength']) {
          <span translate=""
                [translateParams]="{minLength: invalidTypeErrors['minlength']['requiredLength'], propLength: invalidTypeErrors['minlength']['actualLength']}">TRANS__VALIDATION__INVALID_MIN_LENGTH</span>
        }
        @if (invalidTypeErrors['max']) {
          <span translate=""
                [translateParams]="{max: invalidTypeErrors['max']['max'], propNumber: invalidTypeErrors['max']['actual']}">TRANS__VALIDATION__INVALID_MAX_NUMBER</span>
        }
        @if (invalidTypeErrors['min']) {
          <span translate=""
                [translateParams]="{min: invalidTypeErrors['min']['min'], propNumber: invalidTypeErrors['min']['actual']}">TRANS__VALIDATION__INVALID_MIN_NUMBER</span>
        }
        @if (invalidTypeErrors['required']) {
          <span translate="">TRANS__VALIDATION__REQUIRED</span>
        }
        @if (invalidTypeErrors['validatePrecisionDate']) {
          <span>{{invalidTypeErrors['validatePrecisionDate'].message | translate}}</span>
        }
        @if (invalidTypeErrors['validateIdentifier']) {
          <span translate="">TRANS__VALIDATION__IDENTIFIER_EXISTS</span>
        }
        @if (invalidTypeErrors['validateCompare']) {
          <span>{{invalidTypeErrors['validateCompare'].message | translate}}</span>
        }
        @if (invalidTypeErrors['validateUsername']) {
          <span translate="">TRANS__VALIDATION__INVALID_USERNAME</span>
        }
      </div>
    }

  </div>
}
