<mat-form-field appearance="outline"
                class="hierarchic-search-panel__search-field">
  <mat-label>
    <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__SEARCH_FOR"></span>&nbsp;<span
    [translate]="fieldParameters.field.title"></span>
  </mat-label>
  <input #queryInput matInput type="text" [(ngModel)]="query.value" (ngModelChange)="queryChanged()">
  @if (query.value) {
    <button type="button" matSuffix mat-icon-button aria-label="Clear"
            (click)="clearQuery()">
      <mat-icon>close</mat-icon>
    </button>
  }
</mat-form-field>
<div class="hierarchic-search-panel__content"
     [ngClass]="{'create': fieldParameters.canAddNew}">
  @if (searching) {
    <div class="hierarchic-search-panel__searching">
      <mat-progress-spinner mode="indeterminate"
                            color="accent"
                            [diameter]="16"></mat-progress-spinner>
      <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__SEARCHING"></span></div>
  } @else {
    <mat-tree [dataSource]="dataSource"
              [treeControl]="treeControl" class="hierarchic-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <!-- use a disabled button to provide padding for tree leaf (does not work for some reason) -->
        <button mat-icon-button disabled>
          <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
        </button>
        @if (isArray) {
          <mat-checkbox [checked]="node['$$isSelected']"
                        (change)="onNodeSelected(node, true)"></mat-checkbox>
        }
        <button mat-button type="button"
                class="hierarchic-search-panel__node-text"
                [title]="node['$$name']"
                (click)="onNodeSelected(node, true)">
          <span>{{ node['$$name'] }}</span>
          @if (node['authority_id_value'] === 'KulturNav') {
            <span class="concept-authority"></span>
          }
        </button>
        @if (node['description.description']) {
          <button (click)="openDescription(node)"
                  mat-icon-button
                  type="button"
                  class="hierarchic-search-panel__node-description">
            <mat-icon>info</mat-icon>
          </button>
        }
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <ul>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle (click)="onNodeOpened(node)"
                    type="button"
                    [attr.aria-label]="'Toggle ' + node['$$name']">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            @if (isArray) {
              <mat-checkbox [checked]="node['$$isSelected']"
                            (change)="onNodeSelected(node, false)"></mat-checkbox>
            }
            <button mat-button type="button"
                    class="hierarchic-search-panel__node-text"
                    [title]="node['$$name']"
                    (click)="onNodeSelected(node, false)">
              <span>{{ node['$$name'] }}</span>
              @if (node['authority_id_value'] === 'KulturNav') {
                <span class="concept-authority"></span>
              }
            </button>
            @if (node['description.description']) {
              <button (click)="openDescription(node)"
                      mat-icon-button
                      type="button"
                      class="hierarchic-search-panel__node-description">
                <mat-icon>info</mat-icon>
              </button>
            }
          </div>
          <ul class="hierarchic-search-panel__node-children"
              [class.hierarchic-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
      </ul>
    </mat-tree>
  }
  @if (!query.value) {
    <div class="hierarchic-search-panel__feedback">
      <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__NO_SEARCH"></span>
    </div>
  }
  @if (!searching && dataSource.data.length === 0 && query.value) {
    <div class="hierarchic-search-panel__feedback">
      <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__NO_SEARCH_HITS"></span></div>
  }
</div>
