import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';

import {EditFieldSelectComponent} from './edit-field-select/edit-field-select.component';
import {EditFieldSelectOptionsComponent} from './edit-field-select/edit-field-select-options/edit-field-select-options.component';
// eslint-disable-next-line max-len
import {EditFieldSelectSelectedOptionsComponent} from './edit-field-select/edit-field-select-selected-options/edit-field-select-selected-options.component';
import {SharedModule} from '../shared/shared.module';
import {EditObjectDialogComponent} from './edit-object-dialog/edit-object-dialog.component';
import {EditFieldComponent} from './edit-field/edit-field.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {EditFieldsComponent} from './edit-fields/edit-fields.component';
import {SectionViewEditComponent} from './section-view-edit/section-view-edit.component';
import {SectionViewTitleEditComponent} from './section-view-edit/section-view-title-edit/section-view-title-edit.component';
import {EditFieldInlineArrayComponent} from './edit-field-inline-array/edit-field-inline-array.component';
// eslint-disable-next-line max-len
import {EditFieldInlineArrayItemTopBarComponent} from './edit-field-inline-array/edit-field-inline-array-item-top-bar/edit-field-inline-array-item-top-bar.component';
// eslint-disable-next-line max-len
import {EditFieldInlineArrayAddButtonComponent} from './edit-field-inline-array/edit-field-inline-array-add-button/edit-field-inline-array-add-button.component';
import {EditFieldIdentifierComponent} from './edit-field-identifier/edit-field-identifier.component';
// eslint-disable-next-line max-len
import {EditFieldInlineArrayFieldsComponent} from './edit-field-inline-array/edit-field-inline-array-fields/edit-field-inline-array-fields.component';
import {EditFieldSwitchComponent} from './edit-field-switch/edit-field-switch.component';
import {EditFieldCompareComponent} from './edit-field-compare/edit-field-compare.component';
import {EditFieldSearchSelectorComponent} from './edit-field-search-selector/edit-field-search-selector.component';
// eslint-disable-next-line max-len
import {EditFieldSearchSelectorMultipleComponent} from './edit-field-search-selector-multiple/edit-field-search-selector-multiple.component';
// eslint-disable-next-line max-len
import {EditFieldInlineArrayDeleteButtonComponent} from './edit-field-inline-array/edit-field-inline-array-delete-button/edit-field-inline-array-delete-button.component';
import {CopyKeepCheckboxComponent} from './copy-keep-checkbox/copy-keep-checkbox.component';
import {CopyKeepService} from './copy-keep-checkbox/copy-keep.service';
// eslint-disable-next-line max-len
import {EditFieldInlineArrayItemComponent} from './edit-field-inline-array/edit-field-inline-array-item/edit-field-inline-array-item.component';
// eslint-disable-next-line max-len
import {EditPrimeFieldsComponent} from './edit-prime-fields/edit-prime-fields.component';
import {EditFieldCheckArrayComponent} from './edit-field-check-array/edit-field-check-array.component';
import {EditFieldPrecisionDateRangeComponent} from './edit-field-precision-date-range/edit-field-precision-date-range.component';
import {ObjectEditComponent} from './object-edit/object-edit.component';
import {EditFieldImageSelectorComponent} from './edit-field-image-selector/edit-field-image-selector.component';
import {EditFieldDatePickerComponent} from './edit-field-date-picker/edit-field-date-picker.component';
import {EditFieldDateHistoricComponent} from './edit-field-date-historic/edit-field-date-historic.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {EditFieldSelectQueryComponent} from './edit-field-select/edit-field-select-query/edit-field-select-query.component';
// eslint-disable-next-line max-len
import {HierarchicCreateOptionMenuComponent} from './edit-field-hierarchic-select/hierarchic-create-option-menu/hierarchic-create-option-menu.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {EditFieldRadioOptionsComponent} from './edit-field-radio-options/edit-field-radio-options.component';
import {EditFieldInlineFieldComponent} from './edit-field-inline-field/edit-field-inline-field.component';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {EditFieldMetaOperationFieldComponent} from './edit-field-meta-operation-field/edit-field-meta-operation-field.component';
import {EditFieldContextComponent} from './edit-field-context/edit-field-context.component';
import {MatChipsModule} from '@angular/material/chips';
import {ReferenceUsageComponent} from './reference-usage/reference-usage.component';
import {EditFieldSearchSelectorMultipleItemListComponent} from './edit-field-search-selector-multiple/edit-field-search-selector-multiple-item-list/edit-field-search-selector-multiple-item-list.component';
import {EditFieldInlineArrayItemListComponent} from './edit-field-inline-array/edit-field-inline-array-item-list/edit-field-inline-array-item-list.component';
import {InlineArrayHeaderItemComponent} from './edit-field-inline-array/inline-array-header-item/inline-array-header-item.component';
import {InlineArrayHeaderSepComponent} from './edit-field-inline-array/inline-array-header-sep/inline-array-header-sep.component';
import {InlineArrayHeaderValueComponent} from './edit-field-inline-array/inline-array-header-value/inline-array-header-value.component';
import { EditFieldHierarchicSelectComponent } from './edit-field-hierarchic-select/edit-field-hierarchic-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import { HierarchicSearchPanelComponent } from './edit-field-hierarchic-select/hierarchic-search-panel/hierarchic-search-panel.component';
import { HierarchicListPanelComponent } from './edit-field-hierarchic-select/hierarchic-list-panel/hierarchic-list-panel.component';
import {MatTreeModule} from '@angular/material/tree';
import {DragulaModule} from 'ng2-dragula';
import {MatTooltipModule} from '@angular/material/tooltip';
import { EditFieldInputComponent } from './edit-field-input/edit-field-input.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatListModule} from "@angular/material/list";
import { EditObjectChildDialogComponent } from './edit-object-child-dialog/edit-object-child-dialog.component';
import {
    EditFieldRadioInlineArrayComponent
} from "./edit-field-radio-inline-array/edit-field-radio-inline-array.component";


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatDialogModule,
        DragDropModule,
        OverviewFieldsModule,
        MatButtonModule,
        MatMenuModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatCheckboxModule,
        MatChipsModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatTabsModule,
        MatInputModule,
        MatTreeModule,
        DragulaModule,
        MatTooltipModule,
        ScrollingModule,
        MatListModule,
        EditFieldRadioInlineArrayComponent
    ],
    exports: [
        EditFieldSwitchComponent,
        EditPrimeFieldsComponent,
        ObjectEditComponent,
        EditFieldComponent,
        EditFieldImageSelectorComponent,
        EditFieldsComponent,
        SectionViewEditComponent,
        EditFieldInputComponent,
        HierarchicSearchPanelComponent
    ],
  declarations: [
    ConfirmDialogComponent,
    EditFieldComponent,
    EditFieldInlineArrayAddButtonComponent,
    EditFieldInlineArrayComponent,
    EditFieldInlineArrayItemTopBarComponent,
    EditFieldInlineArrayDeleteButtonComponent,
    EditFieldsComponent,
    EditFieldSelectComponent,
    EditFieldSelectOptionsComponent,
    EditFieldSelectSelectedOptionsComponent,
    EditObjectDialogComponent,
    SectionViewEditComponent,
    SectionViewTitleEditComponent,
    EditFieldIdentifierComponent,
    EditFieldInlineArrayFieldsComponent,
    EditFieldSwitchComponent,
    EditFieldCompareComponent,
    EditFieldSearchSelectorComponent,
    EditFieldSearchSelectorMultipleComponent,
    CopyKeepCheckboxComponent,
    EditFieldInlineArrayItemComponent,
    EditPrimeFieldsComponent,
    EditFieldCheckArrayComponent,
    EditFieldPrecisionDateRangeComponent,
    ObjectEditComponent,
    EditFieldImageSelectorComponent,
    EditFieldDatePickerComponent,
    EditFieldDateHistoricComponent,
    EditFieldSelectQueryComponent,
    HierarchicCreateOptionMenuComponent,
    EditFieldRadioOptionsComponent,
    EditFieldInlineFieldComponent,
    EditFieldMetaOperationFieldComponent,
    EditFieldContextComponent,
    ReferenceUsageComponent,
    EditFieldSearchSelectorMultipleItemListComponent,
    EditFieldInlineArrayItemListComponent,
    InlineArrayHeaderItemComponent,
    InlineArrayHeaderSepComponent,
    InlineArrayHeaderValueComponent,
    EditFieldHierarchicSelectComponent,
    HierarchicSearchPanelComponent,
    HierarchicListPanelComponent,
    EditFieldInputComponent,
    EditObjectChildDialogComponent,
  ],
  providers: [
    CopyKeepService
  ]
})
export class ObjectEditModule {
}
