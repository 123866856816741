import { Injectable } from '@angular/core';
import {CmsApiService} from "./cms-api.service";
import {
  AdvancedSearchField,
  GetAdvancedSearchFieldParams
} from "./definitions/advanced-search-field";
import {AdvFieldQuery, AdvFieldQueryGroup} from "./definitions/advanced-search-params";
import {SearchObject} from "./definitions/search-object";
import {HierarchicNode} from "./definitions/hierarchic-objects";
import {OptionsService} from "./options.service";
import {SearchReferenceService} from "./search-reference.service";

@Injectable({
  providedIn: 'root'
})
export class AdvancedSearchFieldService {

  constructor(private cms: CmsApiService,
              private optionsService: OptionsService,
              private referenceService: SearchReferenceService) { }

  // Fields and relation fields that are already used in queries must be excluded. This is achieved by filtering
  // used fields and relations after obtaining the fields from the backend. Why not just filter them in the backend?
  // Well, Sunshine, that would require changing the API to a POST based API instead of GET due to potential large
  // request size since all used fields and relations would have to be sent.
  async getAdvancedSearchFields(
    params: GetAdvancedSearchFieldParams, queryGroups: AdvFieldQueryGroup[], currentGroup: AdvFieldQueryGroup): Promise<AdvancedSearchField[]> {
    const fieldRes = await this.cms.getAdvancedSearchFields(params);
    let res = fieldRes.fields;
    let [usedFieldIds, usedRelationIds] = this.getUsedFieldInfo(queryGroups, currentGroup, params.is_sub_group);
    if (usedRelationIds.length > 0) {
      res = this.filterUsedRelations(res, usedRelationIds);
    }
    if (usedFieldIds.length > 0) {
      res = this.fiterUsedFields(res, usedFieldIds);
    }
    return res;
  }

  async getAdvancedSearchFieldValues(
    fieldQuery: AdvFieldQuery,
    query: string = '',
    superobjectTypeIds: string[],
    max_value_count: number,
    folderId: string): Promise<SearchObject[]> {
    let fieldName = fieldQuery.path ? `${fieldQuery.path}.${fieldQuery.field_name}` : fieldQuery.field_name;
    if (folderId && (fieldQuery.relation_superobject_type_id || fieldQuery.child_document_type)) {
      folderId = ''
    }
    let params = {
      query: query,
      superobject_type_ids: superobjectTypeIds.join(','),
      child_document_type: fieldQuery.child_document_type,
      facet_limit: max_value_count,
      folder_id: folderId
    };
    const searchObjects = await this.cms.getAdvancedSearchFieldValues(fieldName, params);
    return this.setHierarchicFieldValues(searchObjects, fieldQuery)
  }

  private async setHierarchicFieldValues(searchObjects: SearchObject[], fieldQuery: AdvFieldQuery): Promise<SearchObject[]> {
    const reference = this.referenceService.getSearchReferenceFromReferenceId(fieldQuery.reference_id);
    if (searchObjects.length && reference?.is_hierarchic) {
      const nodeDisplayField = await this.optionsService.getNodeDisplayField(reference, 'name.name');
      const rootNode = new HierarchicNode();
      const searchNodes: HierarchicNode[] = searchObjects.map(searchObj => this.optionsService.searchObjectToNode(
        searchObj, false, nodeDisplayField));
      this.optionsService.putSearchNodesInHierarchy(rootNode, searchNodes, nodeDisplayField);
      return rootNode.children;
    } else {
      return searchObjects;
    }
  }

  private getUsedFieldInfo(queryGroups: AdvFieldQueryGroup[], currentGroup: AdvFieldQueryGroup, isSubGroup = false): [string[], string[]] {
    let usedFieldIds = [];
    let usedRelationIds = [];
    if (isSubGroup) {
      return this.getUsedFieldInfoForGroup(currentGroup);
    }
    for (const queryGroup of queryGroups) {
      const [usedFieldIdsForGroup, usedRelationIdsForGroup] = this.getUsedFieldInfoForGroup(queryGroup);
      usedFieldIds = usedFieldIds.concat(usedFieldIdsForGroup);
      usedRelationIds = usedRelationIds.concat(usedRelationIdsForGroup);
      if (queryGroup.sub_groups?.length) {
        const [usedFieldIdsSub, usedRelationIdsSub] = this.getUsedFieldInfo(queryGroup.sub_groups, null, false);
        usedFieldIds = usedFieldIds.concat(usedFieldIdsSub);
        usedRelationIds = usedRelationIds.concat(usedRelationIdsSub);
      }
    }
    return [usedFieldIds, usedRelationIds];
  }

  private getUsedFieldInfoForGroup(queryGroup: AdvFieldQueryGroup): [string[], string[]] {
    let usedFieldIds = [];
    let usedRelationIds = [];
    for (const fieldQuery of queryGroup.field_queries) {
      if (fieldQuery.parent_field_ids?.length) {
        usedFieldIds = usedFieldIds.concat(fieldQuery.parent_field_ids);
      }
      if (fieldQuery.relation_superobject_type_id) {
        usedRelationIds.push(fieldQuery.relation_superobject_type_id);
      }
    }
    return [usedFieldIds, usedRelationIds];
  }

  private fiterUsedFields(fields: AdvancedSearchField[], usedFieldIds: string[]): AdvancedSearchField[] {
    let res = fields;
    if (usedFieldIds.length > 0) {
      res = [];
      for (const field of fields) {
        if (!usedFieldIds.includes(field.artifact_id)) {
          res.push(field);
        }
      }
    }
    return res;
  }

  private filterUsedRelations(fields: AdvancedSearchField[], usedRelationIds: string[]): AdvancedSearchField[] {
    let res = fields;
    if (usedRelationIds.length > 0) {
      res = [];
      for (const field of fields) {
        if (!usedRelationIds.includes(field.superobject_type_id)) {
          res.push(field);
        }
      }
    }
    return res;
  }
}
