import { Injectable } from '@angular/core';
import {SearchContainer} from '../core/definitions/search-container';
import {SearchParameters} from '../core/definitions/search-parameters';
import {RefParams, RefService} from '../core/ref.service';
import {SearchObject} from '../core/definitions/search-object';
import {ObjectTypes} from '../core/definitions/object-types';
import {SolrFilterService} from '../core/solr-filter.service';
import {SearchService} from "../core/search.service";

@Injectable({
  providedIn: 'root'
})
export class SearchHierarchicBreadcrumbService {

  constructor(private solrFilterService: SolrFilterService,
              private searchService: SearchService,
              private refService: RefService) { }

  async setBreadcrumbsFromSearch(searchContainer: SearchContainer, targetId: string) {
    let item = await this.searchForObject(targetId);
    if (item && this.isHierarchicModel(item)) {
      await this.setRef(item);
      searchContainer.breadcrumbs = [item];
      if (item.parent_id) {
        do {
          item = await this.searchForObject(item.parent_id);
          if (item) {
            await this.setRef(item);
            searchContainer.breadcrumbs.unshift(item);
          } else {
            item = null;
          }
        } while (item && item.parent_id);
      }
    }
  }

  private async searchForObject(objectId): Promise<SearchObject> {
    let res;
    const searchParams = {} as SearchParameters;
    this.solrFilterService.addFq(searchParams, 'artifact_id', objectId);
    const searchRes = await this.searchService.search(searchParams);
    if (searchRes.artifacts.length) {
      res = searchRes.artifacts[0];
    } else {
      console.warn(`No object with id ${objectId} found`);
    }
    return res;
  }

  private isHierarchicModel(searchObject: SearchObject) {
    return searchObject.object_type === ObjectTypes.FOLDER;
  }

  private async setRef(item: SearchObject) {
    const refParams = new RefParams(item);
    item.$$refData = await this.refService.makeRef(refParams);
  }
}
