import { Injectable } from '@angular/core';
import {CmsApiService} from '../core/cms-api.service';
import {ResultViewService} from './result-view.service';
import {SearchResultViewType} from '../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../core/definitions/search-container';

@Injectable({
  providedIn: 'root'
})
export class SearchTemplateGroupService {

  constructor(private cms: CmsApiService,
              private resultViewService: ResultViewService) { }

  setTemplateGroup(searchContainer: SearchContainer, templateGroupId: string) {
    searchContainer.templateGroupId = templateGroupId;
    if (!templateGroupId) {
      this.restoreSearchViewOverviewFields(searchContainer);
    }
    if (searchContainer.operationContainer) {
      searchContainer.operationContainer.templateGroupId = templateGroupId;
    }
  }

  async setListFieldTemplateGroup(searchContainer: SearchContainer, listFieldTemplateGroupId: string) {
    searchContainer.listFieldTemplateId = listFieldTemplateGroupId;
    if (listFieldTemplateGroupId) {
      if (searchContainer.operationContainer) {
        searchContainer.operationContainer.listFieldTemplateId = listFieldTemplateGroupId;
      }
      const templateGroup = await this.cms.getTemplateGroup({artifact_id: listFieldTemplateGroupId});
      if (templateGroup) {
        this.resultViewService.setCurrentResultView(SearchResultViewType.LIST, searchContainer);
      } else {
        console.warn('Could not get template group ' + listFieldTemplateGroupId);
      }
    } else {
      this.restoreSearchViewOverviewFields(searchContainer);
    }
  }

  clearTemplateGroup(searchContainer: SearchContainer) {
    searchContainer.templateGroupId = undefined;
    searchContainer.oldTempGroupId = undefined;
    this.restoreSearchViewOverviewFields(searchContainer);
  }

  clearListFieldTemplate(searchContainer: SearchContainer) {
    searchContainer.listFieldTemplateId = undefined;
    this.restoreSearchViewOverviewFields(searchContainer);
  }

  private restoreSearchViewOverviewFields(searchContainer: SearchContainer) {
    if (searchContainer.currentPathView?.search_view.$$origOverviewFields) {
      searchContainer.currentPathView.search_view.overview_fields =
        searchContainer.currentPathView.search_view.$$origOverviewFields;
      searchContainer.currentPathView.search_view.$$origOverviewFields = undefined;
    }
  }

}
