<div class="operation-view-object"
     *ngIf="operationContainer.currentOperation.$$showOperationView" [@slideObjectMenuCurrent]>

  <div class="operation-view-object__content"
       [hidden]="operationContainer.currentOperation.no_operation_view">
    <div class="operation-view-object__top">
      <div class="operation-view-object__top-content">
        <i class="{{operationContainer.currentOperation.menu_icon}}"></i>

        <h1 class="light operation-view-object__top-title"
            [translate]="operationContainer.currentOperation.menu_title"></h1>
      </div>
    </div>
    <div class="operation-view-object__view">
      <app-operation-view-section-container
        *ngIf="!operationContainer.currentOperation.upload_info"
        [operationContainer]="operationContainer"
        [operationStepIndex]="operationContainer.currentOperation.$$currentStepIndex"
      ></app-operation-view-section-container>
      <app-operation-view-upload
        *ngIf="operationContainer.currentOperation.upload_info"
        [operationContainer]="operationContainer"
        [operationStepIndex]="operationContainer.currentOperation.$$currentStepIndex"
      ></app-operation-view-upload>
    </div>
  </div>
</div>
