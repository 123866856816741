<ng-container *ngIf="!enableEditMode">
  <mat-list class="user-collections__view-mode">
    <mat-list-item *ngFor="let userCollection of collections">
        <span>{{userCollection.collection_id_value}}, {{userCollection.collection_rights_id_value}}</span>
        <ng-container *ngIf="userCollection['is_main_collection']">
          (<span translate="TRANS__USER_COLLECTIONS__IS_MAIN_COLLECTION"></span>)
        </ng-container>
      <mat-divider></mat-divider>
    </mat-list-item></mat-list>
</ng-container>

<ng-container *ngIf="enableEditMode">
  <table mat-table [dataSource]="collections" class="edit-user-collections-table">

    <ng-container matColumnDef="collection_name">
      <th mat-header-cell *matHeaderCellDef>
      <span translate="TRANS__USER_COLLECTIONS__COLLECTION"></span></th>
      <td mat-cell *matCellDef="let element">{{element['collection_id_value']}}
        <ng-container *ngIf="element['is_main_collection']">
         (<span translate="TRANS__USER_COLLECTIONS__IS_MAIN_COLLECTION"></span>)
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="collection_rights">
      <th mat-header-cell *matHeaderCellDef>
        <span translate="TRANS__USER_COLLECTIONS__SELECT_COLLECTION_RIGHTS"></span></th>
      <td mat-cell *matCellDef="let element">
        <app-user-collection-rights-type-setter
          [userCollection]="element"
          [collectionType]="collectionType"
        ></app-user-collection-rights-type-setter></td>
    </ng-container>

    <ng-container *ngIf="collectionType === 'collections'" matColumnDef="is_main_collection">
      <th mat-header-cell *matHeaderCellDef>
        <span translate="TRANS__USER_COLLECTIONS__IS_MAIN_COLLECTION"></span></th>
      <td mat-cell *matCellDef="let element">
        <mat-radio-group [(ngModel)]="element['is_main_collection']"
                         (ngModelChange)="onChangedMainCollection(element)">
          <mat-radio-button [value]="true"></mat-radio-button>
        </mat-radio-group></td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef><span>&nbsp;</span></th>
      <td mat-cell *matCellDef="let element">
        <button type="button"
                mat-button
                [title]="'TRANS__USER_COLLECTIONS__REMOVE_COLLECTION' | translate"
                (click)="onRemoveCollection(element)">
          <mat-icon>delete_outline</mat-icon></button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [@deleteItem]="crud.getDestroy(row) ? 'collapsed' : 'expanded'"></tr>
  </table>
</ng-container>
