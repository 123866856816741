<div class="edit-report-template-container">
  <div class="edit-report-template-container-left">
    <span class="edit-report-template-container_close"><i class="material-icons">close</i></span>
    <h1>Tilpass Rapporten</h1>

    @if (!this.reportTemplate) {
      <div>Laster...</div>
    } @else {
      <div class="edit-report-header-area">
        <h1>Lagrede oppsett (ikke implementert enda)</h1>
        <div class="edit-report-input-field-three-button">
          <input type="text" placeholder="Velg et lagret oppsett"/>
          <button class="mat-button edit-report-form-button edit-report-form-button-primary"
                  matTooltip="Lagre som ny rapportmal" (click)="showEditReportTemplateTitleArea=true">
            <mat-icon>add</mat-icon>
          </button>
          <button class="mat-button edit-report-form-button edit-report-form-button-primary"
                  matTooltip="Lagre rapportmal"
                  (click)="persistReportTemplate()">
            <mat-icon>save</mat-icon>
          </button>
          <button class="mat-button edit-report-form-button edit-report-form-button-end" matTooltip="Slett rapportmal">
            <mat-icon>delete</mat-icon>
          </button>
        </div>

        @if (showEditReportTemplateTitleArea) {
          <div class="edit-report-edit-title-overlay"></div>
          <div class="edit-report-edit-title-area">
            <h1>Lagre rapportmalen som en ny rapportmal med gjeldende innstillinger.</h1>
            <h1>Tittel for rapportmalen</h1>
            <input class="form-field" type="text" name="title" placeholder="Gi rapportmalen en tittel."
                   [(ngModel)]="reportTemplate.name"/>

            <h1>Kort beskrivelse</h1>
            <textarea class="form-field" name="shortDescription"
                      placeholder="Gi rapportmalen en kort beskrivelse."
                      [(ngModel)]="reportTemplate.shortDescription"></textarea>

            <div class="text-right">
              <button mat-button (click)="showEditReportTemplateTitleArea=false">Avbryt</button>
              <button mat-button color="accent" (click)="showEditReportTemplateTitleArea=false">Lagre</button>
            </div>
          </div>
        }


        <h1>Rapportutseendet</h1>
        <div>
          <mat-form-field>
            <mat-label>Velg Rapportutseende</mat-label>
            <mat-select [(ngModel)]="this.selectedReportViewType" (selectionChange)="reportViewTypeChanged()">
              @for (reportView of this.reportViewTypes; track reportView) {
                <mat-option [value]="reportView">{{ reportView.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <h1>Visningsvalg</h1>

        <div class="edit-report-two-column-area">
          @for (viewChoice of this.viewOptionsCheckboxValues; track viewChoice) {
            <label><input type="checkbox" [checked]="viewChoice.selected" (change)="toggleViewChoice(viewChoice)"
                          [attr.disabled]="!this.selectedReportViewType || this.selectedReportViewType.viewChoices.indexOf(viewChoice.key) == -1 ? true : null"/> {{ viewChoice.label }}</label>
          }
        </div>

        <h1>Feltvalg</h1>
        <div class="edit-report-header-area-buttons">
        <span class="edit-report-header-area-button accent"
              (click)="markAllTopLevelFields(true)">Merk alle seksjoner</span>
          <span class="edit-report-header-area-button"
                (click)="markAllTopLevelFields(false)">Fjern alle seksjoner</span>
          <span class="edit-report-header-area-button float-right">Søk (uferdig)</span>
        </div>
      </div>

      @if(this.reportTemplate.fieldSections) {
        <div class="report-collapsible-list"
             [dragula]="dragulaId"
             [(dragulaModel)]='reportTemplate.fieldSections'>

          @for (field of reportTemplate.fieldSections; track field) {
            <div class="report-collapsible-list-item" >
              <div class="report-collapsible-list-item-header">
                <mat-slide-toggle [(ngModel)]="field.isSelected" (click)="toggleTopLevelField(field)"></mat-slide-toggle>
                <span class="report-collapsible-list-item-label" (click)="toggleFieldExpanded(field)">{{ field.title | translate }}
                  ({{ field.fields.length }})</span>
                <span class="report-collapsible-list-item-actions">
                  @if (field.isExpanded) {
                    <mat-icon (click)="toggleFieldExpanded(field)">keyboard_arrow_down</mat-icon>
                  } @else {
                    <mat-icon (click)="toggleFieldExpanded(field)">keyboard_arrow_up</mat-icon>
                  }
                  <mat-icon class="handle">drag_indicator</mat-icon>
                </span>
              </div>

              @if (field.isExpanded) {
                @for (child of field.fields; track child) {
                  <div class="report-collapsible-list-child-item">
                    <div class="report-collapsible-list-child-item-header">
                      <mat-slide-toggle [(ngModel)]="child.isSelected" (click)="toggleSubField()"></mat-slide-toggle>
                      <span>{{ child.title ? (child.title | translate) : (child.admin_title | translate) }}</span>
                      <span class="report-collapsible-list-item-actions">
                      <!--mat-icon>drag_indicator</mat-icon-->
                    </span>
                    </div>
                  </div>
                }
              }
            </div>
          }
        </div>
      }
    }
  </div>
  <div class="edit-report-template-container-right">
    <div class="edit-report-preview-area">
      @if(this.reportTemplate) {
        <report-preview [reportTemplate]="reportTemplate" [viewOptionsCheckboxValues]="viewOptionsCheckboxValues" preview="true" limit="10"></report-preview>
      }
    </div>
    <div class="edit-report-button-area">
      <div class="float-left edit-report-button-area-small-left-margin">* Forhåndsvisningen er begrenset til 10 gjenstander</div>
      <button mat-button>AVBRYT</button>
      <button mat-button color="accent">LAG RAPPORT</button>
    </div>
  </div>
</div>
