<div class="report-preview-area">
  @for (artifact of extractedArtifacts; track artifact.artifactId; let idx = $index) {
    <div class="report-image-artifact-object">
      <div class="report-image-artifact-image">
        <div class="report-artifact-field-image-box">
          @if (artifact.thumbnailImage) {
            <app-thumbnail
              [item]="artifact.thumbnailImage"
              [stylingClass]="'report-artifact-field-image-img'"></app-thumbnail>
          } @else {
            <div class="default-image">
              No Image
            </div>
          }
        </div>
      </div>
      <h1>{{ artifact.artifactName }}</h1>

      @for (section of artifact.sections; track section.id) {
        @for (field of section.fields; track $index; let isEven = $even; let isFirst = $first) {
          <div class="report-image-value-area">
            @for (value of field.value; track $index; let isEven2 = $even) {
                {{ value.values }}
            }
          </div>
        }
      }
    </div>
  }
</div>
