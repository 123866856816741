import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ContentListSourceContainer} from '../../core/definitions/object-content-tab/content-list-source-container';

@Component({
  selector: 'app-object-content-list-source-array-pager',
  templateUrl: './object-content-list-source-array-pager.component.html',
  styleUrls: ['./object-content-list-source-array-pager.component.scss']
})
export class ObjectContentListSourceArrayPagerComponent implements OnChanges {

  @Input() contentListSource: ContentListSourceContainer;
  @Output() pageChange = new EventEmitter<void>();


  endPage;
  private startPage = 1;
  private timeoutPromise;
  private lastPage = 1;
  private artifactLowerEl;

  constructor() {
  }

  ngOnChanges() {
    this.artifactLowerEl = document.getElementById('artifactLower');
    this.setPageInfo();
  }

  setPage(page) {
    this.contentListSource.page = page;
    this.listPageChange();
  }

  prevPage() {
    if (this.contentListSource.page > 1) {
      this.setPage(this.contentListSource.page - 1);
    }
  }

  nextPage() {
    if (this.contentListSource.page < this.maxPage) {
      this.setPage(this.contentListSource.page + 1);
    }
  }

  setLastPage() {
    this.setPage(this.maxPage);
  }

  get maxPage() {
    let maxPage = Math.ceil(this.contentListSource.totalPageItems.length / this.contentListSource.maxRows);
    if (maxPage === 0) {
      maxPage = 1;
    }
    return maxPage;
  }

  listPageChange() {
    this.pageChange.emit();
    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }
    this.timeoutPromise = setTimeout(() => {
      if (this.lastPage !== this.contentListSource.page) {
        const page = this.getListPage(this.contentListSource.page);
        if (page) {
          this.lastPage = this.contentListSource.page;
          if (!this.contentListSource.displayAll) {
            this.scrollToPager(500);
          }
        }
      }
    }, 500);
  }

  getPages() {
    let t;
    const res = [];
    for (t = this.startPage; t < this.endPage + 1; t++) {
      res.push(t);
    }
    return res;
  }

  toggleDisplayAll() {
    this.contentListSource.displayAll = !this.contentListSource.displayAll;
    if (!this.contentListSource.displayAll) {
      this.scrollToPager(500);
    }
    this.setPage(1);
  }

  private getListPage(value) {
    let page, res;
    if (!isNaN(value)) {
      page = Number(value);
      if (page > 0 && page <= this.maxPage) {
        res = page;
      }
    }
    return res;
  }

  private scrollToPager(timeOut) {
    if (this.artifactLowerEl) {
      setTimeout(() => {
        const lowerPos = this.artifactLowerEl.offsetTop + 210;

        if (window.pageYOffset > lowerPos) {
          // const divPosition = this.artifactLowerEl.offsetTop;
          // const container = angular.element(
          //   document.getElementsByTagName(
          //     'body'));
          // $document.duScrollToElement(
          //   container, divPosition, 100);
        }
      }, timeOut);
    }
  }

  private setPageInfo() {
    const maxPage = this.maxPage;
    if (maxPage <= 6) {
      // Less than 6 total pages so show all
      this.startPage = 1;
      this.endPage = maxPage;
    } else {
      // More than 6 total pages: calculate start and end.
      if (this.contentListSource.page <= 4) {
        this.startPage = 1;
        this.endPage = 6;
      } else if (this.contentListSource.page + 2 >= maxPage) {
        this.startPage = maxPage - 5;
        this.endPage = maxPage;
      } else {
        this.startPage = this.contentListSource.page - 3;
        this.endPage = this.contentListSource.page + 3;
      }
    }
  }

}
