import {Component, Inject, OnInit} from '@angular/core';
import {SectionsContainer} from "../../core/definitions/sections-container";
import {Reference} from "../../core/definitions/reference";
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from "@angular/material/dialog";
import {EditObjectDialogData} from "../edit-object-dialog-data";
import {ObjectEditService} from "../../core/object-edit.service";
import {EditObjectDialogService} from "../edit-object-dialog.service";
import {ReferenceUsageInfo, SearchReferenceService} from "../../core/search-reference.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-object-child-dialog',
  templateUrl: './edit-object-child-dialog.component.html',
  styleUrls: ['./edit-object-child-dialog.component.scss']
})
export class EditObjectChildDialogComponent implements OnInit {
  sectionsContainer: SectionsContainer = new SectionsContainer();
  private fieldReference: Reference;
  primeFields: any[];
  storeStatus = {
    failed: false,
    message: '',
    serverMsg: ''
  };
  modelTitle = '';
  referenceUsageInfo: ReferenceUsageInfo;

  constructor(public dialogRef: MatDialogRef<EditObjectChildDialogComponent>,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: EditObjectDialogData,
              private objectEditService: ObjectEditService,
              private editObjectDialogService: EditObjectDialogService,
              private searchReferenceService: SearchReferenceService) {
  }

  ngOnInit() {
    this.initObject().then();
  }

  onSubmit() {
    if (this.referenceUsageInfo.usage.length && this.referenceUsageInfo.usageConfirmStoreText) {
      const dialogText = this.translate.instant(this.referenceUsageInfo.usageConfirmStoreText);
      if (!window.confirm(dialogText)) {
        return;
      }
    }
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer, true).then(
      value => {
        this.dialogRef.close(value);
      },
      reason => {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__STORE_FAILED';
        this.storeStatus.serverMsg = reason.error.message;
      }
    );
  }

  get isValid(): boolean {
    return this.sectionsContainer.formGroup.valid;
  }

  private async initObject() {
    const fieldParams = this.data.fieldParameters;
    this.fieldReference = this.searchReferenceService.getSearchReferenceFromField(fieldParams.field);
    this.sectionsContainer = await this.editObjectDialogService.createOptionFromFieldParameters(
      fieldParams, this.data, this.fieldReference, this.data.createText, this.data.parentId);
    if (!this.sectionsContainer) {
      this.storeStatus.failed = true;
      this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__NO_ACCESS';
    } else {
      this.primeFields = this.sectionsContainer.primeFields;
    }
    this.referenceUsageInfo = await this.searchReferenceService.getReferenceUsage(
      this.data.fieldParameters, this.sectionsContainer?.parentObject)
    this.modelTitle = this.getModelTitle();
  }

  private getModelTitle() {
    let res = '';
    if (this.sectionsContainer.rootObject) {
      res = this.sectionsContainer.rootObject.object_model_title || this.sectionsContainer.rootObject.model_title || '';
    }
    return res;
  }

}
