export class ModelCacheArray<T> extends Array<T> {
    loading: Promise<T[]>|null;
    status: string;

    constructor(...items: T[]) {
        super(...items);
        this.loading = null;
        this.status = 'new';
    }

    public setLoading(loading: Promise<T[]>) {
        this.loading = loading
    }
}