import {
  AfterViewChecked,
  AfterViewInit,
  Component, effect, ElementRef,
  EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output, QueryList, signal,
  ViewChildren
} from '@angular/core';
import {MatLabel} from '@angular/material/form-field';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';
import {MatButton} from '@angular/material/button';
import {
  MatActionList,
  MatList,
  MatListItem,
  MatListItemAvatar,
  MatListItemLine,
  MatListItemTitle,
  MatNavList
} from '@angular/material/list';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardImage,
  MatCardSubtitle,
  MatCardTitle
} from '@angular/material/card';
import {MatRipple} from '@angular/material/core';
import {fromEvent, Subscription} from 'rxjs';
import {MAX_NUMBER_OF_RECENT_REGISTRATIONS} from '../../welcome.service';

export interface RecentRegistration {
  img: string;
  type: string;
  label: string;

  [key: string]: any;
}

const MIN_CARD_WIDTH = 80;

@Component({
  selector: 'app-recent-registrations-list',
  standalone: true,
  imports: [
    MatLabel,
    MatProgressSpinner,
    NgIf,
    TranslateModule,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatButton,
    MatList,
    MatListItem,
    MatListItemAvatar,
    MatListItemLine,
    MatListItemTitle,
    MatNavList,
    MatActionList,
    MatCard,
    MatCardImage,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    MatRipple,
  ],
  templateUrl: './recent-registrations-list.component.html',
  styleUrl: './recent-registrations-list.component.scss'
})
export class RecentRegistrationsListComponent implements OnChanges, OnDestroy {

  @Input() loading = false;
  @Input() registrations: RecentRegistration[] = [];

  @Output() registrationClicked = new EventEmitter<RecentRegistration>();

  visibleCards = signal<RecentRegistration[]>([]);
  placeholderItems = signal<any[]>([]);

  subscriptions: Subscription[] = [];

  constructor(private el:ElementRef) {
    this.subscriptions.push(fromEvent(window, 'resize').subscribe(() => {
      this.calculateVisibleItems();
    }));
  }

  ngOnChanges() {
    this.calculateVisibleItems();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onRegistrationClicked(_: MouseEvent, registration: RecentRegistration) {
    this.registrationClicked.emit(registration);
  }

  private calculateVisibleItems() {
    const el = this.el.nativeElement;
    const containerWidth = el.clientWidth;
    const screenWidth = window.innerWidth;
    const halfGap = screenWidth <= 1024 ? 4 : 8;
    const numCards = Math.min(Math.floor(containerWidth / (MIN_CARD_WIDTH + halfGap)), MAX_NUMBER_OF_RECENT_REGISTRATIONS)
    if (screenWidth <= 600) {
      // up to two rows for small screens
      this.visibleCards.set(this.registrations.slice(0, numCards * 2));
      let numPlaceholders = numCards * 2 - this.registrations.length;
      this.placeholderItems.set(Array(Math.max(numPlaceholders, 0)).fill(null));
    } else {
      const numPlaceholders = numCards - (this.registrations.length);
      this.visibleCards.set(this.registrations.slice(0, numCards));
      this.placeholderItems.set(Array(Math.max(numPlaceholders, 0)).fill(null));
    }
  }

}
