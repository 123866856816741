import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {OperationDef} from '../../core/definitions/operation-def';
import {ContentInfo} from '../../core/definitions/content-info';
import {OperationContainer} from '../../core/definitions/operation-container';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {PrimusRouterService} from '../../core/primus-router.service';

@Component({
  selector: 'app-operation-selector-vertical',
  templateUrl: './operation-selector-vertical.component.html',
  styleUrls: ['./operation-selector-vertical.component.scss']
})
export class OperationSelectorVerticalComponent implements OnChanges {

  @Input() smallScreen: boolean;
  @Input() editContent: boolean;
  @Input() operationContainer: OperationContainer;
  @Input() contentList;
  @Output() selectOperation = new EventEmitter<OperationDef>();
  contentInfo: ContentInfo;

  constructor(public primusStateMapper: PrimusStateMapperService,
              private primusRouter: PrimusRouterService) {
  }

  currentState: string;

  ngOnChanges(): void {
    this.currentState = this.primusRouter.currentState();
  }

  setActiveOperation(operation: OperationDef) {
    this.selectOperation.emit(operation);
  }

}
