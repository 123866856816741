import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UiToolsService} from '../../core/ui-tools.service';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationStepExecutionResult} from '../../core/definitions/operation-step-execution-result';
import {TranslateService} from '@ngx-translate/core';
import {CmsErrorHandlerService} from '../../core/cms-error-handler.service';

export interface OperationViewDialogData {
  operationContainer: OperationContainer;
}

@Component({
  selector: 'app-operation-view-dialog',
  templateUrl: './operation-view-dialog.component.html',
  styleUrls: ['./operation-view-dialog.component.scss']
})
export class OperationViewDialogComponent implements OnInit {

  operationContainer: OperationContainer;
  operationStepExecutionResult: OperationStepExecutionResult;
  errorMessages: string[] = [];


  @HostListener('click', ['$event.target'])
  registerClick(event) {
    this.uiTools.registerDocumentClick(event);
  }

  constructor(public dialogRef: MatDialogRef<OperationViewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: OperationViewDialogData,
              private translate: TranslateService,
              private uiTools: UiToolsService,
              private cmsErrorHandlerService: CmsErrorHandlerService) {
  }

  ngOnInit(): void {
    this.operationContainer = this.data.operationContainer;
    this.operationContainer.currentOperation.$$operationDialog = this.dialogRef;
  }

  onOperationStepExecutionResult(operationStepExecutionResult: OperationStepExecutionResult) {
    this.errorMessages = [];
    if (operationStepExecutionResult) {
      this.errorMessages = this.cmsErrorHandlerService.formatSaveError(
        operationStepExecutionResult.result_object,
        operationStepExecutionResult.status_message,
        operationStepExecutionResult.error_message
      );
    }
    this.operationStepExecutionResult = operationStepExecutionResult;
  }

  get dialogTitle() {
    const title = this.operationContainer.currentOperation.$$currentStep.information?.title ||
      this.operationContainer.currentOperation.menu_title;
    return this.translate.instant(title);
  }
}
