<div class="overview-field-value">

  <div class="overview-field-value__notLink"
       *ngIf='!overviewField.is_link'
       [ngClass]="{'content-has-icon': fieldIcon?.icon}">
    <i *ngIf="fieldIcon" class="{{fieldIcon.icon}}">{{fieldIcon.icon_frame}}</i>

    <span *ngIf="!isArrayValue"
          class="overview-field-value__notLink-text"
          [ngClass]="{'expired': expired}"
          [innerHTML]="fieldValues[0] && fieldValues[0].value"></span>

    <ng-container *ngIf="isArrayValue">

      <span *ngFor="let item of fieldValues; index as $index"
            class="overview-field-value__item"
            [ngClass]="{
            'hide-value': $index > 0,
            'has-show-more': fieldValues.length > 1}">
        <span *ngIf="$index < 1">{{item.value}}</span>
      </span>

      <button *ngIf="fieldValues.length > 1"
              (menuOpened)="showMore = true;"
              (menuClosed)="showMore = false;"
              [matMenuTriggerFor]="toggleShowMoreFieldValue"
              class="overview-field-value__show-more"
              mat-button>
        <span>(</span>
        <span translate="TRANS__ARRAY_FIELD__SHOW_MORE"></span>
        <span>)</span>
      </button>

      <mat-menu #toggleShowMoreFieldValue="matMenu"
                (click)="$event.stopPropagation()"
                class="removePaddingInMatMenu overview-show-more-menu-panel">
        <mat-list class="overview-show-more-menu">
          <ng-container *ngFor="let item of fieldValues; index as $index;">
            <mat-list-item *ngIf="$index > 0">
              <span class="overview-field-value__item-text">{{item.value}}</span>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-menu>

    </ng-container>
  </div>

  <div class="overview-field-value__link" *ngIf="overviewField.is_link">
    <app-overview-field-link
      [toneDownTextColor]="false"
      [stylingClass]="viewName"
      [items]="linkItems"
      [disableLinks]="linkDisabled"
    ></app-overview-field-link>
  </div>

</div>



