import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {InformationComponent} from './information/information.component';
import {Observable} from 'rxjs';
import {PrimusRouterService} from '../core/primus-router.service';

@Injectable()
export class CanDeactivateInformationPage  {
  constructor(private primusRouter: PrimusRouterService) {
  }

  canDeactivate(
    component: InformationComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.primusRouter.checkCancel();
  }

}
