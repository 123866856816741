import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SearchObject} from "../../../core/definitions/search-object";

@Component({
  selector: 'app-search-result-view-list-selector',
  templateUrl: './search-result-view-list-selector.component.html',
  styleUrls: ['./search-result-view-list-selector.component.scss']
})
export class SearchResultViewListSelectorComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;
  selectedItemId = null;

  constructor(private searchResultSelectionsService: SearchResultSelectionsService) {
  }

  ngOnInit() {
    if (this.searchContainer.selections.singleSelect && this.searchContainer.selections.selectedItems.length) {
      this.selectedItemId = this.searchResultSelectionsService.getItemId(
          this.searchContainer.selections.selectedItems[0]);
    }
  }

  select(obj: SearchObject, event: any) {
    this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
    if (this.searchContainer.selections.singleSelect) {
      this.selectedItemId = obj.artifact_id;
    }
  }

}
