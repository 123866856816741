import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-panel-layout',
  templateUrl: './panel-layout.component.html',
  styleUrls: [
    './panel-layout.component.scss',
    './panel-layout.component.tablet.scss',
    './panel-layout.component.mobile.scss',
  ]
})
export class PanelLayoutComponent {

  @Input() public closePanelText: string;
  @Input() public headlineText: string;
  @Input() public description: string;
  @Input() public loading?: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public readonly onClose: EventEmitter<void>;

  constructor() {
    this.onClose = new EventEmitter<void>();
    this.loading = false;
  }

}
