<div class="edit-field-date-historic field-date-input"
     [formGroup]="fieldParameters.sectionsContainer.formGroup">
  <input
    [formControlName]="formControlName"
    [placeholder]="placeholder"
    title="{{placeholder}}"
    [id]="formControlName"
    [name]="formControlName"
    type="text"
    (blur)="onFieldBlur()"
    (focus)="onFieldFocus()"
    (change)="onValueChange($event)"
    class="edit-field-date-historic__input"
    autocomplete="off"
    [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy, 'errorInputFrame': isInvalid()}">
</div>
