import {Component, Input, OnInit} from '@angular/core';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-menu-toggle-restrictions',
  templateUrl: './search-menu-toggle-restrictions.component.html',
  styleUrls: ['./search-menu-toggle-restrictions.component.scss']
})
export class SearchMenuToggleRestrictionsComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  constructor(private searchExecutorService: SearchExecutorService) {
  }

  ngOnInit() {
  }

  runSearch() {
    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

}
