import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-side-menu-toggle',
  templateUrl: './side-menu-toggle.component.html',
  styleUrls: ['./side-menu-toggle.component.scss']
})
export class SideMenuToggleComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() sideMenuSmallScreen;
  @Output() filterSideMenuSmallScreenToggled = new EventEmitter<object>();

  constructor() {
  }

  ngOnInit() {
  }
  toggleSideFilterMenuSmallScreen() {
    this.filterSideMenuSmallScreenToggled.emit();
  }
}
