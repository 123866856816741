<div class="operation-menus-small"
     [ngClass]="{'edit': isEditing, 'on-media-page': mediaPage}"
     id="actionMenuContainer">
  <ng-container *ngFor="let operation of operationContainer.operations">

    <button mat-button
            [matMenuTriggerFor]="operationMenu"
            class="operation-menus-small__button text-blue-hover"
            (click)="toggleOperation(operation)"
            #trigger="matMenuTrigger"
            [disabled]="isEditing || (rootObject.read_only && !operation.read_access) ||
              operation.$$disabled">
      <i class="{{operation.menu_icon}}"></i>
      <span [translate]="operation.menu_title"></span></button>

    <mat-menu #operationMenu="matMenu">
      <mat-accordion>
        <mat-expansion-panel
          #currentPanel
          *ngFor="let subOperation of operation.sub_operations; index as i"
          (click)="openOperation(subOperation, operation, trigger); $event.stopPropagation()"
          [hideToggle]="!subOperation.sub_operations"
          [disabled]="!subOperation.sub_operations"
          [class.is-expanded]="currentPanel.expanded"
          [class.is-collapsed]="!currentPanel.expanded">
          <mat-expansion-panel-header
            class="operation-menus-small__panel-header"
            [collapsedHeight]="'40px'"
            [expandedHeight]="'40px'">
            <mat-panel-title>
              <i *ngIf="!subOperation.sub_operations" class="{{subOperation.menu_icon}}"></i>
              <span [translate]="subOperation.menu_title"></span></mat-panel-title>
          </mat-expansion-panel-header>

          <mat-list>
            <mat-list-item
              *ngFor="let subSubOperation of subOperation.sub_operations"
              class="operation-menus-small__panel-content-item">
              <button
                (click)="openOperation(subSubOperation, operation, trigger)"
                [disabled]="subSubOperation.$$disabled"
                [title]="subSubOperation.$$toolTip">
                <i class="{{subSubOperation.menu_icon}}"></i>
                <span [translate]="subSubOperation.menu_title"></span></button>
            </mat-list-item>
          </mat-list>

        </mat-expansion-panel>
      </mat-accordion>
    </mat-menu>
  </ng-container>
</div>
